import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { MimePurposeToBeRenamed } from "../../../../Model/Catalog/MimeModels/MimePurposeToBeRenamed";

@Component({
  selector: "useraction-changemimepurposes",
  templateUrl: "useraction-changemimepurposes.component.html"
})
export class UserActionChangeMimePurposeComponent extends UserActionBaseComponent implements OnInit {

  loading = false;

  purposesGridDataSource: Array<MimePurposeToBeRenamed> = [];

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  ngOnInit() {
    super.ngOnInit(); //important

    this.loadPurposes();
  }



  loadPurposes() {
    return new Promise<void>((resolve) => {

      let selectedCatalogId = this.modelService.catalogService.selectedCatalogId;
      let customerId = this.modelService.loginService.currentUser.customerId;
      this.loading = true;

      this.purposesGridDataSource = new Array<MimePurposeToBeRenamed>();

      this.modelService.catalogService
        .getAllMimePurposesInCatalog(selectedCatalogId, customerId)
        .subscribe(
          (purposes) => {
            this.setPurposes(purposes);
            this.loading = false;
            resolve();
          });

    });
  }

  setPurposes(purposes) {
    if (purposes) {
      for (var element of purposes) {
        let newItem = new MimePurposeToBeRenamed(element, null);
        this.purposesGridDataSource.push(newItem);
      }
    }
  }



  startMacro() {
    if (this.purposesGridDataSource.length == 0)
      return;

    this.modelService.catalogService.showUserAction = false;

    this.modelService.jobService.startUserAction(this.createUserAction()).subscribe(next => {
      this.userActionStarted.emit(next);
    });
  }

  createUserAction(): StartAction {
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "ChangeMimePurposes";
    startAction.actionParameter = this.createParameterStringAsJsonString();
    return startAction;
  }

  createParameterStringAsJsonString(): string {
    let parameterJsonString = JSON.stringify({
      mimePurposesToBeRenamed: this.purposesGridDataSource
    });
    return parameterJsonString;
  }
}
