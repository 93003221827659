import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { DataSourceParams } from "../Model/Catalog/DataSourceParams";


import { UrlResponse } from "../Model/Dto/UrlResponse";
import { MediaElement } from "../Model/Catalog/MediaElement";
import { LoginService } from "./login.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class MediaService {

  constructor(private http: HttpClient, private loginService: LoginService) {
  }


  getThumbUrl(id: string, type: string, customerId: string, catalogId: string): Observable<UrlResponse> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("id", id).append("type", type)
        .append("catalogId", catalogId)
    }

    return (this.http.get<UrlResponse>("api/catalog/GetThumbUrl", options)) as any;
  }

  getEncodedString(s: string) {
    return encodeURIComponent(s);
  }

  getDownloadLink(catalogId: string, customerId: string, path: string) {     
    return "/Cloud/false/" + catalogId + "/" + customerId + "/" + this.getEncodedString(path);
  }


  getMediaElements(catalogId: string, parameter: DataSourceParams) {
    let params = new HttpParams();
    params = params.append("catalogId", catalogId);
    if (parameter.take) {
      params = params.append("take", parameter.take.toString());
    }
    if (parameter.skip) {
      params = params.append("skip", parameter.skip.toString());
    }
    if (parameter.orderBy) {
      params = params.append("orderBy", parameter.orderBy);
    }
    if (parameter.desc) {
      params = params.append("desc", parameter.desc.toString());
    }
    if (parameter.filter) {
      params = params.append("filter", parameter.filter);
    }
    params = params.append("customerId", this.loginService.currentUser.customerId);

    let options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: params
    }

    return this.http.get("api/catalog/GetMedia", options).toPromise();
  }

  getJobDataElements(customerId: string, parameter: DataSourceParams) {

    let params = new HttpParams().append("customerId", customerId);

    if (parameter.take) {
      params = params.append("take", parameter.take.toString());
    }
    if (parameter.skip) {
      params = params.append("skip", parameter.skip.toString());
    }
    if (parameter.orderBy) {
      params = params.append("orderBy", parameter.orderBy);
    }
    if (parameter.desc) {
      params = params.append("desc", parameter.desc.toString());
    }
    if (parameter.filter) {
      params = params.append("filter", parameter.filter);
    }

    let options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: params
    }
    return this.http.get("api/job/GetJobFiles", options).toPromise();

  }
  getJobDataElement(customerId: string, jobId: string) {

    let params = new HttpParams().append("customerId", customerId);

    params = params.append("jobId", jobId.toString());


    let options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: params
    }
    return this.http.get("api/job/GetJobFile", options);

  }

  getMediaElement(mimeSource, catalogId: string): Observable<MediaElement> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("mimeSource", mimeSource).append("catalogId", catalogId)
        .append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.get<MediaElement>("api/catalog/GetMediaElement", options)) as any;
  }

  deleteMime(catalogFileId: string, catalogId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogFileId", catalogFileId).append("catalogId", catalogId)
        .append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.delete("api/catalog/DeleteMedia", options)) as any;;
  }

  deleteJobFile(mimeSource) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("mimeSource", mimeSource)
        .append("customerId", this.loginService.currentUser.customerId)
    }

    this.http.delete("api/job/DeleteJobFile", options).subscribe(() => { });
  }


}
