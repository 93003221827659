import { Component, OnInit } from "@angular/core";
import { CatalogDataQuality } from "../../Model/validation/CatalogDataQuality";
import { ModelService } from "../../Services/model.service";
import { Message } from "../../Model/System/Message";
import { StartAction } from "../../Model/Dto/StartAction";
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from "../../Services/userManagment.service";
import { ExportParameter } from "../../Model/exportParameter/ExportParameter";

@Component({
  selector: "gevis",
  templateUrl: "gevis.component.html",
  styleUrls: []
})
export class GevisComponent {

  gevisUrl = "";
  useClassification = false;
  featureSystemName = "";
  featureSystemNames;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.GetExportParameter = this.GetExportParameter.bind(this);
    this.send = this.send.bind(this);
    this.loadFeatureSystemNames = this.loadFeatureSystemNames.bind(this);


    this.loadFeatureSystemNames();
  }

  public GetExportParameter(): ExportParameter {
    let result = new ExportParameter();
    result.catalogId = this.modelService.catalogService.catalog.id;
    result.customerId = this.modelService.loginService.currentUser.customerId;
    result.version = "GEVIS";
    result.format = "GEVIS";
    result.includeMime = true;
    return result;
  }

  public send() {

    let exportParameterLive = this.GetExportParameter();
    exportParameterLive.useClassification = this.useClassification;
    exportParameterLive.featureSystem = this.featureSystemName;
    this.modelService.catalogService.createGevisExportJob(exportParameterLive).subscribe(
      () => {
        this.translate.get("Der Export wurde als Job gestartet").subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text));
        });
        this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
          .subscribe(() => {

          });
      });
  }

  loadFeatureSystemNames() {
    this.modelService.catalogService
      .getFeatureSystemFilters(this.modelService.catalogService.selectedCatalogId,
        this.modelService.loginService.currentUser.customerId)
      .subscribe(
        (filter) => {
          this.featureSystemNames = filter.map(x => x.text);
        });
  }
}
