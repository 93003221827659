<fullscreen-page>

  <content-column helpSwitch="true" look="light">
    <div slot="content" class="p-2 flex-fill overflow-hidden">
      <dx-data-grid class="h-100"
                    (onToolbarPreparing)="onContentToolbarPreparing($event)"
                    [dataSource]="getDataSource()"
                    keyExpr="katalogIdent"
                    [remoteOperations]="false"
                    [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    noDataText="{{ 'keine Soertimente im System' | translate }}"
                    [showBorders]="true">

        <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="'EdeSortimentsList'"></dxo-state-storing>
        <div *dxTemplate="let toolbaritem of 'getAllButtonTemplate'">
          <dx-button icon="refresh" (onClick)="getAll()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'parseFtpButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Parse Ede Ftp Server' | translate }}" (onClick)="parseFtp()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'startGenerateEdeInvoiceJobTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Abrechnung' | translate }}" icon="download" (onClick)="startGenerateEdeInvoiceJob()"></dx-button>
        </div>

        <div *dxTemplate="let toolbaritem of 'archiveTemplate'">
          <dx-button *ngIf="!isArchive" stylingMode="text" type="default" (onClick)="toogleArchive()" text="{{ 'Zum Archiv'  | translate }}"></dx-button>
          <dx-button *ngIf="isArchive" stylingMode="text" type="default" (onClick)="toogleArchive()" text="{{ 'Zurück zu den Sortimenten'  | translate }}"></dx-button>
        </div>

        <dxo-search-panel [visible]="true"
                          [width]="240"></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <dxi-column dataField="updateBez" caption="{{ 'Sortiment' | translate }}" width="40%" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="katalogIdent" caption="{{ 'KatalogIdent' | translate }}" width="40%" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="module" caption="{{ 'Module' | translate }}" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="language" caption="{{ 'Language' | translate }}"></dxi-column>
        <dxi-column dataField="sortimentsKennung" caption="{{ 'SortimentsKennung' | translate }}"></dxi-column>
        <dxi-column dataField="version" caption="{{ 'Installiert' | translate }}" cellTemplate="cellTemplate"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplate'">
          <div *ngIf="isInstalled(data)">
            <div *ngIf="isNewest(data)">
              <span class="dx-icon-importselected" style="font-size: 20px; color: #0091d4;"></span>
            </div>
            <div *ngIf="!isNewest(data)">
              <span class="dx-icon-importselected" style="font-size: 20px; color: #ffee9c;"></span>
            </div>
          </div>
          <div *ngIf="!isInstalled(data)">
            <span class="dx-icon-import" style="font-size: 20px; color: grey;"></span>
          </div>
        </div>

        <dxi-column caption="" cellTemplate="cellTemplate2" alignment="right"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplate2'">
          <div *ngIf="isInstalled(data)">
            <dx-button icon="clearformat"
                       hint="{{'Verlinkung zum Katalog entfernen'| translate}}"
                       (onClick)="delete(data)">
            </dx-button>
          </div>
        </div>

        <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
        <div *dxTemplate="let sortiment of 'detail'">
          <edeSortimentsVersion [sortiment]="sortiment.data"></edeSortimentsVersion>
        </div>

      </dx-data-grid>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>
