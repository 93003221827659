<dx-data-grid
  [(dataSource)]="dataSource"
  (onRowInserted)="(onRowInserted)"
  (onRowUpdated)="(onRowUpdated)"
  (onRowRemoved)="(onRowRemoved)"
>
  <dxo-column-chooser enabled="true"></dxo-column-chooser>
  <dxo-search enabled="true"></dxo-search>
  <dxo-editing
    [allowAdding]="allowAdding"
    [allowUpdating]="allowUpdating"
    [allowDeleting]="allowDeleting"
  ></dxo-editing>
  <ng-content></ng-content>
</dx-data-grid>
