<div class="dx-fieldset" *ngIf="model">
    <div class="dx-field">
        <div class="dx-field-label dx-field-label200">{{ 'EClass Version' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">ECLASS VERSION</label>
            <dx-select-box [items]="eclasses" [(value)]="model.eclassVersion" valueExpr="version" displayExpr="name"
                [readOnly]="readOnlyUser('electronicSales')" (onValueChanged)="valueChanged($event)">
            </dx-select-box>
        </div>
    </div>

    <!-- <div class="dx-field">
        <div class="dx-field-label dx-field-label200">{{ 'Domain' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">DOMAIN</label>
            <dx-text-box [(value)]="model.domain" [readOnly]="readOnlyUser('electronicSales')" [showClearButton]="true"
                (onValueChanged)="valueChanged($event)"></dx-text-box>
        </div>
    </div>

    <div class="dx-field">
        <div class="dx-field-label dx-field-label200">{{ 'UpdateMode' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">UPDATEMODE</label>
            <dx-select-box [items]="['UPDATE','REPLACE']" [(value)]="model.updateMode"
                [readOnly]="readOnlyUser('electronicSales')" (onValueChanged)="valueChanged($event)">
            </dx-select-box>
        </div>
    </div>

    <div class="dx-field">
        <div class="dx-field-label dx-field-label200">{{ 'FtpServer' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">UPDATEMODE</label>
            <dx-select-box [items]="ftpServers" [(value)]="model.ftpServerId" valueExpr="id" displayExpr="name"
                [readOnly]="readOnlyUser('electronicSales')" (onValueChanged)="valueChanged($event)">
            </dx-select-box>
        </div>
    </div>


    <div *ngIf="model.ftpServerId && model.ftpServerId != ''">
        <div class="dx-field">
            <div class="dx-field-label dx-field-label200">{{ 'Produkt XML Pfad' | translate }}</div>
            <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">Produkt CSV
                    Pfad</label>
                <dx-text-box [(value)]="model.productFilePath" [readOnly]="readOnlyUser('electronicSales')"
                    [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
            </div>
        </div>

        <div class="dx-field">
            <div class="dx-field-label dx-field-label200">{{ 'Kategory XML Pfad' | translate }}</div>
            <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">Kategorie CSV
                    Pfad</label>
                <dx-text-box [(value)]="model.categoryFilePath" [readOnly]="readOnlyUser('electronicSales')"
                    [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
            </div>
        </div>

        <div class="dx-field">
            <div class="dx-field-label dx-field-label200">{{ 'Attrubutes XML Pfad' | translate }}</div>
            <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">Feature CSV
                    Pfad</label>
                <dx-text-box [(value)]="model.attributesFilePath" [readOnly]="readOnlyUser('electronicSales')"
                    [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
            </div>
        </div>
    </div> -->

</div>