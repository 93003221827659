<div class="d-flex flex-column w-100">
  <div class="header d-flex flex-row px-3 pt-1 w-100">
    <div class="p-2 flex-fill overflow-hidden">
      <h5>{{"MassGenerateKITextTitle" | translate}}</h5>
      <span innerHTML="{{'MassGenertateKiTextDescription' | translate}}"></span>
    </div>
  </div>
  <dx-toolbar>
    <dxi-item location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', icon: 'revert', text: 'Zurück zur Katalogansicht' | translate,  onClick: back }"></dxi-item>
  </dx-toolbar>
  <div class="dx-fieldset">
    <div class="p-2 flex-fill">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Einzelne Texte für Varianten generieren' | translate }}</div>
        <div class="dx-field-value">
          <dx-switch [(value)]="genVariantTexts" (onValueChanged)="calculateProds()"></dx-switch>
        </div>
      </div>
    </div>
    <div class="p-2 flex-fill">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Generiere HTML' | translate }}</div>
        <div class="dx-field-value">
          <dx-switch hint="hintMessage" [(value)]="generateHTML"></dx-switch>
        </div>
      </div>
    </div>
    <div class="p-2 flex-fill">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Textstil' | translate }}</div>
        <div class="dx-field-value">
          <dx-select-box [items]="textstyles" [(value)]="textstyle"></dx-select-box>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Maximale Länge' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box min="50" max="10000" [(value)]="maxLength"></dx-number-box>
      </div>
    </div>


    <div class="p-2 flex-fill">
      <div class="dx-field">
        <div class="dx-field-value">
          <dx-button text="{{ 'Erstelle KI Texte' | translate }}" style="width:'100%'" (onClick)="openConfirm()"></dx-button>
        </div>
      </div>
    </div>
  </div>
</div>


<dx-popup [width]="500"
          [height]="800"
          [showTitle]="true"
          title="{{ 'KI Texte erstellen' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [(visible)]="confirmVisible"
          contentTemplate="popupContent">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Starten' | translate,width:'100%', onClick: confirmGenerateText }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-fieldset" id="popup">
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Kosten pro Text' | translate }}</div>
          <div class="dx-field-value"><dx-number-box [readOnly]="true" format="#,##0.## €" [value]="invoiceSetting.pricePerOpenAi"></dx-number-box></div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Texte die generiert werden' | translate }}</div>
          <div class="dx-field-value"><dx-number-box [readOnly]="true" [(value)]="productCount"></dx-number-box></div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Maximale Gesamtkosten' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [value]="productCount * invoiceSetting.pricePerOpenAi"></dx-number-box>
          </div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Benutztes Limit' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.usedLimit"></dx-number-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Limit' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.limit"></dx-number-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Freibetrag' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.allowance"></dx-number-box>
          </div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'TeachingSet' | translate }}</div>
          <div class="dx-field-value">
            <dx-select-box [items]="teachingSets" [(value)]="teachingSet" displayExpr="name"></dx-select-box>
          </div>
        </div>
      </div>
    </div>

    <dx-load-panel #loadPanel
                   shadingColor="rgba(0,0,0,0.4)"
                   [position]="{ of: '#popup' }"
                   [(visible)]="loading"
                   message="{{'Es wird eine Uebersicht ueber die anfallenden Kosten erstellt.'| translate }}"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="false"
                   [hideOnOutsideClick]="false">
    </dx-load-panel>
  </div>
</dx-popup>

