import { Component, OnInit } from "@angular/core";
import { Category } from "../../../Model/Catalog/Category";
import { ModelService } from "../../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { OsgConnection } from "../../../Model/Dto/OsgConnection";
import { UserManagementService } from "../../../Services/userManagment.service";
 import { OsgCategoryMapping } from "../../../Model/Catalog/OsgCategoryMapping";


@Component({
  selector: "categoryOsg",
  templateUrl: "categoryOsg.component.html",
  styleUrls: []
})
export class CategoryOsgComponent implements OnInit {
  pages = [];

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {

  }

  ngOnInit(): void {

    let testText = "";
    testText = this.translate.instant("Test");
    let liveText = "";
    liveText = this.translate.instant("Live");

    this.pages = [
      { id: 1, title: liveText, template: "liveTemplate" }
    ];

    this.modelService.osgConnectionService.getConnection(this.modelService.loginService.currentCustomer.id, true).subscribe(
      (result: OsgConnection) => {
        if (result != null && result.shopUrl.length > 0) {
          this.pages.push({ id: 2, title: testText, template: "testTemplate" });
        }
      });
  }



  get model(): Category {

    if (this.modelService.catalogService.category &&
      this.modelService.catalogService.category.osgCategoryMapping == null) {
      this.modelService.catalogService.category.osgCategoryMapping = new OsgCategoryMapping();
      this.modelService.catalogService.category.osgCategoryMapping.productRange = "";
      this.modelService.catalogService.category.osgCategoryMapping.productRangeTest = "";
      this.modelService.catalogService.category.osgCategoryMapping.lastSend = null;
      this.modelService.catalogService.category.osgCategoryMapping.lastSendTest = null;
    }

    return this.modelService.catalogService.category;
  }




  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get productRange(): string {
    if (this.model.osgCategoryMapping)
      return this.model.osgCategoryMapping.productRange;
    return "";
  }

  get lastSend(): string {
    if (this.model.osgCategoryMapping && this.model.osgCategoryMapping.lastSend)
      return this.model.osgCategoryMapping.lastSend.toLocaleString("de-DE");
    return "";
  }

  get productRangeTest(): string {
    if (this.model.osgCategoryMapping)
      if (this.model.osgCategoryMapping.productRangeTest)
        return this.model.osgCategoryMapping.productRangeTest;
    return "";
  }

  get lastSendTest(): string {
    if (this.model.osgCategoryMapping && this.model.osgCategoryMapping.lastSendTest)
      return this.model.osgCategoryMapping.lastSendTest.toLocaleString("de-DE");
    return "";
  }



  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
