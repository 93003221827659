import { Component } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { RequestPassword } from "../../Model/User/RequestPassword";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "forgotPassword",
  templateUrl: "forgotPassword.component.html",
  styleUrls: ["forgotPassword.component.css"]
})
export class ForgotPasswordComponent {

  constructor(private modelService: ModelService, public translate: TranslateService) {

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Neues Password anfordern");

  }

  model = new RequestPassword();

  onRequestPassword() {
    this.modelService.loginService.requestPassword(this.model)
      .subscribe(success => {
        if (success) {
          let translation = "";
          this.translate.get('Wir haben Ihnen per mail ein neues Passwort zugeschickt').subscribe((text: string) => {
            translation = text;
            this.modelService.systemService.notify(new Message(translation));
            this.modelService.router.navigate(["login"]);
          });



        }
      },
        err => {
          let translation = "";
          this.translate.get('Fehler beim Passwort anfordern').subscribe((text: string) => {
            translation = text;
            this.modelService.systemService.notify(new Message(translation, "error"));
          });
        });
  }
}
