import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginService } from './login.service';
import { OsgSender } from "../Model/osg/osgSender";




@Injectable()
export class OsgSenderService {

  private _OsgSenders?: OsgSender[];
  private _selectedSet?: OsgSender;

  osgSenders: OsgSender[];


  get selectedSet(): OsgSender {
    return this._selectedSet;
  }

  set selectedSet(value: OsgSender) {
    this._selectedSet = value;
  }



  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getSingle(id: string): Observable<OsgSender> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("OsgSenderId", id).append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<OsgSender>("api/OsgSender/GetSingle", options));
  }

  getAll(): Observable<OsgSender[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<OsgSender[]>("api/OsgSender/GetAll", options));
  }



  deleteSingle(id: string) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", id)
      }

      this.http.delete("api/OsgSender/DeleteSingle", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateSingle(item: OsgSender): Observable<OsgSender> {
    return (this.http.post<OsgSender>("api/OsgSender/UpdateSingle", item));
  }

  addSingle(item: OsgSender): Observable<OsgSender> {
    return (this.http.post<OsgSender>("api/OsgSender/AddSingle", item));
  }

  startGenerationJob(item: OsgSender, isTest: boolean): Observable<OsgSender> {
    item.isTest = isTest;
    return (this.http.post<OsgSender>("api/OsgSender/StartGenerationJob", item));
  }
}
