export class OdooConnection {

  constructor(
    public baseUri?: string,
    public user?: string,
    public database?: string,
    public apiKey?: string,
    public catalogCategoryId?: number
  ) {
  }
}

