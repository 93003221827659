
import { Component } from "@angular/core";
import { NewCatalog } from "../../../Model/Dto/NewCatalog";
import { ModelService } from "../../../Services/model.service";
import { Message } from "../../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "newCatalog",
  templateUrl: "newCatalog.component.html"
})
export class NewCatalogComponent {

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.model = new NewCatalog();
    this.model.customerId = this.modelService.loginService.currentUser.customerId;
  }

  model: NewCatalog;

  onAddCatalog() {
    this.modelService.catalogService.addCatalog(this.model).subscribe(success => {
        if (success) {
          this.modelService.router.navigate(["/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + this.modelService.catalogService.selectedCatalogId + '/info']);
        }
      },
      err => this.translate.get("Unbekannter Fehler").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text, "error"));
      }));;
  }
}
