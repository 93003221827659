<div style="max-width: 100%;">
  <dx-data-grid class="h-100"
                [dataSource]="ftpExportSettings"
                [remoteOperations]="false"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                [showBorders]="true"
                [showColumnHeaders]="true"
                mode="multiple"
                (onRowUpdated)="onRowUpdated($event)"
                (onRowRemoved)="onRowRemoved($event)"
                (onRowInserted)="onRowInserted($event)">

    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing mode="cell" [allowUpdating]="isManager()" [allowDeleting]="isManager()" [allowAdding]="isManager()"> </dxo-editing>

    <dxi-column dataField="catalogId" caption="{{'Katalog'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="catalogs" valueExpr="id" displayExpr="catalogName"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="ftpServerId" caption="{{'Ftp Server'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="ftpServers" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="exportConfigId" caption="{{'ExportConfiguration'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="exportConfigs" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="time" dataType="datetime" caption="{{'Uhrzeit'|translate}}"
                [calculateDisplayValue]="getTime"
                format="HH:mm"
                [editorOptions]="{ type:'time', displayFormat:'HH:mm',pickerType:'rollers',intervall:'5'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let column of 'detail'">
      <div class="dx-fieldset">
        <div *ngIf="column.data.exportFormat && column.data.exportFormat != ''" class="dx-field">
          <div class="dx-field-label">{{ 'Export Format' | translate }} </div>
          <div class="dx-field-warning" style="color: red; white-space: pre-wrap;">
            {{ 'Diese Einstellung ist veraltet und überschreibt die Auswahl aus der Export Configuration.' | translate }}
            {{ 'Es wird empfohlen nur noch die ExportConfiguration zu nutzen.' | translate }}
          </div>
          <div class="dx-field-value">
            <dx-select-box [(value)]="column.data.exportFormat"
                           [showClearButton]="!readOnly"
                           [readOnly]="readOnly"
                           [searchEnabled]="true"
                           [dataSource]="exportFormats"
                           (onValueChanged)="updateSetting(column.data)">
            </dx-select-box>
          </div>
        </div>

        <div *ngIf="column.data.excelTemplateId > 0" class="dx-field">
          <div class="dx-field-label">{{ 'Excel Template' | translate }}</div>
          <div class="dx-field-warning" style="color: red; white-space: pre-wrap;">
            {{ 'Diese Einstellung ist veraltet und überschreibt die Auswahl aus der Export Configuration.' | translate }}
            {{ 'Es wird empfohlen nur noch die ExportConfiguration zu nutzen.' | translate }}
          </div>
          <div class="dx-field-value">
            <dx-select-box [(value)]="column.data.excelTemplateId"
                           [showClearButton]="!readOnly"
                           [readOnly]="readOnly"
                           [searchEnabled]="true"
                           valueExpr="id"
                           displayExpr="name"
                           [dataSource]="excelTemplates"
                           (onValueChanged)="updateSetting(column.data)">
            </dx-select-box>
          </div>
        </div>


        <div class="dx-field">
          <div class="dx-field-label">{{ 'Datei Name (leer für generierten Namen)' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="column.data.fileName"
                         [readOnly]="readOnly"
                         [showClearButton]="!readOnly"
                         (onValueChanged)="updateSetting(column.data)">
            </dx-text-box>
          </div>
        </div>



        <div class="dx-field w-100">
          <div class="dx-fieldset w-100">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'TimeStamp anhängen' | translate }}</div>
              <div class="dx-field-value">
                <dx-switch [(value)]="column.data.setTimeStamp"
                           [readOnly]="readOnly"
                           (onValueChanged)="updateSetting(column.data)">
                </dx-switch>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">{{ 'Dateiendung anhängen' | translate }}</div>
              <div class="dx-field-value">
                <dx-switch [(value)]="column.data.addFileExtension"
                           [readOnly]="readOnly"
                           (onValueChanged)="updateSetting(column.data)">
                </dx-switch>
              </div>
            </div>

            <div *ngIf="column.data.setTimeStamp" class="dx-field">
              <div class="dx-field-label">{{ 'TimeStampFormat (leer für YYYY-MM-DD)' | translate }}</div>
              <div class="dx-field-info" style="white-space: pre-wrap;">
                <p>{{ 'Hier finden Sie Informationen wie ein TimeStamp aufgebaut werden kann.' | translate }}</p>
                <p>
                  <a href="https://learn.microsoft.com/en-us/dotnet/standard/base-types/standard-date-and-time-format-strings" target="_blank">Microsoft Dokumentation</a>
                </p>
              </div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="column.data.timeStampFormat"
                             [readOnly]="readOnly"
                             [showClearButton]="!readOnly"
                             (onValueChanged)="updateSetting(column.data)">
                </dx-text-box>
              </div>
            </div>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">{{ 'Letzte Ausfuehrung' | translate }}</div>
          <div class="dx-field-value">
            <dx-date-box type="datetime"
                         [value]="column.data.lastRun"
                         [readOnly]="true">
            </dx-date-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
            <dx-button style="text-align: right; float: right;" text="{{'Manuel ausführen'|translate}}" (onClick)="run(column.data)" icon="redo"> </dx-button>
          </div>
        </div>
      </div>
    </div>
  </dx-data-grid>
</div>
