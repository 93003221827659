import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { TestResult } from '../../Model/Dto/TestResult';
import { StartShopWareExport } from '../../Model/Dto/StartShopWareExport';
import { Message } from "../../Model/System/Message";
import { UserManagementService } from "../../Services/userManagment.service";

@Component({
  selector: "catalogShopware",
  templateUrl: "catalogShopware.component.html",
  styleUrls: []
})
export class CatalogShopwareComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.establishedShopConnectionOne = this.establishedShopConnectionOne.bind(this);
    this.establishedShopConnectionTwo = this.establishedShopConnectionTwo.bind(this);
    this.establishedShopConnection = this.establishedShopConnection.bind(this);
    this.establishedShopConnectionZero = this.establishedShopConnectionZero.bind(this);
    this.deleteShopOne = this.deleteShopOne.bind(this);
    this.deleteShopTwo = this.deleteShopTwo.bind(this);
    this.deleteShopThree = this.deleteShopThree.bind(this);
    this.deleteShopFour = this.deleteShopFour.bind(this);
    this.testShopConnection = this.testShopConnection.bind(this);
   
    this.sendData2Shop = this.sendData2Shop.bind(this);
    this.sendData2ShopZero = this.sendData2ShopZero.bind(this);
    this.sendData2ShopOne = this.sendData2ShopOne.bind(this);
    this.sendData2ShopTwo = this.sendData2ShopTwo.bind(this);
    this.deleteShop = this.deleteShop.bind(this);
    this.deleteShopZero = this.deleteShopZero.bind(this);
  }

  ngOnInit(): void {
    this.reset();

  }

  reset() {
    if (this.modelService.loginService.hasAddonShopWare && this.modelService.catalogService.catalog != null && this.modelService.catalogService.catalog.isPublic) {
      this.testShopConnection();
      
    }
  }

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  //SHOP

  catalogChangedinclShop(event) {
    this.modelService.catalogService.resetConnection();
    this.catalogChanged(event);
  }

  testShopConnection() {
    this.modelService.catalogService.testShopConnection();
  }

  isEstablishedShopConnectionPopupVisible = false;

  establishedShopConnectionZero() {
    this.establishedShopConnection(0);
  }

  establishedShopConnectionOne() {
    this.establishedShopConnection(1);
  }

  establishedShopConnectionTwo() {
    this.establishedShopConnection(2);
  }

  deleteShopOne() {
    this.deleteShop(1);
  }

  deleteShopTwo() {
    this.deleteShop(2);
  }

  deleteShopThree() {
    this.deleteShop(3);
  }

  deleteShopFour() {
    this.deleteShop(4);
  }

  establishedShopConnection(num = 0) {
    switch (num) {
      case 0:
        this.isEstablishedShopConnectionPopupVisible = true;
        break;
      case 1:
        this.isEstablishedShopConnectionPopupVisible = false;
        this.modelService.catalogService.establishedShopConnection();
        break;
      default:
        this.isEstablishedShopConnectionPopupVisible = false;
        break;
    }
  }

  sendPrices: boolean = true;
  isSendCatalogPopupVisible = false;


  sendData2ShopOne() {
    this.sendData2Shop(1);
  }

  sendData2ShopTwo() {
    this.sendData2Shop(2);
  }

  sendData2ShopZero() {
    this.sendData2Shop(0);
  }

  sendData2Shop(num = 0) {
    switch (num) {
      case 0:
        this.isSendCatalogPopupVisible = true;
        break;
      case 1:
        this.isSendCatalogPopupVisible = false;
        let startShopWareExport = new StartShopWareExport();
        startShopWareExport.catalogId = this.modelService.catalogService.selectedCatalogId;
        startShopWareExport.customerId = this.modelService.loginService.currentUser.customerId;
        startShopWareExport.sendPrices = this.sendPrices;
        this.modelService.shopService.startExport(startShopWareExport).subscribe(success => {
          if (success) {
            this.translate.get("Die Übertragung wurde gestartet").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text));
            });
          }
        });
        break;
      default:
        this.isSendCatalogPopupVisible = false;
        break;
    }
  }

  isDeleteShopPopupVisible = false;

  deleteShopZero() {
    this.deleteShop(0);
  }

  deleteShop(num = 0) {
    switch (num) {
      case 0:
        this.isDeleteShopPopupVisible = true;
        break;
      case 1:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "pro").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      case 2:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "cat").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      case 3:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "mime").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      default:
        this.isDeleteShopPopupVisible = false;
        break;
    }
  }

  public deleteShopResult(result: TestResult) {
    if (result.success) {
      this.translate.get("Daten erfolgreich gelöscht").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text));
      });
    } else {
      this.translate.get("Daten nicht gelöscht").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text, "error"));
      });
    }

  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
