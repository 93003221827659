import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { TranslateService } from "@ngx-translate/core";
import { ValidFeatureService } from "../../../../Services/validFeature.service";
import { ValidFeatureSystem } from "../../../../Model/ValidFeatureSystem";

@Component({
  selector: "userAction-deleteFeatureSystems",
  templateUrl: "userAction-deleteFeatureSystems.component.html"
})
export class UserActionDeleteFeatureSystemsComponent extends UserActionFeatureSystemsComponent implements OnInit {
  selectedFeatureSystems: ValidFeatureSystem[];

  constructor(public modelService: ModelService, validFeatureService: ValidFeatureService, private translate: TranslateService) {
    super(modelService, validFeatureService);
  }

  async ngOnInit() {
    await super.ngOnInit()
  }

  startMacro() {
    if (!this.selectedFeatureSystems || this.selectedFeatureSystems.length <= 0) {
      this.modelService.systemService.notifyInfo(this.translate.instant("mindestens ein FeatureSystem auswählen"));
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "DeleteFeatureSystems";
    startAction.actionParameter = JSON.stringify(this.selectedFeatureSystems);

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });

  }
}
