<!--Logins-->

<dx-data-grid class="h-100"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="logins"
              [remoteOperations]="false"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Logins vorhanden' | translate }}"
              [showBorders]="true">
  <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
    <dx-button stylingMode="text" type="default" text="{{ 'Aktualisieren' | translate }}" (onClick)="reloadLogins()" hint="{{ 'Aktualisieren' | translate }}"></dx-button>
  </div>
  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <dxo-paging [pageSize]="1000"> </dxo-paging>
  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Logins suchen' | translate }}" class="treeSearch"></dxo-search-panel>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="AdminLoginsStateNewUIV2"></dxo-state-storing>

  <!--<dxi-column dataField="customerId" caption="letzte KNR#" [groupIndex]="0"></dxi-column>-->
  <dxi-column dataField="lastCustomer" caption="{{ 'letzter Kunde' | translate }}" dataType="string"></dxi-column>

  <dxi-column dataField="userName" caption="{{ 'Benutzername' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="firstName" caption="{{ 'Vorname' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="lastName" caption="{{ 'Nachname' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="lastLogin" caption="{{ 'letzter Login' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy HH:mm:ss' }" [width]="150"></dxi-column>

</dx-data-grid>
