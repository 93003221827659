import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'manufacturerFilter',
  templateUrl: './manufacturerFilter.component.html',
  styleUrls: ['./manufacturerFilter.component.css']
})
export class ManufacturerFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  manufacturerFilters: { name: string }[] = [];
  isFilterLoaded: boolean = false;

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public exportFilterService: ExportFilterService
  ) {
    this.refreshManufacturersFilter = this.refreshManufacturersFilter.bind(this);
  }

  async ngOnInit() {
    if (this.exportParameter.manufacturerFilter == null)
      this.exportParameter.manufacturerFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.manufacturerFilter.filterKey == null)
      this.exportParameter.manufacturerFilter.filterKey = [];

    await this.loadmanufacturerIfNeeded();
  }

  async loadmanufacturerIfNeeded() {
    if (this.exportParameter.manufacturerFilter.useFilter) await this.refreshManufacturersFilter();
  }

  async refreshManufacturersFilter() {
    this.isFilterLoaded = false;
    const names = await lastValueFrom(
      this.exportFilterService.getManufacturerFilters(
        this.catalogId,
        this.modelService.loginService.currentUser.customerId
      )
    );
    this.manufacturerFilters.length = 0;
    this.manufacturerFilters.push(
      ...names.map((x) => {
        return { name: x };
      })
    );

    this.isFilterLoaded = true;
  }
}
