import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { Customer } from "../../../../Model/User/Customer";
import { Catalog } from "../../../../Model/Catalog/Catalog";


@Component({
  selector: "userAction-copyMediaFromOtherCatalog",
  templateUrl: "userAction-copyMediaFromOtherCatalog.component.html"
})

export class UserActionCopyMediaFromOtherCatalogComponent extends UserActionBaseComponent implements OnInit {

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important

    this.modelService.loginService.customerService.getAllCustomerByUser(this.modelService.loginService.currentUser.id).subscribe((customers: Customer[]) => {
      this.customers = customers;
      this.selectedCustomer = customers[0].id;
    });
  }

  _selectedCustomer: string;
  catalogs: Catalog[];
  _sourceCatalog: string = "";
  customers: Customer[];
  replacePrice: boolean = false;

  get selectedCustomer(): string {
    return this._selectedCustomer;
  }

  set selectedCustomer(value: string) {
    this._selectedCustomer = value;
    this.modelService.catalogService.getCatalogs(this._selectedCustomer)
      .subscribe((result: Catalog[]) => {
        this.catalogs = result.filter(c => !c.isVirtual);
      });
  }

  get sourceCatalog(): string {
    return this._sourceCatalog;
  }

  set sourceCatalog(sourceCompareCatalog: string) {
    this._sourceCatalog = sourceCompareCatalog;
  }

  startMacro() {
    if (this.sourceCatalog == "") {
      return;
    }
    if (this.sourceCatalog == this.modelService.catalogService.catalog.id) {
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;

    startAction.actionId = "CopyMediaFromOtherCatalog(" + this.selectedCustomer + ":" + this.sourceCatalog + ")";
    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        //additional step
        this.sourceCatalog = "";


        this.userActionStarted.emit(next);
      });
  }
}
