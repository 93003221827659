<div style="align-content: center; height: 100%">
  <dx-button
    id="upradeInfoButton{{ timestamp }}"
    icon="arrowup"
    stylingMode="contained"
    (onClick)="togglePopover($event)"
    type="default"
    style="background-color: rgb(255, 122, 89); min-width: 25px; place-content: center"
    [width]="25"
    [height]="25"
    [disabled]="disabled"
  />
</div>
<dx-popover
  target="#upradeInfoButton{{ timestamp }}"
  [(visible)]="popoverVisible"
  [shading]="false"
  width="575px"
  height="auto"
  position="left"
>
  <div style="padding: 16px; text-align: center">
    <p style="margin-bottom: 16px">
      {{ infoText | translate }}
    </p>
    <p *ngIf="additionalInfo">
      {{ additionalInfo }}
    </p>

    <dx-button text="{{ 'Vertrieb kontaktieren' | translate }}" type="default" (onClick)="contact()"></dx-button>
  </div>
</dx-popover>
