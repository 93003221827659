<fullscreen-page> 
  <content-column look="dark">    
    <div slot="content" class="h-100 p-2">
      <div class="h-100 w-100 mx-auto">
        <dx-data-grid id="gridContainer"
                      class="h-100"
                      (onToolbarPreparing)="onToolbarPreparing($event)"
                      [dataSource]="model"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [allowColumnResizing]="true"
                      [showBorders]="true"
                      (onRowUpdated)="onCsChanged($event)"
                      (onRowRemoved)="onCsRemoved($event)">

          <dxo-editing mode="cell"
                      [allowUpdating]="true"
                      [allowDeleting]="true"
                      [allowAdding]="false"
                      [useIcons]="true">
            <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie die Klassifikation wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Klassifikation löschen' | translate }}"></dxo-texts>
          </dxo-editing>

          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>

          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" class="treeSearch"></dxo-search-panel>

          <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ClassificationSystemStateNewUIV2"></dxo-state-storing>

          <div *dxTemplate="let toolbaritem of 'addClassificationButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Neue Klassifizierung' | translate }}" (onClick)="onInsertCs()" hint="{{ 'Neues Klassifikationssystem' | translate }}"></dx-button>
          </div>

          <dxi-column dataField="id" caption="" [allowEditing]="false" [width]="30" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column>
          <div *dxTemplate="let d of 'cellTemplate'">
            <a (click)="onEditDetailsClicked(d.data)" class="dx-link-edit dx-icon-edit link" title="{{ 'Klassifikation editieren' | translate }}"></a>
          </div>
          <dxi-column dataField="name" caption="{{ 'Klassifizierungssystem' | translate }}" [allowEditing]="true"></dxi-column>
          <dxi-column dataField="version" caption="{{ 'Version' | translate }}" [allowEditing]="false" [width]="65" [allowFiltering]="false" [allowSorting]="false" ></dxi-column>
        
          <dxi-column dataField="date" caption="{{ 'Datum' | translate }}" [allowEditing]="false" [width]="90" [allowFiltering]="false" [allowSorting]="false" ></dxi-column>
      
          <dxi-column dataField="language" caption="{{ 'Sprache' | translate }}" [allowEditing]="false" [width]="80" [allowFiltering]="false" [allowSorting]="false" ></dxi-column>
    
        </dx-data-grid>
      </div>
    </div>
  </content-column>
</fullscreen-page>
