import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ValidFeature } from "../Model/ValidFeature";
import { ValidFeatureValue } from "../Model/ValidFeatureValue";
import { TranslateService } from "@ngx-translate/core";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValidFeatureService } from "../Services/validFeature.service";
import { RowUpdatedEvent } from "devextreme/ui/data_grid";
import { LoginService } from "../Services/login.service";


@Component({
  selector: "validFeature",
  templateUrl: "validFeature.component.html"
})
export class ValidFeatureComponent {
  @Input() ValidFeatures: ValidFeature[];
  @Output() ValidFeaturesChange = new EventEmitter<ValidFeature[]>();

  validFeatureValues: ValidFeatureValue[] = [];
  types: string[] = ["String", "Boolean", "Number", "Double"];

  constructor(public modelService: ModelService, public translate: TranslateService, public validFeatureService: ValidFeatureService, public loginService: LoginService) {

  }

  async updateValidFeatureValue(event: RowUpdatedEvent) {
    await this.validFeatureService.updateValidFeature(event.data, this.loginService.currentCustomer.id);
    this.ValidFeaturesChange.emit(this.ValidFeatures);
  }

}
