<!--51 Update to Catalog-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Account' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedCustomer?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT</label>
    <dx-select-box
      [(value)]="selectedCustomer"
      [dataSource]="customers"
      displayExpr="name"
      [searchEnabled]="true"
      valueExpr="id"
      placeholder=""
    ></dx-select-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Zielkataloge' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedCatalogs?.length > 0 ? 'placeLabelSmall' : '' }}">
      {{ 'Zielkataloge auswählen' | translate }}
    </label>
    <dx-drop-down-box
      id="catalogsBox"
      [(value)]="selectedCatalogs"
      valueExpr="id"
      displayExpr="catalogName"
      [showClearButton]="true"
      [dataSource]="catalogs"
    >
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid
          [dataSource]="catalogs"
          keyExpr="id"
          [columns]="['catalogName']"
          [selection]="{ mode: 'multiple' }"
          [hoverStateEnabled]="true"
          [paging]="{ enabled: true, pageSize: 10 }"
          [filterRow]="{ visible: true }"
          [scrolling]="{ mode: 'infinite' }"
          [height]="345"
          [(selectedRowKeys)]="selectedCatalogs"
        ></dx-data-grid>
      </div>
    </dx-drop-down-box>

    <dx-load-panel
      #loadPanel
      shadingColor="rgba(0,0,0,0.4)"
      [position]="{ of: '#catalogsBox' }"
      [(visible)]="loading"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="false"
    ></dx-load-panel>
    <dx-button
      stylingMode="contained"
      text="{{ 'Passende Kataloge auswählen' | translate }}"
      type="normal"
      (onClick)="clickSelectValidCatalogs()"
    ></dx-button>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Update Template' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <div [ngStyle]="{ display: 'flex', 'align-items': 'center' }">
      <dx-select-box
        [(value)]="selectedTemplate"
        [dataSource]="updateTemplates"
        displayExpr="name"
        [searchEnabled]="true"
        valueExpr="id"
        placeholder=""
        label="UPDATE TEMPLATE"
        [ngStyle]="{ width: '100%' }"
      ></dx-select-box>
      <dx-button icon="edit" (onClick)="showEditTemplates()"></dx-button>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Filter' | translate }}</div>
  <div class="dx-field-value">
    <np-export-parameter-selector
      [(selectedExportParameterId)]="selectedExportParameterId"
      [onlyUpdateFilter]="true"
    ></np-export-parameter-selector>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Schlüssel' | translate }}</div>
  <div class="dx-field-value">
    <dx-select-box [(value)]="selectedKey"
                   [dataSource]="keys"
                   displayExpr="translated"
                   valueExpr="value">
    </dx-select-box>
  </div>
</div>

<np-dialog
  [popupWidth]="1200"
  [popupHeight]="800"
  [popupTitle]="showPopup ? ('Update Templates' | translate) : ('Update Templates' | translate)"
  [(popupVisible)]="showPopup"
>
  <div slot="content" class="h-100 d-flex flex-row">
    <updateTemplates [helpSwitch]="false"></updateTemplates>
  </div>
</np-dialog>
