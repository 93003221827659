export class TranslateCatalog {

  constructor(
    public languageCode?: string,
    public customerId?: string,
    public catalogId?: string,
    public useDeepL?: boolean
  ) {
  }
}
