export class ExportTranslation {

  constructor(
  
    public customerId?: string,
    public targetLanguageCode?: string,

  ) {
  }
}
