import { Component, OnInit } from "@angular/core";
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { WawiSettings } from "../../Model/User/WawiSettings";
import { CustomField } from "../../Model/User/CustomField";
import { custom } from 'devextreme/ui/dialog';
import { WawiListService } from "../../Services/wawiList.service";
import { WawiList } from "../../Model/wawi/WawiList";
import { LoginService } from "../../Services/login.service";



@Component({
  selector: "wawiSettings",
  templateUrl: "wawiSettings.component.html"
})
export class WawiSettingsComponent implements OnInit {

  pages = [];
  fieldTypes = new Array<string>();
  lists: WawiList[];
  constructor(public modelService: ModelService, public translate: TranslateService, public wawiListService: WawiListService, public loginService: LoginService) {

    let supplierPidText = "";
    supplierPidText = this.translate.instant("Artikel");
    let customFieldsText = "";
    customFieldsText = this.translate.instant("Individuelle Felder");

    this.pages = [
      { id: 1, title: supplierPidText, template: "supplierPidTemplate" },
      { id: 2, title: customFieldsText, template: "customFieldsTextTemplate" }
    ];
    this.fieldTypes.push("String");
    this.fieldTypes.push("Number");
    this.fieldTypes.push("Boolean");
    this.fieldTypes.push("List");
    this.fieldTypes.push("Mapping");
    this.fieldTypes.push("Html");
    

    wawiListService.getAllLists(this.modelService.loginService.currentCustomer.id).subscribe(result => {
      this.lists = result;
    });
    
  }
 
  public onRowRemoving(e) {
    let order = e.data.order;

    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Wollen Sie das Feld wirklich in allen Katalogen am Account löschen").subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith("?")) {  
        txtMsg = txtMsg + "?";
      }
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => { return false; }
        },
        {
          text: txtNo,
          onClick: (e) => { return true; }
        }
      ]
    });

    e.cancel = myDialog.show();
  }


  delete(event = null) {


    this.update(event);
   
  }


  ngOnInit(): void { 
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
  }

  onSystemToolbarPreparing(e) {
    if(this.modelService.loginService.hasAddonGevis){
     e.toolbarOptions.items.unshift({
       location: 'before',
       locateInMenu: 'auto',
       template: 'gevisButtonTemplate',
     });
    }
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newFieldButtonTemplate',
    });
  }

  addCustomField() {
    const customField = new CustomField();
    let order = 0;
    let index = 0;
    for (const field of this.model.customFields) {
      if (field.order > order) {
        order = field.order;
        index++;
      }
    }
    order += 1;
    customField.order = order;

    this.model.customFields.push(customField);
    customField.name = "CustomField" + order;
    this.updateWithRefresh();
   
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER")
      return false;
    if (this.modelService.loginService.currentUser.currentClaim == "READONLY")
      return false;
    return true;
    }

   

   get model(): WawiSettings {
     return this.modelService.loginService.wawiSettings;  
   }
  set model(value: WawiSettings) {
    this.modelService.loginService.wawiSettings = value;
   }



  

  update(e) {
    this.modelService.wawiSettingsService.update(this.model).subscribe(
      (result: number) => {
        this.model.id = result;
    
      });;
  }

  updateWithRefresh() {


    this.modelService.wawiSettingsService.update(this.model).subscribe(
      (result: number) => {
        this.modelService.wawiSettingsService.getWawiSettings(this.model.customerId).subscribe(
          (result: WawiSettings) => {
            this.model = result;
          });
      });;
  }




  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  generateGevisFields(){
    this.addFieldIfNotExist("Obergruppencode");
    this.addFieldIfNotExist("Gruppencode");
    this.addFieldIfNotExist("Untergruppencode"); 
    this.addFieldIfNotExist("Artikelkonditionsgruppencode");
    this.updateWithRefresh();
  }

  addFieldIfNotExist(newName: string) {
    if (this.model.customFields.findIndex(x => x.name == newName) < 0) {
      this.addField(newName);
    }
  }

  addField(name:string){
    const customField = new CustomField(  );
    let order = 0;
    let index = 0;
    for (const field of this.model.customFields) {
      if (field.order > order) {
        order = field.order;
        index++;
      }
    }
    order += 1;
    customField.order = order;

    this.model.customFields.push(customField);
    customField.name = name;
    
  }


}


