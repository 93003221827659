import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from "@ngx-translate/core";
import { OsgSenderService } from "../Services/osgSender.service";
import { OsgSender } from "../Model/osg/osgSender";



@Component({
  selector: "osgSenders",
  templateUrl: "osgSenders.component.html"
})
export class OsgSendersComponent implements OnInit {


  constructor(public modelService: ModelService, public osgSenderService: OsgSenderService, public translate: TranslateService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Multishop Export");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addButtonTemplate'
    });
  }

  ngOnInit(): void {
    this.osgSenderService.getAll().subscribe(
      result => {
        this.osgSenderService.osgSenders = result;
      });
  }

  onSelectionChangedClick(e: { data: OsgSender }) {
    this.osgSenderService.getSingle(e.data.id).subscribe(
      result => {
        this.osgSenderService.selectedSet = result;
      });
  }
  onDeleteClicked(e: { data: OsgSender }) {
    this.osgSenderService.deleteSingle(e.data.id);
  }

  addSingle() {

    let OsgSender = {
      customerId: this.modelService.loginService.currentUser.customerId
    } as OsgSender;

    this.osgSenderService.addSingle(OsgSender).subscribe(
      result => {
        this.osgSenderService.selectedSet = result;
        this.osgSenderService.osgSenders.push(result);
      });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }


}
