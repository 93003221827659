<dx-data-grid [dataSource]="dataSourceUsers" [allowColumnReordering]="true" [allowColumnResizing]="true" 
    (onCellHoverChanged)="onCellHoverChanged($event)" (onInitNewRow)="onInitNewRow($event)">
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="MyAccountUsersStateNewUIV2"></dxo-state-storing>
    <dxo-editing mode="popup" refreshMode="full" [selectTextOnEditStart]="true" allowUpdating="canEdit()"
        [allowDeleting]="loginService.isManager" [allowAdding]="canAddUser()">
        <dxo-popup title="User" [showTitle]="true"></dxo-popup>
        <dxo-form>
            <dxi-item dataField="email"></dxi-item>
            <dxi-item dataField="firstName"></dxi-item>
            <dxi-item dataField="lastName"></dxi-item>
            <dxi-item dataField="currentClaim"></dxi-item>
            <dxi-item dataField="canAccessServicePortal" editorType="dxSwitch">
                <dxo-label location="left" template="canAccessServicePortal">{{ 'Zugriff Service Portal' | translate}}</dxo-label>
            </dxi-item>
          
            <div *dxTemplate="let data of 'canAccessServicePortal'">
                <div id="template-content">
                    <i id="helpedInfo" class="dx-icon dx-icon-info"></i>
                    {{ data.text }}
                </div>
            
                <dx-tooltip
                    target="#helpedInfo"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <div *dxTemplate="let data = data; of: 'content'">
                        <div id="tooltip-content">
                            {{"Zugriffsberechtigte Nutzer können auf service.nextpim.de eigene Tickets und die des Unternehmens einsehen." | translate}}
                        </div>
                    </div>
                </dx-tooltip>
            </div>
        </dxo-form>
    </dxo-editing>

    <dxi-column dataField="email" caption="{{ 'Email' | translate }}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="firstName" caption="{{ 'Vorname' | translate }}"></dxi-column>
    <dxi-column dataField="lastName" caption="{{ 'Nachname' | translate }}"></dxi-column>
    <dxi-column dataField="currentClaim" caption="{{ 'Role' | translate }}" [allowEditing]="loginService.isManager">
        <dxo-lookup [dataSource]="claims" searchEnabled="true"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="canAccessServicePortal" dataType="boolean" caption="{{ 'Zugriff Service Portal' | translate}}" [allowEditing]="loginService.isManager">
    </dxi-column>
    <dxi-column *ngIf="loginService.isAdmin" dataField="lastLogin"
        caption="{{ 'letzter Login' | translate }}"></dxi-column>
    
</dx-data-grid>

<dx-tooltip [target]="tooltipTarget" [visible]="isVisible" >
    <div *dxTemplate="let data of 'content'">
      {{ 'Zugriffsberechtigte Nutzer können auf service.nextpim.de eigene Tickets und die des Unternehmens einsehen.' | translate}}
    </div>
</dx-tooltip>
