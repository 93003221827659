<fullscreen-page>
  <content-column look="dark" helpSwitch="true">
    <div slot="content" class="p-3 flex-fill w-100 mx-auto overflow-hidden">
      <dx-data-grid id="gridContainer"
                    class="h-100"
                    [dataSource]="dataSource"
                    [remoteOperations]="false"
                    [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    [showBorders]="true"
                    [showColumnHeaders]="false"
                    mode="multiple">

        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

        <dxo-editing mode="popup"
                     [allowUpdating]="isAdmin()"
                     [allowDeleting]="isAdmin()"
                     [allowAdding]="isAdmin()">
          <dxo-popup title="Edit article"
                     [showTitle]="true"
                     width="80%"
                     height="80%">
          </dxo-popup>
          <dxo-form class="flex-fill w-100" [colCount]="1">
            <dxi-item itemType="group" [colCount]="3">
              <dxi-item dataField="headLine"></dxi-item>
              <dxi-item dataField="releaseDate" editorType="dxDateBox"></dxi-item>
              <dxi-item dataField="active" editorType="dxSwitch"></dxi-item>
            </dxi-item>
            <dxi-item itemType="group">
              <dxi-item dataField="content"
                        editorType="dxHtmlEditor"
                        [colSpan]="3"
                        [editorOptions]="{ height: 420 }">
              </dxi-item>
              </dxi-item>
          </dxo-form>
        </dxo-editing>

        <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
        <div *dxTemplate="let article of 'detail'">
          <dx-html-editor readOnly="true" value="{{ article.data.content }}"></dx-html-editor>
        </div>
        <dxi-column  caption="new" [width]="25" [allowEditing]="false" cellTemplate="newCellTemplate"></dxi-column>

        <div *dxTemplate="let d of 'newCellTemplate'">
          <span *ngIf="needsBadge(d)" class="badge">0</span>
        </div>

        <dxi-column dataField="headLine"></dxi-column>
        <dxi-column dataField="content" [visible]="false"></dxi-column>
        <dxi-column dataField="releaseDate" dataType="date" name="releaseDateGroup" [groupIndex]="0"  sortOrder="desc"
                    [calculateGroupValue]="groupByDate"
                    format="MMM yyyy"                     
                    ></dxi-column>
        <dxi-column dataField="releaseDate" dataType="date" sortOrder="desc" alignment="right"
                    [allowEditing]="false"
                    [calculateSortValue]="calculateSortValue"></dxi-column>

        <dxi-column dataField="active" [visible]="false"></dxi-column>

      </dx-data-grid>

      <!--<dx-button text="New Article" type="default" (onClick)="clickCreate()">
      </dx-button>-->
    </div>

    <!--<dx-popup [width]="1000"
              height="auto"
              [showTitle]="true"
              title="Create article"
              [dragEnabled]="false"
              [hideOnOutsideClick]="true"
              [showCloseButton]="true"
              position="center"
              [(visible)]="popupVisible">
      <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="saveOption">
      </dxi-toolbar-item>
      <dxo-position at="bottom" my="center"> </dxo-position>
      <div *dxTemplate="let data of 'content'">
        <div class="dx-fieldset">
          <div class="dx-field ">
            <div class="dx-field-label">HeadLine</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="newArticle.headLine" placeholder="HeadLine" [showClearButton]="true"> </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Content</div>
            <div class="dx-field-value">
              <dx-text-area class="border" placeholder="Content" [(value)]="newArticle.content" [showClearButton]="true" height=140 autoResizeEnabled=true> </dx-text-area>
            </div>
          </div>
        </div>
      </div>
    </dx-popup>-->


  </content-column>
</fullscreen-page>
