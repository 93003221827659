<div *ngIf="model" class="np-form-control d-flex flex-column w-100">
  <div class="header d-flex flex-row px-3 pt-1 w-100 align-items-center">
    <div class="p-2">
      <span *ngIf="!categoryMainImage" class="glyphicon glyphicon-picture"></span>
      <img *ngIf="categoryMainImage" src="{{ categoryMainImage }}" />
    </div>
    <div class="p-2 flex-fill">
      <h5>{{ model.groupName }}</h5>
    </div>
    <div *ngIf="modelService.loginService.hasAddonVALIDATION" class="p-2">
      <dx-bar-gauge
        id="gauge"
        [values]="[0, 0, 0]"
        [startValue]="0"
        [endValue]="100"
        [palette]="['#E59D1A', '#10907E', '#1E4B98']"
      >
        <dxo-label [visible]="false"></dxo-label>
      </dx-bar-gauge>
    </div>
  </div>

  <dx-toolbar>
    <dxi-item
      *ngIf="renderMassDataChangeButton()"
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{
        type: 'default',
        text: 'MassenDatenAenderung' | translate,
        icon: 'edit',
        onClick: openMassDataChange
      }"
    ></dxi-item>
  </dx-toolbar>

  <div class="p-2">
    <dx-validation-group id="categoryValidationGroup">
      <dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="detailTabs" [swipeEnabled]="false">
        <!--INFOS-->
        <div *dxTemplate="let company of 'categoryTemplate'">
          <div class="dx-fieldset mainInfos" *ngIf="model">
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200">{{ 'Kategorie' | translate }}</div>
              <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.groupName?.length > 0 ? 'placeLabelSmall' : '' }}">GROUP NAME</label>
                <dx-text-box
                  [readOnly]="readOnly('categoryGroupName') || this.catalogService.catalog.isDemoCatalog"
                  [(value)]="model.groupName"
                  [showClearButton]="true"
                  valueChangeEvent="focusout"
                  (onValueChanged)="categoryChanged($event)"
                  [hint]="
                    this.catalogService.catalog.isDemoCatalog
                      ? 'Diese Funktion steht in der Zielstruktur nicht zur Verfügung'
                      : null
                  "
                >
                  <dx-validator validationGroup="categoryValidationGroup">
                    <dxi-validation-rule
                      type="required"
                      message="{{ 'Bitte geben Sie einen Kategorienamen an' | translate }}!"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label dx-field-label200">{{ 'Beschreibung' | translate }}</div>
              <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.groupDescription?.length > 0 ? 'placeLabelSmall' : '' }}">
                  GROUP DESCRIPTION
                </label>
                <dx-text-area
                  [readOnly]="readOnly('categoryGroupDescription')"
                  [(value)]="model.groupDescription"
                  [height]="90"
                  valueChangeEvent="focusout"
                  (onValueChanged)="categoryChanged($event)"
                ></dx-text-area>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label dx-field-label200">{{ 'Sortiernummer' | translate }}</div>
              <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.groupOrder != null ? 'placeLabelSmall' : '' }}">GROUP ORDER</label>
                <dx-number-box
                  [readOnly]="readOnly('categoryGroupOrder')"
                  [(value)]="model.groupOrder"
                  [min]="0"
                  placeholder=""
                  [showSpinButtons]="true"
                  [showClearButton]="false"
                  valueChangeEvent="focusout"
                  (onValueChanged)="categoryChanged($event)"
                ></dx-number-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label dx-field-label200">{{ 'Group Id' | translate }}</div>
              <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.groupId != null ? 'placeLabelSmall' : '' }}">GROUP ID</label>
                <dx-text-box
                  [readOnly]="readOnly('categoryGroupId')"
                  [(value)]="model.groupId"
                  [showClearButton]="false"
                  valueChangeEvent="focusout"
                  (onValueChanged)="categoryChanged($event)"
                ></dx-text-box>
              </div>
            </div>
            <div class="dx-field" *ngIf="modelService.loginService.hasAddonPRINT">
              <div class="dx-field-label dx-field-label200">{{ 'Gleichbleibende Id' | translate }}</div>
              <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <label class="placeLabel {{ model.groupId != null ? 'placeLabelSmall' : '' }}">STABLE ID</label>
                <dx-text-box [readOnly]="true" [(value)]="model.stableId" [showClearButton]="false"></dx-text-box>
              </div>
            </div>
          </div>
        </div>

        <!--Mimes-->
        <div *dxTemplate="let company of 'medienTemplate'">
          <mimes
            [(model)]="model.mimes"
            [(showToolTips)]="showToolTips"
            [(showProToolTips)]="showProToolTips"
            [(showPlaceHolders)]="showPlaceHolders"
            [(catalogId)]="modelService.catalogService.catalog.id"
            [(customerId)]="modelService.loginService.currentUser.customerId"
            (reloadNedded)="reload()"
            (onValueChanged)="categoryUpdate($event)"
            [(modelService)]="modelService"
          ></mimes>
        </div>

        <!--UDXs-->
        <div *dxTemplate="let company of 'udxTemplate'">
          <udx
            [(model)]="model.udxs"
            [(showToolTips)]="showToolTips"
            [(showProToolTips)]="showProToolTips"
            [(showPlaceHolders)]="showPlaceHolders"
            (onValueChanged)="categoryUpdate($event)"
          ></udx>
        </div>

        <div *dxTemplate="let company of 'templateTemplate'">
          <categoryTemplates></categoryTemplates>
        </div>

        <div *dxTemplate="let company of 'seoTemplate'">
          <seoComponent></seoComponent>
        </div>
        <div *dxTemplate="let company of 'osgTemplate'">
          <categoryOsg></categoryOsg>
        </div>
        <div *dxTemplate="let company of 'shopware6Template'">
          <categoryShopware6></categoryShopware6>
        </div>
      </dx-tab-panel>
    </dx-validation-group>
  </div>
</div>
