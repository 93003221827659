<dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="model"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine anderen Verpackungseinheiten' | translate }}"
              [showBorders]="true"
              (onRowRemoving)="onRowRemoving($event)"
              (onRowRemoved)="delete($event)"
              [masterDetail]="{ enabled: false, template: 'detail' }"
              (onContentReady)="onContentReady($event)"
              [customizeColumns]="customizeColumns">
  <div *dxTemplate="let toolbaritem of 'newPackagingButtonTemplate'">
    <dx-button *ngIf="renderNewPackagingButton()"
               stylingMode="text"
               [disabled]="updateLock"
               type="default"
               text="{{ 'Neue Verpackungseinheit' | translate }}"
               (onClick)="addPackaging()"></dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
    <dx-button *ngIf="!templateView" stylingMode="text" type="default" icon="refresh" (onClick)="reload()"></dx-button>
  </div>

  <dxo-editing mode="cell"
               [allowUpdating]="!readOnly('packUpdate')"
               [allowDeleting]="allowDeleting && !readOnly('mimeDelete')"
               [useIcons]="true">
    <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="PackagingState"></dxo-state-storing>

  <dxi-column dataField="order"
              caption="{{ 'Order' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packOrderTemplate"
              editCellTemplate="packOrderTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packOrderTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="false"
                       [showPlaceLabel]="pack?.data?.order != null"
                       [suppressLabel]="true"
                       [suppressLinkButton]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [suppressDragHandle]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Order' | translate }}"
                       field="ORDER"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="false"
                       [dragFilter]="dragFilter">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.order"
                         [min]="1"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly('packOrder')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, 'PACK_ORDER', pack.data.order.toString())"></dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.order"
                         [min]="1"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly('mimeOrder')"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, 'PACK_ORDER', pack.data.order.toString())"></dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>



  <dxi-column dataField="internationalPid"
              caption="{{ 'International-ID' | translate }}"
              [allowEditing]="true"
              cellTemplate="internationalPidTemplate"
              editCellTemplate="internationalPidTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'internationalPidTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.internationalPid?.length > 0"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, pack.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'International-ID' | translate }}"
                       field="INTERNATIONAL_PID"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="pack.data.internationalPid"
                       [readOnly]="readOnlyForLinkedFields(validationService.fieldNames.packInternationalPid, pack.data.order.toString()) || readOnly(validationService.fieldNames.packInternationalPid)"
                       [showClearButton]="!readOnlyForLinkedFields(validationService.fieldNames.packInternationalPid, pack.data.order.toString())"
                       (onValueChanged)="update($event, null, null, validationService.fieldNames.packInternationalPid, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packInternationalPid, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column dataField="orderUnit"
              caption="{{ 'Einheit' | translate }}"
              [allowEditing]="true"
              cellTemplate="orderUnitTemplate"
              editCellTemplate="orderUnitTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'orderUnitTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.orderUnit?.length > 0"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, pack.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Einheit' | translate }}"
                       field="UNIT"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <np-dropdown-control [fieldName]="validationService.fieldNames.packOrderUnit"
                               [readOnly]="readOnlyForLinkedFields(validationService.fieldNames.packOrderUnit) || readOnly(validationService.fieldNames.packOrderUnit)"
                               [templateView]="templateView"
                               validationGroup="ORDER"
                               [validationCallback]="validationService.validateField(validationService.fieldNames.packOrderUnit)"
                               [items]="unitDataSource"
                               [(model)]="pack.data"
                               modelProp="orderUnit"
                               (onValueChanged)="update($event, null, null, validationService.fieldNames.packInternationalPid, pack.data.order.toString())"
                               (onCustomItemCreating)="onCustomItemCreating($event, pack.data.order)">
          </np-dropdown-control>
        </div>
      </np-inputControl>
    </div>
  </div>


  <dxi-column dataField="contentQuantity"
              caption="{{ 'Menge' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packContentQuantityTemplate"
              editCellTemplate="packContentQuantityTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packContentQuantityTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.contentQuantity != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, pack.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Menge' | translate }}"
                       [field]="validationService.fieldNames.packContentQuantity"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.contentQuantity"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly(validationService.fieldNames.packContentQuantity)"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, validationService.fieldNames.packContentQuantity, pack.data.order.toString())"></dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.contentQuantity"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly(validationService.fieldNames.packContentQuantity)"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, validationService.fieldNames.packContentQuantity, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packContentQuantity, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>

          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <dxi-column dataField="depthNumber"
              caption="{{ 'Verpackungstiefe' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packdepthNumberTemplate"
              editCellTemplate="packdepthNumberTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packdepthNumberTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.depthNumber != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Verpackungstiefe' | translate }}"
                       [field]="validationService.fieldNames.packDepth"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.depthNumber"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly('validationService.fieldNames.packDepth')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, validationService.fieldNames.packDepth, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packDepth, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.depthNumber"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly(validationService.fieldNames.packDepth)"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, validationService.fieldNames.packDepth, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packDepth, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column dataField="lengthNumber"
              caption="{{ 'Verpackungslänge' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packLengthTemplate"
              editCellTemplate="packLengthTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packLengthTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.lengthNumber != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Verpackungslänge' | translate }}"
                       [field]="validationService.fieldNames.packLength"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.lengthNumber"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly('validationService.fieldNames.packLength')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, validationService.fieldNames.packLength, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packLength, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.lengthNumber"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly(validationService.fieldNames.packLength)"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, validationService.fieldNames.packLength, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packLength, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          </div>
      </np-inputControl>
    </div>
  </div>
  <dxi-column dataField="widthNumber"
              caption="{{ 'Verpackungsbreite' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packwidthTemplate"
              editCellTemplate="packwidthTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packwidthTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.widthNumber != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Verpackungsbreite' | translate }}"
                       [field]="validationService.fieldNames.packWidth"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.widthNumber"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly('validationService.fieldNames.packwidth')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, validationService.fieldNames.packwidth, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packWidth, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.widthNumber"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly(validationService.fieldNames.packwidth)"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, validationService.fieldNames.packwidth, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packWidth, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <dxi-column dataField="weightNumber"
              caption="{{ 'Verpackungsgewicht' | translate }}"
              [width]="80"
              [allowEditing]="true"
              cellTemplate="packWeightTemplate"
              editCellTemplate="packWeightTemplate"></dxi-column>
  <div *dxTemplate="let pack of 'packWeightTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="pack?.data?.weightNumber != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Verpackungsgewicht' | translate }}"
                       [field]="validationService.fieldNames.packWeight"
                       [system]="pack.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.weightNumber"
                         [showSpinButtons]="!hasFieldLinked(pack?.data?.order.toString())"
                         [readOnly]="hasFieldLinked(pack?.data?.order.toString()) || readOnly('validationService.fieldNames.packWeight')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, pack?.data, validationService.fieldNames.packWeight, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packWeight, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="pack.data.weightNumber"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly(validationService.fieldNames.packWeight)"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, pack.data, validationService.fieldNames.packWeight, pack.data.order.toString())">
            <dx-validator *ngIf="!templateView && product"
                          validationGroup="PACKAGING"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule type="async"
                                   [validationCallback]="validationService.validateField(validationService.fieldNames.packWeight, pack.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>


</dx-data-grid>

<div *ngIf="modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult?.productMimesValid" class="color3Text">
  <table>
    <tr *ngFor="let msg of validationService.validationResult?.packagingMessages">
      <td>{{ translateMessage(msg) }}</td>
    </tr>
  </table>
</div>



