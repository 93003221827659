<div class="sidebar"> </div>
<div class="desktop-view">
  <p>{{ 'www.ihr-shop.de' | translate }}</p>
  <h1>{{ metaTitel }}</h1>
  <p>{{ metaDescription }}</p>
</div>

<div class="mobile-view">
  <p>{{ 'www.ihr-shop.de' | translate }}</p>
  <h1>{{ metaTitel }}</h1>
  <p>{{ metaDescription }}</p>
</div>
