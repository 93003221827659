import { Component } from '@angular/core';
import { UserActionBaseComponent } from '../userAction-base.component';
import { StartAction } from '../../../../Model/Dto/StartAction';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../../../Services/model.service';

@Component({
  selector: 'app-useraction-assign-mime-by-supplier-pid',
  templateUrl: './useraction-assign-mime-by-supplier-pid.component.html'
})
export class UseractionAssignMimeBySupplierPidComponent extends UserActionBaseComponent {
  useContains: boolean = false;
  delimiter: string = '';
  prefix: string = '';
  postfix: string = '';

  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = 'AssignMimeBySupplierPid';
    startAction.actionParameter = JSON.stringify({
      useContains: this.useContains,
      delimiter: this.delimiter,
      prefix: this.prefix,
      postfix: this.postfix
    });

    this.modelService.jobService.startUserAction(startAction).subscribe((next) => {
      this.userActionStarted.emit(next);
    });
  }
}
