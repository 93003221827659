<np-dialog [popupWidth]="1200"
           [popupHeight]="800"
           [popupTitle]="title"
           [(popupVisible)]="visible">

  <np-help-button>
    <np-help-option text="Wiki" icon="images/Wiki.svg" link="https://knowledge.nextpim.de/grundlagen?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink#verwendung-des-template-editors"></np-help-option>
    <np-help-option text="YouTube" icon="images/YouTube.svg" link="https://www.youtube.com/watch?v=VtYAW8lhN40"></np-help-option>
  </np-help-button>


  <div slot="content" class="w-100 h-100 d-flex flex-row">

    <template-editor class="w-100 h-100"
                     [showSelector]="showSelector"
                     [exportMode]="exportMode">
    </template-editor>

    <np-dialog-button *ngIf="templateService.importJob && showPopupButtonVisible" slot="toolbar" toolbar="bottom" location="before" widget="dxButton"
                      [options]="{ text:  'Bestehendes Template übernehmen' | translate, onClick: showPopup }"></np-dialog-button>

    <np-dialog-button *ngIf="templateService.importJob" slot="toolbar" toolbar="bottom" location="after" widget="dxButton"
                      [options]="{ text:  'Importieren' | translate, onClick: startImport }"></np-dialog-button>
  </div>

</np-dialog>



<dx-popup [width]="600"
          [height]="320"
          [showTitle]="true"
          title="{{ 'Template Auswahl' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'">

    <form (submit)="sync()">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Templatename' | translate }}</div>
          <div class="dx-field-value">
            <dx-select-box #selectBox
                           [items]="templates"
                           [(value)]="selectedTemplateId"
                           valueExpr="id"
                           displayExpr="name"
                           [searchEnabled]="true"
                           noDataText="{{ 'keine Templates im Account' | translate }}">
            </dx-select-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">{{ 'Standardwerte übernehmen' | translate }}</div>
          <div class="dx-field-value">
            <div class="d-flex flex-row pt-2">
              <div class="dx-field-label switch-left">{{ "Nein" | translate }}</div>
              <dx-switch [(value)]="keepDefaultValues"></dx-switch>
              <div class="dx-field-label switch-right">{{ "Ja" | translate }}</div>
            </div>
          </div>
        </div>

        <div class="dx-field d-flex flex-row">
          <dx-button stylingMode="text" type="default" text="{{ 'Mappings Übernehmen' | translate }}" [useSubmitBehavior]="true"></dx-button>
        </div>
      </div>
    </form>

  </div>

</dx-popup>
