import { Input, Output, EventEmitter, Component, ViewChild, Optional, Self } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "translationHelper",
  templateUrl: "translationHelper.component.html"
})

export class TranslationHelperComponent {



  constructor(public translate: TranslateService) {


  }


}
