import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";

@Injectable()
export class UserManagementService {


  constructor(public loginService: LoginService) {

  }

  canView(element: string): boolean{
    if (this.loginService.currentUser == undefined)
      return false;
    return (this.loginService.currentUser.currentClaim != "READONLY");
  }

  canEdit(element: string): boolean {
    if (this.loginService.currentUser == undefined)
      return false;
    return (this.loginService.currentUser.currentClaim != "READONLY");
  }

}
