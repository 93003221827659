import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "admin",
  templateUrl: "admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {
  //public _jobs: BackgroundJob[] = [];

  pages = [
    { id: 1, title: "Jobs", template: "jobsTemplate" },
    { id: 2, title: "Logins", template: "loginsTemplate" },
    { id: 3, title: "Kunden AddOns Übersicht", template: "customerAddonsOverviewTemplate" }
  ];

  constructor(public modelService: ModelService, public translate: TranslateService) {
    //this.reload = this.reload.bind(this);
  }

  ngOnInit(): void {

    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    if (!this.modelService.loginService.isAdmin) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Administration");

    this.modelService.systemService.showMenu = false;

    //this.reload();
  }

  //onGridToolbarPreparing(e) {
  //  e.toolbarOptions.items.unshift({
  //    location: 'before',
  //    locateInMenu: 'auto',
  //    template: 'refreshButtonTemplate'
  //  });
  //}

  //reload() {
  //  this.modelService.jobService.getRunningJobs().subscribe(
  //    (jobs: BackgroundJob[]) => {
  //      this._jobs = jobs;
  //    }
  //  );
  //}

  //get jobs(): BackgroundJob[] {
  //  return this._jobs;
  //}

  //time(started, ended) {
  //  // TODO: SAME in JobListComponent ==> move into a service!!
  //  var time = (Date.parse(ended) - Date.parse(started)) / 1000;
  //  if (time < 600) {
  //    let text = this.translate.instant("Sekunden");
  //    return Math.floor(time * 10) / 10 + " " + text;
  //  }
  //  let text = this.translate.instant("Minuten");
  //  time = time / 60;
  //  return Math.floor(time) + " " + text;
  //}

  //getCatalogNameById(id: string): string {
  //  var cat = this.modelService.catalogService.catalogs.filter(catalog => catalog.id == id).shift();
  //  if (cat) {
  //    return cat.catalogName;
  //  }
  //  return "";
  //}

  //getIconClass(icon) {
  //  return JobService.getIconClass(icon);
  //}

  //set jobs(value: BackgroundJob[]) {
  //  if (!this._jobs) {
  //    this._jobs = new Array<BackgroundJob>();
  //  }
  //  if (!value) {
  //    this._jobs = new Array<BackgroundJob>();
  //  }

  //  //jobs hinzufügen/überschreiben
  //  value.forEach(job => {

  //    var existing = this._jobs.filter(u => u.id == job.id);
  //    if (existing[0]) {
  //      if (existing[0].progress !== job.progress) {
  //        existing[0].progress = job.progress;
  //      }
  //      if (existing[0].state !== job.state) {
  //        existing[0].state = job.state;
  //      }
  //      if (existing[0].started !== job.started) {
  //        existing[0].started = job.started;
  //      }
  //      if (existing[0].ended !== job.ended) {
  //        existing[0].ended = job.ended;
  //      }
  //      if (existing[0].catalogId !== job.catalogId) {
  //        existing[0].catalogId = job.catalogId;
  //      }
  //      if (existing[0].resultCatalogId !== job.resultCatalogId) {
  //        existing[0].resultCatalogId = job.resultCatalogId;
  //      }
  //      if (existing[0].resultFile !== job.resultFile) {
  //        existing[0].resultFile = job.resultFile;
  //      }
  //      if (existing[0].templateId !== job.templateId) {
  //        existing[0].templateId = job.templateId;
  //      }
  //      if (existing[0].catalogName !== job.catalogName) {
  //        existing[0].catalogName = job.catalogName;
  //      }
  //      if (existing[0].resultCatalogName !== job.resultCatalogName) {
  //        existing[0].resultCatalogName = job.resultCatalogName;
  //      }
  //      if (existing[0].userName !== job.userName) {
  //        existing[0].userName = job.userName;
  //      }
  //      if (existing[0].events.length !== job.events.length) {
  //        existing[0].events = job.events;
  //      } else {
  //        if (job.events.length > 0) {
  //          if (job.events[job.events.length - 1].message !== existing[0].events[job.events.length - 1].message) {
  //            existing[0].events = job.events;
  //          }
  //        }
  //      }
  //    } else {
  //      this._jobs.push(job);
  //    }
  //  }
  //  );

  //  while (this.jobRemove(value)) { }
  //  //jobs entfernen, welche nicht mehr vorhanden sind

  //}

  //private jobRemove(value): boolean {
  //  this._jobs.forEach(job => {
  //    var existing = value.filter(u => u.id == job.id);
  //    if (job.id > 10) {
  //      if (!existing[0]) {
  //        this._jobs = this._jobs.filter(u => u.id != job.id);
  //        return true;
  //      }
  //    }
  //  });
  //  return false;
  //}

  //onDeleteClicked(e) {
  //  this.modelService.jobService.delete(e.key.id);
  //}
}
