<div class="d-flex flex-column w-100">
  <!--Header-->
  <div *ngIf="model" class="header d-flex flex-row px-3 pt-1 w-100 align-items-center">
    <div class="p-2">
      <span *ngIf="!catalogMainImage" class="glyphicon glyphicon-picture"></span>
      <img *ngIf="catalogMainImage" src="{{catalogMainImage}}" />
    </div>
    <div class="p-2 flex-fill">
      <h5>{{model.catalogName}}</h5>
      <h6>{{model.catalogVersion ? 'Version: ' + model.catalogVersion : ''}} vom {{model.date | date : 'dd.MM.yyyy'}}</h6>
    </div>
    <div *ngIf="modelService.loginService.hasAddonDATA_QUALITY" class="p-2">
      <dx-pie-chart [dataSource]="dataQualityPieChartDataSource"
                    type="doughnut"
                    [startAngle]="90"
                    [resolveLabelOverlapping]="'shift'"
                    [palette]="dataQualityPieChartColorPalette"
                    (onPointClick)="onGroupClicked($event)">
        <dxi-series argumentField="category" valueField="productCount"></dxi-series>
        <dxo-size [width]="150" [height]="150"></dxo-size>
        <dxo-legend [visible]="false"></dxo-legend>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeDataQualityPieTooltip" />
      </dx-pie-chart>
      <dx-button class="catalogDataQualityLink" icon="login" height="30px" width="30px" (onClick)="jumpToCatalogDataQuality()" style="position: absolute; top: 5px; right: 15px; rotate: 320deg;"/>
      <dx-button *ngIf="!isDataQualityPieChartEmpty && isCatalogDataQualityUpToDate" class="refreshIconUpToDate" hint="{{ 'Datenqualität aktuell' | translate }}" icon="refresh" height="50px" width="50px" (onClick)="calculateDataQuality()" style="position: absolute; top: 57px; right: 80px;" />
      <dx-button *ngIf="isDataQualityPieChartEmpty" class="refreshIconNotCalculated" hint="{{ 'Datenqualität nicht berechnet' | translate }}" icon="refresh" height="50px" width="50px" (onClick)="calculateDataQuality()" style="position: absolute; top: 57px; right: 80px;" />
      <dx-button *ngIf="!isDataQualityPieChartEmpty && !isCatalogDataQualityUpToDate" class="refreshIconNotUpToDate" hint="{{ 'Datenqualität nicht aktuell' | translate }}" icon="refresh" height="50px" width="50px" (onClick)="calculateDataQuality()" style="position: absolute; top: 57px; right: 80px;" />
    </div>
  </div>

  <!--Details-->
  <dx-toolbar>
    <dxi-item *ngIf="renderMakrosButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Makros' | translate, onClick: showUserActions }"></dxi-item>
    <dxi-item *ngIf="renderMediaImportButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Medien importieren' | translate, onClick: showImportMedia }"></dxi-item>
    <dxi-item *ngIf="renderReimportButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Reimport' | translate, onClick: showImportCatalog }"></dxi-item>
    <dxi-item *ngIf="renderExportButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Exportieren' | translate, onClick: showExport }"></dxi-item>
    <dxi-item *ngIf="renderTranslateButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Übersetzen' | translate, onClick: showTranslate }"></dxi-item>
    <!-- Category Export Buttons -->
    <dxi-item *ngIf="renderCategoryExportButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Kategorien exportieren' | translate, onClick: showCategoryExport }"></dxi-item>
    <dxi-item *ngIf="renderCategoryImportButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Kategorien importieren' | translate, onClick: showCategoryImport }"></dxi-item>
    <dxi-item *ngIf="renderMassDataChangeButton()" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'MassenDatenAenderung' | translate, onClick: openMassDataChange }"></dxi-item>
  </dx-toolbar>
  <div class="p-2">
    <dx-validation-group (initialized)="validateCatalog" id="catalogValidationGroup">
      <dx-tab-panel [dataSource]="pages" [selectedIndex]="currentTabIndex" [swipeEnabled]="false" (onSelectionChanged)="onTabChange($event)">

        <!--INFOS-->
        <div *dxTemplate="let company of 'katalogTemplate'">
          <div class="np-form-control dx-fieldset mainInfos" *ngIf="model">

            <!--Katalogname-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(0)" (mouseleave)="ttt(0)">{{ 'Katalogname' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt0">
                <label class="placeLabel {{ model.catalogName?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_NAME</label>
                <dx-text-box id="catalogName" [readOnly]="readOnly('catalogName')" [(value)]="model.catalogName" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)">
                  <dx-validator validationGroup="catalogValidationGroup">
                    <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie einen Katalognamen an' | translate }}!"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>

              </div>
              <dx-tooltip target="#tt0" class="toolTip toolTipField" [(visible)]="v[0]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Der Katalogname wird in der Katalogübersicht angezeigt' | translate }}.
                  </p>
                  <p>
                    {{ 'Der Katalogname gibt bei nexMart die oberste Kategorie an' | translate }}.
                  </p>
                  <p>
                    {{ 'Bsp.: Herbst/Winter' | translate }}2005/2006
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Katalog Version-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">{{ 'Katalog-Version' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt1">
                <label class="placeLabel {{ model.catalogVersion?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_VERSION</label>
                <dx-text-box id="catalogVersion" [readOnly]="readOnly('catalogVersion')" [(value)]="model.catalogVersion" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-text-box>
              </div>
              <dx-tooltip target="#tt1" class="toolTip toolTipField" [(visible)]="v[1]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Die Katalogversion wird in der Katalogübersicht angezeigt' | translate }}.
                  </p>
                  <p>
                    {{ 'Format: “MajorVersion“' | translate }}.
                    {{ '“MinorVersion“ (maximal jedoch ' | translate }}xxx.yyy)
                  </p>
                  <p>
                    {{ 'Beispiel' | translate }}:<br />
                    001.120<br />
                    7.3
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Gruppierung-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(2)" (mouseleave)="ttt(2)">{{ 'Gruppierung' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt2">
                <label class="placeLabel {{ model.pimGroup?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_GROUP</label>
                <dx-autocomplete [minSearchLength]="0"
                                 [dataSource]="modelService.catalogService.catalogGroupList"
                                 [showClearButton]="true"
                                 [showDropDownButton]="true"
                                 valueChangeEvent="blur"
                                 (onValueChanged)="updateCatalogGroup()"
                                 [(value)]="model.pimGroup"
                                 [readOnly]="readOnly('catalogGroup')">
                </dx-autocomplete>
              </div>
              <dx-tooltip target="#tt2" class="toolTip toolTipField" [(visible)]="v[2]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Nach der Gruppe wird in der Katalogübersicht sortiert' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Katalog ID-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(3)" (mouseleave)="ttt(3)">{{ 'Katalog-ID' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt3">
                <label class="placeLabel {{ model.catalogId?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_ID</label>
                <dx-text-box id="catalogId" [readOnly]="readOnly('catalogId')" [(value)]="model.catalogId" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogIdUpdate($event)">
                  <dx-validator validationGroup="catalogIdValidationGroup">
                    <dxi-validation-rule type="custom" message="{{ 'Es existiert mindestens ein Katalog mit der gleichen Id!' | translate }}" [validationCallback]="validateCatalogId"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <dx-tooltip target="#tt3" class="toolTip toolTipField" [(visible)]="v[3]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Eindeutiger Identifikator des Kataloges; dieser wird normalerweise vom Lieferanten bei der ersten Katalogerstellung vergeben und verändert sich über den gesamten Lebenszyklus des Kataloges nicht' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!-- Katalog Status -->
            <div class="dx-field">
              <div class="dx-field-label">{{ 'Katalogstatus' | translate }}</div>
              <div class="dx-field-value">
                <dx-tag-box [(value)]="model.catalogState"
                            [items]="catalogStates"
                            [acceptCustomValue]="true"
                            (onValueChanged)="updateState($event, 'CATALOG_STATUS')"
                            [readOnly]="readOnly('catalogState')"
                            noDataText=""
                            placeholder="">
                  <!-- <dx-validator *ngIf="!templateView" validationGroup="catalogValidationGroup">
              <dxi-validation-rule type="custom" [validationCallback]="validationService.validateStatus"></dxi-validation-rule>
            </dx-validator> -->
                </dx-tag-box>
              </div>
            </div>

            <div class="dx-field" *ngIf="!model.isVirtual">
              <div class="dx-field-label">{{ 'Ist Masterkatalog' | translate }}</div>
              <div class="dx-field-value">
                <dx-switch [(value)]="model.isActivId" [readOnly]="readOnly('catalogVersion')" switchedOffText="{{ 'Ja' | translate }}" (onValueChanged)="catalogIdUpdate($event)" switchedOnText="{{ 'Nein' | translate }}"></dx-switch>
              </div>
            </div>
            <!--Katalog Object ID-->
            <div class="dx-field" *ngIf="modelService.loginService.isAdmin && modelService.loginService.hasAddonDEVELOPMENT">
              <div class="dx-field-label dx-field-label200">{{ 'Katalog-Object-ID' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                <dx-text-box [value]="model.id"></dx-text-box>
              </div>
            </div>

            <!--Währung-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(4)" (mouseleave)="ttt(4)">{{ 'Währungen' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt4">
                <label class="placeLabel {{ model?.currency?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_CURRENCY</label>
                <dx-autocomplete [minSearchLength]="0"
                                 [dataSource]="currencies"
                                 [showClearButton]="true"
                                 [showDropDownButton]="true"
                                 [(value)]="model.currency"
                                 valueChangeEvent="focusout"
                                 (onValueChanged)="catalogUpdate($event)"
                                 [readOnly]="readOnly('catalogPriceUnit')">
                </dx-autocomplete>
              </div>
              <dx-tooltip target="#tt4" class="toolTip toolTipField" [(visible)]="v[4]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Ein Feld aus dem BMEcat das die Währung des Katalogs als Währungscode ISO 4217 angibt (EUR)' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Datum-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(5)" (mouseleave)="ttt(5)">{{ 'Datum' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt5">
                <label class="placeLabel {{ model.date?.length>0 ? 'placeLabelSmall' : '' }}">CATALOG_DATE</label>
                <dx-date-box id="date" [readOnly]="readOnly('catalogDate')" [(value)]="model.date" displayFormat="dd.MM.yyyy" type="date" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-date-box>
              </div>
              <dx-tooltip target="#tt5" class="toolTip toolTipField" [(visible)]="v[5]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Ein Feld aus dem BMEcat' | translate }}.
                    {{ 'Es gibt an, wann der Katalog veröffentlicht wurde' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Länder-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(6)" (mouseleave)="ttt(6)">{{ 'Länder' | translate }}</div>
              <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt6">
                <label class="placeLabel {{ model?.territories?.length>0 ? 'placeLabelSmall' : '' }}">TERRITORIES</label>
                <dx-tag-box [(value)]="model.territories"
                            [items]="territories"
                            placeholder=""
                            [acceptCustomValue]="true"
                            (onValueChanged)="catalogUpdate($event)"
                            [readOnly]="readOnly('catalogCountry')">
                </dx-tag-box>
              </div>
              <dx-tooltip target="#tt6" class="toolTip toolTipField" [(visible)]="v[6]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Angabe eines Territoriums (Region, Land, Staat, Kontinent) kodiert nach ISO 3166 (DE, EU)' | translate }}
                    {{ 'Das Element legt hier fest, in welchen Territorien die Preise gültig sind und damit wo die Produkte des Produktkataloges verfügbar sind' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Language-->
            <div class="dx-field">
              <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(13)" (mouseleave)="ttt(13)">{{ 'Sprache' | translate }}</div>
              <div class="dx-field-value" id="tt13">
                <dx-autocomplete [dataSource]="modelService.loginService.languageFlags"
                                 displayExpr="text"
                                 valueExpr="code"
                                 [(value)]="model.language"
                                 fieldTemplate="field"
                                 (onValueChanged)="catalogUpdate($event)"
                                 [readOnly]="readOnly('catalogLanguage')">
                </dx-autocomplete>
              </div>
              <dx-tooltip target="#tt13" class="toolTip toolTipField" [(visible)]="v[13]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Angabe der Sprache in der der Katalog gepflegt ist' | translate }}.
                    {{ 'Die Angabe sollte für BMEcat nach ISO-639-2 erfolgen' | translate }}.
                  </p>
                  <div *ngIf="showProToolTips" class="nexMartTooltip">
                    {{ 'Technisch gibt es keine Besonderheiten' | translate }}.
                  </div>
                </div>
              </dx-tooltip>
            </div>

            <!--Aggrement-->
            <div *ngIf="modelService.loginService.hasAddonAGREEMENT" class="dx-fieldset">

              <dx-accordion [dataSource]="agreementDummy"
                            [collapsible]="true"
                            [multiple]="false"
                            [animationDuration]="300"
                            selectedItems="emptyArray">

                <div *dxTemplate="let t of 'title'" class="dx-fieldset-header">
                  {{ 'Rahmenvertrag' | translate }}
                </div>

                <div *dxTemplate="let i of 'item'">

                  <!--Aggrement ID-->
                  <div class="dx-field">
                    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(9)" (mouseleave)="ttt(9)">{{ 'ID des Rahmenvertrags' | translate }}</div>
                    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt9">
                      <label class="placeLabel {{ model?.agreement.agreementId?.length>0 ? 'placeLabelSmall' : '' }}">AGREEMENT ID</label>
                      <dx-text-box id="pimGroup" [readOnly]="readOnly('catalogAggrementId')" [(value)]="model.agreement.agreementId" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-text-box>
                    </div>
                    <dx-tooltip target="#tt9" class="toolTip toolTipField" [(visible)]="v[9]">
                      <div *dxTemplate="let data = data of 'content'">
                      </div>
                    </dx-tooltip>
                  </div>

                  <!--Aggrement Type-->
                  <div class="dx-field">
                    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(8)" (mouseleave)="ttt(8)">{{ 'Rahmenvertragstyp' | translate }}</div>
                    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt8">
                      <label class="placeLabel {{ model?.agreement.type?.length>0 ? 'placeLabelSmall' : '' }}">AGREEMENT TYPE</label>
                      <dx-autocomplete [minSearchLength]="0"
                                       [dataSource]="['buyer','supplier']"
                                       [showClearButton]="true"
                                       [showDropDownButton]="true"
                                       [(value)]="model.agreement.type"
                                       valueChangeEvent="focusout"
                                       (onValueChanged)="catalogUpdate($event)"
                                       [readOnly]="readOnly('catalogAggrementType')">
                      </dx-autocomplete>
                    </div>
                    <dx-tooltip target="#tt8" class="toolTip toolTipField" [(visible)]="v[8]">
                      <div *dxTemplate="let data = data of 'content'">
                      </div>
                    </dx-tooltip>
                  </div>

                  <!--Aggrement DESC-->
                  <div class="dx-field">
                    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(10)" (mouseleave)="ttt(10)">{{ 'Beschreibung' | translate }}</div>
                    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt10">
                      <label class="placeLabel {{ model?.agreement.agreementDesc?.length>0 ? 'placeLabelSmall' : '' }}">AGREEMENT DESCRIPTION</label>
                      <dx-text-box id="pimGroup" [readOnly]="readOnly('catalogAggrementDESC')" [(value)]="model.agreement.agreementDesc" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-text-box>
                    </div>
                    <dx-tooltip target="#tt10" class="toolTip toolTipField" [(visible)]="v[10]">
                      <div *dxTemplate="let data = data of 'content'">
                      </div>
                    </dx-tooltip>
                  </div>

                  <!--Aggrement Start Datum-->
                  <div class="dx-field">
                    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(11)" (mouseleave)="ttt(11)">{{ 'Startzeitpunkt' | translate }}</div>
                    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
                      <label class="placeLabel {{ model.agreement.startDate?.toString().length>0 ? 'placeLabelSmall' : '' }}">AGREEMENT START DATE</label>
                      <dx-date-box [readOnly]="readOnly('catalogAggrementStartDate')" [(value)]="model.agreement.startDate" displayFormat="dd.MM.yyyy" type="datetime" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-date-box>
                    </div>
                    <dx-tooltip target="#tt11" class="toolTip toolTipField" [(visible)]="v[11]">
                      <div *dxTemplate="let data = data of 'content'">
                      </div>
                    </dx-tooltip>
                  </div>

                  <!--Aggrement End Datum-->
                  <div class="dx-field">
                    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(12)" (mouseleave)="ttt(12)">{{ 'Endzeitpunkt' | translate }}</div>
                    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt12">
                      <label class="placeLabel {{ model.agreement.endDate?.toString().length>0 ? 'placeLabelSmall' : '' }}">AGREEMENT END DATE</label>
                      <dx-date-box [readOnly]="readOnly('catalogAggrementEndDate')" [(value)]="model.agreement.endDate" displayFormat="dd.MM.yyyy" type="datetime" valueChangeEvent="focusout" (onValueChanged)="catalogUpdate($event)"></dx-date-box>
                    </div>
                    <dx-tooltip target="#tt12" class="toolTip toolTipField" [(visible)]="v[12]">
                      <div *dxTemplate="let data = data of 'content'">
                      </div>
                    </dx-tooltip>
                  </div>

                </div>

              </dx-accordion>

            </div>

            <div *ngIf="hasElectronicSalesAndEClass" class="dx-fieldset">

            </div>

            <!--Notiz-->
            <div class="dx-field detailHeadline">

              <div class="dx-field-label dx-field-label200">{{ 'Notizen' | translate }}</div>
            </div>
            <div class="dx-fieldset">
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(15)" (mouseleave)="ttt(15)">{{ 'Kurz Notiz' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt15">
                  <label class="placeLabel {{ model.note?.length>0 ? 'placeLabelSmall' : '' }}">NOTE</label>
                  <dx-text-box id="pimGroup" [readOnly]="readOnly('catalogNote')" [(value)]="model.note" [showClearButton]="true" (onValueChanged)="catalogUpdate($event)"></dx-text-box>
                </div>
                <dx-tooltip target="#tt15" class="toolTip toolTipField" [(visible)]="v[15]">
                  <div *dxTemplate="let data = data of 'content'">
                    <p>
                      {{ 'Kurz Notiz welche in der Catalogliste angezeigt wird' | translate }}.
                    </p>
                  </div>
                </dx-tooltip>
              </div>

              <!-- Memo-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(14)" (mouseleave)="ttt(14)">{{ 'Bearbeitungsnotiz' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt14" style="border: 1px solid #949494;">

                  <dx-html-editor [(value)]="model.memo"
                                  placeholder="MEMO"
                                  (onValueChanged)="catalogUpdate($event)"
                                  [readOnly]="readOnly('catalogMemo')">
                    <dxo-toolbar>
                      <dxi-item name="undo"></dxi-item>
                      <dxi-item name="redo"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="bold"></dxi-item>
                      <dxi-item name="italic"></dxi-item>
                      <dxi-item name="underline"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="alignLeft"></dxi-item>
                      <dxi-item name="alignCenter"></dxi-item>
                      <dxi-item name="alignRight"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="orderedList"></dxi-item>
                      <dxi-item name="bulletList"></dxi-item>
                    </dxo-toolbar>
                  </dx-html-editor>
                  <dx-tooltip target="#tt14" class="toolTip toolTipField" [(visible)]="v[14]">
                    <div *dxTemplate="let data = data of 'content'">
                      <p>
                        {{ 'Das Notiz Feld dient dazu, dass Sie sich Notizen zu diesem Katalog machen' | translate }}.
                        {{ 'Die Notizen bleiben nur in diesem Katalog. Kein Excel oder BMEcat Export' | translate }}:
                    </div>
                  </dx-tooltip>
                </div>

              </div>
            </div>
          </div>

        </div>

        <!--Lieferant-->
        <div *dxTemplate="let company of 'lieferantTemplate'">
          <catalogSupplier></catalogSupplier>
        </div>

        <!--Käufer-->
        <div *dxTemplate="let company of 'kaeuferTemplate'">
          <catalogBuyer></catalogBuyer>
        </div>

        <!--Mimes-->
        <div *dxTemplate="let company of 'medienTemplate'">
          <mimes [(model)]="model.mimes"
                 [(showToolTips)]="showToolTips"
                 [(showProToolTips)]="showProToolTips"
                 [(showPlaceHolders)]="showPlaceHolders"
                 [(catalogId)]="modelService.catalogService.catalog.id"
                 [(customerId)]="modelService.loginService.currentUser.customerId"
                 (reloadNedded)="reload()"
                 (onValueChanged)="catalogUpdate()"
                 [(modelService)]="modelService">
          </mimes>
        </div>

        <!--Einstellungen-->

        <div *dxTemplate="let company of 'settingsTemplate'">
          <catalogSettings></catalogSettings>
        </div>


        <!--MedienPool-->
        <div *dxTemplate="let company of 'poolTemplate'">
          <mediaPool *ngIf="model"></mediaPool>
        </div>

        <!--DATA Quality-->
        <div *dxTemplate="let company of 'dataQualityTemplate'">
          <dataQuality *ngIf="model"></dataQuality>
        </div>

      <!--GEVIS-->
        <div *dxTemplate="let company of 'gevisTemplate'">
          <gevis></gevis>
        </div>

        <!--ODOO-->
        <div *dxTemplate="let company of 'odooTemplate'">
          <odoo></odoo>
        </div>

      </dx-tab-panel>
    </dx-validation-group>

    <dx-popup [width]="800"
              minHeight="550"
              [height]="'auto'"
              [showTitle]="true"
              title="{{ 'Katalog reimport' | translate }}"
              [dragEnabled]="false"
              [hideOnOutsideClick]="true"
              [(visible)]="importCatalogVisible"
              [showCloseButton]="true"
              contentTemplate="popupContent">
      <div *dxTemplate="let data of 'popupContent'">
        <importCatalog (onReimportCatalog)="hideImportCatalog()"></importCatalog>
      </div>
    </dx-popup>

    <dx-popup [width]="700"
              [height]="400"
              [showTitle]="true"
              title="{{ 'Übersetzung erstellen' | translate }}"
              [dragEnabled]="false"
              [showCloseButton]="true"
              [hideOnOutsideClick]="true"
              [(visible)]="modelService.catalogService.translateVisible"
              contentTemplate="popupContent">
      <div *dxTemplate="let data of 'popupContent'">
        <translateCatalog></translateCatalog>
      </div>
    </dx-popup>

    <dx-popup [width]="800"
              [height]="450"
              [showTitle]="true"
              title="{{ 'Medien importieren' | translate }}"
              [dragEnabled]="false"
              [hideOnOutsideClick]="true"
              [showCloseButton]="true"
              [(visible)]="importMediaVisible"
              contentTemplate="popupContent">
      <div *dxTemplate="let data of 'popupContent'">
        <importMedia (onImportMedia)="hideImportMedia()"></importMedia>
      </div>
    </dx-popup>

    <export [(visible)]="exportVisible"></export>

    <!-- Category Export PopUp -->
    <exportCategory [(visible)]="categoryExportVisible"></exportCategory>

    <!-- Category Import PopUp -->
    <dx-popup class="popup"
              [width]="800"
              [height]="450"
              [showTitle]="true"
              title="{{ 'Kategorien importieren' | translate }}"
              [dragEnabled]="false"
              [hideOnOutsideClick]="true"
              [(visible)]="categoryImportVisible"
              [showCloseButton]="true"
              contentTemplate="popupContent">
      <div *dxTemplate="let data of 'popupContent'">
        <importCategory [(visible)]="categoryImportVisible"></importCategory>
      </div>
    </dx-popup>

  </div>

</div>
