<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Druck Katalog' | translate  }}</div>
    <div class="dx-field-value">
      <dx-switch (onValueChanged)="catalogUpdate($event)" [(value)]="model.isPrint" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Einfache Ansicht' | translate  }}</div>
    <div class="dx-field-value">
      <dx-switch (onValueChanged)="catalogUpdate($event)" [(value)]="model.usePrintView" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
    </div>
  </div>
</div>
