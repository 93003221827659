<!--85 split featurevalue from unit-->
<div class="dx-field">
  <div class="dx-field-label w-25">{{ 'Units' | translate }}</div>
  <div class="dx-field-value w-75">
    <dx-tag-box id="unitTagBox"
                [(value)]="selectedUnits"
                [dataSource]="foundUnitsToBeSplit"
                [showClearButton]="true"
                [multiline]="true"
                [showSelectionControls]="true"
                [acceptCustomValue]="true"
                [width]="500"
                [showDropDownButton]="foundUnitsToBeSplit.length != 0"
                noDataText="{{ 'NoUnitInTagBox' | translate }}">
    </dx-tag-box>
    <dx-load-panel #loadPanel
                   shadingColor="rgba(0,0,0,0.4)"
                   [position]="{ of: '#unitTagBox' }"
                   [(visible)]="unitsLoading"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="true"
                   [closeOnOutsideClick]="false">
    </dx-load-panel>
    <dx-button stylingMode="contained"
               text="{{ 'DetectUnits' | translate }}"
               type="normal"
               (onClick)="loadUnits()">
    </dx-button>
  </div>
</div>
