import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { Message } from "../../../../Model/System/Message";
import { TemplateItemTemplate } from "../../../../Model/Catalog/TemplateItemTemplate";
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { ExportParameter } from "../../../../Model/exportParameter/ExportParameter";

@Component({
  selector: "exportCategory",
  templateUrl: "exportCategory.component.html",
  styleUrls: ["exportCategory.component.css"]
})
export class ExportCategoryComponent implements OnInit {

  private _visible: boolean = false;

  public get visible() {
    return this._visible;
  }

  @Input()
  public set visible(val: boolean) {
      if (this._visible !== val) {
          this._visible = val;
          this.visibleChange.emit(val);
      }        
  }

  @Output() public visibleChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.translateTexts();
    this.reset();
  }

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.exportExcel = this.exportExcel.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.reset = this.reset.bind(this);
  }

  public reset() {
    this.filterReset();
    this.templateReset();
  }



  //*******************************************************************************************************************************************************************//
  //*                                                                                                                                                                 *//
  //*                                                                          Filter                                                                                 *//
  //*                                                                                                                                                                 *//
  //*******************************************************************************************************************************************************************//

  exportMedia: boolean;
  exportMediaOriginalSize: boolean = true;

  imageTypes: string[] = ["jpg", "png"];
  imageSquared = false;
  imageBgColor = '#FFFFFF';
  imageType: string = "jpg";
  maxResolution: number = 1920;

  txtSonstiges: string;

  private filterReset() {
    this.exportMedia = false;
  }

  private model: ExportParameter;

  get listsAreLoaded(): boolean {
    return true;
  }

  txtExportAlsJob: string;
  txtFelder: string;
  txtSeo: string;

  translateTexts() {
    this.txtSonstiges = this.translate.instant("Sonstiges");
    this.txtExportAlsJob = this.translate.instant("Der Export wird als Job ausgeführt");
    this.txtFelder = this.translate.instant("Felder");
    this.txtSeo = this.translate.instant("Seo");
  }

  private GetExportParameter(): ExportParameter {
    let result = new ExportParameter();
    result.catalogId = this.modelService.catalogService.selectedCatalogId;
    result.customerId = this.modelService.loginService.currentUser.customerId;
    result.format = "Bme";

    result.includeMime = this.exportMedia;
    result.orignialSize = this.exportMediaOriginalSize;
    result.maxResolution = this.maxResolution;
    result.imageType = this.imageType;
    result.imageSquared = this.imageSquared;
    result.imageBgColor = this.imageBgColor;

    return result;
  }



  //*******************************************************************************************************************************************************************//
  //*                                                                                                                                                                 *//
  //*                                                                          EXCEL                                                                                  *//
  //*                                                                                                                                                                 *//
  //*******************************************************************************************************************************************************************//

  public templateReset() {
    this.modelService.categoryService.loadFields();
    this.initTabView();

    // TODO: implement for category
    //this.groups = this.modelService.updateTemplateService.GetFieldGroups();
    //let result = ["Basic", "Logistic", "Features", "Price", "Order", "Mimes", "Misc"];
    this.groups = ["Basic", "Mimes"];
    if (this.modelService.loginService.hasAddonSEO) {
      this.groups.push("Seo");
    }

  }

  public excelIndex: number = 0;

  excelPages: { id: number; title: string; template: string }[] = [];

  public initTabView() {
    this.excelPages = [
      { id: 0, title: this.txtFelder, template: "excelFieldsTemplate" },
      { id: 1, title: this.txtSonstiges, template: "miscTemplate" }
    ];
   

  }

  groups: string[] = [];

  changedField(event, group) {
    for (var field of this.fields) {
      if (field.group == group) {
        if (field.field != "GROUP_ID") {
          field.active = event.value;
        }
      }
    }
  }

  readOnly(field) {
    if (field == "GROUP_ID") {
      return true;
    }
    return false;
  }

  get fields() {
    return this.modelService.categoryService.fields;
  }

  getTemplateItemTemplate(name: string) {
    var element = this.modelService.categoryService.fields.filter(item => item.field == name).shift();
    return element;
  }

  exportExcel() {
    this.model = this.GetExportParameter();
    this.model.format = "Excel";
    this.model.fields = [];
    this.fields.forEach((field: TemplateItemTemplate) => {
      if (field.active) {
        this.model.fields.push(field.field);
      }
    });
    this.startTableExport();
  }

  exportCSV() {
    this.model = this.GetExportParameter();
    this.model.format = "csv";
    this.model.fields = [];
    this.fields.forEach((field: TemplateItemTemplate) => {
      if (field.active) {
        this.model.fields.push(field.field);
      }
    });
    this.startTableExport();
  }

  startTableExport() {
    this.modelService.categoryService.categoryExportVisible = false;
    this.modelService.categoryService.createExportJob(this.model)
      .subscribe(
        () => {
          this.modelService.systemService.notify(new Message(this.txtExportAlsJob));
          this.reset();
        });
  }

  public getHeightRatio(group): number {
    var list = this.modelService.categoryService.fields.filter(item => item.group == group);
    return list.length + 2;
  }



  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  get fullScreen() {
    return this.modelService.categoryService.viewService.fullScreen;
  }
}
