<form (submit)="onAddCatalog()">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Katalogname' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="model.name" [showClearButton]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie einen Katalognamen an' | translate }}!"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div *ngIf="modelService.loginService.hasAddonVIRTUAL_CATALOGS" class="dx-field">
      <div class="dx-field-label">{{ 'Virtuell' | translate }}</div>
      <div class="dx-field-value">

        <div>
          <dx-switch [(value)]="model.isVirtual" switchedOffText="{{ 'Nein' | translate  }}" switchedOnText="{{ 'Ja' | translate  }}"></dx-switch>
        </div>

      </div>
    </div>

    <dx-validation-summary id="summary"></dx-validation-summary>

    <div class="dx-field d-flex flex-row">
      <dx-button stylingMode="text" type="default" text="{{ 'Anlegen' | translate }}" [useSubmitBehavior]="true"></dx-button>
    </div>
  </div>
</form>
