<div *ngIf="model" style="width:1200px;">
    <div style="width:550px; float:left;">
      <dx-tile-view class="dx-tile-content" [baseItemWidth]="150" direction="vertical" showScrollbar="false" height="4000" width="540" baseItemHeight="150">
        <dxi-item *ngFor="let tileImage of model.productTileImages"
                  [widthRatio]="widthRatio(tileImage.ratio)"
                  [heightRatio]="heightRatio(tileImage.ratio)">
          <div style="width:100%; height:100%;background-position: center; background-repeat: no-repeat; background-size: cover; " [ngStyle]="{ 'background-image': 'url(' + tileImage.src + ')'}"></div>
        </dxi-item>
      </dx-tile-view>
    </div>
    <div style="width:650px;float:right;">
      <h3>{{model?.descriptionShort}}</h3>
      <p>
        <b>{{marketingClaim}}</b>
      </p>
      <div>
      </div>
      <div style="margin-top:10px;width:100%;">
        <div style="padding-left:0px;width:50%; float:left">
          <div *ngIf="model.supplierPid.length>0"><b>{{ 'Produktnummer' | translate }}: </b>{{model.supplierPid}}</div>
          <div><b>{{ 'EAN-Nr' | translate }}: </b>{{ean}}</div>
          <div *ngIf="model?.points.length>0" style="margin-top:20px;">
            <table>
              <tr *ngFor="let point of model.points">
                <td><b>{{point.name}}</b></td>
                <td style="width:20px;"></td>
                <td>
                  <i *ngFor='let item of forLoopArray(point.points)' class="dx-icon-favorites points" style="color:#0093D6"></i>
                  <i *ngFor='let item of forLoopArray(point.max - point.points)' class="dx-icon-favorites points"></i>
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="model?.usageFeatures.length>0" style="margin-top:20px">
            <p style="margin:0px;"><b>{{ 'Anwendung' | translate }}:</b></p>
            <ul>
              <li>{{model.usageFeatures[0].value}}</li>
              <li *ngIf="model?.usageFeatures.length>1">{{model.usageFeatures[1].value}}</li>
              <li *ngIf="model?.usageFeatures.length>2">{{model.usageFeatures[2].value}}</li>
            </ul>
          </div>
          <div *ngIf="model?.useForFeatures.length>0" style="margin-top:20px">
            <ul class="useFor">
              <li *ngFor="let useFour of model.useForFeatures" class="{{useFour.value}}">{{useFour.value}}</li>
            </ul>
          </div>
        </div>
        <div style="padding:0px;width:50%; float:right;text-align:right;">
          <div *ngIf="model?.productImagesLogos.length>0">
            <img src="{{model.productImagesLogos[0].blobUri}}" style="width:110px;" />
          </div>
          <div *ngIf="model?.variantsFeatures.length>0" style="margin-top:20px;">
            <p><b>{{ 'Ausführung' | translate }}</b></p>
            <ul class="variants">
              <li *ngFor="let variant of model.variantsFeatures">{{variant.name}}: {{variant.value}} {{variant.funit}}</li>
            </ul>
          </div>
          <div *ngIf="hasPrice" style="margin-top:20px;width:100%;">
            <div class="card card-body bg-light" style="width:230px;float:right;">
              <h4 style="margin-top:5px;">{{price.prouctPrice}}</h4>
              zzgl. {{price.prouctTax}}% Mwst.
              <h4 style="margin-bottom:0px">{{price.prouctUnit}}</h4>
            </div>
          </div>
        </div>
        <div *ngIf="model?.productImagesIcon.length>0" style="margin-top:20px;height:60px; float:left;">
          <div *ngFor="let icon of model.productImagesIcon" style="float:left;">
            <img src="{{icon.thumbUri}}" style="width:60px; height:60px; margin: 0rem 1.25rem 0rem 0rem; float: left; display: block; padding: .3125rem; border: none;" />
          </div>
        </div>

        <div style="margin-top:20px;width:100%;float:left;">
          <div *ngIf="model.topFeatures.length>0" class="topFeatures">
            <table *ngIf="model.topFeatures.length<3">
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[0].value}}</td>
                <td *ngIf="model.topFeatures.length==2" class="point">•</td>
                <td *ngIf="model.topFeatures.length==2" class="text">{{model.topFeatures[1].value}}</td>
              </tr>
            </table>
            <table *ngIf="model.topFeatures.length>2 && model.topFeatures.length<5">
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[0].value}}</td>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[2].value}}</td>
              </tr>
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[1].value}}</td>
                <td *ngIf="model.topFeatures.length==4" class="point">•</td>
                <td *ngIf="model.topFeatures.length==4" class="text">{{model.topFeatures[3].value}}</td>
              </tr>
            </table>
            <table *ngIf="model.topFeatures.length>4">
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[0].value}}</td>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[3].value}}</td>
              </tr>
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[1].value}}</td>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[4].value}}</td>
              </tr>
              <tr>
                <td class="point">•</td>
                <td class="text">{{model.topFeatures[2].value}}</td>
                <td *ngIf="model.topFeatures.length>5" class="point">•</td>
                <td *ngIf="model.topFeatures.length>5" class="text">{{model.topFeatures[5].value}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="column" style="margin-top:20px;width:100%;float:left;">
          <dx-accordion [dataSource]="lukasParts"
                        [collapsible]="false"
                        [multiple]="false"
                        [animationDuration]="300"
                        [selectedItems]="[lukasParts[0]]">
            <div *dxTemplate="let element of 'title'">
              <h4>{{element.headLine}}</h4>
            </div>
            <div *dxTemplate="let element of 'item'">
              <div [ngSwitch]="element.type">
                <div *ngSwitchCase="'desc'">
                  <span [innerHTML]="model?.descriptionLong"></span>
                </div>
                <div *ngSwitchCase="'tech'">
                  <dx-data-grid [dataSource]="model.technicalFeatures"
                                [rowAlternationEnabled]="true"
                                [columns]="['name', 'value', 'unit']"
                                [showBorders]="true">
                    <dxi-column dataField="name" caption="{{ 'Bezeichnung' | translate }}"></dxi-column>
                    <dxi-column dataField="value" caption="{{ 'Wert' | translate }}" width="70"></dxi-column>
                    <dxi-column dataField="funit" caption="{{ 'Einheit' | translate }}" width="70"></dxi-column>
                  </dx-data-grid>
                </div>
                <div *ngSwitchCase="'befits'">
                  <table>
                    <tr *ngFor="let benefits of model.benefitsFeatures">
                      <td class="point">•</td>
                      <td class="text">{{benefits.value}}</td>
                    </tr>
                  </table>
                </div>
                <div *ngSwitchCase="'use'">
                  <table>
                    <tr *ngFor="let use of model.usageFeatures">
                      <td class="point">•</td>
                      <td class="text">{{use.value}}</td>
                    </tr>
                  </table>
                </div>
                <div *ngSwitchCase="'download'">
                  <table>
                    <tr *ngFor="let dataSheets of model.productDataSheets">
                      <td class="point">•</td>
                      <td class="text"><a href="{{downloadLink}}{{dataSheets.mimeSource}}">{{dataSheets.mimeDescr}}</a></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </dx-accordion>
        </div>
      </div>
  </div>  
</div>
