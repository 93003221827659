import { Component } from '@angular/core';
import { ModelService } from '../../Services/model.service';

@Component({
  selector: 'help-column',
  templateUrl: './help-column.component.html',
  styleUrls: ["./help-column.component.css"]
})
export class HelpColumnComponent { 
    constructor(public modelService: ModelService) {} 
}