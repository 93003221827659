import { Component, OnInit } from "@angular/core";
import isString from "lodash.isstring";
import { ModelService } from '../Services/model.service';
import { ProductState } from '../Model/Catalog/ProductState';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from '@ngx-translate/core';
import { confirm } from 'devextreme/ui/dialog';


@Component({
  selector: "productStates",
  templateUrl: "productState.component.html"
})
export class ProductStateComponent implements OnInit {
  constructor(public modelService: ModelService, public translate: TranslateService) {
    
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Status");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newStateButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'customDeleteButtonTemplate'
    });    
  }

  ngOnInit(): void {
    this.getStatus();
  }

  public status: ProductState[] = [];
  public states: any[];

  getStatus() {
    this.modelService.productStateService.getStatus().subscribe(
      (result: ProductState[]) => {
        this.status = result;
      });
  }

  update(event) {
    var status;
    if (isString(event)) {
      status = this.status.find(item => item.idKey == event);
    } else {
      if (event.key == null)
        return;
      status = this.status.find(item => item.idKey == event.key.idKey);
    }
    this.modelService.productStateService.updateProductState(status).subscribe((_id: string) => {});
  }

  deleteMultiple() {                                                
    let result = confirm(this.states.length + this.translate.instant("Status ausgewählt </br> Sind Sie sich sicher, dass diese gelöscht werden sollen?"), this.translate.instant("Auswahl löschen"));
    result.then((dialogResult) => {
      if (dialogResult) {
        this.states.map(state => this.modelService.productStateService.deleteProductState(state.idKey));
        this.states.reverse().forEach(x => this.status.splice(this.status.findIndex(y => y.idKey == x.idKey), 1));
      }});
  }
  
  onSelectionChanged(e) {
      this.states = e.selectedRowKeys
  }

  addStatus(status: ProductState = null, id = null) {
    if (id == null) {
      status = new ProductState();
      status.customerId = this.modelService.loginService.currentUser.customerId;

      this.modelService.productStateService.addProductState(status).subscribe(
        (result: ProductState) => {
          this.getStatus();
        });

    } else {
      status.idKey = id;
      this.status.push(status);
    }
  }

  public pimStatus: string[] = ["New", "Updated", "Normal", "Locked", "Deleted"];

  deleteStatus(event) {
    this.modelService.productStateService.deleteProductState(event.data.idKey);
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }
}
