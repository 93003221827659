import { Functions } from '../../Services/functions';

export class ProductPriceDetail {
  constructor(
    public order?: number,
    public priceFactorNumber?: number,
    public priceAmountNumber?: number,
    public priceCurrency?: string,
    public priceType?: string,
    public taxRate?: number,
    public taxCategory?: string,
    public territory?: string,
    public lowerBoundNumber?: number,
    public guid: string = Functions.newGuid()
  ) {
    this.priceCurrency = "EUR";
    this.priceFactorNumber = 1;
    this.taxRate = 0.19;
    this.territory = "DE";
    this.lowerBoundNumber = 1;
  }

  public clearPrice() {
    this.priceCurrency = "";
    this.priceFactorNumber = 0;
    this.taxRate = 0;
    this.territory = "";
    this.lowerBoundNumber = 0;
  }
}
