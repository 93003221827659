<fullscreen-page>
  <content-column look="dark">
    <div slot="content" class="h-100 p-2">
      <div class="d-flex align-items-center h-100 w-100">
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex flex-row">
            <div class="dx-fieldset my-0 flex-fill">
              <div *ngIf="!isTargetCatalogVirtual" class="dx-field justify-content-center">
                <dx-radio-group
                  [items]="operationsNonVirtual"
                  [(value)]="selectedOperation"
                  layout="horizontal"
                  [disabled]="operationsDisabled"
                ></dx-radio-group>
              </div>
              <div *ngIf="isTargetCatalogVirtual" class="dx-field justify-content-center">
                <dx-radio-group
                  [items]="operationsVirtual"
                  [(value)]="selectedOperation"
                  layout="horizontal"
                  [disabled]="operationsDisabled"
                ></dx-radio-group>
              </div>
            </div>
            <div class="dx-field align-items-stretch">
              <np-license-upgrade-button />
              <dx-select-box
                [dataSource]="transferModeItems"
                [readOnly]="isOnboarding"
                displayExpr="value"
                valueExpr="value"
                [(value)]="transferMode"
                label="{{ 'Übertragungsmodus' | translate }}"
              ></dx-select-box>
            </div>
          </div>
          <div class="d-flex flex-row h-100 w-100">
            <div class="d-flex flex-column h-100 w-50 mr-1">
              <div class="dx-fieldset">
                <div class="dx-field">
                  <div class="dx-field-label">
                    {{ 'Account' | translate }}
                  </div>
                  <div class="dx-field-value d-flex flex-row">
                    <dx-select-box
                      class="w-100"
                      [value]="modelService?.loginService?.currentUser?.customerId"
                      [dataSource]="customers"
                      displayExpr="name"
                      valueExpr="id"
                      [searchEnabled]="true"
                      [readOnly]="true"
                    ></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">
                    {{ 'Quellkatalog' | translate }}
                  </div>
                  <div class="dx-field-value d-flex flex-row">
                    <dx-select-box
                      class="w-100"
                      [items]="sourceCatalogs"
                      valueExpr="id"
                      [value]="selectedSourceCatalogId"
                      displayExpr="catalogName"
                      [searchEnabled]="true"
                      (onSelectionChanged)="sourceSelected($event)"
                      noDataText="{{ 'keine Kataloge im Account' | translate }}"
                    ></dx-select-box>
                  </div>
                </div>
              </div>

              <div class="px-6 pt-6 align-self-end" style="padding: 6px">
                <a (click)="toggleFilter()"><span class="glyphicon glyphicon-filter" style="font-size: 20px"></span></a>
              </div>

              <div class="px-3 pb-2" *ngIf="showFilter">
                <productStateOptions
                  [stateValue]="selectedStates"
                  (onValueChanged)="updateState($event)"
                ></productStateOptions>
                <productSearchOptions (onValueChanged)="updateSearchFields($event)"></productSearchOptions>
              </div>

              <div class="flex-fill overflow-hidden">
                <dx-tree-list
                  #treeListSource
                  id="treeListSource"
                  class="tree"
                  [dataSource]="dataSourceSource"
                  [showBorders]="false"
                  [showColumnHeaders]="false"
                  [showColumnLines]="false"
                  [remoteOperations]="{ filtering: true }"
                  keyExpr="treeElementId"
                  parentIdExpr="parent"
                  hasItemsExpr="hasChilds"
                  rootValue="0"
                  [(selectedRowKeys)]="treeSelectedRowKeysSource"
                  (onRowClick)="onRowClick($event)"
                  (onCellPrepared)="onCellPreparedSource($event)"
                >
                  <dxo-search-panel
                    [visible]="true"
                    [(text)]="filterValue"
                    placeholder="{{ 'Produkte suchen' | translate }}"
                    width="auto"
                    class="treeSearch"
                  ></dxo-search-panel>
                  <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
                  <!--<dxo-selection mode="single"></dxo-selection>-->
                  <dxo-selection [allowSelectAll]="false" mode="multiple" [recursive]="false"></dxo-selection>

                  <dxo-row-dragging
                    [onDragStart]="onDragStartSource"
                    group="catalogs"
                    [showDragIcons]="false"
                  ></dxo-row-dragging>

                  <dxi-column dataField="Title" cellTemplate="cellTemplate"></dxi-column>
                  <dxi-column dataField="order" [visible]="false" sortOrder="asc"></dxi-column>

                  <div *dxTemplate="let itemObj of 'cellTemplate'">
                    <div class="treeitem" id="image{{ itemObj.data.treeElementId }}">
                      <div class="container-fluid h-100">
                        <div class="row h-100 d-flex">
                          <div class="col order-0 treeitemimage">
                            <span
                              *ngIf="!itemObj.data.imageUrl"
                              class="glyphicon glyphicon-picture"
                              style="font-size: 36px"
                            ></span>
                            <img *ngIf="itemObj.data.imageUrl" src="{{ itemObj.data.imageUrl }}" style="height: 40px" />
                          </div>

                          <div class="col order-1 overflow-hidden treeitemtext">
                            <div class="treeitemheader">
                              {{ itemObj.data.text }}
                              <!--<span *ngIf="hasSpid(itemObj.data.supplierPid)" class="treeitemheader np-green">
                                {{itemObj.data.text}}
                              </span>
                              <span *ngIf="!hasSpid(itemObj.data.supplierPid)" class="treeitemheader">
                                {{itemObj.data.text}}
                              </span>-->
                            </div>

                            <div class="treeitemdetail">
                              <div [ngSwitch]="itemObj.data.type">
                                <!--CATALOG-->
                                <div *ngSwitchCase="'cat'">
                                  {{ 'Kategorien' | translate }}: {{ itemObj.data.categoryCount }} -
                                  {{ 'Produkte' | translate }}: {{ itemObj.data.productCount }} ({{
                                    'eindeutige' | translate
                                  }}: {{ itemObj.data.uniqueProductCount }})
                                </div>

                                <!--CATEGORY-->
                                <div *ngSwitchCase="'grp'">
                                  {{ 'Kategorien' | translate }}: {{ itemObj.data.categoryCount }} -
                                  {{ 'Produkte' | translate }}: {{ itemObj.data.productCount }}
                                </div>

                                <!--PRODUCT-->
                                <div *ngSwitchCase="'pro'">
                                  {{ itemObj.data.supplierPid }}
                                  <!--<span *ngIf="hasSpid(itemObj.data.supplierPid)" class="np-green">
                                    {{itemObj.data.supplierPid}}
                                  </span>
                                  <span *ngIf="!hasSpid(itemObj.data.supplierPid)" class="">
                                    {{itemObj.data.supplierPid}}
                                  </span>-->
                                  <i
                                    *ngIf="hasSpid(itemObj.data.supplierPid)"
                                    class="dx-icon dx-icon-check np-icon-check"
                                  ></i>
                                  <!--  np-green -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dx-tree-list>
              </div>
            </div>
            <div class="d-flex flex-column h-100 w-50 ml-1">
              <div class="dx-fieldset">
                <div class="dx-field">
                  <div class="dx-field-label">
                    {{ 'Account' | translate }}
                  </div>
                  <div class="dx-field-value d-flex flex-row">
                    <dx-select-box
                      class="w-100"
                      [readOnly]="isOnboarding"
                      [(value)]="selectedCustomerId"
                      [dataSource]="customers"
                      displayExpr="name"
                      valueExpr="id"
                      [searchEnabled]="true"
                    ></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">
                    {{ 'Zielkatalog' | translate }}
                  </div>
                  <div class="dx-field-value d-flex flex-row">
                    <dx-select-box
                      class="w-100"
                      [items]="targetCatalogs"
                      valueExpr="id"
                      [value]="selectedTargetCatalogId"
                      displayExpr="catalogName"
                      [searchEnabled]="true"
                      (onSelectionChanged)="targetSelected($event)"
                      noDataText="{{ 'keine Kataloge im Account' | translate }}"
                      fieldTemplate="field"
                      itemTemplate="item"
                    >
                      <div *dxTemplate="let data of 'field'">
                        <dx-text-box
                          [ngClass]="{ virtualCatalogColor: data?.isVirtual }"
                          [value]="data?.catalogName"
                        ></dx-text-box>
                      </div>

                      <div *dxTemplate="let data of 'item'" [ngClass]="{ virtualCatalogColor: data.isVirtual }">
                        {{ data.catalogName }}
                      </div>
                    </dx-select-box>
                  </div>
                </div>
              </div>
              <div class="px-6 pt-6 align-self-end" style="padding: 6px">
                <a (click)="toggleFilterTarget()">
                  <span class="glyphicon glyphicon-filter" style="font-size: 20px"></span>
                </a>
              </div>

              <div class="px-3 pb-2" *ngIf="showFilterTarget">
                <productStateOptions
                  [stateValue]="selectedStatesTarget"
                  [isTarget]="true"
                  (onValueChanged)="updateStateTarget($event)"
                ></productStateOptions>
                <productSearchOptions [isTarget]="true"></productSearchOptions>
              </div>
              <div class="flex-fill overflow-hidden">
                <dx-tree-list
                  #treeListTarget
                  id="treeListTarget"
                  class="tree"
                  [dataSource]="dataSourceTarget"
                  [showBorders]="false"
                  [showColumnHeaders]="false"
                  [showColumnLines]="false"
                  [remoteOperations]="{ filtering: true }"
                  keyExpr="treeElementId"
                  parentIdExpr="parent"
                  hasItemsExpr="hasChilds"
                  rootValue="0"
                  (onSelectionChanged)="onSelectionChangedTarget($event)"
                  [(selectedRowKeys)]="treeSelectedRowKeysTarget"
                  (onRowClick)="onRowClick($event)"
                  (onCellPrepared)="onCellPreparedSourceTarget($event)"
                >
                  <dxo-search-panel
                    [visible]="true"
                    placeholder="{{ 'Produkte suchen' | translate }}"
                    width="auto"
                    class="treeSearch"
                  ></dxo-search-panel>
                  <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
                  <dxo-selection mode="single"></dxo-selection>

                  <dxo-row-dragging
                    [onAdd]="onAddTarget"
                    [onDragStart]="onDragStartTarget"
                    group="catalogs"
                    [allowDropInsideItem]="true"
                    [allowReordering]="false"
                    [showDragIcons]="false"
                  ></dxo-row-dragging>

                  <dxi-column dataField="Title" cellTemplate="cellTemplate"></dxi-column>
                  <dxi-column dataField="order" [visible]="false" sortOrder="asc"></dxi-column>

                  <div *dxTemplate="let itemObj of 'cellTemplate'">
                    <div class="treeitem" id="image{{ itemObj.data.treeElementId }}">
                      <div class="container-fluid h-100">
                        <div class="row h-100 d-flex">
                          <div class="col order-0 treeitemimage">
                            <span
                              *ngIf="!itemObj.data.imageUrl"
                              class="glyphicon glyphicon-picture"
                              style="font-size: 36px"
                            ></span>
                            <img *ngIf="itemObj.data.imageUrl" src="{{ itemObj.data.imageUrl }}" style="height: 40px" />
                          </div>

                          <div class="col order-1 overflow-hidden treeitemtext">
                            <div class="treeitemheader">
                              {{ itemObj.data.text }}
                            </div>

                            <div class="treeitemdetail">
                              <div [ngSwitch]="itemObj.data.type">
                                <!--CATALOG-->
                                <div *ngSwitchCase="'cat'">
                                  {{ 'Kategorien' | translate }}: {{ itemObj.data.categoryCount }} -
                                  {{ 'Produkte' | translate }}: {{ itemObj.data.productCount }} ({{
                                    'eindeutige' | translate
                                  }}: {{ itemObj.data.uniqueProductCount }})
                                </div>

                                <!--CATEGORY-->
                                <div *ngSwitchCase="'grp'">
                                  {{ 'Kategorien' | translate }}: {{ itemObj.data.categoryCount }} -
                                  {{ 'Produkte' | translate }}: {{ itemObj.data.productCount }}
                                </div>

                                <!--PRODUCT-->
                                <div *ngSwitchCase="'pro'">
                                  {{ itemObj.data.supplierPid }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <dx-context-menu
                      [dataSource]="menuItems"
                      [width]="200"
                      (onShowing)="onContextMenuShowing($event, itemObj)"
                      target="#image{{ itemObj.data.treeElementId }}"
                      id="context{{ itemObj.data.treeElementId }}"
                      (onItemClick)="itemClick($event, itemObj)"
                    >
                      <div *dxTemplate="let e of 'item'">
                        <div>
                          <span [ngClass]="e.icon"></span>
                          <span *ngIf="e.items" class="dx-icon-spinright"></span>
                          {{ e.text }}
                        </div>
                      </div>
                    </dx-context-menu>
                  </div>
                </dx-tree-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </content-column>
</fullscreen-page>

<dx-popup
  [width]="600"
  [height]="400"
  [showTitle]="true"
  title="{{ 'Kategorie hinzufügen' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="onAdd()">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Kategorie' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newCategoryName" [showClearButton]="true">
              <dx-validator (onInitialized)="saveValidatorInstance($event)">
                <dxi-validation-rule
                  type="required"
                  message="{{ 'Bitte geben Sie einen Kategorienamen an' | translate }}!"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <dx-validation-summary id="summary"></dx-validation-summary>
        <div class="dx-field d-flex flex-row">
          <dx-button
            stylingMode="text"
            type="default"
            text="{{ 'Anlegen' | translate }}"
            [useSubmitBehavior]="true"
          ></dx-button>
        </div>
      </div>
    </form>
  </div>
</dx-popup>
