
<div class="dx-fieldset">

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Klassifizierungssystem' | translate }}</div>
    <div class="dx-field-value">
      <dx-select-box [items]="modelService.catalogService.catalog.classificationSystems"
                     [(value)]="classificationSystem"
                     (onValueChanged)="classificationSystemChanged($event)"
                     displayExpr="name"></dx-select-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="classificationSystem" >
    <div class="dx-field-label">{{ 'Klasse' | translate }}</div>
    <div class="dx-field-value">
      <dx-tree-list [dataSource]="dataSource"
                    [remoteOperations]="{ filtering: true }"
                    height="450"
                    keyExpr="id"
                    rootValue="0"
                    parentIdExpr="parentId"
                    [showRowLines]="false"
                    [showBorders]="false"
                    [columnAutoWidth]="true"
                    (onSelectionChanged)="onClassTreeSelectionChanged($event)">
        <dxo-search-panel [visible]="true" width="250"></dxo-search-panel>
        <dxo-selection mode="single"></dxo-selection>
        <dxi-column dataField="number" sortOrder="asc" [width]="150" caption="Nummer"></dxi-column>
        <dxi-column dataField="name" [width]="300" caption="Bezeichnung"></dxi-column>
      </dx-tree-list>
    </div>
  </div>

  <div class="dx-field">
    <dx-button stylingMode="text" type="default" text="{{ 'Klassifizierung hinzufügen' | translate }}" (onClick)="addClassification()" [disabled]="addClassificationDisabled" class="buttonMargin"></dx-button>
  </div>

</div>

