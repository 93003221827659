<!--90: changeMimePurposes-->

<dx-load-indicator *ngIf="loading" id="large-indicator" height="60" width="60"></dx-load-indicator>

<dx-data-grid *ngIf="!loading"
              [dataSource]="purposesGridDataSource"
              [showBorders]="true"
              noDataText="{{ 'ChangeMimePurposesMacroNoDataMessage' | translate }}"
              [style.marginTop.px]="25">
  <dxi-column dataField="mimePurpose" caption="Ursprünglicher Name"></dxi-column>
  <dxi-column dataField="newMimePurpose" caption="Neuer Name" cellTemplate="newNameTemplate"></dxi-column>
  <div *dxTemplate="let model of 'newNameTemplate'">
    <dx-text-box [(value)]="model.data.newMimePurpose" [showClearButton]="false"></dx-text-box>
  </div>
</dx-data-grid>
