export class DataSourceParams {

  constructor(
    public skip?: number,
    public take?: number,
    public orderBy?: string,
    public desc?: boolean,
    public filter?: string
  ) {
  }
}