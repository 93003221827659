import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { RowInsertedEvent, RowRemovedEvent, RowUpdatedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'np-data-grid',
  templateUrl: './np-data-grid.component.html',
  styleUrls: ['./np-data-grid.component.css']
})
export class NpDataGridComponent implements OnInit {
  dataSource: DataSource;

  _items: object[] = [];

  set items(x: object[]) {
    this._items = x;
    this.dataSourceChange.emit();
  }
  @Input() get items(): object[] {
    return this._items;
  }
  @Output() dataSourceChange: EventEmitter<object[]> = new EventEmitter<object[]>();

  @Input() key: string = 'key';

  @Input() allowAdding: boolean = true;
  @Input() allowUpdating: boolean = true;
  @Input() allowDeleting: boolean = true;

  @Output() onRowInserted: EventEmitter<RowInsertedEvent> = new EventEmitter<RowInsertedEvent>();
  @Output() onRowUpdated: EventEmitter<RowUpdatedEvent> = new EventEmitter<RowUpdatedEvent>();
  @Output() onRowRemoved: EventEmitter<RowRemovedEvent> = new EventEmitter<RowRemovedEvent>();

  constructor(public translate: TranslateService) {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        key: this.key,
        data: this.items
      })
    });
  }

  ngOnInit(): void {}
}
