import { Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'snippet',
  templateUrl: './snippet.component.html',
  styleUrls: ['./snippet.component.css'],
})

export class SnippetComponent {
  isPopupVisible: boolean;
  @Input() metaTitel: string;
  @Input() metaDescription: string;

  constructor(public translate: TranslateService) { }
}
