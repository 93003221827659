import { Component } from '@angular/core';
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { TranslateService } from '@ngx-translate/core';
import { FeatureSystemFilter } from "../../../../Model/Dto/FeatureSystemFilter";
import { ExportFilterService } from '../../../../Services/exportFilter.service';
import { CategoryFilterTreeItem } from '../../../../Model/Catalog/exportFilter/categoryFilterTreeItem';



@Component({
  selector: "userAction-removeUdxOnProducts",
  templateUrl: "userAction-removeUdxOnProducts.component.html"
})
export class UserActionRemoveUdxOnProducts extends UserActionBaseComponent { 
  udxFilters: CategoryFilterTreeItem[];
  checkedUdx: CategoryFilterTreeItem[];
  isUdxLoaded: boolean;

  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.udxFilters = new Array<CategoryFilterTreeItem>();
    this.isUdxLoaded = false;
    this.refreshUdxFilter();
  }

  refreshUdxFilter() {
    this.exportFilterService.getUdxFilterTreeElements(
      this.modelService.catalogService.selectedCatalogId,
      this.modelService.loginService.currentUser.customerId)
      .subscribe((filter: CategoryFilterTreeItem[]) => {
        this.udxFilters = filter;
        this.isUdxLoaded = true;
      });
  }

  startMacro() {
    if (this.checkedUdx == undefined || this.checkedUdx.length == 0) {
      this.modelService.systemService.notifyInfo("Mindestens ein UDX auswählen");
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionParameter = JSON.stringify({ Values: this.checkedUdx });
    startAction.actionType = `RemoveUDXOnProduct`;

    this.modelService.jobService.startUserAction(startAction).subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
