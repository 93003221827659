import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ModelService } from "../../Services/model.service";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { OsgSegmentMapping } from '../../Model/Catalog/OsgSegmentMapping';
import { MavisCatalogMapping } from '../../Model/Catalog/mavis/MavisCatalogMapping';
import { MavisLanguage } from '../../Model/Catalog/mavis/MavisLanguage';
import { UserManagementService } from "../../Services/userManagment.service";
import { ExportParameter } from "../../Model/exportParameter/ExportParameter";

@Component({
  selector: "catalogMavis",
  templateUrl: "catalogMavis.component.html",
  styleUrls: []
})
export class CatalogMavisComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.sendData2ShopZero = this.sendData2ShopZero.bind(this); 
    this.sendData2ShopOne = this.sendData2ShopOne.bind(this);
    this.sendData2ShopTwo = this.sendData2ShopTwo.bind(this);

    this.sendData2ShopThree =this.sendData2ShopThree.bind(this);
    this.sendData2ShopFour = this.sendData2ShopFour.bind(this);
    this.sendData2ShopFive = this.sendData2ShopFive.bind(this);
  }


  pages = [];

  ngOnInit(): void {
    if (!this.model.mavisMapping)
      this.model.mavisMapping = new MavisCatalogMapping();
 

    if (!this.modelService.mavisConnectionService.languages) {
      this.modelService.mavisConnectionService.getLanguages(this.modelService.loginService.currentCustomer.id, false).subscribe((languages: MavisLanguage[]) => {
        this.modelService.mavisConnectionService.languages = languages;
      });
    }

    if (!this.modelService.mavisConnectionService.languagesLive) {
      this.modelService.mavisConnectionService.getLanguages(this.modelService.loginService.currentCustomer.id, true).subscribe((languages: MavisLanguage[]) => {
        this.modelService.mavisConnectionService.languagesLive = languages;
      });
    }

    let testText = "";
    testText = this.translate.instant("Test");
    let liveText = "";
    liveText = this.translate.instant("Live");

    this.pages = [
      { id: 1, title: testText, template: "testTemplate" },
      { id: 2, title: liveText, template: "liveTemplate" }
    ];
  }



  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  isSendCatalogPopupVisible: boolean = false;
  isSendCatalogPopupLiveVisible: boolean = false;
  exportMedia: boolean = true;

  sendData2ShopZero() {
    this.sendData2Shop(0);
  }

  sendData2ShopOne() {
    this.sendData2Shop(1);
  }

  sendData2ShopTwo() {
    this.sendData2Shop(2);
  }

  sendData2ShopThree() {
    this.sendData2Shop(3);
  }

  sendData2ShopFour() {
    this.sendData2Shop(4);
  }

  sendData2ShopFive() {
    this.sendData2Shop(5);
  }

  sendData2Shop(num = 0) {
    switch (num) {
      case 0:
        this.isSendCatalogPopupVisible = true;
        break;
      case 1:
        this.isSendCatalogPopupVisible = false;
        let exportParameter = this.GetExportParameter();
        this.modelService.catalogService.createExportJob(exportParameter).subscribe(
          () => {
            this.translate.get("Die Übertragung ins Testsystem wurde gestartet").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text));
            });
            this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
              .subscribe(() => {

              });
          });
        break;
      case 3:
        this.isSendCatalogPopupLiveVisible = true;
        break;
      case 4:
        this.isSendCatalogPopupLiveVisible = false;
        let exportParameterLive = this.GetExportParameter();
        exportParameterLive.isLive = true;
        this.modelService.catalogService.createExportJob(exportParameterLive).subscribe(
          () => {
            this.translate.get("Die Übertragung ins Livesystem wurde gestartet").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text));
            });
            this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
              .subscribe(() => {

              });
          });
        break;
      default:
        this.isSendCatalogPopupVisible = false;
        this.isSendCatalogPopupLiveVisible = false;
        break;
    }
  }

  private GetExportParameter(): ExportParameter {
    let result = new ExportParameter();
    result.catalogId = this.model.id;
    result.customerId = this.modelService.loginService.currentUser.customerId;
    result.version = "MAVIS";
    result.format = "MAVIS";
    result.includeMime = this.exportMedia;
    return result;
  }


  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get lastSend(): string {

    if (this.model.mavisMapping.lastSend)
      return this.model.mavisMapping.lastSend.toLocaleString("de-DE");
    return "";
  }
  get lastSendLive(): string {

    if (this.model.mavisMapping.lastSendLive)
      return this.model.mavisMapping.lastSendLive.toLocaleString("de-DE");
    return "";
  }


  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
