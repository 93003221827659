import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { HasElementKey, HasSystemKey } from '../../../Model/Catalog/NextPimField';
import { StringResponse } from '../../../Model/Dto/StringResponse';
import { ExcelElement } from '../../../Model/ui/ExcelElement';
import { Select } from '../../../Model/ui/Select';
import { WawiList } from '../../../Model/wawi/WawiList';
import { LoginService } from '../../../Services/login.service';
import { TemplateService } from '../../../Services/template.service';
import { WawiListService } from '../../../Services/wawiList.service';
import { TranslateService } from '@ngx-translate/core';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { AddEvent, DragStartEvent, ReorderEvent } from 'devextreme/ui/sortable';
import { HidingEvent } from 'devextreme/ui/popover';
import { ClickEvent } from 'devextreme/ui/button';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'templateExcel',
  templateUrl: 'templateExcel.component.html',
  styleUrls: ['template.css']
})
export class TemplateExcelComponent implements OnInit {
  @Input() excelModel: ExcelElement[] = [];
  @Input() showToolTips: Boolean = false;
  @Input() showProToolTips: Boolean = false;
  @Input() showPlaceHolders: Boolean = true;
  @Input() exportMode: Boolean = false;

  @Input() selectedElements: string[] = [''];

  @Output() rowSelected = new EventEmitter<string>();
  @Output() removeTemplate = new EventEmitter<string>();
  @Output() updateValue = new EventEmitter<string>();
  @Output() reorderModel = new EventEmitter<any>();
  @Output() replaceItem = new EventEmitter<any>();

  rowId = '';
  mappings: WawiList[] = [];
  calculationImport: Select[] = [];
  calculationExport: Select[] = [];
  numberformat: Select[] = [];
  seperator: Select[] = [];
  operators: Select[] = [];

  get excelModelFiltered(): ExcelElement[] {
    this.excelModel.forEach((column) => {
      if (column.displayName == '') {
        if (this.hasFields(column) && !column.isDisplayNameRequested) {
          column.isDisplayNameRequested = true;
          this.templateService
            .getHeadline(
              column.pimFields[0].field,
              column.pimFields[0].elementKey,
              column.pimFields[0].systemKey,
              this.loginService.currentCustomer.id
            )
            .subscribe((result: StringResponse) => {
              column.displayName = result.value;
            });
        }
      }
      column.updateImportFieldUsed();
    });

    if (this.exportMode) {
      return this.excelModel;
    } else {
      return this.excelModel.filter((e) => e.title != null && e.title != '');
    }
  }

  // Das Popover wird in der registrierten Grid Zelle ausgelöst. Hier wird der State für die Darstellung
  // festgehalten und Elemente, die nicht mehr gebraucht werden, werden automatisch über den Garbage Collector
  // bereinigt.
  popoverView = new WeakMap<ExcelElement, { target: HTMLElement; visible: boolean }>();

  constructor(
    private translate: TranslateService,
    public loginService: LoginService,
    wawiListService: WawiListService,
    public templateService: TemplateService
  ) {
    this.mappings.push(new WawiList(null, '', null, null, null, null));
    wawiListService.getAllListsWithoutFields(loginService.currentCustomer.id).subscribe((result) => {
      result.forEach((wawiList) => {
        if (wawiList.isMapping) {
          this.mappings.push(wawiList);
        }
      });
    });
  }

  ngOnInit(): void {
    console.log('templateExcel', this.excelModel);
    this.calculationImport = [
      new Select('', '', ''),
      new Select(this.translate.instant('g in kg'), this.translate.instant('kg in g'), 'GINKG'),
      new Select(this.translate.instant('t in kg'), this.translate.instant('kg in t'), 'TINKG'),
      new Select(this.translate.instant('mm in m'), this.translate.instant('m in mm'), 'MMINM'),
      new Select(this.translate.instant('cm in m'), this.translate.instant('m in cm'), 'CMINM'),
      new Select(this.translate.instant('Stück in C62'), this.translate.instant('C62 in Stück'), 'STKINC62'),
      new Select(this.translate.instant('Faktor'), this.translate.instant('Faktor'), 'FACTOR')
    ];
    this.calculationExport = [
      new Select('', '', ''),
      new Select(this.translate.instant('g in kg'), this.translate.instant('kg in g'), 'GINKG'),
      new Select(this.translate.instant('t in kg'), this.translate.instant('kg in t'), 'TINKG'),
      new Select(this.translate.instant('mm in m'), this.translate.instant('m in mm'), 'MMINM'),
      new Select(this.translate.instant('cm in m'), this.translate.instant('m in cm'), 'CMINM'),
      new Select(this.translate.instant('Faktor'), this.translate.instant('Faktor'), 'FACTOR')
    ];
    this.numberformat = [
      new Select('', '', ''),
      new Select('1.900,00', '1.900,00', ','),
      new Select('1,900.00', '1,900.00', '.')
    ];
    this.seperator = [
      new Select('', '', ''),
      new Select(this.translate.instant('Leerzeichen'), this.translate.instant('Leerzeichen'), ' '),
      new Select('_', '_', '_'),
      new Select('|', '|', '|'),
      new Select(',', ',', ','),
      new Select('.', '.', '.'),
      new Select(';', ';', ';')
    ];
    this.operators = [
      new Select('', '', 'NONE'),
      new Select(this.translate.instant('Addieren'), this.translate.instant('Addieren'), 'ADDITION'),
      new Select(this.translate.instant('Subtrahieren'), this.translate.instant('Subtrahieren'), 'SUBTRACTION'),
      new Select(this.translate.instant('Multiplizieren'), this.translate.instant('Multiplizieren'), 'MULTIPLICATION'),
      new Select(this.translate.instant('Dividieren'), this.translate.instant('Dividieren'), 'DIVISION')
    ];
  }

  rowClicked(rowId: string) {
    this.rowId = rowId;
    this.rowSelected.emit(rowId);
  }

  async removeClicked(column: ExcelElement) {
    const yes = await confirm(
      this.translate.instant('Möchten Sie alle Felder, die mit dieser Spalte belegt wurden entfernen?'),
      this.translate.instant('Feld aus Template löschen')
    );
    if (yes) {
      this.removeTemplate.emit(column.id);
    }
  }

  getNpRowCount(rowCount: number, column: ExcelElement) {
    let increase = 0;
    if (column.calculation === 'FACTOR') increase++;
    return rowCount + increase;
  }

  getDxRowCount(rowCount: number, column: ExcelElement) {
    let increase = 0;
    return rowCount + increase;
  }

  getClass(rowId: string) {
    var classes = '';
    var element = this.excelModel.filter((element) => element.id == rowId).shift();
    if (element.pimFields.some((x) => x.field != null && x.field != undefined && x.field != '')) {
      classes = 'inUse ';
    }

    if (rowId == this.rowId) {
      classes += 'selected ';
      return classes;
    }

    if (this.selectedElements.length != 0 && !(this.selectedElements.length == 1 && this.selectedElements[0] == '')) {
      this.rowId = '';
      classes += this.getClassIfElementSelected(rowId);
    }

    return classes;
  }

  getClassIfElementSelected(rowId: string): string {
    for (let i = 0; i < this.excelModel.length; i++) {
      let e = this.excelModel[i];
      if (
        e.pimFields.some((x) => {
          let result = x.field;
          if (HasSystemKey(x)) {
            result += '_' + x.systemKey;
            if (HasElementKey(x)) {
              result += '_' + x.elementKey;
            }
          }
          return this.selectedElements.some((y) => y == result) && e.id == rowId.toString();
        })
      ) {
        return 'selected ';
      }
    }

    return '';
  }

  hasFields(column: ExcelElement) {
    return column != undefined && column.pimFields != undefined && column.pimFields.length > 0;
  }

  getDragInfo(column: ExcelElement) {
    if (column.displayName != '') {
      return column.displayName;
    }

    if (this.hasFields(column)) {
      return column.pimFields
        .map((x) => (x.field + '_' + x.systemKey + '_' + x.elementKey).replace('_-1_-1', '').replace('_-1', ''))
        .join(' | ');
    } else {
      return column.title;
    }
  }

  toggleSettings(rowId: string) {
    var htmlBlock = document.getElementById('templateExcelDiv_' + rowId);
    var htmlPlus = document.getElementById('plus_' + rowId);
    if (htmlBlock != null) {
      if (this.exportMode) {
        if (htmlBlock.classList.contains('templateExcelExportColumnBig')) {
          htmlBlock.classList.remove('templateExcelExportColumnBig');
          htmlPlus.classList.remove('templatePlusRound');
        } else {
          htmlBlock.classList.add('templateExcelExportColumnBig');
          htmlPlus.classList.add('templatePlusRound');
        }
      } else {
        if (htmlBlock.classList.contains('templateExcelColumnBig')) {
          htmlBlock.classList.remove('templateExcelColumnBig');
          htmlPlus.classList.remove('templatePlusRound');
        } else {
          htmlBlock.classList.add('templateExcelColumnBig');
          htmlPlus.classList.add('templatePlusRound');
        }
      }
    }
  }

  updateNew(column: ExcelElement) {
    this.updateValue.emit(column.id);
  }

  public update(event: ValueChangedEvent, column?: ExcelElement, prop?: string) {
    if (column && prop) {
      column[prop] = event.value;
    }
    this.updateValue.emit(event.element.id.split('_').shift());
  }

  hasTemplateItemSettings(templateItem: ExcelElement) {
    if (!templateItem) return false;
    const hasCalculation = templateItem.calculation !== null && templateItem.calculation !== '';
    const hasNumberFormat = templateItem.numberformat !== null && templateItem.numberformat !== '';
    const hasSeparator = templateItem.seperator !== null && templateItem.seperator !== '';
    const hasMapping = templateItem.mapping !== null && templateItem.mapping !== '';
    const hasAutomaticSeparation = templateItem.maxCharacters > 0 && templateItem.section >= 1;
    const hasPrefixSuffix = templateItem.prefix?.trim() || templateItem.suffix?.trim();
    const hasFactor = templateItem.factorOperator && templateItem.calculation === 'FACTOR';
    return (
      hasCalculation ||
      hasNumberFormat ||
      hasSeparator ||
      hasMapping ||
      hasAutomaticSeparation ||
      hasPrefixSuffix ||
      hasFactor
    );
  }

  onOpenFieldSettings(event: ClickEvent, excelElement: ExcelElement) {
    this.popoverView.set(excelElement, { target: event.element, visible: true });
  }

  onCloseFieldSettings(event: HidingEvent, excelElement: ExcelElement) {
    if (event.cancel) return;
    this.popoverView.set(excelElement, { target: event.element, visible: false });
  }

  onDragStart(e: DragStartEvent) {
    e.itemData = e.fromData[e.fromIndex];
    e.itemData.source = 'excelModel';
    e.itemData.exportMode = this.exportMode;
  }

  onDrop(e: AddEvent) {
    if (e.itemData.source != 'product') {
      return;
    }
    // TODO:
    // wenn man "einfügen an pos. X" realisieren will, müsste man in der methode,
    // die am ende durch dies event getriggert wird, unterscheiden, ob vorhanden (==> "replace")
    // oder nicht vorhanden (==> "add" am index X)

    this.replaceItem.emit(e);
  }

  onReorder(e: ReorderEvent) {
    this.reorderModel.emit(e);
  }
}
