import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";

@Injectable()
export class AddonService {

  private readonly apiUrl = 'api/Addon';

  constructor(private http: HttpClient,

  ) {
  }

  public getAllNormalAddons(): Observable<string[]> {
    const url = `${this.apiUrl}/GetAllNormalAddons`;
    const options = {};
    return this.http.get<string[]>(url, options);
  }

  public getAllExportAddons(): Observable<string[]> {
    const url = `${this.apiUrl}/GetAllExportAddons`;
    const options = {};
    return this.http.get<string[]>(url, options);
  }

  public getAllClassificationAddons(): Observable<string[]> {
    const url = `${this.apiUrl}/GetAllClassificationAddons`;
    const options = {};
    return this.http.get<string[]>(url, options);
  }

  public getAddonsOfCustomer(customerId: string): Observable<string[]> {
    const url = `${this.apiUrl}/GetAddonsOfCustomer`;
    const params = new HttpParams().append("customerId", customerId);
    const options = { params };
    return this.http.get<string[]>(url, options);
  }

  public setAddonsToCustomer(customerId: string, addons: string[]): Observable<string[]> {
    const url = `${this.apiUrl}/SetAddonsToCustomer`;
    const params = new HttpParams().append("customerId", customerId);
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    const options = { headers, params };
    return this.http.post<string[]>(url, addons, options);
  }

  public addonSelectionChanged(customerId: string, addedAddons: string[], removedAddons: string[]): Observable<string[]> {
    const url = `${this.apiUrl}/AddonSelectionChanged`;
    const params = new HttpParams().append("customerId", customerId);
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    const options = { headers, params };
    const changedAddons = { addedAddons, removedAddons };
    return this.http.post<string[]>(url, changedAddons, options);
  }

  public checkCurrentCustomerAddons(customerId: string): Observable<string[]> {
    const url = `${this.apiUrl}/CheckCurrentCustomerAddons`;
    const params = new HttpParams().append("customerId", customerId);
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    const options = { headers, params };
    return this.http.get<string[]>(url, options);
  }

  public checkCurrentAddons(license: string, addons: string[]): Promise<string[]> {
    const url = `${this.apiUrl}/CheckCurrentAddons`;
    const params = new HttpParams().append("license", license);
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    const options = { headers, params };
    return lastValueFrom(this.http.post<string[]>(url, addons, options));
  }
}
