export class CustomerLanguage {

  constructor(
 
    public id?: string,
    public customerId?: string,
    public language?: string    
  ) {
  }
}
