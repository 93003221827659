export class IntershopConnection {

  constructor(
    public domain?: string,
    public channelId?: string,
    public organisationId?: string,
    public updateMode?: string,
    public ftpServerId?: number,
    public productFilePath?: string,
    public categoryFilePath?: string,
    public attributesFilePath?: string,
    public mediaFilePath?: string,
    public exportMedia?: boolean,
  ) {
  }
}

