import { ValidationField } from "./ValidationField";

export class ValidationRule {

  constructor(
    public id?: string,
    public printName?: string,
    public order?: number,
    public field?: ValidationField,
    public stateText?: string,
    public points?: number,
    public pointsPerCount?: number,
    public maxPoints?: number,
    public isRequired?: boolean,
    public minLength?: number,
    public minCount?: number,
    public pattern?: string,
    public minWidth?: number,
    public minHeight?: number,
    public checkIfExists?: boolean,
    public area?: string,
    public displayMessage?: string,
    public groupId?: string,
    public featureSystemNameFilter?: string,
    public featureNameFilter?: string,
    public mimeTypeFilter?: string,
    public mimePurposeFilter?: string,
    public mimeDescriptionFilter?: string,
    public referenceTypeFilter?: string,
    public udxGroupFilter?: string,
    public supplierNameFilter?: string,
    public packingUnitCodeFilter?: string,
    public priceListLiveFilter?: boolean,
    public priceTypeFilter?: string,
    public isConditional?:boolean,
    public conditionalRuleIds?: string[]
  ) {
  }
}


