<fullscreen-page>
  <content-column helpSwitch="true" look="dark">
    <logo slot="toolbar" [routerLink]="['/featureMapping']" look="dark" route="['/featureMapping']"></logo>
    <div slot="content" class="p-3 flex-fill w-100 mx-auto overflow-hidden">
      <dx-toolbar>
        <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Aktualisieren' | translate, onClick: refresh }"></dxi-item>
        <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog auswerten' | translate, onClick: onShowAnalyseCatalog }"></dxi-item>
        <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog mappen' | translate, onClick: onShowMapCatalog }"></dxi-item>
        <dxi-item location="after" widget="dxButton" [options]="{ type: 'default', text: 'Profil exportieren' | translate, onClick: onExportProfil }"></dxi-item>
        <dxi-item location="after" widget="dxButton" [options]="{ type: 'default', text: 'Profil importieren' | translate, onClick: onImportProfil }"></dxi-item>
      </dx-toolbar>
      <dx-popup class="popup"
                [width]="600"
                [height]="450"
                [showTitle]="true"
                title="{{ 'Katalog auswerten' | translate }}"
                [dragEnabled]="false"
                [hideOnOutsideClick]="true"
                [showCloseButton]="true"
                [(visible)]="analyseCatalogVisible"
                contentTemplate="popupContent">
        <div *dxTemplate="let data of 'popupContent'">


          <div class="dx-field">
            <div class="dx-field-label">{{ 'Account' | translate }}</div>
            <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
              <label class="placeLabel {{ selectedCustomer?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT</label>
              <dx-select-box [(value)]="selectedCustomer"
                             [dataSource]="customers"
                             displayExpr="name"
                             [searchEnabled]="true"
                             valueExpr="id"
                             placeholder=""></dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'Quellkatalog' | translate }}</div>
            <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
              <label class="placeLabel {{ catalogToAnalyseId?.length > 0 ? 'placeLabelSmall' : '' }}">SOURCE CATALOG</label>
              <dx-select-box [(value)]="catalogToAnalyseId"
                             [dataSource]="catalogs"
                             [searchEnabled]="true"
                             displayExpr="catalogName"
                             valueExpr="id"
                             placeholder=""></dx-select-box>
            </div>
          </div>
          <div class="dx-field ">
            <div class="dx-field-label" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">{{ 'Hersteller berücksichtigen' | translate }}</div>
            <div class="dx-field-value d-flex flex-row">

              <div class="flex-fill {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttUseManufacturer">

                <div class="d-flex flex-row pt-2">
                  <dx-switch [(value)]="useManufacturer" (onValueChanged)="update()" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
                </div>
              </div>
            </div>
            <dx-tooltip target="#ttUseManufacturer" class="toolTip toolTipField" [(visible)]="v[1]">
              <div *dxTemplate="let data = data of 'content'">
                <p>
                  {{ 'Soll das Profil abhängig vom Hersteller (MANUFACTURER_NAME) sein' | translate }}.
              </div>
            </dx-tooltip>
          </div>

          <div class="dx-field ">
            <div class="dx-field-label" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">{{ 'Featuresystem berücksichtigen' | translate }}</div>
            <div class="dx-field-value d-flex flex-row">

              <div class="flex-fill {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttUseFeatureSystem">

                <div class="d-flex flex-row pt-2">
                  <dx-switch [(value)]="useFeatureSystem" (onValueChanged)="update()" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
                </div>
              </div>
            </div>
            <dx-tooltip target="#ttUseFeatureSystem" class="toolTip toolTipField" [(visible)]="v[1]">
              <div *dxTemplate="let data = data of 'content'">
                <p>
                  {{ 'Soll das Profil abhängig vom Featuresystem (FEATURE_SYSTEM_NAME) sein' | translate }}.
              </div>
            </dx-tooltip>
          </div>


          <dx-toolbar *ngIf="catalogToAnalyseId">
            <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Starten' | translate, onClick: startAnalyse }"></dxi-item>
          </dx-toolbar>
        </div>
      </dx-popup>

      <dx-popup class="popup"
                [width]="600"
                [height]="300"
                [showTitle]="true"
                title="{{ 'Katalog mappen' | translate }}"
                [dragEnabled]="false"
                [hideOnOutsideClick]="true"
                [showCloseButton]="true"
                [(visible)]="mapCatalogVisible"
                contentTemplate="popupContent">
        <div *dxTemplate="let data of 'popupContent'">


          <div class="dx-field">
            <div class="dx-field-label">{{ 'Account' | translate }}</div>
            <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
              <label class="placeLabel {{ selectedCustomer?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT</label>
              <dx-select-box [(value)]="selectedCustomer"
                             [dataSource]="customers"
                             [searchEnabled]="true"
                             displayExpr="name"
                             valueExpr="id"
                             placeholder=""></dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'Katalog' | translate }}</div>
            <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
              <label class="placeLabel {{ catalogToMapId?.length > 0 ? 'placeLabelSmall' : '' }}">CATALOG</label>
              <dx-select-box [(value)]="catalogToMapId"
                             [dataSource]="catalogs"
                             displayExpr="catalogName"
                             [searchEnabled]="true"
                             valueExpr="id"
                             placeholder=""></dx-select-box>
            </div>
          </div>



          <dx-toolbar *ngIf="catalogToMapId">
            <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Starten' | translate, onClick: startMapping }"></dxi-item>
          </dx-toolbar>
        </div>
      </dx-popup>

    <dx-popup [width]="500"
              [height]="300"
              [showTitle]="true"
              title="{{ 'Profil importieren' | translate }}"
              [dragEnabled]="false"
              [hideOnOutsideClick]="true"
              [showCloseButton]="true"
              [(visible)]="importProfileVisible"
              contentTemplate="popupContent">
      <div *dxTemplate="let data of 'popupContent'">
        <importfeatureMappingProfil></importfeatureMappingProfil>
      </div>
    </dx-popup>
      
      <div class="d-flex flex-row w-100">
        <div class="flex-column w-50">
          <div class="dx-fieldset">
            <div class="dx-field ">
              <div class="dx-field-label" (mouseenter)="ttt(0)" (mouseleave)="ttt(0)">{{ 'Name' | translate }}</div>
              <div class="dx-field-value d-flex flex-row">

                <div class="flex-fill {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttName">
                  <label class="placeLabel {{ model.name?.length>0 ? 'placeLabelSmall' : '' }}">NAME</label>
                  <dx-text-box [(value)]="model.name"
                               [showClearButton]="true"
                               (onValueChanged)="update()">
                    <dx-validator>
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </div>
              <dx-tooltip target="#ttName" class="toolTip toolTipField" [(visible)]="v[0]">
                <div *dxTemplate="let data = data of 'content'">
                  <p>
                    {{ 'Der Name des Mappingprofiles' | translate }}.
                </div>
              </dx-tooltip>
            </div>
          </div>

        </div>
        <div class="flex-column w-50">
          <div class="dx-fieldset">

          </div>
        </div>
      </div>





      <div class="d-flex h-100 flex-row">
        <div class="w-50 flex-column px-1 flex-fill overflow-hidden">
          <h6>{{ 'nicht zugeordnet' | translate }}</h6>

          <dx-data-grid #inputGrid
                        class="h-100"
                        [dataSource]="inputs"
                        [allowColumnReordering]="true"
                        [rowAlternationEnabled]="true"
                        [allowColumnResizing]="true"
                        [showBorders]="true">
            <dxo-row-dragging [onAdd]="onAdd" group="inputMapping"></dxo-row-dragging>
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Suchen' | translate }}"></dxo-search-panel>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureMappingInputsStateNewUIV2"></dxo-state-storing>
            <dxo-filter-row [visible]="true" applyFilter="Immediately"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>

            <dxi-column dataField="name" dataType="string" caption="{{ 'Name' | translate }}"></dxi-column>
            <dxi-column dataField="manufacturer" dataType="string" caption="{{ 'Herteller' | translate }}"></dxi-column>
            <dxi-column dataField="systemName" dataType="string" caption="{{ 'Featuresystem Name' | translate }}"></dxi-column>
            <dxi-column dataField="value" dataType="string" caption="{{ 'Wert' | translate }}"></dxi-column>
            <dxi-column dataField="unit" dataType="string" caption="{{ 'Einheit' | translate }}"></dxi-column>
            <dxi-column dataField="isIgnored" dataType="boolean" cellTemplate="isIgnoredcellTemplate" caption="{{ 'Ignorieren' | translate }}"></dxi-column>
            <div *dxTemplate="let itemObj of 'isIgnoredcellTemplate'">
              <dx-button *ngIf="itemObj.data.isIgnored" icon="isnotblank" (onClick)="isIgnoredClick(itemObj)"> </dx-button>
              <dx-button *ngIf="!itemObj.data.isIgnored" icon="isblank" (onClick)="isIgnoredClick(itemObj)"> </dx-button>
            </div>
          </dx-data-grid>



        </div>

        <div class="w-50 flex-column px-1 flex-fill overflow-hidden">
          <h6>{{ 'zugeordnet' | translate }}</h6>

          <dx-data-grid #resultGrid
                        class="h-100"
                        (onToolbarPreparing)="onResultGridToolbarPreparing($event)"
                        [dataSource]="mappingResults"
                        [allowColumnReordering]="true"
                        [rowAlternationEnabled]="true"
                        [allowColumnResizing]="true"
                        [showBorders]="true"
                        (onRowUpdated)="onUpdateMappingResult($event)"
                        (onEditingStart)="startEditing($event)">
            <dxo-editing mode="popup"
                         [allowUpdating]="true"
                         [allowAdding]="true"
                         [allowDeleting]="true">
              <dxo-popup title="{{ 'Mapping Ergebnis' | translate }}"
                         [showTitle]="true"
                         [width]="1200"
                         [height]="325">
              </dxo-popup>
              <dxo-form [colCount]="3">

                <dxi-item dataField="name">
                  <dxo-label text="{{ 'Name' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="value">
                  <dxo-label text="{{ 'Wert' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="unit">
                  <dxo-label text="{{ 'Einheit' | translate }}"></dxo-label>
                </dxi-item>



              </dxo-form>
            </dxo-editing>
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-filter-row [visible]="true" applyFilter="Immediately"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Suchen' | translate }}"></dxo-search-panel>
            <div *dxTemplate="let toolbaritem of 'addMappingResultTemplate'">
              <dx-button stylingMode="text" location="after" type="default" text="{{ 'Neues Mapping' | translate }}" (onClick)="addMapping()" hint="{{ 'Legt ein neues Mapping an, dem dann nicht zugeordnete Werte zugewiesen werden können' | translate }}"></dx-button>
            </div>
            <dxo-row-dragging [onAdd]="onAddResult" group="inputMapping"></dxo-row-dragging>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>

            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureMappingResultsStateNewUIV2"></dxo-state-storing>

            <dxi-column dataField="name" dataType="string" caption="{{ 'Name' | translate }}"></dxi-column>
            <dxi-column dataField="value" dataType="string" caption="{{ 'Wert' | translate }}"></dxi-column>
            <dxi-column dataField="unit" dataType="string" caption="{{ 'Einheit' | translate }}"></dxi-column>
            <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
            <div *dxTemplate="let results of 'detail'">
              <dx-data-grid [dataSource]="results.data.mappingInputs"
                            [allowColumnReordering]="true"
                            [rowAlternationEnabled]="true"
                            [allowColumnResizing]="true"
                            [showBorders]="true"
                            (onContentReady)="contentReady($event,results.data)"
                            (onRowRemoving)="onDeleteMappingClicked($event)">
                <dxo-editing [allowDeleting]="true">  </dxo-editing>
                <dxo-scrolling mode='virtual'></dxo-scrolling>
                <dxo-row-dragging [onAdd]="onAdd" group="inputMapping"></dxo-row-dragging>

                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureMappingResultInputsStateNewUIV2"></dxo-state-storing>
                <dxi-column dataField="name" dataType="string" caption="{{ 'Name' | translate }}"></dxi-column>
                <dxi-column dataField="manufacturer" dataType="string" caption="{{ 'Herteller' | translate }}"></dxi-column>
                <dxi-column dataField="systemName" dataType="string" caption="{{ 'Featuresystem Name' | translate }}"></dxi-column>
                <dxi-column dataField="value" dataType="string" caption="{{ 'Wert' | translate }}"></dxi-column>
                <dxi-column dataField="unit" dataType="string" caption="{{ 'Einheit' | translate }}"></dxi-column>
                <dxi-column dataField="isRule" dataType="boolean" caption="{{ 'Regel' | translate }}"></dxi-column>

              </dx-data-grid>

            </div>
          </dx-data-grid>
          <dx-popup class="popup"
                    [width]="1200"
                    [height]="900"
                    [showTitle]="true"
                    title="{{ 'Mapping' | translate }}"
                    [hideOnOutsideClick]="true"
                    [showCloseButton]="true"
                    [(visible)]="showEditResult"
                    contentTemplate="popupResultContent">
            <div *dxTemplate="let data of 'popupResultContent'">

              <div *ngIf="selectedMappingResult">

                <div class="d-flex h-100 flex-row">
                  <div class="flex-column px-1 flex-fill overflow-hidden">
                    <div class="dx-fieldset">
                      <div class="dx-field ">
                        <div class="dx-field-label">{{ 'Name' | translate }}</div>
                        <div class="dx-field-value d-flex flex-row">
                          <dx-text-box [(value)]="selectedMappingResult.name"
                                       [showClearButton]="true">
                            <dx-validator>
                              <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex h-100 flex-row">
                  <div class="flex-column px-1 flex-fill overflow-hidden">
                    <div class="dx-fieldset">
                      <div class="dx-field ">
                        <div class="dx-field-label">{{ 'Wert' | translate }}</div>
                        <div class="dx-field-value d-flex flex-row">
                          <dx-text-box [(value)]="selectedMappingResult.value"
                                       [showClearButton]="true">
                            <dx-validator>
                              <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex h-100 flex-row">
                  <div class="flex-column px-1 flex-fill overflow-hidden">
                    <div class="dx-fieldset">
                      <div class="dx-field ">
                        <div class="dx-field-label">{{ 'Einheit' | translate }}</div>
                        <div class="dx-field-value d-flex flex-row">
                          <dx-text-box [(value)]="selectedMappingResult.unit"
                                       [showClearButton]="true">

                          </dx-text-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <dx-data-grid [dataSource]="selectedMappingResult.mappingInputs"
                              [allowColumnReordering]="true"
                              [rowAlternationEnabled]="true"
                              [allowColumnResizing]="true"
                              [height]="440"
                              [showBorders]="true"
                              (onContentReady)="contentReady($event,selectedMappingResult)"
                              (onRowRemoving)="onDeleteMappingClicked($event)">
                  <dxo-editing [allowDeleting]="true"> </dxo-editing>
                  <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>

                  <dxi-column dataField="name" dataType="string" caption="{{ 'Name' | translate }}"></dxi-column>
                  <dxi-column dataField="manufacturer" dataType="string" caption="{{ 'Herteller' | translate }}"></dxi-column>
                  <dxi-column dataField="systemName" dataType="string" caption="{{ 'Featuresystem Name' | translate }}"></dxi-column>
                  <dxi-column dataField="value" dataType="string" caption="{{ 'Wert' | translate }}"></dxi-column>
                  <dxi-column dataField="unit" dataType="string" caption="{{ 'Einheit' | translate }}"></dxi-column>
                  <dxi-column dataField="isRule" dataType="boolean" caption="{{ 'Regel' | translate }}"></dxi-column>
                  <dxi-column dataField="factor" dataType="number" caption="{{ 'Faktor' | translate }}"></dxi-column>
                </dx-data-grid>

              </div>
              <dx-toolbar>
                <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Zu Regel umwandeln' | translate, onClick: createRule }"></dxi-item>
                <dxi-item location="after" widget="dxButton" [options]="{ type: 'default', text: 'Speichern' | translate, onClick: saveMappingResult }"></dxi-item>
                <dxi-item location="after" widget="dxButton" [options]="{ type: 'default', text: 'Abbrechen' | translate, onClick: closeMappingResultPopUp }"></dxi-item>
              </dx-toolbar>


            </div>
          </dx-popup>
        </div>
      </div>
    </div>

  <dx-popup             [width]="500"
            [height]="300"
            [showTitle]="true"
            title="{{ 'Artikelnummern Mappings importieren' | translate }}"
            [dragEnabled]="false"
            [hideOnOutsideClick]="true"
            [showCloseButton]="true"
            [(visible)]="importProfileVisible"
            contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
      <importfeatureMappingProfil></importfeatureMappingProfil>
    </div>
  </dx-popup>


  </content-column>
</fullscreen-page>
