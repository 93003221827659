import { Input, Output, EventEmitter, Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ClassificationSystem } from "../Model/classification/ClassificationSystem";
import { ClassificationClass } from '../Model/classification/ClassificationClass';
import { ClassificationClassTree } from '../Model/classification/ClassificationClassTree';

@Component({
  selector: "productClassification",
  templateUrl: "productClassification.component.html"
})
export class ProductClassificationComponent implements OnInit {

  @Input() addClassificationDisabled: boolean = true;
  @Output() windowClose = new EventEmitter<any>();
  @Output() addClassificationFeatures = new EventEmitter<any>();
  classificationSystem: ClassificationSystem;
  classificationClass: ClassificationClass;
  classTree: ClassificationClassTree[];
  public dataSource: any;

  constructor(public modelService: ModelService) {

  }

  ngOnInit(): void {

    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.classTree = new Array<ClassificationClassTree>();
    if (this.classificationSystem) {
      this.setTreeDataSource(this.classificationSystem.id,this.modelService);
    }

  
  }

  setTreeDataSource(classificationSystemId: string, modelService : ModelService) {
    this.dataSource = {
      load: async function (loadOptions) {
        let parentId = "";
        if (loadOptions.parentIds != null) {
          if (loadOptions.parentIds.length > 5) {
            parentId = loadOptions.parentIds[0] + "," + classificationSystemId;
          } else {
            parentId = loadOptions.parentIds.join(",");
          }
        }

        if (classificationSystemId) {
          this.classTree = await modelService.classificationService.getTreeFiltered(classificationSystemId, parentId, loadOptions.filter);
        } else {
          this.classTree = new Array<ClassificationClassTree>();
        }

        return this.classTree;
      }
    };  
  }


  classificationSystemChanged(e) {
    this.modelService.classificationService.selectedClassId = "";
    this.addClassificationDisabled = true;

    if (this.classificationSystem) {
      this.setTreeDataSource(this.classificationSystem.id, this.modelService);
    }
  }

  onClassTreeSelectionChanged(e) {
        
    const selectedRows = e.selectedRowsData;

    if (selectedRows && selectedRows.length) {
      this.modelService.classificationService.selectedClassId = selectedRows[0].id;
      this.addClassificationDisabled = selectedRows[0].notAssignable;
    } else {
      this.modelService.classificationService.selectedClassId = "";
      this.addClassificationDisabled = true;
    }
  }


  addClassification() {
    this.modelService.classificationService.selectedSystem = this.classificationSystem;
    this.addClassificationFeatures.emit();
    this.windowClose.emit();
  }

}


