export class ProductState {

  constructor(
    public idKey?: string,
    public customerId?: string,
    public name?: string,

  ) {

  }

}
