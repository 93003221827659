export class NewCatalog {

  constructor(
    public name?: string,
    public customerId?: string,
    public catalogId?: string,
    public isVirtual?: boolean
  ) {
  }
}
