<fullscreen-page>
  <content-column look="dark">
    <logo slot="toolbar" [routerLink]="['/']" look="dark" route="['/catalogs']"></logo>
    <div slot="content" class="h-100 p-3">
      <div class="d-block h-100 w-100 mx-auto" style="max-width: 600px;">


        <form (submit)="onRequestPassword()">
          <div class="dx-fieldset">

            <div class="dx-fieldset-header">{{ 'Neues Password anfordern' | translate }}</div>
            <div class="dx-field inputWithPlaceHolder">
              <label class="placeLabel {{ model.email?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Email' | translate }}</label>
              <dx-text-box [(value)]="model.email" [showClearButton]="true" width="100%">
                <dx-validator>
                  <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihre Emailadresse ein' | translate }}."></dxi-validation-rule>
                  <dxi-validation-rule type="email"
                                       message="{{ 'Das ist leider keine gültige Emailadresse' | translate }}">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>

            <dx-validation-summary id="summary"></dx-validation-summary>

            <div class="dx-field d-flex flex-row">
              <dx-button stylingMode="text" type="default" text="{{ 'Neues Password anfordern' | translate }}" [useSubmitBehavior]="true"></dx-button>
            </div>

          </div>
        </form>


      </div>
    </div>
  </content-column>
</fullscreen-page>
