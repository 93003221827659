import { Component, OnInit, ViewChild } from '@angular/core';
import { NewCatalog } from '../../Model/Dto/NewCatalog';
import { ModelService } from '../../Services/model.service';
import { Message } from '../../Model/System/Message';
import { CustomerWiki } from '../../Model/System/CustomerWiki';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { Catalog } from '../../Model/Catalog/Catalog';
import { UserManagementService } from '../../Services/userManagment.service';
import { PimLicense } from '../../Model/User/PimLicense';
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'cat-list',
  templateUrl: './catList.component.html',
  styleUrls: ['./catList.component.css']
})
export class CatListComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  welcomePopupVisible: boolean = false;
  catalogToDelete: Catalog;

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public userManagment: UserManagementService
  ) {
    this.onExpandAll = this.onExpandAll.bind(this);
    this.onCollapseAll = this.onCollapseAll.bind(this);
    this.refresh = this.refresh.bind(this);
    this.duplicateCatalog = this.duplicateCatalog.bind(this);
    this.copyCatalog = this.copyCatalog.bind(this);
    this.createRestorePoint = this.createRestorePoint.bind(this);
    this.showCustomDeleteDialog = this.showCustomDeleteDialog.bind(this);

    this.modelService.systemService.currentNavigationTitle = this.translate.instant('Katalog Übersicht');

    this.modelService.loginService.selectedProductTabIndex = 0;
  }

  datagridLoaded = false;
  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.datagridLoaded = false;
    this.modelService.catalogService.selectedCatalogId = null;
    this.modelService.catalogService.isJobRunning = false;
    this.modelService.catalogService.importCatalogVisible = false;
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(['/']);
      return;
    }
    this.modelService.catalogService.catalog = null;
    this.modelService.catalogService.setCatalogs(this.modelService.loginService.currentUser.customerId);

    if (this.modelService.loginService.currentUser.newUser) {
      this.welcomePopupVisible = true;
      this.modelService.loginService.currentUser.newUser = false;
      this.modelService.loginService.updateUser(this.modelService.loginService.currentUser);
    }
  }

  public backUpVisible: boolean = false;
  public jobDataVisible: boolean = false;
  public addCatalogVisible: boolean = false;

  get model() {
    return this.modelService.catalogService.catalogs;
  }

  public newCatalogModel: NewCatalog = new NewCatalog();

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newCatalogButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'importButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'pasteCatalogButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'jobdataButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'backupButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'minGroupsButtonTemplate'
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'maxGroupsButtonTemplate'
    });
  }

  onRowPrepared(e) {
    if (e.rowType == 'data') {
      if (e.data.isVirtual == true) {
        e.rowElement.style.backgroundColor = '#D6F5F3';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', ''); // Standard-Klasse wegräumen, sonst greif es nur auf Nicht-Alternating-Rows
      }

      if (e.data.isDemoCatalog) {
        e.rowElement.style.backgroundColor = 'rgba(255, 122, 89, 0.2)';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', ''); // Standard-Klasse wegräumen, sonst greif es nur auf Nicht-Alternating-Rows
      }
    }
  }

  onExpandAll() {
    this.dataGrid.instance.expandAll();
  }

  onCollapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  get importCatalogVisible(): boolean {
    return this.modelService.catalogService.importCatalogVisible;
  }

  set importCatalogVisible(value: boolean) {
    this.modelService.catalogService.importCatalogVisible = value;
  }

  getLanguageFlag(language: string) {
    return this.modelService.loginService.getLanguageFlag(language.toLowerCase());
  }

  showAddCatalog() {
    this.addCatalogVisible = true;
  }

  showImportCatalog() {
    this.modelService.catalogService.importCatalogVisible = true;
  }

  onEditClicked(e) {
    this.modelService.catalogService.selectedCatalogId = e.key.id;
    this.modelService.router.navigate(["/catalog/" + e.key.customerId + "/" + e.key.id + '/info']);
  }

  catalogSelected(data: Catalog) {
    this.modelService.catalogService.selectedCatalogId = data.id;
    this.modelService.router.navigate(["/catalog/" + data.customerId + "/" + data.id + '/info']);
  }

  updateResponsible(data) {
    this.modelService.catalogService.setResponsible();
    this.modelService.catalogService.catalog = data.data;
    this.modelService.catalogService.updateCatalog();
  }

  update(data) {
    this.modelService.catalogService.catalog = data.data;
    this.modelService.catalogService.updateCatalog();
  }

  duplicateCatalog(e) {
    this.translate.get('Der Katalog wird dupliziert').subscribe((text: string) => {
      this.modelService.systemService.notify(new Message(text));
    });
    this.modelService.catalogService.duplicateCatalog(e.row.data.id);
  }

  createRestorePoint(e) {
    this.translate.get('Ein Wiederherstellungspunkt wird per Job erzeugt').subscribe((text: string) => {
      this.modelService.systemService.notify(new Message(text));
    });
    this.modelService.catalogService.createRestorePoint(e.row.data.id);
  }

  showCustomDeleteDialog(e) {
    let catalogToDeleteIsVirtual = e.row.data.isVirtual;
    this.catalogToDelete = e.row.data;
    const virtualCatalogs = this.modelService.catalogService.catalogs.filter(catalog => catalog.isVirtual);
    if(!catalogToDeleteIsVirtual && virtualCatalogs.length > 0) {      
      this.modelService.catalogService.checkVirtualCatalogs(this.catalogToDelete.id, this.modelService.loginService.currentCustomer.id).then((result: Record<string, number>) => {
        if (Object.keys(result).length > 0) {
          this.showDeleteDialogWithVirtualCatalogInfo(result);
        }
        else {
          this.showDeleteDialog();
        }
      });
    }
    else {
      this.showDeleteDialog();
    }
  }

  showDeleteDialog() {
    let normalCatalogDialog = custom({
      showTitle: true,
      title: this.translate.instant("Katalog löschen"),
      dragEnabled: false,
      messageHtml: this.translate.instant("Wollen Sie den Katalog wirklich löschen?"),

      buttons: [
        {
          text: this.translate.instant("Ja"),
          onClick: (e) => {
            this.onDeleteClicked();
          }
        },
        {
          text: this.translate.instant("Nein"),
        }
      ]
    });
    normalCatalogDialog.show()
  }

  showDeleteDialogWithVirtualCatalogInfo(result: Record<string, number>) {
    var rootUrl = window.location.origin;
    var catalog: Catalog;
    let messageText = "<p>" + this.translate.instant("DeleteNoteVirtualCatalogs") + " <a href='https://knowledge.nextpim.de/wichtige-funktionen#virtuelle-kataloge' target='_blank'>nextPIM Wiki</a>.</p><p>Anzahl Verlinkungen:</p>" +
      "<table><th>Katalog</th><th>Anzahl</th>";
    for (const [key, value] of Object.entries(result)) {
      catalog = this.modelService.catalogService.catalogs.find(cat => cat.id == key);
      if (catalog) {
        messageText += `<tr><td><a href="${rootUrl}/catalog/${this.modelService.loginService.currentCustomer.id}/${catalog.id}/info">${catalog.catalogName}</a></td><td style="text-align: center; vertical-align: middle;">${value}</td></tr>`;
      }
    }
    messageText += "</table>";

    let virtualCatalogDialog = custom({
      showTitle: true,
      title: this.translate.instant("Katalog löschen"),
      dragEnabled: false,
      messageHtml: messageText,

      buttons: [{
        text: "Löschen bestätigen",
        onClick: (e) => {
          this.onDeleteClicked();
        }
      },
      {
        text: "Abbrechen",
        onClick: (e) => {
        }
      },
      ]
    });
    virtualCatalogDialog.show();
  }

  onDeleteClicked() {
    if (
      this.modelService.loginService.currentCustomer.pimLicense == PimLicense.Onboarding &&
      this.modelService.catalogService.catalogs.length > 0 &&
      this.catalogToDelete.isDemoCatalog &&
      this.modelService.catalogService.catalogs.filter((cat) => cat.isDemoCatalog).length == 1
    ) {
      this.modelService.systemService.notifyInfo(
        this.translate.instant(
          'Demo-Katalog kann nicht gelöscht werden. Es muss mindestens ein Demo-Katalog vorhanden sein.'
        )
      );
    } else {
      this.modelService.catalogService.remCatalog(this.catalogToDelete.id);
    }
    this.refresh();
  }

  copyCatalog(e) {
    this.modelService.catalogService.copyCatalogId = e.row.data.id;
    this.modelService.catalogService.copyCatalogCustomerId = this.modelService.loginService.currentUser.customerId;
  }

  get copyCatalogId() {
    return this.modelService.catalogService.copyCatalogId;
  }

  pasteCatalog() {
    this.translate.get('Katalog wird eingefügt').subscribe((text: string) => {
      this.modelService.systemService.notify(new Message(text));
    });

    this.modelService.catalogService.duplicateCatalog(
      this.modelService.catalogService.copyCatalogId,
      this.modelService.catalogService.copyCatalogCustomerId
    );
  }

  //BackUpWindow

  //EXPORT WINDOW
  get fullScreen() {
    return this.modelService.catalogService.viewService.fullScreen;
  }

  set fullScreen(value: boolean) {
    this.modelService.catalogService.viewService.fullScreen = value;
  }

  toFullScreen() {
    this.modelService.catalogService.viewService.fullScreen = true;
  }

  toWindow() {
    this.modelService.catalogService.viewService.fullScreen = false;
  }

  // never used??
  //showTranslate() {
  //  if (this.modelService.loginService.languageFlags.length == 1) {
  //    this.translate.get("Es wurden noch keine Sprachen angelegt, in die eine Übersetzung vorgenommen werden kann.").subscribe((text: string) => {
  //      this.modelService.systemService.notify(new Message(text), 2500);
  //    });
  //  } else {
  //    this.modelService.catalogService.translateVisible = true;
  //  }
  //}

  hideExport() {
    this.modelService.catalogService.exportVisible = false;
  }

  showBackUp() {
    this.backUpVisible = true;
  }

  hideBackUp() {
    this.backUpVisible = false;
  }

  showJobData() {
    this.jobDataVisible = true;
  }

  hideJobData() {
    this.jobDataVisible = false;
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService
        .loadHelp(this.modelService.loginService.currentUser.customerId)
        .subscribe((result: CustomerWiki) => (this.modelService.systemService.help = result.entries));
    }
  }

  //SETTINGS
  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders(): boolean {
    return this.modelService.loginService.showPlaceHolders;
  }

  mimedate() {
    return '?' + new Date().toDateString;
  }

  imageVisable: boolean = false;
  source: string = '';
  target: string = '';

  showImage(source, index) {
    if (source != null) {
      this.target = '#link' + index;
      this.source = source;
      this.imageVisable = true;
    }
  }

  hideImage() {
    this.imageVisable = false;
  }

  memoVisable: boolean = false;
  memoText: string = '';
  memoTarget: string = '';

  showMemo(memo, index) {
    if (memo.data.memo != null) {
      this.memoTarget = '#memo' + memo.rowIndex;
      this.memoText = memo.data.memo;
      this.memoVisable = true;
    }
  }

  hideMemo() {
    this.memoVisable = false;
  }

  canView(name: string): boolean {
    return this.userManagment.canView(name);
  }
  canEdit(name: string): boolean {
    return this.userManagment.canEdit(name);
  }
}
