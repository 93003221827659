//match with PimLicense.cs
export enum PimLicense {
  Starter = "Starter",
  Basic = "Basic",
  Advanced = "Advanced",
  Infinity = "Infinity",
  Professional = "Professional",
  Enterprise = "Enterprise",
  EdeEditon = "EdeEditon",
  Onboarding = "Onboarding"
}
