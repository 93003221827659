import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { ProductPriceDetail } from '../../Model/Catalog/ProductPriceDetail';
import { PriceList } from '../../Model/Catalog/PriceList';
import { CatalogPriceList } from '../../Model/Catalog/CatalogPriceList';
import { Product } from '../../Model/Catalog/Product';
import { ViewService } from '../../Services/view.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../Services/model.service';
import { Message } from '../../Model/System/Message';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from '../../Services/template.service';
import { UserManagementService } from '../../Services/userManagment.service';
import { Functions } from '../../Services/functions';
import { ValidationService } from '../../Services/Validation/validation.service';
import { ContentReadyEvent } from 'devextreme/ui/data_grid';
import { KeyPressEvent, PasteEvent } from 'devextreme/ui/autocomplete';

@Component({
  selector: 'prices',
  templateUrl: 'prices.html',
  styleUrls: ['template.css']
})
export class Prices implements OnInit {
  @Input() model: Product = new Product();
  @Input() showToolTips: Boolean = false;
  @Input() showProToolTips: Boolean = false;
  @Input() showPlaceHolders: Boolean = true;
  @Input() showLinkButtons: Boolean = false;
  @Input() hasAddonLEGALITY: Boolean = false;
  @Input() hasAddonNordwest: Boolean = false;
  @Input() currentCustomerDefaultLanguage: String = 'deu';
  @Input() priceLists: CatalogPriceList[] = [];

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  priceListUpdateLock: boolean = false;
  priceUpdateLock: boolean = false;

  constructor(
    public templateService: TemplateService,
    public validationService: ValidationService,
    public modelService: ModelService,
    public translate: TranslateService,
    public userManagement: UserManagementService
  ) {}

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newPricelistButtonTemplate'
    });
  }

  onDetailGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newPriceButtonTemplate'
    });
  }

  newPriceListOrder = 0;

  ngOnInit(): void {
    this.model.priceLists.forEach((x) => {
      x.guid = Functions.newGuid();
      x.productPriceDetails.forEach((y) => {
        y.guid = Functions.newGuid();
      });
    });

    for (const priceList of this.model.priceLists) {
      if (priceList.priceListOrder > this.newPriceListOrder && priceList.priceListOrder < 1000) {
        this.newPriceListOrder = priceList.priceListOrder;
      }
    }

    if (this.newPriceListOrder === 0) {
      this.model.priceLists.push(new PriceList(++this.newPriceListOrder));
    }

    console.log(this.model.priceLists);
    this.newPriceListOrder += 1;
  }

  formatPriceFactor(value: string) {
    const number = Number(value);
    let result: string;
    if (Number.isNaN(number)) result = value;
    else
      result = this.translate.instant('Preisfaktor (Rabatt)', {
        priceFactor: value,
        discount: (1 - number).toLocaleString(this.translate.currentLang, { style: 'percent' })
      });
    return result;
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, data?, productPriceDetails?, field?: string, system: string = '-1', element: string = '-1') {
    //Order wird bereits bei Listen benutzt
    if (field == 'PRICE_LIST_ORDER') {
      let pl = this.model.priceLists.filter((x) => x.guid == data.guid)[0];
      let originalOrder = event.previousValue;
      let change = event.value > event.previousValue ? 1 : -1;

      for (const priceList of this.model.priceLists.filter((x) => x.priceListOrder === event.value)) {
        for (const detail of priceList.productPriceDetails) {
          const priceFactor = detail.priceFactorNumber.toString();
          if ('string' === typeof priceFactor) {
            detail.priceFactorNumber = parseFloat(priceFactor.replace(',', '.'));
          }

          const lowerBound = detail.lowerBoundNumber.toString();
          if ('string' === typeof lowerBound) {
            detail.lowerBoundNumber = parseFloat(lowerBound.replace(',', '.'));
          }

          const tax = detail.taxRate.toString();
          if ('string' === typeof tax) {
            detail.taxRate = parseFloat(tax.replace(',', '.'));
          }
        }
      }

      while (this.model.priceLists.filter((x) => x.priceListOrder == event.value).length > 1) {
        event.value += change;
        pl.priceListOrder = event.value;
      }
      if (event.value < 1) {
        pl.priceListOrder = originalOrder;
        return;
      }
    }

    //Order wird bereits bei einzelnen price benutzt
    if (field == 'PRICE_ORDER') {
      let pl = data as ProductPriceDetail;
      let originalOrder = event.previousValue;
      let change = event.value > event.previousValue ? 1 : -1;
      while (productPriceDetails.filter((x) => x.order == event.value).length > 1) {
        event.value += change;
        pl.order = event.value;
      }

      if (event.value < 1) {
        pl.order = originalOrder;
        return;
      }
      if (pl.order >= this.newPriceListOrder) {
        for (const priceList of this.model.priceLists) {
          if (priceList.priceListOrder > this.newPriceListOrder && priceList.priceListOrder < 1000) {
            this.newPriceListOrder = priceList.priceListOrder;
          }
        }
        this.newPriceListOrder += 1;
      }
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  priceListName(data) {
    var priceList = this.priceLists.filter((element) => element.order == data).shift();
    if (priceList) return priceList.name;
    return '';
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  get allowDeleting() {
    //return !this.templateView;
    return true;
  }

  addPriceList() {
    this.priceListUpdateLock = true;
    var priceList = new PriceList();
    //Bestimmen der Order
    var PriceListOrder = 0;
    for (let priceList of this.model.priceLists) {
      if (priceList.priceListOrder > PriceListOrder) {
        PriceListOrder = priceList.priceListOrder;
      }
    }
    PriceListOrder += 1;
    priceList.priceListOrder = PriceListOrder;

    if (this.templateView) {
      priceList.start = null;
      priceList.end = null;
    }

    //Preisliste himzufügen
    this.model.priceLists.push(priceList);

    //Produkt Speichern
    this.update();
  }

  onContentReadyPriceLists(event: ContentReadyEvent<CatalogPriceList>) {
    this.priceListUpdateLock = false;
    const datagrid = event.component;
    const key = datagrid.keyOf(this.model.priceLists[0]);
    datagrid.expandRow(key);
  }

  addPrice(priceLists) {
    this.priceUpdateLock = true;
    var price = new ProductPriceDetail();
    //Bestimmen der MwST
    switch (this.currentCustomerDefaultLanguage) {
      case 'nld':
        price.taxRate = 0.21;
        price.territory = 'NL';
        break;
    }
    price.order = this.newPriceListOrder++;

    if (this.templateView) {
      price.priceType = '';
      price.priceAmountNumber = 0;
      price.priceCurrency = '';
      price.taxRate = 0;
      price.priceFactorNumber = 0;
      price.lowerBoundNumber = 0;
      price.territory = '';
    }

    //Preis hinzufügen
    priceLists.data.productPriceDetails.push(price);

    //Produkt Speichern
    this.update();
  }

  onContentReadyPrices() {
    this.priceUpdateLock = false;
  }

  priceTypes = ViewService.dropDownPriceTypes;
  currencies = ViewService.dropDownCurrencies;
  taxs = ViewService.dropDownTaxs;
  priceFactors = ViewService.dropDownPriceFactors;
  lowerBounds = ViewService.dropDownLowerBounds;
  territories = ViewService.dropDownTerritories;
  units = ViewService.dropDownUnits;
  zero = ViewService.dropDownZero;
  one = ViewService.dropDownOne;

  removeBasicPriceDuty() {
    this.model.orderDetail.hasBasicPriceDuty = undefined;
    this.update();
  }

  removeIsTruckageCompanyGood() {
    this.model.orderDetail.isTruckageCompanyGood = undefined;
    this.update();
  }

  removeIsBulkyGood() {
    this.model.orderDetail.isBulkyGood = undefined;
    this.update();
  }

  //USABILITY

  private emptyArray = [false, false, false, false, false, false, false, false, false, false, false];
  public v: boolean[] = this.emptyArray;

  ttt(id) {
    if (this.showToolTips) {
      if (!this.v[id]) {
        this.v = this.emptyArray;
      }
      this.v[id] = !this.v[id];
    }
  }

  numericAutocompletePaste(e: PasteEvent) {
    if (isNaN(e.event.clipboardData.getData('text') as unknown as number)) {
      e.event.preventDefault();
    }
  }

  numericAutocompleteKeyPress(e: KeyPressEvent) {
    if (isNaN(+e.event.key) || e.event.key === ' ') {
      e.event.preventDefault();
    }
  }

  public hasPriceListFieldLinked(system: string = '-1'): boolean {
    // Prüfen, ob irgend ein Feld dieser Preisliste gelinked ist
    for (let masterField of this.templateService.allPriceListFields) {
      if (this.model.isFieldLinked(masterField, system, '-1')) {
        return true;
      }
    }
    return false;
  }

  public hasPriceFieldLinked(system: string = '-1', element: string = '-1'): boolean {
    // Prüfen, ob irgend ein Feld dieses Preises gelinked ist
    for (let detailField of this.templateService.allPriceFields) {
      if (this.model.isFieldLinked(detailField, system, element)) {
        return true;
      }
    }
    return false;
  }

  public onRowRemovingMaster(e) {
    let orderString = e.data.priceListOrder.toString();

    if (this.model.isChild && this.hasPriceListFieldLinked(orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = '';
      this.translate.get('DeleteLinkedFieldNotAllowed').subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, 'info'), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Wollen Sie die Preisliste wirklich löschen').subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith('?')) {
        // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + '?';
      }
    });
    this.translate.get('Wirklich löschen?').subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return false;
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }

  public onRowRemovedMaster(e) {
    let orderString = e.data.priceListOrder.toString();

    // Prüfen, ob irgend ein Feld dieser Preisliste gelinked ist
    for (let masterField of this.templateService.allPriceListFields) {
      if (this.model.isFieldLinked(masterField, orderString, '-1')) {
        this.model.toggleLinkedField(masterField, orderString, '-1');
      }
    }

    this.onValueChanged.emit(e);

    // für den template editor erforderlich:
    e.dFields = this.templateService.allPriceListFields;
    e.dDetailFields = this.templateService.allPriceFields;
    e.dSystem = orderString;
    e.dElement = -1;
    this.elementDeleted.emit(e);
  }

  public onRowRemovingDetail(e, o: PriceList) {
    let system = o.priceListOrder?.toString();

    let orderString = e.data.order.toString(); // Das ist HIER element

    if (this.model.isChild && this.hasPriceFieldLinked(system, orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = '';
      this.translate.get('DeleteLinkedFieldNotAllowed').subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, 'info'), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Wollen Sie den Preis wirklich löschen').subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith('?')) {
        // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + '?';
      }
    });
    this.translate.get('Wirklich löschen?').subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return false;
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }

  public onRowRemovedDetail(e, o: PriceList) {
    let system = o.priceListOrder?.toString();

    let orderString = e.data.order.toString(); // Das ist HIER element

    // Prüfen, ob irgend ein Feld dieses Preises gelinked ist
    for (let detailField of this.templateService.allPriceFields) {
      if (this.model.isFieldLinked(detailField, system, orderString)) {
        this.model.toggleLinkedField(detailField, system, orderString);
      }
    }

    this.onValueChanged.emit(e);

    // für den template editor erforderlich:
    e.dFields = this.templateService.allPriceFields;
    e.dDetailFields = null;
    e.dSystem = system;
    e.dElement = orderString;
    this.elementDeleted.emit(e);
  }

  //ExcelTemplate

  templateSelect(identifier: string, system: string = '-1', element: string = '-1') {
    if (element) {
      this.elementSelected.emit(`${identifier}_${system}_${element}`);
    } else {
      this.elementSelected.emit(`${identifier}_${system}`);
    }
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    return false;
  }

  public showClearButton(field: string, system: string, element: string = '-1'): boolean {
    return !this.readOnlyForLinkedFields(field, system, element) && !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
    //event.forceTreeRefresh = true;

    // SAVE
    this.update(event); //, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
