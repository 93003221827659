<dx-popup
  [width]="popupWidth"
  [height]="popupHeight"
  [dragEnabled]="false"
  [showTitle]="true"
  [title]="popupTitle | translate"
  [hideOnOutsideClick]="closeOnOutsideClick"
  [(visible)]="visible"
  [fullScreen]="popupMaximized"
  [toolbarItems]="toolbarItems"
  contentTemplate="popupContent"
  [hideOnOutsideClick]="closeOnOutsideClick"
>
  <ng-content select="[slot='toolbar']"></ng-content>

  <div *dxTemplate="let data of 'popupContent'">
    <ng-content select="[slot='content']"></ng-content>
  </div>
</dx-popup>
