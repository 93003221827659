import { Functions } from '../../Services/functions';

export class CatalogPriceList {

  constructor(
    public order?: number,
    public name?: string,
    public guid: string = Functions.newGuid()
  ) {

  }
}
