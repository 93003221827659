<div style="height:calc(100vh - 25px);overflow-y:scroll">
  <dx-toolbar>
    <dxi-item [visible]="modelService.systemService.editHelp && canView('helpSite')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Neue Hilfeseite' | translate, onClick: addHelp}"></dxi-item>
    <dxi-item location="after">
      <dx-switch [(value)]="modelService.systemService.editHelp" switchedOffText="Help" (onValueChanged)="helpUpdate()" switchedOnText="EDIT" [readOnly]="readOnly('helpSite')"></dx-switch>
    </dxi-item>
  </dx-toolbar>
  <!--  <dx-switch [(value)]="modelService.systemService.editHelp" switchedOffText="Help" (onValueChanged)="helpUpdate()" switchedOnText="EDIT" style="float: right;"></dx-switch>-->
  <div></div>
  <dx-sortable [data]="modelService.systemService.help"
               (onReorder)="onReorder($event)">
    <dx-accordion #accordion
                  *ngFor="let item of modelService.systemService.help"
                  [dataSource]="[item]"
                  [collapsible]="true"
                  [multiple]="false"
                  [animationDuration]="300"
                  [(selectedItems)]="modelService.systemService.selectedItems"
                  class="marginBottom">
      <div *dxTemplate="let help of 'title'">

        <div>{{help.title}}</div>
      </div>
      <div *dxTemplate="let help of 'item'">
        <div style="height:500px" *ngIf="modelService.systemService.editHelp">
          <dx-text-box [(value)]="help.title" [showClearButton]="true" (onValueChanged)="helpUpdate()" class="marginBottom"></dx-text-box>
          <dx-html-editor [(value)]="help.content"
                          placeholder=""
                          height="420"
                          class="marginBottom"
                          (onFocusOut)="helpUpdate()"
                          [readOnly]="readOnly('helpSite')">
            <dxo-toolbar>
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>
          <a *ngIf="canView('helpSite')" (click)="deleteItem(help)" class="glyphicon glyphicon-trash link" style="float:right;" title="Seite löschen"></a>
        </div>
        <div style="height:500px" *ngIf="!modelService.systemService.editHelp">
          <span [innerHTML]="help.content"></span>
        </div>
      </div>
    </dx-accordion>
  </dx-sortable>
</div>

