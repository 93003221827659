import { Component, OnInit, ViewChild } from "@angular/core";
import { CustomerAddonInfo } from "../../../Model/User/CustomerAddonInfo";
import { ModelService } from "../../../Services/model.service";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
  selector: "customer-addons-overview",
  templateUrl: "customer-addons-overview.component.html"
})
export class CustomerAddonsOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  customerAddonInfos: CustomerAddonInfo[];

  constructor(public modelService: ModelService) {
    this.onExporting = this.onExporting.bind(this);
    this.onExpandAll = this.onExpandAll.bind(this);

    this.onExpandAll = this.onExpandAll.bind(this);
    this.onCollapseAll = this.onCollapseAll.bind(this);

    this.reload = this.reload.bind(this);
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    if (!this.modelService.loginService.isAdmin) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.getCustomerAddonInfos();
  }

  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'minGroupsButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'maxGroupsButtonTemplate',
    });

  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('AddOns');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AddOns.xlsx');
      });
    });
    e.cancel = true;
  }

  onExpandAll() {
    this.dataGrid.instance.expandAll();
  }

  onCollapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  getCustomerAddonInfos(): void {
    this.modelService.loginService.customerService.getCustomerAddonInfos().subscribe((result: CustomerAddonInfo[]) => {
      this.customerAddonInfos = result;
    });
  }

  reload(): void {
    this.getCustomerAddonInfos();
  }
}
