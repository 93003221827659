<div *ngIf="model">
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="product-preview">
          <div class="thumbnail-list">
            <div *ngFor="let image of mimes" (mouseenter)="onThumbnailHover(image.normUri)" class="thumbnail-item">
              <img [src]="image.normUri" class="thumbnail" />
            </div>
          </div>        
          <div class="main-image">
            <span *ngIf="!selectedImage" class="glyphicon glyphicon-picture" style="font-size: 36px;"></span>
            <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Product Image" class="preview-image" />
          </div>
        </div>
        <div *ngIf="model?.topFeatures?.length > 0" class="topFeatures">
          <h6>Topmerkmale</h6>
          <ul>
            <li *ngFor="let topFeature of model.topFeatures">
              {{topFeature.value}}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm product-infos">
        <h5>{{model?.descriptionShort}}</h5>
        <p>{{model?.marketingClaim}}</p>
        <hr>
        <div *ngIf="model?.price?.prouctPrice">
            <h6>{{model?.price.prouctPrice}} <span *ngIf="this.model.orderDetail.hasBasicPriceDuty" class="shopViewBasicPriceDuty"> ({{basicPriceDutyInfo}})</span></h6> 
        </div>   
        <div *ngIf="model?.technicalFeatures">
          <div *ngFor="let feature of model?.technicalFeatures">
            <div class="variantDiv" *ngIf="feature.isVariant">
              <dx-select-box
                [id]="feature.name"
                [items]="getVariantValues(feature.name)"
                displayExpr="value"
                valueExpr="value"
                [label]="feature.name"
                width="30%"
                height="40px"
                [(value)]="feature.value"
                (onValueChanged)="onVariantValueChanged($event)"
                itemTemplate="item">
              </dx-select-box>            
              <div *dxTemplate="let data of 'item'">
                <div style="display:inline-block">{{data.value}}</div>
              </div>
            </div>
          </div>
        </div>  
        <div class="shopviewProductInformations">
          <h6>{{ 'Produktinformationen' | translate }}</h6>          
          <div *ngIf="model.supplierPid.length>0"><b>{{ 'Artikelnummer' | translate }}: </b>{{model.supplierPid}}</div>
          <div *ngIf="model.internationalPid?.length>0"><b>{{ 'Internationale ID' | translate }}: </b>{{model.internationalPid}}</div>
          <div *ngIf="model.manufacturerAid?.length>0"><b>{{ 'Herstellernummer' | translate }}: </b>{{model.manufacturerAid}}</div>
          <div *ngIf="model.manufacturerName?.length>0"><b>{{ 'Hersteller' | translate }}: </b>{{model.manufacturerName}}</div>
        </div>   
        <hr>
        <div *ngIf="model?.descriptionLong">
          <h6>{{ 'Beschreibung' | translate }}</h6>
          <span [innerHTML]="model?.descriptionLong"></span>
          <!--<p>{{model?.descriptionLong}}</p>-->
        </div>
      </div>
    </div>
    <div *ngIf="model?.references?.length > 0" class="row prouduct-refernces">
      
      <dx-tab-panel
        [dataSource]="referencePages"
      >
        <div *dxTemplate="let item of 'item'" class="row referenceItemTemplate">
          <div *ngFor="let data of item.data" class="col-sm referenceItem">
            <div (click)="referenceClicked(data)">
              <div class="thumbnail-item">
                <img [src]="data.normUri" class="thumbnail" />
              </div>
              {{data.descriptionShort}}
            </div>
          </div>
        </div>
      </dx-tab-panel>

    </div>
  </div>
</div>
