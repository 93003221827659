<dx-data-grid [dataSource]="versions"
              [showBorders]="true"
              keyExpr="version"
              [columnAutoWidth]="true"
              noDataText="{{ 'Keine Dateien für das Sortiment wurden gefunden' | translate }}">
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxi-column dataField="version" sortOrder="asc" dataType="number"></dxi-column>
  <dxi-column dataField="updateBez" dataType="string"></dxi-column>
  <dxi-column dataField="date" dataType="date"></dxi-column>
  <dxi-column caption="{{ 'Installiert' | translate }}" cellTemplate="cellTemplate" width="10%"></dxi-column>
  <div *dxTemplate="let data of 'cellTemplate'">
    <div class="d-flex justify-content-center">
      <!--<i *ngIf="isInstalled(data)" class="dx-icon-check dx-icon-custom-style"></i>-->

      <div *ngIf="isInstalled(data)">
        {{ 'Installiert' | translate }}
      </div>

      <dx-button *ngIf="canUpdateTo(data)"
                 id="versionbutton"
                 stylingMode="contained"
                 [text]="getText()"
                 type="normal"
                 width="100%"
                 (onClick)="install(data)">
      </dx-button>


    </div>

  </div>
</dx-data-grid>


<dx-popup [showTitle]="true"
          title="Information"
          width="30%"
          height="50%"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          container=".dx-viewport"
          [(visible)]="popupVisible">


  <dxi-toolbar-item widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" [ngStyle]="{'overflow-y': 'auto'}">
    <span>{{ 'EdeInstallInfo2' | translate }}</span>
    <br />
    <br />
    <span>{{ 'EdeInstallInfo' | translate }}</span>
  </div>
</dx-popup>
