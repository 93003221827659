import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { HazmatClass } from '../Model/Catalog/HazmatClass';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: "hazmatclasses",
  templateUrl: "hazmatclasses.component.html"
})
export class HazmatclassesComponent implements OnInit {
  model: HazmatClass[];

  constructor(public modelService: ModelService, public translate: TranslateService) {

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Gefahrstoffklassen");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'newClassButtonTemplate'
    });    
  }

  ngOnInit(): void {
/*    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }*/

    this.modelService.hazmatClassService.getClasses().subscribe(
      (result: HazmatClass[]) => {
        this.model = result;
      });
  }

  onSelectionChangedClick(e) {
    this.modelService.hazmatClassService.selectedClass = e.data;
  }
  onDeleteClicked(e) {
    this.modelService.hazmatClassService.deleteClass(e.data);
  }

  addClass() {

    let hazmatClass = new HazmatClass();
    hazmatClass.customerId = this.modelService.loginService.currentUser.customerId;

    this.modelService.hazmatClassService.addClass(hazmatClass).subscribe(
      (result: HazmatClass) => {
        this.modelService.hazmatClassService.selectedClass = result;
        this.model.push(this.modelService.hazmatClassService.selectedClass);
      });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }
}
