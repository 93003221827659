<div [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column' }">
  <div class="dx-fieldset" [ngStyle]="{ margin: '20px 30px' }">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'ÄnderungsDatum filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.dateFilter.useFilter"></dx-switch>
      </div>
    </div>
    <div *ngIf="exportParameter.dateFilter.useFilter">
      <div class="{{ fullScreen ? '' : 'maxHeight' }}">
        <div class="dx-field-label">{{ 'Nach dem Datum geänderte Produkte exportieren' | translate }}</div>
        <div class="dx-field-value">
          <dx-date-box [(value)]="exportParameter.dateFilter.filterKey" type="datetime"></dx-date-box>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Auslaufdatum filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.expiredDateFilter.useFilter"></dx-switch>
      </div>
    </div>
    <div *ngIf="exportParameter.expiredDateFilter.useFilter">
      <div class="{{ fullScreen ? '' : 'maxHeight' }}">
        <div class="dx-field-label" style="max-width: 100%">
          <p>{{ 'Vor dem Datum ausgelaufene Produkte ausschließen' | translate }}</p>
          <p style="white-space: pre-wrap">
            {{ 'expiredDateHint' | translate }}
          </p>
        </div>
        <div class="dx-field-value">
          <dx-date-box [(value)]="exportParameter.expiredDateFilter.filterKey" type="datetime"></dx-date-box>
        </div>
      </div>
    </div>
  </div>
</div>
