<dx-data-grid [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true"
              (onRowRemoving)="onDeleteClicked($event)"
               (onSaving)="onSaving($event)">
  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="MyAccountUsersStateNewUIV2"></dxo-state-storing>
  <dxo-editing mode="popup" refreshMode="full" [selectTextOnEditStart]="true" allowUpdating="canEdit()"
               [allowDeleting]="true" [allowAdding]="false">
    <dxo-popup title="{{ 'Datenblatt' | translate }}" [showTitle]="true"></dxo-popup>
    <dxo-form>
      <dxi-item dataField="name"></dxi-item>
     
    </dxo-form>
  </dxo-editing>


  <dxi-column dataField="name" caption="{{ 'Dateiname' | translate }}"></dxi-column>
 

</dx-data-grid>







