<dx-toolbar>
  <dxi-item
    *ngIf="!readOnlyUser('templateUpdate')"
    location="before"
    widget="dxButton"
    [options]="{
      type: 'default',
      icon: 'refresh',
      text: 'Produkte aktualisieren' | translate,
      hint: 'Die Vorlagen werden auf alle Produkte im Katalog übertragen' | translate,
      onClick: applyTemplate
    }"
  ></dxi-item>
</dx-toolbar>

<dx-tab-panel [dataSource]="pages" [selectedIndex]="0" [swipeEnabled]="false">
  <div *dxTemplate="let company of 'featureTemplate'">
    <featuresystems
      *ngIf="model"
      displayMode="edit"
      [(featureSystems)]="model.featureTemplate"
      [(showToolTips)]="showToolTips"
      [(showProToolTips)]="showProToolTips"
      [(showPlaceHolders)]="showPlaceHolders"
      [(hasAddonCLASSIFICATION)]="modelService.loginService.hasAddonCLASSIFICATION"
      [(hasAddonECOMMERCE)]="modelService.loginService.hasAddonECOMMERCE"
      [(modelService)]="modelService"
      (addEcommerceFeatures)="addEcommerceFeatures($event)"
      (addClassificationFeatures)="addClassificationFeatures($event)"
      (onValueChanged)="catalogUpdate($event)"
    ></featuresystems>
  </div>
  <div *dxTemplate="let company of 'mediaTemplate'">
    <mimes
      *ngIf="model"
      [(model)]="model.mimeTemplate"
      [(showToolTips)]="showToolTips"
      [(showProToolTips)]="showProToolTips"
      [isTemplate]="true"
      [(showPlaceHolders)]="showPlaceHolders"
      [(catalogId)]="modelService.catalogService.catalog.id"
      [(customerId)]="modelService.loginService.currentUser.customerId"
      (reloadNedded)="reload()"
      (onValueChanged)="catalogUpdate($event)"
      [(modelService)]="modelService"
    ></mimes>
  </div>

  <div *dxTemplate="let company of 'wawiTemplate'">
    <wawi [(model)]="wawiDummyProduct" (onValueChanged)="updateWawi($event)"></wawi>
  </div>
</dx-tab-panel>
