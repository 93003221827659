<!--58 harmonize featuresystem-->
<div class="dx-field">
  <dx-load-indicator *ngIf="featureSystemsLoading" id="small-indicator" height="20" width="20"></dx-load-indicator>
  <dx-data-grid id="gridContainer" *ngIf="!featureSystemsLoading"
                [dataSource]="featureSystemsWithOrder"
                [showBorders]="true">

    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
    <dxi-column dataField="order" dataType="number" width="100" caption="{{ 'Order' | translate }}"></dxi-column>
    <dxi-column dataField="name" [allowEditing]="false" caption="{{ 'Featuresystem Name' | translate }}"></dxi-column>

  </dx-data-grid>
</div>
