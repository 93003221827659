export class FeatureValue {

  constructor(
    public order?: number,
    public value?: string,
    public valueId?: string,
    public validFeatureValueId?: string
  ) {

    
  

  }
}
