import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { CategoryFilterTreeItem } from '../../../../../../Model/Catalog/exportFilter/categoryFilterTreeItem';
import { TreeItem } from '../../../../../../Model/Dto/TreeItem';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { SimpleHierachicalFilter } from '../../../../../../Model/exportParameter/SimpleHierachicalFilter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'udxFilter',
  templateUrl: './udxFilter.component.html',
  styleUrls: ['./udxFilter.component.css']
})
export class UdxFilterComponent implements OnInit {
  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  udxSelectFilters: CategoryFilterTreeItem[] = [];
  isFilterLoaded: boolean = false;


  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService) {
    this.refreshUdxSelectFilter = this.refreshUdxSelectFilter.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.udxsFilter == null)
      this.exportParameter.udxsFilter = new CatalogFilter<string[]>([])
    if (this.exportParameter.udxsFilter.filterKey == null)
      this.exportParameter.udxsFilter.filterKey = [];

    this.refreshUdxSelectFilter();
  }

  refreshUdxSelectFilter() {
    this.isFilterLoaded = false;
    this.exportFilterService.getUdxFilterTreeElements(this.catalogId, this.modelService.loginService.currentUser.customerId)
      .subscribe((filter: CategoryFilterTreeItem[]) => {
            this.udxSelectFilters = filter;
            this.isFilterLoaded = true;
        });
  }
}
