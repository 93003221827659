import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModelService } from "../../Services/model.service";

@Component({
  selector: "impressum",
  templateUrl: "impressum.component.html"
})
export class ImpressumComponent {

  constructor(private modelService: ModelService, public translate: TranslateService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Impressum");
  }

}
