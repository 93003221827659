<div class="dx-field">
  <div class="dx-field-label">{{ 'Vergleichsmodus' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ 'Exakt gleich' | translate }}</div>
      <dx-switch [(value)]="useContains" label="UseContains"></dx-switch>
      <div class="dx-field-label switch-right">{{ 'Beinhaltet' | translate }}</div>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Prefix' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <dx-text-box [(value)]="prefix" label="prefix" showClearButton="true"></dx-text-box>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Postfix' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <dx-text-box [(value)]="postfix" label="postfix" showClearButton="true"></dx-text-box>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Trennzeichen' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <dx-text-box [(value)]="delimiter" label="delimiter" showClearButton="true"></dx-text-box>
    </div>
  </div>
</div>
