

export class AddClassification{

  constructor(
    public classificationClassId?: string,
    public classificationSystemId?: string,
    public productId: string = "",
    public catalogId: string = "",
    public categoryId: string = "",
    public customerId?:string,
  ) {
  }
}


