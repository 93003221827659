import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { ModelService } from '../../../../Services/model.service';
import { Message } from '../../../../Model/System/Message';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from '../../../../Services/template.service';
import { ExportConfigurationService } from '../../../../Services/exportConfiguration.service';
import { ExportParameter } from '../../../../Model/exportParameter/ExportParameter';
import { CatalogFilter } from '../../../../Model/exportParameter/CatalogFilter';
import { SearchByListParameter } from '../../../../Model/Dto/SearchByListParameter';
import { SystemService } from '../../../../Services/system.service';

@Component({
  selector: 'export',
  templateUrl: 'exportCatalog.component.html',
  styleUrls: ['exportCatalog.component.css']
})
export class ExportCatalogComponent implements OnInit {
  @ViewChild('treeViewRefUdx', { static: false }) treeViewUdx: DxTreeViewComponent;
  @ViewChild('treeViewCategoryFilter', { static: false }) treeViewCategoryFilter: DxTreeViewComponent;

  @Input()
  public set visible(val: boolean) {
    if (this._visible !== val) {
      this._visible = val;
      this.visibleChange.emit(val);
    }
  }
  public get visible() {
    return this._visible;
  }

  @Output() public visibleChange = new EventEmitter<boolean>();

  private _visible: boolean = false;
  public config: ExportParameter;

  configurations: ExportParameter[] = [];
  exportParameterChanged: EventEmitter<object> = new EventEmitter<object>();

  isPopupEditVisible = false;
  isPopupAddVisible = false;

  configName = '';
  configIsDefault = false;

  newConfigName = '';
  newConfigIsDefault = false;

  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public exportConfigurationService: ExportConfigurationService,
    private systemService: SystemService
  ) {
    this.exportNordwestClicked = this.exportNordwestClicked.bind(this);
    this.parameterChanged = this.parameterChanged.bind(this);
    this.export = this.export.bind(this);

    if (modelService.catalogService.selectedCatalogId == null)
      modelService.catalogService.selectedCatalogId = modelService.catalogService.catalog.id;

    if (modelService.loginService.currentUser.customerId == null)
      modelService.loginService.currentUser.customerId = modelService.loginService.currentCustomer.id;

    this.config = new ExportParameter();
    this.config.customerId = modelService.loginService.currentUser.customerId;
    this.config.catalogId = modelService.catalogService.selectedCatalogId;
  }

  ngOnInit(): void {
    this.exportConfigurationService.getAll(this.modelService.loginService.currentCustomer.id).subscribe((result) => {
      this.configurations = result.filter((x) => !x.isUpdateFilter);
      let defaultConfig = this.configurations.find((x) => x.isDefault);
      if (defaultConfig) {
        this.loadConfig(defaultConfig.id);
      }
    });
  }

  public loadConfig(id) {
    this.exportConfigurationService.get(id, this.modelService.loginService.currentCustomer.id).subscribe((result) => {
      this.config = Object.assign(new ExportParameter(), result);
      this.config.catalogId = this.modelService.catalogService.catalog?.id;
      this.config.customerId = this.modelService.loginService.currentUser.customerId;
      this.setEmptyCatalogFilters();
      this.exportParameterChanged.emit();
    });
  }

  setEmptyCatalogFilters() {
    this.config.categoryFilter ??= new CatalogFilter([]);
    this.config.productStatesFilter ??= new CatalogFilter([]);
    this.config.udxsFilter ??= new CatalogFilter([]);
    this.config.productStatesFilter ??= new CatalogFilter([]);
    this.config.manufacturerFilter ??= new CatalogFilter([]);
    this.config.supplierFilter ??= new CatalogFilter([]);
    this.config.dateFilter ??= new CatalogFilter(new Date());
    this.config.searchByListParameter ??= new CatalogFilter(new SearchByListParameter());
    this.config.featureSystemFilter ??= new CatalogFilter([]);
    this.config.featureSystemContentFilter ??= new CatalogFilter([]);
    this.config.priceListsContentFilter ??= new CatalogFilter([]);
    this.config.udxsContentFilter ??= new CatalogFilter([]);
  }

  showAddConfig() {
    this.newConfigName = '';
    this.newConfigIsDefault = false;
    this.isPopupAddVisible = true;
  }

  showEditConfig() {
    this.isPopupEditVisible = true;
  }

  onConfigChanged(e) {
    if (e.selectedItem && e.selectedItem.id != this.config?.id) this.loadConfig(e.selectedItem.id);
  }

  parameterChanged(newParam) {
    this.config = newParam;
  }

  addConfig() {
    if (this.newConfigName == '') {
      this.modelService.systemService.notifyWarning('Konfiguration muss einen Namen haben');
      return;
    }

    this.config.customerId = this.modelService.loginService.currentCustomer.id;
    this.config.catalogId = this.modelService.catalogService.selectedCatalogId;
    this.config.name = this.newConfigName;
    this.config.isDefault = this.newConfigIsDefault;
    this.config.id = null;
    this.config.includeUdxs = true;
    this.updateConfig();
  }

  resetConfig() {
    var resettedConfig = new ExportParameter();
    resettedConfig.name = this.config.name;
    resettedConfig.id = this.config.id;
    resettedConfig.isDefault = this.config.isDefault;
    resettedConfig.customerId = this.config.customerId;
    resettedConfig.catalogId = this.modelService.catalogService.selectedCatalogId;
    this.config = resettedConfig;
  }

  updateConfig() {
    if (this.config.name == '') {
      this.modelService.systemService.notifyWarning('Konfiguration muss einen Namen haben');
      return;
    }

    if (this.templateService.template != null) {
      this.config.templateId = this.templateService.template.id;
    } else {
      this.config.templateId = null;
    }

    this.exportConfigurationService.update(this.config, this.modelService.loginService.currentCustomer.id).subscribe(
      (exportParameter) => {
        this.config = Object.assign(new ExportParameter(), exportParameter);
        this.isPopupAddVisible = false;
        this.isPopupEditVisible = false;
        this.exportConfigurationService
          .getAll(this.modelService.loginService.currentCustomer.id)
          .subscribe((result) => {
            this.configurations = result.filter((x) => !x.isUpdateFilter);
          });
      },
      (error) => {
        this.modelService.systemService.notifyWarning(error);
      }
    );
  }

  deleteConfig() {
    this.exportConfigurationService.delete(this.config.id, this.modelService.loginService.currentCustomer.id).subscribe(
      () => {
        this.exportConfigurationService
          .getAll(this.modelService.loginService.currentCustomer.id)
          .subscribe((result) => {
            this.config = new ExportParameter();
            this.configurations = result.filter((x) => !x.isUpdateFilter);
            this.isPopupAddVisible = false;
            this.isPopupEditVisible = false;
          });
      },
      (error) => {
        this.modelService.systemService.notifyWarning(error);
      }
    );
  }

  export() {
    this.config.customerId = this.modelService.loginService.currentCustomer.id;
    if (this.modelService.catalogService.selectedCatalogId) {
      this.config.catalogId = this.modelService.catalogService.selectedCatalogId;
    }
    if (this.templateService.template?.isAdvancedTemplate) {
      this.config.useMetadata = this.modelService.catalogService.useMetadata;
    }

    if (this.config.version == 'TEMPLATE' || this.config.format == 'TEMPLATE') {
      if (this.templateService.template != null) {
        this.config.templateId = this.templateService.template.id;
      } else {
        this.config.templateId = null;
        this.systemService.notifyInfo(this.translate.instant('Bitte wählen Sie ein Template aus.'));
        return;
      }
    }

    if (this.config.format == 'SEND_TO_CUSTOMER') {
      this.exportToCustomerWithValidation();
      return;
    }

    if (this.config.version == 'NORDWEST') {
      this.exportNordwestClicked();
      return;
    }

    if (this.config.version == 'SMARTSTORE') {
      this.exportSmartStoreClicked();
      return;
    }

    if (this.config.format == 'NEWCATALOG') {
      switch (this.config.version) {
        case 'FIELDLIST':
        case 'TEMPLATE':
          this.exportNewCatalogExcel();
          break;
        case 'BME':
          this.exportNewCatalog();
          break;
      }
      return;
    }

    this.modelService.catalogService.exportVisible = false;
    this.modelService.catalogService.createExportJob(this.config).subscribe(() => {
      if (this.config.scheduledFor) {
        this.modelService.systemService.notify(new Message(this.translate.instant("Export wird ausgeführt am", {
          date: this.config.scheduledFor.toLocaleString()
        })));
      } else {
        this.modelService.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
      }
    });
  }

  exportToCustomerWithValidation() {
    this.modelService.catalogService.exportVisible = false;
    this.modelService.catalogService.createExportToCustomerWithValidationJob(this.config).subscribe(() => {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('Die Validierung und der Export wird als Job ausgeführt'))
      );
    });
  }

  exportSmartStoreClicked() {
    this.config.format = 'SMARTSTORE';
    this.modelService.catalogService.exportVisible = false;
    this.modelService.catalogService.createSmartStoreExportJob(this.config).subscribe(() => {
      this.modelService.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }

  exportNordwestClicked() {
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Sollen die Daten wirklich zum NORDWEST gesendet werden?').subscribe((text: string) => {
      txtMsg = text;
    });
    if (!this.config.includeMime) {
      this.translate
        .get('Sollen die Daten wirklich OHNE MEDIEN zum NORDWEST gesendet werden?')
        .subscribe((text: string) => {
          txtMsg = text;
        });
    }
    this.translate.get('Wirklich senden?').subscribe((text: string) => {
      txtTitle = text;
    });
    let myDialog: any;
    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return { buttonText: true };
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return { buttonText: false };
          }
        }
      ]
    });
    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {
        this.modelService.catalogService.exportVisible = false;
        this.modelService.catalogService.createExportJob(this.config).subscribe(() => {
          this.modelService.systemService.notify(
            new Message(this.translate.instant('Der Export wird als Job ausgeführt'))
          );
        });
      }
    });
  }

  exportNewCatalog() {
    this.modelService.catalogService.exportVisible = false;
    this.modelService.catalogService.createNewCatalogExportJob(this.config).subscribe(() => {
      this.modelService.catalogService.requestCatalogChangingJobs();
      this.modelService.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }

  exportNewCatalogExcel() {
    this.modelService.catalogService.exportVisible = false;

    if (this.config.version == 'TEMPLATE') {
      if (this.templateService.template == null) {
        let message = this.translate.instant('Bitte ein Template auswählen') + '.';
        this.modelService.systemService.notify(new Message(message, 'info'), 3000);
        return;
      }
      this.config.templateId = this.templateService.template.id;
    }

    this.modelService.catalogService.createNewCatalogExportJob(this.config).subscribe(() => {
      this.modelService.catalogService.requestCatalogChangingJobs();
      this.modelService.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }
}
