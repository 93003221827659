import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'logo',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './logo.component.html',
  styleUrls: ["./logo.component.css"]
})
export class LogoComponent {
    @Input() look: 'light' | 'dark' | 'hidden';    
    @Input() click?: () => void;

    get hidden() {
      return this.look === 'hidden';
    }

    get logoUrl() {
        return this.look === 'light' ? '/images/nextpim.png' : '/images/nextpim_negativ.png';
    }
}