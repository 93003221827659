import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { WarrantyClass } from "../Model/Catalog/WarrantyClass";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "warrantyclass",
  templateUrl: "warrantyclass.component.html"
})
export class WarrantyclassComponent implements OnInit {
  
  constructor(public modelService: ModelService, public translate: TranslateService) { }


  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.translateTexts();
    this.reset();
  }

  pages: { id: number; title: string; template: string; }[] = [];
  
  txtHtml: string;
  txtPlain: string;
  
  translateTexts() {
    this.txtHtml = this.translate.instant("HTML-Text");
    this.txtPlain = this.translate.instant("Plain-Text");
  }
  reset() {
    this.pages = [{ id: 1, title: this.txtHtml, template: "htmlTemplate" },
      { id: 2, title: this.txtPlain, template: "plainTemplate"}];
   
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }
  update(e) {


    this.modelService.warrantyClassService.updateClass(this.modelService.warrantyClassService.selectedClass).subscribe(
      (result: WarrantyClass) => {
   
      });
  }


}
