import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { Catalog } from '../../Model/Catalog/Catalog';
import { FeatureValue } from '../../Model/classification/FeatureValue';

@Component({
  selector: "catalogSettings",
  templateUrl: "catalogSettings.component.html",
  styleUrls: []
})
export class CatalogSettingsComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  ngOnInit(): void {
    this.translateTexts();
    this.reset();
  }

  txtClassfification: string = "Klassifikation";
  txtOsg: string = "OSG";
  txtPriceList: string = "Preislisten";
  txtPrint: string = "Print";
  txtShopware: string = "Shopware";
  txtMavis: string = "Mavis";
  txtFeatureTemplate: string = "";
  txtTemplates: string = "";
  txtCsCart: string = "";
  txtJobs: string;

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  set model(catalog: Catalog) {
    this.modelService.catalogService.catalog = catalog;
  }

  catalogUpdate(event = null) {
    this.modelService.catalogService.updateCatalog(event);
  }
  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getCatalog(this.modelService.catalogService.catalog.id, this.modelService.loginService.currentUser.customerId).subscribe();
  }

  private translateTexts() {
    this.txtClassfification = this.translate.instant("Klassifikation");
    this.txtOsg = this.translate.instant("OSG");
    this.txtPriceList = this.translate.instant("Preislisten");
    this.txtPrint = this.translate.instant("Print");
    this.txtShopware = this.translate.instant("Shopware");

    this.txtMavis = this.translate.instant("Mavis");

    this.txtTemplates = this.translate.instant("Vorlagen");

    this.txtCsCart = this.translate.instant("CS-Cart");

    this.txtJobs = this.translate.instant("Jobs");
  }

  pages: { id: number; title: string; template: string; }[] = [];

  reset() {
    this.pages = [
      { id: 1, title: this.txtPriceList, template: "priceListTemplate" }
    ];
    if (this.modelService.loginService.hasAddonCLASSIFICATION) {
      this.pages.push({ id: 2, title: this.txtClassfification, template: "classificationTemplate" });
    }
    if (this.modelService.loginService.hasAddonOSG) {
      this.pages.push({ id: 3, title: this.txtOsg, template: "osgTemplate" });
    }
    if (this.modelService.loginService.hasAddonPRINT) {
      this.pages.push({ id: 4, title: this.txtPrint, template: "printTemplate" });
    }
    if (this.modelService.loginService.hasAddonShopWare) {
      this.pages.push({ id: 5, title: this.txtShopware, template: "shopwareTemplate" });
    }
    if (this.modelService.loginService.hasAddonMavis) {
      this.pages.push({ id: 6, title: this.txtMavis, template: "mavisTemplate" });
    }
    if (this.modelService.loginService.hasAddonShopware6) {
      this.pages.push({ id: 7, title: this.txtShopware, template: "shopware6Template" });
    }
    if (this.modelService.loginService.hasAddonSHOPWARE5) {
      this.pages.push({ id: 11, title: "SHOPWARE5", template: "shopware5Template" });
    }
    if (this.modelService.loginService.hasAddonINTERSHOP) {
      this.pages.push({ id: 12, title: "INTERSHOP", template: "intershopTemplate" });
    }
    if (this.modelService.loginService.hasAddonElectronicSales) {
      this.pages.push({ id: 13, title: "ELECTRONICSALES", template: "electronicSalesTemplate" });
    }
    if (!this.isVirtualCatalog() && this.modelService.loginService.hasAddonProductTemplates) {
      this.pages.push({ id: 8, title: this.txtTemplates, template: "templatesTemplate" });
    }

    this.pages.push({ id: 10, title: this.txtJobs, template: "jobsTemplate" });
  }

  isVirtualCatalog(): boolean {
    return this.model?.isVirtual;
  }

  addEcommerceFeatures() {
    this.modelService.catalogService.addEcommerceFeaturesToCatalog(this.model).subscribe(
      (catalog: Catalog) => {
        this.model.featureTemplate = catalog.featureTemplate;

      });
  }

  public classificationFeatureValues: FeatureValue[] = [];

  public loadClassificationFeatures(params) {
    this.modelService.classificationService.getFeatureValues(params.name, params.valueSource).subscribe(result => {
      this.classificationFeatureValues = result;
    });
  }

  public onSelectionChanged(e) {
    let i = 0;

    //this.modelService.viewService.catalogJobsVisible = (e.addedItems[0].template == "jobsTemplate");
    //refresher in joblist component never stops

    if (e.addedItems[0].template == "jobsTemplate")
      this.modelService.viewService.catalogJobsVisible = true;
    if (e.removedItems[0].template == "jobsTemplate")
      this.modelService.viewService.catalogJobsVisible = false;
  }
}
