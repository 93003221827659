export class LinkedField {

  constructor(
    public field?: string,
    public systemKey?: string,
    public elementKey?: string
  ) {

  }
}
