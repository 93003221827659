<fullscreen-page>
  <navigation-column>
    <div class="h-100 d-flex flex-column">              
      <div class="px-2 flex-fill overflow-hidden">
        <classificationTree></classificationTree>
      </div>
    </div>
  </navigation-column>
  <content-column look="light">  
    <div slot="content" class="h-100 d-flex flex-column">      
      <div class="dx-fieldset">
        <div class="dx-field">
          <h4>{{ modelService.classificationService.selectedSystem.name}}</h4>
        </div>
      </div>              
      <classificationGroup class="flex-fill overflow-hidden"></classificationGroup>            
    </div>
</content-column>
</fullscreen-page>
