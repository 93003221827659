import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ProductDataSource } from '../../Model/dataProcess/productDataSource';

@Injectable()
export class DataSourcesService {

  private readonly apiUrl = 'api/DataSources';

  constructor(private http: HttpClient) {}

  public get(id: string, customerId: string): Promise<ProductDataSource> {
    const url = `${this.apiUrl}/get`;
    const params = new HttpParams().append('id', id).append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.get<ProductDataSource>(url, options));
  }

  public getAll(customerId: string): Promise<ProductDataSource[]> {
    const url = `${this.apiUrl}/getAll`;
    const params = new HttpParams().append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.get<ProductDataSource[]>(url, options));
  }

  public create(model: ProductDataSource, customerId: string): Promise<ProductDataSource> {
    const url = `${this.apiUrl}/create`;
    const params = new HttpParams().append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.post<ProductDataSource>(url, model, options));
  }

  public update(model: ProductDataSource, customerId: string): Promise<ProductDataSource> {
    const url = `${this.apiUrl}/update`;
    const params = new HttpParams().append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.post<ProductDataSource>(url, model, options));
  }

  public delete(id: string, customerId: string): Promise<ProductDataSource> {
    const url = `${this.apiUrl}/delete`;
    const params = new HttpParams().append('id', id).append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.delete<ProductDataSource>(url, options));
  }

  public startApplyChangesToAccountMakro(paramter: { oldToNewDataSources: { oldDataSource: ProductDataSource; newDataSource: ProductDataSource; }[]; }, customerId: string): Promise<object> {
    const url = `${this.apiUrl}/startApplyChangesToAccountMakro`;
    const params = new HttpParams().append('customerId', customerId);
    const options = { params };
    return lastValueFrom(this.http.post(url, paramter, options));
  }
}
