import { Input, Output, EventEmitter, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Mime } from '../../Model/Catalog/Mime';
import { Product } from '../../Model/Catalog/Product';
import { NextPimField } from '../../Model/Catalog/NextPimField';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { LoginService } from '../../Services/login.service';
import { TemplateService } from '../../Services/template.service';
import { ToolTipService } from '../../Services/tooltip.service';
import { UserManagementService } from '../../Services/userManagment.service';
import { StringResponse } from '../../Model/Dto/StringResponse';
import { DragInfo } from '../../Model/ui/DragInfo';
import { ValidationService } from '../../Services/Validation/validation.service';
import { SystemService } from '../../Services/system.service';
import { confirm } from 'devextreme/ui/dialog';
import { ExcelElement } from 'app/Model/ui/ExcelElement';

@Component({
  selector: 'np-inputControl',
  templateUrl: 'inputControl.component.html',
  styleUrls: ['inputControl.component.css'],
  encapsulation: ViewEncapsulation.None // Use to disable CSS Encapsulation for this component
})
export class NpInputControlComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() productTranslation: Product = null;

  @Input() showLinkButtons: boolean = false;
  @Input() showPlaceLabel: boolean = false;
  @Input() showTranslationPlaceLabel: boolean = false;

  @Input() suppressLabel: boolean = false;
  @Input() suppressLinkButton: boolean = false;
  @Input() suppressTranslation: boolean = true;
  @Input() suppressPlaceLabel: boolean = false;
  @Input() suppressToolTip: boolean = false;
  @Input() suppressDragHandle: boolean = false;
  @Input() suppressContentWidth: boolean = false;
  @Input() suppressInfoText: boolean = false;

  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() infoText: string = '';
  @Input() labelText: string = '';
  @Input() field: string = '';
  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag'; // alle/kein Filter: "> *";
  @Input() direction: 'row' | 'column' = 'row';

  @Input() isPrint: boolean = false;
  @Input() showHTMLSwitch: boolean = false;
  @Input() showHTMLEditor: boolean = false;
  @Output() showHTMLEditorChange = new EventEmitter<any>();
  @Output() excelElementDropped = new EventEmitter<any>();

  //@Input() editValue: any = "";
  //@Output() editValueChange = new EventEmitter<any>();

  _system: string;
  get system(): string {
    return this._system;
  }
  @Input('system') set system(value: string) {
    if (value != undefined) {
      this._system = value.toString();
      this.dragInfo.dSystem = this._system;
    }
  }

  _element: string;
  get element(): string {
    return this._element;
  }
  @Input('element') set element(value: string) {
    if (value != undefined) {
      this._element = value.toString();
      this.dragInfo.dElement = this._element;
    }
  }

  showToolTips: boolean = false;
  showProToolTips: boolean = false;
  showPlaceHolders: boolean = true;
  showInfoText: boolean = true;
  showRemoveFromTemplateItem: boolean = false;
  dragInfo: DragInfo = new DragInfo();

  public toolTipVisible: boolean = false;
  public toolTipTargetId: string = '';

  constructor(
    public translate: TranslateService,
    public validationService: ValidationService,
    public templateService: TemplateService,
    public toolTipServiceService: ToolTipService,
    public loginService: LoginService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService,
    public systemService: SystemService
  ) {}

  ngOnInit(): void {
    this.showToolTips = this.loginService.showToolTips;
    this.showProToolTips = this.loginService.showProToolTips;
    this.showPlaceHolders = this.loginService.showPlaceHolders;
    this.showInfoText = this.loginService.showInfoText;

    if (!this.suppressToolTip) {
      this.toolTipTargetId = `${this.field}_${this.system}_${this.element}`;
    }
    this.dragInfo.dModel = this.model;
    this.dragInfo.dField = this.field;
    this.dragInfo.dDisplayName = '';
    if (this.element == undefined) {
      this.element = '-1';
    }
    if (this.system == undefined) {
      this.system = '-1';
    }
  }

  public update(event = null, mimeChange = null) {
    if ((mimeChange != null && mimeChange) || (event != null && event.previousValue !== event.value)) {
      this.model.mimes.forEach((mime: Mime) => {
        mime.lastChanged = new Date();
      });
    }

    this.onValueChanged.emit(event);
  }

  public elementSelectFunction() {
    this.elementSelected.emit(this.field);
  }

  public switchValueChanged(event) {
    this.showHTMLEditorChange.emit(event.value);
  }

  get hasTranslation(): Boolean {
    if (this.productTranslation == null) {
      return false;
    }
    return true;
  }

  get contentWidth(): string {
    if (this.suppressContentWidth) {
      return '';
    }
    if (this.templateField.length > 0) {
      return 'w-75';
    }
    return 'w-100';
  }

  templateField: string = '';

  public fieldValueClasses() {
    let compare = this.field;
    if (this.system != '0' && this.system != '-1') {
      compare = compare + '_' + this.system;
      if (this.element != '0' && this.element != '-1') {
        compare = compare + '_' + this.element;
      }
    }

    let classes = '';
    if (
      this.selectedElements != undefined &&
      !(typeof this.selectedElements === 'string') &&
      this.selectedElements.findIndex((x) => x == compare) != -1
    ) {
      classes = classes + ' selectedField';
    }

    this.templateField = '';
    this.showRemoveFromTemplateItem = false;
    if (this.templateService.template) {
      if (
        this.templateService.hasTemplateItem(
          this.templateService.template.templateItems,
          this.field,
          this.system,
          this.element
        )
      ) {
        classes = classes + ' usedField';
        this.showRemoveFromTemplateItem = true;
        //// Hier triggern wir, dass im Template Editor eine weitere TextBox angezeigt wird, in der das verknüpfte Template-Feld steht.
        //// Das ist eig. nicht "fein", weil wir hier was machen, was mit den CSS-Klassen nix zu tun hat.
        //// Aber da wir an dieser stelle grade eh dabei sind nach den TemplateItems zu schauen, machen wir das hier gleich mit.
        //let item = this.templateService.getTemplateItem(this.templateService.template.templateItems, this.field, this.system, this.element);
        //this.templateField = item.key;
      }
    }

    return classes;
  }

  async removeFromTemplateItem(event) {
    var pimField = new NextPimField(this.element, this.system, this.field);
    this.templateService.clearPimFieldEmitterDone.subscribe(() => this.test(event));
    this.templateService.clearPimFieldEmitter.emit(pimField);
  }

  test(event) {
    event.dField = this.field;
    event.dSystem = this.system;
    event.dElement = this.element;
    event.removeFromTemplateItem = true;
    this.onValueChanged.emit(event);
    this.showRemoveFromTemplateItem = false;
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  toggleToolTip(visible: boolean) {
    if (this.showToolTips) {
      this.toolTipVisible = visible;
    }
  }

  toolTipContent() {
    return this.toolTipServiceService.getToolTipContent(this.field, this.showProToolTips);
  }

  onDragStart(e) {
    // [data]="{ dModel: model, dField: field, dSystem: system, dElement: element }"
    if (!this.showDragHandle) {
      e.cancel = true;
      return;
    }

    if (e.fromData.dModel === undefined || e.fromData.dModel === null) {
      e.cancel = true;
      return;
    }

    // Diese Felder sollen am Drag and Drop nicht teilnehmen, daher hier abbrechen:
    if (e.fromData.dField === 'MIME_ORDER') {
      e.cancel = true;
      return;
    }

    e.itemData = e.fromData;
    e.itemData.source = 'product';

    this.templateService
      .getHeadline(this.field, this.element, this.system, this.loginService.currentCustomer.id)
      .subscribe((result: StringResponse) => {
        this.dragInfo.dDisplayName = result.value;
      });

  }

  onDropAdd(e: {
    itemData?: ExcelElement & { exportMode?: boolean };
    newField?: string;
    newSystem?: string;
    newElement?: string;
  }) {
    // nur für den Import ermöglichen
    if (e.itemData.exportMode) {
      return;
    }

    // ändert direkt das excel model vom template excel component => verrückte nebeneffekte...
    //e.itemData.field = this.field;
    //e.itemData.system = this.system;
    //e.itemData.element = this.element;

    e.newField = this.field;
    e.newSystem = this.system;
    e.newElement = this.element;

    this.excelElementDropped.emit(e);
  }

  // hier muss man überlegen, ob diese nicht als event rausgeworfen werden sollen, um dann auf der eltern-ebene mit dem model zu hantieren
  // macht eigentlich wenig sinn der ganze zinnober, da wir so oder so auf der gleichen instanz 'rumnudeln

  public hasMasterOrChildOrIsTranslated(): boolean {
    // Katalog und Kategorie haben kein Produkt...
    if (this.model == null) {
      return false;
    }

    // Im Übersetzungsmodus nicht anzeigen
    if (this.hasTranslation) {
      return false;
    }

    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system?: string, element?: string): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public async toggleLinkedField(field: string, system?: string, element?: string, event = null) {
    if (!this.userManagement.canEdit('inputControlLink')) {
      return;
    }

    if (this.isFieldLinked(field, system, element)) {
      if (field == 'REFERENCE_FEATURE_SYSTEM_NAME') {
        let result = await confirm(
          this.translate.instant('RemoveLinkedFieldFeatureSystemNameConfirmMessage'),
          this.translate.instant('RemoveLinkedFieldConfirmTitle')
        );
        if (!result) {
          return;
        }
      }
      if (field == 'FNAME' || field == 'FUNIT') {
        let result = await confirm(
          this.translate.instant('RemoveLinkedFieldFeatureNameUnitConfirmMessage'),
          this.translate.instant('RemoveLinkedFieldConfirmTitle')
        );
        if (!result) {
          return;
        }
      }
    }

    let changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }
    event.toggleLinkedFields = true;

    let isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    if (field == 'DESCRIPTION_SHORT' || field == 'SUPPLIER_PID' || field == 'MIME_PURPOSE' || field == 'MIME_SOURCE') {
      // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
      event.forceTreeRefresh = true;
    }

    // SAVE
    if (
      field == 'MIME_TYPE' ||
      field == 'MIME_SOURCE' ||
      field == 'MIME_DESCR' ||
      field == 'MIME_ALT' ||
      field == 'MIME_PURPOSE'
    ) {
      this.update(event, true);
    } else {
      this.update(event);
    }
  }

  public getInitialClassForLinkedField(field: string, system?: string, element?: string): string {
    let isNowLinked = this.isFieldLinked(field, system, element);
    if (!isNowLinked && !this.userManagement.canView('inputControlLink')) {
      return 'link-placeholder';
    }
    return this.model.getClassForLinkedField(isNowLinked);
  }
  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
