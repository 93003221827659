import { Component, Input, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ValidFeatureValue } from "../Model/ValidFeatureValue";
import { TranslateService } from "@ngx-translate/core";
import { ValidFeature } from "../Model/ValidFeature";

@Component({
  selector: "validFeatureValue",
  templateUrl: "validFeatureValue.component.html"
})
export class ValidFeatureValueComponent implements OnInit{
  @Input() validFeature: ValidFeature;

  validFeatureValues: ValidFeatureValue[] = [];

  constructor(public modelService: ModelService, public translate: TranslateService) {

  }
    ngOnInit(): void {
      this.modelService.validFeatureService.getValues(this.validFeature.id, this.modelService.loginService.currentUser.customerId).subscribe(
        (result: ValidFeatureValue[]) => {
          this.validFeatureValues = result
        });
    }

}
