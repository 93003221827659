export class SupplierPidMappingInfo {

  constructor(
    public id?: string,
    public name?: string,
    public gtin?: string,
    public artikelNumber?: string,
  ) {

  }
}
