<fullscreen-page> 
  <content-column look="dark">
    <logo slot="toolbar" [routerLink]="['/']" look="dark" route="['/catalogs']"></logo>
    <div slot="content" class="h-100 p-3">
      <div class="d-block h-100 w-100 mx-auto" style="max-width: 600px;">            
            <form (submit)="onLogin()">
              <div class="dx-fieldset">
                <div class="dx-fieldset-header">{{ 'Login' | translate }}</div>
                <div class="dx-field">
                  <dx-text-box [(value)]="creds.username" [showClearButton]="true" width="100%" label="{{ 'Email' | translate }}">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihre Emailadresse ein' | translate }}."></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>

                <div class="dx-field">
                  <dx-text-box [(value)]="creds.password" mode="password" [showClearButton]="true" width="100%" label="{{ 'Passwort' | translate }}">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihr Passwort ein' | translate }}."></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>

                <dx-validation-summary id="summary"></dx-validation-summary>

                <div class="dx-field d-flex flex-row">
                  <dx-button stylingMode="text" type="default" text="{{ 'Login' | translate }}" [useSubmitBehavior]="true"></dx-button>
                  <a class="forgotPassword ml-auto align-self-center" [routerLink]="['/forgotPassword']">
                    {{ 'Passwort vergesssen' | translate }}?
                  </a>
                </div>

              </div>

            </form>
          </div>
        </div>        
  </content-column>
</fullscreen-page>
