<div class="dx-fieldset">
  <div class="dx-field">
    <h4>{{ 'Garantieklasse' | translate }}</h4>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ modelService.warrantyClassService.selectedClass.name?.length>0 ? 'placeLabelSmall' : '' }}">NAME</label>
      <dx-text-box [(value)]="modelService.warrantyClassService.selectedClass.name" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Garantie in Monaten' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel placeLabelSmall">MONATH</label>
      <dx-number-box [min]="0"
                     [max]="9999"
                     [(value)]="modelService.warrantyClassService.selectedClass.month" (valueChange)="update($event)"></dx-number-box>
    </div>
  </div>
  <div class="dx-field">
    <dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="w-100" [swipeEnabled]="false">
      <div *dxTemplate="let company of 'htmlTemplate'">
        <dx-html-editor [(value)]="modelService.warrantyClassService.selectedClass.descriptionHtml"
                        placeholder="DESCRIPTION HTML" class="warrantyEditor"
                        (onFocusOut)="update($event)">
          <dxo-toolbar>
            <dxi-item name="undo"></dxi-item>
            <dxi-item name="redo"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
            <dxi-item name="font" [acceptedValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
            <dxi-item name="bold"></dxi-item>
            <dxi-item name="italic"></dxi-item>
            <dxi-item name="strike"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="alignLeft"></dxi-item>
            <dxi-item name="alignCenter"></dxi-item>
            <dxi-item name="alignRight"></dxi-item>
            <dxi-item name="alignJustify"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="orderedList"></dxi-item>
            <dxi-item name="bulletList"></dxi-item>
          </dxo-toolbar>
        </dx-html-editor>
      </div>
      <div *dxTemplate="let company of 'plainTemplate'">
        <dx-text-area [(value)]="modelService.warrantyClassService.selectedClass.description" class="warrantyEditor" (onValueChanged)="update($event)"></dx-text-area>
      </div>
    </dx-tab-panel>
  </div>
</div>


