export class Reference {

  constructor(
    public type?: string,
    public artIdTo?: string,
    public descriptionShort?: string,
    public normUri?: string,
    public order?: number
  ) {

  }
}
