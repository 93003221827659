<dx-tab-panel [dataSource]="pages" [selectedIndex]="0">

  <div *dxTemplate="let company of 'customFieldsTextTemplate'">

    <dx-data-grid (onToolbarPreparing)="onSystemToolbarPreparing($event)"
                  [dataSource]="model.customFields"
                  [allowColumnReordering]="true"
                  [rowAlternationEnabled]="true"
                  [allowColumnResizing]="true"
                  [showBorders]="true"
                  (onRowRemoving)="onRowRemoving($event)"
                  (onRowRemoved)="delete($event)"
                  [repaintChangesOnly]="true">
      <dxo-editing mode="cell" [allowUpdating]="isManager()" [allowDeleting]="isManager()" [useIcons]="true" (onValueChanged)="update($event)">
        <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
      </dxo-editing>
      <div *dxTemplate="let toolbaritem of 'newFieldButtonTemplate'">
        <dx-button stylingMode="text" type="default" text="{{ 'Neues Feld' | translate }}" (onClick)="addCustomField()"></dx-button>
      </div>
      <div *dxTemplate="let toolbaritem of 'gevisButtonTemplate'">
        <dx-button stylingMode="text" type="default" text="{{ 'Gevis Felder erstellen' | translate }}" (onClick)="generateGevisFields()"></dx-button>
      </div>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="CustomFieldsState"></dxo-state-storing>

      <dxi-column caption="{{ 'Order' | translate }}" width="130px" dataField="order" sortOrder="asc" cellTemplate="orderCellTemplate" editCellTemplate="orderCellTemplate"></dxi-column>
      <dxi-column caption="{{ 'Name' | translate }}" dataField="name" cellTemplate="nameTemplate" editCellTemplate="nameTemplate"></dxi-column>
      <dxi-column caption="{{ 'Typ' | translate }}" dataField="fieldType" cellTemplate="fieldTypeTemplate" editCellTemplate="fieldTypeTemplate"></dxi-column>
      <dxi-column caption="" dataField="list" cellTemplate="listTemplate" editCellTemplate="listTemplate"></dxi-column>
      <dxi-column *ngIf="loginService.hasAddonGevis" caption=" {{ 'In Gevis exportieren' | translate }}" dataField="useInGevis" cellTemplate="useInGevisCellTemplate" editCellTemplate="useInGevisCellTemplate"></dxi-column>

      <div *dxTemplate="let column of 'orderCellTemplate'">
        <div class="np-field w-100">
          <np-inputControl [showLinkButtons]="false"
                           [showPlaceLabel]="true"
                           [suppressLabel]="true"
                           [suppressLinkButton]="true"
                           [suppressTranslation]="true"
                           [suppressToolTip]="true"
                           [suppressDragHandle]="true"
                           (elementSelected)="null"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Order' | translate }}"
                           field="ORDER"
                           [showDragHandle]="false">

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="column.data.order"
                             [min]="1"
                             [showClearButton]="false"
                             [showSpinButtons]="true"
                             [readOnly]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>
          </np-inputControl>
        </div>

      </div>
      <div *dxTemplate="let column of 'fieldTypeTemplate'">

        <div class="np-field w-100">
          <np-inputControl [showLinkButtons]="false"
                           [showPlaceLabel]="column?.data?.fieldType?.length > 0"
                           [suppressLabel]="true"
                           [suppressToolTip]="true"
                           (onValueChanged)="update($event)"
                           field="FIELDTYPE">

            <div slot="content" class="contentSlot">
              <dx-select-box [dataSource]="fieldTypes"
                             [(value)]="column.data.fieldType"
                             (onValueChanged)="update($event)"></dx-select-box>
            </div>
          </np-inputControl>
        </div>
      </div>

      <div *dxTemplate="let column of 'nameTemplate'">
        <div class="np-field w-100">
          <np-inputControl [showLinkButtons]="false"
                           [showPlaceLabel]="column?.data?.name?.length > 0"
                           [suppressLabel]="true"
                           [suppressToolTip]="true"
                           (onValueChanged)="update($event)"
                           field="NAME">
            <div slot="content" class="contentSlot">
              <dx-text-box [(value)]="column.data.name"
                           [showClearButton]="true"
                           (onValueChanged)="update($event)">
              </dx-text-box>
            </div>
          </np-inputControl>
        </div>
      </div>

      <div *dxTemplate="let column of 'listTemplate'">
        <div *ngIf="column?.data?.fieldType == 'List'" class="np-field w-100">
          <np-inputControl [showLinkButtons]="false"
                           [showPlaceLabel]="column?.data?.listId?.length > 0"
                           [suppressLabel]="true"
                           [suppressToolTip]="true"
                           (onValueChanged)="update($event)"
                           field="listId">
            <div slot="content" class="contentSlot">
              <dx-select-box [items]="lists"
                             [(value)]="column.data.listId"
                             displayExpr="listName"
                             valueExpr="id"
                             (onValueChanged)="update($event)"></dx-select-box>
            </div>
          </np-inputControl>
        </div>
      </div>

      <div *dxTemplate="let column of 'useInGevisCellTemplate'">
        <dx-check-box [(value)]="column.data.useInGevis" (onValueChanged)="update($event)"></dx-check-box>
      </div>
    </dx-data-grid>
  </div>



  <div *dxTemplate="let company of 'supplierPidTemplate'">

    <div class="dx-fieldset ">
      <div class="w-100 dx-field">
        <np-inputControl [showPlaceLabel]="model?.name?.length > 0"
                         [suppressLabel]="false"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'Anzeigename' | translate }}"
                         field="WAWI_NAME">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="model.name"
                         [showClearButton]="true"
                         (onValueChanged)="update($event)">
            </dx-text-box>
          </div>
        </np-inputControl>
      </div>

      <div class="d-flex flex-row w-100">
        <div class="w-50 pr-3 dx-field">
          <np-inputControl [showPlaceLabel]="model?.startSupplierPid > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Erste SupplierPID' | translate }}"
                           field="FIRST_SUPPLIER_PID">

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.startSupplierPid"
                             [showClearButton]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>
        </div>

        <div class="w-50 pr-3 dx-field">
          <np-inputControl [showPlaceLabel]="model?.currentSupplierPid > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Letzte SupplierPID' | translate }}"
                           field="CURRENT_SUPPLIER_PID">

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.currentSupplierPid"
                             [showClearButton]="true"
                             [readOnly]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>
        </div>
      </div>
      <div class="d-flex flex-row w-100">
        <div class="w-50 pr-3 dx-field">
          <np-inputControl [showPlaceLabel]="model?.preFixSupplierPid?.length > 0"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'SupplierPID Prefix' | translate }}"
                           field="PREFIX">

            <div slot="content" class="contentSlot">
              <dx-text-box [(value)]="model.preFixSupplierPid"
                           [showClearButton]="true"
                           (onValueChanged)="update($event)">
              </dx-text-box>
            </div>

          </np-inputControl>
        </div>


        <div class="w-50 pr-3 dx-field">
          <np-inputControl [showPlaceLabel]="model?.postFixSupplierPid?.length > 0"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'SupplierPID Postfix' | translate }}"
                           field="POSTFIX">

            <div slot="content" class="contentSlot">
              <dx-text-box [(value)]="model.postFixSupplierPid"
                           [showClearButton]="true"
                           (onValueChanged)="update($event)">
              </dx-text-box>
            </div>

          </np-inputControl>
        </div>
      </div>




      <div class="d-flex flex-row">
        <div class="w-50 pr-3">
          <div class="dx-field ">
            <div class="dx-field-label">{{ 'Artikelbezeichnung' | translate }} 1</div>
            <div class="dx-field-value">
              <dx-switch (onValueChanged)="update($event)" [(value)]="model.hasLine1" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
            </div>
          </div>
        </div>
        <div class="w-50 pl-3" *ngIf="model.hasLine1">

          <np-inputControl [showPlaceLabel]="model?.line1Length > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Feldlänge' | translate }}"
                           field="WAWILENGTH"
                           [system]="1"
                           [element]="1">
            <!-- system / element hier nur für die tooltips unterschiedlich (==> daraus wird die ID im InputCotrol generiert) -->

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.line1Length"
                             [showClearButton]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>

        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="w-50 pr-3">
          <div class="dx-field ">
            <div class="dx-field-label">{{ 'Artikelbezeichnung' | translate }} 2</div>
            <div class="dx-field-value">
              <dx-switch (onValueChanged)="update($event)" [(value)]="model.hasLine2" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
            </div>
          </div>
        </div>
        <div class="w-50 pl-3" *ngIf="model.hasLine2">

          <np-inputControl [showPlaceLabel]="model?.line1Length > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Feldlänge' | translate }}"
                           field="WAWILENGTH"
                           [system]="2"
                           [element]="2">
            <!-- system / element hier nur für die tooltips unterschiedlich (==> daraus wird die ID im InputCotrol generiert) -->

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.line2Length"
                             [showClearButton]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>

        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="w-50 pr-3">
          <div class="dx-field ">
            <div class="dx-field-label">{{ 'Artikelbezeichnung' | translate }} 3</div>
            <div class="dx-field-value">
              <dx-switch (onValueChanged)="update($event)" [(value)]="model.hasLine3" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
            </div>
          </div>
        </div>
        <div class="w-50 pl-3" *ngIf="model.hasLine3">

          <np-inputControl [showPlaceLabel]="model?.line3Length > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Feldlänge' | translate }}"
                           field="WAWILENGTH"
                           [system]="3"
                           [element]="3">
            <!-- system / element hier nur für die tooltips unterschiedlich (==> daraus wird die ID im InputCotrol generiert) -->

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.line3Length"
                             [showClearButton]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>

        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="w-50 pr-3">
          <div class="dx-field ">
            <div class="dx-field-label">{{ 'Artikelbezeichnung' | translate }} 4</div>
            <div class="dx-field-value">
              <dx-switch (onValueChanged)="update($event)" [(value)]="model.hasLine4" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
            </div>
          </div>
        </div>
        <div class="w-50 pl-3" *ngIf="model.hasLine4">

          <np-inputControl [showPlaceLabel]="model?.line4Length > -1"
                           [suppressLabel]="false"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Feldlänge' | translate }}"
                           field="WAWILENGTH"
                           [system]="4"
                           [element]="4">
            <!-- system / element hier nur für die tooltips unterschiedlich (==> daraus wird die ID im InputCotrol generiert) -->

            <div slot="content" class="contentSlot">
              <dx-number-box [(value)]="model.line4Length"
                             [showClearButton]="true"
                             (onValueChanged)="update($event)">
              </dx-number-box>
            </div>

          </np-inputControl>

        </div>
      </div>

    </div>
  </div>
</dx-tab-panel>
