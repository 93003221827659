import { Input, Output, EventEmitter, Component } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { Product } from "../../../Model/Catalog/Product";
import { WawiIntegration } from "../../../Model/Catalog/WawiIntegration";
import { WawiList } from "../../../Model/wawi/WawiList";
import { ModelService } from "../../../Services/model.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { ValidationService } from "../../../Services/Validation/validation.service";
import { WawiListService } from "../../../Services/wawiList.service";


@Component({
  selector: "wawi",
  templateUrl: "wawi.component.html",
  styleUrls: ["wawi.component.css", "../template.css"]
})
export class WawiComponent {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;

  @Input() templateView: Boolean = false;

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();

  wawiLists: WawiList[];
  wawiListDataSource: DataSource;
  wawiListDataSourceMap = new Map<string, DataSource>();

  constructor(public modelService: ModelService, public userManagement: UserManagementService,
    public wawiListService: WawiListService, public validationService: ValidationService) {
    this.createLines = this.createLines.bind(this);
    if (!this.model.wawi) {
      this.model.wawi = new WawiIntegration();
    }

    this.wawiListService.getAllLists(this.modelService.loginService.currentUser.customerId).subscribe(result => {
      this.wawiLists = result;
      this.wawiListDataSource = new DataSource({
        store: result,
        paginate: true,
        pageSize: 5
      });
      var currentFields = [];
      this.modelService.loginService.wawiSettings.customFields.forEach(field => {
        var customWawiField = this.model.wawi.customFields.find(customWawiField => customWawiField.order == field.order);
        if (field.fieldType == "List") {
          var list = this.wawiLists.find(wawiList => wawiList.id == field.listId);
          var dataSource = new DataSource({
            store: list.fields,
            paginate: true,
            pageSize: 5
          });
          this.wawiListDataSourceMap.set(field.listId, dataSource);
          customWawiField.listId = field.listId;
        }
        currentFields.push(customWawiField);
      });
      this.model.wawi.customFields = currentFields;
    });
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, field?: string, system: string = "-1", element: string = "-1") {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }



  // not used??
  showPlaceLabel(order: number): boolean {
    return false;
  }

  getLabel(order: number): string {
    let field = this.modelService.loginService.wawiSettings.customFields.find(x => x.order === order);
    if (field) {
      return field.name;
    }
    return "";
  }

  getFieldType(order: number): string {
    let field = this.modelService.loginService.wawiSettings.customFields.find(x => x.order === order);
    if (field) {
      return field.fieldType;
    }
    return "";
  }

  createLines() {
    this.modelService.productService.createLines(this.model).subscribe((result: Product) => {

      this.model = this.modelService.catalogService.setProduct(result);

    });

  }

  hasLines(): boolean {
    if (this.modelService.loginService.wawiSettings.hasLine1 ||
      this.modelService.loginService.wawiSettings.hasLine2 ||
      this.modelService.loginService.wawiSettings.hasLine3 ||
      this.modelService.loginService.wawiSettings.hasLine4)
      return true;
    return false;
  }



  //ExcelTemplate

  public elementSelectFunction(identifier: string, order: string = "-1") {
    if (order)
      this.elementSelected.emit(`${identifier}_${order}`);
    else
      this.elementSelected.emit(`${identifier}`);
  }



  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }



  public readOnlyForLinkedFields(field: string, system: string = "-1", element: string = "-1"): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public showClearButton(field: string, system: string = "-1"): boolean {
    return this.readOnlyForLinkedFields(field, system) && !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    //// Im Übersetzungsmodus nicht anzeigen
    //if (this.hasTranslation) {
    //  return false;
    //}

    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = "-1", element: string = "-1"): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  getListElements(name) {
    var defaultDataSource = new DataSource({
      store: []
    });

    let customField = this.modelService.loginService.wawiSettings.customFields.find(x => x.order == name.order);
    if (customField == undefined) {
      return defaultDataSource;
    }
    let result = this.wawiListDataSourceMap.get(customField.listId);
    if (result == undefined) {
      return defaultDataSource;
    }

    return result;
  }
}
