import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";

import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";



@Component({
  selector: "userAction-createEnventaMime",
  templateUrl: "userAction-createEnventaMime.component.html"
})
export class UserActionCreateEnventaMimeComponent extends  UserActionBaseComponent {

  constructor(public modelService: ModelService) {
    super(modelService);
  }
  postfix :string = "";
  startMacro() {
   

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `CreateEnventaMime(${this.postfix})`;

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
