
import { Injectable } from "@angular/core";
import { Message } from '../Model/System/Message';
import notify from "devextreme/ui/notify";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { CustomerWiki } from "../Model/System/CustomerWiki";
import { CustomerWikiDetail } from "../Model/System/CustomerWikiDetail";


@Injectable()
export class SystemService {
  private titleSubject = new BehaviorSubject<string>(null);
  titleUpdate$ = this.titleSubject.asObservable();

  constructor(private http: HttpClient) { }

  public showMenu: boolean = true;
  public showHelp: boolean = false;
  public searchText: string = "";
  public pageIndexCatalogList: number = 0;

  public showShop: boolean = false;
  public templateLukas: boolean = false;


  public notify(message: Message, displayTime?: number) {
    notify({
      message: message.message,
      position: {
        my: "center",
        at: "center"
      }
    },
      message.type,
      displayTime);
  }
  public notifyWarning(message: string, displayTime?: number) {
    notify({
      message: message

    },
      "error",
      displayTime);
  }


  public notifyInfo(message: string, displayTime?: number) {

    this.notify(new Message(message), displayTime);

  }

  public toDo(string: string) {
    console.log(string);
  }

  public showGroupReference: boolean;
  public showToolTips: boolean;

  getFileSizeString(size) {
    if (isNaN(size))
      size = 0;

    if (size < 1024)
      return size + ' Bytes';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' Kb';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' Mb';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' Gb';

    size /= 1024;

    return size.toFixed(2) + ' Tb';
  }



  //Link testen
  public testUrl(fileUrl: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("fileUrl", fileUrl)
    }
    return (this.http.get<TestResult>("api/import/CheckFileUrl", options)) as any;
  }

  public mediaUrlImport(fileUrl: string, catalogId: string, customerId: string): Observable<TestResult> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("fileUrl", fileUrl).append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get<TestResult>("api/import/ImportMimeUrl", options)) as any;

  }

  public catalogUrlImport(fileUrl: string, catalogId: string, customerId: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("fileUrl", fileUrl).append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get<TestResult>("api/import/ImportCatalogUrl", options)) as any;
  }

  public categoryUrlImport(fileUrl: string, catalogId: string, customerId: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("fileUrl", fileUrl).append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get<TestResult>("api/job/CreateCategoryImportFromUrlJob", options)) as any;
  }

  public editHelp: boolean = false;

  private _currentNavigationTitle: string;
  set currentNavigationTitle(title: string) {
    this._currentNavigationTitle = title;
    this.titleSubject.next(title);
  }
  get currentNavigationTitle() {
    return this._currentNavigationTitle;
  }

  public help: CustomerWikiDetail[] = new Array<CustomerWikiDetail>();

  public selectedItems = [];

  saveHelp(customerId: string) {
    let wiki = new CustomerWiki();
    wiki.customerId = customerId;
    wiki.entries = this.help;
    this.http.post("api/CustomerWiki/SetCustomerWiki", wiki).subscribe();
  }

  public loadHelp(customerId: string): Observable<CustomerWiki> {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("customerId", customerId)
      }
      return (this.http.get<CustomerWiki>("api/CustomerWiki/GetCustomerWiki", options)) as any;
    }
  }
}
