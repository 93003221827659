
<div class="dx-field">
  <div class="dx-field-label">{{ 'Postfix' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ postfix?.length > 0 ? 'placeLabelSmall' : '' }}">POSTFIX</label>
    <dx-text-box  [(value)]="postfix"
                
                  placeholder=""></dx-text-box>
  

    
  </div>
</div>
