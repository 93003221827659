<div *ngIf="model" style="margin:20px; width:800px;">
  <div class="column">
    <div style="padding-left:0px;width:550px;float:left;">
      <h5 style="margin-top:0px;">{{model?.descriptionShort}}</h5>
      <p>
        <b>{{marketingClaim}}</b>
      </p>
      <div>
        <div *ngIf="model?.productImagesLogos.length>0" style="float:right;">
          <img src="{{model.productImagesLogos[0].blobUri}}" style="width:110px;" />
        </div>
        <div *ngIf="model.supplierPid.length>0"><b>{{ 'Artikelnummer' | translate }}: </b>{{model.supplierPid}}</div>
        <div *ngIf="model.manufacturerAid?.length>0"><b>{{ 'Herstellernummer' | translate }}: </b>{{model.manufacturerAid}}</div>
        <div *ngIf="model.manufacturerName?.length>0"><b>{{ 'Hersteller' | translate }}: </b>{{model.manufacturerName}}</div>
      </div>
      <div *ngIf="model?.points.length>0" style="margin-top:20px">
        <table>
          <tr *ngFor="let point of model.points">
            <td><b>{{point.name}}</b></td>
            <td style="width:20px;"></td>
            <td>
              <i *ngFor='let item of forLoopArray(point.points)' class="dx-icon-favorites" style="color:#0093D6"></i><i *ngFor='let item of forLoopArray(point.max - point.points)' class="dx-icon-favorites"></i>
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-top:20px">
        <span [innerHTML]="model?.descriptionLong"></span>
      </div>
    </div>
    <div style="padding-right:0px;width:250px;float:right;">
      <div *ngIf="model.productImagesNormal.length>0" style="margin-bottom:10px;float:right;">
        <dx-gallery [dataSource]="mimes"
                    [height]="230"
                    [width]="230"
                    [loop]="true"
                    [showIndicator]="showIndicator"
                    [showNavButtons]="false">
          <div *dxTemplate="let galleryItem of 'item'">
            <div>
              <img src="{{galleryItem.normUri}}" width="230" height="230" />
            </div>
          </div>
        </dx-gallery>
      </div>
      <span *ngIf="model.productImagesNormal.length==0" class="glyphicon glyphicon-picture" style="font-size: 230px;float:right;"></span>
      <div class="shopViewIconContainer">
        <div *ngFor="let galleryItem of model.productImagesIcon" class="shopViewIcons">
          <img src="{{galleryItem.thumbUri}}" />
        </div>
      </div>

      <div *ngIf="hasPrice" class="card card-body bg-light" style="float:right;width:230px;">
     
          <div class="d-flex flex-row">
            <h6>{{price.prouctPrice}}</h6> 
          </div>
          <div class="d-flex flex-row">
           {{ 'zzgl' | translate }}. {{price.prouctTax}}% {{ 'Mwst' | translate }}.
          </div>
          <div class="d-flex accordion-collapseflex-row">
            {{price.prouctUnit}}
          </div>
        </div>
      
    </div>
  </div>
  <div class="column" style="float: left;margin-top:10px;width:100%;" *ngIf="model.features.length>0">
    <dx-tab-panel [dataSource]="features"
                  [selectedIndex]="0"
                  [loop]="false"
                  [animationEnabled]="true"
                  [swipeEnabled]="true">
      <div *dxTemplate="let features of 'title'">
        <span>{{features.name | translate}}</span>
      </div>
      <div *dxTemplate="let featureSys of 'item'">
        <div [ngSwitch]="featureSys.type">
          <div class="dx-fieldset" *ngSwitchCase="'normal'">
            <dx-data-grid *ngIf="featureSys.hasName"
                          [dataSource]="featureSys.features"
                          [rowAlternationEnabled]="true"
                          [columnAutoWidth]="true"
                          [showBorders]="true">
              <dxi-column dataField="name" caption="{{ 'Bezeichnung' | translate }}" ></dxi-column>
              <dxi-column dataField="value" caption="{{ 'Wert' | translate }}" ></dxi-column>
              <dxi-column dataField="funit" caption="{{ 'Einheit' | translate }}"  ></dxi-column>
            </dx-data-grid>


            <div *ngIf="!featureSys.hasName">
              <ul>
                <li *ngFor="let feature of featureSys.features; let i = index">
                  {{feature.value}}
                </li>
              </ul>
            </div>


          </div>
          <div class="dx-fieldset" *ngSwitchCase="'data_sheet'">
            <ul style="list-style: none;padding-left:0px;">
              <li *ngFor='let download of model.productDataSheets' class="dataSheetDownload"><a href="{{mediaService.getDownloadLink(this.catalogId,this.customerId,download.mimeSource)}}" target="_blank"><img src="/images/Adobe_PDF_icon.svg" style="height: 20px;margin-right: 10px;">{{download.mimeDescr}}</a></li>
            </ul>
          </div>
          <div class="dx-fieldset" *ngSwitchCase="'warranty'">
            <span [innerHTML]="model.warrantyClass.descriptionHtml"></span>
          </div>
          <div class="dx-fieldset" *ngSwitchCase="'hazmat'">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-12">
                  <img *ngFor='let item of model.hazmatClass.hazardPictograms' src="/images/ghs/{{item}}.png" class="hazmatIcon" />
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-6">
                  <div class="hazmatHeadline">{{ 'Gefahrenhinweise' | translate }}</div>
                  <div *ngFor="let item of model.hazmatClass.hazardStatements" class="hazmatStatement">
                    {{item}} {{ item | translate }}
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="hazmatHeadline">{{ 'Sicherheitshinweise' | translate }}</div>
                  <div *ngFor="let item of model.hazmatClass.precautionaryStatements" class="hazmatStatement">
                    {{item}} {{ item | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-tab-panel>
  </div>
</div>
