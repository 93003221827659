<div class="dx-fieldset">

  <!--MetaTitle-->
  <div class="np-field">
    <np-inputControl [showLinkButtons]="false"
                     [showPlaceLabel]="model?.metaTitel?.length > 0"
                     labelText="{{ 'Meta Titel' | translate }}"
                     field="META_TITLE"
                     [system]="-1"
                     [element]="-1"
                     [suppressLinkButton]="true"
                     [suppressPlaceLabel]="true"
                     [suppressDragHandle]="true">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.metaTitel"
                     [readOnly]="readOnlyUser('seoTitle')"
                     [showClearButton]="true"
                     (onValueChanged)="categoryUpdate($event)">
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <!--MetaDescription-->
  <div class="np-field">
    <np-inputControl [showLinkButtons]="false"
                     [showPlaceLabel]="model?.metaDescription?.length > 0"
                     labelText="{{ 'Meta Beschreibung' | translate }}"
                     field="META_DESCRIPTION"
                     [system]="-1"
                     [element]="-1"
                     [suppressLinkButton]="true"
                     [suppressPlaceLabel]="true"
                     [suppressDragHandle]="true">

      <div slot="content" class="contentSlot">
        <dx-text-area [(value)]="model.metaDescription"
                      [readOnly]="readOnlyUser('seoDescription')"
                      [height]="130"
                      (onValueChanged)="categoryUpdate($event)">
        </dx-text-area>
      </div>

    </np-inputControl>
  </div>

  <div class="np-field">
    <np-inputControl [showLinkButtons]="false"
                     labelText="{{ 'Langbeschreibung' | translate }}"
                     field="DESCRIPTION_LONG"
                     [system]="-1"
                     [element]="-1"
                     [suppressLinkButton]="true"
                     [suppressPlaceLabel]="true"
                     [suppressDragHandle]="true"
                     [showHTMLEditor]="true">

      <div slot="content" class="contentSlot">


        <div class="w-100">
          <dx-html-editor class="w-100"
                          [(value)]="model.htmlDescription"
                          placeholder="DESCRIPTION_LONG"
                          [readOnly]="readOnlyUser('productDescLong')"
                          (onFocusOut)="categoryUpdate($event)">
            <dxo-toolbar>
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="link"></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>
        </div>




      </div>


    </np-inputControl>
  </div>


</div>
