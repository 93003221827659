export class ClassificationSystem {

  constructor(
    public id?: string,
    public name?: string,
    public language?: string,
    public date?: Date,
    public version?: number
  ) {
  }
}
