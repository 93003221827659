import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { TranslateService } from "@ngx-translate/core";
import { Message } from "../Model/System/Message";
import { TemplateService } from "../Services/template.service";
import { DxSelectBoxComponent } from "devextreme-angular/ui/select-box";
import { Template } from "../Model/Catalog/Template";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "templateSelector",
  templateUrl: "templateSelector.component.html",
  styleUrls: ["./templateSelector.component.css"]
})
export class TemplateSelectorComponent implements OnInit {

  @ViewChild("selectBox") selectBox: DxSelectBoxComponent;

  @Input() exportVersion: string = "";
  @Output() templateSelected = new EventEmitter<string>();

  txtAddTemplate: string;
  canAddTemplate: boolean = false;



  constructor(public modelService: ModelService, public templateService: TemplateService, public translate: TranslateService) {
    this.getAddTemplateHint = this.getAddTemplateHint.bind(this);
  }

  async ngOnInit() {
    if (this.exportVersion === 'CIF_V3') {
      this.templateService.getSAPAribaTemplates();
    } else {
      this.templateService.getExportTemplates()
    }
    this.txtAddTemplate = this.translate.instant("Template erstellt");
    this.canAddTemplate = !(await lastValueFrom(this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)));
  }

  public addTemplateVisible = false;
  showAddTemplate() {
    this.addTemplateVisible = true;
  }

  async addTemplate(e = null) {
    this.addTemplateVisible = false;

    let newTemplate = new Template();
    newTemplate.lastChange = newTemplate.created;   // Wird nicht vom CTOR gesetzt
    newTemplate.isExportTemplate = true;

    newTemplate.name = e;



    let id = await this.templateService.addTemplate(newTemplate);
    //this.templateService.addTemplate();
    this.modelService.systemService.notify(new Message(this.txtAddTemplate));

    if (this.exportVersion === 'CIF_V3') {
      this.templateService.getSAPAribaTemplates();
    } else {
      this.templateService.getExportTemplates()
    }
    this.templateSelected.emit(this.selectBox.value);
    this.canAddTemplate = !(await lastValueFrom(this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)));
  }

  selectTemplate(e) {
    if (e.selectedItem != null) {
      this.templateService.setCurrentExportTemplate(e.selectedItem.id);
      this.templateSelected.emit(e.selectedItem.id);
    }
    else {
      this.templateService.setCurrentExportTemplate(null);
      this.templateSelected.emit(null);
    }
  }

  getAddTemplateHint() {
    if (this.canAddTemplate)
      return null;

    return this.translate.instant("In ihrer Lizenz dürfen Sie keine Templates mehr anlegen.");
  }
}
