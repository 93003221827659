import { Component, Input } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { CatalogDataQuality } from '../../Model/validation/CatalogDataQuality';
import { Message } from "../../Model/System/Message";
import { UserManagementService } from "../../Services/userManagment.service";
import { ExportParameter } from "../../Model/exportParameter/ExportParameter";
@Component({
  selector: "dataQualityStatistic",
  templateUrl: "dataQualityStatistic.component.html",
  styleUrls: []
})
export class DataQualityStatisticComponent {
  @Input() catalogDataQuality: CatalogDataQuality;

  txtExportAlsJob: string;

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.exportDetails = this.exportDetails.bind(this);

    this.txtExportAlsJob = this.translate.instant("Der Export wird als Job ausgeführt");
  }

  exportDetails() {
    var result = new ExportParameter();
    result.catalogId = this.modelService.catalogService.selectedCatalogId;
    result.customerId = this.modelService.loginService.currentUser.customerId;
    result.format = "Qualitydetails";

    this.modelService.catalogService.createExportJob(result).subscribe(
      () => {
        this.modelService.systemService.notify(new Message(this.txtExportAlsJob));

      });
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
