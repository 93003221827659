<dx-data-grid [dataSource]="backUps"
              [remoteOperations]="false"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Datensicherungen im Account' | translate }}"
              [showBorders]="true"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              (onRowRemoving)="deleteBackUp($event)">

  <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
    <dx-button  stylingMode="text" type="default" text="{{ 'Aktualisieren' | translate }}" (onClick)="getBackUps()" hint="{{ 'Liste aktualisieren' | translate }}"></dx-button>
  </div>
  <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="false" [useIcons]="true"></dxo-editing>
  <dxo-group-panel [visible]="true" emptyPanelText="{{ 'Um Spalte zu gruppieren: Spaltenüberschrift hierhin ziehen' | translate }}."></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-search-panel [visible]="true" placeholder="{{ 'Suchen' | translate }}" [highlightCaseSensitive]="true"></dxo-search-panel>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="BackupsStateNewUIV2"></dxo-state-storing>

  <dxi-column dataField="backupDate" caption="{{ 'Datum' | translate }}" [width]="105" dataType="date" [format]="{ type: 'dd.MM.yyyy' }"></dxi-column>
  <dxi-column dataField="time" caption="{{ 'Uhrzeit' | translate }}" [width]="90"></dxi-column>
  <dxi-column dataField="isRestorePoint" caption="{{ 'manuell' | translate }}" [width]="70"></dxi-column>
  <dxi-column dataField="catalogName" caption="{{ 'Katalogname' | translate }}"></dxi-column>
  <dxi-column dataField="supplierName" caption="{{ 'Quelle' | translate }}"></dxi-column>
  <dxi-column caption="" width="80" cellTemplate="editBackupTemplate"></dxi-column>
  <div *dxTemplate="let data of 'editBackupTemplate'" class="center">

    <div class="d-flex flex-row">

      <div *ngIf="data.data.xmlStore">
        <a href="{{data.data.xmlStore}}" class="glyphicon glyphicon-cloud-download link mr-2" title="{{ 'Katalog download' | translate }}"></a>
      </div>

      <div *ngIf="data.data.hasMedia">
        <a (click)="downloadMedia(data.data.id)" class="glyphicon glyphicon-picture link mr-2" title="{{ 'Medien download' | translate }}"></a>
      </div>

      <div>
        <a (click)="restoreBackUp(data.data.id)" class="glyphicon glyphicon-open link mr-2" title="{{ 'Katalog wiederherstellen' | translate }}"></a>
      </div>

    </div>

  </div>

</dx-data-grid>


