import { Functions } from '../../Services/functions';
export class Packaging {

  constructor(
    public depthNumber?: number,
    public lengthNumber?: number,
    public weightNumber?: number,
    public widthNumber?: number,
    public order?: number,
    public internationalPid?: string,
    public orderUnit?: string,
    public contentQuantity?: number,
    public guid: string = Functions.newGuid()
  ) {

  }
}
