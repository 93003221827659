<np-dialog [popupWidth]="1200" [popupHeight]="800" [popupTitle]="'Feldkonfiguration' | translate"
  [(popupVisible)]="visible">
  <div slot="content" class="h-100 w-100">
    <dx-scroll-view width="100%" height="100%" showScrollbar="always">
      <dx-tab-panel [dataSource]="excelPages" [(selectedIndex)]="excelIndex">
        <!--EXCEL FIELDS-->
        <div *dxTemplate="let company of 'excelFieldsTemplate'"
          class="{{ fullScreen ? 'exportExcelTabFullScreen': 'exportExcelTab' }}">
          <dx-tile-view *ngIf="modelService.categoryService.fields.length > 0" class="dx-tile-content excelFields"
            [baseItemWidth]="285" direction="vertical" width="100%" height="100%" baseItemHeight="15">
            <dxi-item *ngFor="let group of groups" [widthRatio]="1" [heightRatio]="getHeightRatio(group)">
              <div class="p-1 pb-3">
                <dx-check-box (value)="false" (onValueChanged)="changedField($event,group)"
                  style="float: left; padding-right: 10px;">
                </dx-check-box>
                <b>{{ group }}</b>
              </div>
              <div *ngFor="let field of fields">
                <div *ngIf="field.group==group" style="height: 35px">
                  <div style="width: 40px; float: left; padding-left: 10px;">
                    <dx-check-box [readOnly]="readOnly(field.field)" [(value)]="field.active">
                    </dx-check-box>
                  </div>
                  <label class="exportLabel">{{ field.field }}</label>
                  <div style="width: calc(100% - 30px); margin-left: 40px;" class="exportText">{{ field.field |
                    translate }}</div>
                </div>
              </div>
            </dxi-item>
          </dx-tile-view>
        </div>



        <div *dxTemplate="let company of 'miscTemplate'" class="{{fullScreen ? 'exportTabFullScreen' : 'exportTab'}}">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'Medien exportieren' | translate }}</div>
              <div class="dx-field-value">
                <dx-switch [(value)]=exportMedia></dx-switch>
              </div>
            </div>
            <div *ngIf="exportMedia">
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Original verwenden' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch [(value)]=exportMediaOriginalSize></dx-switch>
                </div>
              </div>
              <!--SQUARE IMAGE-->
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Quadratische Ansicht' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                  <label class="placeLabel {{ imageSquared ? 'placeLabelSmall' : '' }}">SQUARE IMAGE</label>
                  <dx-switch [(value)]="imageSquared"></dx-switch>
                </div>
              </div>
              <!--BACKGROUND COLOR-->
              <div class="dx-field" *ngIf="imageSquared">
                <div class="dx-field-label">{{ 'Hintergrundfarbe wählen' | translate }}</div>
                <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                  <label class="placeLabel {{ imageBgColor ? 'placeLabelSmall' : '' }}">BACKGROUND
                    COLOR</label>
                  <dx-color-box [(value)]="imageBgColor"></dx-color-box>
                </div>
              </div>
            </div>
            <div *ngIf="!exportMediaOriginalSize">
              <!--MAX RESOLUTION-->
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Maximale Seitenlänge' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                  <label class="placeLabel {{ maxResolution>0 ? 'placeLabelSmall' : '' }}">MAX RESOLUTION</label>
                  <dx-number-box [min]="16" [max]="8192" [(value)]="maxResolution"
                    [showClearButton]="true"></dx-number-box>
                </div>
              </div>
              <!--FILE TYPE-->
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Dateityp' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
                  <label class="placeLabel {{ imageType?.length>0 ? 'placeLabelSmall' : '' }}">FILE TYPE</label>
                  <dx-select-box [(value)]="imageType" [items]="imageTypes" placeholder=""></dx-select-box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--EXCEL TEMPLATES -- ENTFERNT-->
      </dx-tab-panel>
      <np-dialog-button slot="toolbar" toolbar="bottom" location="after" widget="dxButton"
        [options]="{ text: 'Excel exportieren' | translate, onClick: exportExcel }"></np-dialog-button>
      <np-dialog-button *ngIf="modelService.loginService.hasAddonCSV" slot="toolbar" toolbar="bottom" location="after"
        widget="dxButton" [options]="{ text: 'CSV exportieren' | translate, onClick: exportCSV }"></np-dialog-button>
      <np-dialog-button slot="toolbar" toolbar="bottom" location="after" widget="dxButton"
        [options]="{ icon: 'revert', onClick: reset }"></np-dialog-button>
    </dx-scroll-view>
  </div>
</np-dialog>