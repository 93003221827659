import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pindata",
  templateUrl: "pindata.component.html"
})
export class PinDataComponent implements OnInit, OnDestroy {

  @Input() pinned: boolean = false;
  @Input() remarks: string = "";
  @Input() pinExpires?: Date = null;

  @Output() onAdded = new EventEmitter<any>();
  @Output() onRemoved = new EventEmitter<any>();

  constructor(public translate: TranslateService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  onAdd() {
    let parameter = { remarks: this.remarks, pinExpires: this.pinExpires };
    this.onAdded.emit(parameter);

    this.remarks = "";
    this.pinExpires = null;
  }

  onRemove() {
    this.onRemoved.emit();
  }
}
