export class CustomWawiField {

  constructor(
    public order?: number,

    public elementId?: number,
    public listId?: string,
    public stringValue?: string,
    public numberValue?: number,
    public boolValue?: boolean
  ) {
  }
}
