<div [ngStyle]="{'height': 'inherit', 'display':'flex', 'flex-direction':'column'}">
  <div class="dx-fieldset" [ngStyle]="{'margin': '20px 30px'}">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Lieferanten filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.supplierFilter.useFilter" (onValueChanged)="loadsupplierFilterIfNeeded()"></dx-switch>
      </div>
    </div>
  </div>
  <div *ngIf="exportParameter.supplierFilter.useFilter" [ngStyle]="{'height': '100%', 'overflow':'hidden'}">
    <div *ngIf="isFilterLoaded==false" style="top: 30%;">
      <div class="loader">
      </div>
    </div>
    <div *ngIf="isFilterLoaded==true" class="{{fullScreen ? '' : 'maxHeight'}}" [ngStyle]="{'margin-top': '-20px', 'height': 'inherit'}">
      <dx-tree-list [(dataSource)]="supplierFilters"
                    [(selectedRowKeys)]="exportParameter.supplierFilter.filterKey"
                    [ngStyle]="{'height': 'inherit'}"
                    keyExpr="name">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection mode="multiple" recursive="true"></dxo-selection>
        <dxo-scrolling mode="standart"></dxo-scrolling>
        <dxo-paging [enabled]="true" [pageSize]="50"> </dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[10, 25, 50]"
                   [showInfo]="true">
        </dxo-pager>
        <dxi-column dataField="name" caption="{{ 'Lieferant' | translate }}"></dxi-column>
      </dx-tree-list>
    </div>
  </div>
</div>
