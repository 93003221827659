import { lastValueFrom } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import 'devextreme/data/odata/store';
import { ItemClickEvent } from 'devextreme/ui/tab_panel';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { confirm } from 'devextreme/ui/dialog';

import { LoginService } from 'app/Services/login.service';
import { TemplateService } from 'app/Services/template.service';
import { WawiListService } from 'app/Services/wawiList.service';
import { ValidFeatureService } from 'app/Services/validFeature.service';
import { SystemService } from 'app/Services/system.service';

import { Template } from 'app/Model/Catalog/Template';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { ValidFeatureSystem } from 'app/Model/ValidFeatureSystem';
import { WawiList } from 'app/Model/wawi/WawiList';
import { TemplateWorksheet } from 'app/Model/Catalog/TemplateWorksheet';
import { HazmatClassService } from 'app/Services/hazmatClass.service';
import { WarrantyClassService } from 'app/Services/warrantyClass.service';
import { HazmatClass } from 'app/Model/Catalog/HazmatClass';
import { WarrantyClass } from 'app/Model/Catalog/WarrantyClass';
import { LanguageFlag } from 'app/Model/Dto/LanguageFlag';

type AutocompleteValues = { groupId: string; value: string };
type TemplateSheetItemWrapper =
  | { isHeader: boolean; order: number; name: string; templateItems: TemplateItem[] }
  | TemplateWorksheet;

@Component({
  selector: 'np-expert-template',
  templateUrl: './advanced-template.component.html',
  styleUrls: ['./advanced-template.component.css']
})
export class AdvancedTemplateComponent implements OnInit, OnDestroy {
  @Input() customerId: string;
  @Input() templateId: string;
  @Input() catalogId: string;

  validFeatureSystems: ValidFeatureSystem[] = [];
  wawiListMapping: WawiList[] = [];
  hazmatClasses: HazmatClass[];
  warrantyClasses: WarrantyClass[];

  levelDepth = 0;
  levelChoices: AutocompleteValues[] = [];
  levelChoiceGroups: { [key: string]: string[] };
  sheetNameEditorIndex = -1;
  sheetNameEditorOriginalName: string = null;
  sheetIndex = 0;

  _template: Template;

  set template(value: Template) {
    this._template = value;
  }

  get template() {
    return this._template;
  }

  languageFlags: LanguageFlag[] = [];

  set isHeaderVisible(value: boolean) {
    this.setTemplateHeaderVisibility(value);
  }

  get isHeaderVisible() {
    return this.template.useMetadata && this.getTemplateHeaderVisibility();
  }

  worksheets: TemplateWorksheet[];
  sheetItemWrapper: TemplateSheetItemWrapper[];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private wawiListService: WawiListService,
    private templateService: TemplateService,
    private loginService: LoginService,
    private validFeatureService: ValidFeatureService,
    private systemService: SystemService,
    private hazmatService: HazmatClassService,
    private warrantyClassService: WarrantyClassService
  ) {}

  async ngOnInit() {
    this.handleParams();
    this.loginService.getLanguages().subscribe(() => {
      this.languageFlags = this.loginService.languageFlags
    });
    this.validFeatureService
      .getSystems(this.customerId, false)
      .subscribe((result) => (this.validFeatureSystems = result));
    this.hazmatService.getClasses().subscribe((result) => (this.hazmatClasses = result));
    this.warrantyClassService.getClasses().subscribe((result) => (this.warrantyClasses = result));
    this.wawiListService
      .getAllListsWithoutFields(this.loginService.currentCustomer.id)
      .subscribe((result) => (this.wawiListMapping = result.filter((x) => x.isMapping)));

    await lastValueFrom(this.templateService.getTemplateById(this.templateId));
    this.template = this.templateService.template;
    this.worksheets = this.template.worksheetItems;

    if (this.worksheets.length === 0) {
      this.worksheets.push(
        new TemplateWorksheet(1, this.translate.instant('Tabelle #no', { count: 1 }), this.template.templateItems)
      );
    }

    if (this.worksheets[this.sheetIndex] === undefined) {
      this.sheetIndex = 0;
    }

    if (this.template.useMetadata && this.template.templateType === 'SAP_ARIBA') {
      this.sheetItemWrapper = [
        { isHeader: true, order: 0, name: 'Header', templateItems: [] },
        this.template.worksheetItems[0]
      ];
    } else {
      this.sheetItemWrapper = this.template.worksheetItems;
    }
  }

  ngOnDestroy() {
    this.templateService.template = null;
  }

  onSelectWorksheet(event: ItemClickEvent) {
    this.sheetIndex = event.itemIndex;
  }

  async onAddWorksheet() {
    const order = this.worksheets.length + 1;
    this.worksheets.push(new TemplateWorksheet(order, this.translate.instant('Tabelle #no', { count: order })));
    this.update();
  }

  async onRemoveWorksheet(index: number) {
    const count = this.worksheets[index]?.templateItems?.length ?? 0;
    let dialogResult = await confirm(
      this.translate.instant('ConfirmDeleteWorksheet', { count }),
      this.translate.instant('Tabellenseite löschen?')
    );
    if (!dialogResult) return;

    this.worksheets.splice(index, 1);
    this.update();
  }

  async onSaveWorksheetRename(event: ValueChangedEvent) {
    this.sheetNameEditorOriginalName = event.previousValue;
    const sheet = this.worksheets[this.sheetNameEditorIndex];
    sheet.order = this.sheetNameEditorIndex + 1;
    this.update();
  }

  async onCancelWorksheetRename(index: number) {
    this.sheetNameEditorIndex = -1;
    const sheet = this.worksheets[index];
    sheet.name = this.sheetNameEditorOriginalName;
    this.update();
  }

  onSavingSAPAriba() {
    if (this.template.templateType === 'SAP_ARIBA') {
      this.template.templateType = null;
    } else {
      this.template.templateType = 'SAP_ARIBA';
      if (!this.template.metadata?.length) {
        const headerIndex = this.template.worksheetItems.findIndex((x) => ['header'].includes(x.name.toLowerCase()));
        if (headerIndex > -1) {
          const [headerSheet] = this.template.worksheetItems.splice(headerIndex, 1);
          if (headerSheet?.templateItems?.length) {
            this.template.metadata = headerSheet.templateItems.map((x) => ({
              name: x.keys[0],
              order: x.order,
              value: x.defaultValue
            }));
          }
        }
      }
    }

    if (this.template.useMetadata && this.template.templateType === 'SAP_ARIBA') {
      this.sheetItemWrapper = [
        { isHeader: true, order: 0, name: 'Header', templateItems: [] },
        this.template.worksheetItems[0]
      ];
    } else {
      this.sheetItemWrapper = this.template.worksheetItems;
    }

    this.update();
  }

  private isTemplateVisibilitySet() {
    return JSON.parse(localStorage.getItem('templateHeaderVisibility') ?? '{}')[this.template.id] !== undefined;
  }

  private getTemplateHeaderVisibility() {
    return JSON.parse(localStorage.getItem('templateHeaderVisibility') ?? '{}')[this.template.id] ?? true;
  }

  private setTemplateHeaderVisibility(visible: boolean) {
    const templateHeaderVisibility = JSON.parse(localStorage.getItem('templateHeaderVisibility') ?? '{}');
    templateHeaderVisibility[this.template.id] = visible;
    localStorage.setItem('templateHeaderVisibility', JSON.stringify(templateHeaderVisibility));
  }

  async update() {
    for (const sheet of this.worksheets) {
      for (const item of sheet.templateItems) {
        if (item.key?.trim()) {
          item.key = '';
        }
      }
    }
    for (const item of this.template.templateItems) {
      if (item.key?.trim()) {
        item.key = '';
      }
    }

    if (this.template.useMetadata && !this.isTemplateVisibilitySet()) {
      this.setTemplateHeaderVisibility(true);
    }

    await this.templateService.saveTemplate(this.template);
  }

  private handleParams() {
    this.templateId = this.templateId ?? this.route.snapshot.paramMap.get('templateId')!;
    this.customerId = this.customerId ?? this.route.snapshot.paramMap.get('customerId')!;
    this.catalogId = this.catalogId ?? this.route.snapshot.paramMap.get('catalogId')!;

    if (this.loginService?.currentUser?.customerId !== this.customerId) {
      throw 'UnauthorizedCustomer';
    }

    this.systemService.currentNavigationTitle = this.translate.instant('Experten Templates');
  }
}
