export class PrintCredential {

  constructor(
    public id?: number,
    public customerId?: string,
    public login?: string,
    public password?: string,

  ) {
  }
}
