import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { ApplyWawiMappingModel } from "../../../../Model/wawi/ApplyWawiMappingModel";
import { ExportParameter } from "../../../../Model/exportParameter/ExportParameter";
import { UpdateTemplateService } from "../../../../Services/updateTemplate.service";
import { CatalogService } from "../../../../Services/CatalogManagement/catalog.service";
import { TranslateService } from "@ngx-translate/core";
import { WawiListService } from "../../../../Services/wawiList.service";
import { TemplateItemTemplate } from "../../../../Model/Catalog/TemplateItemTemplate";
import { lastValueFrom } from 'rxjs';

@Component({
  selector: "userAction-applyWawiMappings",
  templateUrl: "userAction-applyWawiMappings.component.html"
})
export class UserActionApplyWawiMappings extends UserActionBaseComponent implements OnInit {
  applyWawiMappingModel: ApplyWawiMappingModel;
  config: ExportParameter = new ExportParameter();
  fields: TemplateItemTemplate[] = [];
  fieldGroups: string[] = [];

  constructor(public modelService: ModelService, public updateTemplateService: UpdateTemplateService,
    public catalogService: CatalogService, public translate: TranslateService, public wawiListService: WawiListService) {
    super(modelService);
    this.applyWawiMappingModel = new ApplyWawiMappingModel();
  }

  async ngOnInit() {
    super.ngOnInit();
    this.fields = await lastValueFrom(this.wawiListService.getWawiMappingFields(this.modelService.loginService.currentUser.customerId));
    this.fieldGroups = this.updateTemplateService.GetFieldGroups();
  }

  startMacro() {
    if (!this.config.fieldsToMappingIds ||
        Object.keys(this.config.fieldsToMappingIds).length == 0 ||
        Object.values(this.config.fieldsToMappingIds).every(x => x == "")) {
      this.modelService.systemService.notifyInfo(this.translate.instant("mindestens ein Feld markieren und das Mapping ausfüllen"));
      return;
    }
    this.applyWawiMappingModel.fieldToMappingId = this.config.fieldsToMappingIds;
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "ApplyWawiMappings";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify(this.applyWawiMappingModel);
  }
}
