export class InvoiceSettingDto {
  constructor(
    public id?: number,
    public customerId?: string,
    public limit?: number,
    public usedLimit?: number,
    public pricePerOpenAi?: number,
    public allowance?: number,
  ) {
  }
}
