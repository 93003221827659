import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ClassificationSystem } from "../Model/classification/ClassificationSystem";

@Component({
  selector: "editClassificationSystem",
  templateUrl: "editClassificationSystem.component.html"
})
export class EditClassificationSystemComponent implements OnInit {

  constructor(public modelService: ModelService) {

  }

  ngOnInit(): void {

/*    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }*/

    this.modelService.classificationService.getSystems();
  }

  onInsertCs(e) {

    let system = new ClassificationSystem();
    system.name = e.data.name;
    this.modelService.classificationService.writeSystem(system).subscribe(
      (result: ClassificationSystem) => {
        e.data.id = result.id;
      });
  }

  onCsChanged(e) {

    let system = new ClassificationSystem();

    system.id = e.key.id;
    system.name = e.key.name;
    this.modelService.classificationService.writeSystem(system).subscribe(
      (result: ClassificationSystem) => {

      });

  }

  onCsRemoved(e) {
    this.modelService.classificationService.deleteSystem(e.data.id);
  }

  public onLogoClicked() {
    this.modelService.router.navigate(["/catalogs"]);
  }

  onEditDetailsClicked(data: ClassificationSystem) {
    let x = data;
  }

  get model(): ClassificationSystem[] {
    return this.modelService.classificationService.classificationSystems;
  }

  set model(system: ClassificationSystem[]) {
    this.modelService.classificationService.classificationSystems = system;
  }

}
