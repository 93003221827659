<dx-data-grid id="gridContainer"
              class="h-100"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="model?.priceLists"
              [remoteOperations]="false"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              (onRowUpdated)="update()"
              (onRowRemoved)="removePriceList($event)">

  <div *dxTemplate="let toolbaritem of 'newPricelistButtonTemplate'">
    <div *ngIf="!readOnlyUser('catalogPrizeListAdd')">
      <dx-button stylingMode="text" type="default" text="{{ 'Neue Preisliste' | translate }}" (onClick)="addPriceList()"></dx-button>
    </div>
  </div>

  <dxo-editing mode="cell" [allowDeleting]="!readOnlyUser('catalogPriceListDelete')" [allowUpdating]="!readOnlyUser('catalogPriceListUpdate')" [useIcons]="true">
    <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diese Preisliste wirklich vollständig löschen' | translate }}?" confirmDeleteTitle="{{ 'Preisliste löschen' | translate }}"></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="CatalogPriceListStateNewUIV2"></dxo-state-storing>

  <dxi-column [width]="60" dataField="order" caption="{{ 'Order' | translate }}" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="name" caption="{{ 'Name' | translate }}"></dxi-column>
</dx-data-grid>
