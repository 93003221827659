import { ValidationGroup } from "./ValidationGroup";

export class ValidationMethod {

  constructor(
    public name?: string,
    public id?: string,
    public createdByCustomerId?: string,
    public groups?: ValidationGroup[],
    public isGlobal?: boolean,
    public isDefault?: boolean,
    public isVisible?: boolean,
    public isShared?: boolean,
    public sharedToCustomers?: string[]
  ) {
  }
}


