import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Item as dxListItem, ItemClickEvent } from 'devextreme/ui/list';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { fieldNames } from 'app/Model/FieldNames';
import { comparePimFields, fillLastKey, isNextPimField, parsePimField } from 'app/Services/templateItemHelpers';
import { TemplateService } from 'app/Services/template.service';

@Component({
  selector: 'np-advanced-template-price',
  templateUrl: './advanced-template-price.component.html',
})
export class AdvancedTemplatePriceComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() templateItem: TemplateItem;
  @Input() customerId: string;
  @Input() fieldList: dxListItem[] = [];
  @Output() onUpdate = new EventEmitter<void>();

  isTreeBoxOpened = false;

  get isPriceField() {
    return this.pimField.field !== fieldNames.startDate && this.pimField.field !== fieldNames.endDate;
  }

  get pimField() {
    if (!this.templateItem.pimFields || this.templateItem.pimFields.length === 0) {
      this.templateItem.pimFields = [new NextPimField('-1', '1', fieldNames.startDate)];
    }
    return this.templateItem.pimFields[0];
  }

  constructor(private templateService: TemplateService, private translate: TranslateService) {}

  ngOnInit() {
    this.fieldList = [
      { key: fieldNames.startDate, text: this.translate.instant(fieldNames.startDate) },
      { key: fieldNames.endDate, text: this.translate.instant(fieldNames.endDate) },

      { key: fieldNames.priceType, text: this.translate.instant(fieldNames.priceType) },
      { key: fieldNames.priceAmount, text: this.translate.instant(fieldNames.priceAmount) },
      { key: fieldNames.priceCurrency, text: this.translate.instant(fieldNames.priceCurrency) },
      { key: fieldNames.tax, text: this.translate.instant(fieldNames.tax) },
      { key: fieldNames.priceFactor, text: this.translate.instant(fieldNames.priceFactor) },
      { key: fieldNames.lowerBound, text: this.translate.instant(fieldNames.lowerBound) },
      { key: fieldNames.territory, text: this.translate.instant(fieldNames.territory) }
    ];
    this.normalizeKeys();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFieldDisplayExpr(item: dxListItem) {
    return item?.text === item?.key ? item?.key ?? '' : `${item.text} (${item.key})`;
  }

  onFieldItemClick(event: ItemClickEvent<dxListItem>) {
    this.pimField.field = event.itemData.key;
    this.update();
  }

  onOrderChanged() {
    this.update();
  }

  update() {
    const { field, systemKey, elementKey } = this.pimField;
    const parsedKey = parsePimField(this.templateItem.key);
    this.normalizeKeys();

    if (parsedKey !== null && (comparePimFields(parsedKey, this.pimField) || !isNextPimField(parsedKey.field))) {
      this.onUpdate.emit();
      return;
    }
    this.templateService.getHeadline(field, elementKey, systemKey, this.customerId).subscribe((headline) => {
      fillLastKey(this.templateItem, headline.value);
      this.onUpdate.emit();
    });
  }

  private normalizeKeys(systemKey?: string, elementKey?: string) {
    systemKey = systemKey ?? this.pimField.systemKey;
    elementKey = elementKey ?? this.pimField.elementKey;
    if (systemKey === '-1' || !/^\d+$/.test(systemKey)) {
      this.pimField.systemKey = '1';
    } else {
      this.pimField.systemKey = systemKey.toString();
    }
    if (this.isPriceField) {
      if (elementKey === '-1' || !/^\d+$/.test(elementKey)) {
        this.pimField.elementKey = '1';
      } else {
        this.pimField.elementKey = elementKey.toString();
      }
    } else {
      this.pimField.elementKey = '-1';
    }
  }
}
