<dx-drop-down-box
  [dataSource]="dataSource"
  [(value)]="value"
  valueExpr="value"
  displayExpr="displayValue"
  searchExpr="displayValue"
  placeholder=""
  [readOnly]="readOnly"
  [showClearButton]="!readOnly && !templateView"
  [showDropDownButton]="!readOnly"
  [acceptCustomValue]="true"
  [(opened)]="opened"
  (onValueChanged)="valueChanged($event)"
  (onCustomItemCreating)="customItemCreating($event)"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="dataSource"
      searchExpr="displayValue"
      displayExpr="displayValue"
      valueExpr="value"
      [selectionMode]="selectionMode"
      (onItemClick)="itemClick($event)"
    ></dx-list>
  </div>
  <dx-validator *ngIf="!templateView"
                [validationGroup]="validationGroup"
                (onInitialized)="validationService.startAutomaticValidation($event)">
    <dxi-validation-rule type="async" [validationCallback]="validationCallback"></dxi-validation-rule>
  </dx-validator>
</dx-drop-down-box>
