export class Message {

  constructor(
    public message?: string,
    public type: string = "info"
  ) {
  }


}
