import { Component, OnInit } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { DeeplConnection } from '../../Model/Dto/DeeplConnection';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { DeeplConnectionService } from "../../Services/deeplConnection.service";
import { SystemService } from "../../Services/system.service";
import { HttpErrorResponse } from "@angular/common/http";




@Component({
  selector: "deeplCon",
  templateUrl: "DeeplConnection.component.html"
})
export class DeeplConnectionComponent implements OnInit {
  public currentDeeplConnection: DeeplConnection;

  constructor(public modelService: ModelService, public translate: TranslateService, public deepl: DeeplConnectionService, private systemService: SystemService) {
    this.updateAuthKey = this.updateAuthKey.bind(this);
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.deepl.getConnection(this.modelService.loginService.currentUser.customerId).subscribe((result: DeeplConnection) => {
      this.currentDeeplConnection = result;
      if (result.authKey == null)
        result.authKey = "";
      
    }, o => {
      console.log("Failed to get DeeplConnection for currentUser");
    });

  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER") return false;
    return true;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  public updateAuthKey() {
    this.deepl.update(this.currentDeeplConnection).subscribe((result: DeeplConnection) => {
      this.translate.get('Deepl Key erfolgreich aktualisiert').subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text));
      });
    }, (error: HttpErrorResponse) => {
      this.translate.get('Kein gültiger Deepl Key').subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text, "error"));
      });
    });
  }
}


