
  <div [ngSwitch]="layout">
    <div *ngSwitchCase="'default'">
      <shopView [(model)]="modelService.catalogService.product"></shopView>
    </div>
    <div *ngSwitchCase="'lukas'">
      <!--<shopViewLukas></shopViewLukas>-->
    </div>
  </div>
  {{errorMessage}}
