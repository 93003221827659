<dx-tree-list [dataSource]="data"
              keyExpr="id"
              [showRowLines]="true"
              [showBorders]="true"
              [columnAutoWidth]="true">
  <dxo-editing mode="row"
               [allowUpdating]="true"
               [allowDeleting]="true"
               [allowAdding]="true">
  </dxo-editing>

  <dxi-column dataField="featureSystemName" caption="FeatureSystem">
    <dxo-lookup [dataSource]="lookupData">
    </dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="attribute" caption="Attribute Name">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column type="buttons">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
  </dxi-column>
</dx-tree-list>
