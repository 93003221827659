import { OnInit, Directive } from '@angular/core';
import { ModelService } from "../../../../Services/model.service";
import { UserActionBaseComponent } from '../userAction-base.component';

@Directive()
export abstract class UserActionByListComponent extends UserActionBaseComponent implements OnInit {
  _inputList: string = "";

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  protected formatList(value) {
    var tmp: string = value;
    tmp = tmp.split(";").join("\n");
    tmp = tmp.split("\r").join("\n");
    tmp = tmp.split(" ").join("\n");
    tmp = tmp.split(",").join("\n");
    while (tmp.includes("\n\n")) {
      tmp = tmp.replace("\n\n", "\n");
    }
    if (tmp.substr(0, 1) == "\n") {
      tmp = tmp.substr(1);
    }
    if (tmp.substr(-1, 1) == "\n") {
      tmp = tmp.substr(0, tmp.length - 1);
    }
    return tmp;
  }

  protected formatListIgnoreSpace(value) {
    var tmp: string = value;
    tmp = tmp.split(";").join("\n");
    tmp = tmp.split("\r").join("\n");
    tmp = tmp.split(",").join("\n");
    while (tmp.includes("\n\n")) {
      tmp = tmp.replace("\n\n", "\n");
    }
    if (tmp.substr(0, 1) == "\n") {
      tmp = tmp.substr(1);
    }
    if (tmp.substr(-1, 1) == "\n") {
      tmp = tmp.substr(0, tmp.length - 1);
    }
    return tmp;
  }


  onValueChangedFormatList(event) {
    if (event.event == null)
      return;

    this.inputList = this.formatList(event.value);
  }

  onValueChangedFormatListIgnoreSpace(event) {
    if (event.event == null)
      return;

    this.inputList = this.formatListIgnoreSpace(event.value);
  }

  get inputList(): string {
    return this._inputList;
  }

  set inputList(value: string) {
    this._inputList = value;
  }

  startMacro() {
    throw new Error("this is a abstract method");
  }
}
