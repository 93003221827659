<dx-file-uploader #fileUploader
                  uploadUrl="api/Import/ImportSingleMime"
                  [uploadHeaders]="uploadHeaders"
                  [multiple]="true"
                  accept="*"
                  (onUploaded)="onUploaded($event)"
                  (onBeforeSend)="onBeforeSendUpload($event)"
                  (onUploadStarted)="onUploadStarted($event)"
                  [(value)]="value"
                  uploadMode="instantly">
</dx-file-uploader>
<b>{{ msg }}</b>

