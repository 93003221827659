<dx-tab-panel [dataSource]="pages" [selectedIndex]="0">

  <div *dxTemplate="let company of 'liveTemplate'">
    <div class="dx-fieldset" style="max-width: 600px;">



      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Shop Adresse' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelLive.shopUrl?.length>0 ? 'placeLabelSmall' : '' }}">http://web26.osgmbh.net/api/api.php </label>
          <dx-text-box [(value)]="modelLive.shopUrl" [showClearButton]="true" (onValueChanged)="updateLive($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelLive.shopUrl}}
        </div>
      </div>




      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'API-Key' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelLive.apiKey?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'API-Key' | translate }}</label>
          <dx-text-box [(value)]="modelLive.apiKey" [showClearButton]="true" (onValueChanged)="updateLive($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelLive.apiKey}}
        </div>
      </div>



      <dx-toolbar>
        <dxi-item *ngIf="!isTestedLive" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnectionLive }"></dxi-item>
        <dxi-item *ngIf="isTestedLive" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testShopConnectionLive }"></dxi-item>

      </dx-toolbar>


      <div style="color: red;">
        {{connection2ShopMessage}}
      </div>


    </div>
  </div>


  <div *dxTemplate="let company of 'testTemplate'">
    <div class="dx-fieldset" style="max-width: 600px;">
      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Shop Adresse' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelTest.shopUrl?.length>0 ? 'placeLabelSmall' : '' }}">http://web26.osgmbh.net/api/api.php </label>
          <dx-text-box [(value)]="modelTest.shopUrl" [showClearButton]="true" (onValueChanged)="updateTest($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelTest.shopUrl}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'API-Key' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelTest.apiKey?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'API-Key' | translate }}</label>
          <dx-text-box [(value)]="modelTest.apiKey" [showClearButton]="true" (onValueChanged)="updateTest($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelTest.apiKey}}
        </div>
      </div>



      <dx-toolbar>
        <dxi-item *ngIf="!isTestedTest" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnectionTest }"></dxi-item>
        <dxi-item *ngIf="isTestedTest" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testShopConnectionTest }"></dxi-item>

      </dx-toolbar>


      <div style="color: red;">
        {{connection2ShopMessageTest}}
      </div>


    </div>
  </div>

  </dx-tab-panel>




