<div class="d-flex flex-column w-100">
  <div class="header d-flex flex-row px-3 pt-1 w-100">
    <div class="p-2 flex-fill overflow-hidden">
      <h5>{{ 'MassenDatenAenderung' | translate }}</h5>
      <span innerHTML="{{ 'MassenDatenAenderung Beschreibung' | translate }}"></span>
    </div>
  </div>
  <dx-toolbar>
    <dxi-item
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{
        type: 'default',
        icon: 'revert',
        text: 'Zurück zur Katalogansicht' | translate,
        onClick: backClicked
      }"
    ></dxi-item>
    <dxi-item
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{ type: 'default', icon: 'refresh', text: 'Daten Ändern' | translate, onClick: startJob }"
    ></dxi-item>
    <dxi-item
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{
        type: 'default',
        icon: 'startswith',
        text: 'Description Wizard' | translate,
        onClick: startDescriptionWizard,
        disabled: canView('onboarding') && isOnboarding
      }"
    ></dxi-item>
    <dxi-item *ngIf="canView('onboarding') && isOnboarding" location="before" locateInMenu="auto">
      <np-license-upgrade-button></np-license-upgrade-button>
    </dxi-item>
    <dxi-item
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{
        type: 'default',
        icon: 'trash',
        text: 'Ausgewählte Artikel löschen' | translate,
        onClick: showDeleteJobPopup
      }"
    ></dxi-item>
    <dxi-item
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{
        type: 'default',
        icon: 'decreaselinespacing',
        text: 'Varianten bilden' | translate,
        onClick: createVariants
      }"
    ></dxi-item>
  </dx-toolbar>
  <dx-popup
    [width]="600"
    [height]="350"
    [showTitle]="true"
    [title]="('Ausgewählte Artikel löschen' | translate) + '?'"
    [dragEnabled]="false"
    [showCloseButton]="false"
    [visible]="deleteJobPopupVisible"
    [closeOnOutsideClick]="false"
    container=".dx-viewport"
  >
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="before"
      [options]="{
        text: 'löschen' | translate,
        onClick: startDeleteJob
      }"
    ></dxi-toolbar-item>
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="{
        text: 'schließen' | translate,
        onClick: hideDeleteJobPopup
      }"
    ></dxi-toolbar-item>
    <dxo-position at="center" my="center" collision="fit"></dxo-position>
    <div *dxTemplate="let data of 'content'">
      <div *ngIf="true">
        <div class="d-flex gap-1">
          <dx-switch [(value)]="deleteCategoriesEnabled"></dx-switch>
          <label class="pointer" (click)="deleteCategoriesEnabled = !deleteCategoriesEnabled">
            {{ 'Ausgewählte leere Kategorien auch löschen' | translate }}
          </label>
        </div>
      </div>
    </div>
  </dx-popup>
  <div class="p-2 flex-fill">
    <templateProduct
      class="h-100"
      [model]="emptyProduct"
      [(selectedElements)]="selectedElements"
      [exportMode]="false"
      [massDataChangeMode]="true"
      (onValueChanged)="addDefaultElement($event)"
      [showDragHandle]="false"
      (elementDeleted)="elementDelete($event)"
      (elementSelected)="elementSelectFunction($event)"
      (excelElementDropped)="excelElementDrop($event)"
      [(mimeUpdateMode)]="mimeUpdateMode"
      (onMimeUpdateModeChanged)="mimeUpdateModeChanged($event)"
      [(featureUpdateMode)]="featureUpdateMode"
      (onFeatureUpdateModeChanged)="featureUpdateModeChanged($event)"
      [(priceUpdateMode)]="priceUpdateMode"
      (onPriceUpdateModeChanged)="priceUpdateModeChanged($event)"
      [(udxUpdateMode)]="udxUpdateMode"
      (onUdxUpdateModeChanged)="udxUpdateModeChanged($event)"
    ></templateProduct>
  </div>
</div>
