<div class="dx-fieldset" *ngIf="model">


  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(10)" (mouseleave)="ttt(10)">{{ 'Shop URL' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt10">
      <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">SHOP_URL</label>
      <dx-text-box [(value)]="model.baseUri" [readOnly]="readOnlyUser('shopware6Url')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
      </dx-text-box>

    </div>
    <dx-tooltip target="#tt10" class="toolTip toolTipField" [(visible)]="v[10]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Die Internetadresse des Shopware Shops' | translate }}.
      </div>
    </dx-tooltip>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(11)" (mouseleave)="ttt(11)">{{ 'Client Id' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.clientId?.length>0 ? 'placeLabelSmall' : '' }}">CLIENT_ID</label>
      <dx-text-box [(value)]="model.clientId" [readOnly]="readOnlyUser('shopware6ClientId')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
      </dx-text-box>

    </div>
    <dx-tooltip target="#tt11" class="toolTip toolTipField" [(visible)]="v[11]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Die Client Id mit der die nextPIM sich beim Shop anmeldet.' | translate }}
      </div>
    </dx-tooltip>
  </div>


  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(12)" (mouseleave)="ttt(12)">{{ 'Client Secret' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt12">
      <label class="placeLabel {{ model.clientSecret?.length>0 ? 'placeLabelSmall' : '' }}">CLIENT_SECRET</label>
      <dx-text-box [(value)]="model.clientSecret" [readOnly]="readOnlyUser('shopware6ClientSecret')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
      </dx-text-box>

    </div>
    <dx-tooltip target="#tt11" class="toolTip toolTipField" [(visible)]="v[11]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Das Passwort mit dem sich nextPIM beim Shop anmeldet' | translate }}
      </div>
    </dx-tooltip>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(14)" (mouseleave)="ttt(14)">{{ 'Produkt Kategorie' | translate }}</div>
    <div class="dx-field-value" id="tt12">
      <dx-drop-down-box [(value)]="model.rootCatCategoryId"
                        valueExpr="id"
                        displayExpr="text"
                        placeholder="{{ 'Kategorie auswählen' | translate }}"
                        [showClearButton]="true"
                        [dataSource]="shopCategories"
                        (onValueChanged)="syncTreeViewSelection($event)"
                        [readOnly]="readOnlyUser('shopware6Categorie')">

        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [items]="shopCategories"
                        dataStructure="plain"
                        parentIdExpr="parent"
                        selectionMode="single"
                        [selectByClick]="true"
                        keyExpr="id"
                        displayExpr="text"
                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
            <dxo-scrolling [useNative]="true">
            </dxo-scrolling>
          </dx-tree-view>
        </div>
      </dx-drop-down-box>



    </div>
    <dx-tooltip target="#tt11" class="toolTip toolTipField" [(visible)]="v[11]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Die Kategorie in der sich die Produkte befinden' | translate }}
      </div>
    </dx-tooltip>
  </div>
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(14)" (mouseleave)="ttt(14)">{{ 'Kategorie Layout' | translate }}</div>
    <div class="dx-field-value" id="tt123">
      <dx-select-box [dataSource]="shopwareCmsPage"
                     displayExpr="name"
                     valueExpr="id"
                     (onValueChanged)="catalogChangedinclShop($event)"
                     [(value)]="model.categoryLayoutId"
                     [readOnly]="readOnlyUser('shopware6CategorieLayout')"
                     showClearButton="true">
        <dx-validator validationGroup="shopware6LayoutValidationGroup">
          <dxi-validation-rule type="required"
                               message="{{ 'NoCategoryLayoutInfo' | translate }}">
          </dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
    <dx-tooltip target="#tt123" class="toolTip toolTipField" [(visible)]="v[123]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Die Id des Shopware Kategorie Layouts' | translate }}
      </div>
    </dx-tooltip>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(14)" (mouseleave)="ttt(14)">{{ 'Produkt Layout' | translate }}</div>
    <div class="dx-field-value" id="tt123">
      <dx-select-box [dataSource]="shopwareCmsPage"
                     displayExpr="name"
                     valueExpr="id"
                     (onValueChanged)="catalogChangedinclShop($event)"
                     [(value)]="model.productLayoutId"
                     [readOnly]="readOnlyUser('shopware6CategorieLayout')"
                     showClearButton="true">
        <dx-validator validationGroup="shopware6LayoutValidationGroup">
          <dxi-validation-rule type="required"
                               message="{{ 'NoProductLayoutInfo' | translate }}">
          </dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
    <dx-tooltip target="#tt123" class="toolTip toolTipField" [(visible)]="v[123]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Die Id des Shopware Produkt Layouts' | translate }}
      </div>
    </dx-tooltip>
  </div>



  <div class="dx-field">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(14)" (mouseleave)="ttt(14)">{{ 'Standard Verkaufskanal für Produkte' | translate }}</div>
    <div class="dx-field-value" id="tt124">
      <dx-tag-box [items]="salesChannel"
                  displayExpr="name"
                  (onValueChanged)="catalogChangedinclShop($event)"
                  valueExpr="id"
                  [(value)]="model.productChannelIds"
                  [readOnly]="readOnlyUser('shopware6ProductChannel')">
      </dx-tag-box>
    </div>
    <dx-tooltip target="#tt124" class="toolTip toolTipField" [(visible)]="v[124]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Der Verkaufskanal der den Produkten zugewiesen werden soll, wenn diese nicht in den Kategorien gefüllt wurde' | translate }}
      </div>
    </dx-tooltip>
  </div>


  <div class="dx-field" *ngIf="modelService.loginService.hasAddonSHOPWARE6_VARIANTS">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(125)" (mouseleave)="ttt(125)">{{ 'Variantendarstellung in Kategorien' | translate }}</div>
    <div class="dx-field-value" id="tt125">

      <dx-select-box [dataSource]="variantRepresentations"
                     displayExpr="value"
                     valueExpr="key"
                     [(value)]="model.variantRepresentation"
                     [readOnly]="readOnlyUser('shopware6Variants')"
                     (onValueChanged)="catalogChangedinclShop($event)">
      </dx-select-box>

    </div>
    <dx-tooltip target="#tt125" class="toolTip toolTipField" [(visible)]="v[125]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Sollen Varianten im Shop in Listen dargestellt werden, oder nur der Master Artikel' | translate }}
      </div>
    </dx-tooltip>
  </div>

  <div class="dx-field" *ngIf="modelService.loginService.hasAddonSHOPWARE6_MULTILANGUAGE">
    <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(1254)" (mouseleave)="ttt(1254)">{{ 'Sprachen für die Übertragung' | translate }}</div>
    <div class="dx-field-value" id="tt1254">

      <dx-tag-box [(value)]="model.languages"
                  [dataSource]="languages"
                  displayExpr="name"
                  valueExpr="id"
                  [acceptCustomValue]="false"
                  [showSelectionControls]="true"
                  placeholder=""
                  (onValueChanged)="catalogChanged($event)"
                  [readOnly]="readOnlyUser('shopware6Language')">
      </dx-tag-box>

    </div>
    <dx-tooltip target="#tt1254" class="toolTip toolTipField" [(visible)]="v[1254]">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Welche Sprachen sollen zum Shop übertragen werden' | translate }}
      </div>
    </dx-tooltip>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Zusätzliche Merkmalsgruppe' | translate }}</div>
    <div class="dx-field-value">
      <dx-tag-box [dataSource]="featureSystemNames"
                  [(value)]="model.additionalFeatureSystemNames"
                  [readOnly]="readOnlyUser('shopware6AdditionalFeatureSystemNames')"
                  (onValueChanged)="catalogChanged($event)">
      </dx-tag-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Kategorie-Layouts überschreiben' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.overwriteCategoryLayouts" [readOnly]="readOnlyUser('shopware6OverwriteCategoryLayouts')" (onValueChanged)="catalogChangedinclShop($event)"></dx-switch>
    </div>
  </div>
  <div *ngIf="modelService.loginService.hasAddonLEGALITY" class="dx-field">
    <div class="dx-field-label">{{ 'Gefahrstoffklassen an Beschreibung anhängen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.includeHazmatClassesInDescription" [readOnly]="readOnlyUser('shopware6WarningsAppend')" (onValueChanged)="catalogChangedinclShop($event)"></dx-switch>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Alle Produkte ohne Kategorie in Shopware deaktivieren' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.deactivateProductsWithoutCategory" [readOnly]="readOnlyUser('shopware6DeactivateProductsWithoutCategory')" (onValueChanged)="catalogChangedinclShop($event)"></dx-switch>
    </div>
  </div>
  <div *ngIf="modelService.loginService.hasAddonSHOPWARE6_ASYNCINDEXING" class="dx-field">
    <div class="dx-field-label">{{ 'Queue-Indexing nutzen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.useQueueIndexing" (onValueChanged)="catalogChangedinclShop($event)" hint="{{ 'Queue-Indexing Warning' | translate }}"></dx-switch>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label" (mouseenter)="ttt('lastSend')" (mouseleave)="ttt('lastSend')">{{ 'Letzte Übertragung' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSend">
      <label class="placeLabel {{ lastSend.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
      <dx-text-box [(value)]="lastSend" [disabled]="true" [showClearButton]="false">
      </dx-text-box>

    </div>
    <dx-tooltip target="#ttlastSend" class="toolTip toolTipField" [(visible)]="v['productRange']">
      <div *dxTemplate="let data = data of 'content'">
        {{ 'Datum der letzten Übertragung zu oder vom Shop' | translate }}.
      </div>
    </dx-tooltip>
  </div>






  <dx-toolbar>
    <dxi-item *ngIf="!modelService.catalogService.connection2Shop" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnection }"></dxi-item>
    <dxi-item *ngIf="modelService.catalogService.connection2Shop" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testShopConnection }"></dxi-item>
    <dxi-item *ngIf="modelService.catalogService.connection2Shop && !readOnlyUser('shopware6SendCatalog')" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Katalog senden' | translate , onClick: sendData2Shop }"></dxi-item>
    <dxi-item *ngIf="modelService.catalogService.connection2Shop && !readOnlyUser('shopware6SendCatalog')" location="after" [template]="'dateBoxTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'dateBoxTemplate'">
      <dx-date-box [(value)]="scheduledFor" width="auto" type="datetime" label="{{ 'Übertragung Startzeit' | translate}}"></dx-date-box>
    </div>
  </dx-toolbar>

  <div style="color: red;">
    {{modelService.catalogService.connection2ftpMessage}}
  </div>


</div>

