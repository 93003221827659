<fullscreen-page>
  <navigation-column>
    <div class="h-100 d-flex flex-column">
      <div class="px-2 flex-fill overflow-hidden">
        <dx-data-grid
          class="h-100"
          (onToolbarPreparing)="onGridToolbarPreparing($event)"
          [dataSource]="customers"
          keyExpr="id"
          [remoteOperations]="false"
          [allowColumnReordering]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          noDataText=""
          [showBorders]="true"
          (onSelectionChanged)="onCustomerClick($event)"
          (onContentReady)="onContentReadyHandler($event)"
        >
          <dxo-selection mode="single"></dxo-selection>
          <div *dxTemplate="let toolbaritem of 'newAccountButtonTemplate'">
            <dx-button
              stylingMode="text"
              type="default"
              *ngIf="modelService.loginService.isAdmin"
              text="{{ 'Neuer Account' | translate }}"
              (onClick)="showAddCustomer()"
              hint="{{ 'Neuen Acount anlegen' | translate }}"
            ></dx-button>
          </div>
          <dxo-search-panel
            [visible]="true"
            [highlightCaseSensitive]="true"
            placeholder="{{ 'Account suchen' | translate }} "
            [(text)]="searchText"
          ></dxo-search-panel>
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
          <dxo-editing mode="row" [useIcons]="true"></dxo-editing>

          <dxo-state-storing
            [enabled]="true"
            type="localStorage"
            storageKey="MyAccountAccountsStateNewUIV2"
            savingTimeout="0"
          ></dxo-state-storing>

          <dxi-column
            dataField="name"
            caption="{{ 'Account' | translate }}"
            cellTemplate="nameCellTemplate"
          ></dxi-column>
          <div *dxTemplate="let data of 'nameCellTemplate'">
            {{ hideName(data.value) }}
          </div>

          <dxi-column dataField="city" caption="{{ 'Stadt' | translate }}"></dxi-column>
          <dxi-column dataField="isTestAccount" [width]="50" caption="{{ 'Test' | translate }}"></dxi-column>
          <dxi-column
            dataField="accountValidDate"
            [width]="95"
            dataType="date"
            [format]="{ type: 'dd.MM.yyyy' }"
            caption="{{ 'Ablauf' | translate }}"
          ></dxi-column>
          <dxi-column type="buttons" caption="" [width]="50">
            <dxi-button *ngIf="modelService.loginService.isAdmin" icon="trash" [onClick]="deleteCustomer"></dxi-button>
            <!---->
          </dxi-column>

          <dxi-column dataField="id" [visible]="false"></dxi-column>
        </dx-data-grid>
      </div>
    </div>
  </navigation-column>
  <content-column look="light" class="w-100" style="max-width: 67%">
    <div slot="content" class="h-100 w-100 px-3">
      <dx-scroll-view *ngIf="customer" showScrollbar="always" width="100%">
        <div class="dx-fieldset">
          <!--CustomerName-->
          <div class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(0)" (mouseleave)="ttt(0)">
              {{ 'Name' | translate }}
            </div>
            <div
              class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
              id="tt0"
            >
              <label class="placeLabel {{ customer.name?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT_NAME</label>
              <dx-text-box
                id="accountName"
                [(value)]="customer.name"
                [showClearButton]="true"
                (onValueChanged)="updateCustomer($event)"
                [readOnly]="!modelService.loginService.isManager"
              ></dx-text-box>
            </div>
            <dx-tooltip target="#tt0" class="toolTip toolTipField" [(visible)]="v[0]">
              <div *dxTemplate="let data = data; of: 'content'">
                <p>
                  {{ 'Der Accountname wird in der Accountübersicht angezeigt' | translate }}
                </p>
                <div *ngIf="showProToolTips" class="nexMartTooltip"></div>
              </div>
            </dx-tooltip>
          </div>

          <!--CustomerStreet-->
          <div class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">
              {{ 'Straße' | translate }}
            </div>
            <div
              class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
              id="tt1"
            >
              <label class="placeLabel {{ customer.street?.length > 0 ? 'placeLabelSmall' : '' }}">
                ACCOUNT_STREET
              </label>
              <dx-text-box
                id="accountStreet"
                [(value)]="customer.street"
                [showClearButton]="true"
                (onValueChanged)="updateCustomer($event)"
                [readOnly]="!modelService.loginService.isManager"
              ></dx-text-box>
            </div>
          </div>

          <!--PLZ-->
          <div class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(2)" (mouseleave)="ttt(2)">
              {{ 'Postleitzahl' | translate }}
            </div>
            <div
              class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
              id="tt2"
            >
              <label class="placeLabel {{ customer.zip?.length > 0 ? 'placeLabelSmall' : '' }}">ZIP</label>
              <dx-text-box
                [(value)]="customer.zip"
                [showClearButton]="true"
                (onValueChanged)="updateCustomer($event)"
                [readOnly]="!modelService.loginService.isManager"
              ></dx-text-box>
            </div>
          </div>

          <!--City-->
          <div class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(3)" (mouseleave)="ttt(3)">
              {{ 'Stadt' | translate }}
            </div>
            <div
              class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
              id="tt3"
            >
              <label class="placeLabel {{ customer.city?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT_CITY</label>
              <dx-text-box
                [(value)]="customer.city"
                [showClearButton]="true"
                (onValueChanged)="updateCustomer($event)"
                [readOnly]="!modelService.loginService.isManager"
              ></dx-text-box>
            </div>
          </div>

          <!--Language-->
          <div class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(4)" (mouseleave)="ttt(4)">
              {{ 'Hauptsprache' | translate }}
            </div>
            <div class="dx-field-value" id="tt4">
              <dx-select-box
                [dataSource]="possibleLanguages"
                displayExpr="language"
                valueExpr="code"
                [(value)]="customer.defaultLanguage"
                fieldTemplate="field"
                (onValueChanged)="updateCustomer($event)"
                style="background-color: white"
                [readOnly]="!modelService.loginService.isManager"
              >
                <div *dxTemplate="let data of 'item'">
                  <div class="d-flex flex-row">
                    <div class="pr-2 align-self-center">
                      <img src="images/flags/{{ data.flag }}" class="flag" />
                    </div>
                    <div class="flex-fill pl-2">
                      {{ data.language }}
                    </div>
                  </div>
                </div>
                <div *dxTemplate="let data of 'field'">
                  <div class="d-flex flex-row">
                    <div class="pr-2 align-self-center">
                      <img *ngIf="data != null" src="images/flags/{{ data.flag }}" class="flag" />
                    </div>
                    <div class="flex-fill pl-2">
                      <dx-text-box value="{{ data != null ? data.language : '' }}" [readOnly]="true"></dx-text-box>
                    </div>
                  </div>
                </div>
              </dx-select-box>
            </div>
          </div>

          <!--NextPimVersion-->

          <div class="dx-field" *ngIf="modelService.loginService.isManager">
            <div class="dx-field-label jobListTD">{{ 'NextPim Version' | translate }}</div>
            <div class="dx-field-value">
              <dx-select-box
                [(value)]="customer.pimLicense"
                [items]="versions"
                [readOnly]="!modelService.loginService.isAdmin"
                placeholder=""
                (onValueChanged)="changeVersion($event)"
              ></dx-select-box>
            </div>
          </div>

          <div
            class="dx-field"
            *ngIf="modelService.loginService.isAdmin && modelService.loginService.hasAddonDEVELOPMENT"
          >
            <div class="dx-field-label jobListTD">{{ 'Account Typ' | translate }}</div>
            <div class="dx-field-value">
              <dx-select-box
                [(value)]="customer.accountType"
                [items]="accountTypes"
                placeholder=""
                (onValueChanged)="changeVersion($event)"
              ></dx-select-box>
            </div>
          </div>

          <div class="dx-field" *ngIf="modelService.loginService.isAdmin">
            <div class="dx-field-label jobListTD">{{ 'Account gültig bis' | translate }}</div>
            <div class="dx-field-value">
              <dx-date-box
                id="DateBox"
                [(value)]="customer.accountValidDate"
                [showClearButton]="true"
                displayFormat="dd.MM.yyyy"
                type="date"
                valueChangeEvent="focusout"
                (onValueChanged)="updateCustomer($event)"
              ></dx-date-box>
            </div>
          </div>
          <div class="dx-field" *ngIf="modelService.loginService.isAdmin">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">
              {{ 'Test-Account' | translate }}
            </div>
            <div class="dx-field-value">
              <div class="d-flex flex-row pt-2">
                <div class="dx-field-label switch-left">{{ 'Nein' | translate }}</div>
                <dx-switch
                  (onValueChanged)="updateCustomer($event)"
                  [(value)]="customer.isTestAccount"
                  switchedOffText="{{ 'Nein' | translate }}"
                  switchedOnText="{{ 'Ja' | translate }}"
                ></dx-switch>
                <div class="dx-field-label switch-right">{{ 'Ja' | translate }}</div>
              </div>
            </div>
          </div>
          <div class="dx-field" *ngIf="modelService.loginService.isAdmin">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(1)" (mouseleave)="ttt(1)">
              {{ 'Gastro-Account' | translate }}
            </div>
            <div class="dx-field-value">
              <div class="d-flex flex-row pt-2">
                <div class="dx-field-label switch-left">{{ 'Nein' | translate }}</div>
                <dx-switch
                  (onValueChanged)="updateCustomer($event)"
                  [(value)]="customer.isGastro"
                  switchedOffText="{{ 'Nein' | translate }}"
                  switchedOnText="{{ 'Ja' | translate }}"
                ></dx-switch>
                <div class="dx-field-label switch-right">{{ 'Ja' | translate }}</div>
              </div>
            </div>
          </div>
          <!--Onboarding Partner-->
          <div *ngIf="modelService.loginService.isAdmin" class="dx-field">
            <div class="dx-field-label jobListTD" (mouseenter)="ttt(3)" (mouseleave)="ttt(3)">
              {{ 'Onboarding Partner' | translate }}
            </div>
            <div class="dx-field-value" id="tt3">
              <dx-text-box [(value)]="customer.linkedCustomerId" [readOnly]="true"></dx-text-box>
            </div>
          </div>

          <!--CustomerId-->
          <div
            class="dx-field"
            *ngIf="modelService.loginService.isAdmin && modelService.loginService.hasAddonDEVELOPMENT"
          >
            <div class="dx-field-label jobListTD">{{ 'Customer Id' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [value]="customer.id" [readOnly]="true"></dx-text-box>
              <dx-text-box [value]="customer.id.split('-').join('')" [readOnly]="true"></dx-text-box>
            </div>
          </div>
        </div>
        <dx-tab-panel
          [dataSource]="pages"
          [showNavButtons]="true"
          [selectedIndex]="0"
          [itemTitleTemplate]="myItemTitleTemplate"
        >
          <div *dxTemplate="let data of 'itemTitleTemplate'">
            <span [class]="data.disabled ? 'my-another-class' : ''">{{ data.title }}</span>
            <np-license-upgrade-button
              *ngIf="data.disabled"
              style="margin-left: 5px; float: right"
            ></np-license-upgrade-button>
          </div>

          <div *dxTemplate="let company of 'userTemplate'">
            <userManagement></userManagement>
          </div>
          <!--SETUP-->
          <div *dxTemplate="let company of 'setupTemplate'">
            <div class="dx-fieldset">
              <div class="dx-field">
                <div class="dx-field-label">{{ 'BMEcat Hinweise anzeigen' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch
                    (onValueChanged)="updateUser($event)"
                    [(value)]="modelService.loginService.showPlaceHolders"
                    switchedOffText="NO"
                    switchedOnText="BMEcat"
                  ></dx-switch>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Informationstexte aktivieren' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch
                    (onValueChanged)="updateUser($event)"
                    [(value)]="modelService.loginService.showInfoText"
                    switchedOffText=""
                    switchedOnText="HELP"
                  ></dx-switch>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Feldhinweise aktivieren' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch
                    (onValueChanged)="updateUser($event)"
                    [(value)]="modelService.loginService.showToolTips"
                    switchedOffText="PRO"
                    switchedOnText="HELP"
                  ></dx-switch>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Technische Hinweise aktivieren' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch
                    (onValueChanged)="updateUser($event)"
                    [(value)]="modelService.loginService.showProToolTips"
                    switchedOffText=""
                    switchedOnText="PRO"
                  ></dx-switch>
                </div>
              </div>
              <!--Language-->
              <div class="dx-field">
                <div class="dx-field-label">{{ 'Anzeigesprache' | translate }}</div>
                <div class="dx-field-value">
                  <dx-select-box
                    [dataSource]="possibleFrontEndLanguages"
                    displayExpr="language"
                    valueExpr="code"
                    [(value)]="modelService.loginService.currentUser.frontEndLanguage"
                    fieldTemplate="field"
                    (onValueChanged)="updateUser($event)"
                    style="background-color: white"
                  >
                    <div *dxTemplate="let data of 'item'">
                      <div class="d-flex flex-row">
                        <div class="pr-2 align-self-center">
                          <img src="images/flags/{{ data.flag }}" class="flag" />
                        </div>
                        <div class="flex-fill pl-2">
                          {{ data.language }}
                        </div>
                      </div>
                    </div>
                    <div *dxTemplate="let data of 'field'">
                      <div class="d-flex flex-row">
                        <div class="pr-2 align-self-center">
                          <img *ngIf="data != null" src="images/flags/{{ data.flag }}" class="flag" />
                        </div>
                        <div class="flex-fill pl-2">
                          <dx-text-box value="{{ data != null ? data.language : '' }}" [readOnly]="true"></dx-text-box>
                        </div>
                      </div>
                    </div>
                  </dx-select-box>
                </div>
              </div>
            </div>
          </div>

          <!--ADDONS-->
          <div *dxTemplate="let company of 'addonsTemplate'">
            <div *ngIf="customer">
              <div class="dx-fieldset">
                <dx-toolbar>
                  <dxi-item
                    *ngIf="modelService.loginService.isAdmin"
                    location="before"
                    widget="dxButton"
                    [options]="{ type: 'default', text: 'Demo Defaultwerte' | translate, onClick: setDemoValues }"
                  ></dxi-item>
                  <dxi-item
                    *ngIf="modelService.loginService.isAdmin"
                    location="before"
                    widget="dxButton"
                    [options]="{ type: 'default', text: 'Alles einschalten' | translate, onClick: setAllAddons }"
                  ></dxi-item>
                  <dxi-item
                    *ngIf="modelService.loginService.isAdmin"
                    location="before"
                    widget="dxButton"
                    [options]="{ type: 'default', text: 'Prüfen' | translate, onClick: checkAddons }"
                  ></dxi-item>
                </dx-toolbar>

                <dx-tag-box
                  [value]="normalAddons"
                  [dataSource]="possibleNormalAddons"
                  [readOnly]="!modelService.loginService.isAdmin"
                  [acceptCustomValue]="false"
                  [showSelectionControls]="true"
                  [searchEnabled]="true"
                  label="{{ 'Addons' | translate }}"
                  stylingMode="outlined"
                  (onValueChanged)="onAddonChanged($event)"
                  (onClose)="refresh()"
                ></dx-tag-box>
                <br />
                <dx-tag-box
                  [value]="exportAddons"
                  [dataSource]="possibleExportAddons"
                  [readOnly]="!modelService.loginService.isAdmin"
                  [acceptCustomValue]="false"
                  [showSelectionControls]="true"
                  [searchEnabled]="true"
                  label="{{ 'Ausgabekanäle' | translate }}"
                  stylingMode="outlined"
                  (onValueChanged)="onAddonChanged($event)"
                  (onClose)="refresh()"
                ></dx-tag-box>
                <br />
                <dx-tag-box
                  [value]="classificationAddons"
                  [dataSource]="possibleClassificationAddons"
                  [readOnly]="!modelService.loginService.isAdmin"
                  [acceptCustomValue]="false"
                  [showSelectionControls]="true"
                  [searchEnabled]="true"
                  label="{{ 'Klassifikationen' | translate }}"
                  stylingMode="outlined"
                  (onValueChanged)="onAddonChanged($event)"
                  (onClose)="refresh()"
                ></dx-tag-box>
              </div>
            </div>
          </div>

          <!-- DataSharingCockpit -->
          <div *dxTemplate="let dsc of 'dscTemplate'">
            <div class="dx-fieldset">
              <dscInfos></dscInfos>
            </div>
          </div>

          <div *dxTemplate="let oxomiAccessdata of 'oxomiAccessdataTemplate'">
            <div class="dx-fieldset">
              <np-oxomi-accessdata></np-oxomi-accessdata>
            </div>
          </div>

          <!-- OSG -->
          <div *dxTemplate="let company of 'osgTemplate'">
            <div class="dx-fieldset">
              <osgCon></osgCon>
            </div>
          </div>

          <!-- Mavis -->
          <div *dxTemplate="let company of 'mavisTemplate'">
            <div class="dx-fieldset">
              <mavisCon></mavisCon>
            </div>
          </div>
          <!--easyCatalog-->
          <div *dxTemplate="let company of 'easyCatalogTemplate'">
            <div class="dx-fieldset">
              <easyCatalog [(customerId)]="customer.id"></easyCatalog>
            </div>
          </div>
          <!--NORDWEST-->
          <div *dxTemplate="let company of 'nordwestTemplate'">
            <div class="dx-fieldset">
              <sendToNWConfig></sendToNWConfig>
            </div>
          </div>
          <!-- Wawi-->
          <div *dxTemplate="let company of 'wawiTemplate'">
            <div class="dx-fieldset">
              <wawiSettings></wawiSettings>
            </div>
          </div>

          <!-- Deepl-->
          <div *dxTemplate="let company of 'deeplTemplate'">
            <div class="dx-fieldset">
              <deeplCon></deeplCon>
            </div>
          </div>

          <!-- Recurring Jobs-->
          <div *dxTemplate="let company of 'recurringJobsTemplate'">
            <div class="dx-fieldset">
              <recurringJobs></recurringJobs>
            </div>
          </div>

          <!-- FTP Server-->
          <div *dxTemplate="let company of 'ftpServerTemplate'">
            <div class="dx-fieldset">
              <ftpServer></ftpServer>
            </div>
          </div>

          <!-- EDE Sortimentsabo-->
          <div *dxTemplate="let company of 'edeSortimentsAboTemplate'">
            <div class="dx-fieldset">
              <edeSortiment></edeSortiment>
            </div>
          </div>

          <!-- Rechnungen-->
          <div *dxTemplate="let company of 'invoiceTemplate'">
            <div class="dx-fieldset">
              <invoice></invoice>
            </div>
          </div>

          <!-- Datenblätter-->
          <div *dxTemplate="let dsc of 'datasheetTemplate'">
            <div class="dx-fieldset">
              <datasheetManagement [customerId]="customer.id"></datasheetManagement>
            </div>
          </div>
          <!--Statistik-->
          <div *dxTemplate="let company of 'statisticTemplate'">
            <div class="dx-fieldset">
              <dx-chart id="chart" [dataSource]="customerStatistics">
                <dxo-title text="{{ 'Statistik' | translate }}">
                  <dxo-subtitle text="{{ customer.name }}"></dxo-subtitle>
                </dxo-title>
                <dxi-series valueField="skuCount" name="{{ 'Eindeutige Produkte' | translate }}"></dxi-series>
                <dxi-series
                  *ngIf="modelService.loginService.isAdmin"
                  valueField="eanCount"
                  name="{{ 'Eindeutige EAN-Nummern' | translate }}"
                ></dxi-series>
                <dxi-series valueField="fileCount" name="{{ 'Anzahl Medien' | translate }}"></dxi-series>
                <dxi-series valueField="sumFileSize" name="{{ 'Mediengröße in MB' | translate }}"></dxi-series>
                <dxo-common-series-settings argumentField="date" type="spline" hoverMode="includePoints">
                  <dxo-point hoverMode="allArgumentPoints"></dxo-point>
                </dxo-common-series-settings>
                <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
                <dxo-argument-axis [workdaysOnly]="true" argumentType="datetime">
                  <dxo-label wordWrap="none" format="dd.MM.yy" [overlappingBehavior]="hide"></dxo-label>
                </dxo-argument-axis>
                <dxo-legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                  itemTextPosition="bottom"
                ></dxo-legend>
              </dx-chart>
            </div>
          </div>
        </dx-tab-panel>
      </dx-scroll-view>
    </div>
  </content-column>
</fullscreen-page>

<dx-popup
  [width]="400"
  [height]="200"
  [showTitle]="true"
  title="{{ 'Account anlegen' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="addAccountVisible"
  contentTemplate="popupContent"
>
  <dxi-toolbar-item
    toolbar="bottom"
    location="after"
    widget="dxButton"
    [options]="{ text: 'Account anlegen' | translate, onClick: addCustomer }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="bottom"
    location="after"
    widget="dxButton"
    [options]="{ text: 'Abbrechen' | translate, onClick: hideAddCustomer }"
  ></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'"></div>
</dx-popup>

<dx-popup
  [width]="500"
  [height]="200"
  [showTitle]="true"
  title="{{ 'Account löschen' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="deleteAccountVisible"
  contentTemplate="popupContent"
>
  <dxi-toolbar-item
    toolbar="bottom"
    location="after"
    widget="dxButton"
    [options]="{ text: 'Account löschen' | translate, onClick: confirmDelete }"
  ></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-field-label" style="width: '100%'">
      {{ 'Wiederhole hier den Accountnamen um den Account zu löschen' | translate }}
    </div>
    <dx-text-box [(value)]="deleteConfirmName" [showClearButton]="true"></dx-text-box>
  </div>
</dx-popup>

<!--<dx-popup [width]="304"
          [height]="200"
          [showTitle]="true"
          title="{{ 'Benutzer anlegen' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [(visible)]="addUserVisible"
          contentTemplate="popupContent">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Benutzer anlegen' | translate, onClick: addUser }"></dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: hideAddUser }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <dx-text-box [(value)]="newUserMail" [showClearButton]="true" valueChangeEvent="focusout"></dx-text-box>
  </div>
</dx-popup>-->
