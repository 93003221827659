<dx-drop-down-box
  class="flex-grow-1 np-highlight"
  style="width: 450px;"
  [value]="pimField.field"
  [inputAttr]="{ 'aria-label': 'Owner' }"
  [(opened)]="isTreeBoxOpened"
  valueExpr="key"
  [displayExpr]="getFieldDisplayExpr"
  label="{{ 'Feld' | translate }}"
  placeholder="{{ 'Feld auswählen' | translate}}"
  [showClearButton]="true"
  [dataSource]="fieldList"
  (onValueChanged)="update()"
>
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="fieldList"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-number-box
  class="flex-grow-1 np-highlight"
  label="{{ 'Preisliste Sortiernummer' | translate }}"
  [(value)]="pimField.systemKey"
  (onValueChanged)="onOrderChanged()"
></dx-number-box>

<dx-number-box
  *ngIf="isPriceField"
  class="flex-grow-1 np-highlight"
  label="{{ 'Preis Sortiernummer' | translate }}"
  [(value)]="pimField.elementKey"
  (onValueChanged)="onOrderChanged()"
></dx-number-box>
