import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "catalogPrint",
  templateUrl: "catalogPrint.component.html",
  styleUrls: []
})
export class CatalogPrintComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService) { }

  ngOnInit(): void { }

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

}
