
<dx-tab-panel [dataSource]="jobs" [selectedIndex]="0">
  <div *dxTemplate="let company of 'ftpImportJobsTemplate'">
    <ftpImport></ftpImport>
  </div>
  <div *dxTemplate="let company of 'ftpExportJobsTemplate'">
    <ftpExport></ftpExport>
  </div>
  <div *dxTemplate="let company of 'FtpSupplierPidMappingsImportJobsTemplate'">
    <FtpSupplierPidMappingsImport></FtpSupplierPidMappingsImport>
  </div>

</dx-tab-panel>

