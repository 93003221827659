export class CatalogItem {

  constructor(
    public ProductId?: string,
    public CategoryId?: string,
    public CatalogId?: string,
    public CustomerId?: string,
  ) {

  }
}
