import { FieldListInterface } from '../ui/FieldListInterface';
import { UpdateTemplateItem } from './UpdateTemplateItem';

export class UpdateTemplate implements FieldListInterface {
  public id: string;
  public name: string;
  public customerId: string;
  public fields: string[];
  public addNewChilds?: boolean;
  public ignoreEmptyValues?: boolean;
  public deleteProducts?: boolean;
  public createProducts?: boolean;

  constructor() {}
}
