
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { ShopWare6Connection } from '../Model/Catalog/ShopWareConnection';
import { ShopWare5ShopDto } from '../Model/Dto/Shopware5ShopDto';
import { TreeItem as TreeElement } from "../Model/Dto/TreeItem";



@Injectable()
export class Shopware5Service {
  constructor(private http: HttpClient) {}

  //Verbindung testen
  public getShops(shopUrl: string, user: string, key: string): Observable<ShopWare5ShopDto[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("user", user).append("key", key)
    }
    return (this.http.get("api/shopware5/getshops", options)) as any;
  }

  public getCategories(shopUrl: string, user: string, key: string, parentIds: string[]): Observable<TreeElement[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("user", user).append("key", key)
    }

    parentIds.forEach(id => {
      options.params = options.params.append("parentIds", id);
    });
    return (this.http.get("api/shopware5/getcategories", options)) as any;
  }

  public startExport(catalogId: string, customerId: string): Observable<ShopWare5ShopDto[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get("api/shopware5/exportCatalog", options)) as any;
  }
}
