<div style="max-width: 100%;">
  <dx-data-grid class="h-100"
                [dataSource]="dataSource"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                [showBorders]="true"
                [showColumnHeaders]="true"
                mode="single">
    <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="'ftpServersStore'"></dxo-state-storing>
    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing mode="cell" [allowUpdating]="isManager()" [allowDeleting]="isManager()" [allowAdding]="isManager()" [(editRowKey)]="editRowKey"></dxo-editing>

    <dxi-column dataField="name" caption="{{'Name'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="address" caption="{{'IP Addresse'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="port" dataType="number" caption="{{'Port'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="mode" caption="{{'Modus'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="modes"></dxo-lookup>
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let ftpServer of 'detail'">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Username' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="ftpServer.data.user" (onValueChanged)="onDetailChange(ftpServer.data)"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">{{ 'SSH Key benutzten' | translate }}</div>
          <div class="dx-field-value">
            <dx-switch [(value)]="ftpServer.data.useKey" (onValueChanged)="onDetailChange(ftpServer.data)"></dx-switch>
          </div>
        </div>

        <div class="dx-fieldset">
          <div class="dx-field" *ngIf="!ftpServer.data.useKey">
            <div class="dx-field-label">{{ 'Passwort' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="ftpServer.data.password" mode="password" (onValueChanged)="onDetailChange(ftpServer.data)"></dx-text-box>
            </div>
          </div>

          <div class="dx-field" *ngIf="ftpServer.data.useKey">
            <div class="dx-field-label">{{ 'PassPhrase' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="ftpServer.data.passPhrase" mode="password" (onValueChanged)="onDetailChange(ftpServer.data)"></dx-text-box>
            </div>
          </div>

          <div class="dx-field" *ngIf="ftpServer.data.useKey">
            <div class="dx-field-label">{{ 'Private Key' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-area [(value)]="ftpServer.data.privateKey"
                            (onValueChanged)="onDetailChange(ftpServer.data)"
                            autoResizeEnabled="true"></dx-text-area>
              <dx-file-uploader [multiple]="false"
                                (onValueChanged)="onFileSelected($event, ftpServer.data)">
              </dx-file-uploader>
            </div>
          </div>

          <div class="dx-field">
            <dx-button stylingMode="contained"
                       text="Test"
                       type="normal"
                       width="100%"
                       (onClick)="test(ftpServer.data)">
            </dx-button>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">{{ 'Import Ordner' | translate }}</div>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="folders" [(value)]="ftpServer.data.importDirectory" acceptCustomValue="true"
                             (onOpened)="loadFolderStructure(ftpServer.data)"
                             (onValueChanged)="onDetailChange(ftpServer.data)">
                <div *dxTemplate="let data of 'dropDownButton'">
                  <dx-load-indicator [visible]="isLoading"></dx-load-indicator>
                  <i class="dx-icon-spindown"></i>
                </div>
              </dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">{{ 'Export Ordner' | translate }}</div>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="folders" [(value)]="ftpServer.data.exportDirectory" acceptCustomValue="true"
                             (onOpened)="loadFolderStructure(ftpServer.data)"
                             (onValueChanged)="onDetailChange(ftpServer.data)">
                <div *dxTemplate="let data of 'dropDownButton'">
                  <dx-load-indicator [visible]="isLoading"></dx-load-indicator>
                  <i class="dx-icon-spindown"></i>
                </div>
              </dx-select-box>
            </div>
          </div>

        </div>
      </div>
    </div>
  </dx-data-grid>
</div>
