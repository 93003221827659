import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { CatalogDataQuality } from '../../Model/validation/CatalogDataQuality';
import { CatalogDataQualityChartGroup } from "../../Model/validation/CatalogDataQualityChartGroup";

@Component({
    selector: "dataQualityGroups",
    templateUrl: "dataQualityGroups.component.html",
    styleUrls: []
})

export class DataQualityGroupsComponent implements OnInit, OnChanges {
  @Input() catalogDataQuality: CatalogDataQuality;

  dataSource: CatalogDataQualityChartGroup[];
  colorPalette: string[];

  constructor(public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.initDataSource(this.catalogDataQuality);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDataSource(this.catalogDataQuality);
  }

  initDataSource(value: CatalogDataQuality) {
    this.dataSource = [];
    this.colorPalette = [];
    if (value.productsCountInGroups != undefined) {
      value.productsCountInGroups.forEach(x => {
        this.dataSource.push(new CatalogDataQualityChartGroup(x.validationGroup.id, x.validationGroup.name, x.productCount));
        this.colorPalette.push(x.validationGroup.backgroundColor);
      });
    }
  }
    
  customizeText = (arg: any) => {
    return arg.valueText + " %";
  }

  customizeLabel = (arg: any) => {
    if (arg.valueText == "1")
        return "1 Produkt";

    return arg.valueText + " Produkte";
  }

  customizeDataQualityPieTooltip(event) {
    var tooltip = event.argumentText;
    tooltip += "<br>";
    if (event.valueText == "1") {
      tooltip += "1 Produkt";
    } else {
      tooltip += event.valueText + " Produkte";
    }
    return { text: tooltip };
  }
}
