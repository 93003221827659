import { MappingInput } from "./MappingInput";

export class MappingResult {

  constructor(
    public id?: string,
    public profileId?: string,
    public name?: string,
    public value?: string,
    public unit?: string,
    public mappingInputs?: MappingInput[]
  ) {

  }

}
