<dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="p-2" [swipeEnabled]="false" style="height: 100%;" (onSelectionChanged)="onSelectionChanged($event)">
  <div *dxTemplate="let company of 'priceListTemplate'">
    <catalogPriceList *ngIf="model"></catalogPriceList>
  </div>
  <div *dxTemplate="let company of 'classificationTemplate'">
    <catalogClassification></catalogClassification>
  </div>
  <div *dxTemplate="let company of 'osgTemplate'">
    <catalogOsg></catalogOsg>
  </div>
  <div *dxTemplate="let company of 'mavisTemplate'">
    <catalogMavis></catalogMavis>
  </div>
  <div *dxTemplate="let company of 'printTemplate'">
    <catalogPrint></catalogPrint>
  </div>
  <div *dxTemplate="let company of 'shopwareTemplate'">
    <catalogShopware></catalogShopware>
  </div>
  <div *dxTemplate="let company of 'shopware6Template'">
    <catalogShopware6></catalogShopware6>
  </div>
  <div *dxTemplate="let company of 'shopware5Template'">
    <catalogShopware5></catalogShopware5>
  </div>
  <div *dxTemplate="let company of 'intershopTemplate'">
    <intershopConnection></intershopConnection>
  </div>
  <div *dxTemplate="let company of 'electronicSalesTemplate'">
    <electronicSalesConnection></electronicSalesConnection>
  </div>

  <div *dxTemplate="let company of 'templatesTemplate'">
    <catalogTemplates></catalogTemplates>
  </div>
  <!--JOBS-->
  <div *dxTemplate="let job of 'jobsTemplate'">
    <job-list [storeName]="'JobListStateNewUI_V4_CATALOGDETAIL'"
              [jobListMode]="false"
              [catalogMode]="true"
              [adminMode]="false">
      <!-- always choose ONE mode!! -->
    </job-list>
  </div>
</dx-tab-panel>
