import { Input, Output, EventEmitter, OnInit, Directive } from '@angular/core';
import { ModelService } from '../../../Services/model.service';

@Directive()
export abstract class UserActionBaseComponent implements OnInit {
  @Input() showPlaceHolders: boolean;
  @Output() userActionToggleStartButton = new EventEmitter<boolean>();
  @Output() userActionStarted = new EventEmitter<any>();
  @Output() userActionComponentInitiated = new EventEmitter<UserActionBaseComponent>();

  constructor(public modelService: ModelService) { }

  ngOnInit() {
    //notify the parent component of this current instance
    //Attention: the call to ngOnInit has to be done from every (overwritten) ngOnInit()-Method (super.ngOnInit())
    this.userActionComponentInitiated.emit(this);
  }

  //this should be implementet in every single component which inherits
  abstract startMacro();
}
