<!--47 createTechnicalDetails-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'FeatureSystemName' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedFeatureSystem?.name?.length > 0 ? 'placeLabelSmall' : '' }}">REFERENCE FEATURESYSTEM NAME</label>
    <dx-select-box [(value)]="selectedFeatureSystem"
                   [items]="featureSystems"
                   displayExpr="name"
                   placeholder="">
    </dx-select-box>
  </div>
</div>
