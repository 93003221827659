import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Template } from 'app/Model/Catalog/Template';
import { TemplateMetadata } from 'app/Model/Catalog/TemplateMetadata';
import { TemplateFieldsService } from 'app/Services/templateFields.service';
import DataSource from 'devextreme/data/data_source';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'np-advanced-template-header',
  templateUrl: './advanced-template-header.component.html'
})
export class AdvancedTemplateHeaderComponent implements OnInit {
  @Input() template: Template = null;
  @Output() onUpdate = new EventEmitter<void>();

  dataSource: DataSource<TemplateMetadata> = null;

  constructor(private fieldService: TemplateFieldsService) {}

  ngOnInit(): void {
    const metadata = this.template.metadata ?? [];
    for (let i = 0; i < metadata.length; i++) {
      const item = metadata[i];
      item.order = i + 1;
    }

    this.dataSource = new DataSource(
      metadata.map((x) => ({
        order: x.order,
        orderDisplayValue: this.fieldService.numberToExcelColumn(x.order),
        name: x.name,
        value: x.value
      }))
    );
  }

  private equals<T, U>(a: T, b: U) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  private updateOrders(oldOrder: number = -1, newOrder: number = -1) {
    const items = this.dataSource.items();

    // Finde das zu aktualisierende Item
    const updatedItem = items.find((item) => item.order === oldOrder);
    if (!updatedItem) return;

    if (newOrder < 1) newOrder = 1;
    if (newOrder > items.length) newOrder = items.length;

    if (oldOrder < newOrder) {
      // Verschiebung nach hinten
      for (let item of items) {
        if (item.order > oldOrder && item.order <= newOrder) {
          item.order--;
        }
      }
    } else if (oldOrder > newOrder) {
      // Verschiebung nach vorne
      for (let item of items) {
        if (item.order >= newOrder && item.order < oldOrder) {
          item.order++;
        }
      }
    }

    // Setze die neue Order für das aktualisierte Item
    updatedItem.order = newOrder;
    this.updateDataSource();
  }

  private updateDataSource() {
    const items = this.dataSource.items();
    items.sort((a, b) => a.order - b.order);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order = i + 1;
    }

    this.dataSource.reload();
  }

  onInitNewRow(event: InitNewRowEvent<TemplateMetadata & { orderDisplayValue: string }>) {
    event.data.order = this.dataSource.items().length + 1;
    event.data.orderDisplayValue = this.fieldService.numberToExcelColumn(event.data.order);
    event.data.name = '';
    event.data.value = '';
  }

  onSaving() {
    this.updateOrders();
    const metadata = this.dataSource.items() ?? [];
    if (metadata.length) this.template.metadata = metadata;
    else this.template.metadata = null;
    this.onUpdate.emit();
  }
}
