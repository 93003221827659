<div [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column' }">
  <div class="dx-fieldset" [ngStyle]="{ margin: '20px 30px' }">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Hersteller filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch
          [(value)]="exportParameter.manufacturerFilter.useFilter"
          (onValueChanged)="loadmanufacturerIfNeeded()"
        ></dx-switch>
      </div>
    </div>
  </div>
  <div *ngIf="exportParameter.manufacturerFilter.useFilter" [ngStyle]="{ height: '100%', overflow: 'hidden' }">
    <div *ngIf="isFilterLoaded == false" style="top: 30%">
      <div class="loader"></div>
    </div>
    <div
      *ngIf="isFilterLoaded == true"
      class="{{ fullScreen ? '' : 'maxHeight' }}"
      [ngStyle]="{ 'margin-top': '-20px', height: 'inherit' }"
    >
      <dx-data-grid
        [dataSource]="manufacturerFilters"
        [(selectedRowKeys)]="exportParameter.manufacturerFilter.filterKey"
        [ngStyle]="{ height: 'inherit' }"
        keyExpr="name"
        [repaintChangesOnly]="true"
      >
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-scrolling mode="standard" showScrollbar="always"></dxo-scrolling>
        <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50]" [showInfo]="true"></dxo-pager>
        <dxi-column dataField="name" caption="{{ 'Hersteller' | translate }}"></dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
