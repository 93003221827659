export class WarrantyClass {

  constructor(
    public id?: string,
    public customerId?: string,
    public number?: string,
    public month?: number,
    public name?: string,
    public description?: string,
    public descriptionHtml?: string,


     
  ) {
  }
}


