<dx-tab-panel [dataSource]="pages" [selectedIndex]="0">

  <div *dxTemplate="let company of 'testTemplate'">
    <div class="dx-fieldset" style="max-width: 600px;">



      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Mavis Adresse' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelTest.shopUrl?.length>0 ? 'placeLabelSmall' : '' }}">http://nextpim.bus.nl:6020</label>
          <dx-text-box [(value)]="modelTest.shopUrl" [showClearButton]="true" (onValueChanged)="updateTest($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelTest.shopUrl}}
        </div>
      </div>




      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Benutzername' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelTest.userName?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Benutzername' | translate }}</label>
          <dx-text-box [(value)]="modelTest.userName" [showClearButton]="true" (onValueChanged)="updateTest($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelTest.userName}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Passwort' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelTest.password?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Passwort' | translate }}</label>
          <dx-text-box [(value)]="modelTest.password" [showClearButton]="true" (onValueChanged)="updateTest($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelTest.password}}
        </div>
      </div>


      <dx-toolbar>
        <dxi-item *ngIf="!isTestTested" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnectionTest }"></dxi-item>
        <dxi-item *ngIf="isTestTested" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testShopConnectionTest }"></dxi-item>
      </dx-toolbar>
      <div style="color: red;">
        {{connection2ShopMessageTest}}
      </div>
    </div>

    </div>
  

  <div *dxTemplate="let company of 'liveTemplate'">
    <div class="dx-fieldset" style="max-width: 600px;">



      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Mavis Adresse' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelLive.shopUrl?.length>0 ? 'placeLabelSmall' : '' }}">http://nextpim.bus.nl:6020 </label>
          <dx-text-box [(value)]="modelLive.shopUrl" [showClearButton]="true" (onValueChanged)="updateLive($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelLive.shopUrl}}
        </div>
      </div>




      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Benutzername' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelLive.userName?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Benutzername' | translate }}</label>
          <dx-text-box [(value)]="modelLive.userName" [showClearButton]="true" (onValueChanged)="updateLive($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelLive.userName}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label jobListTD">{{ 'Passwort' | translate }}</div>
        <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ modelLive.password?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Passwort' | translate }}</label>
          <dx-text-box [(value)]="modelLive.password" [showClearButton]="true" (onValueChanged)="updateLive($event)"></dx-text-box>
        </div>
        <div *ngIf="!isManager()" class="dx-field-value">
          {{modelLive.password}}
        </div>
      </div>


      <dx-toolbar>
        <dxi-item *ngIf="!isLiveTested" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnectionLive }"></dxi-item>
        <dxi-item *ngIf="isLiveTested" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testShopConnectionLive }"></dxi-item>
      </dx-toolbar>
      <div style="color: red;">
        {{connection2ShopMessageLive}}
      </div>
    </div>

    </div>
  
</dx-tab-panel>






