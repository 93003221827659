<!--39 CopyMediaFromOtherCatalog-->

<div class="dx-field">
  <div class="dx-field-label">{{ 'Account' | translate }}</div>
  <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedCustomer?.length>0 ? 'placeLabelSmall' : '' }}">ACCOUNT</label>
    <dx-select-box [(value)]="selectedCustomer"
                   [dataSource]="customers"
                   displayExpr="name"
                   [searchEnabled]="true"
                   valueExpr="id"
                   placeholder=""></dx-select-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Quellcatalog' | translate }}</div>
  <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ sourceCatalog?.length>0 ? 'placeLabelSmall' : '' }}">SOURCE CATALOG</label>
    <dx-select-box [(value)]="sourceCatalog"
                   [dataSource]="catalogs"
                   displayExpr="catalogName"
                   [searchEnabled]="true"
                   valueExpr="id"
                   placeholder=""></dx-select-box>
  </div>
</div>
