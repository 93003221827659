<dx-data-grid
  (onToolbarPreparing)="onFeaturesToolbarPreparing($event)"
  [dataSource]="featureSystem.features"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowRemoving)="onRowRemoving($event)"
  (onRowRemoved)="delete($event)"
  [repaintChangesOnly]="true"
  [customizeColumns]="customizeColumns"
  (onContentReady)="onContentReady()"
>
  <dxo-editing
    mode="cell"
    [allowUpdating]="!readOnly('editfeatureDelete')"
    [allowDeleting]="featuresDeletable && !readOnly('editfeatureDelete')"
    [useIcons]="true"
  >
    <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureValuesStateNewUIV2"></dxo-state-storing>

  <div *dxTemplate="let toolbaritem of 'addFeatureButtonTemplate'">
    <dx-button
      stylingMode="text"
      type="default"
      [visible]="!readOnly('CategoryAddFeature')"
      text="{{ 'Neues Feature' | translate }}"
      [disabled]="!addFeatureButtonEnabled || updateLock"
      (onClick)="addFeatureButtonClicked()"
    ></dx-button>
  </div>

  <dxi-column
    width="100px"
    caption="{{ 'Order' | translate }}"
    dataField="forder"
    sortOrder="asc"
    cellTemplate="orderCellTemplate"
    editCellTemplate="orderCellTemplate"
  ></dxi-column>
  <dxi-column
    [visible]="featureNameVisible"
    caption="{{ 'Name' | translate }}"
    dataField="validFeatureId"
    cellTemplate="nameCellTemplate"
    editCellTemplate="nameCellTemplate"
  ></dxi-column>
  <dxi-column
    [visible]="featureDescriptionVisible"
    caption="{{ 'Beschreibung' | translate }}"
    dataField="fDescr"
    cellTemplate="descriptionCellTemplate"
    editCellTemplate="descriptionCellTemplate"
  ></dxi-column>
  <dxi-column
    [visible]="featureValueVisible"
    caption="{{ 'Wert' | translate }}"
    dataField="value"
    cellTemplate="valueCellTemplate"
    editCellTemplate="valueCellTemplate"
  ></dxi-column>

  <dxi-column
    [visible]="featureValueDetailVisible"
    caption="{{ 'Wert Detail' | translate }}"
    dataField="fValueDetails"
    cellTemplate="valueDetailsCellTemplate"
    editCellTemplate="valueDetailsCellTemplate"
  ></dxi-column>
  <dxi-column
    width="120px"
    [visible]="featureUnitVisible"
    caption="{{ 'Einheit' | translate }}"
    dataField="funit"
    cellTemplate="unitCellTemplate"
    editCellTemplate="unitCellTemplate"
  ></dxi-column>
  <dxi-column
    width="100px"
    [visible]="isPrintVisible"
    caption="{{ 'Druckreihenfolge' | translate }}"
    dataField="printOrder"
    cellTemplate="isPrintCellTemplate"
    editCellTemplate="isPrintCellTemplate"
  ></dxi-column>
  <dxi-column
    [visible]="hasAddonCSCard"
    caption="{{ 'CSCardFilter' | translate }}"
    dataField="csCardFilter"
    cellTemplate="csCardFilterCellTemplate"
    editCellTemplate="csCardFilterCellTemplate"
  ></dxi-column>
  <dxi-column
    [visible]="isVariantVisible"
    caption="{{ 'Variantenmerkmal' | translate }}"
    dataField="isVariant"
    cellTemplate="variantCellTemplate"
    editCellTemplate="variantCellTemplate"
  ></dxi-column>

  <div *dxTemplate="let column of 'orderCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.forder != null"
        [suppressLabel]="true"
        [suppressTranslation]="true"
        [suppressToolTip]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        labelText="{{ 'Order' | translate }}"
        field="FORDER"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
      >
        <div slot="content" class="contentSlot">
          <dx-number-box
            [(value)]="column.data.forder"
            [min]="1"
            [showClearButton]="false"
            [showSpinButtons]="!readOnlyForLinkedFields('FORDER', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('FORDER', column.data.validFeatureId) ||
              readOnly('forder') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'FORDER', column.data.validFeatureId)"
          ></dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'nameCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.name?.length > 0"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="updateFeatureName($event)"
        field="FNAME"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="false"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelFeatureNameDroppedError()"
      >
        <div slot="content" class="contentSlot">
          <dx-select-box
            [(value)]="column.data.validFeatureId"
            [showClearButton]="false"
            [readOnly]="
              hasFeatureFieldLinked(column.data.validFeatureId) ||
              readOnly('FNAME') ||
              readOnlyFNameTemplateEditor(column.data)
            "
            (onValueChanged)="updateFeatureName($event, column.data)"
            (validationMessageMode)="(always)"
            [minSearchLength]="0"
            [searchEnabled]="true"
            itemTemplate="item"
            acceptCustomValue="true"
            placeholder=""
            valueExpr="id"
            displayExpr="name"
            (onCustomItemCreating)="addCustomFeature($event, column.data)"
            [(dataSource)]="featuresDataSource"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField('FNAME', getRelevantSystemKey(), getRelevantElementKey(column.data))
                "
              ></dxi-validation-rule>
            </dx-validator>
            <div *dxTemplate="let data of 'item'">
              {{ data.unit ? data.name + ' => ' + data.unit : data.name }}
            </div>
          </dx-select-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'descriptionCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.fDescr?.length > 0"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        field="FDESCR"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <dx-text-box
            [(value)]="column.data.fDescr"
            [showClearButton]="showClearButton('FDESCR', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('FDESCR', column.data.validFeatureId) ||
              readOnly('fDescr') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'FDESCR', column.data.validFeatureId)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField('FDESCR', getRelevantSystemKey(), getRelevantElementKey(column.data))
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'valueCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.value?.length > 0"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="updateFeatureValue($event)"
        field="FVALUE"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <featurevalue
            [(modelService)]="modelService"
            [(displayMode)]="displayMode"
            [templateView]="templateView"
            [(featureSystem)]="featureSystem"
            [(feature)]="column.data"
            [hasAddonCLASSIFICATION]="hasAddonCLASSIFICATION"
            [readOnly]="
              readOnlyForLinkedFields('FVALUE', column.data.validFeatureId) ||
              readOnly('fValue') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="updateFeatureValue($event, column.data)"
          ></featurevalue>
        </div>
      </np-inputControl>
    </div>
  </div>

  <div *dxTemplate="let column of 'valueDetailsCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.fValueDetails?.length > 0"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        field="FVALUE_DETAILS"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <dx-text-box
            [(value)]="column.data.fValueDetails"
            [showClearButton]="showClearButton('FVALUE_DETAILS', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('FVALUE_DETAILS', column.data.validFeatureId) ||
              readOnly('fValueDetails') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'FVALUE_DETAILS', column.data.validFeatureId)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField(
                    'FVALUE_DETAILS',
                    getRelevantSystemKey(),
                    getRelevantElementKey(column.data)
                  )
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'unitCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.funit?.length > 0"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="updateFeatureUnit($event)"
        field="FUNIT"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="false"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelFeatureUnitDroppedError()"
      >
        <div slot="content" class="contentSlot">
          <dx-text-box
            [(value)]="column.data.funit"
            [showClearButton]="showClearButton('FUNIT', column.data.validFeatureId)"
            [readOnly]="
              hasFeatureFieldLinked(column.data.validFeatureId) ||
              readOnly('fUnit') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="updateFeatureUnit($event, column.data)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField('FUNIT', getRelevantSystemKey(), getRelevantElementKey(column.data))
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'isPrintCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="column?.data?.printOrder != null"
        [suppressLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        field="FEATURE_PRINT_ORDER"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <dx-number-box
            [(value)]="column.data.printOrder"
            [min]="0"
            [showSpinButtons]="!readOnlyForLinkedFields('FEATURE_PRINT_ORDER', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('FEATURE_PRINT_ORDER', column.data.validFeatureId) ||
              readOnly('fPrintOrder') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'FEATURE_PRINT_ORDER', column.data.validFeatureId)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField(
                    'FEATURE_PRINT_ORDER',
                    getRelevantSystemKey(),
                    getRelevantElementKey(column.data)
                  )
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'variantCellTemplate'">
    <div class="w-100">
      <np-inputControl
        [(model)]="model"
        [showPlaceLabel]="column?.data?.isVariant != null"
        [showLinkButtons]="showLinkButtons"
        [suppressLabel]="true"
        [suppressPlaceLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        field="ISVARIANT"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div
          slot="content"
          class="contentSlot np-others"
          style="width: auto; height: auto; padding-top: 12px; padding-left: 10px"
        >
          <dx-switch
            [(value)]="column.data.isVariant"
            [showClearButton]="showClearButton('ISVARIANT', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('ISVARIANT', column.data.validFeatureId) ||
              readOnly('ISVARIANT') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'ISVARIANT', column.data.validFeatureId)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField(
                    'ISVARIANT',
                    getRelevantSystemKey(),
                    getRelevantElementKey(column.data)
                  )
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-switch>
        </div>
      </np-inputControl>
    </div>
  </div>
  <div *dxTemplate="let column of 'csCardFilterCellTemplate'">
    <div class="w-100">
      <np-inputControl
        [(model)]="model"
        [showPlaceLabel]="column?.data?.csCardFilter != null"
        [showLinkButtons]="showLinkButtons"
        [suppressLabel]="true"
        [suppressPlaceLabel]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantElementKey(column.data)) : null"
        (onValueChanged)="update($event)"
        field="FEATURE_CS_CARD_FILTER"
        [system]="getRelevantSystemKey()"
        [element]="getRelevantElementKey(column.data)"
        [showDragHandle]="showDragHandle && featureIsValid(column.data)"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div
          slot="content"
          class="contentSlot np-others"
          style="width: auto; height: auto; padding-top: 12px; padding-left: 10px"
        >
          <dx-select-box
            [(value)]="column.data.csCardFilter"
            displayExpr="name"
            keyExpr="key"
            valueExpr="key"
            [items]="csCardFilters"
            [showClearButton]="showClearButton('FEATURE_CS_CARD_FILTER', column.data.validFeatureId)"
            [readOnly]="
              readOnlyForLinkedFields('FEATURE_CS_CARD_FILTER', column.data.validFeatureId) ||
              readOnly('FEATURE_CS_CARD_FILTER') ||
              readOnlyTemplateEditor(column.data)
            "
            (onValueChanged)="update($event, column.data, 'FEATURE_CS_CARD_FILTER', column.data.validFeatureId)"
          >
            <dx-validator *ngIf="!templateView"
                          validationGroup="FEAT"
                          (onInitialized)="validationService.startAutomaticValidation($event)">
              <dxi-validation-rule
                type="async"
                [validationCallback]="
                  validationService.validateField(
                    'FEATURE_CS_CARD_FILTER',
                    getRelevantSystemKey(),
                    getRelevantElementKey(column.data)
                  )
                "
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </np-inputControl>
    </div>
  </div>
</dx-data-grid>

<div *ngIf="exportMode" style="margin-top: 7px; height: 113px">
  <div class="d-flex flex-row">
    <div class="np-field w-50">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="false"
        [showPlaceLabel]="featureSystem?.featureSystemTable?.length > 0"
        [suppressLabel]="true"
        [suppressLinkButton]="true"
        [suppressTranslation]="true"
        [suppressToolTip]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event) : null"
        (onValueChanged)="update($event)"
        labelText="{{ 'FeatureSystemTable' | translate }}"
        field="FEATURE_SYSTEM_TABLE"
        [system]="getRelevantSystemKey()"
        [showDragHandle]="showDragHandle && featureSystemIsValid()"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <dx-text-area
            [(value)]="featureSystem.featureSystemTable"
            [height]="130"
            [readOnly]="readOnly('fSystemTable')"
          ></dx-text-area>
        </div>
      </np-inputControl>
    </div>
    <div class="np-field w-50 pl-3">
      <np-inputControl
        [(model)]="model"
        [showLinkButtons]="false"
        [showPlaceLabel]="featureSystem?.featureSystemList?.length > 0"
        [suppressLabel]="true"
        [suppressLinkButton]="true"
        [suppressTranslation]="true"
        [suppressToolTip]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelectionEnabled ? templateSelect($event) : null"
        (onValueChanged)="update($event)"
        labelText="{{ 'FeatureSystemList' | translate }}"
        field="FEATURE_SYSTEM_LIST"
        [system]="getRelevantSystemKey()"
        [showDragHandle]="showDragHandle && featureSystemIsValid()"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event, column?.data)"
      >
        <div slot="content" class="contentSlot">
          <dx-text-area
            [(value)]="featureSystem.featureSystemList"
            [height]="130"
            [readOnly]="readOnly('fSystemList')"
          ></dx-text-area>
        </div>
      </np-inputControl>
    </div>
  </div>
</div>
