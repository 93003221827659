import { Component, OnDestroy, OnInit } from "@angular/core";
import { DxDraggableModule } from "devextreme-angular";

@Component({
  selector: "sandbox",
  templateUrl: "sandbox.component.html",
  styleUrls: ["./sandbox.component.css"]
})
export class SandboxComponent implements OnInit, OnDestroy {



  //text42: string;
  //content: string;



  constructor() { }



  ngOnInit(): void {
    //this.text42 = "text42";
    //this.content = "";



    this.dummy42 = new Dummy(42, "dummy42", new Date());
    this.listLeft1 = [
      new Dummy(1, "listLeft1_1", new Date()),
      new Dummy(2, "listLeft1_2", new Date()),
      new Dummy(3, "listLeft1_3", new Date()),
      new Dummy(4, "listLeft1_4", new Date()),
      new Dummy(5, "listLeft1_5", new Date()),
      new Dummy(6, "listLeft1_6", new Date()),
      new Dummy(7, "listLeft1_7", new Date()),
      new Dummy(8, "listLeft1_8", new Date())
    ];
    this.listRight1 = [];
  }

  ngOnDestroy(): void {
    //this.text42 = "";
    //this.content = "";



    this.dummy42 = new Dummy(42, "dummy42", new Date());
    this.listLeft1 = [
      new Dummy(1, "listLeft1_1", new Date()),
      new Dummy(2, "listLeft1_2", new Date()),
      new Dummy(3, "listLeft1_3", new Date()),
      new Dummy(4, "listLeft1_4", new Date()),
      new Dummy(5, "listLeft1_5", new Date()),
      new Dummy(6, "listLeft1_6", new Date()),
      new Dummy(7, "listLeft1_7", new Date()),
      new Dummy(8, "listLeft1_8", new Date())
    ];
    this.listRight1 = [];
  }



  //// test only
  //updateText42(e) {
  //  this.text42 = e.value;
  //}



  dummy42: Dummy;
  listLeft1: Dummy[];
  listRight1: Dummy[];

  //listLeft2 = ["listLeft2_1", "listLeft2_2", "listLeft2_3", "listLeft2_4", "listLeft2_5", "listLeft2_6", "listLeft2_7", "listLeft2_8"];
  //listRight2: string[] = [];



  onSingleDragStart(e) {
    e.itemData = e.fromData;
    e.itemData.source = "single";
  }

  onLeftDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
    e.itemData.source = "left";
  }

  onRightDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
    e.itemData.source = "right";
  }



  onRightDrop(e) {
    if (e.itemData.name == "dummy42") {
      this.listRight1.push(e.itemData);
    }

    let indexLeft = this.listLeft1.indexOf(e.itemData);
    let indexRight = this.listRight1.indexOf(e.itemData);
    if (indexLeft >= 0) {
      //this.listLeft1.splice(indexLeft, 1);  //links entfernen, wenn gewünscht

      if (indexRight < 0) {                   // nur einfügen, wenn noch nicht drin
        this.listRight1.push(e.itemData);
      }
    }

    // müsste an der richtigen position eingefügt werden...
  }

  onTrashDrop(e) {
    if (e.itemData.source != "right") {
      return;
    }

    let indexRight = this.listRight1.indexOf(e.itemData);
    if (indexRight >= 0) {
      this.listRight1.splice(indexRight, 1);  //rechts entfernen
    }
  }



  onRightReorder(e) {
    this.listRight1.splice(e.fromIndex, 1);
    this.listRight1.splice(e.toIndex, 0, e.itemData);
  }
}

export class Dummy {
  constructor(
    public id?: number,
    public name?: string,
    public created?: Date
  ) {

  }
}
