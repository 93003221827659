<div class="d-flex flex-row h-100">
  <dx-scroll-view class="w-33" showScrollbar="always">
    <dx-accordion
      [dataSource]="cards"
      [collapsible]="true"
      [multiple]="false"
      [animationDuration]="300"
      [(selectedItems)]="macroAccordionSelectedItem"
    >
      <div *dxTemplate="let card of 'title'">
        <div style="font-size: 18px">{{ card.name }}</div>
      </div>
      <div *dxTemplate="let card of 'item'" class="p-0">
        <div *ngFor="let element of card.elements">
          <dx-button
            stylingMode="text"
            text="{{ getMacroName(element) }}"
            hint="{{ getMacroName(element) }}"
            (onClick)="setMacro(element)"
            class="dx-button-text-left w-100"
          ></dx-button>
        </div>
      </div>
    </dx-accordion>
  </dx-scroll-view>
  <div class="flex-fill">
    <dx-scroll-view showScrollbar="always">
      <div *ngIf="macro != 0">
        <div class="dx-accordion-item-title">
          <div class="dx-accordion-item-title-caption">{{ getMacroName(macro) }}</div>
        </div>

        <div *ngIf="macro != 62" class="dx-accordion-item-body">
          <div [innerHTML]="getMacroText(macro)"></div>
        </div>
        <div *ngIf="isDelete" class="dx-accordion-item-body accordion-body-warning">
          <div>
            {{
              'ACHTUNG: Diese Makro löscht Daten unwiderruflich. Bitte erstellen Sie ggf. einen Wiederherstellungspunkt.'
                | translate
            }}
          </div>
        </div>
        <div *ngIf="isFeatureSystemMakro" class="dx-accordion-item-body">
          <div>
            {{
              'Merkmalsgruppen, die als Vorlage im Katalog hinterlegt sind können mit diesem Makro nicht bearbeitet werden.'
                | translate
            }}
          </div>
        </div>
        <div *ngIf="macro == 62">
          <!--62: ExportReportProductDatasheet - Enthält HTML im Text und den Übersetzungen-->
          <div class="dx-accordion-item-body" [innerHtml]="getMacroText(macro)"></div>
        </div>

        <div [ngSwitch]="macro.toString()" class="dx-accordion-item-body">
          <!--11 Bilder konvertieren-->
          <div *ngSwitchCase="'11'">
            <userAction-convertPictures
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-convertPictures>
          </div>
          <!--15 Löschen per Liste-->
          <div *ngSwitchCase="'15'">
            <userAction-deleteByList
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteByList>
          </div>
          <!--16 Suchen per Liste-->
          <div *ngSwitchCase="'16'">
            <userAction-searchByList
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-searchByList>
          </div>
          <!--17 Update von Katalog-->
          <div *ngSwitchCase="'17'">
            <userAction-updateCatalog
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-updateCatalog>
          </div>
          <!--51 Update to Katalog-->
          <div *ngSwitchCase="'51'">
            <userAction-updateToCatalog
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-updateToCatalog>
          </div>
          <!--18 compare catalog-->
          <div *ngSwitchCase="'18'">
            <userAction-compareCatalog
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-compareCatalog>
          </div>
          <!--31 Rename FeatureSystem Name-->
          <div *ngSwitchCase="'31'">
            <userAction-renameFeatureSystem
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-renameFeatureSystem>
          </div>
          <!--34 Delete Empty Features-->
          <div *ngSwitchCase="'34'">
            <userAction-deleteEmptyFeatures
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteEmptyFeatures>
          </div>

          <!-- 83: massGenerateKITexts -->
          <div *ngSwitchCase="'83'">
            <userAction-generateAITexts
              [showPlaceHolders]="showPlaceHolders"
              (userActionToggleStartButton)="onToggleStartMacroButton($event)"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-generateAITexts>
          </div>

          <!--84 Rename Features-->
          <div *ngSwitchCase="'84'">
            <userAction-renameFeatures
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-renameFeatures>
          </div>
          <!--85 Split Featurevalues from Unit-->
          <div *ngSwitchCase="'85'">
            <userAction-splitFeatureValuesFromUnit
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-splitFeatureValuesFromUnit>
          </div>
          <!--38 DeleteFeatureSystems-->
          <div *ngSwitchCase="'38'">
            <userAction-deleteFeatureSystems
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteFeatureSystems>
          </div>
          <!--39 Copy Media From Other Catalog-->
          <div *ngSwitchCase="'39'">
            <userAction-copyMediaFromOtherCatalog
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-copyMediaFromOtherCatalog>
          </div>
          <!--40 deleteFeatureByName-->
          <div *ngSwitchCase="'40'">
            <userAction-deleteFeatures
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteFeatures>
          </div>
          <!--47 Create Technical Details-->
          <div *ngSwitchCase="'47'">
            <userAction-createTechnicalDetails
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-createTechnicalDetails>
          </div>
          <!--55 SpreadCatalog-->
          <div *ngSwitchCase="'55'">
            <userAction-spreadCatalog
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-spreadCatalog>
          </div>
          <!--58 Harmonize Featuresystems -->
          <div *ngSwitchCase="'58'">
            <userAction-harmonizeFeatureSystems
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-harmonizeFeatureSystems>
          </div>
          <!--60 Fix Classification-->
          <div *ngSwitchCase="'60'">
            <userAction-fixClassification
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-fixClassification>
          </div>
          <!--62: ExportReportProductDatasheet-->
          <div *ngSwitchCase="'62'">
            <userAction-exportReportProductDatasheet
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-exportReportProductDatasheet>
          </div>
          <!-- 64  DeleteDuplicateMimes -->
          <div *ngSwitchCase="'64'">
            <userAction-deleteDuplicateMimes
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteDuplicateMimes>
          </div>
          <!--68: RemoveUDXOnProducts-->
          <div *ngSwitchCase="'68'">
            <userAction-removeUdxOnProducts
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-removeUdxOnProducts>
          </div>
          <!--71: DeleteMediaByPurpose-->
          <div *ngSwitchCase="'71'">
            <userAction-deleteMediaByPurpose
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-deleteMediaByPurpose>
          </div>

          <!--72: createVariantFeatureSystem-->
          <div *ngSwitchCase="'72'">
            <userAction-CreateVariants
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-CreateVariants>
          </div>

          <!--74: harmonizeFeatures-->
          <div *ngSwitchCase="'74'">
            <userAction-harmonizeFeatures
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-harmonizeFeatures>
          </div>

          <!--78: setWawiSupplierPids-->
          <div *ngSwitchCase="'78'">
            <userAction-setWawiSupplierPids
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-setWawiSupplierPids>
          </div>

          <div *ngSwitchCase="'82'">
            <userAction-createEnventaMime
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-createEnventaMime>
          </div>

          <div *ngSwitchCase="'44'">
            <userAction-setMimePurpose
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-setMimePurpose>
          </div>

          <!--87: markAsChanged-->
          <div *ngSwitchCase="'87'">
            <userAction-markAsChanged
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-markAsChanged>
          </div>

          <!--90: changeMimePurposes-->
          <div *ngSwitchCase="'90'">
            <useraction-changemimepurposes
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></useraction-changemimepurposes>
          </div>

          <!--97: applyWawiMappings-->
          <div *ngSwitchCase="'97'">
            <userAction-applyWawiMappings
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></userAction-applyWawiMappings>
          </div>

          <div *ngSwitchCase="'101'">
            <app-useraction-assign-mime-by-supplier-pid
              [showPlaceHolders]="showPlaceHolders"
              (userActionComponentInitiated)="onUserActionComponentInitiated($event)"
              (userActionStarted)="onUserActionStarted($event)"
            ></app-useraction-assign-mime-by-supplier-pid>
          </div>
        </div>
        <div class="macroHead dx-accordion-item-body" *ngIf="startMacroButtonEnabled">
          <dx-button
            stylingMode="text"
            type="default"
            text="{{ 'Starte Makro' | translate }}"
            (onClick)="startMacro()"
            class="buttonMargin"
          ></dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>
