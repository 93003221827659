import { Input, Output, EventEmitter, Component } from "@angular/core";
import { Product } from '../../Model/Catalog/Product';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ValidationService } from "../../Services/Validation/validation.service";
import { LoginService } from "../../Services/login.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "productDetailValidation",
  templateUrl: "productDetailValidation.component.html",
  styleUrls: ["./productDetailValidation.component.css"]
})
export class ProductDetailValidationComponent{
  @Input() model: Product = new Product;
  @Input() showToolTips: Boolean = true;
  @Input() hasAddonVALIDATION: Boolean = true;
  @Input() isPrint: Boolean = false;
  @Input() biggerHeader: Boolean = null;

  @Output() validateProduct = new EventEmitter<any>();

  emptyArray = [false, false, false, false, false, false];
  v: boolean[] = this.emptyArray;
  validationGroupColors: string[];

  constructor(public validationService: ValidationService, public catalogService: CatalogService, private loginService: LoginService) {
  }

  get bigHeader() {
    if (this.biggerHeader != null) {
      return this.biggerHeader;
    }
    if (window) {
      return (window.innerHeight > 1000);
    }
    return true;
  }

  async toggleValidation() {
    this.loginService.currentCustomer.automaticValidation = !this.loginService.currentCustomer.automaticValidation;

    if (this.loginService.currentCustomer.automaticValidation) {
      this.validationService.validateProduct(this.catalogService.product);
      this.validateProduct.emit();
    }

    await lastValueFrom(this.loginService.customerService.update(this.loginService.currentCustomer));
  }

  //USABILITY

  ttt(id) {
    if (this.showToolTips) {
      if (!this.v[id]) {
        this.v = this.emptyArray;
      }
      this.v[id] = !this.v[id];
    }
  }
}


