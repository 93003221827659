import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { ValidFeatureService } from "../../../../Services/validFeature.service";


@Component({
  selector: "userAction-CreateVariants",
  templateUrl: "userAction-createVariantsFeatureSystem.component.html"
})
export class UserActionCreateVariantsFeatureSystemComponent extends UserActionFeatureSystemsComponent {
  mode = false;
  createTechnicalDetails = false;

  constructor(public modelService: ModelService, validFeatureService: ValidFeatureService) {
    super(modelService, validFeatureService);
  }

  startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "CreateOrMarkVariantsFeatures";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString(): string {
    let parameterJsonString = JSON.stringify(
      {
        mode: this.mode,
        createTechnicalDetails: this.createTechnicalDetails
      });
    return parameterJsonString;
  }
}
