export class MavisCatalogMapping {

    constructor(
        public lastSend?: Date,
        public lastSendLive?: Date,
        public language?: number,
        public languageLive?: number,
    ) {

    }
}



