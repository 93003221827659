import { Address } from "./Address";


export class Buyer {

  constructor(
    public buyerName?: string,
    public buyerIdType?: string,
    public buyerId?: string,
    public address?: Address,
  ) {
    this.address = new Address();
  }
}
