import {map} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { ModelService } from '../Services/model.service';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { MappingProfile } from '../Model/featureMapping/MappingProfile';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "featureMapping",
  templateUrl: "featureMapping.component.html"
})
export class FeatureMappingComponent implements OnInit {
  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Feature Mapping");

  }

  ngOnInit(): void {
    this.getProfiles();
  }

  public mappingProfiles: MappingProfile[] = [];
  
  onContentReady(e) {
    e.component.columnOption("command:edit",
      {
        visibleIndex: -1,
      });
  }



  onEditClicked(e) {

    this.navigateToDetail(e.key.id);
 
  }

  navigateToDetail(id: string) {
    this.modelService.featureMappingService.selectedMappingId = id;
    this.modelService.router.navigate(["/featureMappingDetail"]);
  }


  getProfiles() {
    this.modelService.featureMappingService.getMappingProfiles().subscribe(
        (result: MappingProfile[]) => {
        this.mappingProfiles = result;
      });
  }

  update(event) {
   
    this.modelService.featureMappingService.updateMappingProfile(event.data,this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingProfile) => {
      });


   
  }

  addMappingProfile(e ) {
    e.cancel = true;
    let profile = new MappingProfile();
    profile.name = e.data.name;

    this.modelService.featureMappingService.addMappingProfile(profile, this.modelService.loginService.currentCustomer.id)
      .subscribe((result: MappingProfile) => this.navigateToDetail(result.id));
    
    

  }

 

  deleteStatus(event) {
    this.modelService.featureMappingService.deleteMappingProfile(event.data.id, this.modelService.loginService.currentCustomer.id);
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }

  addProfileToList(mappingProfile: MappingProfile): void {
    this.mappingProfiles.push(mappingProfile);

  }
}
