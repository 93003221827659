export class LanguageFlag {

  constructor(
    public code?: string,
    public language?: string,
    public flag?: string
  ) {

  }
}
