import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ExportFormatWithCustomers } from '../Model/exportParameter/ExportFormatWithCustomers';
import { ExportFormatWithVersions } from '../Model/exportParameter/ExportFormatWithVersions';
import { ExportParameter } from '../Model/exportParameter/ExportParameter';


@Injectable()
export class ExportConfigurationService {
  private readonly apiUrl = 'api/ExportConfiguration';

  constructor(private http: HttpClient) { }

  get(id: string, customerId: string): Observable<ExportParameter> {
    const url = `${this.apiUrl}/Get?id=${id}&customerId=${customerId}`;
    return this.http.get<ExportParameter>(url);
  }

  getAll(customerId: string): Observable<ExportParameter[]> {
    const url = `${this.apiUrl}/GetAll?customerId=${customerId}`;
    return this.http.get<ExportParameter[]>(url);
  }

  delete(id: string, customerId: string): Observable<void> {
    const url = `${this.apiUrl}/Delete?id=${id}&customerId=${customerId}`;
    return this.http.delete<void>(url);
  }

  update(model: ExportParameter, customerId: string): Observable<ExportParameter> {
    const url = `${this.apiUrl}/Update?customerId=${customerId}`;
    return this.http.post<ExportParameter>(url, model);
  }


  getExportFormatWithVersions(customerId: string): Observable<ExportFormatWithVersions[]> {
    const url = `${this.apiUrl}/GetExportFormatsWithVersions?customerId=${customerId}`;
    return this.http.get<ExportFormatWithVersions[]>(url);
  }

  getExportFormatForSendToCustomers(customerId: string): Promise<ExportFormatWithCustomers> {
    const url = `${this.apiUrl}/GetExportFormatForSendToCustomers?customerId=${customerId}`;
    return lastValueFrom(this.http.get<ExportFormatWithCustomers>(url));
  }
  
}
