import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../../Model/Catalog/Product';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-template-product-categories',
  templateUrl: './template-product-categories.component.html',
  styleUrls: ['./template-product-categories.component.css', '../template.css']
})
export class TemplateProductCategoriesComponent implements OnInit {
  @Input() model: Product = null;
  @Input() selectedElements: string[] = [''];
  @Input() dragFilter: string = 'noDrag';
  @Input() showDragHandle: boolean = false;

  @Output() onValueChanged = new EventEmitter<any>();
  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();

  constructor(public translate: TranslateService) {}

  ngOnInit() {}

  update(event = null) {
    this.onValueChanged.emit(event);
  }
  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }
}
