import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { DeeplConnection } from "../Model/Dto/DeeplConnection";
import { EdeMemberId } from "../Model/edeSortimentsAbo/EdeMemberId";
import { Message } from "../Model/System/Message";

@Injectable()
export class EdeMemberIdService {


  constructor(private http: HttpClient, public translate: TranslateService) {

  }

  getConnection(customerId: string): Observable<EdeMemberId> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<EdeMemberId>("api/EdeMemberId/GetMemberId", options)) as any;
  }

  update(deeplConnection: EdeMemberId): Observable<EdeMemberId> {
    return (this.http.post<EdeMemberId>("api/EdeMemberId/Update", deeplConnection));
  }
}
