<!--17 Update von Katalog-->

<div class="dx-field">
  <div class="dx-field-label">{{ 'Account' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedCustomer?.length > 0 ? 'placeLabelSmall' : '' }}">ACCOUNT</label>
    <dx-select-box
      [(value)]="selectedCustomer"
      [dataSource]="customers"
      displayExpr="name"
      [searchEnabled]="true"
      valueExpr="id"
      placeholder=""
    ></dx-select-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Quellkatalog' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ sourceCatalog?.length > 0 ? 'placeLabelSmall' : '' }}">SOURCE CATALOG</label>
    <dx-select-box
      [(value)]="sourceCatalog"
      [dataSource]="catalogs"
      displayExpr="catalogName"
      [searchEnabled]="true"
      valueExpr="id"
      placeholder=""
    ></dx-select-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Update Template' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <div [ngStyle]="{ display: 'flex', 'align-items': 'center' }">
      <dx-select-box
        [(value)]="selectedTemplate"
        [dataSource]="updateTemplates"
        [searchEnabled]="true"
        displayExpr="name"
        valueExpr="id"
        placeholder=""
        label="UPDATE TEMPLATE"
        [ngStyle]="{ width: '100%' }"
      ></dx-select-box>
      <dx-button icon="edit" (onClick)="showEditTemplates()"></dx-button>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Filter' | translate }}</div>
  <div class="dx-field-value">
    <np-export-parameter-selector
      [(selectedExportParameterId)]="selectedExportParameterId"
      [onlyUpdateFilter]="true"
    ></np-export-parameter-selector>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Schlüssel' | translate }}</div>
  <div class="dx-field-value">
    <dx-select-box [(value)]="selectedKey"
                   [dataSource]="keys"
                   displayExpr="translated"
                   valueExpr="value">
    </dx-select-box>
  </div>
</div>

<np-dialog
  [popupWidth]="1200"
  [popupHeight]="800"
  [popupTitle]="showPopup ? ('Update Templates' | translate) : ('Update Templates' | translate)"
  [(popupVisible)]="showPopup"
>
  <div slot="content" class="h-100 d-flex flex-row">
    <updateTemplates [helpSwitch]="false"></updateTemplates>
  </div>
</np-dialog>
