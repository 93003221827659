<fullscreen-page>
  <content-column helpSwitch="true" look="light">
    <div slot="content" class="p-2 flex-fill overflow-hidden">
      <dx-data-grid #mappingsGrid
                    id="mappingsGrid"
                    class="h-100"
                    (onToolbarPreparing)="onContentToolbarPreparing($event)"
                    [dataSource]="supplierPidMappings"
                    [remoteOperations]="false"
                    [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    noDataText="{{ 'keine Mappings im Account' | translate }}"
                    [showBorders]="true"
                    (onRowRemoving)="deleteSupplierPidMapping($event)"
                    (onRowUpdated)="update($event)"
                    (onRowUpdating)="onRowUpdating($event)">

        <div *dxTemplate="let toolbaritem of 'addMappingButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Neue Artikelnummer' | translate }}" (onClick)="addSupplierPidMapping()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'deleteAllButtonTemplate'">
          <dx-button stylingMode="text" type="danger" text="{{ 'Alles Löschen' | translate }}" (onClick)="deleteAllSupplierPidMapping()"></dx-button>
        </div>

        <div *dxTemplate="let toolbaritem of 'excelExportButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Excel Export' | translate }}" (onClick)="exportExcel()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'excelImportButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Excel Import' | translate }}" (onClick)="showImport()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'Aktualisieren' | translate }}" (onClick)="loadData()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'supplierSelectTemplate'" class="toolbar-row">
          <div *ngIf="modelService.loginService.hasAddonDEVELOPMENT">
            <dx-drop-down-box id="suppliers"
                              [value]="selectedSupplier?.supplierId"
                              label="{{ 'Supplier' | translate }}"
                              valueExpr="supplierId"
                              displayExpr="supplierName"
                              width="500"
                              [dataSource]="suppliers">
              <div *dxTemplate="let data of 'content'">
                <dx-data-grid #suppliersGrid
                              id="suppliersGrid"
                              [dataSource]="suppliers"
                              [selection]="{ mode: 'single' }"
                              [hoverStateEnabled]="true"
                              [paging]="{ enabled: true, pageSize: 10 }"
                              [filterRow]="{ visible: true }"
                              [scrolling]="{ mode: 'virtual' }"
                              [height]="345"
                              valueExpr="suplierId"
                              [(selectedRowKeys)]="selectedSupplierKey"
                              (onSelectionChanged)="supplierSelectionChanged($event)"
                              searchExpr="supplierName"
                              [searchEnabled]="true">
                  <dxi-column dataField="supplierName" caption="{{ 'Lieferant' | translate }}"></dxi-column>
                  <dxi-column dataField="supplierId" caption="{{ 'LieferantenId' | translate }}"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-drop-down-box>

            <dx-load-panel #loadPanel
                           shadingColor="rgba(0,0,0,0.4)"
                           [position]="{ of: '#suppliers' }"
                           [(visible)]="suppliersLoading"
                           [showIndicator]="true"
                           [showPane]="false"
                           [shading]="false"
                           [closeOnOutsideClick]="false">
            </dx-load-panel>
          </div>
        </div>


        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Mapping suchen' | translate }}" width="250"></dxo-search-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-editing mode="cell" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
          <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diese Zuordnung wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Übersetzung löschen' | translate }}"></dxo-texts>
        </dxo-editing>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="SupplierPidMappingsState"></dxo-state-storing>

        <dxi-column dataField="supplierPid" caption="{{ 'Produktnummer' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
        <dxi-column dataField="internationalPid" caption="{{ 'Internationale ID' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
        <dxi-column dataField="manufacturerName" caption="{{ 'Herstellername' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
        <dxi-column dataField="manufacturerAid" caption="{{ 'Herstellernummer' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>


        <dxi-column dataField="usedSuppliers" caption="{{ 'Gepflegte Lieferanten' | translate }}" [allowEditing]="false" [allowSearch]="true"></dxi-column>


        <dxi-column *ngIf="selectedSupplier != null" dataField="gtin" caption="{{ 'Lieferanten Gtin' | translate }}"></dxi-column>
        <dxi-column *ngIf="selectedSupplier != null" dataField="artikelNumber" caption="{{ 'Lieferanten Artikelnummer' | translate }}"></dxi-column>

        <dxi-column dataField="refernceGtins" caption="{{ 'ReferenzeGtins' | translate }}"  [allowEditing]="true" cellTemplate="tagBoxEditor" editCellTemplate="tagBoxEditor" [allowSearch]="true"></dxi-column>
        <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
          <dx-tag-box [(value)]="cellInfo.value"
                      (onValueChanged)="updateReferenceGtin($event, cellInfo)"
                      [acceptCustomValue]="true"
                      [applyValueMode]="true">
          </dx-tag-box>
        </div>
      </dx-data-grid>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>

<dx-popup *ngIf="importSupplierPidMappingsVisible"
          [width]="500"
          [height]="300"
          [showTitle]="true"
          title="{{ 'Artikelnummern Mappings importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importSupplierPidMappingsVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <importSupplierPidMappings></importSupplierPidMappings>
  </div>
</dx-popup>



