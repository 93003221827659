import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { excelFieldNames, fieldNames } from 'app/Model/FieldNames';

export function fillLastKey(templateItem: TemplateItem, key: string) {
  const lastIndex = templateItem.keys.length - 1;
  const keys = templateItem.keys;
  if (lastIndex >= 0 && keys[lastIndex]?.trim() === '') {
    keys[lastIndex] = key;
  }
}

export function parseFeatureField(key: string, systemKey = '-1', elementKey = '-1') {
  if (!key?.trim()) return null;

  const [field, featureSystemName = '', addition = ''] = (key ?? '').split('__');
  const [featureName = '', featureUnit = ''] = addition.split('>>>>>');
  const pimField = new NextPimField(field, systemKey, elementKey);
  return new TemplateItem([pimField], 1, featureSystemName, '', featureUnit, '', false, '', featureName);
}

export function parsePimField(key: string, systemKey?: string, elementKey?: string) {
  if (!key?.trim()) return null;
  let field: string;
  [field, systemKey = '-1', elementKey = '-1'] = (key ?? '').split('__');
  return new NextPimField(elementKey, systemKey, field);
}

export function comparePimFields(a: NextPimField, b: NextPimField) {
  if (a === null) return false;
  return a.field == b.field && a.systemKey === b.systemKey && a.elementKey == b.elementKey;
}

const mimeFields = [
  fieldNames.mimes,
  fieldNames.mimeType,
  fieldNames.mimeSource,
  fieldNames.mimeDescription,
  fieldNames.mimeAlt,
  fieldNames.mimePurpose,
  fieldNames.mimeLinkSizeNorm
];
export function isMimeField(field: string) {
  return Boolean(field) && mimeFields.includes(field.split('__')[0]);
}

const featureFields = [
  fieldNames.featureSystemName,
  fieldNames.featureSystemGroupId,
  fieldNames.featureSystemGroupId2,
  fieldNames.featureSystemGroupName,
  fieldNames.featureSystemOrder,
  fieldNames.fOrder,
  fieldNames.fName,
  fieldNames.fValue,
  fieldNames.fUnit,
  fieldNames.fDescription,
  fieldNames.fValueDetails,
  fieldNames.featurePrintOrder
];
export function isFeatureField(field: string) {
  return Boolean(field) && featureFields.includes(field.split('__')[0]);
}

const priceFields = [
  fieldNames.startDate,
  fieldNames.startTime,
  fieldNames.endDate,
  fieldNames.endTime,
  fieldNames.isDailyPrice,
  fieldNames.priceType,
  fieldNames.priceAmount,
  fieldNames.priceFactor,
  fieldNames.priceCurrency,
  fieldNames.lowerBound,
  fieldNames.tax,
  fieldNames.territory,
  fieldNames.costOfLivingAdjustment,
  fieldNames.isDiscontinued,
  fieldNames.discontinuedDate
];
export function isPriceField(field: string) {
  return Boolean(field) && priceFields.includes(field.split('__')[0]);
}

const legalFields = [
  fieldNames.isBiocidalProduct,
  fieldNames.isCeGs,
  fieldNames.isSelfServiceForbidden,
  fieldNames.isWeeeRohsEar,
  fieldNames.isFertigpackv,
  fieldNames.isReach,
  fieldNames.isOekodesignEvpg,
  fieldNames.isVerpackungsv,
  fieldNames.isCommoditiesControll,
  fieldNames.isSecurityDatasheetNeeded,
  fieldNames.isDetergenzienv,
  fieldNames.isDualUse,
  fieldNames.isKosmetikv,
  fieldNames.isBatterieV,
  fieldNames.warrantyClass,
  fieldNames.hazmatClass,
];
export function isLegalField(field: string) {
  return Boolean(field) && legalFields.includes(field.split('__')[0]);
}

const miscFields = [
  fieldNames.descriptionLongWithoutHtml,
  fieldNames.group,
  fieldNames.master,
  fieldNames.featureList,
  fieldNames.link
];
export function isMiscField(field: string) {
  return Boolean(field) && miscFields.includes(field.split('__')[0]);
}

export function isReferenceField(field: string) {
  field = field?.split('__')?.[0];
  return fieldNames.productReferencesType === field || fieldNames.productReferencesSpid === field;
}

export function isSeoField(field: string) {
  field = field?.split('__')?.[0];
  return fieldNames.metaTitel === field || fieldNames.metaDescription === field;
}

const supplierFields = [
  fieldNames.packingUnitCode,
  fieldNames.packingUnitQuantityMax,
  fieldNames.packingUnitQuantityMin,
  fieldNames.packingUnitAmountContentUnit,
  fieldNames.supplierId,
  fieldNames.supplierName,
  fieldNames.supplierDeliveryTime,
  fieldNames.supplierPrice,
  fieldNames.supplierValidFrom,
  fieldNames.supplierValidUntil,
  fieldNames.mainSupplierId
]
export function isSupplierField(field: string) {
  return Boolean(field) && supplierFields.includes(field.split('__')[0]);
}

const udxFields = [fieldNames.udxCategory, fieldNames.udxName, fieldNames.udxValue];
export function isUdxField(field: string) {
  return Boolean(field) && udxFields.includes(field.split('__')[0]);
}

// export function isWawiField(field: string, settings: WawiSettings) {
//   return settings.hasLine1 && field === fieldNames.line1 ||
//     settings.hasLine2 && field === fieldNames.line2 ||
//     settings.hasLine3 && field === fieldNames.line3 ||
//     settings.hasLine4 && field === fieldNames.line4 ||
//     settings.customFields.some(x => x.name === field);
// }

export function isESalesField(field: string) {
  return Boolean(field) && field.startsWith('ELECTRONIC_SALES_');
}

export function isNextPimField(field: string) {
  return Boolean(field) && excelFieldNames.includes(field.split('__')[0]);
}
