import { CustomWawiField } from "./CustomWawiField";

export class WawiIntegration {

  constructor(
    public wawiId?: string,
    public line1?: string,
    public line2?: string,
    public line3?: string,
    public line4?: string,
    public customFields?: CustomWawiField[],


  ) {
  }
}
