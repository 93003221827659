
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginService } from './login.service';
import { CatalogState } from '../Model/Catalog/CatalogState';


@Injectable()
export class CatalogStateService {


  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getStatus(): Observable<CatalogState[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.get<CatalogState>("api/CatalogState/GetCatalogStates", options)) as any;
  }

  catalogState : string[] = new Array<string>();

  deleteCatalogState(id: string) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", id)
      }

      this.http.delete("api/CatalogState/DeleteCatalogState", options)
        .subscribe();
    }
  }

  updateCatalogState(state: CatalogState): Observable<string> {
    return (this.http.post("api/CatalogState/UpdateCatalogState", state)) as any;
  }

  addCatalogState(state: CatalogState): Observable<CatalogState> {
    return (this.http.post("api/CatalogState/AddCatalogState", state)) as any;
  }



}
