export class SendToNWConfig {

  constructor(
    public id?: number,
    public customerId?: string,
    public ftpServer?: string,
    public ftpFolder?: string,
    public ftpUser?: string,
    public password?: string,
    public isTested?: boolean
  ) {
  }
}
