<div class="form">
  <dx-progress-bar #progressBar
                   id="progress-bar-status"
                   width="100%"
                   [ngClass]="progressColor"
                   [min]="0"
                   [max]="100"
                   [value]="progressValue"
                   >
  </dx-progress-bar>

  <div class="progress-info" [ngStyle]="{ color: progressColor }">
    {{ progressText }}
  </div>
</div>
