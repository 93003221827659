import { Shopware5FeatureAttribute } from "../Shopware5FeatureAttribute";

export class ShopWare5Connection {

  constructor(
    public baseUri?: string,
    public shopId?: number,
    public rootCatId?: number,
    public rootCatName?: string,
    public user?: string,
    public apiKey?: string,
    public onlyCreatePrices?:boolean,
    public topFeatureAttribute? : string,
    public benefitsAttribute?: string,
    public featureAttributes?: Shopware5FeatureAttribute[]
  ) {

  }
}

