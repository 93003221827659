
  <dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="p-2">

    <div *dxTemplate="let company of 'testTemplate'">

      <dx-toolbar>
        <dxi-item *ngIf="!readOnlyUser('mavisSend')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog senden' | translate, onClick: sendData2ShopZero }"></dxi-item>
      </dx-toolbar>

      <div class="dx-fieldset" *ngIf="model">


        <div class="dx-field">
          <div class="dx-field-label" (mouseenter)="ttt('lastSend')" (mouseleave)="ttt('lastSend')">{{ 'Letzte Übertragung' | translate }}</div>
          <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSend">
            <label class="placeLabel {{ lastSend.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
            <dx-text-box [(value)]="lastSend" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('mavisLastSend')">
            </dx-text-box>

          </div>
          <dx-tooltip target="#ttlastSend" class="toolTip toolTipField" [(visible)]="v['productRange']">
            <div *dxTemplate="let data = data of 'content'">
              {{ 'Datum der letzten Übertragung zu Mavis' | translate }}.
            </div>
          </dx-tooltip>
        </div>

        <div class="dx-field">
          <div class="dx-field-label" (mouseenter)="ttt('mavisLanguage')" (mouseleave)="ttt('mavisLanguage')">{{ 'Mavis Sprache' | translate }}</div>
          <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="mavisLanguage">
            <dx-select-box [dataSource]="this.modelService.mavisConnectionService.languages"
                           displayExpr="name"
                           valueExpr="number"
                           [value]="model.mavisMapping.language"
                           [readOnly]="readOnlyUser('mavisLanguage')"
                           (onValueChanged)="catalogChanged($event)"></dx-select-box>

          </div>
          <dx-tooltip target="#mavisLanguage" class="toolTip toolTipField" [(visible)]="v['productRange']">
            <div *dxTemplate="let data = data of 'content'">
              {{ 'Sprache für Mavis' | translate }}.
            </div>
          </dx-tooltip>
        </div>

        <dx-popup title="{{ 'Katalog senden' | translate }}?"
                  [(visible)]="isSendCatalogPopupVisible"
                  [width]="350"
                  [height]="250"
                  [showTitle]="true">
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Daten senden' | translate, onClick: sendData2ShopOne }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: sendData2ShopTwo }"></dxi-toolbar-item>
          <div *dxTemplate="let data of 'content'">
            <p>{{ 'Soll der Katalog zum Testsystem übertragen werden' | translate }}?</p>
          </div>
        </dx-popup>

      </div>
    </div>

    <div *dxTemplate="let company of 'liveTemplate'">

      <dx-toolbar>
        <dxi-item *ngIf="!readOnlyUser('mavisSend')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog senden' | translate, onClick: sendData2ShopThree }"></dxi-item>
      </dx-toolbar>

      <div class="dx-fieldset" *ngIf="model">


        <div class="dx-field">
          <div class="dx-field-label" (mouseenter)="ttt('lastSend')" (mouseleave)="ttt('lastSend')">{{ 'Letzte Übertragung' | translate }}</div>
          <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ lastSendLive.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
            <dx-text-box [(value)]="lastSendLive" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('mavisLastSend')">
            </dx-text-box>

          </div>
          <dx-tooltip target="#ttlastSend" class="toolTip toolTipField" [(visible)]="v['productRange']">
            <div *dxTemplate="let data = data of 'content'">
              {{ 'Datum der letzten Übertragung zu Mavis' | translate }}.
            </div>
          </dx-tooltip>
        </div>

        <div class="dx-field">
          <div class="dx-field-label" (mouseenter)="ttt('mavisLanguageLive')" (mouseleave)="ttt('mavisLanguage')">{{ 'Mavis Sprache' | translate }}</div>
          <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <dx-select-box [dataSource]="this.modelService.mavisConnectionService.languagesLive"
                           displayExpr="name"
                           valueExpr="number"
                           [value]="model.mavisMapping.languageLive"
                           (onValueChanged)="catalogChanged($event)"
                           [readOnly]="readOnlyUser('mavisLanguage')"></dx-select-box>

          </div>
          <dx-tooltip target="#mavisLanguage" class="toolTip toolTipField" [(visible)]="v['productRange']">
            <div *dxTemplate="let data = data of 'content'">
              {{ 'Sprache für Mavis' | translate }}.
            </div>
          </dx-tooltip>
        </div>

        <dx-popup title="{{ 'Katalog senden' | translate }}?"
                  [(visible)]="isSendCatalogPopupLiveVisible"
                  [width]="350"
                  [height]="250"
                  [showTitle]="true">
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Daten senden' | translate, onClick: sendData2ShopFour }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: sendData2ShopFive }"></dxi-toolbar-item>
          <div *dxTemplate="let data of 'content'">
            <p>{{ 'Soll der Katalog zum Livesystem übertragen werden' | translate }}?</p>
          </div>
        </dx-popup>

      </div>
    </div>
</dx-tab-panel>
