import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";


@Component({
  selector: "userAction-setWawiSupplierPids",
  templateUrl: "userAction-setWawiSupplierPids.component.html",
  styleUrls: ["userAction-setWawiSupplierPids.component.css"]
})
export class UserActionSetWawiSupplierPidsComponent extends UserActionBaseComponent {

  fillAlt: boolean = false;
  supplierIsManufacturer: boolean = false;
  newProductStatus: string = "";


  constructor(public modelService: ModelService) {
    super(modelService);
  }

  catalogHasSupplier(): boolean {
    let value = this.modelService.catalogService.catalog.supplier != null &&
      this.modelService.catalogService.catalog.supplier.supplierName != null && this.modelService.catalogService.catalog.supplier.supplierName != "" &&
      this.modelService.catalogService.catalog.supplier.supplierId != null && this.modelService.catalogService.catalog.supplier.supplierId != "";
    return value;
  }

  startMacro() {

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = `SetWawiSupplierPids`;
    startAction.actionParameter = JSON.stringify({ FillAlt: this.fillAlt, SupplierIsManufacturer: this.supplierIsManufacturer, NewProductStatus: this.newProductStatus });

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
