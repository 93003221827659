import { Component, OnInit, ViewChild } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ClassificationClassTree } from "../Model/classification/ClassificationClassTree";
import { DxTreeListComponent } from "devextreme-angular";
import {StartAction } from"../Model/Dto/StartAction";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "classificationTree",
  templateUrl: "classificationTree.component.html",
  styleUrls: ["./classificationTree.component.css"]
})
export class ClassificationTreeComponent implements OnInit {

  @ViewChild(DxTreeListComponent, { static: true })
  treeList: DxTreeListComponent;

  constructor(public modelService: ModelService, public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.refresh();
  }

  model: ClassificationClassTree[];

  refresh() {
    this.modelService.classificationService.getTree(this.modelService.classificationService.selectedSystem.id).subscribe((classificationClassTree: ClassificationClassTree[]) => {
      this.model = classificationClassTree;
    });
  }


  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'createCatalogTemplate'
    });



  }

  onCreateCatalog() {
    let startAction = new StartAction();
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `CreateClassificationCatalog(${this.modelService.classificationService.selectedSystem.id})`;

    this.modelService.jobService.startJob(startAction)
      .subscribe(next => {

        this.translate.get("Der Katalog wird per Job erzeugt").subscribe((text: string) => {
 
          this.modelService.systemService.notifyInfo(text);
        });

        

      });
  }


  onClassTreeChanged(e) {
    this.modelService.classificationService.updateClassTree(e.data).subscribe(
      (result: ClassificationClassTree) => {

      });
  }

  onClassTreeSelectionChanged(e) {
    let selected = e.currentSelectedRowKeys;
    if (selected)
      this.modelService.classificationService.selectedClassId = selected[0];
    else
      this.modelService.classificationService.selectedClassId  = "";
  }


  onClassTreeRemoved(e) {
    this.modelService.classificationService.deleteClassTree(e.data);
  }
  onClassTreeAdded(e) {
    let kat = new ClassificationClassTree();
    kat.name= e.data.name;
    kat.parentId = e.data.parentId;
    kat.classificationSystemId = this.modelService.classificationService.selectedSystem.id;
    kat.number = e.data.number;
    this.modelService.classificationService.updateClassTree(kat).subscribe(
      (result: ClassificationClassTree) => {
        e.data.id = result.id;
      });
  }
}
