import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent}  from "../userAction-base.component";

@Component({
  selector: "userAction-deleteEmptyFeatures",
  templateUrl: "userAction-deleteEmptyFeatures.component.html"
})
export class UserActionDeleteEmptyFeaturesComponent extends UserActionBaseComponent  {
  deleteFeatureSystems: boolean = false;

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "DeleteEmptyFeatures";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify(this.deleteFeatureSystems);
  }


}
