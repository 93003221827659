import { ValidationRule } from "./ValidationRule";

export class ValidationGroup {

  constructor(
    public id?: string,
    public name?: string,
    public order?: number,
    public backgroundColor?: string,
    public stateText?: string,
    public ignoreForReport?: boolean,
    public rules?: ValidationRule[],
    public productCount?: number
  ) {
  }
}


