import { AfterViewInit, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ContentChildren } from "@angular/core";
import { QueryList } from "@angular/core";
import { Directive } from "@angular/core";
import { Component } from "@angular/core";
import dxButton from "devextreme/ui/button";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import dxDropDownButton from "devextreme/ui/drop_down_button";

@Directive({
  selector: 'np-help-option'
})
export class NpHelpOption {
  @Input() public icon: string;
  @Input() public text: string;
  @Input() public link: string;
}

@Component({
  selector: "np-help-button",
  templateUrl: "NpHelpButton.component.html"
})
export class NpHelpButtonComponent{
  options: any[] = [];
  icon: string = "help";
  buttons: QueryList<NpHelpOption>;

  constructor() {
    this.openHelpLink = this.openHelpLink.bind(this);
  }

  openHelpLink(link) {
    window.open(link.itemData.link, '_blank').focus();
  }

  @ContentChildren(NpHelpOption, { descendants: true })
  public set contentButtons(buttons: QueryList<NpHelpOption>) {
    this.buttons = buttons;
    this.options.push(...this.buttons?.map(b => { return { icon: b.icon, text: b.text, link: b.link } }));
    
  }

}
