import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Shopware5FeatureAttribute } from "../../../../Model/Shopware5FeatureAttribute";
import { ModelService } from "../../../../Services/model.service";


@Component({
  selector: "shopware5FeatureSystemList",
  templateUrl: "shopware5FeatureSystemList.component.html",
  styleUrls: []
})
export class Shopware5FeatureSystemList {
  @Input() featureAttributes: Shopware5FeatureAttribute[];


  lookupData: any = [];

  constructor(modelService: ModelService) {
    modelService.catalogService.getFeatureNames(modelService.catalogService.catalog.id, modelService.loginService.currentCustomer.id).subscribe((result) => {
      if (result) {
        this.lookupData = result;
      }
    });
  }

  get data() {
    if (this.featureAttributes != null)
      return this.featureAttributes;
    return [];
  }
}
