import { Input, Output, EventEmitter, Component, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';
import { FeatureSystem } from '../../../../Model/Catalog/FeatureModels/FeatureSystem';
import { ModelService } from '../../../../Services/model.service';
import { ViewService } from '../../../../Services/view.service';
import { Feature } from '../../../../Model/Catalog/FeatureModels/Feature';
import { FeatureValue } from '../../../../Model/classification/FeatureValue';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoginService } from '../../../../Services/login.service';
import { Message } from '../../../../Model/System/Message';
import { custom } from 'devextreme/ui/dialog';
import { Product } from '../../../../Model/Catalog/Product';
import { TemplateService } from '../../../../Services/template.service';
import { UserManagementService } from '../../../../Services/userManagment.service';
import { CatalogService } from '../../../../Services/CatalogManagement/catalog.service';
import { ValidationService } from '../../../../Services/Validation/validation.service';
import { ValidFeatureService } from '../../../../Services/validFeature.service';
import { ValidFeature } from '../../../../Model/ValidFeature';
import { lastValueFrom } from 'rxjs';
import { ProductService } from '../../../../Services/CatalogManagement/product.service';

@Component({
  selector: 'features',
  templateUrl: 'features.component.html',
  styleUrls: ['../../template.css']
})
export class EditFeatureValueList implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  @Input() displayMode: 'edit' | 'import' | 'export';
  @Input() featureSystem: FeatureSystem = null;
  @Input() hasAddonCLASSIFICATION = false;
  @Input() modelService: ModelService;
  @Input() model: Product = null;
  @Input() selectedElements: string[] = [''];
  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';
  @Input() templateView: boolean = false;

  @Output() onValueChanged = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();
  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  @Input() showPlaceHolders = true;
  @Input() showLinkButtons: Boolean = false;

  updateLock: boolean = false;
  updateFrontendLinkedFields: boolean = false;
  private validFeatures: ValidFeature[];
  private featuresDataSource: DataSource;

  csCardFilters = [
    { key: 'S', name: 'Select Box: Text' },
    { key: 'E', name: 'Select Box: Brand/Manufacturer' },
    { key: 'T', name: 'Others: Text' }
  ];

  constructor(
    public templateService: TemplateService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public loginService: LoginService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService,
    public validFeatureService: ValidFeatureService,
    public productService: ProductService
  ) {
    this.addCustomFeature = this.addCustomFeature.bind(this);
  }

  ngOnInit(): void {
    this.requestFeatureValues();
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  async requestFeatureValues(reload = true) {
    if (reload)
      this.validFeatures = await lastValueFrom(
        this.validFeatureService.getFeatures(
          this.featureSystem.validFeatureSystemId,
          this.loginService.currentCustomer.id
        )
      );

    this.featuresDataSource = new DataSource({
      store: this.validFeatures,
      paginate: true,
      pageSize: 10
    });
  }

  async setFeatureValidId(feature: Feature) {
    if (!this.validFeatures) return;
    if (!this.featureIsValid(feature)) {
      feature.validFeatureId = undefined;
      return;
    }

    const featureValue = this.validFeatures.find((x) => x.name === feature.name && x.unit == feature.funit);
    if (featureValue) {
      feature.validFeatureId = featureValue.id;
    } else {
      let result = await this.validFeatureService.getValidIdForFeature(
        feature.name,
        feature.funit,
        this.featureSystem.validFeatureSystemId,
        this.loginService.currentCustomer.id
      );
      feature.validFeatureId = result;
    }
    if (!this.templateView) {
      feature.updateLinkedFields = true;
      this.updateFrontendLinkedFields = true;
    }
  }

  async updateFeatureValue(event, feature: Feature) {
    if (event && event.toggleLinkedFields) {
      this.onValueChanged.emit();
      return;
    }

    if (ViewService.isSingleValue(this.featureSystem.referenceFeatureSystemName)) {
      feature.name = feature.value;
      feature.funit = '';
      let previousValue = event.value;
      await this.updateFeatureName(event, feature);
      event.value = previousValue;
    }
    this.update(event, feature, 'FVALUE', feature.validFeatureId);
  }

  async updateFeatureName(event, feature: Feature) {
    if (event.toggleLinkedFields) {
      this.onValueChanged.emit();
      return;
    }

    if (!event.event) return;

    if (event && event.removeFromTemplateItem) {
      feature = this.featureSystem.features.find((x) => x.validFeatureId == event.dElement);
    } else if (!feature) {
      this.onValueChanged.emit();
      return;
    }
    if (event) {
      let validFeature = this.validFeatures.find((x) => x.id == event.value);
      if (validFeature) {
        feature.funit = validFeature.unit;
        feature.name = validFeature.name;
      }
    }

    if (feature.validFeatureId == 'temp id') {
      feature.validFeatureId = feature.oldValidFeatureId;
      feature.oldValidFeatureId = null;
    }

    await this.setFeatureValidId(feature);
    if (event) {
      event.value = feature.validFeatureId;
      if (event.removeFromTemplateItem) {
        //event.previousValue =
      }
    }

    this.update(event, feature, 'FNAME', feature.validFeatureId);
  }

  async updateFeatureUnit(event, feature: Feature) {
    if (event && event.removeFromTemplateItem) {
      feature = this.featureSystem.features.find((x) => x.validFeatureId == event.dElement);
    } else if (!feature) {
      this.onValueChanged.emit();
      return;
    }
    event.previousValue = feature.validFeatureId;

    await this.setFeatureValidId(feature);
    if (event) event.value = feature.validFeatureId;

    this.update(event, feature, 'FUNIT', feature.validFeatureId);
  }

  addCustomFeature(e, feature: Feature) {
    if (!e.text) {
      e.customItem = null;
      return;
    }
    e.customItem = new ValidFeature('temp id', this.featureSystem.validFeatureSystemId, e.text, feature.funit ?? '');
    feature.name = e.text;
    feature.oldValidFeatureId = feature.validFeatureId;
  }

  featureDisplayExpr(item: ValidFeature): string {
    if (item === null || item === undefined) return '';

    return item.name;
  }

  onFeaturesToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addFeatureButtonTemplate'
    });
  }

  get addFeatureButtonEnabled(): boolean {
    return (
      (!ViewService.isSingleLine(this.featureSystem.referenceFeatureSystemName) ||
        this.featureSystem.features.length === 0) &&
      !this.modelService.classificationService.isClassificationSystem(this.featureSystem.referenceFeatureSystemName)
    );
  }

  async addFeatureButtonClicked() {
    const feature = new Feature();
    feature.forder =
      (this.featureSystem?.features?.reduce((maxOrder, f) => Math.max(maxOrder, f.forder ?? 0), 0) ?? 0) + 1;
    feature.featureValues = new Array<FeatureValue>();
    this.featureSystem.features.push(feature);

    this.update();
    this.updateLock = false;
  }

  async onContentReady() {
    this.updateLock = false;
    if (this.featureSystem.features.some((x) => !this.validFeatures?.some((y) => y.id == x.validFeatureId))) {
      this.requestFeatureValues(true);
    }
    if (this.updateFrontendLinkedFields) {
      this.updateFrontendLinkedFields = false;
      this.model.linkedFields = await lastValueFrom(
        this.productService.getLinkedFields(this.model.id, this.loginService.currentCustomer.id)
      );
    }
  }

  update(event = null, data?, field?: string, element: string = '-1') {
    this.dataGrid.instance.refresh(true);

    // für den template editor erforderlich:
    if (event && data) {
      event.dField = field;
      if (this.featureSystem.validFeatureSystemId || !this.templateView) {
        event.dSystem = this.featureSystem.validFeatureSystemId;
      } else {
        event.dSystem = this.featureSystem.order.toString();
      }
      if (element != '-1' || !this.templateView) {
        event.dElement = element;
      } else {
        event.dElement = data.forder.toString();
      }
      event.createTemplateItem = !event.removeFromTemplateItem;
    }

    if (data == undefined || this.featureIsValid(data)) {
      this.onValueChanged.emit(event);
    }
  }

  delete(event) {
    let product = this.modelService.catalogService.product;
    let system;
    let element;
    if (this.featureSystem.validFeatureSystemId) {
      system = this.featureSystem.validFeatureSystemId;
    } else {
      system = this.featureSystem.order.toString();
    }
    if (event.data.validFeatureId) {
      element = event.data.validFeatureId;
    } else {
      element = event.data.forder.toString();
    }

    for (let featureField of this.templateService.allFeatureFields) {
      if (product != null && product.isFieldLinked(featureField, system, element)) {
        product.toggleLinkedField(featureField, system, element);
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    if (event.dFields == undefined) {
      event.dFields = this.templateService.allFeatureFields;
      event.dDetailFields = null;
      event.dSystem = system;
      event.dElement = element;
    }
    this.elementDeleted.emit(event);
  }

  public onRowRemoving(e) {
    let product = this.modelService.catalogService.product;
    let element;
    if (e.data.validFeatureId) {
      element = e.data.validFeatureId;
    } else {
      element = e.data.forder.toString();
    }

    if (product != null && product.isChild && this.hasFeatureFieldLinked(element)) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('DeleteLinkedFieldNotAllowed'), 'info'),
        3000
      );
      e.cancel = true;
      return;
    }

    let myDialog = custom({
      title: this.translate.instant('Wirklich löschen?'),
      messageHtml: this.translate.instant('Wollen Sie das Feature wirlich löschen'),
      buttons: [
        {
          text: this.translate.instant('Ja'),
          onClick: (e) => {
            return false;
          }
        },
        {
          text: this.translate.instant('Nein'),
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    e.cancel = myDialog.show();
  }

  get exportMode(): boolean {
    return this.displayMode === 'export';
  }

  get featuresEditable(): boolean {
    return true;
  }

  get featureNameEditable(): boolean {
    return this.featuresEditable && !ViewService.isMagicSystem(this.featureSystem.referenceFeatureSystemName);
  }

  get featureDescriptionEditable(): boolean {
    return this.featuresEditable && !ViewService.isMagicSystem(this.featureSystem.referenceFeatureSystemName);
  }

  get featuresDeletable(): boolean {
    return this.featuresEditable && !ViewService.isMagicSystem(this.featureSystem.referenceFeatureSystemName);
  }

  get profiModeEnabled(): boolean {
    return this.modelService.loginService.currentUser.featureProfiMode;
  }

  get featureNameVisible(): boolean {
    return !ViewService.isSingleValue(this.featureSystem.referenceFeatureSystemName);
  }

  get featureDescriptionVisible(): boolean {
    return this.profiModeEnabled && !ViewService.isSingleValue(this.featureSystem.referenceFeatureSystemName);
  }

  get isVariantVisible(): boolean {
    return (
      this.featureSystem.referenceFeatureSystemName == 'udf_NMTECHNICALDETAILS-1.0' ||
      this.featureSystem.referenceFeatureSystemName == 'Safework'
    );
  }

  get featureValueVisible(): boolean {
    return true;
  }

  get featureValueDetailVisible(): boolean {
    return (
      this.profiModeEnabled &&
      !ViewService.isSingleValue(this.featureSystem.referenceFeatureSystemName) &&
      !ViewService.isMagicSystem(this.featureSystem.referenceFeatureSystemName)
    );
  }

  get featureUnitVisible(): boolean {
    return (
      !ViewService.isSingleValue(this.featureSystem.referenceFeatureSystemName) &&
      !ViewService.isMagicSystem(this.featureSystem.referenceFeatureSystemName)
    );
  }

  get isPrintVisible(): boolean {
    return this.loginService.hasAddonPRINT;
  }

  get templateSelectionEnabled(): boolean {
    return this.displayMode === 'import' || this.displayMode === 'export';
  }

  templateSelect(identifier: string, order?: number) {
    if (order) {
      this.elementSelected.emit(`${identifier}_${this.featureSystem.order}_${order}`);
    } else {
      this.elementSelected.emit(`${identifier}_${this.featureSystem.order}`);
    }
  }

  excelElementDrop(e, feature: Feature) {
    if (e.newField != 'FNAME' && !this.featureIsValid(feature)) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('FeatureValuesCannotBeDropped'), 'error'),
        3000
      );
      return;
    }
    this.excelElementDropped.emit(e);
  }

  excelFeatureNameDroppedError() {
    this.modelService.systemService.notify(
      new Message(this.translate.instant('FeatureNameDroppedError'), 'error'),
      3000
    );
  }

  excelFeatureUnitDroppedError() {
    this.modelService.systemService.notify(
      new Message(this.translate.instant('FeatureUnitDroppedError'), 'error'),
      3000
    );
  }

  public hasFeatureFieldLinked(element?: string): boolean {
    let product = this.modelService.catalogService.product;
    let system = this.featureSystem.validFeatureSystemId;

    for (let featureSystemField of this.templateService.allFeatureFields) {
      if (product != null && product.isFieldLinked(featureSystemField, system, element)) {
        return true;
      }
    }
    return false;
  }

  public readOnlyForLinkedFields(field: string, element?: string): boolean {
    let product = this.modelService.catalogService.product;
    let system = this.featureSystem.validFeatureSystemId;

    if (product != null && field != null) {
      var isLinked = product.isFieldLinked(field, system, element);
      if (isLinked && product.isChild != null && product.isChild) {
        return true;
      }
    }

    return false;
  }

  public showClearButton(field: string, element: string): boolean {
    return !this.readOnlyForLinkedFields(field, element) && !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null) {
      return product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, element?: string): boolean {
    let product = this.modelService.catalogService.product;
    let system = this.featureSystem.validFeatureSystemId;
    if (product != null) {
      return product.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(field: string, element?: string, event = null) {
    let product = this.modelService.catalogService.product;
    let system = this.featureSystem.validFeatureSystemId;

    var relevantElements = [element];
    if (field == this.templateService.fieldNames.fName || field == this.templateService.fieldNames.fUnit) {
      relevantElements = [this.templateService.fieldNames.fName, this.templateService.fieldNames.fUnit];
    }

    var changed = false;
    for (let relevantElement of relevantElements) {
      changed = product.toggleLinkedField(field, system, relevantElement) || changed;
    }

    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, element);
    event.target.className = product.getClassForLinkedField(isNowLinked);

    if (product.isChild && isNowLinked) {
      event.forceReload = true;
    }

    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, element?: string): string {
    let product = this.modelService.catalogService.product;
    var isNowLinked = this.isFieldLinked(field, element);
    return product.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  readOnlyFNameTemplateEditor(f: Feature) {
    return this.templateView && !this.featureSystemIsValid();
  }

  readOnlyTemplateEditor(f: Feature) {
    return this.templateView && (!this.featureSystemIsValid() || !this.featureIsValid(f));
  }

  featureSystemIsValid() {
    return this.featureSystem.referenceFeatureSystemName != undefined;
  }

  featureIsValid(feature: Feature) {
    if (!this.featureNameVisible) return this.featureSystemIsValid();

    if (!feature) return false;

    return this.featureSystemIsValid() && feature.name != undefined;
  }

  getRelevantElementKey(feature: Feature) {
    if (feature.validFeatureId) {
      return feature.validFeatureId;
    }
    return feature.forder;
  }

  getRelevantSystemKey() {
    if (this.featureSystem.validFeatureSystemId) {
      return this.featureSystem.validFeatureSystemId;
    }
    return this.featureSystem.order;
  }

  get hasAddonCSCard() {
    return this.loginService.hasAddonCsCard;
  }
}
