<div class="dx-fieldset">
  <dx-pie-chart style="margin-top:20px;"
                type="doughnut"
                [startAngle]="90"
                title="Produktkategorien"
                [resolveLabelOverlapping]="'shift'"
                [palette]="colorPalette"
                [dataSource]="dataSource">
    <dxi-series argumentField="category" valueField="productCount">
      <dxo-label [visible]="true"
                 [customizeText]="customizeLabel">
        <dxo-connector [visible]="true"></dxo-connector>
      </dxo-label>
    </dxi-series>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-legend [visible]="false"></dxo-legend>
    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeDataQualityPieTooltip"></dxo-tooltip>
  </dx-pie-chart>
</div>
