import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShopWare5Connection } from "../../../Model/Catalog/Shopware5Connection";
import { ShopWare5ShopDto } from "../../../Model/Dto/Shopware5ShopDto";
import { ModelService } from "../../../Services/model.service";
import { Shopware5Service } from "../../../Services/shopware5.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { TreeItem as TreeElement } from "../../../Model/Dto/TreeItem";
import { DxDropDownBoxComponent, DxTreeViewComponent } from "devextreme-angular";
import { OdooConnection } from "../../../Model/Catalog/OdooConnection";
import { OdooService } from "../../../Services/odoo.service";


@Component({
  selector: "odoo",
  templateUrl: "catalogOdoo.component.html",
  styleUrls: []
})
export class CatalogOdooComponent implements OnInit {
  @ViewChild(DxTreeViewComponent) treeView;
  public categories: any;

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService, public odooService: OdooService) {
    this.sendCatalog = this.sendCatalog.bind(this);
    this.testConnection = this.testConnection.bind(this);
    this.valueChanged = this.valueChanged.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  ngOnInit(): void {
    this.getCategories(false);
  }

  valueChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  public getCategories(sendMessage = true) {
    this.odooService.getCategories(this.model.baseUri, this.model.database, this.model.user, this.model.apiKey).subscribe((result: TreeElement[]) => {
      if (sendMessage)
        this.modelService.systemService.notifyInfo(result.length.toString() + this.translate.instant(" Kategorien wurden gefunden"));
      this.categories = result;
    }, error => {
      if (sendMessage)
        this.modelService.systemService.notifyWarning(error.error);
    });
  }

  treeView_itemSelectionChanged(e) {
    this.model.catalogCategoryId = e.itemData.id;
    this.modelService.catalogService.updateCatalog();
  }

  syncTreeViewSelection() {
    if (!this.treeView) return;

    if (!this.model.catalogCategoryId) {
      this.treeView.instance.unselectAll();
    } else {
      this.treeView.instance.selectItem(this.model.catalogCategoryId);
    }
  }

  get model(): OdooConnection {
    if (!this.modelService.catalogService.catalog.odooConnection) {
      this.modelService.catalogService.catalog.odooConnection = new OdooConnection();
      this.modelService.catalogService.catalog.odooConnection.baseUri = "";
      this.modelService.catalogService.catalog.odooConnection.apiKey = "";
      this.modelService.catalogService.catalog.odooConnection.user = "";
      this.modelService.catalogService.catalog.odooConnection.database = "";
    }
    return this.modelService.catalogService.catalog.odooConnection;
  }

  public testConnection() {
    this.odooService.testConnection(this.modelService.catalogService.catalog.id, this.modelService.catalogService.catalog.customerId).subscribe((result) => {
      this.modelService.systemService.notifyInfo("Erfolgreich angemeldet");
    }, error => {
      this.modelService.systemService.notifyWarning(error.error.detail);
    });
  }

  public sendCatalog() {
    this.odooService.startExport(this.modelService.catalogService.catalog.id, this.modelService.catalogService.catalog.customerId).subscribe((result) => {
      this.modelService.systemService.notifyInfo("Export wurde erstellt");
    }, error => {
      this.modelService.systemService.notifyWarning(error.error);
    });
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
