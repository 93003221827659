<div>
  <!--  <dx-button stylingMode="text" type="default" icon="{{icon}}" (onClick)="helpClick($event)"></dx-button>
    <dx-action-sheet [items]="helpButtons"
                     [usePopover]="true"
                     [showTitle]="false"
                     [(visible)]="actionSheetVisible"
                     [target]="actionSheetTarget"
                     (onItemClick)="openHelpLink($event.itemData.link)"
                     width="auto"
                     height="auto"></dx-action-sheet>-->

  <dx-drop-down-button #dropDownButton
                       [items]="options"
                       icon="help"
                       displayExpr="text"
                       keyExpr="text"
                       [showArrowIcon]="false"
                       stylingMode="text"
                       [useSelectMode]="false"
                       wrapItemText="false"
                       [dropDownOptions]="{ width: '500%' }"
                       style="color: #0091d4 !important;"
                       (onItemClick)="openHelpLink($event)"></dx-drop-down-button>
</div>

