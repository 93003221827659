import { Input, Output, EventEmitter, Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../../Model/Catalog/Product';
import { ViewService } from '../../../Services/view.service';
import { Reference } from '../../../Model/Catalog/Reference';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../../Services/model.service';
import { TemplateService } from '../../../Services/template.service';
import { UserManagementService } from '../../../Services/userManagment.service';
import { CatalogService } from '../../../Services/CatalogManagement/catalog.service';
import { ValidationService } from '../../../Services/Validation/validation.service';
import CustomStore from 'devextreme/data/custom_store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginService } from '../../../Services/login.service';
import { RowPreparedEvent, RowRemovedEvent } from 'devextreme/ui/data_grid';
import {} from 'devextreme/ui/accordion';
import { ProductService } from '../../../Services/CatalogManagement/product.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'np-product-references',
  templateUrl: 'product-references.component.html',
  styleUrls: ['./product-references.component.css']
})
export class References implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true })
  dataGrid: DxDataGridComponent;

  _model: Product = new Product();
  @Input() set model(value: Product) {
    this._model = value;
    this.initializeReferencesByType();
  }
  get model(): Product {
    return this._model;
  }

  @Input() showToolTips: Boolean = false;
  @Input() showProToolTips: Boolean = false;
  @Input() showPlaceHolders: Boolean = true;
  @Input() showLinkButtons: Boolean = false;
  @Input() lastImageUpload: Date = new Date();
  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [''];
  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() elementSelected = new EventEmitter<string>();
  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();
  @Output() onValueChanged = new EventEmitter<any>();

  private referencesByType: { [key: string]: Reference[] } = {};
  addVisible: boolean = false;
  typeToAdd: string = '';
  productStore: CustomStore;
  selectedNewProduct;
  productSelectBoxData;

  constructor(
    public templateService: TemplateService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService,
    public authService: OAuthService,
    public loginService: LoginService,
    public productService: ProductService
  ) {
    this.showAddReference = this.showAddReference.bind(this);

    var catalogId = this.catalogService.selectedCatalogId;
    var accessToken = this.authService.getAccessToken();
    this.productSelectBoxData = new DataSource({
      store: createStore({
        key: 'SupplierPid',
        loadUrl: 'api/product/remoteReferences',
        loadParams: { catalogId: catalogId, customerId: this.loginService.currentCustomer.id },
        onBeforeSend(method, ajaxOptions) {
          (ajaxOptions.xhrFields = { withCredentials: true }),
            (ajaxOptions.headers = {
              Authorization: `Bearer ${accessToken}`
            });
        }
      }),
      paginate: true,
      pageSize: 10
    });
  }

  ngOnInit(): void {}

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  async onReferencePrepared(event: RowPreparedEvent<Reference>) {
    if (event.data.artIdTo == null || (event.data.normUri && event.data.descriptionShort)) return;
    let result: { descriptionShort; normUri } = await this.productService
      .getReferenceDataBySupplierPid(
        event.data.artIdTo,
        this.loginService.currentUser.customerId,
        this.catalogService.catalog.id
      )
      .catch();
    event.data.descriptionShort = result.descriptionShort;
    event.data.normUri = result.normUri;
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
    this.initializeReferencesByType();
  }

  showAddReference(e, type: string) {
    e.event.stopPropagation();
    this.typeToAdd = type;
    this.selectedNewProduct = null;
    this.addVisible = true;
  }

  addReference() {
    var reference = new Reference();
    reference.type = this.typeToAdd;
    reference.artIdTo = this.selectedNewProduct.supplierPid;
    reference.order = this.model.references.length + 1;

    this.model.references.push(reference);
    this.update();
    this.addVisible = false;
    this.initializeReferencesByType();
  }

  getReferencesByType(type: string): Reference[] {
    return this.referencesByType[type];
  }

  getReferencesTypeCountText(type: string): string {
    let count = this.getReferencesByType(type).length;
    if (count == 0) return '';
    return '(' + count + ')';
  }

  initializeReferencesByType() {
    this.referencesByType = {};
    for (const type of this.referenceTypes) {
      this.referencesByType[type] = [];
    }

    if (this.model.references) {
      for (const reference of this._model.references) {
        if (!this.referencesByType[reference.type]) {
          this.referencesByType[reference.type] = [];
        }
        this.referencesByType[reference.type].push(reference);
      }
    }
  }

  get referenceTypes() {
    return ViewService.referenceTypes;
  }

  onRowRemoved(e: RowRemovedEvent<Reference>) {
    let index = this.model.references.findIndex((x) => x.artIdTo == e.data.artIdTo && x.type == e.data.type);
    this.model.references.splice(index);
    this.update();
    this.initializeReferencesByType();
  }

  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }

  excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    if (this.model != null) {
      return this.model.isFieldLinked(field, system, element);
    }
    return false;
  }

  toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    this.update(event);
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
