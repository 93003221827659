export class ClassificationClassTree {

  constructor(
    public id?: string,
    public classificationSystemId?: string,
    public parentId?: string,
    public groupId?: string,
    public name?: string,
    public number?: string,
    public notAssignable?: boolean
  ) {
  }
}
