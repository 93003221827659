export class ProductSupplier {
  

  constructor(
  
    public order?: number,
    public supplierId?: string,
    public supplierName?: string,
    public pid?: string,
    public internationalId?: number,
    public internationalIdType?: string,
    public supplierProductDescription?: string,
    public supplierProductDescriptionShort?: string,
    public deliveryTime?: number,
    public price?: number,
    public validFrom?: Date,
    public validUntil?: Date,
    public guid?: string,
    public discountGroupCode?: string,
    public pricingUnitCode?: string,
    public priceAllocationUnitCode?: string,
    public originalPriceAllocationUnitCode?: string,
    public contentUnitCode?: string,
    public packingAmount?: string,
    public orderIntervall?: string,
    public minOrderAmount?: string,
    public discontinuedProduct?: boolean,
    public discontinuedDate?: Date,
   
  ) {

  }

  get current(): boolean {
    let isValidFrom = this.validFrom == null || this.validFrom.getTime() < Date.now();
    let isValidUntil = this.validUntil == null || this.validUntil.getTime() > Date.now();
    return isValidFrom && isValidUntil;
  }
}
