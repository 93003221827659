import { PackingUnit } from './PackingUnit';

export class OrderDetail {
  constructor(
    public contentUnit?: string,
    public deliveryTime?: string,
    public noCuPerOuNumber?: number,
    public orderUnit?: string,
    public packingUnits?: PackingUnit[],
    public priceQuantityNumber?: number,
    public quantityIntervalNumber?: number,
    public quantityMaxNumber?: number,
    public quantityMinNumber?: number,
    public contentQuantityNumber?: number,
    public contentPackage?: string,
    public basicQuantityNumber?: number,
    public hasBasicPriceDuty?: boolean,
    public isBulkyGood?: boolean,
    public isTruckageCompanyGood?: boolean,
    public costOfLivingAdjustment?: number,
    public alloySurcharge?: number,
    public isDurabilityProduct?: boolean,
    public durabilityDays?: number
  ) {
    this.packingUnits = [];
  }
}
