
// Achtung: Property names werden in FieldNames.ts
// mit einem String value gemappt.
export class ElectronicSalesDetail {
  // Flags
  notReturnable: boolean = null;
  notDiscountable: boolean = null;
  notOrderable: boolean = null;
  notRequestable: boolean = null;
  locked: boolean = null;
  onlyOffline: boolean = null;

  // Marketing
  dateCreated: Date = null;
  descriptionLong = "";
  descriptionMarketing = "";
  promoInfo = "";
  noteCustomer = "";
  seoUrlFilename = "";

  // Richtlinien
  fsk = "";
  euDataSheet = "";
  eek = "";
  eeLabel = "";
  eekColor = "";

  // Lager/Logistik
  stockKeeping = "";
  stockLocation = "";
  stockUnit = "";
  stockInfoText = "";
  stockKeepingModus = "";
  stockPriceFactor = "";
  noStockInfoText = "";

  // Zuschläge/Kosten
  surCharges1ArtNr = "";
  surCharges1Percent = "";
  surCharges1FixValue = "";
  surCharges2ArtNr = "";
  surCharges2Percent = "";
  surCharges2FixValue = "";
  charges = "";

  // Bestellung
  orderDecimalPlaces: number = null;
  orderFactor: number = null;
  orderStockFactor: number = null;
  orderPriceFactor: number = null;

  // Sonstiges
  priceInfo = "";
  newInShopTime: Date = null;
  materialGroup = "";
}
