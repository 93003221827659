<div class="d-flex gap-1 mb-3">
    <div class="dx-field-value inputWithPlaceHolder flex-fill w-25">
        <label class="placeLabel placeLabelSmall">{{ 'Suchmodus' | translate }}</label>
        <dx-select-box [dataSource]="[
                            { label: 'oder' | translate, value: 'or' },
                            { label: 'und' | translate, value: 'and' }
                       ]"
                       [(value)]="model.searchStateMode"
                       (onValueChanged)="update()"
                       valueExpr="value"
                       displayExpr="label">
        </dx-select-box>
    </div>
    <div class="dx-field-value inputWithPlaceHolder flex-column w-75">
        <label class="placeLabel {{ stateValue.length > 0  ? 'placeLabelSmall' : '' }}">{{ 'Produktstatus' | translate }}</label>
        <dx-tag-box [(value)]="stateValue"
                    [(items)]="modelService.productStateService.productStates"
                    [acceptCustomValue]="true"
                    (onValueChanged)="update()"
                    placeholder=""
                    noDataText="">
        </dx-tag-box>
    </div>
</div>