import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { ClassificationSystem } from '../../Model/classification/ClassificationSystem';
import { UserManagementService } from "../../Services/userManagment.service";
import { ClassificationService } from "../../Services/classification.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "catalogClassification",
  templateUrl: "catalogClassification.component.html",
  styleUrls: []
})

export class CatalogClassificationComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService, public classifcationService: ClassificationService) { }

  async ngOnInit() {                                                
    this.classificationSystems = await lastValueFrom(
      this.classifcationService.getSystemsOfCustomer(
        this.modelService.loginService.currentUser.customerId,
        this.modelService.catalogService.catalog.language));
  }

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  classificationSystems : ClassificationSystem[] = new Array<ClassificationSystem>();


  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
