<!--78 setWawiSupplierPids-->

<div class="dx-field">
  <div class="dx-field-label">{{ 'Bestehenden Werte überschreiben' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ "Nein" | translate }}</div>
      <dx-switch [(value)]="overrideExisting"
                 switchedOffText="Nein"
                 switchedOnText="Ja"></dx-switch>
      <div class="dx-field-label switch-right">{{ "Ja" | translate }}</div>
    </div>
  </div>
</div>
