<div class="d-flex flex-row h-100">
  <div class="flex-fill">



    <!--cust: {{ customerId }}<br />
    cata: {{ catalogId }}<br />
    prod: {{ productId }}<br />-->



    <div class="dx-accordion-item-title">{{ txtExportReportProductDatasheetShort }}</div>

    <div class="accordion-body" [innerHtml]="txtExportReportProductDatasheetLong"></div>



    <div class="dx-field">
      <div class="dx-field-label">{{ 'Report' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ selectedReport?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'REPORT' | translate }}</label>
        <dx-select-box [(value)]="selectedReport"
                       [(dataSource)]="allReports"
                       placeholder=""></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Muster Dateiname' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ filenamePattern?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'MUSTER DATEINAME' | translate }}</label>
        <dx-text-box [(value)]="filenamePattern" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beschreibung' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ mimeDescr?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'MIME DESCRIPTION' | translate }}</label>
        <dx-text-box [(value)]="mimeDescr" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Alle ersetzen' | translate }}</div>
      <dx-check-box [(value)]="replaceAll" style="padding-top: 6px;"></dx-check-box>
    </div>



    <div class="macroHead dx-accordion-item-body">
      <dx-button stylingMode="text" type="default" text="{{ 'Produktdatenblatt erstellen' | translate }}" (onClick)="create()" class="buttonMargin"></dx-button>
    </div>



  </div>
</div>
