
<div class="d-flex flex-row">

  <div *ngIf="templateService.exportTemplates" class="flex-fill">

    <np-inputControl [showLinkButtons]="false"
                     [showPlaceLabel]="templateService.template?.name?.length > 0"
                     [suppressLinkButton]="true"
                     [suppressPlaceLabel]="true"
                     [suppressDragHandle]="true"
                     labelText="{{ 'Auswahl' | translate }}"
                     field="TEMPLATE_NAME"
                     [system]="-1"
                     [element]="-1">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                       [items]="templateService.exportTemplates"
                       [value]="templateService.template?.id"
                       valueExpr="id"
                       displayExpr="name"
                       [searchEnabled]="true"
                       [showClearButton]="true"
                       (onSelectionChanged)="selectTemplate($event)"
                       noDataText="{{ 'keine Templates im Account' | translate }}">
        </dx-select-box>
      </div>

    </np-inputControl>

  </div>

  <div class="align-self-center">
    <div id="addButton">
      <dx-button (onClick)="showAddTemplate()" icon="plus" [disabled]="canAddTemplate"></dx-button>
    </div>
    <dx-tooltip target="#addButton"
                *ngIf="canAddTemplate"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="true">
      <div *dxTemplate="let data = data; of: 'content'">{{ 'Sie haben die maximale Anzahl an Templates in ihrem Account erreicht.' | translate }}</div>
    </dx-tooltip>
  </div>

</div>



<dx-popup class="popup"
          [width]="600"
          [height]="400"
          [showTitle]="true"
          title="{{ 'Neues Template' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addTemplateVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <newTemplate (onAdded)="addTemplate($event)"></newTemplate>
  </div>
</dx-popup>
