<!--74 harmonizeFeatures-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'FeatureSystemName' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedFeatureSystem?.name?.length > 0 ? 'placeLabelSmall' : '' }}">REFERENCE FEATURESYSTEM NAME</label>
    <dx-select-box [(value)]="selectedFeatureSystem"
                   [items]="filteredFeatureSystems"
                   displayExpr="name"
                   (onValueChanged)="onFeaturesystemChanged($event)"
                   placeholder="">
    </dx-select-box>
  </div>
</div>
<dx-load-indicator *ngIf="featuresLoading" id="small-indicator" height="20" width="20"></dx-load-indicator>
<dx-data-grid *ngIf="selectedFeatureSystem && !featuresLoading"
              id="gridContainer"
              [dataSource]="featuresWithOrder"
              [showBorders]="true">

  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
  <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
  <dxi-column dataField="order" dataType="number" width="100" caption="{{ 'Order' | translate }}"></dxi-column>
  <dxi-column dataField="name" [allowEditing]="false" caption="{{ 'Feature-Name' | translate }}"></dxi-column>

</dx-data-grid>

