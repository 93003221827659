import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ModelService } from "../../Services/model.service";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { OsgSegmentMapping } from '../../Model/Catalog/OsgSegmentMapping';
import { OsgConnection } from "../../Model/Dto/OsgConnection";
import { StartAction } from "../../Model/Dto/StartAction";
import { UserManagementService } from "../../Services/userManagment.service";
import { ViewService } from '../../Services/view.service';
import { ExportParameter } from "../../Model/exportParameter/ExportParameter";

@Component({
  selector: "catalogOsg",
  templateUrl: "catalogOsg.component.html",
  styleUrls: []
})
export class CatalogOsgComponent implements OnInit {
  pages = [];
  get priceTypes() { return ViewService.dropDownPriceTypes; }

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.sendData2ShopZero = this.sendData2ShopZero.bind(this);
    this.sendData2Shop = this.sendData2Shop.bind(this);
    this.sendData2ShopOne = this.sendData2ShopOne.bind(this);
    this.sendData2ShopTwo = this.sendData2ShopTwo.bind(this);

    this.sendData2ShopTestOne = this.sendData2ShopTestOne.bind(this);
    this.sendData2ShopTestTwo = this.sendData2ShopTestTwo.bind(this);
    this.sendData2ShopTestZero = this.sendData2ShopTestZero.bind(this);
    this.resetCatalogTest = this.resetCatalogTest.bind(this);
    this.resetCatalog = this.resetCatalog.bind(this);
    this.startResetCatalog = this.startResetCatalog.bind(this);
    this.startResetCatalogTest = this.startResetCatalogTest.bind(this);
    this.closePopups = this.closePopups.bind(this);
  }

  ngOnInit(): void {

    let testText = "";
    this.translate.get("Test").subscribe((text: string) => {
      testText = text;
    });
    let liveText = "";
    this.translate.get("Live").subscribe((text: string) => {
      liveText = text;
    });

    this.pages = [
      { id: 1, title: liveText, template: "liveTemplate" }
    ];

    this.modelService.osgConnectionService.getConnection(this.modelService.loginService.currentCustomer.id, true).subscribe(
      (result: OsgConnection) => {
        if (result != null && result.shopUrl.length > 0) {
          this.pages.push({ id: 2, title: testText, template: "testTemplate" });
        }
      });
  }

  canChangeKeepRootCategory(): boolean {
    if (this.modelService.catalogService.catalog != null &&
      this.modelService.catalogService.catalog.osgSegmentMapping != null) {
      if (this.modelService.catalogService.catalog.osgSegmentMapping.lastSend || this.modelService.catalogService.catalog.osgSegmentMapping.lastSendTest) {
        return true;
      }
      return false;
    }
    return false;
  }

  get model(): Catalog {

    if (this.modelService.catalogService.catalog &&
      this.modelService.catalogService.catalog.osgSegmentMapping == null) {
      this.modelService.catalogService.catalog.osgSegmentMapping = new OsgSegmentMapping();
      this.modelService.catalogService.catalog.osgSegmentMapping.productRange = "";
      this.modelService.catalogService.catalog.osgSegmentMapping.productRangeTest = "";
      this.modelService.catalogService.catalog.osgSegmentMapping.lastSend = null;
      this.modelService.catalogService.catalog.osgSegmentMapping.lastSendTest = null;
      this.modelService.catalogService.catalog.osgSegmentMapping.priceType = "";
      this.modelService.catalogService.catalog.osgSegmentMapping.priceTypeTest = "";

    }

    return this.modelService.catalogService.catalog;
  }

  isSendCatalogPopupVisible: boolean = false;
  isSendCatalogTestPopupVisible: boolean = false;
  exportMedia: boolean = true;
  isResetCatalogTestVisible: boolean = false;
  isResetCatalogVisible: boolean = false;



  sendData2ShopZero() {
    this.sendData2Shop(0, false);
  }
  sendData2ShopTestZero() {
    this.sendData2Shop(0, true);
  }

  sendData2ShopOne() {
    this.sendData2Shop(1, false);
  }

  sendData2ShopTwo() {
    this.sendData2Shop(2, false);
  }

  sendData2ShopTestOne() {
    this.sendData2Shop(1, true);
  }

  sendData2ShopTestTwo() {
    this.sendData2Shop(2, true);
  }

  resetCatalogTest() {
    this.isResetCatalogTestVisible = true;

  }
  resetCatalog() {
    this.isResetCatalogVisible = true;
  }

  closePopups() {
    this.isResetCatalogVisible = false;
    this.isResetCatalogTestVisible = false;
  }

  startResetCatalogTest() {
    this.startResetCatalog(1);
  }

  startResetCatalog(isTest: number = 0) {
    this.closePopups();
    const startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = "ResetOSG(" + isTest + ")";

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(success => {
        if (success) {
          this.translate.get("Das Zurücksetzen wurde als Job gestartet").subscribe((text: string) => {
            this.modelService.systemService.notify(new Message(text));
          });
        }
      });
  }

  sendData2Shop(num = 0, isTest: boolean) {
    switch (num) {
      case 0:
        if (isTest == false)
          this.isSendCatalogPopupVisible = true;
        else
          this.isSendCatalogTestPopupVisible = true;
        break;
      case 1:
        this.isSendCatalogPopupVisible = false;
        this.isSendCatalogTestPopupVisible = false;
        let model = this.GetExportParameter();
        if (isTest) {
          model.isLive = false;
        } else {
          model.isLive = true;
        }
        this.modelService.catalogService.createExportJob(model).subscribe(
          () => {
            this.translate.get("Die Übertragung wurde gestartet").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text));
            });
            this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
              .subscribe(() => {

              });
          });
        break;
      default:
        this.isSendCatalogPopupVisible = false;
        this.isSendCatalogTestPopupVisible = false;
        break;
    }
  }

  private GetExportParameter(): ExportParameter {
    var result = new ExportParameter();
    result.catalogId = this.model.id;
    result.customerId = this.modelService.loginService.currentUser.customerId;
    result.version = "OSG";
    result.format = "OSG";
    result.includeMime = this.exportMedia;

    return result;
  }

  catalogChanged(event = null) {
    this.modelService.catalogService.updateCatalog(event);
  }


  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get productRange(): string {
    if (this.model.osgSegmentMapping)
      return this.model.osgSegmentMapping.productRange;
    return "";
  }

  get lastSend(): string {
    if (this.model.osgSegmentMapping && this.model.osgSegmentMapping.lastSend)
      return this.model.osgSegmentMapping.lastSend.toLocaleString("de-DE");
    return "";
  }

  get productRangeTest(): string {
    if (this.model.osgSegmentMapping)
      if (this.model.osgSegmentMapping.productRangeTest)
        return this.model.osgSegmentMapping.productRangeTest;
    return "";
  }

  get lastSendTest(): string {
    if (this.model.osgSegmentMapping && this.model.osgSegmentMapping.lastSendTest)
      return this.model.osgSegmentMapping.lastSendTest.toLocaleString("de-DE");
    return "";
  }



  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
