import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { TranslateService } from '@ngx-translate/core';
import { SendToNWConfig } from '../Model/Dto/SendToNWConfig';






@Injectable()
export class SendToNWConfigService {


    constructor(private http: HttpClient, public translate: TranslateService) {

    }

  

    getConnection(customerId: string): Observable<SendToNWConfig> {
        const options = {
            headers: new HttpHeaders().append("Content-Type", "application/json"),
            params: new HttpParams().append("customerId", customerId)
        }

        return (this.http.get<SendToNWConfig>("api/SendToNWConfig/GetNWConfig", options)) as any;
  }

  

    update(SendToNWConfig: SendToNWConfig): Observable<number> {
        return (this.http.post("api/SendToNWConfig/Update", SendToNWConfig)) as any;

    }

    testConnection(id: number): Observable<TestResult> {
        const options = {
            headers: new HttpHeaders().append("Content-Type", "application/json"),
            params: new HttpParams().append("connectionId", id.toString())
        }
        return (this.http.get("api/SendToNWConfig/testConnection", options)) as any;
    }




}
