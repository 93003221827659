<div class="np-form-control dx-fieldset mainInfos" *ngIf="model">

  <!--BUYER_NAME-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Käufername' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.buyerName?.length>0 ? 'placeLabelSmall' : '' }}">BUYER_NAME</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerName')" [(value)]="model.buyer.buyerName" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--BUYER_ID-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Käuferkennung' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.buyerId?.length>0 ? 'placeLabelSmall' : '' }}">BUYER_ID</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerId')" [(value)]="model.buyer.buyerId" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--BUYER_ID_TYPE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'ID-Typ' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.buyerIdType?.length>0 ? 'placeLabelSmall' : '' }}">BUYER_ID_TYPE</label>
      <dx-autocomplete [minSearchLength]="0"
                       [dataSource]="idType"
                       [showClearButton]="true"
                       [showDropDownButton]="true"
                       [(value)]="model?.buyer.buyerIdType"
                       valueChangeEvent="focusout"
                       (onValueChanged)="catalogChanged($event)"
                       [readOnly]="readOnlyUser('buyerIdType')">
      </dx-autocomplete>
    </div>
  </div>

  <!--NAME1-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 1' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.name1?.length>0 ? 'placeLabelSmall' : '' }}">NAME1</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressName1')" [(value)]="model.buyer.address.name1" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--NAME2-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 2' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.name2?.length>0 ? 'placeLabelSmall' : '' }}">NAME2</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressName2')" [(value)]="model.buyer.address.name2" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--NAME3-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 3' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.name3?.length>0 ? 'placeLabelSmall' : '' }}">NAME3</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressName3')" [(value)]="model.buyer.address.name3" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--CONTACT-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Ansprechpartner' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.contact?.length>0 ? 'placeLabelSmall' : '' }}">CONTACT</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressContact')" [(value)]="model.buyer.address.contact" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--STREET-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Straße' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.street?.length>0 ? 'placeLabelSmall' : '' }}">STREET</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressStreet')" [(value)]="model.buyer.address.street" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--ZIP-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'PLZ' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.zip?.length>0 ? 'placeLabelSmall' : '' }}">ZIP</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressZip')" [(value)]="model.buyer.address.zip" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--CITY-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Ort' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.city?.length>0 ? 'placeLabelSmall' : '' }}">CITY</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressCity')" [(value)]="model.buyer.address.city" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--STATE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Bundesland' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.state?.length>0 ? 'placeLabelSmall' : '' }}">STATE</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressState')" [(value)]="model.buyer.address.state" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--COUNTRY-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Land' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.country?.length>0 ? 'placeLabelSmall' : '' }}">COUNTRY</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressCountry')" [(value)]="model.buyer.address.country" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--PHONE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Telefon' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.phone?.length>0 ? 'placeLabelSmall' : '' }}">PHONE</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressPhone')" [(value)]="model.buyer.address.phone" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--FAX-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Telefax' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.fax?.length>0 ? 'placeLabelSmall' : '' }}">FAX</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressFax')" [(value)]="model.buyer.address.fax" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--EMAIL-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'E-Mail' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.email?.length>0 ? 'placeLabelSmall' : '' }}">EMAIL</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressEmail')" [(value)]="model.buyer.address.email" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--URL-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Homepage' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.url?.length>0 ? 'placeLabelSmall' : '' }}">URL</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressUrl')" [(value)]="model.buyer.address.url" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <!--ADDRESS_REMARKS-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Bemerkung' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.buyer.address.addressRemarks?.length>0 ? 'placeLabelSmall' : '' }}">ADDRESS_REMARKS</label>
      <dx-text-box [readOnly]="readOnlyUser('buyerAddressRemarks')" [(value)]="model.buyer.address.addressRemarks" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>
</div>
