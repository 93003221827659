<fullscreen-page>
  <content-column>
    <div slot="content" class="p-2 flex-fill overflow-hidden">
      <dx-data-grid
        class="h-100"
        (onToolbarPreparing)="onContentToolbarPreparing($event)"
        [dataSource]="oxomiSupplierList"
        keyExpr="id"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        noDataText="{{ 'keine Lieferanten im System' | translate }}"
        [showBorders]="true"
        (onRowRemoved)="onRemovedSupplier($event)"
        (onRowUpdated)="onUpdatedSupplier($event)">
        
        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <div *dxTemplate="let toolbaritem of 'addSupllierTemplate'">
          <dx-button stylingMode="text" type="default" icon="add" text="{{ 'Neuer Lieferant' | translate }}" (onClick)="showAdd()"></dx-button>
        </div>
        
        <div *dxTemplate="let toolbaritem of 'excelImportButtonTemplate'">
          <dx-button stylingMode="text" type="default" icon="import" text="{{ 'Excel Import' | translate }}" (onClick)="showExcelImport()"></dx-button>
        </div>
        
        <div *dxTemplate="let toolbaritem of 'excelExportButtonTemplate'">
          <dx-button *ngIf="excelExportVisible" stylingMode="text" type="default" icon="export" text="{{ 'Excel Export' | translate }}" (onClick)="excelExport($event)" ></dx-button>
        </div>

        <dxo-editing mode="cell" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
          <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diesen Lieferanten wirklich löschen' | translate }}?"
                      confirmDeleteTitle="{{ 'Lieferant löschen' | translate }}"
                      editRow="{{ 'Lieferant bearbeiten' | translate }}"
                      deleteRow="{{ 'Lieferant löschen' | translate }}">
                      
          </dxo-texts>
        </dxo-editing>

        <dxi-column dataField="number" caption="{{ 'Nummer' | translate }}" width="30%" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="name" caption="{{ 'Lieferant' | translate }}" width="50%" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="lastDataUpdate" caption="{{ 'Letztes Produkt Update' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [calculateCellValue]="getValueForLastUpdate" width="20%" [allowSearch]="true"></dxi-column>

        <dxi-column caption="" cellTemplate="resetLastUpdateDate" [width]="50"></dxi-column>
        <div *dxTemplate="let data of 'resetLastUpdateDate'">
            <dx-button stylingMode="text" type="default" icon="revert" [disabled]="resetLastUpdateNotPossible(data)"
                       hint="{{'Letztes Produkt Update Datum zurücksetzen'| translate}}" (onClick)="resetLastUpdateDate(data)">
            </dx-button>
        </div>

        <dxi-column caption="" cellTemplate="updateCheckOxomiData" [width]="50"></dxi-column>
        <div *dxTemplate="let data of 'updateCheckOxomiData'">
            <dx-button stylingMode="text" type="default" icon="search"
                       hint="{{'Auf Updates prüfen'| translate}}" (onClick)="checkForDataUpdate(data)">
            </dx-button>
        </div>

        <dxi-column caption="" cellTemplate="importOxomiData" [width]="50"></dxi-column>
        <div *dxTemplate="let data of 'importOxomiData'">
            <dx-button stylingMode="text" type="default" icon="import" [disabled]="updateAvailable(data)"
                      [hint]="getHint(data)" (onClick)="updateData(data)">                      
            </dx-button>            
        </div>

      </dx-data-grid>
    </div>
  </content-column>
</fullscreen-page>

<dx-popup [showTitle]="true"
          title="{{ 'Lieferant anlegen' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addVisible"
          contentTemplate="popupContent"
          height="30%"
          width="30%">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Lieferant anlegen' | translate, onClick: addSupplier }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">    
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Nummer' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newSupplier.number" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Lieferant' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newSupplier.name" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="600"
          [height]="350"
          [showTitle]="true"
          title="{{ 'Lieferantenliste importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="excelImportVisible"
          contentTemplate="popupContent2">
  <div *dxTemplate="let data of 'popupContent2'">    
    <dx-file-uploader #fileUploader
                      name="file"
                      uploadUrl="api/OxomiSortiment/ImportSupplier"
                      [multiple]="false"
                      [chunkSize]="10000000"
                      accept=".xlsx"
                      (onUploaded)="onUploaded($event)"
                      (onUploadError)="onUploadError($event)"
                      uploadMode="instantly"
                      (onBeforeSend)="onBeforeSendUpload($event)">
    </dx-file-uploader>
  </div>
</dx-popup>

<dx-popup [showTitle]="true"
          title="{{ 'Import Zusammenfassung' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importResumeVisible"
          contentTemplate="popupContent3"
          height="50%"
          width="30%">
  <div *dxTemplate="let data of 'popupContent3'">    
    <p class="oxomiImportSummary">{{'Lieferanten neu' | translate}}: {{excelImportNewCount}}</p>  
    <p class="oxomiImportSummary">{{'Lieferanten upgedatet' | translate}}: {{excelImportUpdateCount}}</p>
    <div *ngIf="missingValues">
      <p class="oxomiImportSummary">{{'Lieferanten ignoriert' | translate}}: {{excelImportIgnoreCount}}</p>
      <p class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten mit unvollständigen Daten wurden ignoriert' | translate}}!</p>
      <div *ngIf="supplierWithoutNameExists">
        <label class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten ohne Namen' | translate}}:</label>
        <p>{{supplierWithoutName}}</p>
      </div>
      <div *ngIf="supplierWithoutNumberExists">
        <label class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten ohne Lieferantennummer' | translate}}:</label>
        <p>{{supplierWithoutNumber}}</p>
      </div>
    </div>
  </div>
</dx-popup>