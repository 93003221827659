<!--Customer AddOns Übersicht-->

<dx-data-grid class="h-100"
              [dataSource]="customerAddonInfos"
              [remoteOperations]="false"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Daten vorhanden' }}"
              [showBorders]="true"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              (onExporting)="onExporting($event)">


  <div *dxTemplate="let toolbaritem of 'minGroupsButtonTemplate'">
    <dx-button stylingMode="text" (onClick)="onCollapseAll()" type="default" icon="chevronright" hint="{{ 'Alle Gruppen schliessen' }}"></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'maxGroupsButtonTemplate'">
    <dx-button stylingMode="text" (onClick)="onExpandAll()" type="default" icon="chevrondown" hint="{{ 'Alle Gruppen öffnen' }}"></dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
    <dx-button stylingMode="text" type="default" text="{{ 'Aktualisieren' }}" (onClick)="reload()" hint="{{ 'Aktualisieren' }}"></dx-button>
  </div>

  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-paging [pageSize]="1000"> </dxo-paging>

  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Daten suchen' }}" class="treeSearch"></dxo-search-panel>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="CustomerAddonsOverviewStateNewUIV1"></dxo-state-storing>

  <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>

  <dxi-column dataField="addon" caption="{{ 'AddOn' }}" dataType="string"></dxi-column>
  <dxi-column dataField="customerName" caption="{{ 'Kunde' }}" dataType="string"></dxi-column>
  <dxi-column dataField="userMails" caption="{{ 'Mailkontakte' }}" dataType="string"></dxi-column>

</dx-data-grid>
