<fullscreen-page>
  <content-column look="dark">
    <logo slot="toolbar" [routerLink]="['/catalogs']" look="dark" route="['/catalogs']"></logo>

    <div slot="content" class="p-3 flex-fill w-100 mx-auto overflow-hidden">
      <dx-scroll-view showScrollbar="always">
        <dx-tab-panel [dataSource]="pages" [selectedIndex]="0">
          <!--Jobs-->
          <div *dxTemplate="let company of 'jobsTemplate'">
            <job-list [storeName]="'JobListStateNewUI_V4_ADMIN'"
                      [jobListMode]="false"
                      [catalogMode]="false"
                      [adminMode]="true"><!-- always choose ONE mode!! -->
            </job-list>
          </div>

          <!--Logins-->
          <div *dxTemplate="let l of 'loginsTemplate'">
            <admin-logins></admin-logins>
          </div>

          <!--Customer AddOns Übersicht-->
          <div *dxTemplate="let l of 'customerAddonsOverviewTemplate'">
            <customer-addons-overview></customer-addons-overview>
          </div>

        </dx-tab-panel>
      </dx-scroll-view>
    </div>


  </content-column>
</fullscreen-page>
