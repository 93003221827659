import { Component } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { Message } from "../../../Model/System/Message";
import { TranslateCatalog } from "../../../Model/Dto/TranslateCatalog";
import { LanguageFlag } from "../../../Model/Dto/LanguageFlag";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "translateCatalog",
  templateUrl: "translateCatalog.component.html"
})
export class TranslateCatalogComponent {

  public selectedLanguage: string ;
  model: TranslateCatalog;
  public useDeepL: boolean = false;
  public hasLanguages: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.onTranslate = this.onTranslate.bind(this);

    //this._languageFlags = this.modelService.loginService.languageFlags;

    let allFlags = this.modelService.loginService.languageFlags;

    let relevantFlags = new Array<LanguageFlag>();

    if (allFlags.length == 1) {   // Es wurden noch keine Sprachen angelegt. Nur die "currentCustomerDefaultLanguage" ist hier drin.
      //relevantFlags = allFlags;
    } else {                      // Es wurden Sprachen angelegt. Die "currentCustomerDefaultLanguage" soll nicht in die Auswahl.
      allFlags.forEach((flag: LanguageFlag) => {
        if (flag.code != this.modelService.loginService.currentCustomer.defaultLanguage) {
          relevantFlags.push(flag);
        }
      });
      this._languageFlags = relevantFlags;

      if (this._languageFlags.length > 0) {
        this.selectedLanguage = this._languageFlags[0].code;
        this.hasLanguages = true;
      }
    }
  }

  _languageFlags: LanguageFlag[];

  get languageFlags(): LanguageFlag[] {
    return this._languageFlags;
  }

  set languageFlags(value: LanguageFlag[]) {
    this._languageFlags = value;
  }

  onTranslate() {
    this.model = new TranslateCatalog();
    this.model.languageCode = this.selectedLanguage;
    this.model.customerId = this.modelService.loginService.currentUser.customerId;
    this.model.catalogId = this.modelService.catalogService.selectedCatalogId;
    this.model.useDeepL = this.useDeepL;

    this.modelService.translationService.translateCatalog(this.model).subscribe(success => {
      if (success) {
        this.translate.get("Übersetzung wird als Job ausgeführt").subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text));
          this.modelService.catalogService.translateVisible = false;
        });
        
        }
      },
      err => this.translate.get("Unbekannter Fehler").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text,"error"));
      }));
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

}
