import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TestResult } from "../Model/Dto/TestResult";
import { TranslateService } from '@ngx-translate/core';
import { MavisConnection } from '../Model/Dto/MavisConnection';
import { MavisLanguage } from '../Model/Catalog/mavis/MavisLanguage';




@Injectable()
export class MavisConnectionService {


  constructor(private http: HttpClient, public translate: TranslateService) {

  }

  languages: MavisLanguage[];
  languagesLive: MavisLanguage[];
  getTestConnection(customerId: string): Observable<MavisConnection> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<MavisConnection>("api/MavisConnection/GetConnection", options)) as any;
  }
  getLiveConnection(customerId: string): Observable<MavisConnection> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("isLive", "true")
    }

    return (this.http.get<MavisConnection>("api/MavisConnection/GetConnection", options)) as any;
  }

  getLanguages(customerId: string, isLive: boolean): Observable<MavisLanguage[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("isLive", isLive.toString())
    }

    return (this.http.get<MavisLanguage[]>("api/MavisConnection/GetLanguages", options)) as any;
  }

  update(mavisConnection: MavisConnection): Observable<number> {
    return (this.http.post("api/MavisConnection/Update", mavisConnection)) as any;

  }

  testConnection(id: number): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("connectionId", id.toString())
    }
    return (this.http.get("api/MavisConnection/testConnection", options)) as any;
  }




}
