import { Component } from "@angular/core";

import { ChangePassword } from "../../Model/User/ChangePassword";

import { ModelService } from "../../Services/model.service";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: "changePassword",
  templateUrl: "changePassword.component.html",
  styleUrls: ["changePassword.component.css"]
})
export class ChangePasswordComponent {

  constructor(private modelService: ModelService, public translate: TranslateService) {


    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Passwort ändern");
  }

  creds = new ChangePassword();

  onChangePassword() {
    this.modelService.loginService.changePassword(this.creds)
      .subscribe(success => {
        if (success) {
          let translation = "";
          this.translate.get('Ihr Passwort wurde geändert').subscribe((text: string) => {
            translation = text;
            this.modelService.systemService.notify(new Message(translation));
            this.modelService.router.navigate(["account/home"]);
          });



        }
      },
        err => {
          let translation = "";
          this.translate.get('Fehler beim Ändern des Passworts').subscribe((text: string) => {
            translation = text;
            this.modelService.systemService.notify(new Message(translation, "error"));
          });

        });
  }

  passwordComparison = () => {
    return this.creds.newPassword;
  };
}
