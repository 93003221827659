export class duplicateItem {

  constructor(
    public sourceItemId?: string,

    public sourceCategoryId?: string,
    public sourceCatalogId?: string,

    public sourceCustomerId?: string,

    public targetItemId?: string,

    public targetCategoryId?: string,
    public targetCatalogId?: string,

    public targetCustomerId?: string
  ) {

  }
}
