<div style="overflow-y: auto;" >
  <dx-load-panel shadingColor="rgba(0,0,0,0.4)"
                 [(visible)]="isLoading"
                 [showIndicator]="true"
                 [showPane]="true"
                 [shading]="true"
                 [hideOnOutsideClick]="false">
  </dx-load-panel>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <dx-tag-box [dataSource]="features"
                  [(value)]="selectedFeatures"
                  [showSelectionControls]="true"
                  applyValueMode="instantly"
                  [showClearButton]="true"
                  [searchEnabled]="true"
                  placeholder=""
                  displayExpr="name">
      </dx-tag-box>
    </div>
  </div>
</div>
