import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import Validator from "devextreme/ui/validator";

@Component({
  selector: "newTemplate",
  templateUrl: "newTemplate.component.html"
})
export class NewTemplateComponent implements OnInit, OnDestroy {

  templateName: string = "";

  @Output() onAdded = new EventEmitter<string>();

  // diese Component könnte man, mit entspr. Parametern, generischer gestalten...

  constructor(public translate: TranslateService) {
    //let i = 1;  //1
  }

  ngOnInit(): void {
    this.templateName = ""; //2
  }

  ngOnDestroy(): void {
    //this.templateName = "";   // feuert zu spät, nämlich erst, wenn die eltern-komponente weggeräumt wird...
  }

  validatorInstance: Validator;
  saveValidatorInstance(e) {
    this.validatorInstance = e.component; //3
  }

  onAdd() {
    this.onAdded.emit(this.templateName);
    this.templateName = ""; // OnDestroy feuert anscheinend nicht...? doch, s. oben...

    this.validatorInstance.reset();
  }
}
