import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Category } from "../../../Model/Catalog/Category";
import { Shopware6CategoryMapping } from "../../../Model/Catalog/Shopware6CategoryMapping";
import { ShopwareCmsPage } from '../../../Model/Dto/ShopwareCmsPage';
import { ModelService } from "../../../Services/model.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { ShopwareSalesChannel } from "../../../Model/Dto/ShopwareSalesChannel";
import { lastValueFrom } from "rxjs";


@Component({
  selector: "categoryShopware6",
  templateUrl: "categoryShopware6.component.html",
  styleUrls: []
})
export class CategoryShopware6Component implements OnInit {
  pages = [];
  shopwareCmsPages: ShopwareCmsPage[];
  salesChannel: ShopwareSalesChannel[] = [];

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.categoryUpdate = this.categoryUpdate.bind(this);
  }

  async ngOnInit() {

    if (this.model.shopware6CategoryMapping == null) {
      this.model.shopware6CategoryMapping = new Shopware6CategoryMapping();
    }

    this.shopwareCmsPages = new Array<ShopwareCmsPage>();

    this.shopwareCmsPages = await lastValueFrom(this.modelService.catalogService.GetShopwareCmsPage(
      this.modelService.catalogService.catalog.shopWareConnection.baseUri,
      this.modelService.catalogService.catalog.shopWareConnection.clientId,
      this.modelService.catalogService.catalog.shopWareConnection.clientSecret));

    if (this.model.parentCategoryId == '000000000000000000000000') {
      this.salesChannel = await lastValueFrom(this.modelService.catalogService.GetShopwareSalesChannel(
        this.modelService.catalogService.catalog.shopWareConnection.baseUri,
        this.modelService.catalogService.catalog.shopWareConnection.clientId,
        this.modelService.catalogService.catalog.shopWareConnection.clientSecret));
    } else {
      this.salesChannel = undefined;
    }
  }



  get model(): Category {  

    return this.modelService.catalogService.category;
  }

  set model(value: Category) {
    this.modelService.catalogService.category = value;
  
  }
  categoryUpdate(event = null) {
    this.modelService.catalogService.updateCategory();
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
