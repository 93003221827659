<div class="dx-fieldset">

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Sprache' | translate }}</div>
    <div class="dx-field-value">
      <dx-select-box [dataSource]="languageFlags"
                     displayExpr="language"
                     valueExpr="code"
                     [(value)]="selectedLanguage"
                     fieldTemplate="field"
                     style="background-color:white;">

        <div *dxTemplate="let data of 'item'">
          <div class=" d-flex flex-row">
            <div class="pr-2 align-self-center">
              <img src="images/flags/{{data?.flag}}" class="flag" />
            </div>
            <div class="flex-fill pl-2">
              {{data?.language}}
            </div>
          </div>
        </div>

        <div *dxTemplate="let data of 'field'">
          <div class=" d-flex flex-row">
            <div class="pr-2 align-self-center">
              <img src="images/flags/{{data?.flag}}" class="flag" />
            </div>
            <div class="flex-fill pl-2">
              <dx-text-box [value]="data?.language" [readOnly]="true"></dx-text-box>
            </div>
          </div>
        </div>

        <dx-validator>
          <dxi-validation-rule type="required" message="{{ 'Bitte wählen Sie eine Sprache aus' | translate }}!"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
  </div>

  <div *ngIf="modelService.loginService.hasAddonDEEPLTRANSLATE" class="dx-field">
    <div class="dx-field-label">{{ 'Mit DeepL übersetzen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="useDeepL" switchedOffText="{{ 'Nein' | translate  }}" switchedOnText="{{ 'Ja' | translate  }}"></dx-switch>
    </div>
  </div>

  <dx-validation-summary id="summary"></dx-validation-summary>

</div>

<dx-toolbar>
  <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Übersetzen' | translate, onClick: onTranslate }"></dxi-item>
</dx-toolbar>
