<div class="dx-fieldset">
  <div class="dx-field">
    <h5>{{ 'Update Template' | translate }}</h5>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ template.name?.length > 0 ? 'placeLabelSmall' : '' }}">NAME</label>
      <dx-text-box [(value)]="template.name" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label" style="overflow: visible">
      {{ 'Neue Varianten von vorhandenen Produkten übernehmen' | translate }}
    </div>
    <div class="dx-field-value">
      <dx-switch
        (onValueChanged)="update($event)"
        [(value)]="template.addNewChilds"
        switchedOffText="{{ 'Nein' | translate }}"
        switchedOnText="{{ 'Ja' | translate }}"
      ></dx-switch>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">
      {{ 'Leere Werte in der Quelle ignorieren' | translate }}
    </div>
    <div class="dx-field-value">
      <dx-switch
        (onValueChanged)="update($event)"
        [(value)]="template.ignoreEmptyValues"
        switchedOffText="{{ 'Nein' | translate }}"
        switchedOnText="{{ 'Ja' | translate }}"
      ></dx-switch>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label" style="overflow: visible">
      {{ 'Fehlende Produkte anlegen' | translate }}
    </div>
    <div class="dx-field-value">
      <dx-switch
        (onValueChanged)="update($event)"
        [(value)]="template.createProducts"
        switchedOffText="{{ 'Nein' | translate }}"
        switchedOnText="{{ 'Ja' | translate }}"
      ></dx-switch>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label" style="overflow: visible">
      {{ 'Zusätzliche Produkte löschen' | translate }}
    </div>
    <div class="dx-field-value">
      <dx-switch
        (onValueChanged)="update($event)"
        [(value)]="template.deleteProducts"
        switchedOffText="{{ 'Nein' | translate }}"
        switchedOnText="{{ 'Ja' | translate }}"
      ></dx-switch>
    </div>
  </div>
  <div class="dx-field">
    <h5>{{ 'Felder' | translate }}</h5>
  </div>
  <div class="dx-field">
    <fieldList
      (onValueChanged)="update($event)"
      [fields]="catalogService.fields"
      [fieldGroups]="groups"
      [(fieldListContainer)]="template"
      [style]="{ height: 'inherit', width: '100%' }"
      isUpdateTemplate="true"
    ></fieldList>
  </div>
</div>
