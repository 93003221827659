import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { AppUser } from "../../../Model/User/AppUser";
import { LoginService } from '../../../Services/login.service';
import { CustomerService } from '../../../Services/customer.service';
import { UserService } from '../../../Services/user.service';
import { PimLicense } from '../../../Model/User/PimLicense';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'userManagement',
  templateUrl: 'userManagement.component.html'
})
export class UserManagementComponent implements OnInit {
  claims: string[] = [
    "USER",
    "READONLY",
    "MANAGER"];;
  selectedClaim: string = "USER";
  dataSourceUsers: DataSource;

  tooltipTarget:any;
  isVisible: boolean = false;

  constructor(public loginService: LoginService, public customerService: CustomerService, public userService: UserService) {
    this.canAddUser = this.canAddUser.bind(this);
    this.canEdit = this.canEdit.bind(this);

    let that = this
    this.dataSourceUsers = new DataSource({
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions: any) {
          return await lastValueFrom(that.customerService.getUsersOfCustomer(that.loginService.currentCustomer.id));
        },
        insert: async function (values) {
          let newUser: AppUser = values as AppUser;
          newUser.id = "";
          newUser.customerId = that.loginService.currentCustomer.id;
          return await lastValueFrom(that.userService.create(that.loginService.currentUser.id, newUser));
        },
        update: async function (key, values) {
          let userToUpdate = await lastValueFrom(that.userService.getUser(key, that.loginService.currentCustomer.id));
          Object.assign(userToUpdate, values);
          return await lastValueFrom(that.userService.update(userToUpdate));
        },
        remove: async function (key) {
          await lastValueFrom(that.customerService.deleteUserFromCustomer(key, that.loginService.currentCustomer.id));
          return;
        }
      }
      ),
      reshapeOnPush: true,
    });
  }

  ngOnInit(): void {    
  }

  onCellHoverChanged(event: any) {
    if (event.rowType === 'header' && event.column.dataField === 'canAccessServicePortal') {
      this.tooltipTarget = event.cellElement;
      if (event.eventType === 'mouseover') {
        this.isVisible = true;
      }
      else {
        this.isVisible = false;
      }
    }  
  }

  onInitNewRow(e) {
    e.data.canAccessServicePortal = true;
  }
  
  public canEdit(e) {
    return e.row.data.email == this.loginService.currentUser.email
      || this.loginService.currentUser.currentClaim == "ADMIN"
      || this.loginService.currentUser.currentClaim == "MANAGER";
  }

  public canAddUser(): boolean {
    let maxUsers = 0;
    switch (this.loginService.currentCustomer.pimLicense) {
      case PimLicense.Starter:
        maxUsers = 1;
        break;
      case PimLicense.Basic:
        maxUsers = 2;
        break;
      case PimLicense.Advanced:
        maxUsers = 5;
        break;
      case PimLicense.Infinity:
      default:
        maxUsers = Number.MAX_VALUE;
        break;
    }


    return this.loginService.isManager && this.dataSourceUsers.totalCount() < maxUsers;
  }
}
