import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { custom } from 'devextreme/ui/dialog';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { TemplateItemTemplate } from '../../../../../Model/Catalog/TemplateItemTemplate';
import { CatalogFilter } from '../../../../../Model/exportParameter/CatalogFilter';
import { ExportFormatWithCustomers } from '../../../../../Model/exportParameter/ExportFormatWithCustomers';
import { ExportFormatWithVersions, SelectBoxItem } from '../../../../../Model/exportParameter/ExportFormatWithVersions';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';
import { Message } from '../../../../../Model/System/Message';
import { Customer } from '../../../../../Model/User/Customer';
import { CatalogService } from '../../../../../Services/CatalogManagement/catalog.service';
import { ExportConfigurationService } from '../../../../../Services/exportConfiguration.service';
import { JobService } from '../../../../../Services/job.service';
import { LoginService } from '../../../../../Services/login.service';
import { ModelService } from '../../../../../Services/model.service';
import { TemplateService } from '../../../../../Services/template.service';
import { UpdateTemplateService } from '../../../../../Services/updateTemplate.service';
import { WawiListService } from '../../../../../Services/wawiList.service';
import { ItemClickEvent, SelectionChangedEvent, ValueChangedEvent } from 'devextreme/ui/select_box';
import { CollectionWidgetItem } from 'devextreme/ui/collection/ui.collection_widget.base';

@Component({
  selector: 'exportParameter',
  templateUrl: 'exportParameter.component.html',
  styleUrls: ['exportParameter.component.css']
})
export class ExportParameterComponent implements OnInit {
  @Input() catalogId: string;
  @Input() onlyUpdateFilter: boolean = false;

  _config: ExportParameter;
  @Input() set config(value) {
    this._config = value;
    this.initConfig();
  }

  get config() {
    return this._config;
  }

  interfaceWithConfigs = ['INTERSHOP', 'ODOO', 'SHOPWARE5', 'SHOPWARE6', 'ELECTRONIC_SALES', 'OSG'];
  contentIndex = 0;
  filterIndex = 0;
  selectedIndex = 0;
  onTemplateChange: EventEmitter<string> = new EventEmitter<string>();
  exportFormats: SelectBoxItem[] = [];
  exportVersions: SelectBoxItem[] = [];
  exportToCustomers: Customer[] = [];
  wawiMappingFields: TemplateItemTemplate[] = [];
  fieldGroups: string[] = [];
  private exportFormatWithVersions: ExportFormatWithVersions[] = [];
  private exportFormatWithCustomers: ExportFormatWithCustomers;
  private prevConfig: ExportParameter;

  constructor(
    public loginService: LoginService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public exportConfigurationService: ExportConfigurationService,
    public jobService: JobService,
    public catalogService: CatalogService,
    public updateTemplateService: UpdateTemplateService,
    public wawiListService: WawiListService,
    private cdRef: ChangeDetectorRef
  ) {
    if (loginService.currentUser.customerId == null)
      loginService.currentUser.customerId = loginService.currentCustomer.id;
  }

  async ngOnInit() {
    this.catalogService.loadFields();
    this.fieldGroups = this.updateTemplateService.GetFieldGroups();
    await this.initExportFormats();
    this.setExportVersions();
    this.initConfig();
    this.wawiMappingFields = await lastValueFrom(
      this.wawiListService.getWawiMappingFields(this.loginService.currentUser.customerId)
    );
  }

  async initExportFormats() {
    this.exportFormatWithVersions = await lastValueFrom(
      this.exportConfigurationService.getExportFormatWithVersions(this.loginService.currentUser.customerId)
    );
    this.exportFormats = this.exportFormatWithVersions.map((x) => x.exportFormat);
    this.exportFormatWithCustomers = await this.exportConfigurationService.getExportFormatForSendToCustomers(
      this.loginService.currentUser.customerId
    );
    if (this.exportFormatWithCustomers?.value && this.exportFormatWithCustomers?.value.length > 0) {
      this.exportFormats.push(new SelectBoxItem(false, this.exportFormatWithCustomers.key));
    }

    this.exportFormats = this.exportFormats.sort((a, b) => {
      if (a.disabled !== b.disabled) {
        return a.disabled ? 1 : -1;
      }
      return a.text.localeCompare(b.text);
    });
    this.fixExportFormat();
  }

  initConfig() {
    if (this.prevConfig != null && this.prevConfig?.id == this._config.id) return;

    this.prevConfig = JSON.parse(JSON.stringify(this._config));
    this._config.customerId = this.loginService.currentUser.customerId;
    this._config.catalogId = this.catalogId;
    this._config.useTextQualifier = true;

    if (this._config.templateId != null) {
      this.templateService.getExportTemplates().then(() => {
        this.templateService.setCurrentExportTemplate(this._config.templateId);
      });
    } else {
      this.templateService.setCurrentExportTemplate(null);
    }
    this.onTemplateChange.emit();
  }

  getConfigSummary(): string {
    var summary = this.translate.instant('Es sind folgende Einstellungen gesetzt:');
    return summary + this._config.getSummary(this.translate);
  }

  getClass() {
    return this._config.isDifferent(this.prevConfig) ? 'orange' : '';
  }

  onVersionChange(event: ValueChangedEvent) {
    if (event.value !== 'CIF_V3' && event.previousValue === 'CIF_V3') {
      this.templateService.getExportTemplates();
    } else if (event.value === 'CIF_V3' && event.previousValue !== 'CIF_V3') {
      this.templateService.getSAPAribaTemplates();
    }
  }

  setExportVersions() {
    this.exportVersions = this.exportFormatWithVersions
      .find((x) => x.exportFormat.text == this.config.format)
      ?.exportVersions.map((x) => x);
    if (!this.exportVersions) {
      this.exportVersions = [];
      if (this.exportFormatWithCustomers.key == this.config.format) {
        this.exportToCustomers = this.exportFormatWithCustomers.value;
        this.fixExportToCustomers();
      }
    } else {
      this.exportToCustomers = [];
      this.fixExportVersion();
    }
  }

  fixExportVersion() {
    if (!this.exportVersions?.some((x) => x.text == this._config.version)) {
      if (this._config.format == 'FIELDLIST' && this.exportVersions.some((x) => !x.disabled && x.text == 'XLSX'))
        this._config.version = this.exportVersions.find((x) => !x.disabled && x.text == 'XLSX')?.text;
      else this._config.version = this.exportVersions.filter((x) => !x.disabled)[0].text;
    }
  }

  fixExportFormat() {
    if (!this.exportFormats.some((x) => x.text == this._config.format)) {
      if (this.exportFormats.some((x) => x.text == 'FIELDLIST' && !x.disabled)) {
        this._config.format = 'FIELDLIST';
      } else this._config.format = this.exportFormats.filter((x) => !x.disabled)[0].text;
    }
  }

  fixExportToCustomers() {
    if (!this.exportToCustomers?.some((x) => x == this._config.version)) {
      this._config.version = this.exportToCustomers[0]?.id;
    }
  }

  onContentReady() {
    if (this._config.format == 'FIELDLIST') {
      this.selectedIndex = 3;

      this.cdRef.detectChanges();
    }
  }

  myItemTemplate(data, index, container) {
    if (data.disabled) {
      container.parentElement.classList.remove('dx-state-disabled');
      container.parentElement.classList.add('my-disabled-class');
    }
    return 'item';
  }

  getExportFormatInfoText(exportFormat: string): string {
    let result = null;
    let relevantExportVersions = this.exportFormatWithVersions
      ?.find((x) => x.exportFormat.text == exportFormat)
      ?.exportVersions?.map((x) => x.text);
    if (relevantExportVersions) {
      result = 'In diesem Export Format kann man diese Versionen benutzten: ' + relevantExportVersions.join(', ');
    }

    return result;
  }
}
