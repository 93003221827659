import { PimLicense } from "./PimLicense";

export class RegisterUser {
  constructor(
    public mail?: string,
    public password?: string,
    public repeatPassword?: string,
    public firstName?: string,
    public lastName?: string,
    public accountName?: string,
    public city?: string,
    public street?: string,
    public zipCode?: string,
    public linkedCustomerId?: string,
    public pimLicense: string = PimLicense.Onboarding,
    public sharedValidationMethodId?: string,
    public sharedCatalogId?: string,
    public exportAddons?: string[],
    public classificationAddons?: string[]
  ) {
  }
}
