<dx-data-grid class="h-100 px-2 pb-2"
              [dataSource]="modelService.classificationService.selectedClass.features"
              [remoteOperations]="false"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Datensätze' | translate }}"
              [showBorders]="true"
              (onRowUpdated)="update()"
              (onRowRemoved)="update()"
              (onRowInserted)="update()"
              [masterDetail]="{ enabled: true, template: 'detail' }">

  <dxo-editing mode="cell"
                [allowAdding]="true"
                [allowUpdating]="true"
                [allowDeleting]="true"
                [useIcons]="true">
    <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie den Eintrag wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Eintrag löschen' | translate }}"></dxo-texts>
  </dxo-editing>

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ClassificationFeaturesStateNewUIV2"></dxo-state-storing>

  <dxi-column dataField="order" caption="Order" [width]="50"></dxi-column>
  <dxi-column dataField="name" caption="Name"></dxi-column>
  <dxi-column dataField="unit" caption="Einheit" [width]="80"></dxi-column>
  <dxi-column dataField="isRequired" caption="Pflicht" [width]="80"></dxi-column>
  <dxi-column dataField="description" caption="Beschreibung"></dxi-column>


  <dxi-column dataField="valueType" caption="{{ 'Typ' | translate }}" [allowEditing]="false" cellTemplate="responsibleTemplate" [width]="100"></dxi-column>
  <div *dxTemplate="let feature of 'responsibleTemplate'">

    <dx-autocomplete class="dataGridAutocomplete"
                      [minSearchLength]="0"
                      [dataSource]="valueTypeSource"
                      [showDropDownButton]="true"
                      valueChangeEvent="blur"
                      [(value)]="feature.data.valueType">
    </dx-autocomplete>
  </div>

  <div *dxTemplate="let feature of 'detail'">


    <dx-data-grid class="internal-grid"
                  [dataSource]="feature.data.values"
                  [remoteOperations]="false"
                  [allowColumnReordering]="true"
                  [rowAlternationEnabled]="true"
                  [allowColumnResizing]="true"
                  noDataText="{{ 'keine Werte' | translate }}"
                  [showBorders]="true"
                  (onRowUpdated)="update()"
                  (onRowRemoved)="update()"
                  (onRowInserted)="update()">

      <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true">
        <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie dieses Wert wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Wert löschen' | translate }}"></dxo-texts>
      </dxo-editing>

      <dxo-paging [enabled]="false"></dxo-paging>

      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ClassificationFeaturesValuesStateNewUIV2"></dxo-state-storing>

      <dxi-column dataField="order" caption="{{ 'Nummer' | translate }}" (onValueChanged)="update()" alignment="center" [width]="150" [dataType]="number"></dxi-column>
      <dxi-column dataField="value" caption="{{ 'Wert' | translate }}" (onValueChanged)="update()"></dxi-column>
      <dxi-column dataField="valueId" caption="{{ 'Id' | translate }}" (onValueChanged)="update()"></dxi-column>
    </dx-data-grid>


  </div>
</dx-data-grid>
