<!--71: DeleteMediaByPurpose-->



<div class="dx-field">
  <div class="dx-field-label">{{ 'Verwendung' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedPurpose?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'MIME PURPOSE' | translate }}</label>
    <dx-autocomplete [(value)]="selectedPurpose"
                     [(dataSource)]="allPurposes"
                     [minSearchLength]="0"
                     [showClearButton]="true"
                     [showDropDownButton]="true">
    </dx-autocomplete>
  </div>
</div>
