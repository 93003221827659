import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { CategoryFilterTreeItem } from '../../../../../../Model/Catalog/exportFilter/categoryFilterTreeItem';
import { FeatureSystemFilter } from '../../../../../../Model/Dto/FeatureSystemFilter';
import { TreeItem } from '../../../../../../Model/Dto/TreeItem';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { SimpleHierachicalFilter } from '../../../../../../Model/exportParameter/SimpleHierachicalFilter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'udxContentFilter',
  templateUrl: './udxContentFilter.component.html',
  styleUrls: ['./udxContentFilter.component.css']
})
export class UdxContentFilterComponent implements OnInit {
  @ViewChild("treeViewUdxSelectFilter", { static: false }) treeViewUdxSelectFilter: DxTreeViewComponent;
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  udxSelectFilters: CategoryFilterTreeItem[];
  isFilterLoaded: boolean = false;

  constructor(public modelService: ModelService, public exportFilterService: ExportFilterService) {
    this.refreshUdxSelectFilter = this.refreshUdxSelectFilter.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.udxsContentFilter == null)
      this.exportParameter.udxsContentFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.udxsContentFilter.filterKey == null)
      this.exportParameter.udxsContentFilter.filterKey = [];

    this.refreshUdxSelectFilter();
  }

  refreshUdxSelectFilter() {
    if (!this.exportParameter.udxsContentFilter.useFilter)
      return;

    this.isFilterLoaded = false;
    this.exportFilterService.getUdxFilterTreeElements(this.catalogId, this.modelService.loginService.currentUser.customerId)
      .subscribe((filter: CategoryFilterTreeItem[]) => {
        this.udxSelectFilters = filter;
        this.isFilterLoaded = true;
      });
  }
}
