import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";

@Component({
  selector: "userAction-deleteDuplicateMimes",
  templateUrl: "userAction-deleteDuplicateMimes.component.html"
})
export class UserActionDeleteDuplicateMimesComponent extends UserActionBaseComponent {
  useOnlyMimeSource: boolean = false;

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important
  }

  startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `DeleteDuplicateMimes(useOnlyMimeSource:${this.useOnlyMimeSource.toString()})`;

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        //additional step

        this.userActionStarted.emit(next);
      });
  }
}
