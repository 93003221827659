import { CustomerWikiDetail } from "./CustomerWikiDetail";

export class CustomerWiki {

  constructor(
 
    public customerId?: string,
    public id?: string,
    public entries?: CustomerWikiDetail[],
  ) { }
}
