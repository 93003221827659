import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginService } from './login.service';
import { TeachingSet } from "../Model/OpenAi/teachingset";
import { TeachingSample } from "../Model/OpenAi/teachingsample";



@Injectable()
export class TeachingSetService {

  private _teachingSets?: TeachingSet[];
  private _selectedSet?: TeachingSet;
  private _selectedSample?: TeachingSample;
  
  get selectedSet(): TeachingSet {
    return this._selectedSet;
  }

  set selectedSet(value: TeachingSet) {
    this._selectedSet = value;
  }

  get selectedSample(): TeachingSample {
    return this._selectedSample;
  }

  set selectedSample(value: TeachingSample) {
    this._selectedSample = value;
  }

  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getSingle(id: string): Observable<TeachingSet> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("teachingSetId", id).append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<TeachingSet>("api/TeachingSet/GetSingle", options));
  }

  getAll(): Observable<TeachingSet[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<TeachingSet[]>("api/TeachingSet/GetAll", options));
  }

  getAllTrained(): Observable<TeachingSet[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<TeachingSet[]>("api/TeachingSet/GetAllTrained", options));
  }

  deleteSingle(id: string) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", id)
      }

      this.http.delete("api/TeachingSet/DeleteSingle", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateSingle(item: TeachingSet): Observable<TeachingSet> {
    return (this.http.post<TeachingSet>("api/TeachingSet/UpdateSingle", item));
  }

  addSingle(item: TeachingSet): Observable<TeachingSet> {
    return (this.http.post<TeachingSet>("api/TeachingSet/AddSingle", item));
  }

  startGenerationJob(item: TeachingSet): Observable<TeachingSet> {
    return (this.http.post<TeachingSet>("api/TeachingSet/StartGenerationJob", item));
  }
}
