<dx-data-grid class="h-100" (onToolbarPreparing)="onToolbarPreparing($event)" [dataSource]="status"
  [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
  noDataText="{{ 'keine Status im Account' | translate }}" [showBorders]="true"
  (onSelectionChanged)="onSelectionChanged($event)" (onRowRemoving)="deleteStatus($event)"
  (onRowUpdated)="update($event)">
  <dxo-selection mode="multiple"></dxo-selection>

  <div *dxTemplate="let toolbarItem of 'customDeleteButtonTemplate'">
    <dx-button stylingMode="text" type="default" text="{{ 'Auswahl löschen' | translate }}"
      (onClick)="deleteMultiple()"></dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'newStateButtonTemplate'">
    <dx-button stylingMode="text" type="default" text="{{ 'Neuer Status' | translate }}"
      (onClick)="addStatus()"></dx-button>
  </div>
  <dxo-editing mode="cell" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true"></dxo-editing>

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"
    placeholder="{{ 'Status suchen' | translate }}"></dxo-search-panel>

  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ProductStatesStateNewUIV2"></dxo-state-storing>

  <dxi-column dataField="name" caption="{{ 'Name' | translate }}" (onValueChanged)="update($event)"></dxi-column>

</dx-data-grid>