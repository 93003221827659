import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { custom } from 'devextreme/ui/dialog';

import { Product } from '../../../Model/Catalog/Product';
import { Message } from '../../../Model/System/Message';
import { Functions } from '../../../Services/functions';
import { MediaService } from '../../../Services/media.service';
import { ModelService } from '../../../Services/model.service';
import { TemplateService } from '../../../Services/template.service';
import { UserManagementService } from '../../../Services/userManagment.service';
import { ValidationService } from '../../../Services/Validation/validation.service';
import { ViewService } from '../../../Services/view.service';
import { Packaging } from '../../../Model/Catalog/Packaging';
import { ProductService } from '../../../Services/CatalogManagement/product.service';
import { CustomItemCreatingEvent } from 'devextreme/ui/select_box';

@Component({
  selector: 'productPackaging',
  templateUrl: 'packaging.component.html',
  styleUrls: ['../template.css']
})
export class Packagings implements OnInit {
  @Input() model: Packaging[] = [];
  @Input() product: Product = null;
  @Input() showLinkButtons: Boolean = false;
  @Input() catalogId: string = '';
  @Input() customerId: string = '';
  @Input() exportMode: Boolean = false;
  @Input() isTemplate: Boolean = false;
  @Output() onValueChanged = new EventEmitter<any>();
  @Output() reloadNedded = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();


  updateLock: boolean = false;
  newPackagingOrder = 0;
  unitDataSource: { value: string; displayValue: string }[] = [];
  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public validationService: ValidationService,
    public translate: TranslateService,
    public userManagement: UserManagementService,
    public mediaService: MediaService,
    public productService: ProductService,
  ) {
    this.addPackaging = this.addPackaging.bind(this);
    this.update = this.update.bind(this);
    this.reload = this.reload.bind(this);
  }

  ngOnInit(): void {


    this.model.forEach((x) => {
      x.guid = Functions.newGuid();
    });

    for (const mime of this.model) {
      if (mime.order > this.newPackagingOrder && mime.order < 1000) {
        this.newPackagingOrder = mime.order;
      }
    }
    this.newPackagingOrder += 1;
    this.productService?.getOrderUnitList().subscribe((unitList) => {
      this.initializeUnits(unitList);
    });
  }

  initializeUnits(unitList: string[]) {

    for (let i = 0; i < unitList.length; i++) {
      this.addUnitDataSourceItem(unitList[i], `${this.translate.instant(unitList[i])} (${unitList[i]})`);
    }

    for (let i = 0; i < this.model.length; i++) {

      if (this.unitDataSource.every((x) => x.value !== this.model[i].orderUnit)) {
        this.addUnitDataSourceItem(this.model[i].orderUnit, this.model[i].orderUnit);
      }
    }

    this.uniqueUnitDataSourceItems();
  }

  onCustomItemCreating(event: CustomItemCreatingEvent, order: number) {
    if (this.unitDataSource.every((x) => x.value !== event.text)) {
      this.addUnitDataSourceItem(event.text, event.text);
      this.uniqueUnitDataSourceItems();
    }
    this.model[order].orderUnit = event.text;
  }
  private addUnitDataSourceItem(value: string, displayValue: string) {
    this.unitDataSource.push({ value, displayValue });
  }

  private uniqueUnitDataSourceItems() {
    const existingItems = [];
    let i = 0;
    while (this.unitDataSource.length > i) {
      const item = JSON.stringify(this.unitDataSource[i]);
      if (existingItems.includes(item)) {
        this.unitDataSource.splice(i, 1);
      } else {
        existingItems.push(item);
        i++;
      }
    }
    this.unitDataSource = [...this.unitDataSource];
  }

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newPackagingButtonTemplate'
    });


  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, packChange = null, data?, field?: string, system: string = '-1', element: string = '-1') {
    //Order wird bereits benutzt
    if (field == 'PACK_ORDER') {
      let fs = this.model.filter((x) => x.guid == data.guid)[0];
      let originalOrder = event.previousValue;
      let change = event.value > event.previousValue ? 1 : -1;
      while (this.model.filter((x) => x.order == event.value).length > 1) {
        event.value += change;
        fs.order = event.value;
      }
      if (event.value < 1) {
        fs.order = originalOrder;
        return;
      }

      if (fs.order >= this.newPackagingOrder) {
        for (const pack of this.model) {
          if (pack.order > this.newPackagingOrder && pack.order < 1000) {
            this.newPackagingOrder = pack.order;
          }
        }
        this.newPackagingOrder += 1;
      }
    }



    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event.event == undefined) {
      event.event = new Object(); // für den template editor erforderlich, da sonst keine default-items erstellt werden
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }

    this.onValueChanged.emit(event);
  }

  delete(event = null) {
    let orderString = event.data.order.toString();


    for (let packField of this.templateService.allPackFields) {
      if (this.product != null && this.product.isFieldLinked(packField, orderString, '-1')) {
        this.product.toggleLinkedField(packField, orderString, '-1');
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allMimeFields;
    event.dDetailFields = null;
    event.dSystem = orderString;
    event.dElement = -1;
    this.elementDeleted.emit(event);
  }

  reload() {
    this.reloadNedded.emit();
  }

  addPackaging() {
    this.updateLock = true;
    var mime = new Packaging();

    mime.order = this.newPackagingOrder++;
    this.model.push(mime);
    this.update();
  }

  onContentReady(e: DxDataGridTypes.ContentReadyEvent) {
    this.updateLock = false;
    //if (this.model.length == 1) {
    //  e.component.expandRow(this.model[0]);
    
    //}
  }

  get allowDeleting() {
    //return !this.templateView;
    return true;
  }

  get mimeTypes() {
    return ViewService.dropDownMimeTypes;
  }

  get mimePurposes() {
    return ViewService.dropDownMimePurposes;
  }

  get mimeDescr() {
    return this.modelService.viewService.dropDownMimeDescr;
  }

  public importMimeVisible: boolean = false;
  showImportMime() {
    this.importMimeVisible = true;
  }
  hideImportMime() {
    this.importMimeVisible = false;
  }

  public createReportProductDatasheetVisible: boolean = false;
  showCreateReportProductDatasheet() {
    this.createReportProductDatasheetVisible = true;
  }

  hideCreateReportProductDatasheet() {
    this.createReportProductDatasheetVisible = false;
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  public onRowRemoving(e) {
    let orderString = e.data.order.toString();

    if (this.product != null && this.product.isChild && this.hasFieldLinked(orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = '';
      this.translate.get('DeleteLinkedFieldNotAllowed').subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, 'info'), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Wollen Sie die Verpackungseinheit wirklich löschen').subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith('?')) {
        // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + '?';
      }
    });
    this.translate.get('Wirklich löschen?').subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return false;
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }

  //ExcelTemplate

  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public hasFieldLinked(system?: string): boolean {
    for (let packField of this.templateService.allPackFields) {
      if (this.product != null && this.product.isFieldLinked(packField, system, '-1')) {
        return true;
      }
    }
    return false;
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (this.product != null && field != null) {
      var isLinked = this.product.isFieldLinked(field, system, element);
      if (isLinked && this.product.isChild != null && this.product.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    if (this.product != null) {
      return this.product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    if (this.product != null) {
      return this.product.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.product.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.product.getClassForLinkedField(isNowLinked);

    if (this.product.isChild && isNowLinked) {
      event.forceReload = true;
    }



    // SAVE
    this.update(event, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.product.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product != null && this.isVirtualCatalog()) || !this.userManagement.canEdit(name);
  }

  renderNewPackagingButton(): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.canView('PackagingNew');
  }


}

//nexmart Informationen:
//Diesem sind zwar 3 Produkt-Bilder zugewiesen im Eingangsfile. Aber es wird standardmäßig immer das Produktbild mit der höchsten Auflösung genommen und dann verdreifacht – das Eingangsfile wird zum Zoombild, daraus wird zusätzlich ein Produktbild und ein Thumbnail erstellt. Alle anderen Produktbilder, wenn sie nicht als weitere, Anwendungsbilder deklariert sind, verfallen.
//Als die „3“ Produktbilder wird immer eine Basis genutzt.Die Nutzung von 2 verschiedenen Bildern als Produktbild für einen Artikel geht nicht.
