import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { WawiSettings } from "../Model/User/WawiSettings";




@Injectable()
export class WawiSettingsService {


    constructor(private http: HttpClient, public translate: TranslateService) {

    }

 
   
  getWawiSettings(customerId: string): Observable<WawiSettings> {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("customerId", customerId).append("isLive", "true")
      }

    return (this.http.get<WawiSettings>("api/WawiSettings/GetWawiSettings", options)) as any;
    }

  

  update(settings: WawiSettings): Observable<number> {
    return (this.http.post("api/WawiSettings/Update", settings)) as any;

    }

   




}
