import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { WarrantyClass } from '../Model/Catalog/WarrantyClass';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: "warrantyclasses",
  templateUrl: "warrantyclasses.component.html"
})
export class WarrantyclassesComponent implements OnInit {
  model: WarrantyClass[];

  constructor(public modelService: ModelService, public translate: TranslateService) {

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Garantieklassen");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newClassButtonTemplate'
    });    
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.modelService.warrantyClassService.getClasses().subscribe(
      (result: WarrantyClass[]) => {
        this.model = result;
      });
  }

  onSelectionChangedClick(e) {
    this.modelService.warrantyClassService.selectedClass = e.data;
  }
  onDeleteClicked(e) {
    this.modelService.warrantyClassService.deleteClass(e.data);
  }

  addClass() {

    let warrantyClass = new WarrantyClass();
    warrantyClass.customerId = this.modelService.loginService.currentUser.customerId;

    this.modelService.warrantyClassService.addClass(warrantyClass).subscribe(
      (result: WarrantyClass) => {
        this.modelService.warrantyClassService.selectedClass = result;
        this.model.push(this.modelService.warrantyClassService.selectedClass);
      });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }
}
