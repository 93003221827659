import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { CreateReportProductDatasheet } from "../Model/Dto/CreateReportProductDatasheet";

@Component({
  selector: "productReportingDialogComponent",
  templateUrl: "productreportingdialog.component.html"
})
export class ProductReportingDialogComponent implements OnInit {

  @Input() customerId: string = "";
  @Input() catalogId: string = "";
  @Input() productId: string = "";

  @Output() onCreate = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  _allReports: string[];
  _selectedReport: string;
  _filenamePattern: string;
  _mimeDescr: string;
  _replaceAll: boolean = false;

  txtExportReportProductDatasheetShort: string;
  txtExportReportProductDatasheetLong: string;
  txtSelectReport: string;
  txtSelectFilenamePattern: string;

  constructor(public modelService: ModelService, public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.txtExportReportProductDatasheetShort = this.translate.instant("ReportProductDatasheetShort");
    this.txtExportReportProductDatasheetLong = this.translate.instant("ReportProductDatasheetLong");
    this.txtSelectReport = this.translate.instant("Bitte wählen Sie einen Report aus");
    this.txtSelectFilenamePattern = this.translate.instant("Bitte wählen Sie ein Muster für die Vergabe der Dateinamen aus");

    this.modelService.productReportingService.getUrls()
      .subscribe((items: string[]) => {
        this.allReports = items;
      });
  }



  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }



  get allReports(): string[] {
    return this._allReports;
  }

  set allReports(value: string[]) {
    this._allReports = value;
  }



  get selectedReport(): string {
    return this._selectedReport;
  }

  set selectedReport(value: string) {
    this._selectedReport = value;
  }



  get filenamePattern(): string {
    return this._filenamePattern;
  }

  set filenamePattern(value: string) {
    this._filenamePattern = value;
  }



  get mimeDescr(): string {
    return this._mimeDescr;
  }

  set mimeDescr(value: string) {
    this._mimeDescr = value;
  }



  get replaceAll(): boolean {
    return this._replaceAll;
  }

  set replaceAll(value: boolean) {
    this._replaceAll = value;
  }



  create() {
    if (this.selectedReport == "" || this.selectedReport == undefined) {
      this.modelService.systemService.notifyInfo(this.txtSelectReport);
      return;
    }
    if (this.filenamePattern == "" || this.filenamePattern == undefined) {
      this.modelService.systemService.notifyInfo(this.txtSelectFilenamePattern);
      return;
    }

    let dto = new CreateReportProductDatasheet(
      this.customerId,
      this.catalogId,
      this.productId,
      this.selectedReport,
      this.filenamePattern,
      this.mimeDescr,
      this.replaceAll
    );



    this.modelService.productService.createReportProductDatasheet(dto).subscribe(
      (result: any) => {
        this.onDone.emit();
      });



    this.selectedReport = "";
    this.filenamePattern = "";
    this.mimeDescr = "";
    this.replaceAll = false;

    this.onCreate.emit();
  }
}
