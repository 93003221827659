import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { ModelService } from "./model.service";

@Injectable()
export class EdcImportService {
  constructor(public modelService: ModelService, private http: HttpClient) { }

  public import(customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get("api/Edc/GetCatalog", options)) as any;
  }
}
