import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../Services/login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ClickEvent, OptionChangedEvent } from 'devextreme/ui/button';

@Component({
  selector: 'np-license-upgrade-button',
  templateUrl: './license-upgrade-button.component.html',
  styleUrl: './license-upgrade-button.component.css'
})
export class NpLicenseUpgradeButtonComponent {
  @Input() infoText: string = 'Nutzen Sie alle Funktionen von nextPIM und kontaktieren Sie unseren Vertrieb';
  @Input() disabled: boolean = false;
  @Input() additionalInfo: string = null;

  popoverVisible: boolean = false;
  calendarUrl = 'https://meetings-eu1.hubspot.com/marlies-peschke/60-min-30-minund-15-min-meeting-nextpim-sales';
  timestamp: string;

  constructor(public translate: TranslateService, public loginService: LoginService, private http: HttpClient) {
    this.timestamp = Date.now().toString();
  }

  togglePopover(e: ClickEvent) {
    this.popoverVisible = !this.popoverVisible;
    e.event.stopPropagation();
  }

  async contact() {
    window.open(this.calendarUrl, '_blank');
    await this.licenseUpgradeInfo(this.loginService.currentUser.email, this.loginService.currentCustomer.name);
  }

  licenseUpgradeInfo(email: string, account: string): Promise<object> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('email', email).append('account', account)
    };
    return lastValueFrom(this.http.post<object>('api/Account/licenseUpgradeInfo', null, options));
  }
}
