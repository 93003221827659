<div class="np-form-control dx-fieldset">
  <dx-validation-group id="LEGAL">
    <dx-box direction="row" crossAlign="stretch" width="100%" [ngStyle]="{ gap: '2rem', marginBottom: '2rem' }">
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Biozidbehandeltes Produkt' | translate }}"
              field="IS_BIOCIDAL_PRODUCT"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isBiocidalProduct"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_BIOCIDAL_PRODUCT') || readOnly('legalBiocidalProduct')"
                  [fieldName]="validationService.fieldNames.isBiocidalProduct"
                  (onValueChanged)="update($event, 'IS_BIOCIDAL_PRODUCT')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Selbstbedienungsverbot relevant' | translate }}"
              field="IS_SELFSERVICE_FORBIDDEN"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isSelfServiceForbidden"
                  [templateView]="templateView"
                  [readOnly]="
                    readOnlyForLinkedFields('IS_SELFSERVICE_FORBIDDEN') || readOnly('legalSelfserviceForbidden')
                  "
                  [fieldName]="validationService.fieldNames.isSelfServiceForbidden"
                  (onValueChanged)="update($event, 'IS_SELFSERVICE_FORBIDDEN')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Fertigpack Verordnung relevant ' | translate }}"
              field="IS_FERTIGPACKV"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isFertigpackv"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_FERTIGPACKV') || readOnly('legalFertigPackV')"
                  [fieldName]="validationService.fieldNames.isFertigpackv"
                  (onValueChanged)="update($event, 'IS_FERTIGPACKV')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Ökodesign EVPG ENVKG relevant' | translate }}"
              field="IS_OEKODESIGN_EVPG_ENVKG"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isOekodesignEvpgEnvkg"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_OEKODESIGN_EVPG_ENVKG') || readOnly('legalOekodesign')"
                  [fieldName]="validationService.fieldNames.isOekodesignEvpg"
                  (onValueChanged)="update($event, 'IS_OEKODESIGN_EVPG_ENVKG')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Grundstoffüberwachung relevant' | translate }}"
              field="IS_COMMODITIES_CONTROLL"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isCommoditiesControll"
                  [templateView]="templateView"
                  [readOnly]="
                    readOnlyForLinkedFields('IS_COMMODITIES_CONTROLL') || readOnly('legalCommoditiesControll')
                  "
                  [fieldName]="validationService.fieldNames.isCommoditiesControll"
                  (onValueChanged)="update($event, 'IS_COMMODITIES_CONTROLL')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'DetergenzienV relevantes Produkt' | translate }}"
              field="IS_DETERGENZIENV"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isDetergenzienv"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_DETERGENZIENV') || readOnly('legalDetergenzienV')"
                  [fieldName]="validationService.fieldNames.isDetergenzienv"
                  (onValueChanged)="update($event, 'IS_DETERGENZIENV')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Kosmetik Verordnung relevantes Produkt' | translate }}"
              field="IS_KOSMETIKV"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isKosmetikv"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_KOSMETIKV') || readOnly('legalKosmetikV')"
                  [fieldName]="validationService.fieldNames.isKosmetikv"
                  (onValueChanged)="update($event, 'IS_KOSMETIKV')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              direction="row"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="model?.productLogistic?.hazardousMaterialClassId?.length > 0"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Gefahrenstoffklasse' | translate }}"
              field="HAZMAT_ClASS"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-select-box
                  [items]="hazmatClasses"
                  placeholder=""
                  [showClearButton]="showClearButton()"
                  [(value)]="model.productLogistic.hazardousMaterialClassId"
                  [readOnly]="readOnlyForLinkedFields('HAZMAT_ClASS') || readOnly('legalHazmatClass')"
                  displayExpr="name"
                  [searchEnabled]="true"
                  valueExpr="id"
                  noDataText="{{ 'Keine Gefahrenstoffklassen angelegt.' | translate }}"
                  (onValueChanged)="update($event, 'HAZMAT_ClASS')"
                >
                  <dx-validator
                    *ngIf="!templateView"
                    validationGroup="LEGAL"
                    (onInitialized)="validationService.startAutomaticValidation($event)"
                  >
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField('HAZMAT_ClASS')"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'CE GS relevantes Produkt' | translate }}"
              field="IS_CE_GS"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isCeGs"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_CE_GS') || readOnly('legalCeGs')"
                  [fieldName]="validationService.fieldNames.isCeGs"
                  (onValueChanged)="update($event, 'IS_CE_GS')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'WEEE ROHS EAR relevant' | translate }}"
              field="IS_WEEE_ROHS_EAR"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isWeeeRohsEar"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_WEEE_ROHS_EAR') || readOnly('legalWEEROHSEAR')"
                  [fieldName]="validationService.fieldNames.isWeeeRohsEar"
                  (onValueChanged)="update($event, 'IS_WEEE_ROHS_EAR')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'REACH relevant' | translate }}"
              field="IS_REACH"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isReach"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_REACH') || readOnly('legalReach')"
                  [fieldName]="validationService.fieldNames.isReach"
                  (onValueChanged)="update($event, 'IS_REACH')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'VerpackungsV relevantes Produkt' | translate }}"
              field="IS_VERPACKUNGSV"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isVerpackungsv"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_VERPACKUNGSV') || readOnly('legalVerpackungsV')"
                  [fieldName]="validationService.fieldNames.isVerpackungsv"
                  (onValueChanged)="update($event, 'IS_VERPACKUNGSV')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Sicherheitsdatenblatt erforderlich' | translate }}"
              field="IS_SECURITY_DATASHEET_NEEDED"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isSecurityDatasheetNeeded"
                  [templateView]="templateView"
                  [readOnly]="
                    readOnlyForLinkedFields('IS_SECURITY_DATASHEET_NEEDED') || readOnly('legalSecurityDatasheetNeeded')
                  "
                  [fieldName]="validationService.fieldNames.isSecurityDatasheetNeeded"
                  (onValueChanged)="update($event, 'IS_SECURITY_DATASHEET_NEEDED')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Doppelverwendungsfähigkeit (dual use)' | translate }}"
              field="IS_DUAL_USE"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isDualUse"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_DUAL_USE') || readOnly('legalDualUse')"
                  [fieldName]="validationService.fieldNames.isDualUse"
                  (onValueChanged)="update($event, 'IS_DUAL_USE')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              [direction]="templateView ? 'column' : 'row'"
              [showLinkButtons]="showLinkButtons"
              [suppressPlaceLabel]="true"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'BattG relevant' | translate }}"
              field="IS_BATTERIE_V"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup
                  [(value)]="model.legalCharacteristic.isBatterieV"
                  [templateView]="templateView"
                  [readOnly]="readOnlyForLinkedFields('IS_BATTERIE_V') || readOnly('legalBatterieV')"
                  [fieldName]="validationService.fieldNames.isBatterieV"
                  (onValueChanged)="update($event, 'IS_BATTERIE_V')"
                  validatorValidationGroup="LEGAL"
                ></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl
              [(model)]="model"
              direction="row"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="model?.productLogistic?.warrantyClassId?.length > 0"
              [(selectedElements)]="selectedElements"
              (elementSelected)="elementSelectFunction($event)"
              (onValueChanged)="update($event)"
              labelText="{{ 'Garantieklasse' | translate }}"
              field="WARRANTY_ClASS"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-select-box
                  [items]="warrantyClasses"
                  placeholder=""
                  [showClearButton]="showClearButton()"
                  displayExpr="name"
                  valueExpr="id"
                  [searchEnabled]="true"
                  [(value)]="model.productLogistic.warrantyClassId"
                  [readOnly]="readOnlyForLinkedFields('WARRANTY_ClASS') || readOnly('legalWarrantyClass')"
                  noDataText="{{ 'Keine Garantieklassen angelegt.' | translate }}"
                  (onValueChanged)="update($event, 'WARRANTY_ClASS')"
                >
                  class="hint">
                  <dx-validator
                    *ngIf="!templateView"
                    validationGroup="LEGAL"
                    (onInitialized)="validationService.startAutomaticValidation($event)"
                  >
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField('WARRANTY_ClASS')"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </dx-validation-group>
</div>
