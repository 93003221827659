import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { ValidFeatureService } from "../../../../Services/validFeature.service";

@Component({
  selector: "userAction-harmonizeFeatureSystems",
  templateUrl: "userAction-harmonizeFeatureSystems.component.html"
})
export class UserActionHarmonizeFeatureSystems extends UserActionFeatureSystemsComponent {
  _destFeatureSystemName: string = "";
  featureSystemsWithOrder: any[] = [];

  constructor(public modelService: ModelService, validFeatureService: ValidFeatureService) {
    super(modelService, validFeatureService);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.featureSystemsWithOrder = this.featureSystems.sort((a, b)=> a.name.localeCompare(b.name));
    this.setOrders();
  }

  setOrders() {
    for (var i = 1; i <= this.featureSystemsWithOrder.length; i++) {
      this.featureSystemsWithOrder[i-1].order = i;
    }
  }

  startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "HarmonizeFeatureSystems";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify(this.featureSystemsWithOrder);
  }
}
