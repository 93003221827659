import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../../Services/model.service';
import { ExcelElement } from '../../../Model/ui/ExcelElement';
import { Product } from '../../../Model/Catalog/Product';
import { TemplateService } from '../../../Services/template.service';
import { Functions } from '../../../Services/functions';
import { FieldNames } from '../../../Model/FieldNames';
import { Message } from '../../../Model/System/Message';
import { SystemService } from '../../../Services/system.service';
import { MassDataChangeService } from '../../../Services/CatalogManagement/massDataChange.service';
import { DxTreeListComponent } from 'devextreme-angular';
import { TreeItem } from '../../../Model/Dto/TreeItem';
import { MassDataChangeParameter } from '../../../Model/Dto/MassDataChangeParameter';
import { Template } from '../../../Model/Catalog/Template';
import { Output, EventEmitter } from '@angular/core';
import { NextPimField } from '../../../Model/Catalog/NextPimField';
import { UserManagementService } from 'app/Services/userManagment.service';
import { PimLicense } from 'app/Model/User/PimLicense';

@Component({
  selector: 'massDataChange',
  templateUrl: './massDataChange.component.html',
  styleUrls: ['./massDataChange.component.css']
})
export class MassDataChangeComponent {
  @Input() treeList: DxTreeListComponent;
  @Input() emptyProduct: Product;

  @Output() cancel = new EventEmitter<any>();

  selectedElements: string[] = [];
  excelModel: ExcelElement[];
  private fieldNames: FieldNames;
  DataChanges: Map<string, string> = new Map<string, string>();
  _backupProduct: Product;
  messageSpidIntPidDefaultNotAllowed: any;
  messageSelectTemplate: string;
  deleteJobPopupVisible = false;
  deleteCategoriesEnabled = false;

  get isOnboarding() {
    return this.modelService.loginService.currentCustomer.pimLicense == PimLicense.Onboarding;
  }

  constructor(
    public userManagement: UserManagementService,
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public systemService: SystemService,
    public massDataChangeService: MassDataChangeService
  ) {
    this.fieldNames = new FieldNames();
    this.excelModel = [];
    this.templateService.template = new Template();
    this.messageSpidIntPidDefaultNotAllowed = this.translate.instant('messageSpidIntPidDefaultNotAllowed');
    this.messageSelectTemplate = this.translate.instant('Bitte ein Template auswählen') + '.';
    this.startJob = this.startJob.bind(this);
    this.addDefaultElement = this.addDefaultElement.bind(this);
    this.isUpdateModeField = this.isUpdateModeField.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.backClicked = this.backClicked.bind(this);
    this.startDeleteJob = this.startDeleteJob.bind(this);
    this.showDeleteJobPopup = this.showDeleteJobPopup.bind(this);
    this.hideDeleteJobPopup = this.hideDeleteJobPopup.bind(this);
    this.startDescriptionWizard = this.startDescriptionWizard.bind(this);
    this.createVariants = this.createVariants.bind(this);
    this.templateService.clearPimFieldEmitter.subscribe(async (x) => await this.clearPimField(x));
  }

  public back(idToSelectAfterGoingBack: string = undefined, isNewlyAdded: boolean = false) {
    this.templateService.template.templateItems = [];
    this.cancel.emit({ idToSelect: idToSelectAfterGoingBack, isNewlyAdded: isNewlyAdded });
  }

  backClicked() {
    this.back();
  }

  startDescriptionWizard() {
    this.modelService.catalogService.startMassGenerateKITexts.emit();
  }

  public startJob() {
    let productIds: string[] = [];
    let selectedData: TreeItem[] = this.treeList.instance.getSelectedRowsData();

    if (!selectedData || selectedData.length == 0) {
      this.systemService.notifyInfo('Es wurden keine Elemente ausgewählt.');
      return;
    }

    let cat = selectedData.filter((t) => t.type == 'cat');
    let grp = selectedData.filter((t) => t.type == 'grp');
    let pro = selectedData.filter((t) => t.type == 'pro');

    let templateItems = this.templateService.createTemplateItems(this.excelModel, false);
    //let masters = pro.filter(p => p.hasChilds);

    this.templateService.template.isExportTemplate = false;
    this.templateService.template.templateItems = this.templateService.createTemplateItems(this.excelModel, false);
    //this.templateService.updateTemplate(this.templateService.template);

    let param = new MassDataChangeParameter(
      cat.length > 0,
      grp.map((x) => x.id.toString()),
      pro.map((x) => x.id.toString()),
      this.templateService.template
    );

    this.massDataChangeService.startJob(this.modelService.catalogService.catalog.id, param).subscribe(() => {
      this.modelService.jobService.feedRefreshJobs(
        this.modelService.loginService.currentCustomer.id,
        this.modelService.catalogService.selectedCatalogId
      );
      this.modelService.systemService.notify(
        new Message(this.translate.instant('Änderungen wurden als Job gestartet')),
        1500
      );
      this.back();
    });
  }

  public get product() {
    if (this.emptyProduct) {
      // this.modelService.productService.createWawiIfNeeded(this._product, this.modelService.loginService.wawiSettings);
      return this.emptyProduct;
    }

    this.buildListsAndTables();
    return this.emptyProduct;
  }

  private buildListsAndTables() {
    if (this.emptyProduct == null || this.emptyProduct == undefined) {
      return;
    }

    this.emptyProduct.featureSystems.forEach((item) => {
      item.featureSystemList = this.templateService.createListForSystem(item);
      item.featureSystemTable = this.templateService.createTableForSystem(item);
    });

    this.emptyProduct.featurelist = this.templateService.createTableForAllSystems(this.emptyProduct.featureSystems);
    this.emptyProduct.descWithoutHTML = Functions.stripHTML(this.emptyProduct.descriptionLong);
    this.emptyProduct.groupString = this.templateService.createGroupString(this.excelModel);
  }

  public async addDefaultElement(e) {
    if (e == null || e.event == undefined) return;

    let field = e.dField;
    let system = e.dSystem;
    let element = e.dElement;
    let value = e.value;

    if (field == null || field == undefined) return;

    if (
      field == this.fieldNames.supplierPid ||
      field == this.fieldNames.internatonalPid ||
      field == this.fieldNames.supplierInternationalId ||
      field == this.fieldNames.pid
    ) {
      this.systemService.notify(new Message(this.messageSpidIntPidDefaultNotAllowed, 'info'), 3000);
      return;
    }

    if (field == this.fieldNames.featureSystemOrder || field == this.fieldNames.fOrder) {
      if (system.length != 24) {
        this.templateService.changeKey(
          this.emptyProduct,
          e.previousValue,
          e.value,
          system,
          element,
          field,
          this.excelModel
        );
        this.updateTemplate(null);
        return;
      }
    }

    if (
      field == this.fieldNames.featureSystemName ||
      field == this.fieldNames.fName ||
      field == this.fieldNames.fUnit
    ) {
      this.templateService.changeKey(
        this.emptyProduct,
        e.previousValue,
        e.value,
        system,
        element,
        field,
        this.excelModel
      );
    }

    if (field == 'MIME_ORDER' || field == 'PRICE_LIST_ORDER' || field == 'PRICE_ORDER') {
      this.templateService.changeKey(
        this.emptyProduct,
        e.previousValue,
        e.value,
        system,
        element,
        field,
        this.excelModel
      );
      this.updateTemplate(null);
      return;
    }

    if (!e.createTemplateItem) {
      return;
    }

    this.templateService.refresExcelElementhOrder(this.excelModel);

    let count = this.excelModel.length;

    let excelElement = new ExcelElement();

    if (this.templateService.hasExcelElement(this.excelModel, field, system, element)) {
      excelElement = this.templateService.getExcelElement(this.excelModel, field, system, element);
      this.templateService.clearPreviewRow1Row2(excelElement);
      this.templateService.clearOptions(excelElement);
    } else {
      excelElement.id = (count + 1).toString();
      excelElement.pimFields.push(new NextPimField(element, system, field));
      excelElement.title = null;

      this.templateService.clearPreviewRow1Row2(excelElement);
      this.excelModel.push(excelElement);
    }

    if (field == this.fieldNames.descriptionLong) {
      // bei dem HTML Editor kommt es nicht in e.value mit...
      value = await this.templateService.getValueFromProduct(
        this.emptyProduct,
        false,
        false,
        excelElement,
        field,
        system,
        element,
        '|'
      );
    } else if (!this.isUpdateModeField(field)) {
      if (value == null || value == undefined) {
        value = '';
      } else {
        value = await this.templateService.getValueFromProduct(
          this.emptyProduct,
          false,
          false,
          excelElement,
          field,
          system,
          element,
          '|'
        );
      }
    }

    this.templateService.setOptions(excelElement, value, '', '', '', '|');
    this.updateTemplate(null);
  }

  private isUpdateModeField(field: string): boolean {
    if (
      field == this.fieldNames.featureUpdateMode ||
      field == this.fieldNames.mimeUpdateMode ||
      field == this.fieldNames.priceUpdateMode ||
      field == this.fieldNames.udxUpdateMode ||
      field == this.fieldNames.supplierUpdateMode
    ) {
      return true;
    }
    return false;
  }

  public async updateTemplate(e) {
    console.log('updated Template');
    this.templateService.template.isExportTemplate = false;
    this.templateService.template.templateItems = this.templateService.createTemplateItems(this.excelModel, false);
    //this.templateService.updateTemplate(this.templateService.template);

    // Das ist nicht nötig (wir haben daraus grade das Template erzeugt & gespeichert)
    // UND es führt zu dem unerwünschten Verhalten s. Ticket https://nextpim.myjetbrains.com/youtrack/issue/nPIM-789
    //this.excelModel = this.templateService.createExcelElements(this.templateService.template.templateItems, this.templateService.template.isUseable);

    for (let item of this.excelModel) {
      if (item.defaultValue != '') {
        // brauchen wir gar nicht. wert passt ja schon.
        // bei einigen Controls wird eine Endlosschleife ausgelöst...
        //let value = await this.templateService.getValueFromProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.seperator);
        //if (item.defaultValue != value) {
        //  this.templateService.setValueOnProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.defaultValue, item.seperator, true);
        //}
        // UPDATEMODE TODO HERE ????
      } else {
        // Bei neuen Elementen wurde der Seperator (naturgemäß) noch gar nicht gesetzt
        // Strings werden bei "" gesplittet:
        // Aus 1 z.B.Status "blau" werden 4 Status "b" "l" "a" "u"

        if (item.pimFields.findIndex((x) => this.templateService.needsStandardSeperator(x.field)) != -1) {
          if (item.seperator == null || item.seperator == undefined || item.seperator == '') {
            item.seperator = '|';
          }
        }
        item.pimFields.forEach((x) => {
          this.templateService.setValueOnProduct(
            this.emptyProduct,
            true,
            false,
            item,
            x.field,
            x.systemKey,
            x.elementKey,
            item.row1,
            this.excelModel,
            item.seperator,
            true
          );
        });
      }

      //this.templateService.setValueOnProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.row1, item.seperator, true);
    }
  }

  //Mime - Template Product
  private _mimeUpdateMode: string = 'NORMAL';

  get mimeUpdateMode(): string {
    return this._mimeUpdateMode;
  }

  @Input()
  set mimeUpdateMode(value: string) {
    this._mimeUpdateMode = value;
  }

  mimeUpdateModeChanged(e) {
    if (this.mimeUpdateMode == e.selectedItem) {
      return;
    }

    e.dField = this.fieldNames.mimeUpdateMode;
    e.dSystem = -1;
    e.dElement = -1;
    e.value = e.selectedItem;
    e.event = this.fieldNames.mimeUpdateMode; // sonst steigt addDefaultElement(e); aus...

    this.addDefaultElement(e);

    this.mimeUpdateMode = e.selectedItem;
  }

  //Feature - TemplateProduct Component
  private _featureUpdateMode: string = 'NORMAL';

  get featureUpdateMode(): string {
    return this._featureUpdateMode;
  }

  @Input()
  set featureUpdateMode(value: string) {
    this._featureUpdateMode = value;
  }

  featureUpdateModeChanged(e) {
    if (this.featureUpdateMode == e.selectedItem) {
      return;
    }

    e.dField = this.fieldNames.featureUpdateMode;
    e.dSystem = -1;
    e.dElement = -1;
    e.value = e.selectedItem;
    e.event = this.fieldNames.featureUpdateMode; // sonst steigt addDefaultElement(e); aus...

    this.addDefaultElement(e);

    this.featureUpdateMode = e.selectedItem;
  }

  // Price - Template Product
  private _priceUpdateMode: string = 'NORMAL';

  get priceUpdateMode(): string {
    return this._priceUpdateMode;
  }

  @Input()
  set priceUpdateMode(value: string) {
    this._priceUpdateMode = value;
  }

  priceUpdateModeChanged(e) {
    if (this.priceUpdateMode == e.selectedItem) {
      return;
    }

    e.dField = this.fieldNames.priceUpdateMode;
    e.dSystem = -1;
    e.dElement = -1;
    e.value = e.selectedItem;
    e.event = this.fieldNames.priceUpdateMode; // sonst steigt addDefaultElement(e); aus...

    this.addDefaultElement(e);

    this.priceUpdateMode = e.selectedItem;
  }

  //Udx - Template Product
  private _udxUpdateMode: string = 'NORMAL';

  get udxUpdateMode(): string {
    return this._udxUpdateMode;
  }

  @Input()
  set udxUpdateMode(value: string) {
    this._udxUpdateMode = value;
  }

  udxUpdateModeChanged(e) {
    if (this.udxUpdateMode == e.selectedItem) {
      return;
    }

    e.dField = this.fieldNames.udxUpdateMode;
    e.dSystem = -1;
    e.dElement = -1;
    e.value = e.selectedItem;
    e.event = this.fieldNames.udxUpdateMode; // sonst steigt addDefaultElement(e); aus...

    this.addDefaultElement(e);

    this.udxUpdateMode = e.selectedItem;
  }

  public async clearPimField(pimField: NextPimField, needRefresh: boolean = true) {
    let isFeatureKey =
      pimField.field == this.fieldNames.fName ||
      pimField.field == this.fieldNames.featureSystemName ||
      pimField.field == this.fieldNames.fUnit;

    let matchingExcelItem = this.excelModel.filter((x) =>
      x.pimFields.some(
        (y) =>
          (isFeatureKey || y.field == pimField.field) &&
          y.elementKey == pimField.elementKey &&
          y.systemKey == pimField.systemKey
      )
    );

    for (let excelItem of matchingExcelItem) {
      let pimFieldsToRemove = excelItem.pimFields.filter(
        (y) =>
          (isFeatureKey || y.field == pimField.field) &&
          y.elementKey == pimField.elementKey &&
          y.systemKey == pimField.systemKey
      );

      for (const x of pimFieldsToRemove) {
        await this.templateService.setValueOnProduct(
          this.product,
          false,
          false,
          excelItem,
          x.field,
          x.systemKey,
          x.elementKey,
          null,
          this.excelModel,
          null,
          true
        );
      }

      excelItem.pimFields = excelItem.pimFields.filter(
        (y) =>
          !(
            (isFeatureKey || y.field == pimField.field) &&
            y.elementKey == pimField.elementKey &&
            y.systemKey == pimField.systemKey
          )
      );
      this.templateService.clearOptions(excelItem);
    }

    this.excelModel = this.excelModel.filter((x) => x.pimFields.length > 0 || (x.title != null && x.title != ''));

    this.templateService.clearPimFieldEmitterDone.emit();

    if (needRefresh) await this.updateTemplate(null);
  }

  public elementDelete(e) {
    let fields = e.dFields;
    let detailFields = e.dDetailFields;
    let system = e.dSystem;
    let element = e.dElement;

    let needsUpdate = false;

    for (let field of fields) {
      let found = this.excelModel.find(
        (i) => i.pimFields.findIndex((x) => x.field == field && x.systemKey == system) != -1
      );
      needsUpdate = this.removeOrClearExcelElement(found, needsUpdate);
    }
    // wenn ein übergeordneter datensatz gelöscht wurde, müssen auch die zugehörigen details entfernt werden
    if (needsUpdate && detailFields != null) {
      for (let field of detailFields) {
        // es müssen immer alle entfernt werden die zu dem system gehören
        // ==> filter, nicht find!!
        let found = this.excelModel.filter(
          (i) => i.pimFields.findIndex((x) => x.field == field && x.systemKey == system) != -1
        );

        for (let item of found) {
          needsUpdate = this.removeOrClearExcelElement(item, needsUpdate);
        }
      }
    }
  }

  public showDeleteJobPopup() {
    this.deleteJobPopupVisible = true;
    this.deleteCategoriesEnabled = false;
  }

  public hideDeleteJobPopup() {
    this.deleteJobPopupVisible = false;
    this.deleteCategoriesEnabled = false;
  }

  public startDeleteJob() {
    let selectedData: TreeItem[] = this.treeList.instance.getSelectedRowsData();

    const categories = selectedData.filter((t) => t.type == 'grp');
    const products = selectedData.filter((t) => t.type == 'pro');

    this.templateService.template.isExportTemplate = false;
    this.templateService.template.templateItems = this.templateService.createTemplateItems(this.excelModel, false);

    const param = new MassDataChangeParameter(
      false,
      categories.map((x) => x.id.toString()),
      products.map((x) => x.id.toString()),
      null,
      true,
      this.deleteCategoriesEnabled
    );
    this.massDataChangeService.startJob(this.modelService.catalogService.catalog.id, param).subscribe(async (value) => {
      let productsCount = products.length;
      const categoriesCount = categories.length;

      if (categoriesCount === 0) {
        this.modelService.jobService.refreshJobFeedbackText = this.translate.instant('Es wurden Produkte gelöscht', {
          count: productsCount
        });
      } else {
        productsCount = products.length + categories.reduce((s, x) => s + x.productCount, 0);
        this.modelService.jobService.refreshJobFeedbackText = this.translate.instant(
          'Es wurden Produkte und Kategorien gelöscht',
          { productsCount, categoriesCount }
        );
      }

      this.modelService.jobService.feedRefreshJobs(
        this.modelService.loginService.currentCustomer.id,
        this.modelService.catalogService.selectedCatalogId
      );
      this.modelService.systemService.notify(
        new Message(this.translate.instant('Löschen wird durchgeführt. Bitte warten...')),
        1500
      );
      this.back();
    });
  }

  public removeOrClearExcelElement(excelElement: ExcelElement, needsUpdate: boolean): boolean {
    let index = this.excelModel.indexOf(excelElement);
    if (index >= 0) {
      if (excelElement.title == null || excelElement.title == undefined || excelElement.title == '') {
        this.excelModel.splice(index, 1);
      } else {
        excelElement.pimFields = [];
      }
      needsUpdate = true;
    }
    return needsUpdate;
  }

  public elementSelectFunction(e) {
    this.selectedElements = e;
  }

  public excelElementDrop(e) {
    let field = e.newField;
    let system = e.newSystem;
    let element = e.newElement;

    this.addElementFromExcel(field, system, element, e.fromIndex);
  }

  public async addElementFromExcel(field: string, system: string, element: string, index: number) {
    // eig. ist es ein replace... just naming...
    if (this.templateService.hasExcelElement(this.excelModel, field, system, element)) {
      let existing = this.templateService.getExcelElement(this.excelModel, field, system, element);
      let existingIndex = this.excelModel.indexOf(existing);

      this.excelModel[existingIndex].pimFields = [];
      this.templateService.clearOptions(this.excelModel[existingIndex]);
    }

    let existing = this.excelModel[index];

    // kommt nur im import mode vor => wert im produkt zurücksetzen (leeren)
    existing.pimFields.forEach((x) => {
      this.templateService.setValueOnProduct(
        this.emptyProduct,
        false,
        false,
        existing,
        x.field,
        x.systemKey,
        x.elementKey,
        null,
        this.excelModel,
        null,
        true
      );
    });

    if (
      this.excelModel[index].pimFields.findIndex(
        (x) => x.field == field && x.systemKey == system && x.elementKey == element
      ) == -1
    )
      this.excelModel[index].pimFields.push(new NextPimField(element, system, field));

    this.templateService.clearOptions(this.excelModel[index]);
    this.updateTemplate(null);
  }

  async createVariants() {
    let selectedData: TreeItem[] = this.treeList.instance.getSelectedRowsData();

    const categories = selectedData.filter((t) => t.type == 'grp');
    const products = selectedData.filter((t) => t.type == 'pro');
    const master = selectedData.filter((t) => t.hasChilds && t.type == 'pro');
    const variants = selectedData.filter((t) => t.isChild && t.type == 'pro');

    if (categories.length > 1) {
      this.systemService.notify(
        new Message(this.translate.instant('Es darf maximal nur eine Kategorie ausgewählt werden')),
        1500
      );
      return;
    }

    if (master.length > 0) {
      this.systemService.notify(new Message(this.translate.instant('Es dürfen keine Master ausgewählt werden')), 1500);
      return;
    }

    if (variants.length > 0) {
      this.systemService.notify(
        new Message(this.translate.instant('Es dürfen keine Varianten ausgewählt werden')),
        1500
      );
      return;
    }

    if (products.length == 0 && categories.length == 0) {
      this.systemService.notify(
        new Message(this.translate.instant('Es muss mindestens ein Produkt ausgewählt werden')),
        1500
      );
      return;
    }

    if (
      !products.every((x) => x.parent == products[0].parent) ||
      (categories.length == 1 && !products.every((x) => x.parent == categories[0].id))
    ) {
      this.systemService.notify(
        new Message(this.translate.instant('Die ausgewählten Produkte müssen in der gleichen Kategorie sein')),
        1500
      );
      return;
    }

    if (products.some((x) => x.catalogMappingCount > 1)) {
      this.systemService.notify(
        new Message(
          this.translate.instant(
            'Eines der ausgewählten Produkte existiert in mehreren Kategorien und kann daher nicht zu einer Variante umgewandelt werden'
          )
        ),
        1500
      );
      return;
    }

    var productIds = products.map((x) => x.id.toString());
    var categoryId = categories[0]?.id?.toString();
    if (!categoryId) {
      categoryId = "";
    }

    try {
      var createdMaster = await this.massDataChangeService.createVariants(
        this.modelService.catalogService.catalog.id,
        categoryId,
        productIds
      );
      this.systemService.notify(
        new Message(this.translate.instant('Der Master wurde erstellt und Produkte verschoben')),
        1500
      );
      this.back(createdMaster.id, true);
    } catch (error) {
      if (error.status === 400 && error.error == 'Already exisiting Master in Category') {
        this.systemService.notify(
          new Message(this.translate.instant('Es dürfen keine Master ausgewählt werden')),
          1500
        );
        return;
      }
    }
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
}
