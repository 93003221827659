import { Component, OnInit } from "@angular/core";

import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { CatalogPriceList } from "../../Model/Catalog/CatalogPriceList";
import { StartAction } from '../../Model/Dto/StartAction';
import { Message } from '../../Model/System/Message';
import { UserManagementService } from "../../Services/userManagment.service";

@Component({
  selector: "catalogPriceList",
  templateUrl: "catalogPriceList.component.html",
  styleUrls: []
})
export class CatalogPriceListComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) { }

  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newPricelistButtonTemplate'
    });
  }

  ngOnInit(): void {}

  changed(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  update() {
    this.modelService.catalogService.updateCatalog();
  }

  txtStartedAsJob: string = "";
  removePriceList(e) {

   let order = e.data.order;

    const startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;   
    this.txtStartedAsJob = this.translate.instant("Die Preisliste wird bei allen Produkten über einen Job gelöscht.");

    startAction.actionId = `DeletePriceList(${order})`;
    this.modelService.jobService.startUserAction(startAction)
      .subscribe(success => {
        if (success) {
          this.modelService.systemService.notify(new Message(this.txtStartedAsJob));
        }
      });

    this.modelService.catalogService.updateCatalog();
  }


  get model() {
    return this.modelService.catalogService.catalog;
  }

  addPriceList() {
    var catalogPriceList = new CatalogPriceList();
    var priceListNumber = 0;
    for (let priceList of this.model.priceLists) {
      if (priceList.order > priceListNumber) {
        priceListNumber = priceList.order;
      }
    }
    priceListNumber += 1;
    catalogPriceList.order = priceListNumber;
    this.model.priceLists.push(catalogPriceList);
    this.update();
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
