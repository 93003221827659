import { Input,Component } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { Mime } from "../../../Model/Catalog/Mime";
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from "angular-oauth2-oidc";
import { ValidationService } from "../../../Services/Validation/validation.service";

@Component({
  selector: "importMime",
  templateUrl: "importMime.component.html"
})
export class ImportMimeComponent {
  @Input() isTemplate: Boolean = false;
  value: any[];
  uploadHeaders: any;
  msg: string = "";

  constructor(private modelService: ModelService, public translate: TranslateService, public validationService: ValidationService, private oAuthService: OAuthService,) {
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);

    this.txtHochgeladen = this.translate.instant("hochgeladen");
    this.reset();
  }

  reset() {
    this.uploadHeaders = {
      CatalogId: this.modelService.catalogService.selectedCatalogId,
      CustomerId: this.modelService.loginService.currentUser.customerId,
    }
    this.value = [];
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded(e) {
    this.msg = e.file.name.substring(0, 15) + " " + this.txtHochgeladen;
    this.reset();
  }

  onUploadStarted(e) {
    var mime = new Mime();
    var mimeOrder = 0;
    for (let mime of this.model.mimes) {
      if (mime.mimeOrder > mimeOrder) {
        mimeOrder = mime.mimeOrder;
      }
    }
    mimeOrder += 1;
    mime.mimeOrder = mimeOrder;
    mime.mimeSource = e.file.name;
    mime.mimeType = e.file.type;
    if (this.isTemplate) {
      this.model.mimeTemplate.push(mime);
    } else {
      this.model.mimes.push(mime);
    }
    this.update();
  }

  update() {
    if (this.modelService.catalogService.isCatalogSelected) {
      this.modelService.catalogService.updateCatalog();
    }
    if (this.modelService.catalogService.isCategorySelected) {
      this.modelService.catalogService.updateCategory();
    }
    if (this.modelService.catalogService.isProductSelected) {
      this.modelService.catalogService.updateProduct().then(() => {
        this.validationService.validateProduct(this.modelService.catalogService.product);
      });
      
    }
  }

  get model() {
    if (this.modelService.catalogService.isCatalogSelected) {
      return this.modelService.catalogService.catalog;
    }
    if (this.modelService.catalogService.isCategorySelected) {
      return this.modelService.catalogService.category;
    }
    if (this.modelService.catalogService.isProductSelected) {
      return this.modelService.catalogService.product;
    }
  }

  txtHochgeladen: string;
}
