import { Component, Input, OnInit } from '@angular/core';
import { CategoryFilterTreeItem } from '../../../../../../Model/Catalog/exportFilter/categoryFilterTreeItem';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'pricelistContentFilter',
  templateUrl: './pricelistContentFilter.component.html',
  styleUrls: ['./pricelistContentFilter.component.css']
})
export class PricelistContentFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  filterKeys: Set<string>;
  priceListFilters: CategoryFilterTreeItem[];
  isPriceListFiltersLoaded = false;

  exportOnRequestPrices = false;

  constructor(public modelService: ModelService, public exportFilterService: ExportFilterService) { }

  ngOnInit(): void {
    if (this.exportParameter.priceListsContentFilter == null)
      this.exportParameter.priceListsContentFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.priceListsContentFilter.filterKey == null)
      this.exportParameter.priceListsContentFilter.filterKey = [];
    this.filterKeys = new Set(this.exportParameter.priceListsContentFilter.filterKey);
    this.refreshPriceListFilter();
  }

  onToggle(key: string) {
    if (this.filterKeys.has(key)) {
      this.filterKeys.delete(key);
    } else {
      this.filterKeys.add(key);
    }
    this.refreshPriceListFilter();
  }

  refreshPriceListFilter() {
    if (!this.exportParameter.priceListsContentFilter.useFilter)
      return;

    const filterKey = this.exportParameter.priceListsContentFilter.filterKey.filter(s => s !== "");
    this.exportParameter.priceListsContentFilter.filterKey = Array.from(new Set([...filterKey, ...Array.from(this.filterKeys)]));
    this.exportFilterService.getPriceLists(this.catalogId, this.modelService.loginService.currentUser.customerId)
      .subscribe((filter: CategoryFilterTreeItem[]) => {
        this.priceListFilters = filter;
        this.isPriceListFiltersLoaded = true;
      });
  }

}
