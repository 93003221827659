import { FeatureSystem } from "../Catalog/FeatureModels/FeatureSystem";

export class TeachingSample {
  constructor(
    public descriptionShort?: string,
    public descriptionLong?: string,
    public groupString?: string,
    public manufacturer?: string,
    public keyWords?: string[],
    public featureSystem?: FeatureSystem) { }
}
