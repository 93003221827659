import { ProductPriceDetail } from "./ProductPriceDetail";
import { Functions } from '../../Services/functions';

export class PriceList {

  constructor(
    public priceListOrder?: number,
    public start?: Date,
    public end?: Date,
    public isDailyPrice: boolean = false,
    public productPriceDetails: ProductPriceDetail[] = [],
    public guid: string = Functions.newGuid()
  ) {
    this.start = new Date(new Date().getFullYear(), 0, 1);
    this.end = new Date(new Date().getFullYear(), 11, 31);
  }
}
