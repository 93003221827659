
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { UpdateTemplate } from '../Model/Catalog/UpdateTemplate';
import { LoginService } from './login.service';
import { ProductState } from '../Model/Catalog/ProductState';


@Injectable()
export class ProductStateService {


  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getStatus(): Observable<ProductState[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.get<ProductState>("api/ProductState/GetProductStates", options)) as any;
  }

  productStates : string[] = new Array<string>();

  deleteProductState(id: string) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", id)
      }

      this.http.delete("api/ProductState/DeleteProductState", options)
        .subscribe();
    }
  }

  updateProductState(productState: ProductState): Observable<string> {
    return (this.http.post("api/ProductState/UpdateProductState", productState)) as any;
  }

  addProductState(productState: ProductState): Observable<ProductState> {
    return (this.http.post("api/ProductState/AddProductState", productState)) as any;
  }



}
