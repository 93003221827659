import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestoreBackup } from "../../Model/Dto/RestoreBackup";
import { Backup } from "../../Model/System/Backup";
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';

@Injectable()
export class BackupService {
  constructor(private http: HttpClient, 
   
  ) {
  }

  deleteBackup(id) {
    this.http.delete("api/Backup/DeleteBackup/" + id ).subscribe();

  }

  getBackups(customerId: string): Observable<Backup[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return this.http.get<Backup[]>("api/backup/getbackups", options);
  }

  restoreBackup(restoreBackup: RestoreBackup) {
    this.http.post("api/Backup/RestoreBackup", restoreBackup)
      .subscribe(success => {
        if (success) {
        }
      }); }

  getMediaJob(backupId: string, customerId: string) {
    let restoreBackup = new RestoreBackup();
    restoreBackup.backupId = backupId;
    restoreBackup.customerId = customerId;
    restoreBackup.restoreMedia = true;
    this.http.post("api/Backup/CreateMediaJob", restoreBackup)
      .subscribe(success => {
        if (success) {
        }
      });

  }
}
