<fullscreen-page>
  <content-column *ngIf="template" look="dark" helpSwitch="true">
    <div slot="content" class="d-flex flex-column h-100 p-2 overflow-auto">
      <div class="d-flex m-3 gap-2">
        <dx-button
          *ngIf="catalogId"
          stylingMode="text"
          type="default"
          text="{{ 'Zurück zum Katalog' | translate }}"
          [routerLink]="['/catalog', customerId, catalogId]"
        ></dx-button>
        <dx-button
          *ngIf="!catalogId"
          stylingMode="text"
          type="default"
          text="{{ 'Zurück zur Template Liste' | translate }}"
          routerLink="/templates"
        ></dx-button>
        <dx-button
          *ngIf="template.templateType !== 'SAP_ARIBA'"
          text="{{ 'Worksheet hinzufügen' | translate }}"
          icon="add"
          type="default"
          (onClick)="onAddWorksheet($event)"
        ></dx-button>
      </div>

      <div *ngIf="template.templateType !== 'SAP_ARIBA'" class="d-flex align-items-center gap-3 m-4">
        Kopfeinstellungen hinzufügen
        <dx-switch [(value)]="template.useMetadata" (onOptionChanged)="update()"></dx-switch>

        <dx-button *ngIf="template.useMetadata && isHeaderVisible" type="normal" (onClick)="isHeaderVisible = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-eye"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
            />
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
          </svg>
        </dx-button>

        <dx-button *ngIf="template.useMetadata && !isHeaderVisible" type="normal" (onClick)="isHeaderVisible = true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-eye-slash"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"
            />
            <path
              d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"
            />
            <path
              d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"
            />
          </svg>
        </dx-button>
      </div>

      <!-- <div *ngIf="template.useMetadata" class="d-flex align-items-center gap-3 m-4">
        SAP Ariba Template
        <dx-switch [value]="template.templateType" (onValueChanged)="onSavingSAPAriba()"></dx-switch>
      </div> -->

      <div class="mx-3 my-5" *ngIf="isHeaderVisible && template.templateType != 'SAP_ARIBA'">
        <np-advanced-template-header [template]="template" (onUpdate)="update($event)"></np-advanced-template-header>
      </div>

      <dx-tab-panel
        class="sticky-top"
        width="100%"
        height="100%"
        [dataSource]="sheetItemWrapper"
        tabsPosition="top"
        stylingMode="secondary"
        (onTitleClick)="onSelectWorksheet($event)"
      >
        <div *dxTemplate="let data of 'title'; let index = index">
          <dx-text-box
            *ngIf="sheetNameEditorIndex == index && !data.isHeader"
            style="min-width: 215px"
            [(value)]="data.name"
            (onValueChanged)="onSaveWorksheetRename($event)"
          ></dx-text-box>
          <div
            *ngIf="sheetNameEditorIndex != index || data.isHeader"
            style="min-width: 215px; padding: 17px 16px 14px; line-height: 24px; font-size: 16px; text-transform: none"
          >
            {{ data.name }}
            <span *ngIf="!data.isHeader">({{ data.templateItems?.length }})</span>
          </div>
          <dx-button
            *ngIf="sheetNameEditorIndex == index && !data.isHeader"
            icon="close"
            [data-prevValue]="data.name"
            (onClick)="onCancelWorksheetRename(index)"
          ></dx-button>
          <dx-button
            *ngIf="sheetNameEditorIndex != index && !data.isHeader"
            icon="edit"
            (onClick)="sheetNameEditorIndex = index"
          ></dx-button>
          <dx-button
            *ngIf="sheetNameEditorIndex != index && !data.isHeader"
            icon="remove"
            (onClick)="onRemoveWorksheet(index)"
          ></dx-button>
        </div>

        <div
          [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column' }"
          *dxTemplate="let data of 'item'; let index = index"
        >
          <np-advanced-template-header
            *ngIf="data.isHeader"
            [template]="template"
            (onUpdate)="update($event)"
          ></np-advanced-template-header>
          <np-advanced-template-datagrid
            *ngIf="!data.isHeader"
            [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column', height: '100%' }"
            [customerId]="customerId"
            [sheetIndex]="template.templateType == 'SAP_ARIBA' ? 0 : index"
            [(template)]="template"
            [wawiListMapping]="wawiListMapping"
            [languageFlags]="languageFlags"
            [warrantyClasses]="warrantyClasses"
            [hazmatClasses]="hazmatClasses"
            [validFeatureSystems]="validFeatureSystems"
            (onUpdate)="update($event)"
          ></np-advanced-template-datagrid>
        </div>
      </dx-tab-panel>
    </div>
  </content-column>
</fullscreen-page>
