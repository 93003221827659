import { Component, OnInit, ViewChild } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "../../Services/Validation/validation.service";
import { ValidationGroup } from "../../Model/validation/ValidationGroup";
import { ValidationMethod } from "../../Model/validation/ValidationMethod";
import { ValidationRule } from "../../Model/validation/ValidationRule";
import { confirm } from "devextreme/ui/dialog";
import { DxTreeViewComponent } from "devextreme-angular/ui/tree-view";
import ArrayStore from "devextreme/data/array_store";
import { ValidationField } from "../../Model/validation/ValidationField";
import { fieldNames } from "../../Model/FieldNames";
import { TranslatedText } from "../../Model/ui/TranslatedText";
import { Customer } from "../../Model/User/Customer";
import { CustomerService } from "../../Services/customer.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "validation",
  templateUrl: "validation.component.html",
  styleUrls: ["./validation.component.css"]
})
export class ValidationComponent implements OnInit {
  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;

  validationMethods: ValidationMethod[];
  selectedValidationMethod: ValidationMethod;
  newValidationMethod: ValidationMethod;
  createValidationMethodPopupVisible: boolean;

  validationGroups: ArrayStore;
  selectedValidationItem: any;
  fieldNames: string[];
  dataQualityAreas: string[];
  fieldGroups: TranslatedText[];
  fields: ValidationField[];
  fieldNamesInGroup: TranslatedText[];
  conditionalRules: ValidationRule[];
  allFieldNamesAvailable = fieldNames;
  sharedToCustomersDataSource: Customer[];

  constructor(public modelService: ModelService, private translate: TranslateService, private validationService: ValidationService, private customerService: CustomerService) {
    this.createValidationMethod = this.createValidationMethod.bind(this);
    this.getValidationMethodDisplayText = this.getValidationMethodDisplayText.bind(this);

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Validierung");

    this.modelService.catalogService.loadFields2Subscribe().toPromise().then(() => {
      this.fieldNames = this.modelService.catalogService.fields.map((templateItem) => templateItem.field);
    });
    this.validationService.getDataQualityAreas().toPromise().then((dataQualityAreas) => {
      this.dataQualityAreas = dataQualityAreas;
    });
    this.validationService.getValidationFieldGroups().toPromise().then((validationFieldGroups) => {
      this.fieldGroups = validationFieldGroups.map(x => new TranslatedText(x, translate.instant(x)));
    });
    this.validationService.getValidationFields().toPromise().then((validationFields) => {
      this.fields = validationFields;
    });
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.loadValidationMethods();
  }

  async createValidationRule(validationGroup) {
    var newValidationRule = new ValidationRule();
    var foundValidationGroup = await this.validationGroups.byKey(validationGroup.id);
    newValidationRule.id = crypto.randomUUID();
    newValidationRule.printName = this.translate.instant("Neue Regel");
    newValidationRule.groupId = foundValidationGroup.id;
    newValidationRule.field = new ValidationField();
    foundValidationGroup.rules.push(newValidationRule);
    this.validationGroups.push([{
      type: "update",
      data: foundValidationGroup,
      key: foundValidationGroup.id
    }]);
    this.treeView.instance.expandItem(validationGroup);
    this.saveValidationMethod(this.selectedValidationMethod);
  }

  async duplicateValidationItem(item) {
    var newValidationItem = JSON.parse(JSON.stringify(item));
    newValidationItem.id = crypto.randomUUID();
    if (this.itemIsValidationGroup(item)) {
      newValidationItem.name += this.translate.instant(" - Duplikat");
      newValidationItem.rules.forEach((validationRule) => {
        validationRule.id = crypto.randomUUID();
        validationRule.groupId = newValidationItem.id;
      });
      this.validationGroups.push([{
        type: "insert",
        data: newValidationItem,
        key: newValidationItem.id
      }]);
    } else {
      newValidationItem.printName += this.translate.instant(" - Duplikat");
      var foundValidationGroup = await this.validationGroups.byKey(newValidationItem.groupId);
      foundValidationGroup.rules.push(newValidationItem);
      this.validationGroups.push([{
        type: "update",
        data: foundValidationGroup,
        key: foundValidationGroup.id
      }]);
      this.loadConditionalRules();
    }
    this.selectedValidationItem = newValidationItem;
    this.saveValidationMethod(this.selectedValidationMethod);
  }

  async deleteValidationItem(item) {
    if (this.selectedValidationItem && this.selectedValidationItem.id == item.id) {
      this.selectedValidationItem = undefined;
    }

    if (this.itemIsValidationGroup(item)) {
      let dialogResult = await confirm(this.translate.instant("ConfirmDeleteValidationGroup"), this.translate.instant("Gruppe löschen"));
      if (!dialogResult) {
        return;
      }

      this.validationGroups.push([{
        type: "remove",
        key: item.id
      }]);
    } else {
      let dialogResult = await confirm(this.translate.instant("ConfirmDeleteValidationRule"), this.translate.instant("Regel löschen"));
      if (!dialogResult) {
        return;
      }

      var foundValidationGroup: ValidationGroup = this.validationGroups.createQuery().toArray().find(
        (validationGroup: ValidationGroup) => validationGroup.rules.includes(item));
      var index = foundValidationGroup.rules.indexOf(item);
      if (index > -1) {
        foundValidationGroup.rules.splice(index, 1);
      }
      this.validationGroups.push([{
        type: "update",
        data: foundValidationGroup,
        key: foundValidationGroup.id
      }]);
      this.loadConditionalRules();
    }
    this.saveValidationMethod(this.selectedValidationMethod);
  }

  selectedItemFieldFilled() {
    if (this.selectedValidationItem != undefined &&
      this.selectedValidationItem.field != undefined &&
      this.selectedValidationItem.field.fieldName != undefined) {
      return true;
    }
    return false;
  }

  selectedItemFieldIsList() {
    if (this.selectedItemFieldFilled() &&
      this.selectedValidationItem.field.isList == true) {
      return true;
    }
    return false;
  }

  selectedItemFieldIsBool() {
    if (this.selectedItemFieldFilled() &&
      this.selectedValidationItem.field.isBool == true) {
      return true;
    }
    return false;
  }

  selectedItemFieldIsPicture() {
    if (this.selectedItemFieldFilled() &&
      this.selectedValidationItem.field.isPicture == true) {
      return true;
    }
    return false;
  }

  onFieldGroupChanged(item) {
    if (item.selectedItem != undefined) {
      this.updateFieldNamesInGroupList(item.selectedItem.value);
    }
  }

  onFieldNameChanged(item) {
    if (item.selectedItem == undefined) {
      return;
    }
    var foundField = this.fields.find((field) => field.fieldName == item.selectedItem.value)
    this.selectedValidationItem.field = JSON.parse(JSON.stringify(foundField));
  }

  onIsConditionalChanged(e) {
    if (e.event) {
      this.loadConditionalRules();
      this.saveValidationMethod(this.selectedValidationMethod);
    }
  }

  updateFieldNamesInGroupList(fieldGroup) {
    if (fieldGroup == undefined) {
      this.fieldNamesInGroup = undefined;
      return;
    }
    let fieldNames = this.fields.filter((field) => field.fieldGroup === fieldGroup).map((field) => field.fieldName);
    this.fieldNamesInGroup = fieldNames.map(x => new TranslatedText(x, this.translate.instant(x)));
  }

  onValidationItemSelection(item) {
    var selectedNodes = item.component.getSelectedNodes();
    if (selectedNodes != undefined && selectedNodes.length > 0) {
      var selectedItem = selectedNodes[0].itemData;
      this.selectedValidationItem = selectedItem;
      if (selectedItem.field != undefined) {
        this.updateFieldNamesInGroupList(selectedItem.field.fieldGroup);
      }
    }
  }

  createValidationGroup() {
    var newValidationGroup = new ValidationGroup();
    newValidationGroup.id = crypto.randomUUID();
    newValidationGroup.name = this.translate.instant("Neue Gruppe");
    newValidationGroup.rules = new Array<ValidationRule>();
    this.validationGroups.push([{
      type: "insert",
      data: newValidationGroup,
      key: newValidationGroup.id
    }]);
    this.saveValidationMethod(this.selectedValidationMethod);
  }

  itemIsValidationGroup(item) {
    return item.rules != undefined;
  }

  loadValidationMethods() {
    this.selectedValidationItem = undefined;
    this.selectedValidationMethod = undefined;
    
    this.validationService.getValidationMethodsForEditor().subscribe((validationMethods) => {
      this.validationMethods = validationMethods;
    });
  }

  loadConditionalRules() {
    this.conditionalRules = [];
    this.selectedValidationMethod.groups.forEach((validationGroup) => {
      this.conditionalRules.push(...validationGroup.rules.filter((validationRule) => validationRule.isConditional));
    });
  }

  async loadCustomers() {
    this.sharedToCustomersDataSource = await this.customerService.getAllCustomers();
  }

  async onValidationMethodChanged(e) {
    if (this.selectedValidationMethod != undefined) {
      this.validationGroups = new ArrayStore({
        key: "id",
        data: this.selectedValidationMethod.groups
      });
      this.loadConditionalRules();
      if (this.modelService.loginService.isAdmin) {
        await this.loadCustomers();
      }
    } else {
      this.validationGroups = new ArrayStore();
      this.sharedToCustomersDataSource = [];
    }
    
    this.selectedValidationItem = undefined;
  }

  showCreateValidationMethodPopup() {
    this.newValidationMethod = new ValidationMethod();
    this.createValidationMethodPopupVisible = true;
  }

  showDuplicateValidationMethodPopup() {
    if (this.selectedValidationMethod == undefined) {
      this.modelService.systemService.notifyInfo(this.translate.instant("Bitte Datenqualität-Set auswählen"));
      return;
    } 
    this.newValidationMethod = JSON.parse(JSON.stringify(this.selectedValidationMethod));
    this.newValidationMethod.name += this.translate.instant(" - Duplikat");
    this.newValidationMethod.isGlobal = false;
    this.createValidationMethodPopupVisible = true;
  }

  async createValidationMethod() {
    this.createValidationMethodPopupVisible = false;
    this.newValidationMethod.id = undefined;
    this.newValidationMethod.createdByCustomerId = this.modelService.loginService.currentUser.customerId;

    var createdValidationMethod = await this.validationService.createValidationMethod(this.newValidationMethod).toPromise();

    this.validationMethods.push(createdValidationMethod);
    this.selectedValidationMethod = createdValidationMethod;
  }

  async changeFieldNameAndSaveValidationMethod(validationMethod: ValidationMethod, e: any = undefined) {
    if (e == undefined || e.event) {
      var foundField = this.fields.find((field) => field.fieldName == e.value)
      this.selectedValidationItem.field = JSON.parse(JSON.stringify(foundField));
      await this.validationService.updateValidationMethod(validationMethod).toPromise();
    }
  }

  async saveValidationMethod(validationMethod: ValidationMethod, e: any = undefined) {
    if (e == undefined || e.event) {
      await this.validationService.updateValidationMethod(validationMethod).toPromise();
    }
  }

  async deleteValidationMethod() {
    if (this.selectedValidationMethod == undefined) {
      this.modelService.systemService.notifyInfo(this.translate.instant("Bitte Datenqualität-Set auswählen"));
      return;
    }
    let dialogResult = await confirm(this.translate.instant("ConfirmDeleteValidationMethod"), this.translate.instant("Datenqualität-Set löschen"));
    if (!dialogResult) {
      return;
    }

    this.validationService.deleteValidationMethod(this.selectedValidationMethod).toPromise()
      .then(() => {
        var index = this.validationMethods.indexOf(this.selectedValidationMethod);
        if (index > -1) {
          this.validationMethods.splice(index, 1);
          this.resetValidationMethodSelectBox();
        }
      }).catch(() => {
        this.modelService.systemService.notifyWarning(this.translate.instant("Fehler beim Löschen, Daten werden neu geladen"));
        this.loadValidationMethods();
      });
  }

  resetValidationMethodSelectBox() {
    this.selectedValidationMethod = undefined;
    this.validationGroups.clear();
  }

  isGlobalValidationMethod(validationMethod: ValidationMethod): boolean {
    if (validationMethod != undefined) {
      return validationMethod.isGlobal;
    }
    return false;
  }

  isUserCreatorOfValidationMethod(validationMethod: ValidationMethod): boolean {
    if (validationMethod != undefined) {
      return validationMethod.createdByCustomerId == this.modelService.loginService.currentUser.customerId;
    }
    return false;
  }

  getValidationMethodDisplayText(validationMethod: ValidationMethod) {
    if (validationMethod == null) {
      return "";
    }
    var displayText = validationMethod.name;
    if (validationMethod.isGlobal) {
      if (this.isAdminUser()) {
        displayText += " (Global)";
      } else {
        displayText += " (Read-Only)";
      }
    }
    if (validationMethod.isShared) {
      displayText += " (Shared)";
    }
    return displayText;
  }

  get noDataText() {
    if (this.selectedValidationMethod == undefined) {
      return this.translate.instant("Datenqualität-Set auswählen");
    }
    else {
      return this.translate.instant("Keine Gruppe vorhanden");
    }
  }

  isAdminUser() {
    return this.modelService.loginService.isAdmin;
  }

  showFeatureSystemNameFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Features" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.featureSystemName) {
      return true;
    }
    return false;
  }

  showFeatureNameFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Features" &&
      !validationRule.field.fieldName.includes("REFERENCE_FEATURE") &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.fName) {
      return true;
    }
    return false;
  }

  showMimeTypeFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Medien" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.mimeType) {
      return true;
    }
    return false;
  }

  showMimePurposeFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Medien" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.mimePurpose) {
      return true;
    }
    return false;
  }

  showMimeDescriptionFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Medien" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.mimeDescription) {
      return true;
    }
    return false;
  }

  showReferenceTypeFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Referenzen" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.productReferencesType) {
      return true;
    }
    return false;
  }

  showUdxGroupFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Udx" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.udxCategory) {
      return true;
    }
    return false;
  }

  showSupplierNameFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Lieferanten" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.supplierName) {
      return true;
    }
    return false;
  }

  showPackingUnitCodeFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "EinheitenCode" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.packingUnitCode) {
      return true;
    }
    return false;
  }

  showPriceListLiveFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Preislisten" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.startDate &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.endDate) {
      return true;
    }
    return false;
  }

  showPriceTypeFilter() {
    if (this.itemIsValidationGroup(this.selectedValidationItem)) {
      return false;
    }

    var validationRule: ValidationRule = this.selectedValidationItem;
    if (validationRule.field.fieldGroup == "Preislisten" &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.priceLists &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.startDate &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.endDate &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.isDailyPrice &&
      validationRule.field.fieldName != this.allFieldNamesAvailable.priceType) {
      return true;
    }
    return false;
  }

  isReadOnlyForUser(validationMethod: ValidationMethod) {
    return (this.isGlobalValidationMethod(validationMethod) ||
          !this.isUserCreatorOfValidationMethod(validationMethod)) &&
          !this.isAdminUser();
  }

  isSharedIsReadOnly(validationMethod: ValidationMethod) {
    return validationMethod.sharedToCustomers && validationMethod.sharedToCustomers.length > 0;
  }
}
