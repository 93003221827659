export class MavisConnection {

  constructor(
    public id?: number,
    public customerId?: string,
    public shopUrl?: string,
    public userName?: string,
    public password?: string,
    public isLive?: boolean,

  ) {
  }
}
