<dx-tab-panel #tabPanel
              [height]="260"
              [dataSource]="pages"
              [selectedIndex]="0"
              [loop]="false"
              [animationEnabled]="true"
              [swipeEnabled]="true">
  <div *dxTemplate="let temp of 'uploadTemplate'">
    <div class="dx-fieldset">
      <div class="dx-field">
        <dx-file-uploader #fileUploader
                          name="file"
                          uploadUrl="api/job/CreateCategoriesImportFromChunksJob"
                          [uploadHeaders]="uploadHeaders"
                          [multiple]="false"
                          [chunkSize]="10000000"
                          [accept]="fileTypes"
                          uploadMode="instantly"
                          (onBeforeSend)="onBeforeSendUpload($event)"
                          (onUploaded)="onUploaded()"
                          invalidFileExtensionMessage="{{ 'Bitte nur zip xlsx oder csv Dateien auswählen' | translate }}."
                          invalidMaxFileSizeMessage="{{ 'Die Datei ist leider zu groß' | translate }}"
                          invalidMinFileSizeMessage="{{ 'Die Datei ist leider zu klein' | translate }}"
                          selectButtonText="{{ 'Datei auswählen' | translate }}"
                          labelText="{{ 'oder Datei hier ablegen' | translate }}"
                          uploadButtonText="{{ 'Upload' | translate }}"
                          uploadedMessage="{{ 'Hochgeladen' | translate }}"
                          uploadFailedMessage="{{ 'Hochladen fehlgeschlagen' | translate }}">
        </dx-file-uploader>
      </div>
    </div>
  </div>

  <div *dxTemplate="let temp of 'linkTemplate'">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="{{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }} w-100" id="uc1" (mouseenter)="uct(1)" (mouseleave)="uct(1)">
          <label class="placeLabel {{ fileUrl?.length>0 ? 'placeLabelSmall' : '' }}">CATEGORIES_URL</label>
          <dx-text-box [(value)]="fileUrl" [showClearButton]="true" (onValueChanged)="linkChanged($event)"></dx-text-box>
        </div>

        <dx-tooltip target="#uc1" class="toolTip toolTipField" [(visible)]="c[1]">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Hier können Sie einen Link zu einer Katalogdatei eintragen, um diesen zu importieren' | translate }}
            {{ 'Es werden zum Beispiel Links zu Dateien in einer Dropbox unterstützt oder Links, welche aus anderen Systemen erstellt wurden' | translate }}.
            {{ 'Bsp' | translate }}.: https://www.dropbox.com/s/h5pldo0u8h7qkje/mediaFile.zip?dl=0
          </div>
        </dx-tooltip>
      </div>

      <div *ngIf="fileUrl.length>0">
        <div *ngIf="!fileUrlTest">
          <div style="color: red;">
            {{fileUrlTestMessage}}
          </div>
        </div>
      </div>

    </div>

    <dx-toolbar *ngIf="fileUrl.length>0">
      <dxi-item *ngIf="!fileUrlTest" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Link testen' | translate, onClick: testLink }"></dxi-item>
      <dxi-item *ngIf="fileUrlTest" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Kategorien laden' | translate, onClick: startDownload }"></dxi-item>
    </dx-toolbar>

  </div>
</dx-tab-panel>
