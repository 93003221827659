
<div class="dx-fieldset" style="max-width: 600px;">
  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'DeepL Authorization Key' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="currentDeeplConnection != null" [(value)]="currentDeeplConnection.authKey" [showClearButton]="true"></dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <dx-toolbar>
      <dxi-item *ngIf="isManager" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Speichern' | translate , onClick: updateAuthKey }"></dxi-item>
    </dx-toolbar>
  </div>
  <!--<div class="dx-field" *ngIf="currentDeeplCheck != null">
    <div class="dx-field-label jobListTD"> {{ 'Characters used' | translate }} {{currentDeeplCheck.character_count}}</div>
  </div>
  <div class="dx-field" *ngIf="currentDeeplCheck != null">
    <div class="dx-field-label jobListTD">  {{ 'Character limit' | translate }} {{currentDeeplCheck.character_limit}}</div>
  </div>-->
</div>






