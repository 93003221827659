import { Component, OnInit } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";

import { ModelService } from '../../Services/model.service';
import { SendToNWConfig } from '../../Model/Dto/SendToNWConfig';




@Component({
  selector: "sendToNWConfig",
  templateUrl: "sendToNWConfig.component.html"
})
export class SendToNWConfigComponent implements OnInit {

  connection2Shop: boolean ;

  constructor(public modelService: ModelService) {
    this.testftpConnection = this.testftpConnection.bind(this);
  }

 


  ngOnInit(): void { 
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    } 
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER") return false;
    return true;
    }

   get model(): SendToNWConfig {
    
        return this.modelService.loginService.sendToNWConfig;  
    }
   set model(value: SendToNWConfig) {
        this.modelService.loginService.sendToNWConfig= value;
    }





  update() {
    this.modelService.sendToNWConfigService.update(this.model).subscribe(
      (result: number) => {
        this.model.id = result;
        this.isTested = false;
      });;
  }

  public testftpConnection() {

    this.modelService.sendToNWConfigService.testConnection(this.model.id).subscribe((result: TestResult) => this.testShopResult(result));
  }

  private testShopResult(result: TestResult) {
    if (result.success) {
      this.isTested = true;
      this.connection2ftpMessage = "";
    } else {
      this.connection2ftpMessage = result.message;
      this.isTested = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connection2ftpMessage;
      this.modelService.systemService.notify(msg);
    }
  }





  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  connection2ftpMessage: string;
  isTested:boolean;
}


