import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { PackingUnit } from '../../../../Model/Catalog/PackingUnit';
import { Product } from '../../../../Model/Catalog/Product';
import { CustomerService } from '../../../../Services/customer.service';
import { ModelService } from '../../../../Services/model.service';
import { ProductService } from '../../../../Services/CatalogManagement/product.service';
import { TemplateService } from '../../../../Services/template.service';
import { UserManagementService } from '../../../../Services/userManagment.service';
import { WawiListService } from '../../../../Services/wawiList.service';
import { WawiListElement } from '../../../../Model/wawi/WawiList';

@Component({
  selector: 'supplierPackingUnits',
  templateUrl: 'supplierPackingUnits.component.html'
})
export class SupplierPackingUnits implements OnInit {
  @Input() units: PackingUnit[] = [];
  @Input() contentUnit: number;
  @Input() orderUnit: number;
  @Input() noCuPerOU: number;

  @Input() product: Product = null;
  @Input() isTemplate: boolean = false;
  @Input() templateView: boolean = false;
  @Input() selectedElements: string[] = [''];
  @Input() exportMode: boolean = false;
  @Input() showLinkButtons: boolean = false;

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();

  @Output() onValueChanged = new EventEmitter<any>();

  updateLock = false;
  gevisMappingList: WawiListElement[];
  generatedUnits: PackingUnit[] = [];

  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public userManagement: UserManagementService,
    public wawiListService: WawiListService,
    public customerService: CustomerService,
    public productService: ProductService
  ) {
    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.getGevisMappedCode = this.getGevisMappedCode.bind(this);
    this.generateMissingCodes = this.generateMissingCodes.bind(this);

    wawiListService
      .getListByName('EinheitenCodes', modelService.loginService.currentCustomer.id)
      .toPromise()
      .then((list) => {
        this.gevisMappingList = list.fields;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  ngOnInit(): void {
    this.generatedUnits.push(new PackingUnit(1, this.product.orderDetail.contentUnit, 'Basis Einheit', 1, 1, 1, 1));
    this.generatedUnits.push(
      new PackingUnit(
        2,
        this.product.orderDetail.orderUnit,
        'Bestell Einheit',
        this.product.orderDetail.noCuPerOuNumber,
        1,
        this.product.orderDetail.noCuPerOuNumber,
        1
      )
    );
  }

  get newOrder(): number {
    let newOrder = 1;
    for (const unit of this.product.orderDetail.packingUnits) {
      if (unit.order == newOrder) {
        newOrder++;
      }
    }
    return newOrder;
  }

  getGevisMappedCode(code: string) {
    if (this.gevisMappingList == null) return '';
    let result = this.gevisMappingList.findIndex((x) => x.key == code);
    return result == -1 ? '' : this.gevisMappingList[result].value ?? '';
  }

  add() {
    this.updateLock = true;
    this.product.orderDetail.packingUnits.push(new PackingUnit(this.newOrder, '', ''));
    this.update();
  }

  onContentReady() {
    this.updateLock = false;
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1'): void {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
      event.supplierPackingChanged = true;
    }

    this.onValueChanged.emit(event);
  }

  delete(event = null) {
    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allSupplierUnitFields;
    event.dDetailFields = null;
    event.dSystem = event.data.order;
    event.dElement = -1;
    this.elementDeleted.emit(event);
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'generateMissingCodesButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newSupplierUnitButtonTemplate'
    });
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  templateSelect(identifier: string, order: string) {
    this.elementSelected.emit(`${identifier}_${order}`);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }

  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (this.product != null && field != null) {
      var isLinked = this.product.isFieldLinked(field, system, element);
      if (isLinked && this.product.isChild != null && this.product.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  showClearButton(field: string, system: string): boolean {
    return !this.readOnlyForLinkedFields(field, system) && !this.templateView;
  }

  generateMissingCodes() {
    this.productService.generateMissingPackingCodes(this.product.id, this.product.customerId).subscribe(() => {
      this.modelService.catalogService
        .getProduct(this.product.id, this.product.customerId, this.modelService.catalogService.catalog.id)
        .subscribe((result) => {
          this.product = result;
        });
    });
  }
}
