import { CatalogItem } from "./CatalogItem";

export class TransferCatalogItem {

  constructor(
    public itemsToTransfer?: CatalogItem[],
    public targetItem?: CatalogItem
  ) {

  }
}
