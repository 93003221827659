import { Component, OnInit } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { MavisConnection } from '../../Model/Dto/MavisConnection';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: "mavisCon",
  templateUrl: "mavisConnection.component.html"
})
export class MavisConnectionComponent implements OnInit {
  connection2Shop: boolean;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.testShopConnectionTest = this.testShopConnectionTest.bind(this);
    this.testShopConnectionLive = this.testShopConnectionLive.bind(this);
  }
  pages = [];




  ngOnInit(): void { 
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }


    let testText = "";
    testText = this.translate.instant("Test");
    let liveText = "";
    liveText = this.translate.instant("Live");

    this.pages = [
      { id: 1, title: testText, template: "testTemplate" },
      { id: 2, title: liveText, template: "liveTemplate" }
    ];
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER") return false;
    return true;
    }

   get modelTest(): MavisConnection {
        return this.modelService.loginService.mavisConnectionTest;  
    }
   set modelTest(value: MavisConnection) {
        this.modelService.loginService.mavisConnectionTest = value;
    }

   get modelLive(): MavisConnection {
     this.modelService.loginService.mavisConnectionLive.isLive = true;
     return this.modelService.loginService.mavisConnectionLive;  
   }
   set modelLive(value: MavisConnection) {
     this.modelService.loginService.mavisConnectionLive = value;
   }



  updateTest() {
      

    this.modelService.mavisConnectionService.update(this.modelTest).subscribe(
      (result: number) => {
        this.modelTest.id = result;
        this.isTestTested = false;
      });;
  }

  updateLive() {
      

    this.modelService.mavisConnectionService.update(this.modelLive).subscribe(
      (result: number) => {
        this.modelLive.id = result;
        this.isLiveTested = false;
      });;
  }

  public testShopConnectionTest() {

    this.modelService.mavisConnectionService.testConnection(this.modelTest.id).subscribe((result: TestResult) => this.testShopResultTest(result));
  }

  public testShopConnectionLive() {

    this.modelService.mavisConnectionService.testConnection(this.modelLive.id).subscribe((result: TestResult) => this.testShopResultLive(result));
  }

  private testShopResultTest(result: TestResult) {
    if (result.success) {
      this.isTestTested = true;
      this.connection2ShopMessageTest = "";
    } else {
      this.connection2ShopMessageTest = result.message;
      this.isTestTested = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connection2ShopMessageTest;
      this.modelService.systemService.notify(msg);
    }
  }
  private testShopResultLive(result: TestResult) {
    if (result.success) {
      this.isLiveTested = true;
      this.connection2ShopMessageLive = "";
    } else {
      this.connection2ShopMessageLive = result.message;
      this.isLiveTested = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connection2ShopMessageLive;
      this.modelService.systemService.notify(msg);
    }
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  connection2ShopMessageTest: string;
  connection2ShopMessageLive: string;
  isTestTested:boolean;
  isLiveTested:boolean;
}


