import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { FeatureToBeRenamed } from "../../../../Model/Catalog/FeatureModels/FeatureToBeRenamedModel";
import { ValidFeatureSystem } from "../../../../Model/ValidFeatureSystem";
import { ValidFeatureService } from "../../../../Services/validFeature.service";

@Component({
  selector: "userAction-renameFeatures",
  templateUrl: "userAction-renameFeatures.component.html"
})
export class UserActionRenameFeaturesComponent extends UserActionFeatureSystemsComponent {
  featuresGridDataSource: Array<FeatureToBeRenamed> = [];
  selectedFeatureSystem: ValidFeatureSystem;
  featuresLoading = false;

  constructor(public modelService: ModelService, validFeatureService: ValidFeatureService) {
    super(modelService, validFeatureService);
  }

  onFeatureSystemChanged(e) {
    this.featuresGridDataSource = [];
    if (this.selectedFeatureSystem) {
      this.featuresLoading = true;
      this.loadFeatures(this.selectedFeatureSystem).then((features) => {
        for (let feature of features) {
          this.featuresGridDataSource.push(new FeatureToBeRenamed(feature, "", ""));
        }
        this.featuresLoading = false;
      });
    }
  }

  startMacro() {
    if (this.featuresGridDataSource.length == 0) {
      return;
    }

    this.modelService.catalogService.showUserAction = false;

    this.modelService.jobService.startUserAction(this.createUserAction()).subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createUserAction(): StartAction {
    let userAction = new StartAction();
    userAction.catalogId = this.modelService.catalogService.catalog.id;
    userAction.customerId = this.modelService.loginService.currentUser.customerId;
    userAction.actionId = `RenameFeatures(${this.createParameterStringAsJsonString()})`;
    return userAction;
  }

  createParameterStringAsJsonString(): string {
    let parameterJsonString = JSON.stringify(
      {
        featureSystemName: this.selectedFeatureSystem.name,
        featuresToBeRenamed: this.featuresGridDataSource
      });
    return parameterJsonString;
  }
}
