import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldNames } from '../Model/FieldNames';

@Injectable({
  providedIn: 'root'
})
export class ToolTipService {
  private fieldNames: FieldNames;

  constructor(public translate: TranslateService) {
    this.fieldNames = new FieldNames();
  }

  getToolTipContent(field: string, showProToolTips: boolean): string {
    switch (field) {



      // ****************************************************************
      // nicht immer fehlt der '.' am Ende...?
      // ****************************************************************



      // ****************************************************************
      // PRODUKT
      // ****************************************************************



      //case "BUYERAID":                                  // legacy "Ender-Field"
      case this.fieldNames.buyerAid:                    // ==> "BUYER_AID"
        let line1BuyerAid = this.translate.instant('Dieses Element enthält die Artikelnummer des einkaufenden Unternehmens');
        return `<p>${line1BuyerAid}.</p>`;



      //case "DESCRIPTIONLONG":                           // legacy "Ender-Field"
      case this.fieldNames.descriptionLong:             // ==> "DESCRIPTION_LONG"

        let line1DescriptionLong = this.translate.instant('Das Feld DESCRIPTION_LONG ist die Langbeschreibung, eine ausführliche Produktbeschreibung, die den Namen oder die Kurzbeschreibung ergänzen sollte');
        let line2DescriptionLong = this.translate.instant('Folgende HTMLTags sind dabei erlaubt:');

        let line3DescriptionLong = '<li>&lt;b&gt; ' + this.translate.instant('für Fettdruck') + '</li>';
        let line4DescriptionLong = '<li>&lt;i&gt; ' + this.translate.instant('für kursiv') + '</li>';
        let line5DescriptionLong = '<li>&lt;p&gt; ' + this.translate.instant('für Paragraphen') + '</li>';
        let line6DescriptionLong = '<li>&lt;br&gt; ' + this.translate.instant('für Zeilenumbrüche') + '</li>';
        let line7DescriptionLong = '<li>&lt;ul&gt;/&lt;li&gt; ' + this.translate.instant('für Listendarstellung') + '</li>';

        let line8DescriptionLong = this.translate.instant('Um HTML-Tags übertragen zu können, müssen die Zeichen') + ' &#39;&gt;&#39; und &#39;&lt;&#39; ';
        let line9DescriptionLong = this.translate.instant('durch Ihre entsprechenden character references kodiert sein, damit ein XML-Parser die BMEcat-Datei akzeptiert');
        let line10DescriptionLong = this.translate.instant('Beispiel') + ' : &#39;&lt;&#39; = &amp;lt; ';
        let line11DescriptionLong = this.translate.instant('bzw') + '. &#39;&gt;&#39; = &amp;lt;';

        if (showProToolTips) {
          return `<p>${line1DescriptionLong}. ${line2DescriptionLong}</p>
                  <ul>${line3DescriptionLong}${line4DescriptionLong}${line5DescriptionLong}${line6DescriptionLong}${line7DescriptionLong}</ul>
                  <div class="nexMartTooltip"><p>
                  ${line8DescriptionLong}${line9DescriptionLong}. ${line10DescriptionLong}${line11DescriptionLong}
                  </p></div>`;
        }
        else {
          return `<p>${line1DescriptionLong}. ${line2DescriptionLong}</p>
                  <ul>${line3DescriptionLong}${line4DescriptionLong}${line5DescriptionLong}${line6DescriptionLong}${line7DescriptionLong}</ul>`;
        }



      //case "DESCRIPTIONSHORT":                          // legacy "Ender-Field"
      case this.fieldNames.descriptionShort:            // ==> "DESCRIPTION_SHORT"
        let line1DescriptionShort = this.translate.instant('Dieses Element enthält die Kurzbeschreibung/-bezeichnung des Produktes');
        let line2DescriptionShort = this.translate.instant('Grundsätzlich soll diese Beschreibung kurz und innerhalb der ersten 40 Zeichen eindeutig und aussagekräftig sein, da in der weiteren Verwendung viele Anwendungssysteme nur 40 Zeichen verarbeiten können');
        let line3DescriptionShort = this.translate.instant('(Beispiel: SAP-OCI, SAP R/3) Ausführliche Kurzbeschreibungen bieten Vorteile bei Suchanfragen von weitestgehend ähnlichen Produkten');
        let line4DescriptionShort = this.translate.instant('Bereits in der ersten Trefferliste sind diese dann differenzierbar');
        let line5DescriptionShort = this.translate.instant('Auf Abkürzungen von wesentlichen Produkteigenschaften sollte generell verzichtet werden (schwarz statt schw)');
        let line6DescriptionShort = this.translate.instant('Abkürzungen von Organisationen oder Standards können selbstverständlich verwendet werden (zum Beispiel DIN A4, VDE)');
        return `<p>${line1DescriptionShort}. ${line2DescriptionShort}. ${line3DescriptionShort}. ${line4DescriptionShort}. ${line5DescriptionShort}. ${line6DescriptionShort}.</p>`;



      //case "EDENUMBER1":                                // legacy "Ender-Field"
      case this.fieldNames.edeNumber1:                  // ==> "EDE_NUMBER_1"
      //case "EDENUMBER2":                                // legacy "Ender-Field"
      case this.fieldNames.edeNumber2:                  // ==> "EDE_NUMBER_2"
      //case "EDENUMBER3":                                // legacy "Ender-Field"
      case this.fieldNames.edeNumber3:                  // ==> "EDE_NUMBER_3"
      //case "EDENUMBER4":                                // legacy "Ender-Field"
      case this.fieldNames.edeNumber4:                  // ==> "EDE_NUMBER_4"
      //case "EDENUMBER5":                                // legacy "Ender-Field"
      case this.fieldNames.edeNumber5:                  // ==> "EDE_NUMBER_5"
        let line1EdeNumber = this.translate.instant('Eine EDE Katalog Bestellnummer');
        let line2EdeNumber = this.translate.instant('Dieses Feld wird nur im nextCat exportiert');
        return `<p>${line1EdeNumber}. ${line2EdeNumber}.</p>`;



      //case "ERPGROUPBUYER":                             // legacy "Ender-Field"
      case this.fieldNames.erpGroupBuyer:               // ==> "ERP_GROUP_BUYER"
        let line1ErpGroupBuyer = this.translate.instant('Angabe der Warengruppe oder Materialklasse des Produktes im ERP-System des einkaufenden Unternehmens');
        let line2ErpGroupBuyer = this.translate.instant('Wertebereich: abgestimmt auf Warengruppen des ERP-Systems des Käufers');
        return `<p>${line1ErpGroupBuyer}. ${line2ErpGroupBuyer}.</p>`;



      //case "ERPGROUPSUPPLIER":                          // legacy "Ender-Field"
      case this.fieldNames.erpGroupSupplier:            // ==> "ERP_GROUP_SUPPLIER"
        let line1ErpGroupSupplier = this.translate.instant('Angabe der Warengruppe oder Materialklasse des Produktes im ERP-System des Lieferanten');
        return `<p>${line1ErpGroupSupplier}.</p>`;



      //case "INTERNATIONALPID":                          // legacy "Ender-Field"
      case this.fieldNames.internatonalPid:             // ==> "INTERNATIONAL_PID"

        let line1InternatonalPid = this.translate.instant('Die INTERNATIONAL_ID ist eine internationale Artikelnummer');
        let line2InternatonalPid = this.translate.instant('Über das Feld INTERNATIONAL_ID_TYPE wird der Typ dieser Nummer festgelegt');
        let line3InternatonalPid = this.translate.instant('Heute ist die GTIN als Nachfolger der EAN verbreitet');
        let line4InternatonalPid = this.translate.instant('Dabei entsprechen die EAN Nummern dieser GTIN');

        let line5InternatonalPid = this.translate.instant('GTINs werden international Einmalig vergeben, auch Produkte in verschiedenen Varianten und Verpackungseinheiten haben unterschiedliche Nummern');

        let line6InternatonalPid = this.translate.instant('Es hat sich als best practice erwiesen, jedem Produkt (Auch Varianten-Hauptprodukte) eine eindeutige GTIN zu vergeben, da so alle Systeme die Produkte unter dieser Nummer wiederfinden');
        let line7InternatonalPid = this.translate.instant('Im Regelfall vergibt der Hersteller diese Produktnummer');
        let line8InternatonalPid = this.translate.instant('Sollte dem nicht so sein, kann man eigene GTINs erwerben und Produkten diese Nummern geben');

        let line9InternatonalPid = this.translate.instant('Amazon verwendet ASIN Nummer zur eindeutigen Identifizierung');
        let line10InternatonalPid = this.translate.instant('Diese sollten im UDX Feld ASIN abgelegt werden');

        let line11InternatonalPid = this.translate.instant('Bei nexMart werden nur GTINs angezeigt');
        let line12InternatonalPid = this.translate.instant('In Zukunft sollen zusätzliche Artikelnummern hier eingetragen werden');

        if (showProToolTips) {
          return `<p>${line1InternatonalPid}. ${line2InternatonalPid}. ${line3InternatonalPid}. ${line4InternatonalPid}.</p>
                  <p>${line5InternatonalPid}.</p>
                  <p>${line6InternatonalPid}. ${line7InternatonalPid}. ${line8InternatonalPid}.</p>
                  <p>${line9InternatonalPid}. ${line10InternatonalPid}.</p>
                  <div class="nexMartTooltip"><p>
                  ${line11InternatonalPid}. ${line12InternatonalPid}.
                  </p></div>`;
        }
        else {
          return `<p>${line1InternatonalPid}. ${line2InternatonalPid}. ${line3InternatonalPid}. ${line4InternatonalPid}.</p>
                  <p>${line5InternatonalPid}.</p>
                  <p>${line6InternatonalPid}. ${line7InternatonalPid}. ${line8InternatonalPid}.</p>
                  <p>${line9InternatonalPid}. ${line10InternatonalPid}.</p>`;
        }



      //case "INTERNATIONALPIDTYPE":                      // legacy "Ender-Field"
      case this.fieldNames.internatonalPidType:         // ==> "INTERNATIONAL_PID_TYPE"

        let line1InternatonalPidType = this.translate.instant('Über dieses Feld wird der Typ der INTERNATIONAL_ID festgelegt');
        let line2InternatonalPidType = this.translate.instant('Es sollte hier GTIN verwendet werden');
        let line3InternatonalPidType = this.translate.instant('In alten Daten dürfte auch noch regelmäßig EAN auftauchen');

        let line4InternatonalPidType = this.translate.instant('Es gibt eine Aktion & Methode, welche EAN per Knopfdruck zur GTIN wandelt');

        let line5InternatonalPidType = this.translate.instant('NexMart unterstützt im Moment nur GTIN');
        let line6InternatonalPidType = this.translate.instant('Nummern anderer Typen dürfen nicht übertragen werden');

        if (showProToolTips) {
          return `<p>${line1InternatonalPidType}. ${line2InternatonalPidType}. ${line3InternatonalPidType}.</p>
                  <p>${line4InternatonalPidType}.</p>
                  <div class="nexMartTooltip"><p>
                  ${line5InternatonalPidType}. ${line6InternatonalPidType}.
                  </p></div>`;
        }
        else {
          return `<p>${line1InternatonalPidType}. ${line2InternatonalPidType}. ${line3InternatonalPidType}.</p><p>${line4InternatonalPidType}.</p>`;
        }



      ////case "":                                        // war nicht vergeben...
      case this.fieldNames.isDiscontinued:              // ==> "IS_DISCONTINUED"
        let line1IsDiscontinued = this.translate.instant('Hier können Sie angeben, ob es sich um einen Auslaufartikel handelt und wenn ja, zu wann der Artikel ausläuft.');
        return `<p>${line1IsDiscontinued}</p>`;



      //case "KEYWORDS":                                  // legacy "Ender-Field"
      case this.fieldNames.keywords:                    // ==> "KEYWORDS"
        let line1Keywords = this.translate.instant('Schlagwort, welches das Finden des Produktes erleichtert');
        let line2Keywords = this.translate.instant('Das Produkt soll im Zielsystem auch über die Eingabe dieses Schlagwortes gefunden werden können');
        return `<p>${line1Keywords}. ${line2Keywords}.</p>`;



      //case "MANUFACTURERNAME":                          // legacy "Ender-Field"
      case this.fieldNames.manufacturerName:            // ==> "MANUFACTURER_NAME"
        let line1ManufacturerName = this.translate.instant('Name des Herstellers');
        return `<p>${line1ManufacturerName}.</p>`;



      //case "MANUFACTURERPID":                           // legacy "Ender-Field"
      case this.fieldNames.manufacturerPID:             // ==> "MANUFACTURER_PID"
        let line1ManufacturerPID = this.translate.instant('Die MANUFACTURER_PID ist die ursprünglich vom Hersteller vergebene Produktnummer');
        let line2ManufacturerPID = this.translate.instant('Diese sollte nur vom Hersteller festgelegt und verändert werden');
        let line3ManufacturerPID = this.translate.instant('Hierbei handelt es sich in der Regel nicht um eine EAN, es kann jedoch eine sein');
        return `<p>${line1ManufacturerPID}. ${line2ManufacturerPID}. ${line3ManufacturerPID}.</p>`;



      //case "MANUFACTURERTYPEDESCR":                     // legacy "Ender-Field"
      case this.fieldNames.manufacturerTypeDescription: // ==> "MANUFACTURER_TYPE_DESCR"
        let line1ManufacturerTypeDescription = this.translate.instant('Die Herstellerbezeichnung ist ein Name für das Produkt, welcher unter Umständen bekannter ist als der korrekte Produktname');
        let line2ManufacturerTypeDescription = this.translate.instant('Wird die Herstellertypbezeichnung angegeben, sollte auch der Name des Herstellers angegeben werden');
        return `<p>${line1ManufacturerTypeDescription}. ${line2ManufacturerTypeDescription}.</p>`;



      //case "PRODUCTORDER":                              // legacy "Ender-Field"
      case this.fieldNames.productOrder:                // ==> "PRODUCT_ORDER"
        let line1ProductOrder = this.translate.instant('Mit der Sortiernummer werden die Produkte innerhalb einer Kategorie sortiert');
        return `<p>${line1ProductOrder}.</p>`;



      //case "REMARKS":                                   // legacy "Ender-Field"
      case this.fieldNames.remarks:                     // ==> "REMARKS"
        let line1Remarks = this.translate.instant('Zusätzliche Bemerkungen des Lieferanten');
        return `<p>${line1Remarks}.</p>`;



      //case "STATUS":                                    // legacy "Ender-Field"
      case this.fieldNames.status:                      // ==> "STATUS"
        let line1Status = this.translate.instant('Diese ist ein interner Produkt Status der Ihnen hilft, ihre Produkte zu verwalten');
        let line2Status = this.translate.instant('Dieses Feld wird nur im nextCat exportiert');
        return `<p>${line1Status}. ${line2Status}.</p>`;



      //case "SUPPLIERALTPID":                            // legacy "Ender-Field"
      case this.fieldNames.supplierAltPid:              // ==> "SUPPLIER_ALT_PID"

        let line1SupplierAltPid = this.translate.instant('Die SUPPLIER_ALT_PID ist eine alternative Produktnummer');
        let line2SupplierAltPid = this.translate.instant('Dieses Feld ist weitestgehend ohne spezielle Funktion');
        let line3SupplierAltPid = this.translate.instant('Hersteller tragen hier Ihre alten Produktnummer ein, wodurch bei nexMart über diese Produktnummern die Produkte ebenfalls gefunden werden können');

        let line4SupplierAltPid = this.translate.instant('Bei der Erstellung eines nexMart BMEcat und bei der Übertragung zu nexMart werden Produkte, welche eine alternative Produktnummer haben doppelt berücksichtigt');
        let line5SupplierAltPid = this.translate.instant('Das erste Produkt hat nur die SUPPLIER_PID');
        let line6SupplierAltPid = this.translate.instant('Das zweite Produkt hat als SUPPLIER_PID die SUPPLIER_ALT_PID und als SUPPLIER_ALT_PID die SUPPLIER_PID');
        //let line7SupplierAltPid = this.translate.instant('Das erste Produkt hat nur die SUPPLIER_PID');
        //let line8SupplierAltPid = this.translate.instant('Das zweite Produkt hat als SUPPLIER_PID die SUPPLIER_ALT_PID und als SUPPLIER_ALT_PID die SUPPLIER_PID');
        let line9SupplierAltPid = this.translate.instant('Damit wird dieses zweite Produkt unter der SUPPLIER_ALT_PID gefunden und mit der SUPPLIER_PID bestellt');

        if (showProToolTips) {
          return `<p>${line1SupplierAltPid}. ${line2SupplierAltPid}. ${line3SupplierAltPid}.</p>
                  <div class="nexMartTooltip"><p>
                  ${line4SupplierAltPid}. ${line5SupplierAltPid}. ${line6SupplierAltPid}. ${line9SupplierAltPid}.
                  </p></div>`;
        }
        else {
          return `<p>${line1SupplierAltPid}. ${line2SupplierAltPid}. ${line3SupplierAltPid}.</p>`;
        }



      //case "SUPPLIERPID":                               // legacy "Ender-Field"
      case this.fieldNames.supplierPid:                 // ==> "SUPPLIER_PID"

        let line1SupplierPid = this.translate.instant('Die SUPPLIER_PID ist die Hauptproduktnummer der Produkte im PIM System');
        let line2SupplierPid = this.translate.instant('Hierrüber werden die Produkte eindeutig identifiziert');
        let line3SupplierPid = this.translate.instant('Sie sollte in der Regel mit der Produktnummer im ERP System übereinstimmen');

        let line4SupplierPid = this.translate.instant('Aus historischen Gründen haben Produktnummern alle möglichen Formate');
        let line5SupplierPid = this.translate.instant('Diese werden auch nicht einfach geändert, da Einkäufer diese Produktnummern für Bestellungen verwenden');
        let line6SupplierPid = this.translate.instant('Es wird empfohlen, auf Produktnummern umzustellen, welche ausschließlich aus Nummern bestehen');
        let line7SupplierPid = this.translate.instant('Optimal wäre die Verwendung von GTIN Nummern (Auch für Master Artikel)');
        let line8SupplierPid = this.translate.instant('Die älteren Nummern können bei den INTERNATIONALPID mit dem TYPE XXX eingetragen werden');

        let line9SupplierPid = this.translate.instant('Die Produktnummer darf im Katalog nicht mehrfach vorhanden sein, da die Bearbeitung der Daten mit Excel dadurch zu Fehlern führen wird');
        let line10SupplierPid = this.translate.instant('Auch eine Weitergabe der Daten wäre dadurch fehlerhaft');

        let line11SupplierPid = this.translate.instant('Unter dieser Produktnummer werden die Artikel auch zu nexMart übertragen');
        let line12SupplierPid = this.translate.instant('Sie wird auch für die Bestellungen verwendet');

        if (showProToolTips) {
          return `<p>${line1SupplierPid}. ${line2SupplierPid}. ${line3SupplierPid}.</p>
                  <p>${line4SupplierPid}. ${line5SupplierPid}. ${line6SupplierPid}. ${line7SupplierPid}. ${line8SupplierPid}.</p>
                  <p>${line9SupplierPid}. ${line10SupplierPid}.</p>
                  <div class="nexMartTooltip"><p>
                  ${line11SupplierPid}. ${line12SupplierPid}.
                  </p></div>`;
        }
        else {
          return `<p>${line1SupplierPid}. ${line2SupplierPid}. ${line3SupplierPid}.</p>
                  <p>${line4SupplierPid}. ${line5SupplierPid}. ${line6SupplierPid}. ${line7SupplierPid}. ${line8SupplierPid}.</p>
                  <p>${line9SupplierPid}. ${line10SupplierPid}.</p>`;
        }



      // ****************************************************************
      // MIMES
      // ****************************************************************



      case this.fieldNames.mimeAlt:                     // ==> "MIME_ALT"
        let line1MimeAlt = this.translate.instant('Alternativtext, falls die Datei zum Beispiel in dem Zielsystem nicht darstellbar ist');
        return `<p>${line1MimeAlt}.</p>`;



      case this.fieldNames.mimeDescription:             // ==> "MIME_DESCR"
        let line1MimeDescription = this.translate.instant('Sie können im Feld MIME_DESCR eine Beschreibung für die Abbildung oder das Datenblatt angeben');
        return `<p>${line1MimeDescription}.</p>`;



      case "MIME_ORDER":
        let line1MimeOrder = this.translate.instant('Über die MIME_ORDER kann die Reihenfolge der Abbildungen auf der Produktdetailseite gesteuert werden');
        let line2MimeOrder = this.translate.instant('Als Wert muss eine positive, ganze Zahl verwendet werden');
        let line3MimeOrder = this.translate.instant('Bitte keine negativen Werte, 0 oder Dezimalzahlen angeben');
        let line4MimeOrder = this.translate.instant('Das Bild mit der kleinsten Zahl wird zuerst angezeigt');

        return `<p>${line1MimeOrder}. ${line2MimeOrder}. ${line3MimeOrder}. ${line4MimeOrder}.</p>`;



      case this.fieldNames.mimePurpose:                 // ==> "MIME_PURPOSE"

        let line1MimePurpose = this.translate.instant('MIME_PURPOSE ist der Verwendungszweck. Folgende Angaben sind möglich');
        let line2MimePurpose = this.translate.instant('normal - Produkthauptbild');
        let line3MimePurpose = this.translate.instant('detail - weiteres Produkthauptbild');
        let line4MimePurpose = this.translate.instant('milieu - Anwendungsbild');
        let line5MimePurpose = this.translate.instant('data_sheet - Prdouktdatenblatt');

        return `<p>${line1MimePurpose}:<br />
                ${line2MimePurpose}<br />
                ${line3MimePurpose}<br />
                ${line4MimePurpose}<br />
                ${line5MimePurpose}</p>`;



      case this.fieldNames.mimeSource:                  // ==> "MIME_SOURCE"
        let line1MimeSource = this.translate.instant('Im Feld MIME_SOURCE wird der Name de referenzierten Bilddatei oder des Datenblatts angegeben');
        return `<p>${line1MimeSource}.</p>`;



      case this.fieldNames.mimeType:                    // ==> "MIME_TYPE"
        let line1MimeType = this.translate.instant('Im Feld MIME_TYPE wird der Dateityp definiert');
        return `<p>${line1MimeType}.</p>`;



      // ****************************************************************
      // ORDER DETAILS
      // ****************************************************************



      case this.fieldNames.contentUnit:                 // ==> "CONTENT_UNIT"

        let line1ContentUnit = this.translate.instant('Die CONTENT_UNIT gibt an, welche Inhaltseinheiten in der Bestelleinheit enthalten sind');
        let line2ContentUnit = this.translate.instant('Als Inhaltseinheit müssen UN/CEFACT-Einheiten verwendet werden');
        let line3ContentUnit = this.translate.instant('Beispiele');

        let line4ContentUnit = this.translate.instant('BO - Flasche');
        let line5ContentUnit = this.translate.instant('BX - Box');
        let line6ContentUnit = this.translate.instant('C62 - Stück');
        let line7ContentUnit = this.translate.instant('CG - Karton');
        let line8ContentUnit = this.translate.instant('CT - Kartonage');
        let line9ContentUnit = this.translate.instant('LTR - Liter');
        let line10ContentUnit = this.translate.instant('PR - Paar');

        return `${line1ContentUnit}.<br />
                ${line2ContentUnit}.<br /><br />
                <b>${line3ContentUnit}:</b>
                <ul>
                <li>${line4ContentUnit}</li>
                <li>${line5ContentUnit}</li>
                <li>${line6ContentUnit}</li>
                <li>${line7ContentUnit}</li>
                <li>${line8ContentUnit}</li>
                <li>${line9ContentUnit}</li>
                <li>${line10ContentUnit}</li>
                </ul>`;



      case this.fieldNames.noCuPerOu:                   // ==> "NO_CU_PER_OU"

        let line1NoCuPerOu = this.translate.instant('Die Inhaltseinheit und Verpackungsmenge müssen zusammen angegeben werden');
        let line2NoCuPerOu = this.translate.instant('Die Verpackungsmenge definiert, welche Anzahl der Inhaltseinheit in der Bestelleinheit vorliegen');
        let line3NoCuPerOu = this.translate.instant('Beispiel: In einem Karton befinden sich 10 Flaschen');

        let line4NoCuPerOu = this.translate.instant('Bestelleinheit: CG');
        let line5NoCuPerOu = this.translate.instant('Inhaltseinheit: BO');
        let line6NoCuPerOu = this.translate.instant('Verpackungsmenge: 10');

        return `${line1NoCuPerOu}.<br />
                ${line2NoCuPerOu}.<br /><br />
                <b>${line3NoCuPerOu}</b><br />
                <ul>
                <li>${line4NoCuPerOu}</li>
                <li>${line5NoCuPerOu}</li>
                <li>${line6NoCuPerOu}</li>
                </ul>`;



      case this.fieldNames.orderUnit:                   // ==> "ORDER_UNIT"

        let line1OrderUnit = this.translate.instant('Das Feld ORDER UNIT gibt die Bestelleinheit an');
        let line2OrderUnit = this.translate.instant('Als Bestelleinheit müssen UN/CEFACT-Einheiten verwendet werden');
        let line3OrderUnit = this.translate.instant('Die Bestelleinheit und der Preis gehören zusammen');

        let line4OrderUnit = this.translate.instant('Geben Sie als Bestelleinheit Stück an, so ist auch der Preis pro Stück zu verwenden.');
        let line5OrderUnit = this.translate.instant('Geben Sie als Bestelleinheit Paket an, so muss auch der Preis für das gesamte Paket hinterlegt werden');
        let line6OrderUnit = this.translate.instant('Geben Sie als Bestelleinheit Stück an, so ist auch der Preis pro Stück zu verwenden.');

        let line7OrderUnit = this.translate.instant('Beispiele');

        let line8OrderUnit = this.translate.instant('BO - Flasche');
        let line9OrderUnit = this.translate.instant('BX - Box');
        let line10OrderUnit = this.translate.instant('C62 - Stück');
        let line11OrderUnit = this.translate.instant('CG - Karton');
        let line12OrderUnit = this.translate.instant('CT - Kartonage');
        let line13OrderUnit = this.translate.instant('LTR - Liter');
        let line14OrderUnit = this.translate.instant('PR - Paar');

        return `<p>${line1OrderUnit}.</p><br />
                ${line2OrderUnit}.<br />
                ${line3OrderUnit}:<br />
                <ul>
                <li>${line4OrderUnit}</li>
                <li>${line5OrderUnit}.</li>
                <li>${line6OrderUnit}</li>
                </ul>
                <b>${line7OrderUnit}:</b>
                <ul>
                <li>${line8OrderUnit}</li>
                <li>${line9OrderUnit}</li>
                <li>${line10OrderUnit}</li>
                <li>${line11OrderUnit}</li>
                <li>${line12OrderUnit}</li>
                <li>${line13OrderUnit}</li>
                <li>${line14OrderUnit}</li>
                </ul>`;



      case this.fieldNames.priceQuantity:               // ==> "PRICE_QUANTITY"

        let line1PriceQuantity = this.translate.instant('Die PRICE_QUANTITY, auch Preismenge genannt, gibt an, auf welche Anzahl Bestelleinheiten sich der angegebene Preis bezieht');
        let line2PriceQuantity = this.translate.instant('Bitte nur ganzzahlige Werte angeben');
        let line3PriceQuantity = this.translate.instant('Bitte keine negativen Werte, 0 oder 1000er Trennzeichen verwenden');

        return `<p>${line1PriceQuantity}.<br />
                ${line2PriceQuantity}.<br />
                ${line3PriceQuantity}.</p>`;



      case this.fieldNames.quanityInterval:             // ==> "QUANTITY_INTERVAL"
        let line1QuanityInterval = this.translate.instant('Die Mengenstaffel wird im Feld QUANTITY_INTERVAL übergeben.') ;
        return `<p>${line1QuanityInterval}.</p>`;



      case this.fieldNames.quantityMin:                 // ==> "QUANTITY_MIN"
        let line1QuantityMin = this.translate.instant('Die Mindestbestellmenge wird im BMEcat-Feld QUANTITY_MIN übergeben.');
        return `<p>${line1QuantityMin}.</p>`;



      case this.fieldNames.quantityMax:                 // ==> "QUANTITY_MAX"
        let line1QuantityMax = this.translate.instant('Die Maximalbestellmenge wird im Feld QUANTITY MAX übergeben.');
        return `<p>${line1QuantityMax}.</p>`;



      case this.fieldNames.deliveryTime:                // ==> "DELIVERY_TIME"
        let line1DeliveryTime = this.translate.instant('Die Lieferzeit in Tagen wird im Feld DELIVERY TIME übergeben.');
        return `<p>${line1DeliveryTime}.</p>`;



      // ****************************************************************
      // WAWI
      // ****************************************************************



      case this.fieldNames.wawiName:
        let line1wawiName = this.translate.instant('Anzeigename des Warenwirtschaftssystems.');
        return `<p>${line1wawiName}.</p>`;
      case this.fieldNames.firstSupplierPid:
        let firstSupplierPid = this.translate.instant('Erste SUPPLIER_PID die von nextPIM vergeben werden darf');
        return `<p>${firstSupplierPid}.</p>`;
      case this.fieldNames.currentSupplierPid:
        let line1currentSupplierPid = this.translate.instant('Letzte SUPPLIER_PID die von nextPIM vergeben wurde.');
        return `<p>${line1currentSupplierPid}.</p>`;
      case this.fieldNames.wawiLenght:
        let line1Length = this.translate.instant('Maximale Anzahl Zeichen für das Feld');
        return `<p>${line1Length}.</p>`;
      case this.fieldNames.prefix:
        let line1prefix = this.translate.instant('Zeichenfolge die VOR eine neu zu vergebene Artikelnummer gesetzt wird.');
        return `<p>${line1prefix}.</p>`;
      case this.fieldNames.postfix:
        let line1postfix = this.translate.instant('Zeichenfolge die NACH eine neu zu vergebene Artikelnummer gesetzt wird.');
        return `<p>${line1postfix}.</p>`;



      // ****************************************************************
      // SEO
      // ****************************************************************



      case this.fieldNames.metaDescription:             // ==> "META_DESCRIPTION"

        let line1MetaDescription = this.translate.instant('Das Feld META DESCRIPTION beschreibt die Seite des Shops, welche den dieses Produkt zeigt und steht bei Suchergebnissen angezeigt' );

        let line2MetaDescription = this.translate.instant('In den guten alten SEO Zeiten war die Meta Description quasi Dreh- und Angelpunkt der Suchmaschinenoptimierung');
        let line3MetaDescription = this.translate.instant('Aktuell hat sie jedoch an Bedeutung verloren, ist aber keinesfalls unwichtig geworden');
        let line4MetaDescription = this.translate.instant('Offiziell wirkt sich die Meta Description überhaupt nicht mehr oder wenn nur noch marginal auf das direkte Ranking in der Google Suche aus');
        let line5MetaDescription = this.translate.instant('Aber auch hier ist es wichtig, das wichtigste Keyword oder die 2 wichtigesten Keywords mit in der Description zu verpacken, denn diese werden Fett hervorgehoben, falls sie zur Suchanfrage passen');
        let line6MetaDescription = this.translate.instant('Auch hier sollte wieder eine Handlungsaufforderung mit eingebaut werden, welche zum Klicken animiert');
        let line7MetaDescription = this.translate.instant('Denn was wiederum indirekt positiv zum Google Ranking beiträgt, ist die Klickrate auf das Suchergebnis und das ist als Baustein für die gesamte Shopware SEO super');
        let line8MetaDescription = this.translate.instant('Banal ausgedrückt: Mehr Klicks auf mein Suchergebnis können zu besseren Rankings führen');

        let line9MetaDescription = this.translate.instant('Zeichenzahl beachten: Nicht mehr als 150-155 Zeichen verwenden');
        let line10MetaDescription = this.translate.instant('Wichtigstes Keyword am Anfang und im weiteren Verlauf einbauen');
        let line11MetaDescription = this.translate.instant('Hersteller und Artikelnamen in der Meta Description verwenden');
        let line12MetaDescription = this.translate.instant('Handlungsaufforderung nicht vergessen');
        let line13MetaDescription = this.translate.instant('Bei Bedarf Sonderzeichen nutzen, für optische Reize und bessere Klickraten');

        let line14MetaDescription = this.translate.instant('Dieses Feld wird nur im nextCat exportiert');

        return `<p>${line1MetaDescription}.</p>
                <p>${line2MetaDescription}. ${line3MetaDescription}. ${line4MetaDescription}. ${line5MetaDescription}. ${line6MetaDescription}. ${line7MetaDescription}. ${line8MetaDescription}. </p>
                <ul>
                <li>${line9MetaDescription}</li>
                <li>${line10MetaDescription}</li>
                <li>${line11MetaDescription}</li>
                <li>${line12MetaDescription}</li>
                <li>${line13MetaDescription}</li>
                </ul>
                <p>${line14MetaDescription}.</p>`;



      case this.fieldNames.metaTitel:                   // ==> "META_TITLE"

        let line1MetaTitel  = this.translate.instant('Das Feld META TITLE bezeichnet die Seite des Shops, welche den dieses Produkt zeigt und steht bei Suchergebnissen ganz oben');
        let line2MetaTitel  = this.translate.instant('Ein META TITLE sollte nicht länger als 55 Zeichen inklusive Leerzeichen sein, dies hat sich allerdings geändert');
        let line3MetaTitel  = this.translate.instant('Denn mittlerweile zählt Google nicht mehr stupide die Zeichenanzahl, sondern berechnet den Title-Tag nach Pixelbreite der der Buchstaben auf der Google Suchergebnisseite');
        let line4MetaTitel  = this.translate.instant('Eine Pixelbreite von 512 Pixel sollte hier nicht überschritten werden');
        let line5MetaTitel  = this.translate.instant('Nun ist es nicht immer einfach die Breite jedes Buchstaben in Pixeln zu kennen');
        let line6MetaTitel  = this.translate.instant('Deswegen kann man grob sagen, dass der Title Tag aktuell nicht mehr als 49-54 Zeichen inklusive Leerzeichen haben darf');
        let line7MetaTitel  = this.translate.instant('Unverändert ist jedoch, dass das wichtigste Keyword einer einzelnen Seite oder Kategorie immernoch an erster Stelle stehen sollte');
        let line8MetaTitel  = this.translate.instant('Der oder die Sätze die man dort unterbringt, sollten natürlich auch Sinn ergeben und zum klicken animieren');
        let line9MetaTitel  = this.translate.instant('Ein Call to Action ist dort unterzubringen, wenn möglich');
        let line10MetaTitel = this.translate.instant('Alternativ kann man auch ein weiteres Keyword mit in den Title-Tag packen');

        let line11MetaTitel  = this.translate.instant('Länge beachten: Nicht mehr als 52-55 Zeichen');
        let line12MetaTitel  = this.translate.instant('Wichtigstes Keyword verwenden');
        let line13MetaTitel  = this.translate.instant('Hersteller und Artikelnamen verwenden');
        let line14MetaTitel  = this.translate.instant('Handlungsaufforderung mit einbauen');
        let line15MetaTitel = this.translate.instant('Bei Bedarf Sonderzeichen Nutzen, für optische Reize und bessere Klickraten');

        let line16MetaTitel  = this.translate.instant('Dieses Feld wird nur im nextCat exportiert');

        return `<p>${line1MetaTitel}. ${line2MetaTitel}. ${line3MetaTitel}. ${line4MetaTitel}. ${line5MetaTitel}. ${line6MetaTitel}. ${line7MetaTitel}. ${line8MetaTitel}. ${line9MetaTitel}. ${line10MetaTitel}. </p>
                <ul>
                <li>${line11MetaTitel}</li>
                <li>${line12MetaTitel}</li>
                <li>${line13MetaTitel}</li>
                <li>${line14MetaTitel}</li>
                <li>${line15MetaTitel}</li>
                </ul>
                <p>${line16MetaTitel}.</p>`;



      // ****************************************************************
      // LOGISTIC
      // ****************************************************************



      case this.fieldNames.countryOfOrgin:              // ==> "COUNTRY_OF_ORIGIN"

        let line1CountryOfOrgin = this.translate.instant('Gibt das Herkunftsland des Produktes an. Über die Subdivisions-Codes kann auch eine Region angegeben werden');
        let line2CountryOfOrgin = this.translate.instant('ISO 3166-1 Ländercodes');
        let line3CountryOfOrgin = this.translate.instant('Beispiele');
        let line4CountryOfOrgin = this.translate.instant('DE (Deutschland)');
        let line5CountryOfOrgin = this.translate.instant('US (USA)');
        let line6CountryOfOrgin = this.translate.instant('DE-NW (Nordrhein-Westfalen in Deutschland)');
        let line7CountryOfOrgin = this.translate.instant('DK-025 (Kreis Roskilde in Dänemark)');

        return `<p>${line1CountryOfOrgin}.<br />
                ${line2CountryOfOrgin}.<br /><br />
                <b>${line3CountryOfOrgin}:</b><br />
                ${line4CountryOfOrgin}.<br />
                ${line5CountryOfOrgin}.<br />
                ${line6CountryOfOrgin}.<br />
                ${line7CountryOfOrgin}.</p>`;



      case this.fieldNames.customsNumber:               // ==> "CUSTOMS_NUMBER"
        let line1CustomsNumber= this.translate.instant('Die Zolltarifnummer ist erforderlich, um die Import- und Exportvorschriften für die betreffende Ware ermitteln zu können');
        let line2CustomsNumber= this.translate.instant('In erster Linie können anhand dieser Nummer die Einfuhr- und ggfs. bestehende Ausfuhrabgaben festgestellt werden');
        let line3CustomsNumber= this.translate.instant('Aber auch das mögliche Bestehen eines Import- oder Exportverbotes oder -vorbehaltes lässt sich auf ihrer Grundlage klären');
        let line4CustomsNumber= this.translate.instant('Die Zolltarifnummer kann anhand des „Warenverzeichnisses für die Außenhandelsstatistik“ ermittelt werden');
        return `<p>${line1CustomsNumber}. ${line2CustomsNumber}. ${line3CustomsNumber}. ${line4CustomsNumber}.</p>`;



      case this.fieldNames.depth:                       // ==> "DEPTH"
        let line1Depth = this.translate.instant('Verpackungstiefe in Meter (m)');
        let line2Depth = this.translate.instant('Trennzeichen "."');
        return `<p>${line1Depth}.<br />
                ${line2Depth}.</p>`;



      case this.fieldNames.lenght:                      // ==> "LENGTH"
        let line1Lenght = this.translate.instant('Verpackungslänge in Meter (m)');
        let line2Lenght = this.translate.instant('Trennzeichen "."');
        return `<p>${line1Lenght}.<br />
                ${line2Lenght}.</p>`;



      case this.fieldNames.weight:                      // ==> "WEIGHT"
        let line1Weight = this.translate.instant('Verpackungsgewicht in Kilogramm (kg)');
        let line2Weight = this.translate.instant('Trennzeichen "."');
        return `<p>${line1Weight}.<br />
                ${line2Weight}.</p>`;



      case this.fieldNames.width:                       // ==> "WIDTH"
        let line1Width = this.translate.instant('Verpackungsbreite in Meter (m)');
        let line2Width = this.translate.instant('Trennzeichen "."');
        return `<p>${line1Width}.<br />
                ${line2Width}.</p>`;



      // ****************************************************************
      // LEGAL
      // ****************************************************************



      case this.fieldNames.isBatterieV:                 // ==> "IS_BATTERIE_V"
        let line1IsBatterieV = this.translate.instant('Ist das Produkt BattG relevant?');
        return `<p>${line1IsBatterieV}</p>`;



      case this.fieldNames.isBiocidalProduct:           // ==> "IS_BIOCIDAL_PRODUCT"
        let line1IsBiocidalProduct = this.translate.instant('Handelt es sich um laut BiozidV biozidbehandelte Ware?');
        return `<p>${line1IsBiocidalProduct}</p>`;



      case this.fieldNames.isCeGs:                      // ==> "IS_CE_GS"
        let line1IsCeGs = this.translate.instant('Handelt es sich um ein CE GS relevantes Produkt?');
        return `<p>${line1IsCeGs}</p>`;



      case this.fieldNames.isCommoditiesControll:       // ==> "IS_COMMODITIES_CONTROLL"
        let line1IsCommoditiesControll = this.translate.instant('Handelt es sich um Grundstoffüberwachung relevantes Produkt?');
        return `<p>${line1IsCommoditiesControll}</p>`;



      case this.fieldNames.isDetergenzienv:             // ==> "IS_DETERGENZIENV"
        let line1IsDetergenzienv = this.translate.instant('Handelt es sich um ein DetergenzienV relevantes Produkt?');
        return `<p>${line1IsDetergenzienv}</p>`;



      case this.fieldNames.isDualUse:                   // ==> "IS_DUAL_USE"
        let line1IsDualUse = this.translate.instant('Ist das Produkt prinzipiell sowohl für zivile, als auch für militärische Zwecke verwendbar?');
        return `<p>${line1IsDualUse}</p>`;



      case this.fieldNames.isFertigpackv:               // ==> "IS_FERTIGPACKV"
        let line1IsFertigpackv = this.translate.instant('Handelt es sich um laut FertigpackV relevant Ware?');
        return `<p>${line1IsFertigpackv}</p>`;



      case this.fieldNames.isKosmetikv:                 // ==> "IS_KOSMETIKV"
        let line1IsKosmetikv = this.translate.instant('Handelt es sich um laut Kosmetik Verordnung relevantes Produkt?');
        return `<p>${line1IsKosmetikv}</p>`;



      case this.fieldNames.isOekodesignEvpg:            // ==> "IS_OEKODESIGN_EVPG_ENVKG"
        let line1IsOekodesignEvpg = this.translate.instant('Handelt es sich um laut Ökodesign EVPG ENVKG relevantes Produkt?');
        return `<p>${line1IsOekodesignEvpg}</p>`;



      case this.fieldNames.isReach:                     // ==> "IS_REACH"
        let line1IsReach = this.translate.instant('Handelt es sich um ein REACH relevantes Produkt?');
        return `<p>${line1IsReach}</p>`;



      case this.fieldNames.isSecurityDatasheetNeeded:   // ==> "IS_SECURITY_DATASHEET_NEEDED"
        let line1IsSecurityDatasheetNeeded = this.translate.instant('Ist ein Sicherheitsdatenblatt erforderlich?');
        return `<p>${line1IsSecurityDatasheetNeeded}</p>`;



      case this.fieldNames.isSelfServiceForbidden:      // ==> "IS_SELFSERVICE_FORBIDDEN"
        let line1IsSelfServiceForbidden = this.translate.instant('Handelt es sich um ein Produkt, für das eine selbstbedienungsverbot relevant ist?');
        return `<p>${line1IsSelfServiceForbidden}</p>`;



      case this.fieldNames.isVerpackungsv:              // ==> "IS_VERPACKUNGSV"
        let line1IsVerpackungsv = this.translate.instant('Handelt es sich um laut VerpackungsV relevantes Produkt?');
        return `<p>${line1IsVerpackungsv}</p>`;



      case this.fieldNames.isWeeeRohsEar:               // ==> "IS_WEEE_ROHS_EAR"
        let line1IsWeeeRohsEar = this.translate.instant('Handelt es sich um ein WEEE ROHS EAR relevantes Produkt?');
        return `<p>${line1IsWeeeRohsEar}</p>`;



      // ****************************************************************
      // MISC (TEMPLATE-EDITOR)
      // ****************************************************************



      case this.fieldNames.group:                       // ==> "GROUP_STRING"
        let line1Group = this.translate.instant('Der GROUP_STING gibt den Kategoriebaum an.');
        return `<p>${line1Group}</p>`;



      case this.fieldNames.master:                      // ==> "MASTER"
        let line1Master = this.translate.instant('MASTER gibt die Variantenzuordnung an');
        return `<p>${line1Master}.</p>`;



      default:
        return "";
    }
  }
}
