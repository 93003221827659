import { Component } from '@angular/core';
import { ModelService } from '../../../../Services/model.service';
import { StartAction } from '../../../../Model/Dto/StartAction';
import { UserActionBaseComponent } from '../userAction-base.component';
import { Customer } from '../../../../Model/User/Customer';
import { Catalog } from '../../../../Model/Catalog/Catalog';
import { UpdateTemplate } from '../../../../Model/Catalog/UpdateTemplate';
import { TranslateService } from '@ngx-translate/core';
import { FieldNames } from '../../../../Model/FieldNames';
import { Message } from '../../../../Model/System/Message';
import { SystemService } from '../../../../Services/system.service';
import { TranslatedText } from '../../../../Model/ui/TranslatedText';

@Component({
  selector: 'userAction-updateCatalog',
  templateUrl: 'userAction-updateCatalog.component.html'
})
export class UserActionUpdateCatalogComponent extends UserActionBaseComponent {
  private fieldNames: FieldNames;
  _catalogs: Catalog[];
  customers: Customer[];
  _replacePrice: boolean = false;
  _selectedCustomer: string;
  _sourceCatalog: string = '';
  updateTemplates: UpdateTemplate[];
  selectedTemplate: string = '';
  txtSelectTemplate: string;
  txtSelectCatalog: string;
  useEanAsKey: boolean = false;
  keys: TranslatedText[] = [];
  selectedKey: string;
  selectedExportParameterId: string = '';

  warningSpidUpdate: string = '';
  warningIntPidUpdate: string = '';
  warningSpaltidUpdate: string = '';

  constructor(
    public modelService: ModelService,
    public systemService: SystemService,
    public translate: TranslateService
  ) {
    super(modelService);

    // merken:
    this.currentNavigationTitle = this.modelService.systemService.currentNavigationTitle;

    this.fieldNames = new FieldNames();

    this.warningSpidUpdate = this.translate.instant('warningSpidUpdate');
    this.warningIntPidUpdate = this.translate.instant('warningIntPidUpdate');
    this.warningSpaltidUpdate = this.translate.instant('warningSpaltidUpdate');

    this.keys.push(new TranslatedText('SUPPLIER_PID', this.translate.instant('SUPPLIER_PID')));
    this.keys.push(new TranslatedText('INTERNATIONAL_PID', this.translate.instant('INTERNATIONAL_PID')));
    this.keys.push(new TranslatedText('SUPPLIER_ALT_PID', this.translate.instant('SUPPLIER_ALT_PID')));
    this.selectedKey = this.keys[0].value;
  }

  ngOnInit(): void {
    super.ngOnInit(); //important

    this.modelService.loginService.customerService
      .getAllCustomerByUser(this.modelService.loginService.currentUser.id)
      .subscribe((customers: Customer[]) => {
        this.customers = customers;
        this.selectedCustomer = this.modelService.loginService.currentCustomer.id;
      });
    this.modelService.updateTemplateService.getUpdateTemplates().subscribe((templates: UpdateTemplate[]) => {
      this.updateTemplates = templates;
      if (templates.length > 0) {
        this.selectedTemplate = templates[0].id;

        this.onKeyChanged(null);
      }
    });

    this.txtSelectTemplate = this.translate.instant('Bitte wählen Sie ein Update-Template aus');
    this.txtSelectCatalog = this.translate.instant('Bitte wählen Sie mindestens einen Katalog aus');

    // der CTOR von updateTemplates ändert ihn, wir müssen den wieder zurücksetzen:
    this.modelService.systemService.currentNavigationTitle = this.currentNavigationTitle;
  }

  currentNavigationTitle: string = '';

  _showPopup: boolean = false;

  get showPopup() {
    return this._showPopup;
  }

  set showPopup(value: boolean) {
    this._showPopup = value;

    if (value) {
      // reicht in ngOnInit:
      // der CTOR von updateTemplates ändert ihn, wir müssen den wieder zurücksetzen:
      //this.modelService.systemService.currentNavigationTitle = this.currentNavigationTitle;
    } else {
      // nach dem Schließen des Popup die Liste neu laden, falls ein neues Template angelegt wurde:
      this.modelService.updateTemplateService.getUpdateTemplates().subscribe((templates: UpdateTemplate[]) => {
        this.updateTemplates = templates;
        if (templates.length > 0 && this.selectedTemplate == '') {
          this.selectedTemplate = templates[0].id;

          this.onKeyChanged(null);
        }
      });
    }
  }

  showEditTemplates() {
    this.showPopup = true;
  }

  get catalogs(): Catalog[] {
    return this._catalogs;
  }

  set catalogs(value: Catalog[]) {
    this._catalogs = value;
  }

  get replacePrice(): boolean {
    return this._replacePrice;
  }

  set replacePrice(value: boolean) {
    this._replacePrice = value;
  }

  get selectedCustomer(): string {
    return this._selectedCustomer;
  }

  set selectedCustomer(value: string) {
    this._selectedCustomer = value;
    this.modelService.catalogService.getCatalogs(this._selectedCustomer).subscribe((result: Catalog[]) => {
      this.catalogs = result.filter((c) => !c.isVirtual);
    });
  }

  get sourceCatalog(): string {
    return this._sourceCatalog;
  }

  set sourceCatalog(sourceCatalog: string) {
    this._sourceCatalog = sourceCatalog;
  }

  editTemplatesClick() {
    this.modelService.router.navigate(['updateTemplates']);
  }

  onKeyChanged($event) {
    let template = this.updateTemplates.filter((u) => u.id == this.selectedTemplate)[0];
    if (template == null || template == undefined) {
      return;
    }
  }

  createParameterStringAsJsonString() {
    return JSON.stringify({
      sourceCatalogId: this.sourceCatalog,
      sourceCustomerId: this.selectedCustomer,
      targetCatalogId: this.modelService.catalogService.catalog.id,
      targetCustomerId: this.modelService.loginService.currentCustomer.id,
      updateTemplateId: this.selectedTemplate,
      productKey: this.selectedKey,
      exportParameterId: this.selectedExportParameterId
    });
  }

  startMacro() {
    if (this.sourceCatalog == '') {
      this.modelService.systemService.notifyInfo(this.txtSelectCatalog);
      return;
    }
    if (this.sourceCatalog == this.modelService.catalogService.catalog.id) {
      return;
    }
    if (this.selectedTemplate == '') {
      this.modelService.systemService.notifyInfo(this.txtSelectTemplate);
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentCustomer.id;
    startAction.actionType = 'UpdateFromCatalog';
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction).subscribe((next) => {
      //additional step
      this.sourceCatalog = '';

      this.userActionStarted.emit(next);
    });
  }
}
