import { Component, OnInit } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";

import { ModelService } from '../../Services/model.service';
import { SendToNWConfig } from '../../Model/Dto/SendToNWConfig';




@Component({
  selector: "recurringJobs",
  templateUrl: "recurringJobs.component.html"
})
export class RecurringJobsComponent{

  tabs = [
    { id: 1, title: "Jobs", template: "jobsTemplate" },
    { id: 2, title: "FTP Server", template: "ftpServerTemplate" }
  ];
  jobs = [
    { id: 1, title: "FTP Import", template: "ftpImportJobsTemplate" },   
    { id: 2, title: "FTP Export", template: "ftpExportJobsTemplate" },
    { id: 3, title: "FTP SupplierMappingImport", template: "FtpSupplierPidMappingsImportJobsTemplate" }
  ];
  constructor(public modelService: ModelService) {}
}
