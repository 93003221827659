export class ValidFeature {

  constructor(
    public id?: string,
    public validFeatureSystemId?: string,
    public name?: string,
    public unit?: string,
    public type?: string 
  ) {
  }

  get displayValue(): string {
    return this.name + ' ' + this.unit;

  }
  

}

