<div [ngStyle]="{'height': 'inherit', 'display':'flex', 'flex-direction':'column'}">
  <div class="dx-fieldset" [ngStyle]="{'margin': '20px 30px'}">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Preislisten auswählen' | translate }} </div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.priceListsContentFilter.useFilter" (onValueChanged)="refreshPriceListFilter()"></dx-switch>
      </div>
    </div>
    <div *ngIf="exportParameter.priceListsContentFilter.useFilter" class="dx-field">
      <div class="dx-field-label">{{ 'OnRequest Preise exportieren' | translate }} </div>
      <div class="dx-field-value">
        <dx-switch [activeStateEnabled]="filterKeys.has('|#|on_request_with_price')" (onValueChanged)="onToggle('|#|on_request_with_price')"></dx-switch>
      </div>
    </div>
  </div>
  <div *ngIf="exportParameter.priceListsContentFilter.useFilter" [ngStyle]="{'height': '100%', 'overflow':'hidden'}">
    <div *ngIf="isPriceListFiltersLoaded==false" style="top: 30%;">
      <div class="loader">
      </div>
    </div>
    <div *ngIf="isPriceListFiltersLoaded==true" class="{{fullScreen ? '' : 'maxHeight'}}" [ngStyle]="{'margin-top': '-20px', 'height': 'inherit'}">
      <dx-tree-list [(dataSource)]="priceListFilters"
                    [(selectedRowKeys)]="exportParameter.priceListsContentFilter.filterKey"
                    dataStructure="tree"
                    itemsExpr="items"
                    keyExpr="groupString"
                    showCheckBoxesMode="selectAll"
                    [ngStyle]="{'height': 'inherit'}">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection mode="multiple" recursive="true"></dxo-selection>
        <dxo-scrolling preloadEnabled="true" useNative="true"></dxo-scrolling>
        <dxi-column dataField="text" caption="{{ 'Preislisten' | translate }}"></dxi-column>
      </dx-tree-list>
    </div>
  </div>
</div>
