
<dx-tab-panel [dataSource]="pages" [selectedIndex]="0">

  <div *dxTemplate="let company of 'liveTemplate'">


    <div class="dx-fieldset" *ngIf="model">


      <div class="dx-field" >
        <div class="dx-field-label" (mouseenter)="ttt('productRange')" (mouseleave)="ttt('productRange')">{{ 'Sortiment' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttProductRange">
          <label class="placeLabel {{ productRange.length>0 ? 'placeLabelSmall' : '' }}">PRODUCT_RANGE</label>
          <dx-text-box [(value)]="productRange" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('osgCatalogProductRange')">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttProductRange" class="toolTip toolTipField" [(visible)]="v['productRange']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Das Sortiment des OSG Shops' | translate }}.
          </div>
        </dx-tooltip>
      </div>
      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('lastSend')" (mouseleave)="ttt('lastSend')">{{ 'Letzte Übertragung' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSend">
          <label class="placeLabel {{ lastSend.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
          <dx-text-box [(value)]="lastSend" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('osgCatalogLastSend')">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttlastSend" class="toolTip toolTipField" [(visible)]="v['productRange']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Datum der letzten Übertragung zu oder vom Shop' | translate }}.
          </div>
        </dx-tooltip>
      </div>


    </div>
  </div>


  <div *dxTemplate="let company of 'testTemplate'">
   
    <div class="dx-fieldset" *ngIf="model">
     
      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('productRangeTest')" (mouseleave)="ttt('productRangeTest')">{{ 'Sortiment' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttProductRangeTest">
          <label class="placeLabel {{ productRangeTest.length>0 ? 'placeLabelSmall' : '' }}">PRODUCT_RANGE</label>
          <dx-text-box [(value)]="productRangeTest" [disabled]="true" [showClearButton]="false">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttProductRangeTest" class="toolTip toolTipField" [(visible)]="v['productRangeTest']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Das Sortiment des OSG Shops' | translate }}.
          </div>
        </dx-tooltip>
      </div>
      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('lastSendTest')" (mouseleave)="ttt('lastSendTest')">{{ 'Letzte Übertragung' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSendTest">
          <label class="placeLabel {{ lastSendTest.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
          <dx-text-box [(value)]="lastSendTest" [disabled]="true" [showClearButton]="false">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttlastSendTest" class="toolTip toolTipField" [(visible)]="v['ttlastSendTest']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Datum der letzten Übertragung zu oder vom Shop' | translate }}.
          </div>
        </dx-tooltip>
      </div>


     




    </div>


  </div>
</dx-tab-panel>
