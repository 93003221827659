import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { CatalogDataQuality } from "../../Model/validation/CatalogDataQuality";
import { ActivatedRoute } from "@angular/router";
import { CatalogDataQualityService } from "../../Services/Validation/catalogDataQuality.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "dataQualityPrintChart",
  templateUrl: "dataQualityPrintChart.component.html",
  styleUrls: []
})

export class DataQualityPrintChartComponent implements OnInit {
  customerId: string;
  catalogId: string;

  dataSource: any[];
  colorPalette: string[];
  catalogDataQuality: CatalogDataQuality;

  constructor(private catalogDataQualityService: CatalogDataQualityService, activatedRoute: ActivatedRoute) {
    activatedRoute.queryParams.subscribe(params => {
      this.catalogId = params['catalogId'];
    });
    activatedRoute.queryParams.subscribe(params => {
      this.customerId = params['customerId'];
    });
  }

  async ngOnInit(): Promise<void> {
    this.catalogDataQuality = await this.catalogDataQualityService.getCatalogDataQuality(this.customerId, this.catalogId);
    
    this.dataSource = [];
    this.colorPalette = [];
    if (this.catalogDataQuality.productsCountInGroups != undefined) {
      this.catalogDataQuality.productsCountInGroups.forEach(x => {
        this.dataSource.push({ category: x.validationGroup.name, val: x.productCount });
        this.colorPalette.push(x.validationGroup.backgroundColor);
      });
    }
  }

  customizeText = (arg: any) => {
    return arg.valueText + " %";
  }

  customizeLabel = (arg: any) => {
    if (arg.valueText == "1")
      return "1 Produkt";

    return arg.valueText + " Produkte";
  }
}
