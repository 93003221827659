export class PackingUnit {
  constructor(
    public order?: number,
    public packingUnitCode?: string,
    public packingUnitDescr?: string,
    public quantityMin?: number,
    public quantityMax?: number,

    public amountContentUnit?: number,
    public amountSmallerUnit?: number
  ) { }
}
