import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { ValidFeatureService } from "../../../../Services/validFeature.service";
import { ValidFeatureSystem } from "../../../../Model/ValidFeatureSystem";
import { TranslateService } from "@ngx-translate/core";
import { ViewService } from "../../../../Services/view.service";


@Component({
  selector: "userAction-renameFeatureSystem",
  templateUrl: "userAction-renameFeatureSystem.component.html"
})
export class UserActionRenameFeatureSystemComponent extends UserActionFeatureSystemsComponent implements OnInit {
  featureSystemsWithMagicFeatureSystems: ValidFeatureSystem[] = [];
  destFeatureSystemName: string = "";
  selectedFeatureSystem: ValidFeatureSystem;

  constructor(public modelService: ModelService, validFeatureService: ValidFeatureService, private translate: TranslateService) {
    super(modelService, validFeatureService);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.featureSystemsWithMagicFeatureSystems.push(...this.featureSystems);
    ViewService.dropDownFeatureSystemNames.forEach(magicFeatureSystemName => {
      if (!this.featureSystemsWithMagicFeatureSystems.some(x => x.name == magicFeatureSystemName)) {
        this.featureSystemsWithMagicFeatureSystems.push(new ValidFeatureSystem(undefined, magicFeatureSystemName));
      }
    });
  }

  startMacro() {
    if (!this.selectedFeatureSystem) {
      this.modelService.systemService.notifyInfo(this.translate.instant("FeatureSystem auswählen"));
      return;
    }
    if (this.destFeatureSystemName == "") {
      this.modelService.systemService.notifyInfo(this.translate.instant("Neuen FeatureSystem-Namen eingeben"));
      return;
    }
    if (this.destFeatureSystemName == this.selectedFeatureSystem.name) {
      this.modelService.systemService.notifyInfo(this.translate.instant("Der neue Name ist gleich dem alten Namen"));
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "RenameFeatureSystem";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify({
      source: this.selectedFeatureSystem.name,
      newName: this.destFeatureSystemName
    });
  }
}
