export class MappingProfile {

    constructor(
        public id?: string,
        public name?: string,
 
    ) {

    }

}
