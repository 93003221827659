import { CustomField } from "./CustomField";

export class WawiSettings {

  constructor(
    public id?: number,
    public customerId?: string,
    public name?: string,
    public startSupplierPid?: number,
    public currentSupplierPid?: number,
    public postFixSupplierPid?: string,
    public preFixSupplierPid?: string,
    public hasLine1?: boolean,
    public hasLine2?: boolean,
    public hasLine3?: boolean,
    public hasLine4?: boolean,
    public line1Length?: number,
    public line2Length?: number,
    public line3Length?: number,
    public line4Length?: number,
    public customFields: CustomField[] = []) {
  }
}












