import { Component, Input, OnInit } from '@angular/core';
import { ModelService } from '../Services/model.service';
import { UpdateTemplate } from '../Model/Catalog/UpdateTemplate';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TemplateItemTemplate } from '../Model/Catalog/TemplateItemTemplate';
import { UpdateTemplateItem } from '../Model/Catalog/UpdateTemplateItem';
import { TranslateService } from '@ngx-translate/core';
import { SystemService } from '../Services/system.service';
import { Router } from '@angular/router';
import { UpdateTemplateService } from '../Services/updateTemplate.service';
import { CatalogService } from '../Services/CatalogManagement/catalog.service';
import { LoginService } from '../Services/login.service';
import { ExportParameter } from '../Model/exportParameter/ExportParameter';
import { ExportConfigurationService } from '../Services/exportConfiguration.service';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'updateTemplates',
  templateUrl: 'updateTemplates.component.html'
})
export class UpdateTemplatesComponent implements OnInit {
  @Input() helpSwitch?: boolean = true;

  templates: UpdateTemplate[];
  selectedTemplate: UpdateTemplate;
  selectedRowKeys: any;

  constructor(
    public translate: TranslateService,
    public loginService: LoginService,
    public router: Router,
    public updateTemplateService: UpdateTemplateService,
    public catalogService: CatalogService,
    public systemService: SystemService,
    public exportConfigurationService: ExportConfigurationService
  ) {
    this.systemService.currentNavigationTitle = this.translate.instant('Update Templates');
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newTemplateButtonTemplate'
    });
  }

  ngOnInit(): void {
    if (this.loginService.currentUser == null) {
      this.router.navigate(['/']);
      return;
    }

    this.updateTemplateService.getUpdateTemplates().subscribe((result: UpdateTemplate[]) => {
      this.templates = result;
      if (this.templates?.length > 0) this.selectedRowKeys = [this.templates[0].id];
    });
    this.catalogService.loadFields();
  }

  onSelectionChangedClick(e: SelectionChangedEvent) {
    this.selectedTemplate = e.selectedRowsData[0];
  }

  get fields() {
    return this.catalogService.fields;
  }

  onDeleteClicked(e) {
    this.updateTemplateService.deleteUpdateTemplate(e.data);
  }

  addTemplate() {
    let updateTemplate = new UpdateTemplate();
    updateTemplate.customerId = this.loginService.currentUser.customerId;

    this.updateTemplateService.addUpdateTemplate(updateTemplate).subscribe((result: UpdateTemplate) => {
      this.selectedTemplate = result;
      this.templates.push(this.selectedTemplate);
    });
  }

  toggleHelp() {
    this.systemService.showHelp = !this.systemService.showHelp;
    if (this.systemService.showHelp) {
      this.systemService
        .loadHelp(this.loginService.currentUser.customerId)
        .subscribe((result: CustomerWiki) => (this.systemService.help = result.entries));
    }
  }
}
