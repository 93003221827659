<div [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column' }">
  <div class="dx-fieldset" [ngStyle]="{ margin: '20px 30px' }">
    <div class="dx-field" *ngIf="isExcel() || this.exportParameter.isUpdateFilter">
      <div class="dx-field-label">{{ 'ExportProducts' | translate }}</div>
      <div class="dx-field-value">
        <dx-select-box
          [items]="exportProductTypes"
          [(value)]="selectedExportProductType"
          (onValueChanged)="onExportProductsChanged($event)"
          displayExpr="text"
        ></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Kategorien ohne Produkte nicht exportieren' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.ignoreEmptyCategories"></dx-switch>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">
        {{ 'Master immer ausgeben, wenn Childs den Filterkriterien entsprechen' | translate }}
      </div>
      <div class="dx-field-value">
        <dx-switch
          [readOnly]="selectedExportProductType.id != ''"
          [(value)]="exportParameter.exportMasterOfFilteredChilds"
        ></dx-switch>
      </div>
    </div>
  </div>
</div>
