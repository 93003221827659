import { DateTime } from "rrule/dist/esm/datetime";

export class OxomiSupplier {

  constructor(
    public id?: number,
    public customerId?: string,
    public number?: string,
    public name?: string,
    public lastDataUpdate?: Date,
    public dataUpdateAvailable?: boolean
  ) {
  }
}
