<div class="dx-fieldset">
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Benutztes Limit ' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.usedLimit"></dx-number-box>
      </div>
    </div>
  </div>

  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Limit ' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [(value)]="invoiceSetting.limit" format="#,##0.## €" (onValueChanged)="update()" [showClearButton]="true"></dx-number-box>
      </div>
    </div>
  </div>

  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Preis pro Textgenerierung' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="!isAdmin()" format="#,##0.## €" [(value)]="invoiceSetting.pricePerOpenAi" (onValueChanged)="update()" [showClearButton]="true"></dx-number-box>
      </div>
    </div>
  </div>

  <div class="p-2 flex-fill" *ngIf="isAdmin() || invoiceSetting.allowance > 0">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Freibetrag' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="!isAdmin()" format="#,##0.## €" [(value)]="invoiceSetting.allowance" (onValueChanged)="update()" [showClearButton]="true"></dx-number-box>
      </div>
    </div>
  </div>

  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label"></div>
      <div class="dx-field-value">
        <dx-button text="{{ 'Export' | translate }}" icon="download" style="width:'100%'" (onClick)="export()"></dx-button>
      </div>
    </div>
  </div>
</div>



<dx-chart id="chart" [dataSource]="invoices">
  <dxi-series argumentField="day"  type="bar"
              valueField="costs" format="#,##0.## €" name="{{ 'Kosten' | translate }}">
  </dxi-series>
  <dxo-argument-axis [workdaysOnly]="false" argumentType="date">
    <dxo-label wordWrap="none" format="dd.MM.yy"></dxo-label>
  </dxo-argument-axis>
</dx-chart>
