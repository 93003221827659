export class AddItem {

  constructor(
    public parentId?: string,
    public customerId?: string,
    public parentTreeId?: string,
    public newCategoryName?: string
  ) {
  }
}
