<dx-drop-down-box
  class="flex-grow-1 np-highlight"
  style="width: 450px;"
  [showClearButton]="true"
  [(value)]="pimField.field"
  [dataSource]="fieldList"
  [displayExpr]="getFieldDisplayExpr"
  valueExpr="key"
  label="{{ 'Feld' | translate }}"
  placeholder="{{ 'Feld auswählen' | translate}}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="fieldList"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-number-box
  class="flex-grow-1 np-highlight"
  min="1"
  label="{{ 'Sortiernummer' | translate }}"
  [(value)]="pimField.systemKey"
  (onValueChanged)="onOrderChanged($event)"
></dx-number-box>
