import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Feedback } from '../Model/Feedback';

@Injectable()
export class FeedbackService {

    constructor(private http: HttpClient) {
    }

    sendFeedback(feedback: Feedback) {
        return this.http.post("api/feedback/SendFeedback", feedback);
    }
}
