export class CustomField {

  constructor(
    public id?: string,
    public order?: number,
    public wawiSettingsId?: number,
    public name?: string,
    public length?: string,
    public fieldType?: string,
    public listId?: string,
    public useInGevis: boolean = false,
  ) {
  }
}
