<div class="dx-field">
  <div class="dx-field-label">{{ 'Zielaccounts' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedCustomers?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'Zielaccounts auswählen' | translate }}</label>
    <dx-drop-down-box [(value)]="selectedCustomers"
                      valueExpr="id"
                      displayExpr="name"
                      [showClearButton]="true"
                      [dataSource]="customers">
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid [dataSource]="customers"
                      keyExpr="id"
                      [columns]="['name']"
                      [selection]="{ mode: 'multiple' }"
                      [hoverStateEnabled]="true"
                      [paging]="{ enabled: true, pageSize: 10 }"
                      [filterRow]="{ visible: true }"
                      [scrolling]="{ mode: 'infinite' }"
                      [height]="345"
                      [(selectedRowKeys)]="selectedCustomers">
        </dx-data-grid>
      </div>
    </dx-drop-down-box>
  </div>
</div>



