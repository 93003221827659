<div slot="content" class="h-100 p-2">
  <div class="h-100 w-100 mx-auto">
    <dx-data-grid id="gridContainer"
                  class="h-100"
                  [dataSource]="validFeatureValues"
                  [allowColumnReordering]="true"
                  [rowAlternationEnabled]="true"
                  [allowColumnResizing]="true"
                  [showBorders]="true">
      <dxi-column dataField="value" caption="{{ 'Wert' | translate }}"></dxi-column>
    </dx-data-grid>
  </div>
</div>
