import { Component } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ValidFeatureSystem } from "../Model/ValidFeatureSystem";
import { ValidFeature } from "../Model/ValidFeature";
import { TranslateService } from "@ngx-translate/core";
import { Message } from "../Model/System/Message";
import { StartAction } from "../Model/Dto/StartAction";
import { ValidFeatureUsageReport } from "../Model/ValidFeatureUsageReport";
import CustomStore from "devextreme/data/custom_store";


@Component({
  selector: "validFeatureSystem",
  templateUrl: "validFeatureSystem.component.html"
})
export class ValidFeatureSystemComponent {


  validFeatureSystems: ValidFeatureSystem[] = [];
  validFeatures: ValidFeature[] = [];
  selectedValidFeatureSystem: ValidFeatureSystem = new ValidFeatureSystem()
  usageDataSource: any;
  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.modelService.validFeatureService.getSystems(this.modelService.loginService.currentUser.customerId).subscribe(
      (result: ValidFeatureSystem[]) => {
        this.validFeatureSystems = result
      });
    let that = this;
    this.usageDataSource = new CustomStore({
      load: async function (loadOptions) {
        return new Promise((resolve, reject) => {
          that.modelService.validFeatureService.getUsageReport(that.modelService.loginService.currentUser.customerId, that.validFeatureSystem.id).subscribe(
            (result: ValidFeatureUsageReport[]) => {
              resolve({
                data: result,
                totalCount: result.length,
              });
            },
            (error) => {
              reject("Data loading error");
            }
          );
        });
      }
    });
  }

  startDeleteJob() {
    const startAction = new StartAction();
    startAction.customerId = this.modelService.loginService.currentUser.customerId;

    startAction.actionType = "DeleteUnusedFeatures";
    startAction.actionId = "DeleteUnusedFeatures";
    startAction.actionParameter = "{}";

    this.modelService.jobService.startJob(startAction)
      .subscribe(success => {
        if (success) {
          this.modelService.systemService.notify(new Message(this.translate.instant("Das Makro wurde gestartet")));
        }
      });
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'deleteUnusedButtonTemplate'
    });



  }
  onRowClick(event: any) {
    this.modelService.validFeatureService.getFeatures(event.data.id, this.modelService.loginService.currentUser.customerId).subscribe(
      (result: ValidFeature[]) => {
        this.validFeatures = result
      });
    this.selectedValidFeatureSystem.name = event.data.name
  }
  validFeatureSystem: ValidFeatureSystem = new ValidFeatureSystem();
  isUsageListVisible: boolean;
  usageReport: ValidFeatureUsageReport[] = new Array<ValidFeatureUsageReport>();
  loadingVisible: boolean;

  async onLookUpClick(validFeatureSystem: ValidFeatureSystem) {
    this.validFeatureSystem = validFeatureSystem;
    this.usageReport = new Array<ValidFeatureUsageReport>();
    this.isUsageListVisible = true;
    if (this.usageGrid) {
      this.usageGrid.refresh();
    } else {
      this.needsRefresh = true;

    }
  }
  usageGrid: any;
  needsRefresh: boolean;

  onContentReady(e) {
    this.usageGrid = e.component;
    if (this.needsRefresh) {
      this.usageGrid.refresh();
      this.needsRefresh = false;
    }

  }


}
