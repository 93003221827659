<div slot="content" class="h-100 p-2">
  <div class="h-100 w-100 mx-auto">
    <dx-data-grid id="gridContainer"
                  class="h-100"
                  [(dataSource)]="ValidFeatures"
                  [allowColumnReordering]="true"
                  [rowAlternationEnabled]="true"
                  [allowColumnResizing]="true"
                  [showBorders]="true"
                  (onRowUpdated)="updateValidFeatureValue($event)">
        <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true">       </dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxi-column dataField="name" caption="{{ 'Name' | translate }}" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="unit" caption="{{ 'Einheit' | translate }}" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="type" caption="{{ 'Type' | translate }}" [allowEditing]="true" [showEditorAlways]="true" editCellTemplate="typeSelector"></dxi-column>
        <div *dxTemplate="let cellInfo of 'typeSelector'">
          <dx-select-box [items]="types"
                         [(value)]="cellInfo.value"
                         showClearButton="true"
                         (onValueChanged)="cellInfo.setValue($event.value)"></dx-select-box>
        </div>


        <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
        <div *dxTemplate="let validFeature of 'detail'">
          <validFeatureValue [validFeature]="validFeature.data" class="flex-fill overflow-hidden"></validFeatureValue>
        </div>
    </dx-data-grid>
  </div>
</div>
