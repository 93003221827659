<div class="d-flex gap-1">
  <div class="dx-field-value inputWithPlaceHolder flex-fill w-25">
    <label class="placeLabel placeLabelSmall">{{ 'Suchmodus' | translate }}</label>
    <dx-select-box
      [(value)]="model.searchMode"
      [items]="searchModes"
      displayExpr="name"
      (onSelectionChanged)="onSearchModeSelectionChanged()"
      valueExpr="value"
    ></dx-select-box>
  </div>
  <div class="dx-field-value inputWithPlaceHolder flex-column w-75">
    <label class="placeLabel placeLabelSmall">{{ 'Durchsuchte Felder' | translate }}</label>
    <dx-drop-down-box
      [(value)]="selectedSearchFields"
      valueExpr="value"
      displayExpr="name"
      [showClearButton]="true"
      [dataSource]="searchFields"
    >
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid
          [dataSource]="searchFields"
          keyExpr="value"
          [closeOnOutsideClick]="false"
          [selection]="{ mode: 'multiple' }"
          [hoverStateEnabled]="true"
          (onSelectionChanged)="onSearchFieldsSelectionChanged()"
          [(selectedRowKeys)]="selectedSearchFields"
        ></dx-data-grid>
      </div>
    </dx-drop-down-box>
  </div>
</div>
