export class OsgImportCatalog {

  constructor(
    public customerId?: string,
    public productRange?: string,
    public keepRootCategory?: boolean,
    public useTest?: boolean,
    public takeAllCategory?:boolean,

  ) {
  }
}
