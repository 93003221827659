import { ValidFeature } from "../../ValidFeature";

export class FeatureToBeRenamed {
  public constructor(
    public feature: ValidFeature,
    public newName: string,
    public newUnit: string)
  {
  }
}
