import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from "@ngx-translate/core";
import { TeachingSet, TeachingSetStates } from "../Model/OpenAi/teachingset";
import { TeachingSetService } from "../Services/teachingSet.service";



@Component({
  selector: "teachingsets",
  templateUrl: "teachingsets.component.html"
})
export class TeachingSetsComponent implements OnInit {
  model: TeachingSet[];

  constructor(public modelService: ModelService, public teachingSetService: TeachingSetService, public translate: TranslateService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Description Wizard");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addButtonTemplate'
    });
  }

  ngOnInit(): void {
    this.teachingSetService.getAll().subscribe(
      result => {
        this.model = result;
      });
  }

  onSelectionChangedClick(e: { data: TeachingSet }) {
    this.teachingSetService.selectedSet = e.data;
  }
  onDeleteClicked(e: { data: TeachingSet }) {
    this.teachingSetService.deleteSingle(e.data.id);
  }

  addSingle() {

    let teachingSet = {
      customerId: this.modelService.loginService.currentUser.customerId
    } as TeachingSet;

    this.teachingSetService.addSingle(teachingSet).subscribe(
      result => {
        this.teachingSetService.selectedSet = result;
        this.model.push(result);
      });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }

  getStateDesc(state: TeachingSetStates) {
    switch (state) {
      case TeachingSetStates.InProgress:
        return "InProgress";
      case TeachingSetStates.Ready:
        return "Ready";
      case TeachingSetStates.Requested:
        return "Requested";
      case TeachingSetStates.Uninitialized:
        return "Uninitialized";
    }
  }

}
