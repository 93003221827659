<div class="{{look}} px-0 h-100 w-100">
    <div class="d-flex flex-row expandicons">
        <div *ngIf="navigationSwitch" (click)="toggleNavigation()" class="px-1 pt-2" style="z-index: 1;">
          <img *ngIf="!modelService.systemService.showMenu" src="/images/ikon_Arrow_right.png" class="menuToggleImage" />
          <img *ngIf="modelService.systemService.showMenu" src="/images/ikon_Arrow_left.png" class="menuToggleImage" />
        </div>
        <div *ngIf="helpSwitch" (click)="toggleHelp()" class="ml-auto px-1 pt-2" style="z-index: 1;">
          <img *ngIf="!modelService.systemService.showHelp" src="/images/ikon_Arrow_left.png" class="menuToggleImage" />
          <img *ngIf="modelService.systemService.showHelp" src="/images/ikon_Arrow_right.png" class="menuToggleImage" />
        </div>
    </div>
    <div class="detail h-100 d-flex flex-column" style="margin-top: -20px;">
        <ng-content select="[slot=content]"></ng-content>            
    </div>
</div> 
