<!--18 compare catalog-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Quellcatalog' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ sourceCompareCatalog?.length > 0 ? 'placeLabelSmall' : '' }}">SOURCE CATALOG</label>
    <dx-select-box [(value)]="sourceCompareCatalog"
                   [dataSource]="catalogs"
                   displayExpr="catalogName"
                   [searchEnabled]="true"
                   valueExpr="id"
                   placeholder=""></dx-select-box>
  </div>
</div>
<div class="dx-field">
  <div class="dx-field-label">{{ 'Schlüssel' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ "SUPPLIER_PID" | translate }}</div>
      <dx-switch [(value)]="useEanAsKey"
                 switchedOffText="SUPPLIER_PID"
                 switchedOnText="INTERNATIONAL_PID"></dx-switch>
      <div class="dx-field-label switch-right">{{ "INTERNATIONAL_PID" | translate }}</div>
    </div>
  </div>
</div>
