<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Shop URL' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt10">
      <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">SHOP_URL</label>
      <dx-text-box [(value)]="model.baseUri" [readOnly]="readOnlyUser('showare5Url')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Shopware User' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">USER</label>
      <dx-text-box [(value)]="model.user" [readOnly]="readOnlyUser('showare5User')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'API Key' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.apiKey?.length>0 ? 'placeLabelSmall' : '' }}">API_KEY</label>
      <dx-text-box [(value)]="model.apiKey" [readOnly]="readOnlyUser('showare5apiKey')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Media Fingerprint Attribute' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ 'placeLabelSmall' }}">MEDIAFINGERPRINT</label>
      <dx-text-box value="fingerprint" [readOnly]="true" [showClearButton]="true"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Preise nur bei Neuanlage übertragen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.onlyCreatePrices" (onValueChanged)="valueChanged($event)" hint="{{ 'Queue-Indexing Warning' | translate }}"></dx-switch>
    </div>
  </div>


  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Produkt Kategorie' | translate }}</div>
    <div class="dx-field-value" id="categories">
      <dx-drop-down-box [value]="selectedItem.id" valueExpr="id" displayExpr="text" [dataSource]="[this.selectedItem]" [readOnly]="readOnlyUser('shopware5Categorie')">
        <dx-scroll-view [height]="'300px'">
          <dx-tree-list [dataSource]="dataSource"
                        noDataText="{{ 'Kategorien konnten nicht geladen werden' | translate }}"
                        [remoteOperations]="{ filtering: true }"
                        keyExpr="id"
                        [selectedRowKeys]="[rootCatId]"
                        parentIdExpr="parent"
                        hasItemsExpr="hasChilds"
                        rootValue="1"
                        (onSelectionChanged)="treeView_itemSelectionChanged($event)">
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="text" caption="{{'Kategorie'| translate}}"></dxi-column>
            <dxi-selection mode="single"></dxi-selection>
          </dx-tree-list>
        </dx-scroll-view>
      </dx-drop-down-box>
    </div>
  </div>



  <dx-toolbar>
    <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Kategorien laden' | translate , onClick: reloadCategories }"></dxi-item>
    <dxi-item *ngIf="model.rootCatId != -1" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Katalog senden' | translate , onClick: sendCatalog}"></dxi-item>
  </dx-toolbar>

</div>
