import { Component, Input, OnInit } from '@angular/core';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';
import { ModelService } from '../../../../../Services/model.service';

@Component({
  selector: 'csvSettings',
  templateUrl: './csvSettings.component.html'

})
export class CsvSettingsComponent implements OnInit {
  @Input() exportParameter: ExportParameter;

 

  constructor(public modelService: ModelService) {

  }

  ngOnInit(): void {

  }


 

}
