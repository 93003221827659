import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from "../../Services/userManagment.service";
import { CustomerWiki } from "../../Model/System/CustomerWiki";

@Component({
  selector: "help",
  templateUrl: "help.component.html"
})
export class HelpComponent implements OnInit{

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagment : UserManagementService) {
    this.addHelp = this.addHelp.bind(this);
    this.helpUpdate = this.helpUpdate.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  ngOnInit(): void {

    this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => {
      this.modelService.systemService.help = result.entries
    });
  }

  addHelp() {
    var pageNumber = this.modelService.systemService.help.length + 1;
    this.translate.get("Neue Seite").subscribe((text: string) => {
      this.modelService.systemService.help.push({ "title": text + " " + pageNumber, "content": "" });
    });


   
  }



  helpUpdate() {
    // if (!this.modelService.systemService.editHelp)
    {
      this.modelService.systemService.saveHelp(this.modelService.loginService.currentUser.customerId);
    }
  }

  onReorder(e) {
    let data = this.modelService.systemService.help[e.fromIndex];

    this.modelService.systemService.help.splice(e.fromIndex, 1);
    this.modelService.systemService.help.splice(e.toIndex, 0, data);
    this.helpUpdate();
  }


  deleteItem(help) {
    var selectedItem = this.modelService.systemService.selectedItems[0];
    this.modelService.systemService.selectedItems = [];
    this.modelService.systemService.help = this.modelService.systemService.help.filter(element => element.title != selectedItem.title);
    this.helpUpdate();

  }

  canView(name: string): boolean {
    return this.userManagment.canView(name);
  }
  readOnly(name: string): boolean {
    return !this.userManagment.canEdit(name);
  }
}
