export class Address {

  constructor(
    public name1?: string,
    public name2?: string,
    public name3?: string,
    public contact?: string,
    public street?: string,
    public zip?: string,
    public city?: string,
    public state?: string,
    public country?: string,
    public phone?: string,
    public fax?: string,
    public email?: string,
    public url?: string,
    public addressRemarks?: string,
    public addressType?: string
  ) {

  }
}
