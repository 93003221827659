export class StartAction {

  constructor(
    public catalogId?: string,
    public actionType?: string,
    public actionId?: string,
    public actionParameter?: string,
    public customerId?: string
  ) {
  }
}
