import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { TranslateService } from '@ngx-translate/core';
import { OxomiConnection } from '../Model/Dto/Oxomi/OxomiConnection';


@Injectable()
export class OxomiConnectionService {

  constructor(private http: HttpClient, public translate: TranslateService) {}

  getOxomiConnection(customerId: string): Promise<OxomiConnection> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return lastValueFrom(this.http.get<OxomiConnection>("api/OxomiConnection/GetConnection", options)) as any;
  }

  update(oxomiConnection: OxomiConnection): Promise<OxomiConnection> {
    return lastValueFrom(this.http.post("api/OxomiConnection/Update", oxomiConnection)) as any;
  }

  testConnection(id: number, customerId: string): Promise<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("connectionId", id.toString()).append("customerId", customerId)
    }
    return lastValueFrom(this.http.get("api/OxomiConnection/TestConnection", options)) as any;
  }
}
