import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';
import { ExportConfigurationService } from '../../../../../Services/exportConfiguration.service';
import { LoginService } from '../../../../../Services/login.service';
import { lastValueFrom } from 'rxjs';
import { CatalogService } from '../../../../../Services/CatalogManagement/catalog.service';
import { SystemService } from '../../../../../Services/system.service';
import { TranslationService } from '../../../../../Services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { TeachingSample } from '../../../../../Model/OpenAi/teachingsample';

@Component({
  selector: 'np-export-parameter-selector',
  templateUrl: './export-parameter-selector.component.html',
  styleUrls: ['./export-parameter-selector.component.css']
})
export class ExportParameterSelectorComponent implements OnInit {
  @Input() onlyUpdateFilter = false;

  _selectedExportParameterId = '';
  @Input() selectedExportParameterId;
  @Output() selectedExportParameterIdChange = new EventEmitter<string>();

  public exportParameters: ExportParameter[] = [];
  selectedExportParameter: ExportParameter;
  newFilter: ExportParameter;
  editorVisible = false;
  addUpdateFilterVisible: boolean = false;

  get selectedKey() {
    return this.selectedExportParameterId;
  }

  set selectedKey(x) {
    this.selectedExportParameterId = x;
    this.selectedExportParameterIdChange.emit(this.selectedExportParameterId);
  }

  constructor(
    public exportParameterService: ExportConfigurationService,
    public loginService: LoginService,
    public catalogService: CatalogService,
    public systemService: SystemService,
    public translate: TranslateService
  ) {
    this.closeEditorConfirmDialog = this.closeEditorConfirmDialog.bind(this);
  }

  async ngOnInit() {
    await this.LoadFilters();
  }

  openEditor() {
    if (!this.selectedExportParameterId) {
      this.systemService.notifyWarning('Filter konnte nicht geöffnet werden');
      return;
    }
    this.selectedExportParameter = Object.assign(
      new ExportParameter(),
      this.exportParameters.find((x) => x.id == this.selectedExportParameterId)
    );

    this.editorVisible = true;
  }

  displayExpr(item: ExportParameter) {
    return 'ID: ' + item.id + ', Name: ' + item.name;
  }

  get catalogId(): string {
    //TODO: catalog chooser mit default wert, wenn selectedCatalog leer ist
    return this.catalogService.selectedCatalogId;
  }

  openCreate() {
    this.newFilter = new ExportParameter();
    this.newFilter.isUpdateFilter = this.onlyUpdateFilter;
    this.addUpdateFilterVisible = true;
  }

  async addUpdateFilter() {
    this.newFilter = await lastValueFrom(
      this.exportParameterService.update(this.newFilter, this.loginService.currentCustomer.id)
    );
    await this.LoadFilters();
    this.selectedExportParameterId = this.newFilter.id;
    this.addUpdateFilterVisible = false;
  }

  async deleteConfig() {
    await lastValueFrom(
      this.exportParameterService.delete(this.selectedExportParameterId, this.loginService.currentCustomer.id)
    );
    await this.LoadFilters();
    this.selectedExportParameterId = undefined;
  }

  private async LoadFilters() {
    let exportParameters = await lastValueFrom(
      this.exportParameterService.getAll(this.loginService.currentCustomer.id)
    );
    this.exportParameters = exportParameters.filter((x) => x.isUpdateFilter == this.onlyUpdateFilter);
  }

  async saveParameter() {
    await lastValueFrom(
      this.exportParameterService.update(this.selectedExportParameter, this.loginService.currentCustomer.id)
    );
    await this.LoadFilters();
    this.editorVisible = false;
  }

  closeEditorConfirmDialog() {
    let saveChangesDialog = custom({
      title: this.translate.instant('Änderungen Speichern'),
      messageHtml: this.translate.instant('Wollen Sie die Änderungen speichern ?'),
      buttons: [
        {
          text: this.translate.instant('Speichern'),
          onClick: (e) => {
            return true;
          }
        },
        {
          text: this.translate.instant('Verwerfen'),
          onClick: (e) => {
            return false;
          }
        }
      ]
    });

    saveChangesDialog.show().then(async (dialogResult) => {
      if (dialogResult) {
        await this.saveParameter();
      } else {
        await this.LoadFilters();
        this.editorVisible = false;
      }
    });
    return false;
  }
}
