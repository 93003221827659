
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { ShopWare6Connection } from '../Model/Catalog/ShopWareConnection';



@Injectable()
export class ShopService {



  constructor(private http: HttpClient) {

  }

  //Verbindung testen
  public testShopConnection(shopUrl: string, shopAuth: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("shopAuth", shopAuth)
    }
    return (this.http.get("api/ShopConnector/testShopConnection", options)) as any;
  }
  testShopWare6Connection(con: ShopWare6Connection) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("baseUri", con.baseUri)
                              .append("clientId", con.clientId)
                              .append("clientSecret", con.clientSecret)
    }
    return (this.http.get("api/ShopConnector/testShopWare6Connection", options)) as any;
}


  //Verbindung abfragen
  public getConnection2Shop(shopUrl: string, shopAuth: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("shopAuth", shopAuth)
    }
    return (this.http.get("api/ShopConnector/getConnection2Shop", options)) as any;
  }
  // Start export
  startExport(startShopWareExport) {
    return this.http.post("api/ShopConnector/StartShopWareExport", startShopWareExport).pipe(map(response => {
      return true;
    }));


  }

  //Noch in POST umbauen
  public establishedShopConnection(shopUrl: string, shopAuth: string, customerId: string, catalogId: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("shopAuth", shopAuth).append("customerId", customerId).append("catalogId", catalogId)
    }
   
    return (this.http.get<TestResult>("api/ShopConnector/establishedShopConnection", options)) as any;
  }

  //Shop löschen
  public deleteShop(shopUrl: string, shopAuth: string, type: string): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("shopAuth", shopAuth).append("type", type)
    }
    return (this.http.get<TestResult>("api/ShopConnector/deleteShop", options)) as any;
  }
}
