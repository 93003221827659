
export class SearchFields {
  constructor(
    public searchField?: string,
    public id?: string,
    public productSearchOptionsId?: number,
  ) {
  }
  deserialize(input: any) {
    Object.assign(this, input);

    //fuer jeden komplexen Datentypen notwendig (!)
    //this.userToCustomerList = this.userToCustomerList.map((utc: UserToCustomer) => new UserToCustomer().deserialize(utc));

    return this;
  }
}
