import { Mime } from "./Mime";
import { UdxField } from "./UdxField";
import { ProductLogistic } from "./ProductLogistic";
import { OrderDetail } from "./OrderDetail";
import { Reference } from "./Reference";
import { FeatureSystem } from "./FeatureModels/FeatureSystem";
import { Feature } from "./FeatureModels/Feature";
import { PriceList } from "./PriceList";
import { Agreement } from "./Agreement";
import { ChangeInfo } from "./ChangeInfo";
import { WarrantyClass } from './WarrantyClass';
import { HazmatClass } from './HazmatClass';
import { CatalogMapping } from './CatalogMapping';
import { LegalCharacteristic } from './LegalCharacteristic';
import { ReportLastRun } from './Reporting/ReportLastRun';
import { LinkedField } from './LinkedField';
import { WawiIntegration } from "./WawiIntegration";
import { ProductSupplier } from "./ProductSupplier";
import { ElectronicSalesDetail } from "./ElectronicSalesDetail";
import { ViewService } from "../../Services/view.service";

export class Product {
  constructor(
    public id?: string,
    public parentProductId?: string,
    public productCount?: number,
    public customerId?: string,
    public articleOrder?: string,
    public buyerAid?: string,
    public buyerAidType?: string,
    public descriptionLong?: string,
    public descriptionShort?: string,
    public erpGroupBuyer?: string,
    public erpGroupSupplier?: string,
    public internationalPid?: string,
    public internationalPidType?: string,
    public isChild?: boolean,
    public isMaster?: boolean,
    public lastChange?: string,
    public manufacturerAid?: string,
    public manufacturerName?: string,
    public edeNumber1?: string,
    public edeNumber2?: string,
    public edeNumber3?: string,
    public edeNumber4?: string,
    public edeNumber5?: string,
    public manufacturerTypeDescr?: string,
    public remarks?: string,
    public supplierPid?: string,
    public supplierAltAid?: string,
    public sourceProductId?: string,
    public productLogistic: ProductLogistic = new ProductLogistic(),
    public orderDetail: OrderDetail = new OrderDetail(),
    public mimes: Mime[] = [],
    public catalogMappings: CatalogMapping[] = [],
    public mimeTemplate: Mime[] = [],
    public udxs: UdxField[] = [],
    public products: Product[] = [],
    public featureSystems: FeatureSystem[] = [],
    public keywords: string[] = [],
    public states: string[] = [],
    public state: string = "",
    public priceLists: PriceList[] = [],
    public references: Reference[] = [],
    public productOrder?: number,
    public changeInfo?: ChangeInfo,
    public agreement: Agreement = new Agreement(),
    public metaTitel?: string,
    public metaDescription?: string,
    public legalCharacteristic: LegalCharacteristic = new LegalCharacteristic(),
    public isDiscontinued?: boolean,
    public discontinuedDate?: Date,
    public wawi?: WawiIntegration,
    public reportRuns: ReportLastRun[] = [],
    public linkedFields: LinkedField[] = [],
    public suppliers: ProductSupplier[] = [],
    public mainSupplierId?: string,
    public electronicSales?: ElectronicSalesDetail
  ) { }
  ////Editier Fenster
  //public validateNumbers: number[] = [0, 0, 0, 0, 0]; //Setzt die Kreise auf Null
  //public productInfosValid: boolean = true;     //Ausrufezeichen am MainTab auf aus
  //public productKeywordsValid: boolean = true;     //Ausrufezeichen am MainTab auf aus
  //public productMediaValid: boolean = true;     //Ausrufezeichen am MediaTab auf aus
  //public productFeaturesValid: boolean = true;  //Ausrufezeichen am FeatureTab auf aus
  //public productPriceValid: boolean = true;  //Ausrufezeichen am FeatureTab auf aus
  //public productOrderDetailsValid: boolean = true;  //Ausrufezeichen am FeatureTab auf aus
  //public productLogiticValid: boolean = true;  //Ausrufezeichen am FeatureTab auf aus
  //public productReferenceValid: boolean = true;  //Ausrufezeichen am FeatureTab auf aus
  //public featuresSystemsValid = false;
  //public marketingClaimValid = false;
  //public imagesValid = false;
  //public logoValid = false;
  //public dataSheetValid = false;
  public validGroup = 0;
  public groupString: string = "";
  public master: string = "";
  public descWithoutHTML: string = "";
  public featurelist: string = "";

  //ShopView
  public productImagesNormal: Mime[] = [];      //Mimes mit dem Purpose Normal oder Detail für Shopview und Validierung
  public price: productPrice = new productPrice();
  public productImagesIcon: Mime[] = [];        //Mimes mit dem Purpose Icon für Shopview und Validierung
  public productImagesLogos: Mime[] = [];
  public productDataSheets: Mime[] = [];

  public features: svFeatureSystem[] = [];      //Featuressysteme für Shopview und Validierung
  public marketingClaim: string = "";           //MarketingClaim für Shopview und Validierung
  public productTileImages: { "src": string, "ratio": number }[] = [];



  public prepareView() {
    this.searchPrice();
    this.searchMimes();
    this.searchFeatures();
    if (this.productDataSheets.length > 0) {
      var dataSheets = new svFeatureSystem();
      dataSheets.name = "Datenblätter";
      dataSheets.type = "data_sheet";
      this.features.push(dataSheets);

    }
  }

  private searchMimes(): void {
    this.productImagesNormal = [];
    this.productImagesIcon = [];
    this.productImagesLogos = [];
    this.productDataSheets = [];

    if (this.mimes) {
      for (let mime of this.mimes) {
        switch (mime.mimePurpose) {
          case "normal":
          case "detail":
          case "milieu":
            this.productImagesNormal.push(mime);
            break;
          case "icon":
            if (this.productImagesIcon.length < 5) {
              this.productImagesIcon.push(mime);
            }
            break;
          case "logo":
            this.productImagesLogos.push(mime);
            break;
          case "data_sheet":
          case "safty_data_sheet":
            this.productDataSheets.push(mime);
            break;
        }
      }

      this.productTileImages = [];
      //Tile Images suchen
      for (let mime of this.mimes) {
        switch (mime.mimePurpose) {
          case "normal":
            if (parseInt(mime.mimeAlt) > 0)
              this.productTileImages.push({ "src": mime.normUri, "ratio": parseInt(mime.mimeAlt) });
            break;
        }
      }
      for (let mime of this.mimes) {
        switch (mime.mimePurpose) {
          case "milieu":
          case "detail":
            if (parseInt(mime.mimeAlt) > 0)
              this.productTileImages.push({ "src": mime.normUri, "ratio": parseInt(mime.mimeAlt) });
            break;

        }
      }
    }
  }

  private searchPrice(): void {
    //TODO OrderDetails pürfen



    // https://nextpim.myjetbrains.com/youtrack/issue/nPIM-610
    // Notiz: Ersten NRP, wenn vorhanden, sonst ersten NET_LIST, sonst irgend einen (ersten gefundenen).

    // ==> Mindestens bis zum ersten NRP alle durchgehen.
    // Das kann bedeuten wirklich alle durchgehen zu müssen, falls es gar keinen NRP gibt.

    var firstPrice = null;
    var firstNrpPrice = null;
    var firstNetlistPrice = null;

    if (this.priceLists) {
      loop:
      for (let priceList of this.priceLists) {
        for (let price of priceList.productPriceDetails) {

          if (firstPrice == null) {
            firstPrice = price;
          }

          if (price.priceType === "nrp" && firstNrpPrice == null) {
            firstNrpPrice = price;
            break loop;
          }

          if (price.priceType === "net_list" && firstNetlistPrice == null) {
            firstNetlistPrice = price;
            //break loop;   // nicht aussteigen, es könnte später noch ein NRP kommen.
          }

        }
      }

    }

    var price = null;
    if (firstNrpPrice != null) {
      price = firstNrpPrice;
    } else if (firstNetlistPrice != null) {
      price = firstNetlistPrice;
    } else {
      price = firstPrice;
    }



    if (price == null) {
      return;
    }



    var tax = price.taxRate;
    tax = tax * 100;
    var amount = price.priceAmountNumber;

    if (amount > 0 && price.priceCurrency != null && price.priceCurrency.length == 3 && tax < 100) {
      var amountString = amount.toLocaleString('de-DE', { style: 'currency', currency: price.priceCurrency });
      var priceObj = new productPrice();
      priceObj.prouctTax = tax.toString();
      priceObj.prouctUnit = "1 Stück";
      if (this.orderDetail != null) {
        switch (this.orderDetail.contentUnit) {
          case "C62":
            break;
          case "ANN":
            priceObj.prouctUnit = "Jahreslizenz";
            break;
          case "MON":
            priceObj.prouctUnit = "Monatslizenz";
            break;
        }
      }
      priceObj.prouctPrice = amountString;
      this.price = priceObj;
    }
  }

  public points = [];
  public technicalFeatures: Feature[] = [];
  public topFeatures: Feature[] = [];
  public benefitsFeatures: Feature[] = [];
  public usageFeatures: Feature[] = [];
  public useForFeatures: Feature[] = [];
  public variantsFeatures: Feature[] = [];

  private searchFeatures(): void {

    this.features = [];

    if (this.featureSystems) {

      for (let featureSystem of this.featureSystems) {
        switch (featureSystem.referenceFeatureSystemName) {
          case "udf_NMTECHNICALDETAILS-1.0":
            this.technicalFeatures = featureSystem.features;
          case "udf_NMOTHERS-1.0":
          case "udf_NMTRADINGDETAILS-1.0":
            var featSysObj = new svFeatureSystem();
            featSysObj.name = Product.translateFetSysName(featureSystem.referenceFeatureSystemName);
            for (let feature of featureSystem.features) {
              var featObj = new svFeatures();
              featObj.name = feature.name;
              featObj.value = feature.value;
              featObj.funit = feature.funit;
              featSysObj.features.push(featObj);
            }
            if (featSysObj.features.length > 0) {
              this.features.push(featSysObj);
            }
            break;
          case "udf_NMTOPFEATURES-1.0":
            this.topFeatures = featureSystem.features;
          case "udf_NMBENEFITS-1.0":
            if (featureSystem.referenceFeatureSystemName == "udf_NMBENEFITS-1.0") {
              this.benefitsFeatures = featureSystem.features;
            }
          case "udf_NMUSAGE-1.0":
            if (featureSystem.referenceFeatureSystemName == "udf_NMUSAGE-1.0") {
              this.usageFeatures = featureSystem.features;
            }
          case "udf_NMSCOPEOFSUPPLY-1.0":
            var featSysObj = new svFeatureSystem();
            featSysObj.name = Product.translateFetSysName(featureSystem.referenceFeatureSystemName);
            featSysObj.hasName = false;
            for (let feature of featureSystem.features) {
              var featObj = new svFeatures();
              featObj.value = feature.value;
              featSysObj.features.push(featObj);
            }
            if (featSysObj.features.length > 0) {
              this.features.push(featSysObj);
            }
            break;
          case "udf_NMPOINTS-1.0":
            this.points = [];
            for (let feature of featureSystem.features) {
              if (parseInt(feature.value) > 0 &&
                parseInt(feature.funit) > 0 &&
                parseInt(feature.value) <= parseInt(feature.funit)) {
                this.points.push({ "name": feature.name, "points": feature.value, "max": feature.funit });
              }
            }
            break;
          case "udf_NMMARKETINGCLAIM-1.0":
            for (let feature of featureSystem.features) {
              this.marketingClaim = feature.value;
              break;
            }
            break;
          case "udf_NMUSEFOR-1.0":
            this.useForFeatures = featureSystem.features;
            break;
          case "udf_NMVARIANTS-1.0":
            this.variantsFeatures = featureSystem.features;
            break;
        }
      }
    }
  }

  public static translateFetSysName(name: string): string {
    switch (name) {
      case "udf_NMMARKETINGCLAIM-1.0":
        return "MarketingClaim";
      case "udf_NMTRADINGDETAILS-1.0":
        return "Lieferinformationen";
      case "udf_NMOTHERS-1.0":
        return "andere Merkmale";
      case "udf_NMTECHNICALDETAILS-1.0":
        return "Technische Daten";
      case "udf_NMTOPFEATURES-1.0":
        return "Topmerkmale";
      case "udf_NMSCOPEOFSUPPLY-1.0":
        return "Lieferumfang";
      case "udf_NMBENEFITS-1.0":
        return "Vorteile";
      case "udf_NMUSAGE-1.0":
        return "Anwendung";
      case "udf_NMUSEFOR-1.0":
        return "Material";
      case "udf_NMVARIANTS-1.0":
        return "Varianten Merkmale";
    }
    return "";
  }

  //Validation

  public hintsActiv = false;                    //Wenn true, dann werden Hintsactiviert

  public activateHints() {
    this.hintsActiv = true;
  }

  addWarranty(warranty) {
    var warrantyFS = new svFeatureSystem();
    warrantyFS.name = warranty;
    warrantyFS.type = "warranty";
    this.features.push(warrantyFS);
  }

  addHazmat() {
    var hazmatFS = new svFeatureSystem();
    hazmatFS.name = "Gefahreninformation";
    hazmatFS.type = "hazmat";
    this.features.push(hazmatFS);
  }

  warrantyClass: WarrantyClass;
  hazmatClass: HazmatClass;


  public applyGlobalTemplate(other: Product) {
    this.featureSystems = other.featureSystems;
    this.mimes = other.mimes;
  }

  public hasMasterOrChild(): boolean {
    return this.productCount != null && this.productCount > 0 // Master
      || this.isChild != null && this.isChild;                // Child
  }

  public isFieldLinked(field: string, system?: string, element?: string): boolean {
    if (this.linkedFields == null || this.linkedFields.length == 0) {
      return false;
    }

    var found = this.linkedFields.find(l => l.field == field && l.systemKey == system && l.elementKey == element);
    if (found != null) {
      return true;
    }
    return false;
  }

  public toggleLinkedField(field: string, system?: string, element?: string): boolean {

    let _system = system;
    if (_system == null) {
      _system = "-1";
    }
    var _element = element;
    if (_element == null) {
      _element = "-1";
    }
    // Prüfen, ob das Feld aktuell gelinked ist:
    var isLinked = this.isFieldLinked(field, _system, _element);

    // Es ist nicht gelinked, nun soll es gelinked werden:
    if (isLinked == false) {
      var newItem = new LinkedField(field, _system, _element);
      this.linkedFields.push(newItem);
      if (this.isFeatureSystemField(field)) {
        var index = this.linkedFields.findIndex(l => l.field == "REFERENCE_FEATURE_SYSTEM_NAME" && l.systemKey == _system);
        if (index < 0) {
          var newItem = new LinkedField("REFERENCE_FEATURE_SYSTEM_NAME", _system, _element);
          this.linkedFields.push(newItem);
        }
      }

      if (this.isFeatureField(field)) {
        var index = this.linkedFields.findIndex(l => l.field == "REFERENCE_FEATURE_SYSTEM_NAME" && l.systemKey == _system);
        if (index < 0) {
          var newItem = new LinkedField("REFERENCE_FEATURE_SYSTEM_NAME", _system, "-1");
          this.linkedFields.push(newItem);
        }

        var index = this.linkedFields.findIndex(l => l.field == "FNAME" && l.systemKey == _system && l.elementKey == _element);
        if (index < 0) {
          var newItem = new LinkedField("FNAME", _system, _element);
          this.linkedFields.push(newItem);
        }
        var index = this.linkedFields.findIndex(l => l.field == "FUNIT" && l.systemKey == _system && l.elementKey == _element);
        if (index < 0) {
          var newItem = new LinkedField("FUNIT", _system, _element);
          this.linkedFields.push(newItem);
        }
      }
      return true;
    }

    // Es ist gelinked, nun soll es nicht mehr gelinked sein:
    var index = this.linkedFields.findIndex(l => l.field == field && l.systemKey == system && l.elementKey == element);
    if (index < 0) {
      return false; // Das dürfte aufgrund der vorigen Prüfung eig. nie vorkommen...
    }
    this.linkedFields.splice(index, 1);

    if (field == "FVALUE" || field == "FEATURE_PRINT_ORDER") {
      let featureSystem = this.featureSystems.find(x => x.validFeatureSystemId == system);
      if (ViewService.isSingleValue(featureSystem.referenceFeatureSystemName)) {
        let foundLinkedField = this.linkedFields.findIndex(x => (x.field == "FVALUE" || x.field == "FEATURE_PRINT_ORDER") && x.systemKey == system && x.elementKey == element);
        if (foundLinkedField < 0) {
          this.linkedFields = this.linkedFields.filter(l => l.systemKey != _system || l.elementKey != _element);
        }
      }
    }

    if (field == "REFERENCE_FEATURE_SYSTEM_NAME") {
      this.linkedFields = this.linkedFields.filter(l => l.systemKey != _system);
    }

    if (field == "FNAME" || field == "FUNIT") {
      this.linkedFields = this.linkedFields.filter(l => l.systemKey != _system || l.elementKey != _element);
    }

    return true;
  }

  isFeatureSystemField(field: string): boolean {
    if (field == "REFERENCE_FEATURE_SYSTEM")
      return true;
    if (field == "REFERENCE_FEATURE_SYSTEM_NAME")
      return true;
    if (field == "REFERENCE_FEATURE_GROUP_ID")
      return true;
    if (field == "REFERENCE_FEATURE_GROUP_ID2")
      return true;
    if (field == "REFERENCE_FEATURE_GROUP_NAME")
      return true;
    if (field == "FS_ORDER")
      return true;
    return false;
  }

  isFeatureField(field: string): boolean {
    if (field == "FNAME")
      return true;
    if (field == "FVALUE")
      return true;
    if (field == "FUNIT")
      return true;
    if (field == "FORDER")
      return true;
    if (field == "FDESCR")
      return true;
    if (field == "FVALUE_DETAILS")
      return true;
    if (field == "FEATURE_PRINT_ORDER")
      return true;
    if (field == "ISVARIANT")
      return true;
    return false; 
  }

  public getClassForLinkedField(linked: boolean): string {
    if (linked) {
      // linked ==> link anzeigen
      return "glyphicons-halflings-167-link-nextPimBlue"
    } else {
      // nicht linked ==> link-remove anzeigen
      return "glyphicons-halflings-168-link-remove-nextPimBlue"
    }
  }

}

class productPrice {
  public prouctPrice: string = "";
  public prouctCurrency: string = "";
  public prouctTax: string = "";
  public prouctUnit: string = "";
}

class svFeatureSystem {
  public name: string = "";
  public hasName: boolean = true;
  public type: string = "normal";
  public features: svFeatures[] = [];
}

class svFeatures {
  public name: string = "";
  public value: string = "";
  public funit: string = "";
}
