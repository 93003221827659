export class Functions {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static stripHTML(value: string) {
    if (!value)
      return "";
    return value.replace(/<[^!](?:[^>"']|"[^"]*"|'[^"]*')*>|<!--.*?-->/g, ' ')
      .replace(/ +/g, ' ')
      .replace(/^s+/g, '')
      .replace(/s+$/g, '');
  }
    
}




