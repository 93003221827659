import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Customer } from "../Model/User/Customer";
import { AppUser } from "../Model/User/AppUser";
import { Login } from "../Model/User/Login";
import { CustomerAddonInfo } from "../Model/User/CustomerAddonInfo";

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient,

  ) {
  }

  public getCustomer(customerId: string): Observable<Customer> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get("api/Customer/GetCustomer", options)) as any;
  }

  public getAllCustomers(): Promise<Customer[]> {
    return lastValueFrom(this.http.get("api/Customer/GetAllCustomer") as Observable<Customer[]>);
  }

  public getAllCustomerByUser(userId: string): Observable<Customer[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("userId", userId)
    }
    return (this.http.get("api/Customer/GetAllCustomerByUser", options)) as any;
  }

  public getUsersOfCustomer(customerId: string): Observable<AppUser[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get("api/Customer/GetUsersOfCustomer", options)) as any;
  }

  public getLogins(): Observable<Login[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }
    return (this.http.get("api/Customer/GetLogins", options)) as any;
  }



  public getCustomerAddonInfos(): Observable<CustomerAddonInfo[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    };
    return (this.http.get("api/CustomerAddon/GetCustomerAddonInfoList", options)) as any;
  }

  public create(customer: Customer): Observable<Customer> {
    var result = this.http.post<Customer>("api/Customer/CreateCustomer", customer);
    return (result);
  }

  public update(customer: Customer): Observable<Customer> {
    var result = this.http.post<Customer>("api/Customer/UpdateCustomer", customer);
    return (result);
  }

  public addUserToCustomer(customerId: string, userId: string, claim: string): Observable<Customer> {

    var headers = new HttpHeaders().append("userId", userId).append("customerId", customerId).append("claim", claim);
    var result = this.http.post<Customer>("api/Customer/AddUserToCustomer", { headers: headers, observe: 'response' });
    return (result);
  }

  public deleteUserFromCustomer(userId: string, customerId: string): Observable<HttpResponse<AppUser>> {
    var headers = new HttpHeaders().append("userId", userId).append("customerId", customerId);
    var result = this.http.delete<AppUser>("api/Customer/DeleteUserFromCustomer", { headers: headers, observe: 'response' });
    return (result);
  }



  public deleteCustomer(customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.post<object>("api/Customer/DeleteCustomer", null, options)) as any;
  }
}
