
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginService } from './login.service';
import { MappingProfile } from "../Model/featureMapping/MappingProfile";
import { TranslateService } from '@ngx-translate/core';
import { MappingInput } from "../Model/featureMapping/MappingInput";
import { MappingResult } from "../Model/featureMapping/MappingResult";


@Injectable()
export class FeatureMappingService {


  selectedMappingId: string;

  constructor(private http: HttpClient, public loginService: LoginService, public translate: TranslateService) {

  }
  
  getMappingProfiles(): Observable<MappingProfile[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.get<MappingProfile>("api/FeatureMapping/GetMappingProfiles", options)) as any;
  }

  getMappingProfile(mappingProfileId: string, customerId: string): Observable<MappingProfile> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("mappingProfileId", mappingProfileId)
    }

    return (this.http.get<MappingProfile>("api/FeatureMapping/GetMappingProfile", options)) as any;
  }

  getMappingInput(mappingProfileId: string, customerId: string): Observable<MappingInput[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("mappingProfileId", mappingProfileId)
    }

    return (this.http.get<MappingProfile>("api/FeatureMapping/getMappingInput", options)) as any;
  }

  getMappingInputsByResultId(mappingResultid: string, profileId: string, customerId: string):
    Observable<MappingInput[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("mappingProfileId", profileId).append("mappingResultid", mappingResultid)
    }

    return (this.http.get<MappingProfile>("api/FeatureMapping/GetMappingInputByResult", options)) as any;


  }
  


  deleteMappingProfile(id: string, customerId:string) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", id)
          .append("customerId", customerId)
      }

      this.http.delete("api/FeatureMapping/DeleteMappingProfile", options)
        .subscribe(
          result => {
             return true;
          },
          err => {
            console.error(err);
            return false;
          }
        );
    }
  }

  updateMappingProfile(mappingProfile: MappingProfile,customerId: string): Observable<MappingProfile> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.post("api/FeatureMapping/UpdateMappingProfile", mappingProfile,options)) as any;
  }

  addMappingProfile(mappingProfile: MappingProfile, customerId: string): Observable<MappingProfile> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.post("api/FeatureMapping/AddMappingProfile", mappingProfile,options)) as any;
  }


  addOrUpdateMappingResult(mappingResult: MappingResult, customerId: string): Observable<MappingResult> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.post("api/FeatureMapping/AddOrUpdateMappingResult", mappingResult, options)) as any;
  }

  createRule(mappingResult: MappingResult, customerId: string): Observable<MappingResult> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.post("api/FeatureMapping/createRule", mappingResult, options)) as any;
  }

  addOrUpdateMappingInput(mappingInput: MappingInput, customerId: string): Observable<MappingInput> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.post("api/FeatureMapping/AddOrUpdateMappingInput", mappingInput, options)) as any;
  }

  getMappingResults(mappingProfileId: string, customerId: string): Observable<MappingResult[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("mappingProfileId", mappingProfileId)
    }

    return (this.http.get<MappingResult>("api/FeatureMapping/GetMappingResults", options)) as any;
  }
}
