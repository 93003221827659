export class CatalogMapping {

  constructor(
    public catalogId?: string,   
    public categoryId?: string,
    public order?: number,
    public catalogName?: string,
    public isVirtualCatalog?: boolean,
    public categoryName?: string,
    public categoryIconUrl?: string
   ){ }

}
