<div style="max-width: 100%;">
  test
  <dx-data-grid class="h-100"
                [dataSource]="dataSource"
                [remoteOperations]="false"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                [showBorders]="true"
                [showColumnHeaders]="true"
                mode="multiple">
    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing mode="popup" [allowUpdating]="isManager()" [allowDeleting]="isManager()" [allowAdding]="isManager()">
      <dxo-popup title="Ftp Reimport Job"
                 [showTitle]="true">
      </dxo-popup>
    </dxo-editing>
    <dxi-column dataField="ftpServerId" caption="{{'Ftp Server'|translate}}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="ftpServers" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="time" dataType="datetime" caption="{{'Uhrzeit'|translate}}"
                [calculateDisplayValue]="getTime"
                format="HH:mm"
                [editorOptions]="{ type:'time', displayFormat:'HH:mm',pickerType:'rollers',intervall:'5'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="lastRun" dataType="datetime" caption="{{'Letzte Ausfuehrung'|translate}}"
                [calculateDisplayValue]="getLastRun"
                format="dd.MM HH:mm"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column cellTemplate="diffCellTemplate" editCellTemplate="emptyTemplate">
      <div *dxTemplate="let data of 'diffCellTemplate'">
        <dx-button style="text-align: right; float: right;" text="{{'Manuel ausführen'|translate}}" (onClick)="run(data)" icon="redo">
        </dx-button>
      </div>
      <div *dxTemplate="let data of 'emptyTemplate'">
      </div>
    </dxi-column>

  </dx-data-grid>
</div>
