<fullscreen-page>
  <navigation-column>
    <div slot="content" class="h-100 p-2">
      <div class="h-100 w-100 mx-auto">
        <dx-data-grid id="gridContainer"
                      class="h-100"
                      [(dataSource)]="validFeatureSystems"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [selectedRowKeys]="[]"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      (onToolbarPreparing)="onGridToolbarPreparing($event)"
                      (onRowClick)="onRowClick($event)">

          <div *dxTemplate="let toolbaritem of 'deleteUnusedButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Nicht verwendete Daten löschen' | translate }}" (onClick)="startDeleteJob()" hint="{{ 'Löscht alle nicht verwendeten Daten' | translate }}"></dx-button>
          </div>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="false" [allowSearch]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Featuresystem suchen' | translate }}" width="250"></dxo-search-panel>
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
          <dxi-column dataField="name" caption="{{ 'Name' | translate }}"></dxi-column>
          <dxi-column dataField="id" caption="" title="{{ 'Verwendung' | translate }}" width="75px" cellTemplate="isUsedTemplate"></dxi-column>
          <div *dxTemplate="let data of 'isUsedTemplate'">
            <div style="padding=6">
              <dx-button icon="search"
                         (onClick)="onLookUpClick(data.data)">
              </dx-button>
            </div>
          </div>
        </dx-data-grid>
        <dx-popup [width]="1200"
                  [height]="600"
                  id="popUp"
                  [(title)]="selectedValidFeatureSystem.name"
                  [dragEnabled]="true"
                  [hideOnOutsideClick]="true"
                  [showCloseButton]="false"
                  [(visible)]="isUsageListVisible">>
          <div *dxTemplate="let data of 'content'">

            <dx-data-grid [dataSource]="usageDataSource"
                          [showBorders]="true"
                          [allowColumnReordering]="true"
                          [rowAlternationEnabled]="true"
                          (onContentReady)="onContentReady($event)">
              >
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-header-filter [visible]="false" [allowSearch]="true"></dxo-header-filter>
              <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Suchen' | translate }}" width="250"></dxo-search-panel>
              <dxo-group-panel [visible]="false"></dxo-group-panel>
              <dxo-load-panel [enabled]="true" [showIndicator]="loadingVisible" [show]="loadingVisible" text="{{ 'Moment bitte... das sind viele' | translate }}"></dxo-load-panel>
              <dxi-column dataField="catalogName" caption="{{ 'Katalog' | translate }}"></dxi-column>
              <dxi-column dataField="categoryName" caption="{{ 'Kategorie' | translate }}"></dxi-column>
              <dxi-column dataField="supplierPid" caption="{{ 'SupplierPid' | translate }}"></dxi-column>
              <dxi-column dataField="descriptionShort" caption="{{ 'Bezeichnung' | translate }}"></dxi-column>

            </dx-data-grid>
            <dx-load-panel #loadPanel
                           shadingColor="rgba(0,0,0,0.4)"
                           [visible]="loadingVisible"
                           [showIndicator]="true"
                           [showPane]="true"
                           [shading]="false"
                           [closeOnOutsideClick]="false">
            </dx-load-panel>

          </div>
        </dx-popup>
        
      </div>
    </div>
  </navigation-column>
  <content-column>
    <div slot="content" class="h-100 d-flex flex-column">
      <div class="dx-fieldset">
        <div class="dx-field">
          <h4>{{ selectedValidFeatureSystem.name }}</h4>
        </div>
      </div>
      <validFeature [(ValidFeatures)]="validFeatures" class="flex-fill overflow-hidden"></validFeature>
    </div>
  </content-column>
</fullscreen-page>
