import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { PreloadAllModules } from '@angular/router';
import { DxTreeViewComponent } from 'devextreme-angular';
import _ from 'lodash';
import { FeatureSystemFilter } from '../../../../../../Model/Dto/FeatureSystemFilter';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { SimpleHierachicalFilter } from '../../../../../../Model/exportParameter/SimpleHierachicalFilter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'featureContentFilter',
  templateUrl: './featureContentFilter.component.html',
  styleUrls: ['./featureContentFilter.component.css']
})
export class FeatureContentFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  isFeatureSystemFiltersLoaded = false;
  featureSystemFilters;

  constructor(public modelService: ModelService, public exportFilterService: ExportFilterService) {
    this.refreshFeatureSystemFilter = this.refreshFeatureSystemFilter.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.featureSystemContentFilter == null)
      this.exportParameter.featureSystemContentFilter = new CatalogFilter<string[]>([])
    if (this.exportParameter.featureSystemContentFilter.useFilter == null)
      this.exportParameter.featureSystemContentFilter.useFilter = false;
    if (this.exportParameter.featureSystemContentFilter.filterKey == null)
      this.exportParameter.featureSystemContentFilter.filterKey = [];

    this.refreshFeatureSystemFilter();
  }


  refreshFeatureSystemFilter() {
    if (!this.exportParameter.featureSystemContentFilter.useFilter)
      return;

    this.isFeatureSystemFiltersLoaded = false;
    this.exportFilterService
      .getFeatureSystemFilters(this.catalogId, this.modelService.loginService.currentUser.customerId).toPromise()
      .then((filter: FeatureSystemFilter[]) => {
        this.featureSystemFilters = filter;
      }).finally(
        () => {
          this.isFeatureSystemFiltersLoaded = true;
        });
  }
}
