<!--38 deleteFeatureSystems-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'FeatureSystems' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <dx-load-indicator *ngIf="featureSystemsLoading" id="medium-indicator" height="40" width="40"></dx-load-indicator>
    <dx-drop-down-box [inputAttr]="{ 'aria-label': 'Owner' }"
                      [(value)]="selectedFeatureSystems"
                      placeholder=""
                      displayExpr="name"
                      [showClearButton]="true"
                      [dataSource]="featureSystems">
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid [dataSource]="featureSystems"
                      [selection]="{ mode: 'multiple' }"
                      [hoverStateEnabled]="true"
                      [paging]="{ enabled: true, pageSize: 10 }"
                      [filterRow]="{ visible: true }"
                      [scrolling]="{ mode: 'virtual' }"
                      [height]="345"
                      [(selectedRowKeys)]="selectedFeatureSystems">
          <dxi-column dataField="name" [allowEditing]="false" caption="{{ 'Featuresystem-Name' | translate }}"></dxi-column>
        </dx-data-grid>
      </div>
    </dx-drop-down-box>
  </div>
</div>
