<fullscreen-page>
  <content-column helpSwitch="true" look="dark">
    <div slot="content" class="p-2 flex-fill w-75 mx-auto overflow-hidden">
      <dx-tab-panel [dataSource]="pages" [selectedIndex]="0" [swipeEnabled]="false" [animationEnabled]="true"
        [showNavButtons]="true" [loop]="true">
        <div *dxTemplate="let data of 'productStateTemplate'">
          <productStates></productStates>
        </div>
        <div *dxTemplate="let data of 'catalogStateTemplate'">
          <catalogStates></catalogStates>
        </div>
      </dx-tab-panel>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>