import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ClassificationSystem } from "../Model/classification/ClassificationSystem";
import { TranslateService } from "@ngx-translate/core";
import { StartAction } from "../Model/Dto/StartAction";



@Component({
  selector: "classificationSystem",
  templateUrl: "classificationSystem.component.html"
})
export class ClassificationSystemComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService) {

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Klassifikationssysteme");
  }

  ngOnInit(): void {

/*    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }*/

    this.modelService.classificationService.getSystems();
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addClassificationButtonTemplate',
    });
  }

  onInsertCs() {

    let system = new ClassificationSystem();
    system.name = "";
    this.modelService.classificationService.writeSystem(system).subscribe(
      (result: ClassificationSystem) => {
        system.id = result.id;
        this.model.push(system);
      });
  }

  



onCsChanged(e) {

  let system = new ClassificationSystem();

  system.id = e.key.id;
  system.name = e.key.name;
  this.modelService.classificationService.writeSystem(system).subscribe(
    (result: ClassificationSystem) => {

    });

}

onCsRemoved(e) {
  this.modelService.classificationService.deleteSystem(e.data.id);
}

  public onLogoClicked(){

  this.modelService.router.navigate(["/catalogs"]);
}

onEditDetailsClicked(data : ClassificationSystem) {

  this.modelService.classificationService.selectedSystem = data;
  this.modelService.router.navigate(["/editclassification"]);
}

get model(): ClassificationSystem[] {
  return this.modelService.classificationService.classificationSystems;
}

set model(system: ClassificationSystem[]) {
  this.modelService.classificationService.classificationSystems = system;
}
}


