import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { CategoryFilterTreeItem } from '../../../../../../Model/Catalog/exportFilter/categoryFilterTreeItem';
import { TreeItem } from '../../../../../../Model/Dto/TreeItem';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'categoryFilter',
  templateUrl: './categoryFilter.component.html',
  styleUrls: ['./categoryFilter.component.css']
})
export class CategoryFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  categoryFilters: CategoryFilterTreeItem[];
  isFilterLoaded: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService) {
    this.loadFilterIfNeeded = this.loadFilterIfNeeded.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.categoryFilter == null)
      this.exportParameter.categoryFilter = new CatalogFilter<string[]>([])
    if (this.exportParameter.categoryFilter.filterKey == null)
      this.exportParameter.categoryFilter.filterKey = [];

    this.loadFilterIfNeeded();
  }

  loadFilterIfNeeded() {
    if (this.exportParameter.categoryFilter.useFilter)
      this.refreshCategoryFilter();
  }

  refreshCategoryFilter() {
    this.isFilterLoaded = false;
    this.exportFilterService.getCategoryFilterTreeElements(this.catalogId, this.modelService.loginService.currentUser.customerId)
      .toPromise()
      .then((filter: CategoryFilterTreeItem[]) => {
        this.categoryFilters = filter;
      })
      .finally(() => this.isFilterLoaded = true);
  }
}
