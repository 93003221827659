<div>
  <div class="dx-fieldset" [ngStyle]="{'margin': '20px 30px'}">
    <div class="dx-field">
      <div class="dx-field-label" id="useTextQualifier">{{ 'Text Qualifierer verwenden' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]=exportParameter.useTextQualifier></dx-switch>
      </div>
      <dx-tooltip target="#useTextQualifier"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  [hideOnOutsideClick]="false">
        <div *dxTemplate="let data = data; of: 'content'">
          <div style="width: 400px">
            {{ 'Bei CSV Dateien ist ein Text Qualifizierer (textqualifier) das Zeichen, das verwendet wird, um Textfelder zu umschließen, insbesondere wenn diese Felder Kommas oder andere spezielle Zeichen enthalten, die sonst als Trennzeichen interpretiert würden. Die gängigsten Textqualifizierer sind Anführungszeichen oder Apostrophe .' | translate }}
          </div>
        </div>
      </dx-tooltip>
    </div>
    <div class="dx-field" *ngIf="exportParameter.useTextQualifier">
      <div class="dx-field-label">{{ 'Text Qualifierer' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]=exportParameter.textQualifier maxLength="1"></dx-text-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="!exportParameter.includeMime">
      <div class="dx-field-label">{{ 'Trennzeichen' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]=exportParameter.delimiter maxLength="1" ></dx-text-box>
      </div>
    </div>
  </div>
</div>
