import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ProductService } from '../../../../../../Services/CatalogManagement/product.service';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'supplierContentFilter',
  templateUrl: './supplierContentFilter.component.html',
  styleUrls: ['./supplierContentFilter.component.css']
})
export class SupplierContentFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  supplierFilters: { name: string }[] = [];
  isFilterLoaded: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService, public productService: ProductService) {
    this.loadsupplierFilterIfNeeded = this.loadsupplierFilterIfNeeded.bind(this);

  }

  ngOnInit(): void {
    if (this.exportParameter.supplierFilter == null)
      this.exportParameter.supplierFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.supplierFilter.filterKey == null)
      this.exportParameter.supplierFilter.filterKey = [];

    this.loadsupplierFilterIfNeeded();
  }

  loadsupplierFilterIfNeeded() {

    if (this.exportParameter.supplierFilter.useFilter)
      this.refreshManufacturersFilter();
  }

  refreshManufacturersFilter() {
    this.isFilterLoaded = false;
    this.exportFilterService.getSuppliers(this.catalogId, this.modelService.loginService.currentUser.customerId).toPromise().then(data => {
      this.supplierFilters = data.map(x => { return { name: x } });
    }).finally(() =>
      this.isFilterLoaded = true
    );
  }
}
