<dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="_catalogMappings"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Kategorien' | translate }}"
              [showBorders]="true"
              (onRowPrepared)="onRowPrepared($event)"
              (onRowUpdated)="update()"
              (onRowRemoved)="delete($event)">
  <div *dxTemplate="let toolbaritem of 'newCategoryButtonTemplate'">
    <div *ngIf="!readOnlyUser('categorieNew')">
      <dx-button stylingMode="text" type="default" *ngIf="!product.isChild" text="{{ 'Kategorie hinzufügen' | translate }}" (onClick)="showPopup()" class="buttonMargin marginBottom"></dx-button>
      <div *ngIf="product.isChild">{{ 'Die Kategoriezuordnungen können über den Master Artikel verwaltet werden.' | translate }}</div>
    </div>
  </div>

  <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="allowDeleting && !readOnlyUser('categorieDelete')" [useIcons]="true">
    <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie die Kategorie-Zuordnung wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Zuordnung löschen' | translate }}"></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="CategoriesStateNewUIV3"></dxo-state-storing>

  <dxi-column dataField="categoryIconUrl" caption="" [allowEditing]="false" cellTemplate="imageCellTemplate" [width]="80"></dxi-column>
  <div *dxTemplate="let data of 'imageCellTemplate'" class="catlistImageDiv">
    <div>
      <img *ngIf="!data.value" src="images/ikon_article_tree_invert.png" class="catlistImage" />
      <img *ngIf="data.value" src="{{data.value}}" style="width: 40px;" />
    </div>
  </div>

  <dxi-column dataField="order" dataType="number" caption="{{ 'Order' | translate }}">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="catalogName" [allowEditing]="false" caption="{{ 'Katalog' | translate }}"></dxi-column>
  <dxi-column *ngIf="loginService.hasAddonDEVELOPMENT" dataField="isVirtualCatalog" [allowEditing]="false" caption="{{ 'Virtuell' | translate }}" [allowEditing]="false"></dxi-column><!-- ein wenig mißbraucht an dieser stelle... -->
  <dxi-column dataField="categoryName" [allowEditing]="false" caption="{{ 'Bezeichnung' | translate }}"></dxi-column>
  <dxi-column cellTemplate="jumpToProductTemplate" [allowEditing]="false"></dxi-column>
  <div *dxTemplate="let treeItem of 'jumpToProductTemplate'">
    <dx-button stylingMode="text" (onClick)="jumpToCategory(treeItem.data)" text="{{ 'JumpToCategory' | translate }}" type="default" style="min-width: 32px;"></dx-button>
  </div>

</dx-data-grid>

<dx-popup [width]="800"
          [height]="530"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [showTitle]="true"
          title="{{ 'Kategorie hinzufügen' | translate }}"
          [(visible)]="popupVisible">
  <dxi-toolbar-item toolbar="bottom" location="after" [visible]="isAddCategoryVisible()" widget="dxButton" [options]="{ text: 'Kategorie hinzufügen' | translate, onClick: addCategories }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%" showScrollbar="always">

      <div *ngIf="isCategoriesLoaded==false" style="left: 30%; top: 30%;">
        <div class="loader">
        </div>
      </div>
      <div *ngIf="isCategoriesLoaded==true" class="{{fullScreen ? '': 'maxHeight'}}">
        <dx-tree-list #treeListCategoryFilter
                      [(dataSource)]="categoryFilters"
                      [(selectedRowKeys)]="checkedCategories"
                      dataStructure="tree"
                      itemsExpr="items"
                      keyExpr="id"
                      [ngStyle]="{'height': 'inherit'}">
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-selection mode="multiple"></dxo-selection>
          <dxo-scrolling preloadEnabled="true" useNative="true"></dxo-scrolling>
          <dxi-column dataField="text" caption="{{ 'Kategorie' | translate }}"></dxi-column>
        </dx-tree-list>

      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<!--(onSelectAllValueChanged)="onSelectAllCategories($event)"
(onItemSelectionChanged)="selectionChangedOnCategory($event)"-->
