import { Component, OnInit } from "@angular/core";
import isString from "lodash.isstring";
import { ModelService } from '../Services/model.service';
import { CatalogState } from '../Model/Catalog/CatalogState';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { TranslateService } from '@ngx-translate/core';
import { confirm } from 'devextreme/ui/dialog';


@Component({
  selector: "catalogStates",
  templateUrl: "catalogState.component.html"
})
export class CatalogStateComponent implements OnInit {
  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Status");
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newStateButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'customDeleteButtonTemplate'
    });    
  }

  ngOnInit(): void {
    this.getStatus();
  }

  public states: CatalogState[] = [];
  public selectedStates: any[];

  getStatus() {
    this.modelService.catalogStateService.getStatus().subscribe(
      (result: CatalogState[]) => {
        this.states = result;
      });
  }

  update(event) {
    var status;
    if (isString(event)) {
      status = this.states.find(item => item.idKey == event);
    } else {
      if (event.key == null)
        return;
      status = this.states.find(item => item.idKey == event.key.idKey);
    }
    this.modelService.catalogStateService.updateCatalogState(status).subscribe((_id: string) => {});
  }

  deleteMultiple() {                                                
    let result = confirm(this.selectedStates.length + this.translate.instant("Status ausgewählt </br> Sind Sie sich sicher, dass diese gelöscht werden sollen?"), this.translate.instant("Auswahl löschen"));
    result.then((dialogResult) => {
      if (dialogResult) {
        this.selectedStates.map(state => this.modelService.catalogStateService.deleteCatalogState(state.idKey));
        this.selectedStates.reverse().forEach(x => this.states.splice(this.states.findIndex(y => y.idKey == x.idKey), 1));
      }});
  }
  
  onSelectionChanged(e) {
      this.selectedStates = e.selectedRowKeys
  }

  addStatus(status: CatalogState = null, id = null) {
    if (id == null) {
      status = new CatalogState();
      status.customerId = this.modelService.loginService.currentUser.customerId;

      this.modelService.catalogStateService.addCatalogState(status).subscribe(
        (result: CatalogState) => {
          status = result;
          this.states.push(status);
        });

    } else {
      status.idKey = id;
      this.states.push(status);
    }
  }

  public pimStatus: string[] = ["New", "Updated", "Normal", "Locked", "Deleted"];

  deleteStatus(event) {
    this.modelService.catalogStateService.deleteCatalogState(event.data.idKey);
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }
}
