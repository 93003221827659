import { TeachingSample } from "./teachingsample";

export enum TeachingSetStates {
    Uninitialized = 0,
    Requested = 1,
    InProgress = 2,
    Ready = 3
}

export interface TeachingSet {
    customerId: string;
    id: string;
    name: string;
    state: TeachingSetStates;
    samples: TeachingSample[];
}
