export class UdxField {

  constructor(
    public name?: string,
    public value?: string,
    public order?: number,
    public type?: string,
    public category?: string,
    public childs?: UdxField[],
  ) {
    if (this.childs == null) {
      this.childs = [];
    }
  }
}
