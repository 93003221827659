import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { OsgConnection } from "../Model/Dto/OsgConnection";
import { TestResult } from "../Model/Dto/TestResult";
import { TranslateService } from '@ngx-translate/core';
import { OsgImportCatalog } from '../Model/Dto/OsgImportCatalog';


@Injectable()
export class OsgConnectionService {

  constructor(private http: HttpClient, public translate: TranslateService) { }

  getConnection(customerId: string, getTest: boolean): Observable<OsgConnection> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("getTest", getTest.toString())
    }

    return (this.http.get<OsgConnection[]>("api/OsgConnection/GetConnection", options)) as any;
  }

  update(osgConnection: OsgConnection): Observable<number> {
    return (this.http.post("api/OsgConnection/Update", osgConnection)) as any;

  }

  testShopConnection(id: number): Observable<TestResult> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("connectionId", id.toString())
    }
    return (this.http.get("api/OsgConnection/testShopConnection", options)) as any;
  }

  getProductRanges(customerId: string, useTest: boolean): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("useTest", useTest.toString())
    }

    return (this.http.get<OsgConnection[]>("api/OsgConnection/GetProductRanges", options)) as any;
  }

  importCatalog(productRange: string, customerId: string, keepRootCategory: boolean, useTest: boolean, takeAllCategory: boolean): Observable<boolean> {

    let osgImportCatalog = new OsgImportCatalog();

    osgImportCatalog.customerId = customerId;
    osgImportCatalog.productRange = productRange;
    osgImportCatalog.keepRootCategory = keepRootCategory;
    osgImportCatalog.useTest = useTest;
    osgImportCatalog.takeAllCategory = takeAllCategory;

    return (this.http.post("api/Import/ImportOsgCatalog", osgImportCatalog)) as any;

  }
}
