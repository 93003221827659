import { Component, OnInit } from "@angular/core";
import { CatalogDataQuality } from "../../Model/validation/CatalogDataQuality";
import { ModelService } from "../../Services/model.service";
import { Message } from "../../Model/System/Message";
import { StartAction } from "../../Model/Dto/StartAction";
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from "../../Services/userManagment.service";
import { EdeMemberIdService } from "../../Services/edeMemberId.service";
import { EdeMemberId } from "../../Model/edeSortimentsAbo/EdeMemberId";

@Component({
  selector: "edeSortiment",
  templateUrl: "edeSortimentsAbo.component.html",
  styleUrls: []
})
export class EdeSortimentsAboComponent {

  memberId: EdeMemberId = new EdeMemberId();

  constructor(public modelService: ModelService, public translate: TranslateService, public edeMemberIdService: EdeMemberIdService) {
    this.update = this.update.bind(this);
    this.edeMemberIdService.getConnection(this.modelService.loginService.currentCustomer.id).subscribe((result: EdeMemberId) => {
      this.memberId = result;
    });
  }

  public update() {
    this.edeMemberIdService.update(this.memberId).subscribe(() => {
      console.log("updated memberId");
    });
  }
}
