import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { HazmatClass } from "../Model/Catalog/HazmatClass";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "hazmatclass",
  templateUrl: "hazmatclass.component.html"
})
export class HazmatclassComponent implements OnInit {
  pages: ({ id: number; title; template: string } | { id: number; title: string; template: string })[];

  constructor(public modelService: ModelService, public translate: TranslateService) { }

  pText = "";
  hText = "";
  piktogrammeText = "";

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }


    this.piktogrammeText = this.translate.instant("GHS-Piktogramme");

    this.hText = this.translate.instant("H-Sätze");

    this.pText = this.translate.instant("P-Sätze");

    this.pages = [
      { id: 1, title: this.piktogrammeText, template: "piktoTemplate" },
      { id: 2, title: this.hText, template: "hTemplate" },
      { id: 3, title: this.pText, template: "pTemplate" },
    ];


  }
  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }
  update(e) {


    this.modelService.hazmatClassService.updateClass(this.modelService.hazmatClassService.selectedClass).subscribe(
      (result: HazmatClass) => {
   
      });
  }


}
