export class Translation {

  constructor(
    public id?: string,
    public customerId?: string,
    public lastChanged?: Date,
    public text?: string,
    public translation?: string,
    public sourceLanguageCode?: string,
    public targetLanguageCode?: string,
    public approved?: boolean
  ) {
  }
}
