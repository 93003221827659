<dx-file-uploader
  #fileUploader
  name="file"
  uploadUrl="api/import/importTranslationsChunky"
  [uploadHeaders]="uploadHeaders"
  [multiple]="false"
  [chunkSize]="10000000"
  accept=".xlsx"
  uploadMode="instantly"
  (onBeforeSend)="onBeforeSendUpload($event)">
</dx-file-uploader>
