import { Component, OnInit, ViewChild } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ClassificationClass } from "../Model/classification/ClassificationClass";
import { FeatureValue } from "../Model/classification/FeatureValue";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
  selector: "classificationGroup",
  templateUrl: "classificationGroup.component.html"
})
export class ClassificationGroupComponent implements OnInit {

  @ViewChild(DxDataGridComponent)
  private dataGrid: DxDataGridComponent;


  valueTypeSource = [ "List", "Number","String" ];

  constructor(public modelService: ModelService) {

  }


  ngOnInit(): void {
 
  }

  update() {
    this.modelService.classificationService.updateClass(this.modelService.classificationService.selectedClass).subscribe(
      (result: ClassificationClass) => {
        this.modelService.classificationService.selectedClass.features.forEach(x => {
          if (!x.values) {
            x.values = new Array<FeatureValue>();
          }
        });
      });;
  }
}
