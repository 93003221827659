<div class="dx-fieldset" style="max-width: 600px;">

  <div class="dx-field">
    <div class="dx-field-label jobListTD">CustomerId</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">

      <dx-text-box [(value)]="model.customerId" [readOnly]="True" [showClearButton]="false"></dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Login' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">

      <dx-text-box [(value)]="model.login" [readOnly]="True" [showClearButton]="false"></dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Password' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">

      <dx-text-box [(value)]="model.password" [readOnly]="True" [showClearButton]="false"></dx-text-box>
    </div>
  </div>



  <dx-toolbar>
    <dxi-item *ngIf="isManager" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Zugangsdaten erstellen' | translate , onClick: createCredentials }"></dxi-item>
  </dx-toolbar>






</div>








