<div>
  {{ 'MarketingClaim' | translate }}
  {{ 'Lieferinformationen' | translate }}
  {{ 'andere Merkmale' | translate }}
  {{ 'Technische Daten' | translate }}
  {{ 'Topmerkmale' | translate }}
  {{ 'Lieferumfang' | translate }}
  {{ 'Vorteile' | translate }}
  {{ 'Anwendung' | translate }}
  {{ 'Material' | translate }}
  {{ 'Varianten Merkmale' | translate }}
</div>
