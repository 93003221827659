import { Component, OnInit } from '@angular/core';
import { ModelService } from '../Services/model.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
})
export class StatesComponent implements OnInit {

  pages: { id: number; title: string; template: string; }[] = [];

  constructor(public modelService: ModelService, public translateService: TranslateService) {
  }
  
  ngOnInit(): void {
    this.pages = [
      { id: 1, title: this.translateService.instant('Produktstatus'), template: "productStateTemplate" },
      { id: 2, title: this.translateService.instant('Katalogstatus'), template: "catalogStateTemplate" },
    ];
  }
}
