<fullscreen-page>
  <navigation-column>
    <div class="h-100 d-flex flex-column">
      <div class="p-2 flex-fill overflow-hidden">
        <dx-data-grid
          class="h-100"
          [dataSource]="templates"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [remoteOperations]="false"
          [allowColumnReordering]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          noDataText="{{ 'keine Update Templates im Account' | translate }}"
          [showBorders]="true"
          keyExpr="id"
          [selectedRowKeys]="selectedRowKeys"
          (onSelectionChanged)="onSelectionChangedClick($event)"
          (onRowRemoved)="onDeleteClicked($event)"
        >
          <dxo-selection mode="single"></dxo-selection>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
          <dxo-search-panel
            [visible]="true"
            [highlightCaseSensitive]="true"
            placeholder="{{ 'Update Template suchen' | translate }}"
          ></dxo-search-panel>
          <div *dxTemplate="let toolbaritem of 'newTemplateButtonTemplate'">
            <dx-button
              stylingMode="text"
              type="default"
              text="{{ 'Neues Update Template' | translate }}"
              (onClick)="addTemplate()"
            ></dx-button>
          </div>
          <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="false" [useIcons]="true"></dxo-editing>

          <dxo-state-storing
            [enabled]="true"
            type="localStorage"
            storageKey="UpdateTemplatesStateNewUIV2"
          ></dxo-state-storing>

          <dxi-column dataField="name" caption="{{ 'Name' | translate }}"></dxi-column>
        </dx-data-grid>
      </div>
    </div>
  </navigation-column>
  <content-column [helpSwitch]="helpSwitch" look="light">
    <div slot="content" class="h-100 px-3">
      <dx-scroll-view *ngIf="selectedTemplate" showScrollbar="always">
        <UpdateTemplate [(template)]="selectedTemplate"></UpdateTemplate>
      </dx-scroll-view>
    </div>
  </content-column>
  <help-column class="{{ systemService.showHelp ? '' : 'd-none' }}"></help-column>
</fullscreen-page>
