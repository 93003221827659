<div class="dx-fieldset">
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Einzelne Texte für Varianten generieren' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="genVariantTexts" (onValueChanged)="calculateProds()"></dx-switch>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Generiere HTML' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch hint="hintMessage" [(value)]="generateHTML"></dx-switch>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Textstil' | translate }}</div>
      <div class="dx-field-value">
        <dx-select-box [items]="textstyles" [(value)]="textstyle"></dx-select-box>
      </div>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Maximale Länge' | translate }}</div>
    <div class="dx-field-value">
      <dx-number-box min="50" max="10000" [(value)]="maxLength"></dx-number-box>
    </div>
  </div>

<div class="p-2 flex-fill">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Produkte über Massendatenbearbeitung auswählen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch hint="hintMessage" [(value)]="generateWithMassDataChange"></dx-switch>
    </div>
  </div>
</div>
</div>

<div class="dx-fieldset" *ngIf="!generateWithMassDataChange">
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Kosten pro Text' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €"
                       [value]="invoiceSetting.pricePerOpenAi"></dx-number-box>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Texte die generiert werden' | translate }}</div>
      <div class="dx-field-value"><dx-number-box [readOnly]="true" [(value)]="productCount"></dx-number-box></div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Maximale Gesamtkosten' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €"
                       [value]="productCount * invoiceSetting.pricePerOpenAi"></dx-number-box>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Benutztes Limit' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.usedLimit"></dx-number-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Limit' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.limit"></dx-number-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Freibetrag' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.allowance"></dx-number-box>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'TeachingSet' | translate }}</div>
      <div class="dx-field-value">
        <dx-select-box [items]="teachingSets" [(value)]="teachingSet" displayExpr="name"></dx-select-box>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="!generateWithMassDataChange && !balanceIsFilled && !loading">
  <p>{{ 'Das Budget reicht nicht aus, um Texte für alle Produkte im Katalog zu generieren.' | translate }}</p>
</div>

<div class="alert alert-info" *ngIf="!generateWithMassDataChange && balanceIsFilled && !loading">
  <p>{{ 'Wenn Sie das Makro ausführen, werden alle Produkte des Katalogs mit generierten Texten ergänzt. Prüfen Sie, ob das Budget für Sie stimmig ist.' | translate }}</p>
</div>

<div class="alert alert-info" *ngIf="generateWithMassDataChange">
  <p>{{ 'Sie werden auf die Massendatenbearbeitung weitergeleitet. Dort können Sie gezielt Produkte für die Textgenerierung auswählen.' | translate }}</p>
</div>
