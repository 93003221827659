import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { TemplateItem } from "../../Model/Catalog/TemplateItem";
import { TemplateItemTemplate } from "../../Model/Catalog/TemplateItemTemplate";
import { ExportParameter } from '../../Model/exportParameter/ExportParameter';
import { LoginService } from "./../login.service";
import { ViewService } from "./../view.service";

@Injectable()
export class CategoryService
{
  public fields: TemplateItemTemplate[] = [];

  public categoryExportVisible: boolean = false;
  public categoryImportVisible: boolean = false;

  constructor(private http: HttpClient, public loginService: LoginService, public viewService: ViewService)
  {

  }

  createExportJob(exportParameter: ExportParameter) {
    return this.http.post("api/job/CreateCategoryExportJob", exportParameter);
  }

  public loadFields()
  {
    this.loadCategoryFields2Subscribe().subscribe();
  }

  public loadCategoryFields2Subscribe(): Observable<any>
  {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return this.http.get<TemplateItemTemplate[]>("api/category/GetExcelTemplateItemTemplate", options)
      .pipe(
        map((result: TemplateItemTemplate[]) =>
        {
          this.fields = result
        }));
  }

  private sortTemplateItems(templateItems: TemplateItem[])
  {
    templateItems.sort(function (a: TemplateItem, b: TemplateItem): number
    {
      if (a.order == null || a.order == 0)
      {
        return -1;
      }
      if (b.order == null || b.order == 0)
      {
        return 1;
      }
      if (a.order < b.order)
      {
        return -1;
      }
      if (a.order > b.order)
      {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    });
  }
}
