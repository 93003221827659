export class ChangePassword {

  constructor(
    public oldPassword?: string,
    public confirmPassword?: string,
    public newPassword?: string) {


  }
}
