import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { TeachingSetService } from "../Services/teachingSet.service";
import { TeachingSample } from "../Model/OpenAi/teachingsample";
import { FeatureSystem } from "../Model/Catalog/FeatureModels/FeatureSystem";
import { TeachingSetStates } from "../Model/OpenAi/teachingset";
import { ValidationService } from "../Services/Validation/validation.service";

@Component({
  selector: "teachingset",
  templateUrl: "teachingset.component.html"
})
export class TeachingSetComponent implements OnInit {

  samplePages: { id: number; title: string; template: string; }[] = [];

  constructor(public modelService: ModelService, public teachingSetService: TeachingSetService, public validationService: ValidationService, public translate: TranslateService) {
    this.addSample = this.addSample.bind(this);
    this.addFeatureSystem = this.addFeatureSystem.bind(this);
    this.generate = this.generate.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.paste = this.paste.bind(this);
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.samplePages = [
      { id: 1, title: this.translate.instant("Details"), template: "sampleDetailTemplate" },
      { id: 2, title: this.translate.instant("Features"), template: "sampleFeaturesTemplate" },
      { id: 2, title: this.translate.instant("Ergebnis"), template: "sampleResultTemplate" }
    ];

  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  get generateEnabled() {
    return this.teachingSetService.selectedSet?.state === undefined || this.teachingSetService.selectedSet?.state === TeachingSetStates.Uninitialized || this.teachingSetService.selectedSet?.state === TeachingSetStates.Ready;
  }

  generate() {
    this.teachingSetService.startGenerationJob(this.teachingSetService.selectedSet).subscribe(result => {
      this.modelService.systemService.notifyInfo(this.translate.instant("Ein Job wurde erzeugt"));
    });
  }

  addSample() {
    this.teachingSetService.selectedSet.samples.push({
      descriptionShort: this.translate.instant("Neues Muster")
    } as TeachingSample);

    this.update();
  }

  addFeatureSystem() {
    const featureSystem = new FeatureSystem();

    featureSystem.order = 1;

    this.teachingSetService.selectedSample.featureSystem = featureSystem;

    this.update();
  }

  update() {
    this.teachingSetService.updateSingle(this.teachingSetService.selectedSet).subscribe(
      result => {
        // this.teachingSetService.selectedSet = result;
      });
  }

  getStatus(): string {
    switch (this.teachingSetService.selectedSet.state) {
      case TeachingSetStates.InProgress:
        return "InProgress";
      case TeachingSetStates.Ready:
        return "Ready";
      case TeachingSetStates.Requested:
        return "Requested";
      case TeachingSetStates.Uninitialized:
        return "Uninitialized";
    }
  }


  paste() {
    this.modelService.catalogService.loadClipboard().subscribe(clipboard => {
      if (clipboard.content == "pro") {
        this.modelService.catalogService.getProductUnchecked(clipboard.objectId, this.modelService.loginService.currentCustomer.id)
          .subscribe(product => {
            let sample = new TeachingSample();
            sample.descriptionShort = product.descriptionShort;
            sample.descriptionLong = product.descriptionLong;
            sample.featureSystem = product.featureSystems[0];
            sample.keyWords = product.keywords;
            sample.manufacturer = product.manufacturerName;
            this.modelService.catalogService.getCategory(product.catalogMappings[0].categoryId, this.modelService.loginService.currentCustomer.id) // .catalogMappings[0]. ist hier OK
              .subscribe(category => {
                sample.groupString = category.groupName;
                this.teachingSetService.selectedSet.samples.push(sample);
                this.update();
              });
          });
      } else {
        this.modelService.systemService.notifyInfo(this.translate.instant("In der Zwischenablage befindet sich kein Produkt"));
      }
    });
  }

}
