import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";


@Component({
  selector: "userAction-setMimePurpose",
  templateUrl: "userAction-setMimePurpose.component.html"
})
export class UserActionSetMimePurposeComponent extends UserActionBaseComponent {
  
  overrideExisting: boolean = false;


  constructor(public modelService: ModelService) {
    super(modelService);
  }

  startMacro() {
   
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = `SetMimePurpose`;
    startAction.actionParameter = JSON.stringify({ OverrideExisting: this.overrideExisting });

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
