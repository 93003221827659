<dx-data-grid
  (onToolbarPreparing)="onGridToolbarPreparing($event)"
  [dataSource]="model"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  noDataText="{{ 'keine Medien' | translate }}"
  [showBorders]="true"
  (onRowRemoving)="onRowRemoving($event)"
  (onRowRemoved)="delete($event)"
  [masterDetail]="{ enabled: true, template: 'detail' }"
  (onContentReady)="onContentReady($event)"
  [customizeColumns]="customizeColumns"
>
  <div *dxTemplate="let toolbaritem of 'newMimeButtonTemplate'">
    <dx-button
      *ngIf="renderNewMimeButton()"
      stylingMode="text"
      [disabled]="updateLock"
      type="default"
      text="{{ 'Neue Mime' | translate }}"
      (onClick)="addMime()"
    ></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'importButtonTemplate'">
    <dx-button
      *ngIf="renderImportMimeButton()"
      stylingMode="text"
      type="default"
      text="{{ 'Importieren' | translate }}"
      (onClick)="showImportMime()"
    ></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'reportButtonTemplate'">
    <dx-button
      *ngIf="renderReportButton()"
      stylingMode="text"
      type="default"
      text="{{ 'Produktdatenblatt erstellen' | translate }}"
      (onClick)="showCreateReportProductDatasheet()"
    ></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
    <dx-button *ngIf="!templateView" stylingMode="text" type="default" icon="refresh" (onClick)="reload()"></dx-button>
  </div>

  <dxo-editing
    mode="cell"
    [allowUpdating]="!readOnly('mimeUpdate')"
    [allowDeleting]="allowDeleting && !readOnly('mimeDelete')"
    [useIcons]="true"
  >
    <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="MimesStateNewUIV2"></dxo-state-storing>

  <dxi-column
    dataField="mimeOrder"
    caption="{{ 'Order' | translate }}"
    [width]="70"
    [allowEditing]="true"
    cellTemplate="mimeOrderTemplate"
    editCellTemplate="mimeOrderTemplate"
  ></dxi-column>
  <div *dxTemplate="let mime of 'mimeOrderTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="product"
        [showLinkButtons]="false"
        [showPlaceLabel]="mime?.data?.mimeOrder != null"
        [suppressLabel]="true"
        [suppressLinkButton]="true"
        [suppressTranslation]="true"
        [suppressToolTip]="true"
        [suppressDragHandle]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="null"
        (onValueChanged)="update($event)"
        labelText="{{ 'Order' | translate }}"
        field="MIME_ORDER"
        [system]="mime.data.mimeOrder.toString()"
        [showDragHandle]="false"
        [dragFilter]="dragFilter"
      >
        <div slot="content" class="contentSlot">
          <dx-number-box
            *ngIf="showLinkButtons"
            class="inDataGrid"
            [(value)]="mime.data.mimeOrder"
            [min]="1"
            [showSpinButtons]="!hasMimeFieldLinked(mime.data.mimeOrder.toString())"
            [readOnly]="hasMimeFieldLinked(mime.data.mimeOrder.toString()) || readOnly('mimeOrder')"
            [showClearButton]="false"
            valueChangeEvent="focusout"
            (onValueChanged)="update($event, null, mime.data, 'MIME_ORDER', mime.data.mimeOrder.toString())"
          ></dx-number-box>
          <dx-number-box
            *ngIf="!showLinkButtons"
            class="inDataGrid"
            [(value)]="mime.data.mimeOrder"
            [min]="1"
            [showSpinButtons]="!templateView"
            [readOnly]="readOnly('mimeOrder')"
            [showClearButton]="false"
            (onValueChanged)="update($event, null, mime.data, 'MIME_ORDER', mime.data.mimeOrder.toString())"
          ></dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column
    dataField="thumbUri"
    caption=""
    cellTemplate="thumbTemplate"
    editCellTemplate="thumbTemplate"
    [allowEditing]="false"
    [width]="50"
  ></dxi-column>
  <div *dxTemplate="let thumbUri of 'thumbTemplate'" style="margin-top: -7px; margin-bottom: -5px; margin-left: 3px">
    <div>
      <span *ngIf="!thumbUri.value" class="glyphicon glyphicon-picture" style="font-size: 29px"></span>
      <img *ngIf="thumbUri.value" src="{{thumbUri.value}}" style="height: 29px" />
    </div>
  </div>

  <dxi-column
    dataField="mimeDescr"
    caption="{{ 'Beschreibung' | translate }}"
    [allowEditing]="true"
    cellTemplate="mimeDescrTemplate"
    editCellTemplate="mimeDescrTemplate"
  ></dxi-column>
  <div *dxTemplate="let mime of 'mimeDescrTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="product"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="mime?.data?.mimeDescr?.length > 0"
        [suppressLabel]="true"
        [suppressToolTip]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
        (onValueChanged)="update($event)"
        labelText="{{ 'Beschreibung' | translate }}"
        field="MIME_DESCR"
        [system]="mime.data.mimeOrder.toString()"
        [showDragHandle]="showDragHandle"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event)"
      >
        <div slot="content" class="contentSlot">
          <dx-autocomplete
            maxItemCount="50"
            [minSearchLength]="0"
            [dataSource]="mimeDescr"
            [readOnly]="readOnlyForLinkedFields('MIME_DESCR', mime.data.mimeOrder.toString()) || readOnly('mimeDescription')"
            [showClearButton]="showClearButton('MIME_DESCR', mime.data.mimeOrder.toString())"
            [showDropDownButton]="!readOnlyForLinkedFields('MIME_DESCR', mime.data.mimeOrder.toString())"
            [(value)]="mime.data.mimeDescr"
            valueChangeEvent="blur"
            (onValueChanged)="update($event, null, null, 'MIME_DESCR', mime.data.mimeOrder.toString())"
            (onSelectionChanged)="updateFromAutocomplete($event, 'MIME_DESCR', mime.data.mimeOrder.toString())"
          >
            <dx-validator
              *ngIf="!templateView && product"
              validationGroup="MIME"
              (onInitialized)="validationService.startAutomaticValidation($event)"
            >
              <dxi-validation-rule
                type="async"
                [validationCallback]="validationService.validateField('MIME_DESCR', mime.data.mimeOrder.toString())"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-autocomplete>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column
    dataField="mimePurpose"
    caption="{{ 'Verwendung' | translate }}"
    [allowEditing]="true"
    cellTemplate="mimePurposeTemplate"
    editCellTemplate="mimePurposeTemplate"
    [width]="180"
  ></dxi-column>
  <div *dxTemplate="let mime of 'mimePurposeTemplate'">
    <div class="np-field w-100">
      <np-inputControl
        [(model)]="product"
        [showLinkButtons]="showLinkButtons"
        [showPlaceLabel]="mime?.data?.mimePurpose?.length > 0"
        [suppressLabel]="true"
        [suppressToolTip]="true"
        [(selectedElements)]="selectedElements"
        (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
        (onValueChanged)="update($event)"
        labelText="{{ 'Verwendung' | translate }}"
        field="MIME_PURPOSE"
        [system]="mime.data.mimeOrder.toString()"
        [showDragHandle]="showDragHandle"
        [dragFilter]="dragFilter"
        (excelElementDropped)="excelElementDrop($event)"
      >
        <div slot="content" class="contentSlot">
          <dx-autocomplete
            [minSearchLength]="0"
            [dataSource]="mimePurposes"
            [readOnly]="readOnlyForLinkedFields('MIME_PURPOSE', mime.data.mimeOrder.toString()) || readOnly('mimePurpose')"
            [showClearButton]="showClearButton('MIME_PURPOSE', mime.data.mimeOrder.toString())"
            [showDropDownButton]="!readOnlyForLinkedFields('MIME_PURPOSE', mime.data.mimeOrder.toString())"
            [(value)]="mime.data.mimePurpose"
            valueChangeEvent="blur"
            (onValueChanged)="update($event, null, null, 'MIME_PURPOSE', mime.data.mimeOrder.toString())"
            (onSelectionChanged)="updateFromAutocomplete($event, 'MIME_PURPOSE', mime.data.mimeOrder.toString())"
          >
            <dx-validator
              *ngIf="!templateView && product"
              validationGroup="MIME"
              (onInitialized)="validationService.startAutomaticValidation($event)"
            >
              <dxi-validation-rule
                type="async"
                [validationCallback]="validationService.validateField('MIME_PURPOSE', mime.data.mimeOrder.toString())"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-autocomplete>
        </div>
      </np-inputControl>
    </div>
  </div>

  <div *dxTemplate="let mime of 'detail'">
    <dx-box direction="row" width="100%" class="np-form-control" [ngStyle]="{ gap: '1.5rem' }">
      <dxi-item [ratio]="1">
        <div *ngIf="!exportMode" class="dx-card d-flex justify-content-center align-items-center">
          <span *ngIf="!mime.data.normUri" class="glyphicon glyphicon-picture" [ngStyle]="{ fontSize: '25ch', marginBlock: '2rem' }"></span>
          <a
            *ngIf="mime.data.normUri"
            href="{{mediaService.getDownloadLink(this.catalogId,this.customerId,mime.data.mimeSource)}}"
            target="_blank"
          >
            <img src="{{mime.data.normUri}}" [ngStyle]="{ maxWidth: '100%' }" />
          </a>
        </div>
      </dxi-item>
      <dxi-item [ratio]="3">
        <div class="dx-card mb-4">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Bildinformation' | translate }}</div>
            <div class="d-flex justify-content-between gap-3">
              <div class="np-field flex-grow-1">
                <np-inputControl
                  [(model)]="product"
                  [showLinkButtons]="showLinkButtons"
                  [showPlaceLabel]="mime?.data?.mimeSource?.length > 0"
                  [(selectedElements)]="selectedElements"
                  (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
                  (onValueChanged)="update($event)"
                  labelText="{{ 'Dateiname / Link' | translate }}"
                  field="MIME_SOURCE"
                  [system]="mime.data.mimeOrder.toString()"
                  [showDragHandle]="showDragHandle"
                  [dragFilter]="dragFilter"
                  (excelElementDropped)="excelElementDrop($event)"
                >
                  <div slot="content" class="contentSlot">
                    <dx-text-box
                      [(value)]="mime.data.mimeSource"
                      [readOnly]="readOnlyForLinkedFields('MIME_SOURCE', mime.data.mimeOrder.toString()) ||readOnly('mimeSource')"
                      [showClearButton]="showClearButton('MIME_SOURCE', mime.data.mimeOrder.toString())"
                      (onValueChanged)="update($event, null, null, 'MIME_SOURCE', mime.data.mimeOrder.toString())"
                    >
                      <dx-validator
                        *ngIf="!templateView && product"
                        validationGroup="MIME"
                        (onInitialized)="validationService.startAutomaticValidation($event)"
                      >
                        <dxi-validation-rule
                          type="async"
                          [validationCallback]="validationService.validateField('MIME_SOURCE', mime.data.mimeOrder.toString())"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </np-inputControl>
              </div>
              <div class="np-field" [ngStyle]="{ width: '28rem' }">
                <np-inputControl
                  [(model)]="product"
                  [showLinkButtons]="showLinkButtons"
                  [showPlaceLabel]="mime?.data?.mimeType?.length > 0"
                  [(selectedElements)]="selectedElements"
                  (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
                  (onValueChanged)="update($event)"
                  labelText="{{ 'Dateityp' | translate }}"
                  field="MIME_TYPE"
                  [system]="mime.data.mimeOrder.toString()"
                  [showDragHandle]="showDragHandle"
                  [dragFilter]="dragFilter"
                  (excelElementDropped)="excelElementDrop($event)"
                >
                  <div slot="content" class="contentSlot">
                    <dx-autocomplete
                      [minSearchLength]="0"
                      [dataSource]="mimeTypes"
                      [readOnly]="readOnlyForLinkedFields('MIME_TYPE', mime.data.mimeOrder.toString()) || readOnly('mimeTypes')"
                      [showClearButton]="showClearButton('MIME_TYPE', mime.data.mimeOrder.toString())"
                      [showDropDownButton]="!readOnlyForLinkedFields('MIME_TYPE', mime.data.mimeOrder.toString())"
                      [(value)]="mime.data.mimeType"
                      valueChangeEvent="blur"
                      (onValueChanged)="update($event, null, null, 'MIME_TYPE', mime.data.mimeOrder.toString())"
                      (onSelectionChanged)="updateFromAutocomplete($event, 'MIME_TYPE', mime.data.mimeOrder.toString())"
                    >
                      <dx-validator
                        *ngIf="!templateView && product"
                        validationGroup="MIME"
                        (onInitialized)="validationService.startAutomaticValidation($event)"
                      >
                        <dxi-validation-rule
                          type="async"
                          [validationCallback]="validationService.validateField('MIME_TYPE', mime.data.mimeOrder.toString())"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-autocomplete>
                  </div>
                </np-inputControl>
              </div>
            </div>
            <np-inputControl
              [(model)]="product"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="mime?.data?.mimeAlt?.length > 0"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
              (onValueChanged)="update($event)"
              labelText="{{ 'Alternativtext' | translate }}"
              field="MIME_ALT"
              [system]="mime.data.mimeOrder.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box
                  [(value)]="mime.data.mimeAlt"
                  [readOnly]="readOnlyForLinkedFields('MIME_ALT', mime.data.mimeOrder.toString()) || readOnly('mimeOrder')"
                  [showClearButton]="showClearButton('MIME_ALT', mime.data.mimeOrder.toString())"
                  (onValueChanged)="update($event, null, null, 'MIME_ALT', mime.data.mimeOrder.toString())"
                >
                  <dx-validator
                    *ngIf="!templateView && product"
                    validationGroup="MIME"
                    (onInitialized)="validationService.startAutomaticValidation($event)"
                  >
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField('MIME_ALT', mime.data.mimeOrder.toString())"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Verwendung' | translate }}</div>
            <div class="np-field">
              <np-inputControl
                [(model)]="product"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="mime?.data?.mimePurpose?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
                (onValueChanged)="update($event)"
                labelText="{{ 'Verwendungszweck' | translate }}"
                field="MIME_PURPOSE"
                [system]="mime.data.mimeOrder.toString()"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-autocomplete
                    [minSearchLength]="0"
                    [dataSource]="mimePurposes"
                    [readOnly]="readOnlyForLinkedFields('MIME_PURPOSE', mime.data.mimeOrder.toString()) || readOnly('mimePurpose')"
                    [showClearButton]="showClearButton('MIME_PURPOSE', mime.data.mimeOrder.toString())"
                    [showDropDownButton]="!readOnlyForLinkedFields('MIME_PURPOSE', mime.data.mimeOrder.toString())"
                    [(value)]="mime.data.mimePurpose"
                    valueChangeEvent="blur"
                    (onValueChanged)="update($event, null, null, 'MIME_PURPOSE', mime.data.mimeOrder.toString())"
                    (onSelectionChanged)="updateFromAutocomplete($event, 'MIME_PURPOSE', mime.data.mimeOrder.toString())"
                  >
                    <dx-validator
                      *ngIf="!templateView && product"
                      validationGroup="MIME"
                      (onInitialized)="validationService.startAutomaticValidation($event)"
                    >
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('MIME_PURPOSE', mime.data.mimeOrder.toString())"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>
              </np-inputControl>
            </div>
            <div class="np-field">
              <np-inputControl
                [(model)]="product"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="mime?.data?.mimeDescr?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
                (onValueChanged)="update($event)"
                labelText="{{ 'Beschreibung' | translate }}"
                field="MIME_DESCR"
                [system]="mime.data.mimeOrder.toString()"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-autocomplete
                    maxItemCount="50"
                    [minSearchLength]="0"
                    [dataSource]="mimeDescr"
                    [readOnly]="readOnlyForLinkedFields('MIME_DESCR', mime.data.mimeOrder.toString()) || readOnly('mimeOrder')"
                    [showClearButton]="showClearButton('MIME_DESCR', mime.data.mimeOrder.toString())"
                    [showDropDownButton]="!readOnlyForLinkedFields('MIME_DESCR', mime.data.mimeOrder.toString())"
                    [(value)]="mime.data.mimeDescr"
                    valueChangeEvent="blur"
                    (onValueChanged)="update($event, null, null, 'MIME_DESCR', mime.data.mimeOrder.toString())"
                    (onSelectionChanged)="updateFromAutocomplete($event, 'MIME_DESCR', mime.data.mimeOrder.toString())"
                  >
                    <dx-validator
                      *ngIf="!templateView && product"
                      validationGroup="MIME"
                      (onInitialized)="validationService.startAutomaticValidation($event)"
                    >
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('MIME_DESCR', mime.data.mimeOrder.toString())"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>
              </np-inputControl>
            </div>

            <div *ngIf="excludeFromExport()">
              <hr class="mb-4" />
              <div class="np-field dx-field">
                <div class="dx-field-label">{{ 'Von Export ausgrenzen?' | translate }}</div>
                <div class="dx-field-value">
                  <np-inputControl
                    *ngIf="hasShopware6Addon()"
                    [(model)]="product"
                    [suppressLabel]="true"
                    [suppressLinkButton]="true"
                    [suppressPlaceLabel]="true"
                    [(selectedElements)]="selectedElements"
                    (elementSelected)="templateSelect($event, mime.data.mimeOrder.toString())"
                    (onValueChanged)="update($event)"
                    field="MIME_EXCLUDE_FROM_SHOPWARE6"
                    [system]="mime.data.mimeOrder.toString()"
                    [showDragHandle]="showDragHandle"
                    [dragFilter]="dragFilter"
                    (excelElementDropped)="excelElementDrop($event)"
                  >
                    <div
                      slot="content"
                      class="contentSlot np-others"
                      style="width: 125px; height: 32px; padding-top: 2px; padding-left: 2px"
                    >
                      <dx-check-box
                        [(value)]="mime.data.excludeFromShopware6"
                        [readOnly]="readOnlyForLinkedFields('MIME_EXCLUDE_FROM_SHOPWARE6', mime.data.mimeOrder.toString()) || readOnly('mimeOrder')"
                        (onValueChanged)="update($event, null, null, 'MIME_EXCLUDE_FROM_SHOPWARE6', mime.data.mimeOrder.toString())"
                        text="{{ 'Shopware' | translate }}"
                      >
                        <dx-validator
                          *ngIf="!templateView && product"
                          validationGroup="MIME"
                          (onInitialized)="validationService.startAutomaticValidation($event)"
                        >
                          <dxi-validation-rule
                            type="async"
                            [validationCallback]="validationService.validateField('MIME_EXCLUDE_FROM_SHOPWARE6', mime.data.mimeOrder.toString())"
                          ></dxi-validation-rule>
                        </dx-validator>
                      </dx-check-box>
                    </div>
                  </np-inputControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </div>
</dx-data-grid>

<div
  *ngIf="modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult?.productMimesValid"
  class="color3Text"
>
  <table>
    <tr *ngFor="let msg of validationService.validationResult?.mediaMessages">
      <td>{{ translateMessage(msg) }}</td>
    </tr>
  </table>
</div>

<dx-popup
  class="popup"
  [width]="500"
  [height]="300"
  [showTitle]="true"
  title="Mime importieren"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="importMimeVisible"
  (onHiding)="hideImportMime()"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <importMime [(isTemplate)]="isTemplate"></importMime>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [width]="800"
  [height]="675"
  [showTitle]="true"
  [title]="txtExportReportProductDatasheetShort"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="createReportProductDatasheetVisible"
  (onHiding)="hideCreateReportProductDatasheet()"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <productReportingDialogComponent
      [customerId]="customerId"
      [catalogId]="catalogId"
      [productId]="product.id"
      (onCreate)="hideCreateReportProductDatasheet()"
      (onDone)="reload()"
    ></productReportingDialogComponent>
  </div>
</dx-popup>
