import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PrintCredential } from '../Model/System/PrintCredential';
import { CreatePrintCredential } from '../Model/Dto/CreatePrintCredential';

@Injectable()
export class PrintService {
  

  constructor(private http: HttpClient) {

  }


  getPrintCredentials( customerId: string): Observable<PrintCredential> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<PrintCredential>("api/print/GetPrintCredentials", options)) as any;
  }

  createCredentials(dto: CreatePrintCredential): Observable<PrintCredential>  {
    return (this.http.post("api/print/createCredential", dto)) as any;
}








}
