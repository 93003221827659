<!--68: RemoveUDXOnProducts-->
<dx-scroll-view width="100%" height="100%" showScrollbar="always">
  <div class="dx-field" style="height: 400px">
    <div *ngIf="isUdxLoaded==false" style="left: 30%; top: 30%;">
      <div class="loader">
      </div>
    </div>
    <div *ngIf="isUdxLoaded==true" class="{{fullScreen ? '': 'maxHeight'}}">
      <dx-tree-list [(dataSource)]="udxFilters"
                    [(selectedRowKeys)]="checkedUdx"
                    dataStructure="tree"
                    itemsExpr="items"
                    keyExpr="groupString"
                    showCheckBoxesMode="selectAll"
                    [ngStyle]="{'height': 'inherit'}">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection mode="multiple" recursive="true"></dxo-selection>
        <dxo-scrolling preloadEnabled="true" useNative="true"></dxo-scrolling>
        <dxi-column dataField="text" caption="{{ 'Udx' | translate }}"></dxi-column>
      </dx-tree-list>
    </div>
  </div>
</dx-scroll-view>
