import { Component, ViewChild } from "@angular/core";
import { Message } from "../Model/System/Message";
import { TranslateService } from "@ngx-translate/core";
import { ModelService } from "../Services/model.service";
import { DxDataGridComponent, DxFileUploaderComponent } from "devextreme-angular";
import { WawiListService } from "../Services/wawiList.service";
import { OAuthService } from "angular-oauth2-oidc";
import { WawiList, WawiListElement } from "../Model/wawi/WawiList";

@Component({
  selector: "wawiLists",
  templateUrl: "wawiLists.component.html"
})
export class WawiListComponent {
  @ViewChild(DxFileUploaderComponent, { static: true })
  dxfileuploader: DxFileUploaderComponent;

  @ViewChild(DxDataGridComponent, { static: true })
  dataGrid: DxDataGridComponent;

  wawiLists: WawiList[] = [];
  currentWawiList: WawiList;
  newWawiList: WawiList;
  importVisible = false;
  addVisible = false;
  editVisible = false;
  isLoading = false;
  importAsNewList = false;
  exportImportInfoPopupVisible = false;
  exportImportDirectionButtonOptions: any;
  closeButtonOptions: any;

  constructor(public modelService: ModelService, public translate: TranslateService, public wawiListService: WawiListService, private oAuthService: OAuthService) {
    const that = this;
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Wawi Listen");
    this.exportExcel = this.exportExcel.bind(this);
    this.onUploaded = this.onUploaded.bind(this);
    this.startImportListener = this.startImportListener.bind(this);
    this.wawiListSelected = this.wawiListSelected.bind(this);
    this.addWawiList = this.addWawiList.bind(this);
    this.showAdd = this.showAdd.bind(this);
    this.onSaved = this.onSaved.bind(this);
    this.onRowInserting = this.onRowInserting.bind(this);
    this.renameWawiList = this.renameWawiList.bind(this);
    this.showEdit = this.showEdit.bind(this);
    this.delete = this.delete.bind(this);
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);
    this.showExportImportDirectionInfo = this.showExportImportDirectionInfo.bind(this);

    this.reloadWawiLists(undefined);
  }

  get listSelected(): boolean {
    return (this.currentWawiList != undefined);
  }

  get wawiListFields(): WawiListElement[] {
    if (this.currentWawiList != undefined) {
      return this.currentWawiList.fields;
    } else {
      return [];
    }
  }

  get isMapping(): boolean {
    if (this.currentWawiList != undefined) {
      return this.currentWawiList.isMapping;
    } else {
      return false;
    }
  }


  get noDataText() {
    if (this.wawiLists.length == 0)
      return this.translate.instant("keine Listen vorhanden");
    return this.translate.instant("keine Einträge vorhanden");
  }

  get key() {
    if (this.currentWawiList == undefined)
      return "Schlüssel";
    return this.currentWawiList.key;
  }
  get value() {
    if (this.currentWawiList == undefined)
      return "Wert";
    return this.currentWawiList.value;
  }

  onContentToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelImportButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelExportButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'deleteButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'editButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addWawiListsTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'showExportImportDirectionInfoTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'wawiListsTemplate',
    });


  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    if (!this.importAsNewList) {
      e.request.setRequestHeader("currentWawiListId", this.currentWawiList.id);
    }
    e.request.setRequestHeader("customerId", this.modelService.loginService.currentUser?.customerId);
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded() {
    let msg = new Message();
    msg.message = "Import Job wurde gestartet";
    this.modelService.systemService.notify(msg);
    this.startImportListener();
    this.importVisible = false;
  }

  startImportListener() {
    this.modelService.jobService.isJobRunningThatChangesWawiList(this.modelService.loginService.currentCustomer.id).subscribe(isRunning => {
      if (isRunning) {
        setTimeout(this.startImportListener, 2000);
      }
      else
      {
        this.reloadWawiLists(this.currentWawiList);
      }
    })
  }

  reloadWawiLists(newWawiListSelection: WawiList) {
    this.wawiListService.getAllListsWithoutFields(this.modelService.loginService.currentUser.customerId).subscribe(result => {
      this.wawiLists = result;
      if (this.wawiLists.length > 0) {
        let newSelection = this.wawiLists.find(wawiList => this.hasWawiAddon() || wawiList.isMapping);
        if (newWawiListSelection) {
          newSelection = newWawiListSelection;
        }

        this.wawiLists.forEach(wawiList => wawiList.visible = this.hasWawiAddon() || wawiList.isMapping);

        this.wawiListService.getList(newSelection.id, this.modelService.loginService.currentCustomer.id).toPromise()
          .then((data: WawiList) => {
            if (data != undefined) {
              if (data.fields == undefined) {
                data.fields = [];
              }
              var wawiList = this.wawiLists.find(x => x.id == newSelection.id);
              wawiList.fields = data.fields;
              this.currentWawiList = wawiList;
            }
          });
      } else {
        this.currentWawiList = undefined;
      }
    });
  }

  onSaved(e) {
    this.wawiListService.update(this.currentWawiList, this.modelService.loginService.currentCustomer.id).subscribe((data: WawiList) => {
      var wawiList = this.wawiLists.find(x => x.id == data.id);
      wawiList.fields = data.fields;
      this.isLoading = false;
    });
  }

  onRowInserting(e) {
    let i = 0;
    this.wawiListFields.forEach(element => {
      if (element != null && element.id >= i)
        i = element.id + 1;
    });
    e.data.id = i;
  }

  exportExcel() {
    this.wawiListService.startExport(this.modelService.loginService.currentCustomer.id, this.currentWawiList.id).subscribe();
    let msg = new Message();
    msg.message = "Export Job wurde gestartet";
    this.modelService.systemService.notify(msg);
  }

  showImport() {
    this.importAsNewList = false;
    this.importVisible = true;
  }

  wawiListSelected(e) {
    if (!e.selectedItem) return;
    this.wawiListService.getList(e.selectedItem.id, this.modelService.loginService.currentUser.customerId).subscribe((result: WawiList) => {
      if (result) {
        if (result.fields == undefined) {
          result.fields = [];
        }
        var wawiList = this.wawiLists.find(x => x.id == result.id);
        wawiList.fields = result.fields;
        this.currentWawiList = wawiList;
      }
    });
  }

  showAdd() {
    this.newWawiList = new WawiList("", "", "", "", !this.hasWawiAddon(), []);
    this.addVisible = true;
  }

  addWawiList() {
    if (this.newWawiList.listName == "") {
      this.modelService.systemService.notifyInfo("Bitte eine Listennamen angeben!");
      return;
    }
    if (this.newWawiList.value == "" || (this.newWawiList.key == "" && this.newWawiList.isMapping)) {
      this.modelService.systemService.notifyInfo("Bitte Spaltennamen angeben!");
      return;
    }
    
    this.modelService.systemService.notifyInfo("Liste wird erstellt");
    this.wawiListService.create(this.newWawiList, this.modelService.loginService.currentCustomer.id).subscribe((result: WawiList) => {
      this.modelService.systemService.notifyInfo("Liste wurde erstellt");
      this.reloadWawiLists(result);
    });

    this.addVisible = false;
  }


  editList: WawiList;
  showEdit() {
    this.editList = new WawiList("", "", "", "", false, []);
    this.editList.id = this.currentWawiList.id;
    this.editList.key = this.currentWawiList.key;
    this.editList.value = this.currentWawiList.value;
    this.editList.listName = this.currentWawiList.listName;
    this.editList.isMapping = this.currentWawiList.isMapping;
    this.editVisible = true;
  }

  renameWawiList() {
    this.wawiListService.updateOnlyList(this.editList, this.modelService.loginService.currentCustomer.id).subscribe((result: WawiList) => {
      if (result != undefined) {
        this.reloadWawiLists(result);
      }
      this.editVisible = false;
    });
  }

  delete() {
    this.wawiListService.delete(this.currentWawiList.id, this.modelService.loginService.currentCustomer.id).subscribe(() => {
      this.reloadWawiLists(undefined);
    });
  }

  hasWawiAddon() {
    return this.modelService.loginService.hasAddonWawiErp;
  }

  showExportImportDirectionInfo() {
    this.exportImportInfoPopupVisible = true;
  }
}
