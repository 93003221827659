export class Clipboard {

  constructor(
    public id?: string,
    public userId?: string,
    public isCut?: boolean,
    public isMaster?: boolean,
    public customerId?: string,
    public objectId?: string,
    public content?: string
  ) {
  }
}
