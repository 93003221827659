export class FieldNames {

  constructor(
    public descriptionShort: string = "DESCRIPTION_SHORT",
    public supplierPid: string = "SUPPLIER_PID",
    public supplierAltPid: string = "SUPPLIER_ALT_PID",
    public buyerAid: string = "BUYER_AID",
    public descriptionLong: string = "DESCRIPTION_LONG",
    public descriptionLongWithoutHtml: string = "DESC_WITHOUT_HTML",
    public manufacturerTypeDescription: string = "MANUFACTURER_TYPE_DESCR",
    public internatonalPid: string = "INTERNATIONAL_PID",
    public internatonalPidType: string = "INTERNATIONAL_PID_TYPE",
    public group: string = "GROUP_STRING",
    public remarks: string = "REMARKS",
    public manufacturerPID: string = "MANUFACTURER_PID",
    public manufacturerName: string = "MANUFACTURER_NAME",
    public keywords: string = "KEYWORDS",
    public productOrder: string = "PRODUCT_ORDER",
    public udx: string = "UDX",
    public productReferences: string = "PRODUCT_REFERENCES",
    public productReferencesAccessories: string = "PRODUCT_REFERENCES_accessories",
    public productReferencesSparePart: string = "PRODUCT_REFERENCES_sparepart",
    public productReferencesSimilar: string = "PRODUCT_REFERENCES_similar",
    public productReferencesFollowUp: string = "PRODUCT_REFERENCES_followup",
    public productReferencesMandatory: string = "PRODUCT_REFERENCES_mandatory",
    public productReferencesOthers: string = "PRODUCT_REFERENCES_others",
    public productReferencesSelect: string = "PRODUCT_REFERENCES_select",
    public productReferencesConsitsOf: string = "PRODUCT_REFERENCES_consists_of",
    public productReferencesBaseProduct: string = "PRODUCT_REFERENCES_base_product",
    public productReferencesDiffOrderUnit: string = "PRODUCT_REFERENCES_diff_order_Unit",
    public status: string = "STATUS",
    public statusUpdateMode: string = "STATUS_UPDATE_MODE",
    public master: string = "MASTER", //SUPPLIER_PID des Master | MASTER | leer


    public edeNumber1: string = "EDE_NUMBER_1",
    public edeNumber2: string = "EDE_NUMBER_2",
    public edeNumber3: string = "EDE_NUMBER_3",
    public edeNumber4: string = "EDE_NUMBER_4",
    public edeNumber5: string = "EDE_NUMBER_5",

    public customsNumber: string = "CUSTOMS_NUMBER",
    public countryOfOrgin: string = "COUNTRY_OF_ORIGIN",
    public weight: string = "WEIGHT",
    public lenght: string = "LENGTH",
    public width: string = "WIDTH",
    public depth: string = "DEPTH",
    public hazmatClass: string = "HAZMAT_ClASS",
    public warrantyClass: string = "WARRANTY_ClASS",

    public specialTreatmentClass: string = "SPECIAL_TREATMENT_CLASS",
    public specialTreatmentClassType: string = "SPECIAL_TREATMENT_CLASS_TYPE",

    public erpGroupBuyer: string = "ERP_GROUP_BUYER",
    public erpGroupSupplier: string = "ERP_GROUP_SUPPLIER",

    public orderDetail: string = "ORDER_DETAIL",
    public deliveryTime: string = "DELIVERY_TIME",
    public orderUnit: string = "ORDER_UNIT",
    public contentUnit: string = "CONTENT_UNIT",
    public noCuPerOu: string = "NO_CU_PER_OU",
    public priceQuantity: string = "PRICE_QUANTITY",
    public quantityMin: string = "QUANTITY_MIN",
    public quantityMax: string = "QUANTITY_MAX",
    public quanityInterval: string = "QUANTITY_INTERVAL",

    public contentQuantity: string = "CONTENT_QUANTITY",
    public contentPackage: string = "CONTENT_PACKAGE",
    public basicQuantity: string = "BASIC_QUANTITY",
    public hasBasicPriceDuty: string = "HAS_BASEPRICE_DUTY",
    public isBulkyGood: string = "IS_BULKY_GOOD",
    public isTruckageCompanyGood: string = "IS_TRUCKAGE_COMPANY_GOOD",

    public priceLists: string = "PRICE_LISTS",
    public prices: string = "PRICES",
    public startDate: string = "DATETIME_START_DATE",
    public startTime: string = "DATETIME_START_TIME",
    public endDate: string = "DATETIME_END_DATE",
    public endTime: string = "DATETIME_END_TIME",
    public isDailyPrice: string = "IS_DAILY_PRICE",
    public priceType: string = "PRICE_TYPE",
    public priceAmount: string = "PRICE_AMOUNT",
    public priceFactor: string = "PRICE_FACTOR",
    public priceCurrency: string = "PRICE_CURRENCY",
    public lowerBound: string = "LOWER_BOUND",
    public tax: string = "TAX",
    public territory: string = "TERRITORY",
    public costOfLivingAdjustment: string = "COST_OF_LIVING_ADJUSTMENT",
    public isDiscontinued: string = "IS_DISCONTINUED",
    public discontinuedDate: string = "DISCONTINUED_DATE",

    public basePrice: string = "BASE_PRICE",

    public featureTableList: string = "FEATURE_SYSTEM_TABLE", // Alle Features EINES Systems
    public featureList: string = "FEATURE_SYSTEM_LIST",       // Alle Features EINES Systems
    public featureListComplete: string = "FEATURE_LIST",      // Alle Features ALLER Systeme
    public featureSystem: string = "REFERENCE_FEATURE_SYSTEM",
    public featureSystemName: string = "REFERENCE_FEATURE_SYSTEM_NAME",
    public featureSystemGroupId: string = "REFERENCE_FEATURE_GROUP_ID",
    public featureSystemGroupId2: string = "REFERENCE_FEATURE_GROUP_ID2",
    public featureSystemGroupName: string = "REFERENCE_FEATURE_GROUP_NAME",
    public featureSystemOrder: string = "FS_ORDER",
    public fOrder: string = "FORDER",
    public fName: string = "FNAME",
    public fValue: string = "FVALUE",
    public fUnit: string = "FUNIT",
    public fDescription: string = "FDESCR",
    public fValueDetails: string = "FVALUE_DETAILS",
    public featurePrintOrder: string = "FEATURE_PRINT_ORDER",
    public isVariant: string = "ISVARIANT",

    public mimes: string = "MIMES",
    public mimeType: string = "MIME_TYPE",
    public mimeSource: string = "MIME_SOURCE",
    public mimeDescription: string = "MIME_DESCR",
    public mimeAlt: string = "MIME_ALT",
    public mimePurpose: string = "MIME_PURPOSE",
    public mimeLinkSizeNorm: string = "LINK_NORM",
    public mimeExcludeFromShopware6: string = "MIME_EXCLUDE_FROM_SHOPWARE6",

    public productReferencesType: string = "PRODUCT_REFERENCES_TYPE",     // Dieses Feld ist nicht für den Excel Ex-/Import (über "Felder") vorgesehen. Es sollte kein ExcelTemplateItemTemplate dafür definiert werden.
    public productReferencesSpid: string = "PRODUCT_REFERENCES_SPID",     // Dieses Feld ist nicht für den Excel Ex-/Import (über "Felder") vorgesehen. Es sollte kein ExcelTemplateItemTemplate dafür definiert werden.

    public udxCategory: string = "UDX_CATEGORY",                          // Dieses Feld ist nicht für den Excel Ex-/Import (über "Felder") vorgesehen. Es sollte kein ExcelTemplateItemTemplate dafür definiert werden.
    public udxName: string = "UDX_NAME",                                  // Dieses Feld ist nicht für den Excel Ex-/Import (über "Felder") vorgesehen. Es sollte kein ExcelTemplateItemTemplate dafür definiert werden.
    public udxValue: string = "UDX_VALUE",                                // Dieses Feld ist nicht für den Excel Ex-/Import (über "Felder") vorgesehen. Es sollte kein ExcelTemplateItemTemplate dafür definiert werden.

    public metaDescription: string = "META_DESCRIPTION",
    public metaTitel: string = "META_TITLE",
    public featureUpdateMode: string = "FEATURE_UPDATE_MODE",
    public udxUpdateMode: string = "UDX_UPDATE_MODE",
    public mimeUpdateMode: string = "MIME_UPDATE_MODE",
    public priceUpdateMode: string = "PRICE_UPDATE_MODE",
    public supplierUpdateMode: string = "SUPPLIER_UPDATE_MODE",
    public categoryUpdateMode: string = "CATEGORY_UPDATE_MODE",

    public isMimeChanged: string = "IS_MIME_CHANGED",
    public isLogisticChanged: string = "IS_LOGISTIC_CHANGED",
    public isFeatureChanged: string = "IS_FEATURE_CHANGED",
    public isUdxChanged: string = "IS_UDX_CHANGED",
    public isOrderDetailChanged: string = "IS_ORDERDETAIL_CHANGED",
    public isKeywordChanged: string = "IS_KEYWORD_CHANGED",
    public isReferencesChanged: string = "IS_REFERENCES_CHANGED",
    public isBaseProductChanged: string = "IS_BASEPRODUCT_CHANGED",
    public isDeleted: string = "IS_DELETED",
    public isNew: string = "IS_NEW",


    public color: string = "COLOR",
    public material: string = "MATERIAL",
    public pattern: string = "PATTERN",
    public size: string = "SIZE",
    public energieEfficentClass: string = "ENERGIE_EFFICENT_CLASS",
    public ageGroup: string = "AGE_GROUP",
    public gender: string = "GENDER",
    public materialComposition: string = "MATERIAL_COMPOSITION",
    public shoeSize: string = "SHOE_SIZE",
    public dressSize: string = "DRESS_SIZE",
    public ebayCategory: string = "EBAY_CATEGORY",
    public epid: string = "EPID",
    public amazonAsin: string = "AMAZON_ASIN",
    public googleProductCategory: string = "GOOGLE_PRODUCT_CATEGORY",
    public ebayCategory2: string = "EBAY_CATEGORY_2",

    public googleSalesPrice: string = "GOOGLE_SALES_PRICE",
    public conradCategory: string = "CONRAD_CATEGORY",
    public rakutenCategory: string = "RAKUTEN_CATEGORY",
    public realCategory: string = "REAL_CATEGORY",
    public manoManocategory: string = "MANO_MANO_CATEGORY",
    public crowdfoxCategory: string = "CROWDFOX_CATEGORY",
    public crowdfoxB2BCategory: string = "CROWDFOX_B2B_CATEGORY",
    public bazzarCategory: string = "BAZZAR_CATEGORY",
    public yategoCategory: string = "YATEGO_CATEGORY",
    public yategoTopOffer: string = "YATEGO_TOP_OFFER",
    public yategoDeliverySurcharge: string = "YATEGO_DELIVERY_SURCHARGE",
    public yategoCalcOnce: string = "YATEGO_CALC_ONCE",
    public portal1Category: string = "PORTAL_1_CATEGORY",
    public portal2Category: string = "PORTAL_2_CATEGORY",
    public portal3Category: string = "PORTAL_3_CATEGORY",
    public portal4Category: string = "PORTAL_4_CATEGORY",
    public portal5Category: string = "PORTAL_5_CATEGORY",


    public alloySurcharge: string = "ALLOY_SURCHARGE",
    public isBiocidalProduct: string = "IS_BIOCIDAL_PRODUCT",
    public isSelfServiceForbidden: string = "IS_SELFSERVICE_FORBIDDEN",
    public isFertigpackv: string = "IS_FERTIGPACKV",
    public isOekodesignEvpg: string = "IS_OEKODESIGN_EVPG_ENVKG",
    public isCommoditiesControll: string = "IS_COMMODITIES_CONTROLL",
    public isDetergenzienv: string = "IS_DETERGENZIENV",
    public isKosmetikv: string = "IS_KOSMETIKV",
    public isCeGs: string = "IS_CE_GS",
    public isWeeeRohsEar: string = "IS_WEEE_ROHS_EAR",
    public isReach: string = "IS_REACH",
    public isVerpackungsv: string = "IS_VERPACKUNGSV",
    public isSecurityDatasheetNeeded: string = "IS_SECURITY_DATASHEET_NEEDED",
    public isDualUse: string = "IS_DUAL_USE",
    public isBatterieV: string = "IS_BATTERIE_V",
    public warrantyClassMonth: string = "WARRANTY_CLASS_MONTH",
    public typeModel: string = "TYPE_MODEL",
    public tradeMark: string = "TRADEMARK",

    public isDurabilityProduct: string = "IS_DURABILITY_PRODUCT",
    public durabilityDays: string = "DURABILITY_DAYS",

    public wawiName: string = "WAWI_NAME",
    public firstSupplierPid: string = "FIRST_SUPPLIER_PID",
    public currentSupplierPid: string = "CURRENT_SUPPLIER_PID",
    public wawiLenght: string = "WAWILENGTH",
    public prefix: string = "PREFIX",
    public postfix: string = "POSTFIX",
    public line1: string = "LINE_1",
    public line2: string = "LINE_2",
    public line3: string = "LINE_3",
    public line4: string = "LINE_4",
    public customField: string = "CUSTOM_FIELD",

    public link: string = "PRODUCT_LINK",

    public mainSupplierId: string = "MAINSUPPLIER_ID",

    public suppliers: string = "SUPPLIER_LIST",
    public supplierOrder: string = "SUPPLIER_ORDER",
    public supplierId: string = "SUPPLIER_ID",
    public supplierName: string = "SUPPLIER_NAME",
    public pid: string = "PID",
    public supplierInternationalId: string = "SUPPLIER_INTERNATIONAL_ID",
    public supplierInternationalIdType: string = "SUPPLIER_INTERNATIONAL_ID_TYP",
    public supplierProductDescription: string = "SUPPLIER_PRODUCT_DESC",
    public supplierProductDescriptionShort: string = "SUPPLIER_DESC_SHORT",
    public supplierDeliveryTime: string = "SUPPLIER_DELIVERY_TIME",
    public supplierPrice: string = "SUPPLIER_PRICE",
    public supplierValidFrom: string = "SUPPLIER_VALID_FROM",
    public supplierValidUntil: string = "SUPPLIER_VALID_UNTIL",

    public packOrder: string = "PACK_ORDER",
    public packDepth: string = "PACK_DEPTH",
    public packLength: string = "PACK_LENGTH",
    public packWeight: string = "PACK_WEIGHT",
    public packWidth: string = "PACK_WIDTH",    
    public packInternationalPid: string = "PACK_INTERNATIONAL_PID",
    public packOrderUnit: string = "PACK_ORDERUNIT",
    public packContentQuantity: string = "PACK_CONTENT_QUANTITY",


    public supplierContentUnitCode: string = "SUPPLIER_CONTENT_UNIT_CODE",
    public supplierPackingAmount: string = "SUPPLIER_PACKING_AMOUNT",
    public supplierOrderIntervall: string = "SUPPLIER_ORDER_INTERVALL",
    public supplierMinOrderAmount: string = "SUPPLIER_MIN_ORDER_AMOUNT",
    public supplierDiscontinuedProduct: string = "SUPPLIER_DISCONTINUED_PRODUCT",
    public supplierDiscontinuedDate: string = "SUPPLIER_DISCONTINUED_DATE",

    public supplierDiscountGroupCode: string = "SUPPLIER_DISCOUNT_GROUP_CODE",
    public supplierPricingUnitCode: string = "SUPPLIER_PRICE_UNIT_CODE",
    public supplierPriceAllocationUnitCode: string = "SUPPLIER_PRICE_ALLOCATION_UNIT_CODE",
    public supplierPriceAllocationUnitCodeOriginal: string = "SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL",

    public packingUnit: string = "PACKING_UNIT",
    public packingUnitCode: string = "PACKING_UNIT_CODE",
    public packingUnitDescr: string = "PACKING_UNIT_DESCR",
    public packingUnitQuantityMax: string = "PACKING_UNIT_QUANTITY_MAX",
    public packingUnitQuantityMin: string = "PACKING_UNIT_QUANTITY_MIN",
    public packingUnitAmountContentUnit: string = "PACKING_UNIT_AMOUNT_CONTENT_UNIT",
    public packingUnitAmountSmallerUnit: string = "PACKING_UNIT_AMOUNT_SMALLER_UNIT",

    public electronicSalesNotReturnable: string = "ELECTRONIC_SALES_NOTRETURNABLE",
    public electronicSalesNotDiscountable: string = "ELECTRONIC_SALES_NOTDISCOUNTABLE",
    public electronicSalesNotOrderable: string = "ELECTRONIC_SALES_NOTORDERABLE",
    public electronicSalesNotRequestable: string = "ELECTRONIC_SALES_NOTREQUESTABLE",
    public electronicSalesLocked: string = "ELECTRONIC_SALES_LOCKED",
    public electronicSalesOnlyOffline: string = "ELECTRONIC_SALES_ONLYOFFLINE",

    public electronicSalesDateCreated: string = "ELECTRONIC_SALES_DATE_CREATED",
    public electronicSalesDescriptionLong: string = "ELECTRONIC_SALES_DESCRIPTION_LONG",
    public electronicSalesDescriptionMarketing: string = "ELECTRONIC_SALES_DESCRIPTION_MARKETING",
    public electronicSalesPromoInfo: string = "ELECTRONIC_SALES_PROMO_INFO",
    public electronicSalesNoteCustomer: string = "ELECTRONIC_SALES_NOTE_CUSTOMER",
    public electronicSalesSeoUrlFilename: string = "ELECTRONIC_SALES_SEOURL_FILENAME",
    
    public electronicSalesFSK: string = "ELECTRONIC_SALES_FSK",
    public electronicSalesEUDataSheet: string = "ELECTRONIC_SALES_EUDATASHEET",
    public electronicSalesEEK: string = "ELECTRONIC_SALES_EEK",
    public electronicSalesEELabel: string = "ELECTRONIC_SALES_EELABEL",
    public electronicSalesEEKColor: string = "ELECTRONIC_SALES_EEK_COLOR",
    
    public electronicSalesStockKeeping: string = "ELECTRONIC_SALES_STOCKKEEPING",
    public electronicSalesStockLocation: string = "ELECTRONIC_SALES_STOCK_LOCATION",
    public electronicSalesStockUnit: string = "ELECTRONIC_SALES_STOCK_UNIT",
    public electronicSalesStockInfoText: string = "ELECTRONIC_SALES_STOCK_INFOTEXT",
    public electronicSalesStockKeepingModus: string = "ELECTRONIC_SALES_STOCKKEEPING_MODUS",
    public electronicSalesStockPriceFactor: string = "ELECTRONIC_SALES_STOCK_PRICE_FACTOR",
    public electronicSalesNoStockInfoText: string = "ELECTRONIC_SALES_NOSTOCK_INFOTEXT",

    public electronicSalesSurCharges1ArtNr: string = "ELECTRONIC_SALES_SURCHARGES1_ARTNR",
    public electronicSalesSurCharges1Percent: string = "ELECTRONIC_SALES_SURCHARGES1_PERCENT",
    public electronicSalesSurCharges1FixValue: string = "ELECTRONIC_SALES_SURCHARGES1_FIXVALUE",
    public electronicSalesSurCharges2ArtNr: string = "ELECTRONIC_SALES_SURCHARGES2_ARTNR",
    public electronicSalesSurCharges2Percent: string = "ELECTRONIC_SALES_SURCHARGES2_PERCENT",
    public electronicSalesSurCharges2FixValue: string = "ELECTRONIC_SALES_SURCHARGES2_FIXVALUE",
    public electronicSalesCharges: string = "ELECTRONIC_SALES_CHARGES",
    
    public electronicSalesOrderDecimalPlaces: string = "ELECTRONIC_SALES_ORDER_DECIMAL_PLACES",
    public electronicSalesOrderFactor: string = "ELECTRONIC_SALES_ORDER_FACTOR",
    public electronicSalesOrderStockFactor: string = "ELECTRONIC_SALES_ORDER_STOCK_FACTOR",
    public electronicSalesOrderPriceFactor: string = "ELECTRONIC_SALES_ORDER_PRICE_FACTOR",
    
    public electronicSalesPriceInfo: string = "ELECTRONIC_SALES_PRICE_INFO",
    public electronicSalesNewInShopTime: string = "ELECTRONIC_SALES_NEWINSHOP_TIME",
    public electronicSalesAlternateArticleNumber: string = "ELECTRONIC_SALES_ALTERNATE_ARTICLENUMBER",
    public electronicSalesMaterialGroup: string = "ELECTRONIC_SALES_MATERIAL_GROUP",

  ) {
    this.electronicSalesBoolMapping = new Map(
      [
        [this.electronicSalesNotReturnable, 'notReturnable'],
        [this.electronicSalesNotDiscountable, 'notDiscountable'],
        [this.electronicSalesNotOrderable, 'notOrderable'],
        [this.electronicSalesNotRequestable, 'notRequestable'],
        [this.electronicSalesLocked, 'locked'],
        [this.electronicSalesOnlyOffline, 'onlyOffline'],
      ]
    );
    this.electronicSalesNumberMapping = new Map(
      [
        [this.electronicSalesOrderDecimalPlaces, 'orderDecimalPlaces'],
        [this.electronicSalesOrderFactor, 'orderFactor'],
        [this.electronicSalesOrderStockFactor, 'orderStockFactor'],
        [this.electronicSalesOrderPriceFactor, 'orderPriceFactor'],
      ]
    );
    this.electronicSalesStringMapping = new Map(
      [
        [this.electronicSalesDescriptionMarketing, 'descriptionMarketing'],
        [this.electronicSalesSeoUrlFilename, 'seoUrlFilename'],
        [this.electronicSalesPromoInfo, 'promoInfo'],
        [this.electronicSalesFSK, 'fsk'],
        [this.electronicSalesEUDataSheet, 'euDataSheet'],
        [this.electronicSalesEEK, 'eek'],
        [this.electronicSalesEELabel, 'eeLabel'],
        [this.electronicSalesEEKColor, 'eekColor'],
        [this.electronicSalesNoteCustomer, 'noteCustomer'],
        [this.electronicSalesStockKeeping, 'stockKeeping'],
        [this.electronicSalesStockLocation, 'stockLocation'],
        [this.electronicSalesStockUnit, 'stockUnit'],
        [this.electronicSalesStockInfoText, 'stockInfoText'],
        [this.electronicSalesStockKeepingModus, 'stockKeepingModus'],
        [this.electronicSalesStockPriceFactor, 'stockPriceFactor'],
        [this.electronicSalesNoStockInfoText, 'noStockInfoText'],
        [this.electronicSalesSurCharges1ArtNr, 'surCharges1ArtNr'],
        [this.electronicSalesSurCharges1Percent, 'surCharges1Percent'],
        [this.electronicSalesSurCharges1FixValue, 'surCharges1FixValue'],
        [this.electronicSalesSurCharges2ArtNr, 'surCharges2ArtNr'],
        [this.electronicSalesSurCharges2Percent, 'surCharges2Percent'],
        [this.electronicSalesSurCharges2FixValue, 'surCharges2FixValue'],
        [this.electronicSalesCharges, 'charges'],
        [this.electronicSalesNewInShopTime, 'newInShopTime'],
        [this.electronicSalesAlternateArticleNumber, 'alternateArticleNumber'],
        [this.electronicSalesMaterialGroup, 'materialGroup'],
      ]
    );
  }

  electronicSalesBoolMapping: Map<string, string>;
  electronicSalesNumberMapping: Map<string, string>;
  electronicSalesStringMapping: Map<string, string>;
}

export const fieldNames = new FieldNames();
export const excelFieldNames = Object.values(fieldNames).filter(x => typeof x === 'string');
