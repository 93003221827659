<np-dialog
  [popupWidth]="1200"
  [popupHeight]="800"
  [popupTitle]="'JobFiles' | translate"
  [(popupVisible)]="visible">  
  <dx-data-grid slot="content"
                class="w-100 h-100"
                [dataSource]="dataSource"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                noDataText="{{ 'keine Files im Account' | translate }}"
                [showBorders]="true">

    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true"></dxo-remote-operations>
    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="false" [allowedPageSizes]="[15]"></dxo-pager>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="JobDataStateNewUIV2"></dxo-state-storing>

    <dxi-column dataField="mimeSource" caption="{{ 'Dateiname' | translate }}" cellTemplate="nameCellTemplate"></dxi-column>
    <div *dxTemplate="let data of 'nameCellTemplate'">
      <a href="{{data.data.blobUri}}" target="_blank">{{data.value}}</a>
    </div>
    <dxi-column dataField="fileDate" dataType="date" [allowFiltering]="false" [allowSorting]="false" caption="{{ 'Datum' | translate }}"></dxi-column>
    <dxi-column dataField="fileSize" [allowFiltering]="false" [allowSorting]="false" caption="{{ 'Größe' | translate }}" cellTemplate="sizeCellTemplate"></dxi-column>
    <div *dxTemplate="let data of 'sizeCellTemplate'">
      {{getFileSizeString(data.value)}}
    </div>

    <dxi-column [width]="40" cellTemplate="editCellTemplate"></dxi-column>
    <div *dxTemplate="let data of 'editCellTemplate'">
      <a (click)="deleteRequest(data)" class="dx-icon-trash" title="{{ 'Datei löschen' | translate }}"></a>
    </div>
  </dx-data-grid>
  
  <np-dialog-button slot="toolbar" toolbar="bottom" location="after" widget="dxButton" [options]="{ icon: 'refresh', onClick: reload }"></np-dialog-button>
</np-dialog>


