<fullscreen-page>
  <content-column helpSwitch="true" look="light">
    <div slot="content" class="p-2 flex-fill overflow-hidden">
      <dx-data-grid (onToolbarPreparing)="onContentToolbarPreparing($event)"
                    [dataSource]="wawiListFields"
                    keyExpr="id"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    [noDataText]="noDataText"
                    [showBorders]="true"
                    (onRowInserting)="onRowInserting($event)"
                    (onSaved)="onSaved($event)">
        <div *dxTemplate="let toolbaritem of 'showExportImportDirectionInfoTemplate'">
          <dx-button *ngIf="listSelected && isMapping" id="showPopoverInfoButton" stylingMode="text" type="default" icon="info" (onClick)="showExportImportDirectionInfo()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'excelExportButtonTemplate'">
          <dx-button *ngIf="listSelected" stylingMode="text" type="default" icon="export" text="{{ 'Excel Export' | translate }}" (onClick)="exportExcel()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'excelImportButtonTemplate'">
          <dx-button stylingMode="text" type="default" icon="import" text="{{ 'Excel Reimport' | translate }}" (onClick)="showImport()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'wawiListsTemplate'">
          <dx-select-box [(items)]="wawiLists" displayExpr="listName" [searchEnabled]="true" width="250" [value]="currentWawiList" (onSelectionChanged)="wawiListSelected($event)"></dx-select-box>
        </div>
        <div *dxTemplate="let toolbaritem of 'addWawiListsTemplate'">
          <dx-button stylingMode="text" type="default" icon="add" text="{{ 'Neue Liste' | translate }}" (onClick)="showAdd()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'editButtonTemplate'">
          <dx-button *ngIf="listSelected" stylingMode="text" type="default" icon="edit" text="{{ 'Liste bearbeiten' | translate }}" (onClick)="showEdit()"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'deleteButtonTemplate'">
          <dx-button *ngIf="listSelected" stylingMode="text" type="default" icon="trash" text="{{ 'Liste löschen' | translate }}" (onClick)="delete()"></dx-button>
        </div>

        <dxo-toolbar>
          <dxi-item name="addRowButton" showText="always"></dxi-item>
        </dxo-toolbar>

        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Eintrag suchen' | translate }}" width="250"></dxo-search-panel>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-editing mode="row" [allowAdding]="listSelected" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
          <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diesen Eintrag wirklich löschen' | translate }}?"
                     confirmDeleteTitle="{{ 'Eintrag löschen' | translate }}"
                     addRow="{{'Neuen Eintrag erstellen'| translate}}"></dxo-texts>
        </dxo-editing>


        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="WawiListStateV2"></dxo-state-storing>

        <dxi-column *ngIf="isMapping && listSelected" dataField="key" dataType="text" [caption]="key" [allowEditing]="true"></dxi-column>
        <dxi-column *ngIf="listSelected" dataField="value" dataType="text" [caption]="value" [allowEditing]="true"></dxi-column>
      </dx-data-grid>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>

<dx-popup *ngIf="importVisible"
          [width]="600"
          [height]="350"
          [showTitle]="true"
          title="{{ 'Wawi Liste importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importVisible"
          contentTemplate="popupContent2">
  <div *dxTemplate="let data of 'popupContent2'">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label w-50">{{ 'AlsNeueListeImport' | translate }}</div>
        <div class="w-50">
          <dx-switch [(value)]="importAsNewList"></dx-switch>
        </div>
      </div>
    </div>
    <dx-file-uploader #fileUploader
                      name="file"
                      uploadUrl="api/import/ImportWawiListChunky"
                      [multiple]="false"
                      [chunkSize]="10000000"
                      accept=".xlsx"
                      (onUploaded)="onUploaded()"
                      uploadMode="instantly"
                      (onBeforeSend)="onBeforeSendUpload($event)">
    </dx-file-uploader>
  </div>
</dx-popup>


<dx-popup [showTitle]="true"
          title="{{ 'Wawi Liste erstellen' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addVisible"
          contentTemplate="popupContent"
          height="50%"
          width="50%">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Liste Erstellen' | translate, onClick: addWawiList }"></dxi-toolbar-item>

  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newWawiList.listName" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div *ngIf="hasWawiAddon()" class="dx-field">
      <div class="dx-field-label">{{ 'Typ' | translate }}</div>
      <div class="dx-field-value">
        <div class="d-flex flex-row pt-2">
          <div class="dx-field-label switch-left">{{'Liste'| translate}}</div>
          <dx-switch [(value)]="newWawiList.isMapping" switchedOffText="{{'Liste'| translate}}" switchedOnText="{{'Mapping'| translate}}"></dx-switch>
          <div class="dx-field-label switch-right">{{ "Mapping" | translate }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="newWawiList.isMapping" class="dx-field">
      <div class="dx-field-label">{{ 'Schlüssel-Spalten Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newWawiList.key" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Wert-Spalten Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newWawiList.value" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup [showTitle]="true"
          title="{{ 'Wawi Liste bearbeiten' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="editVisible"
          contentTemplate="popupContent3"
          height="50%"
          width="50%">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Aenderungen anwenden' | translate, onClick: renameWawiList }"></dxi-toolbar-item>

  <div *dxTemplate="let data of 'popupContent3'">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="editList.listName" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Typ' | translate }}</div>
      <div class="dx-field-value">
        <div class="d-flex flex-row pt-2">
          <div class="dx-field-label switch-left">{{'Liste'| translate}}</div>
          <dx-switch [(value)]="editList.isMapping" switchedOffText="{{'Liste'| translate}}" switchedOnText="{{'Mapping'| translate}}"></dx-switch>
          <div class="dx-field-label switch-right">{{ "Mapping" | translate }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="editList.isMapping" class="dx-field">
      <div class="dx-field-label">{{ 'Schlüssel-Spalten Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="editList.key" [showClearButton]="true"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Wert-Spalten Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="editList.value" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>
<dx-popover target="#showPopoverInfoButton"
            [width]="400"
            position="top"
            [(visible)]="exportImportInfoPopupVisible">
  <dxo-animation>
    <dxo-show type="pop"
              [from]="{ scale: 0 }"
              [to]="{ scale: 1 }"></dxo-show>
    <dxo-hide type="fade" [from]="1" [to]="0"></dxo-hide>
  </dxo-animation>
  <div *dxTemplate="let data = model; of: 'content'">
    {{ 'ExportImportInfoPopoverTextPart1' | translate }}<br /><br />
    {{ 'ExportImportInfoPopoverTextPart2' | translate }}<br /><br />
    {{ 'ExportImportInfoPopoverTextPart3' | translate }}
  </div>
</dx-popover>
