import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionFeatureSystemsComponent } from "../userAction-featureSystem/userAction-featureSystem.component";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { ValidFeatureService } from "../../../../Services/validFeature.service";
import { ValidFeatureSystem } from "../../../../Model/ValidFeatureSystem";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: "userAction-createTechnicalDetails",
    templateUrl: "userAction-createTechnicalDetails.component.html"
})
export class UserActionCreateTechnicalDetailsComponent extends UserActionFeatureSystemsComponent {
  selectedFeatureSystem: ValidFeatureSystem;

  constructor(public modelService: ModelService, validFeatureSystem: ValidFeatureService, private translate: TranslateService) {
    super(modelService, validFeatureSystem);
  }

  startMacro() {
    if (!this.selectedFeatureSystem) {
      this.modelService.systemService.notifyInfo(this.translate.instant("FeatureSystem auswählen"));
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "CreateTechnicalDetails";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify(this.selectedFeatureSystem);
  }
}
