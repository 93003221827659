import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Catalog } from '../../../Model/Catalog/Catalog';
import { ModelService } from '../../../Services/model.service';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { TestResult } from '../../../Model/Dto/TestResult';
import { Message } from '../../../Model/System/Message';
import { TranslateService } from '@ngx-translate/core';
import { OsgConnection } from '../../../Model/Dto/OsgConnection';
import { EdcImportService } from '../../../Services/edcImport.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'importCatalog',
  templateUrl: 'importCatalog.component.html'
})
export class ImportCatalogComponent {
  @ViewChild(DxFileUploaderComponent)
  dxfileuploader: DxFileUploaderComponent;

  @Output()
  onReimportCatalog = new EventEmitter();

  value: any[] = [];
  model: Catalog[];
  fileTypes: string;
  uploadHeaders: any;
  pages: { id: number; title; template: string }[];
  txtFromOSG: string;
  txtFromLink: string;
  txtUpload: string;
  txtEdeSortiment: string;
  productRanges: string[];
  productRange: string;
  keepRootCategory: boolean = false;
  takeAllCategory: boolean = false;
  _useTest: boolean = false;
  hasOsgTestSystem: boolean = false;
  constructor(
    private oauthService: OAuthService,
    private userIdleService: UserIdleService,
    public modelService: ModelService,
    public translate: TranslateService,
    public edcImportService: EdcImportService
  ) {
    this.testLink = this.testLink.bind(this);
    this.startDownload = this.startDownload.bind(this);
    this.osgCreateJob = this.osgCreateJob.bind(this);
    this.edcImport = this.edcImport.bind(this);

    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);

    this.fileTypes = '.xml,.zip,.xlsx,.csv,.xls';
    this.txtUpload = this.translate.instant('Upload');
    this.txtFromLink = this.translate.instant('Aus Link');
    this.txtFromOSG = this.translate.instant('OSG');
    this.txtEdeSortiment = this.translate.instant('EDC Sortiment');

    this.pages = [
      { id: 1, title: this.txtUpload, template: 'uploadTemplate' },
      { id: 2, title: this.txtFromLink, template: 'linkTemplate' }
    ];

    if (this.modelService.loginService.hasAddonOSG && !this.modelService.catalogService.selectedCatalogId) {
      this.pages.push({ id: 3, title: this.txtFromOSG, template: 'osgTemplate' });
      this.initProductranges();
    }

    if (this.modelService.loginService.hasAddonDEVELOPMENT) {
      this.pages.push({ id: 3, title: this.txtEdeSortiment, template: 'edcTemplate' });
    }

    if (this.modelService.catalogService.catalog) {
      this.uploadHeaders = {
        CatalogId: this.modelService.catalogService.selectedCatalogId,
        CustomerId: this.modelService.loginService.currentUser.customerId
      };
    } else {
      this.uploadHeaders = {
        CustomerId: this.modelService.loginService.currentUser.customerId
      };
    }

    this.modelService.osgConnectionService
      .getConnection(this.modelService.loginService.currentCustomer.id, true)
      .subscribe((result: OsgConnection) => {
        if (result != null && result.shopUrl != null && result.shopUrl.length > 0) {
          this.hasOsgTestSystem = true;
        }
      });
  }

  get useTest(): boolean {
    return this._useTest;
  }
  set useTest(value: boolean) {
    if (value != this._useTest) {
      this._useTest = value;
      this.initProductranges();
    }
  }

  initProductranges() {
    this.productRanges = new Array<string>();
    this.modelService.osgConnectionService
      .getProductRanges(this.modelService.loginService.currentUser.customerId, this.useTest)
      .subscribe((result: string[]) => {
        this.productRanges = result;
      });
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
  }

  onProgress(e: {}) {
    this.userIdleService.stopWatching();
    this.userIdleService.startWatching();
  }

  onUploaded() {
    console.log('onUploaded');
    this.dxfileuploader.instance.reset();
    if (this.modelService.catalogService.importCatalogVisible == true) {
      console.log('Katalog hochgeladen');
      const text = this.translate.instant('Katalog hochgeladen');
      this.modelService.systemService.notify(new Message(text));
    }
    this.modelService.catalogService.requestCatalogChangingJobs();
    this.modelService.catalogService.viewService.jobsVisible = true;
    this.modelService.catalogService.importCatalogVisible = false;
    this.onReimportCatalog.emit();
  }

  fileUrl: string = '';
  fileUrlTest: boolean = false;
  fileUrlTestMessage: string = '';

  testLink() {
    this.fileUrlTest = false;
    var tmp = this.fileUrl.split('?', 1);
    var link = tmp[0];
    link = link.replace('www.dropbox', 'dl.dropbox');
    this.fileUrl = link;

    if (
      !(
        this.fileUrl.substr(-4, 4) == '.zip' ||
        this.fileUrl.substr(-4, 4) == '.xml' ||
        this.fileUrl.substr(-5, 5) == '.xlsx' ||
        this.fileUrl.substr(-4, 4) == '.csv'
      )
    ) {
      this.fileUrlTestMessage = this.translate.instant('Bitte Link zu einer .xml .zip .xlsx oder .csv Datei angeben');

      return;
    }

    this.modelService.systemService
      .testUrl(this.fileUrl)
      .subscribe((result: TestResult) => this.testLinkResult(result));
  }

  linkChanged(event = null) {
    this.fileUrlTest = false;
    this.fileUrlTestMessage = '';
  }

  testLinkResult(result: TestResult) {
    this.fileUrlTest = result.success;
    this.fileUrlTestMessage = result.message;
  }

  startDownload() {
    this.modelService.catalogService.importCatalogVisible = false;
    this.modelService.systemService
      .catalogUrlImport(
        this.fileUrl,
        this.modelService.catalogService.selectedCatalogId,
        this.modelService.loginService.currentCustomer.id
      )
      .subscribe((result: TestResult) => this.downloadStarted(result));
  }

  downloadStarted(result: TestResult) {
    if (result.success) {
      this.fileUrl = '';
      this.fileUrlTest = false;
      this.fileUrlTestMessage = '';

      const text = this.translate.instant('Import als Job gestartet');
      this.modelService.systemService.notify(new Message(text));
      this.modelService.catalogService.requestCatalogChangingJobs();
      this.modelService.catalogService.viewService.jobsVisible = true;
      this.modelService.catalogService.importCatalogVisible = false;
      this.onReimportCatalog.emit();
    } else {
      const text = this.translate.instant('Unbekannter Fehler');
      this.modelService.systemService.notify(new Message(text, 'error'));
    }
  }

  c: boolean[] = [false, false];

  uct(id) {
    if (this.modelService.loginService.showToolTips) {
      this.c[id] = !this.c[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  osgCreateJob() {
    this.modelService.osgConnectionService
      .importCatalog(
        this.productRange,
        this.modelService.loginService.currentUser.customerId,
        this.keepRootCategory,
        this.useTest,
        this.takeAllCategory
      )
      .subscribe((result: boolean) => {
        const text = this.translate.instant('Import als Job gestartet');
        this.modelService.systemService.notify(new Message(text));
      });
  }

  edcImport() {
    this.edcImportService.import(this.modelService.loginService.currentCustomer.id).subscribe((x) => {
      this.modelService.systemService.notify(new Message('Katalog wurde erstellt'));
    });
  }
}
