<div class="dx-fieldset">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Bemerkung' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-area [(value)]="remarks"
                    [height]="130">
      </dx-text-area>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Anheften läuft ab' | translate }}</div>
    <div class="dx-field-value">
      <dx-date-box id="date"
                   [(value)]="pinExpires"
                   displayFormat="dd.MM.yyyy HH:mm:ss"
                   type="datetime"
                   valueChangeEvent="focusout"
                   [showClearButton]="true">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field d-flex flex-row">
    <dx-button stylingMode="text" type="default" text="{{ 'Speichern' | translate }}" (onClick)="onAdd()"></dx-button>
    <dx-button *ngIf="pinned==true" stylingMode="text" type="default" text="{{ 'Löschen' | translate }}" (onClick)="onRemove()"></dx-button>
  </div>
</div>
