import { CatalogPriceList } from './CatalogPriceList';
import { Mime } from './Mime';
import { Buyer } from './Buyer';
import { Supplier } from './Supplier';
import { Agreement } from './Agreement';
import { UdxField } from './UdxField';
import { ClassificationSystem } from '../classification/ClassificationSystem';
import { OsgSegmentMapping } from './OsgSegmentMapping';
import { MavisCatalogMapping } from './mavis/MavisCatalogMapping';
import { ShopWare6Connection } from './ShopWareConnection';
import { FeatureSystem } from './FeatureModels/FeatureSystem';
import { ShopWare5Connection } from './Shopware5Connection';
import { OdooConnection } from './OdooConnection';
import { WawiIntegration } from './WawiIntegration';
import { IntershopConnection } from './IntershopConnection';
import { ElectronicSalesConnection } from './ElectronicSalesConnection';

export class Catalog {
  constructor(
    public id?: string,
    public catalogName?: string,
    public customerId?: string,
    public pimGroup?: string,
    public catalogId?: string,
    public language?: string,
    public lastUpdate?: Date,
    public lastImageUpload?: Date,
    public uniqueProductCount?: number,
    public productCount?: number,
    public categoryCount?: number,
    public buyer: Buyer = new Buyer(),
    public agreement: Agreement = new Agreement(),
    public catalogAlias?: string,
    public catalogVersion?: string,
    public catalogState?: string[],
    public currency?: string,
    public date?: Date,
    public generatorInfo?: string,
    public mimes: Mime[] = [],
    public mimeTemplate: Mime[] = [],
    public featureTemplate: FeatureSystem[] = [],
    public mimeRoot?: string,
    public memo?: string,
    public priceLists: CatalogPriceList[] = [],
    public uploadDate?: string,
    public thumbUri?: string,
    public territories: string[] = [],
    public state?: string,
    public type?: string,
    public supplier: Supplier = new Supplier(),
    public udxs: UdxField[] = [],
    public isPublic?: boolean,
    public isPrint?: boolean,
    public isVirtual?: boolean,
    public isActivId?: boolean,
    public usePrintView?: boolean,
    public shopUrl?: string,
    public shopAuth?: string,
    public startCatId?: number,
    public productPrefix?: string,
    public responsible?: string,
    public note?: string,
    public classificationSystems?: ClassificationSystem[],
    public osgSegmentMapping?: OsgSegmentMapping,
    public mavisMapping?: MavisCatalogMapping,
    public shopWareConnection?: ShopWare6Connection,
    public shopWare5Connection?: ShopWare5Connection,
    public odooConnection?: OdooConnection,
    public intershopConnection?: IntershopConnection,
    public electronicSalesConnection?: ElectronicSalesConnection,
    public wawiTemplate?: WawiIntegration,
    public validationMethodId?: string,
    public isDemoCatalog?: boolean
  ) {}
  public hintsActiv = false; //Wenn true, dann werden Hintsactiviert
  public imagesValid = false;
  public logoValid = false;
  public dataSheetValid = false;
  public iconValid = false;
}
