import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { Product } from '../../Model/Catalog/Product';
import { ViewService } from '../../Services/view.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../Services/userManagment.service';
import { ValidationService } from '../../Services/Validation/validation.service';
import { CustomItemCreatingEvent, SelectionChangedEvent } from 'devextreme/ui/select_box';
import { ProductPriceDetail } from 'app/Model/Catalog/ProductPriceDetail';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { LoginService } from 'app/Services/login.service';
import { ProductService } from 'app/Services/CatalogManagement/product.service';
import { ItemClickEvent } from 'devextreme/ui/list';
import { TranslationService } from 'app/Services/translation.service';
import { calculateBasicPrice, calculateNetPrice } from 'app/ui/utils/prices';

@Component({
  selector: 'orderDetail',
  templateUrl: 'orderDetail.component.html',
  styleUrls: ['./orderDetail.css', 'template.css']
})
export class OrderDetailComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;
  @Input() hasAddonLEGALITY: Boolean = false;
  @Input() hasAddonNordwest: Boolean = false;
  @Input() currentCustomerDefaultLanguage: String = 'deu';

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();

  unitDataSource: { value: string; displayValue: string }[] = [];

  get translatedOrderUnit() {
    return this.model.orderDetail?.orderUnit ? this.translate.instant(this.model.orderDetail.orderUnit ?? '') : '';
  }

  get translatedContentUnit() {
    return this.model.orderDetail?.contentUnit ? this.translate.instant(this.model.orderDetail.contentUnit ?? '') : '';
  }

  get allowDeleting() {
    return !this.templateView;
  }

  get priceFactors() {
    return ViewService.dropDownPriceFactors;
  }
  get lowerBounds() {
    return ViewService.dropDownLowerBounds;
  }
  get territories() {
    return ViewService.dropDownTerritories;
  }
  get zero() {
    return ViewService.dropDownZero;
  }
  get one() {
    return ViewService.dropDownOne;
  }

  constructor(
    public validationService: ValidationService,
    public catalogService: CatalogService,
    public productService: ProductService,
    public translationService: TranslationService,
    public loginService: LoginService,
    public translate: TranslateService,
    public userManagement: UserManagementService
  ) {}

  ngOnInit(): void {
    this.productService?.getOrderUnitList().subscribe((unitList) => {
      this.initializeUnits(unitList);
    });
  }

  initializeUnits(unitList: string[]) {
    for (let i = 0; i < unitList.length; i++) {
      this.addUnitDataSourceItem(unitList[i], `${this.translate.instant(unitList[i])} (${unitList[i]})`);
    }
    if (this.unitDataSource.every((x) => x.value !== this.model.orderDetail.orderUnit)) {
      this.addUnitDataSourceItem(this.model.orderDetail.orderUnit, this.model.orderDetail.orderUnit);
    }
    if (this.unitDataSource.every((x) => x.value !== this.model.orderDetail.contentUnit)) {
      this.addUnitDataSourceItem(this.model.orderDetail.contentUnit, this.model.orderDetail.contentUnit);
    }
    this.uniqueUnitDataSourceItems();
  }

  onCustomItemCreating(event: CustomItemCreatingEvent, propertyName: string) {
    if (this.unitDataSource.every((x) => x.value !== event.text)) {
      this.addUnitDataSourceItem(event.text, event.text);
      this.uniqueUnitDataSourceItems();
    }
    this.model.orderDetail[propertyName] = event.text;
  }

  onItemClick(event: ItemClickEvent<{ value: string; displayValue: string }>, propertyName: string) {
    this.model.orderDetail[propertyName] = event.itemData.value;
  }

  onSelectionChange(event: SelectionChangedEvent, propertyName: string) {
    if ('string' === typeof event.selectedItem) {
      this.model.orderDetail[propertyName] = event.selectedItem;
    } else {
      this.model.orderDetail[propertyName] = event.selectedItem.value;
    }
  }

  private addUnitDataSourceItem(value: string, displayValue: string) {
    this.unitDataSource.push({ value, displayValue });
  }

  private uniqueUnitDataSourceItems() {
    const existingItems = [];
    let i = 0;
    while (this.unitDataSource.length > i) {
      const item = JSON.stringify(this.unitDataSource[i]);
      if (existingItems.includes(item)) {
        this.unitDataSource.splice(i, 1);
      } else {
        existingItems.push(item);
        i++;
      }
    }
    this.unitDataSource = [...this.unitDataSource];
  }

  private getFirstNetListPrice() {
    return (
      this.model.priceLists.flatMap((x) => x.productPriceDetails).find((x) => x.priceType === 'net_list') ??
      new ProductPriceDetail()
    );
  }

  private calculateBasicPrice(priceAmount: number, currency: string) {
    if (priceAmount === null) return `<${this.translate.instant('Preis')}>`;
    return calculateBasicPrice(
      priceAmount,
      this.model.orderDetail.priceQuantityNumber,
      this.model.orderDetail.basicQuantityNumber,
      this.model.orderDetail.contentQuantityNumber
    ).toLocaleString(this.translate.currentLang, { style: 'currency', currency });
  }

  previewBasicQuantity() {
    const detail = this.getFirstNetListPrice();
    const netPrice = calculateNetPrice(detail.priceType, detail.priceAmountNumber, detail.taxRate);
    const price = this.calculateBasicPrice(netPrice * (detail.priceFactorNumber || 1), detail.priceCurrency);
    const quantity = (this.model.orderDetail.basicQuantityNumber || 0).toLocaleString(this.translate.currentLang);
    const unit = this.model.orderDetail?.contentPackage
      ? this.translate.instant(this.model.orderDetail?.contentPackage)
      : `<${this.translate.instant('Inhaltseinheit')}>`;
    return this.translate.instant('netto je Menge / Einheit', {
      basicNetPrice: price,
      priceQuantity: quantity,
      orderUnit: unit,
    });
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    if (!this.model.orderDetail.hasBasicPriceDuty) {
      this.model.orderDetail.contentPackage = undefined;
      this.model.orderDetail.basicQuantityNumber = undefined;
      this.model.orderDetail.contentQuantityNumber = undefined;
    }

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (!event) event = {};
    if (!event.event) event.event = {};

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }

    this.onValueChanged.emit(event);
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  removeBasicPriceDuty() {
    this.model.orderDetail.hasBasicPriceDuty = undefined;
    this.update();
  }

  removeIsTruckageCompanyGood() {
    this.model.orderDetail.isTruckageCompanyGood = undefined;
    this.update();
  }

  removeIsBulkyGood() {
    this.model.orderDetail.isBulkyGood = undefined;
    this.update();
  }

  //ExcelTemplate

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    return false;
  }

  public showClearButton(field: string): boolean {
    return !this.readOnlyForLinkedFields(field) && !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
    //event.forceTreeRefresh = true;

    // SAVE
    this.update(event); //, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
