import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModelService } from "../../../Services/model.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { ProductSearchOptions } from "../../../Model/User/ProductSearchOptions";
import { SearchFields } from "../../../Model/User/SearchFields";
import { FieldNames } from "../../../Model/FieldNames";

@Component({
  selector: "productStateOptions",
  templateUrl: "productStateOptions.component.html",
  styleUrls: ["productStateOptions.component.css"]
})
export class ProductStateOptionsComponent implements OnInit {
  @Input()
  stateValue: string[] = [];

  @Input()
  isTarget = false;

  @Output()
  onValueChanged = new EventEmitter<{ mode: 'or' | 'and'; states: string[] }>();

  get model(): ProductSearchOptions {
    if (this.isTarget) {
      return this.modelService.loginService.currentUser.productSearchOptionsTarget;
    }

    return this.modelService.loginService.currentUser.productSearchOptions;
  }
  set model(value: ProductSearchOptions) {
    if (this.isTarget) {
      this.modelService.loginService.currentUser.productSearchOptionsTarget = value;
    }
    else {
      this.modelService.loginService.currentUser.productSearchOptions = value;
    }
  }

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) { }

  ngOnInit(): void {
    if (!this.model) {
      const fieldNames = new FieldNames();
      this.model = new ProductSearchOptions();
      this.model.searchMode = "Contains";
      this.model.searchStateMode = 'or';
      this.model.searchFields = [
        new SearchFields( fieldNames.supplierPid ),
        new SearchFields( fieldNames.supplierAltPid ),
        new SearchFields( fieldNames.internatonalPid ),
        new SearchFields( fieldNames.descriptionShort ),
      ];
    }
  }

  update() {
    const searchFields = this.model.searchFields.map(s => s.searchField);
    this.modelService.loginService
      .updateSearchFields(
        searchFields, 
        this.model.searchMode, 
        this.modelService.loginService.currentUser.id, 
        this.isTarget, 
        this.model.searchStateMode)
      .subscribe(options => {
        this.model = options;
        this.onValueChanged.emit({
          mode: this.model.searchStateMode,
          states: this.stateValue,
        });
      });
  }

}