<div class="dx-fieldset" style="max-width: 600px;">



  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'FTP-Server' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.ftpServer?.length>0 ? 'placeLabelSmall' : '' }}">sftp-server.nordwest.com </label>
      <dx-text-box [(value)]="model.ftpServer" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
    <div *ngIf="!isManager()" class="dx-field-value">
      {{model.ftpServer}}
    </div>
  </div>




  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Benutzername' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.ftpUser?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Benutzername' | translate }}</label>
      <dx-text-box [(value)]="model.ftpUser" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
    <div *ngIf="!isManager()" class="dx-field-value">
      {{model.ftpUser}}
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Passwort' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.password?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Passwort' | translate }}</label>
      <dx-text-box [(value)]="model.password" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
    <div *ngIf="!isManager()" class="dx-field-value">
      *********************************
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Verzeichnis' | translate }}</div>
    <div *ngIf="isManager()" class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.ftpFolder?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Verzeichnis' | translate }}</label>
      <dx-text-box [(value)]="model.ftpFolder" [showClearButton]="true" (onValueChanged)="update($event)"></dx-text-box>
    </div>
    <div *ngIf="!isManager()" class="dx-field-value">
      {{model.ftpFolder}}
    </div>
  </div>

  

  <dx-toolbar>
    <dxi-item *ngIf="!isTested" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testftpConnection }"></dxi-item>
    <dxi-item *ngIf="isTested" location="before" widget="dxButton" [options]="{ type: 'default', icon:'check',   text:  'Verbindung testen' | translate , onClick: testftpConnection }"></dxi-item>
  </dx-toolbar>

    <div style="color: red;">
      {{connection2ftpMessage}}
    </div>


</div>








