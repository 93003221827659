<div class="h-100 d-flex flex-column">
  <div class="px-2 flex-fill overflow-hidden"> 
    <dx-tree-list class="d-block h-100"
                  [dataSource]="model"
                  keyExpr="id"
                  parentIdExpr="parentId"
                  [showRowLines]="false"
                  [showBorders]="false"
                  rootValue="0"
                  [columnAutoWidth]="true"
                  noDataText="{{ 'keine Datensätze' | translate }}"
                  (onToolbarPreparing)="onGridToolbarPreparing($event)"
                  (onRowUpdated)="onClassTreeChanged($event)"
                  (onRowRemoved)="onClassTreeRemoved($event)"
                  (onRowInserted)="onClassTreeAdded($event)"
                  (onSelectionChanged)="onClassTreeSelectionChanged($event)">
      <dxo-search-panel [visible]="true" width="250"></dxo-search-panel>
      <dxo-selection mode="single"></dxo-selection>
      <div *dxTemplate="let toolbaritem of 'createCatalogTemplate'">
        <dx-button  stylingMode="text" type="default" text="{{ 'Katalog erzeugen' | translate }}" (onClick)="onCreateCatalog()" hint="{{ 'Ein Katalog zu diesem Klassifizierungssystem wird erzeugt' | translate }}"></dx-button>
      </div>


      <dxi-column dataField="number" sortOrder="asc" [width]="150" caption="Nummer"></dxi-column>
      <dxi-column dataField="name" caption="Bezeichnung"></dxi-column>

      <dxo-editing mode="popup"
                   [allowUpdating]="true"
                   [allowDeleting]="true"
                   [allowAdding]="true"
                   [useIcons]="true">

        <dxo-popup title="{{ 'Klassifikationsgruppe' | translate }}"
                   [showTitle]="true"
                   [width]="700"
                   [height]="250">
        </dxo-popup>
      </dxo-editing>
    </dx-tree-list>
  </div>
</div>
