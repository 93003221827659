<div class="inline-elements">
  <dx-select-box
    class="lookup"
    [dataSource]="exportParameters"
    [(value)]="selectedKey"
    displayExpr="name"
    valueExpr="id"
    [ngStyle]="{ width: '100%' }"
    label="Katalog Filter"
    showClearButton="true"
    noDataText="{{ 'Keine Filter vorhanden' | translate }}"
  ></dx-select-box>
  <dx-button *ngIf="selectedExportParameterId" icon="edit" (onClick)="openEditor()"></dx-button>
  <dx-button *ngIf="selectedExportParameterId" icon="trash" (onClick)="deleteConfig()"></dx-button>
  <dx-button icon="add" (onClick)="openCreate()"></dx-button>
</div>

<np-dialog
  [popupWidth]="1400"
  [popupHeight]="800"
  [popupTitle]="'Katalog Filter' | translate"
  [(popupVisible)]="editorVisible"
  [closeOnOutsideClick]="closeEditorConfirmDialog"
>
  <np-help-button>
    <np-help-option
      text="YouTube"
      icon="images/YouTube.svg"
      link="https://www.youtube.com/watch?v=I_H6bf3kB98"
    ></np-help-option>
  </np-help-button>

  <div slot="content" [ngStyle]="{ height: 'inherit' }">
    <exportParameter
      *ngIf="selectedExportParameter"
      [(config)]="selectedExportParameter"
      [onlyUpdateFilter]="onlyUpdateFilter"
      [catalogId]="catalogId"
      [ngStyle]="{ height: 'inherit' }"
      class="d-flex flex-column"
    >
      <dx-button
        (onClick)="saveParameter()"
        stylingMode="text"
        type="default"
        text="{{ 'Speichern' | translate }}"
      ></dx-button>

      <dx-button
        (onClick)="cancelEdit()"
        stylingMode="text"
        type="default"
        text="{{ 'Änderungen verwerfen' | translate }}"
      ></dx-button>
    </exportParameter>
  </div>
</np-dialog>

<dx-popup
  class="popup"
  [width]="600"
  [height]="400"
  [showTitle]="true"
  title="{{ 'Neuer Update Filter' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="addUpdateFilterVisible"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <form (submit)="addUpdateFilter()">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Update FilterName' | translate }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newFilter.name" [showClearButton]="true">
              <dx-validator>
                <dxi-validation-rule
                  type="required"
                  message="{{ 'Bitte geben Sie einen Namen an' | translate }}!"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field d-flex flex-row">
          <dx-button
            stylingMode="text"
            type="default"
            text="{{ 'Anlegen' | translate }}"
            [useSubmitBehavior]="true"
          ></dx-button>
        </div>
      </div>
    </form>
  </div>
</dx-popup>
