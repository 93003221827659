import { Component, OnInit } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { MavisConnection } from '../../Model/Dto/MavisConnection';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { WawiSettings } from "../../Model/User/WawiSettings";
import { CustomField } from "../../Model/User/CustomField";
import { custom } from 'devextreme/ui/dialog';
import { InvoiceService } from "../../Services/invoice.service";
import { InvoiceStatistic } from "../../Model/OpenAi/InvoiceStatistic";
import { InvoiceSettingDto } from "../../Model/Dto/InvoiceSettingDto";
import { HttpClient, HttpHeaders } from "@angular/common/http";



@Component({
  selector: "invoice",
  templateUrl: "invoice.component.html"
})
export class InvoiceComponent {
  invoices: InvoiceStatistic[];
  invoiceSetting: InvoiceSettingDto = new InvoiceSettingDto();

  constructor(public modelService: ModelService, public translate: TranslateService, public invoiceService: InvoiceService, public http: HttpClient) {
    this.update = this.update.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.export = this.export.bind(this);

    invoiceService.getAll(modelService.loginService.currentCustomer.id).subscribe((result: InvoiceStatistic[]) => {
      this.invoices = result;
      this.invoices.forEach(x => x.day = new Date(x.day));
    });
    invoiceService.GetInvoiceSetting(modelService.loginService.currentCustomer.id).subscribe((result: InvoiceSettingDto) => {
      this.invoiceSetting = result;
    });

  }

  public update() {
    this.invoiceService.updateInvoiceSetting(this.modelService.loginService.currentCustomer.id, this.invoiceSetting).subscribe((result: InvoiceSettingDto) => {
      this.invoiceSetting = result;
    });
  }

  public isAdmin() {
    return this.modelService.loginService.isAdmin;
  }

  public export() {
    this.invoiceService.ExportInvoices(this.modelService.loginService.currentCustomer.id).subscribe(result => {
      if (result != null && result != "")
        window.open(result, '_blank');
      else
        this.modelService.systemService.notifyInfo("Es gibt noch keine Rechnungen im Account");
    });
  }
}
