
  <dx-tree-list [dataSource]="dataSource"
                itemsExpr="items"
                keyExpr="field"
                (onRowUpdated)="mappingChanged($event)"
                (onSelectionChanged)="updateExportParameter($event)"
                allowColumnResizing="true"
                [(selectedRowKeys)]="selectedKeys"
                height="inherit">
    <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
    <dxi-column width="35%" caption="Field" [allowEditing]="false" cellTemplate="fieldCellTemplate"></dxi-column>
    <div *dxTemplate="let model of 'fieldCellTemplate'">
      <div style="height:30px;" *ngIf="model.data.parentId">
        <label class="fieldLabel">{{ model.data.field }}</label>
        <div class="fieldText">{{ model.data.translatedField }}</div>
      </div>
      <div *ngIf="!model.data.parentId">
        <div>{{ model.data.field }}</div>
      </div>
    </div>
    <dxi-column dataField="mappingId" caption="Mapping" showEditorAlways="true">
      <dxo-lookup [dataSource]="wawiMappings" displayExpr="listName" valueExpr="id" [allowClearing]="true"></dxo-lookup>
    </dxi-column>
    <dxi-column width="40%" dataField="preview" [allowEditing]="false"></dxi-column>
  </dx-tree-list>
