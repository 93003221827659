import { Component, OnInit } from "@angular/core";


import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../../Services/model.service';
import { AddClassification } from '../../../Model/classification/AddClassification';
import { FeatureSystem } from '../../../Model/Catalog/FeatureModels/FeatureSystem';
import { Category } from '../../../Model/Catalog/Category';
import { StartAction } from '../../../Model/Dto/StartAction';
import { Message } from "../../../Model/System/Message";
import { FeatureValue } from '../../../Model/classification/FeatureValue';
import { UserManagementService } from "../../../Services/userManagment.service";
import { Product } from "../../../Model/Catalog/Product";


@Component({
  selector: "categoryTemplates",
  templateUrl: "categoryTemplates.component.html",
  styleUrls: []
})
export class CategoryTemplatesComponent implements OnInit {

  wawiDummyProduct: Product;


  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {

    this.applyTemplate = this.applyTemplate.bind(this);

    this.wawiDummyProduct = new Product();
    this.wawiDummyProduct.wawi = this.model.wawiTemplate;
  }

  ngOnInit(): void {
    this.translateTexts();
    this.reset();
  }


  txtFeatureTemplate: string;
  txtMediaTemplate: string;
  txtMakroGestarted: string;

  get model(): Category {
    return this.modelService.catalogService.category;
  }

  set model(category: Category) {
    this.modelService.catalogService.category = category;
  }

  updateWawi(event = null) {
    this.model.wawiTemplate = this.wawiDummyProduct.wawi;
    this.categoryUpdate();
  }

  categoryUpdate(event = null) {
    this.modelService.catalogService.updateCategory();
  }
  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getCategory(this.modelService.catalogService.category.id, this.modelService.loginService.currentUser.customerId).subscribe();
    this.wawiDummyProduct.wawi = this.model.wawiTemplate;
  }

  private translateTexts() {

    this.txtFeatureTemplate = this.translate.instant("Merkmale");
    this.txtMediaTemplate = this.translate.instant("Medien");
    this.txtMakroGestarted = this.translate.instant("Die Aktualisierung wurde als Job gestartet");
  }

  pages: { id: number; title: string; template: string }[] = [];
  reset() {
    this.pages = [
      { id: 1, title: this.txtFeatureTemplate, template: "featureTemplate" },
      { id: 2, title: this.txtMediaTemplate, template: "mediaTemplate" },
      { id: 3, title: this.translate.instant("Wawi"), template: "wawiTemplate" }
    ];

  }

  addEcommerceFeatures() {
    this.modelService.catalogService.addEcommerceFeaturesToCategory(this.model).subscribe(
      (category: Category) => {
        this.model.featureTemplate = category.featureTemplate;

      });
  }

  public classificationFeatureValues: FeatureValue[] = [];

  public loadClassificationFeatures(params) {
    this.modelService.classificationService.getFeatureValues(params.name, params.valueSource).subscribe(result => {
      this.classificationFeatureValues = result;
    });
  }

  addClassificationFeatures(event) {
    if (this.modelService.classificationService.selectedClassId == "") return;
    let dto = new AddClassification();
    dto.classificationClassId = this.modelService.classificationService.selectedClassId;
    dto.classificationSystemId = this.modelService.classificationService.selectedSystem.id;
    dto.customerId = this.modelService.catalogService.catalog.customerId;
    dto.categoryId = this.modelService.catalogService.category.id;

    this.modelService.classificationService.addClassification(dto).subscribe(
      (result: FeatureSystem[]) => {
        this.modelService.catalogService.category.featureTemplate = new Array<FeatureSystem>();

        let that = this;
        result.forEach(function (r) {
          let fs = Object.assign(new FeatureSystem(), r);
          that.modelService.catalogService.category.featureTemplate.push(fs);
        });
      });
  }



  applyTemplate() {


    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "ApplyProductTemplate";

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.modelService.systemService.notify(new Message(this.txtMakroGestarted));
      });
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
