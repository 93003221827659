<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'OrganisationId' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">ORGANISATIONID</label>
      <dx-text-box [(value)]="model.organisationId"
                   [readOnly]="readOnlyUser('intershop')"
                   [showClearButton]="true"
                   (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'ChannelId' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">CHANNELID</label>
      <dx-text-box [(value)]="model.channelId"
                   [readOnly]="readOnlyUser('intershop')"
                   [showClearButton]="true"
                   (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'UpdateMode' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">UPDATEMODE</label>
      <dx-select-box [items]="['UPDATE','REPLACE']"
                     [(value)]="model.updateMode"
                     [readOnly]="readOnlyUser('intershop')"
                     (onValueChanged)="valueChanged($event)">
      </dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Export Medien' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">Export Medien</label>
      <dx-switch [(value)]="model.exportMedia"
                 [readOnly]="readOnlyUser('intershop')"
                 (onValueChanged)="valueChanged($event)">
      </dx-switch>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'FtpServer' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">FTP SERVER</label>
      <dx-select-box [items]="ftpServers"
                     [(value)]="model.ftpServerId"
                     valueExpr="id"
                     displayExpr="name"
                     [readOnly]="readOnlyUser('intershop')"
                     (onValueChanged)="valueChanged($event)"
                     [showClearButton]="true">
      </dx-select-box>
    </div>
  </div>


  <div *ngIf="model.ftpServerId && model.ftpServerId != ''">
    <div class="dx-field">
      <div class="dx-field-label dx-field-label200">{{ 'Produkt XML Pfad' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">Produkt XML Pfad</label>
        <dx-text-box [(value)]="model.productFilePath"
                     [readOnly]="readOnlyUser('intershop')"
                     [showClearButton]="true"
                     (onValueChanged)="valueChanged($event)"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label dx-field-label200">{{ 'Kategory XML Pfad' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">Kategorie XML Pfad</label>
        <dx-text-box [(value)]="model.categoryFilePath"
                     [readOnly]="readOnlyUser('intershop')"
                     [showClearButton]="true"
                     (onValueChanged)="valueChanged($event)"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label dx-field-label200">{{ 'Attrubutes XML Pfad' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.attributesFilePath?.length>0 ? 'placeLabelSmall' : '' }}">Attribut XML Pfad</label>
        <dx-text-box [(value)]="model.attributesFilePath"
                     [readOnly]="readOnlyUser('intershop')"
                     [showClearButton]="true"
                     (onValueChanged)="valueChanged($event)"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label dx-field-label200">{{ 'Media Pfad' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.mediaFilePath?.length>0 ? 'placeLabelSmall' : '' }}">Media Pfad</label>
        <dx-text-box [(value)]="model.mediaFilePath"
                     [readOnly]="readOnlyUser('intershop')"
                     [showClearButton]="true"
                     (onValueChanged)="valueChanged($event)"></dx-text-box>
      </div>
    </div>

  </div>

</div>
