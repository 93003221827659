<fullscreen-page>
  <navigation-column>
    <div class="h-100 d-flex flex-column">              
      <div class="p-2 flex-fill overflow-hidden">
        <dx-data-grid class="h-100"
                      (onToolbarPreparing)="onToolbarPreparing($event)"
                      [dataSource]="model"
                      [remoteOperations]="false"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [allowColumnResizing]="true"
                      noDataText="{{ 'keine Garantieklasse im Account' | translate }}"
                      [showBorders]="true"
                      (onRowClick)="onSelectionChangedClick($event)"
                      (onRowRemoving)="onDeleteClicked($event)">
          <div *dxTemplate="let toolbaritem of 'newClassButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Neue Garantieklasse' | translate }}" (onClick)="addClass()"></dx-button>
          </div>
          <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="false" [useIcons]="true"></dxo-editing>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Garantieklasse suchen' | translate }}"></dxo-search-panel>

          <dxo-state-storing [enabled]="true" type="localStorage" storageKey="WarrantyClassesStateNewUIV2"></dxo-state-storing>

          <dxi-column dataField="name" caption="{{ 'Name' | translate }}"></dxi-column>

        </dx-data-grid>
      </div>
    </div>
  </navigation-column>
  <content-column helpSwitch="true" look="light">
    <div slot="content" class="h-100 px-3">
      <dx-scroll-view *ngIf="modelService.warrantyClassService.selectedClass" showScrollbar="always">
        <warrantyclass></warrantyclass>        
      </dx-scroll-view>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>
