export class WawiList {
  constructor(
    public id: string,
    public listName: string,
    public key: string,
    public value: string,
    public isMapping: boolean,
    public fields: WawiListElement[],
    public visible?: boolean
  ) { }
}

export class WawiListElement {
  constructor(
    public id: number,
    public key: string,
    public value: string
  ) { }
}
