<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field ">
    <div class="dx-field-label">{{ 'Öffentlich' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch (onValueChanged)="catalogUpdate($event)" [(value)]="model.isPublic" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
    </div>
  </div>
  <div *ngIf="model.isPublic">
    <div class="dx-field">
      <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(10)" (mouseleave)="ttt(10)">{{ 'Shop URL' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt10">
        <label class="placeLabel {{ model.shopUrl?.length>0 ? 'placeLabelSmall' : '' }}">SHOP_URL</label>
        <dx-text-box [(value)]="model.shopUrl" [readOnly]="readOnlyUser('showareUrl')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
        </dx-text-box>

      </div>
      <dx-tooltip target="#tt10" class="toolTip toolTipField" [(visible)]="v[10]">
        <div *dxTemplate="let data = data of 'content'">
          {{ 'Die Internetadresse des Shopware Shops' | translate }}.
        </div>
      </dx-tooltip>
    </div>
    <div class="dx-field">
      <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(11)" (mouseleave)="ttt(11)">{{ 'Shop Token' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
        <label class="placeLabel {{ model.shopAuth?.length>0 ? 'placeLabelSmall' : '' }}">SHOP_TOKEN</label>
        <dx-text-box [(value)]="model.shopAuth" [readOnly]="readOnlyUser('showareToken')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
        </dx-text-box>

      </div>
      <dx-tooltip target="#tt11" class="toolTip toolTipField" [(visible)]="v[11]">
        <div *dxTemplate="let data = data of 'content'">
          {{ 'Der Autorisierungs Token' | translate }}
        </div>
      </dx-tooltip>
    </div>

    <div class="dx-field">
      <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(12)" (mouseleave)="ttt(12)">{{ 'Start Kategorie' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt12">
        <label class="placeLabel {{ model.startCatId > 0 ? 'placeLabelSmall' : '' }}">CATEGORY_ID</label>
        <dx-number-box [(value)]="model.startCatId" [readOnly]="readOnlyUser('showareCategorieStart')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
        </dx-number-box>

      </div>
      <dx-tooltip target="#tt12" class="toolTip toolTipField" [(visible)]="v[12]">
        <div *dxTemplate="let data = data of 'content'">
          {{ 'Die Kategorie ID ab der nextPIM IDs vergeben darf' | translate }}
        </div>
      </dx-tooltip>
    </div>

    <div class="dx-field">
      <div class="dx-field-label dx-field-label200" (mouseenter)="ttt(13)" (mouseleave)="ttt(13)">{{ 'Prefix' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt13">
        <label class="placeLabel {{ model.productPrefix?.length>0  ? 'placeLabelSmall' : '' }}">PREFIX</label>
        <dx-text-box [(value)]="model.productPrefix" [readOnly]="readOnlyUser('showarePrefix')" [showClearButton]="true" (onValueChanged)="catalogChangedinclShop($event)">
        </dx-text-box>

      </div>
      <dx-tooltip target="#tt13" class="toolTip toolTipField" [(visible)]="v[13]">
        <div *dxTemplate="let data = data of 'content'">
          {{ 'Prefix, der an die SUPLIER_PID angehängt werden soll' | translate }}
        </div>
      </dx-tooltip>
    </div>
    <dx-toolbar>
      <dxi-item *ngIf="!modelService.catalogService.connection2Shop" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testShopConnection}"></dxi-item>
    </dx-toolbar>
    <div style="color: red;">
      {{ modelService.catalogService.connection2ShopMessage }}
    </div>

    <dx-toolbar *ngIf="modelService.catalogService.connection2Shop && !readOnlyUser('shopwareSendDeleteZero')">
      <dxi-item *ngIf="!modelService.catalogService.connection2ShopEstablished" location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung herstellen' | translate , onClick: establishedShopConnectionZero}"></dxi-item>
      <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Katalog senden' | translate , onClick: sendData2ShopZero}"></dxi-item>
      <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'ShopDaten löschen' | translate , onClick: deleteShopZero}"></dxi-item>
    </dx-toolbar>

    <div>
      <div>


        <dx-popup title="{{ 'Verbindung herstellen?' | translate }}"
                  [(visible)]="isEstablishedShopConnectionPopupVisible"
                  [width]="350"
                  [height]="250"
                  [showTitle]="true">
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Shop verbinden' | translate, onClick: establishedShopConnectionOne }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: establishedShopConnectionTwo }"></dxi-toolbar-item>
          <div *dxTemplate="let data of 'content'">
            <p>{{ 'Wollen Sie den Shop mit diesem Katalog verbinden?' | translate }}</p>
          </div>
        </dx-popup>
        <dx-popup title="{{ 'Katalog senden' | translate }}?"
                  [(visible)]="isSendCatalogPopupVisible"
                  [width]="600"
                  [height]="300"
                  [showTitle]="true">
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Daten senden' | translate, onClick: sendData2ShopOne }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: sendData2ShopTwo }"></dxi-toolbar-item>
          <div *dxTemplate="let data of 'content'">
            <div class="dx-fieldset">
              <div class="dx-field">

                <p>{{ 'Soll der Katalog übertragen werden' | translate }}?</p>
              </div>

              <div class="dx-field">
                <div class="dx-field-label">{{ 'Preise übertragen' | translate }}</div>
                <div class="dx-field-value">
                  <dx-switch [(value)]="sendPrices" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
                </div>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup title="{{ 'Shop Löschen' | translate }}?"
                  [(visible)]="isDeleteShopPopupVisible"
                  [width]="750"
                  [height]="250"
                  [showTitle]="true">
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Produkte löschen' | translate, onClick: deleteShopOne }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Kategorien löschen' | translate, onClick: deleteShopTwo }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Medien löschen' | translate, onClick: deleteShopThree }"></dxi-toolbar-item>
          <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: deleteShopFour }"></dxi-toolbar-item>
          <div *dxTemplate="let data of 'content'">
            <p>Shopdaten löschen?</p>
          </div>
        </dx-popup>
      </div>
    </div>
  </div>
</div>
