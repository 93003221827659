<div class="p-3">
  <div class="card mb-3">
    <div class="card-body">
      <p>{{ 'Die Schnittstellen Einstellungen aus dem Katalog, die für den Export benutzt werden' | translate }}</p>
      <p class="text-danger">{{ 'ACHTUNG: Diese Einstellungen sind aus dem Katalog und werden nicht in der Export Konfiguration gespeichert, sondern direkt im Katalog' | translate }}</p>
    </div>
  </div>

  <div [ngSwitch]="exportParameter.version" class="mt-3">
    <div *ngSwitchCase="'INTERSHOP'" class="border p-3">
      <intershopConnection></intershopConnection>
    </div>
    <div *ngSwitchCase="'GEVIS'" class="border p-3">
      <gevis></gevis>
    </div>
    <div *ngSwitchCase="'ODOO'" class="border p-3">
      <odoo></odoo>
    </div>
    <div *ngSwitchCase="'SHOPWARE5'" class="border p-3">
      <catalogShopware5></catalogShopware5>
    </div>
    <div *ngSwitchCase="'SHOPWARE6'" class="border p-3">
      <catalogShopware6></catalogShopware6>
    </div>
    <div *ngSwitchCase="'ELECTRONIC_SALES'" class="border p-3">
      <electronicSalesConnection></electronicSalesConnection>
    </div>
    <div *ngSwitchCase="'OSG'" class="border p-3">
      <catalogOsg></catalogOsg>
    </div>
  </div>
</div>
