
export class Agreement {

  constructor(
    public agreementId?: string,
    public type?: string,
    public agreementDesc?: string,
    public startDate?: Date,
    public endDate?: Date,
  ) {
    
  }

}
