export function throwInvalidProperty<T extends object>(model: T, prop: string) {
    if (!model.hasOwnProperty(prop)) {
        throw new Error(`The property ${model.constructor.name}.${prop} does not exist!`);
    }
}

export function safeSetToModel<T extends object>(model: T, prop: string, value: unknown) {
    throwInvalidProperty(model, prop);
    model[prop] = value;
}

export function safeGetFromModel<T extends object>(model: T, prop: string) {
    throwInvalidProperty(model, prop);
    return model[prop];
}

export async function safeAsyncSetToModel<
    M extends object,
    V extends M[keyof M]
>(model: M, prop: string, resultFn: () => Promise<V>) {
    throwInvalidProperty(model, prop);
    model[prop] = await resultFn();
}

export async function safeAsyncGetFromModel<
    M extends object,
    V extends M[keyof M]
>(model: M, prop: string, resultFn: (value: V) => Promise<V>) {
    throwInvalidProperty(model, prop);
    return await resultFn(model[prop]);
}
