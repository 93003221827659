<dx-data-grid id="gridMediaPoolContainer"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="dataSource"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Medien im Katalog' | translate }}"
              [showBorders]="true"
              (onRowPrepared)="onRowPrepared($event)">

  <div *dxTemplate="let toolbaritem of 'mediaDownloadButtonTemplate'">
    <dx-button *ngIf="!readOnlyUser('mediaPool')" text="{{ 'Medien download' | translate }}" (onClick)="downloadMimes()" stylingMode="text" type="default"></dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'reloadButtonTemplate'">
    <dx-button (onClick)="reload()" stylingMode="text" type="default" icon="refresh"></dx-button>
  </div>

  <dxo-search-panel [visible]="true" placeholder="{{ 'Suchen' | translate }}" [highlightCaseSensitive]="false"></dxo-search-panel>
  <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true"></dxo-remote-operations>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100]"></dxo-pager>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="MediaPoolStateNewUIV2"></dxo-state-storing>

  <dxi-column dataField="normUri" caption="" [width]="60" [allowFiltering]="false" [allowSorting]="false" cellTemplate="imageCellTemplate"></dxi-column>
  <div *dxTemplate="let data of 'imageCellTemplate'" style="overflow:visible">
    <div (mouseenter)="showImage(data.value,data.rowIndex)" (mouseleave)="hideImage()">
      <span *ngIf="!data.value" class="glyphicon glyphicon-picture" style="font-size: 40px;"></span>
      <img *ngIf="data.value" src="{{data.value}}{{mimedate()}}" style="height: 40px;" id="link{{data.rowIndex}}" />
    </div>
  </div>
  <dxi-column dataField="mimeSource" caption="{{ 'Dateiname' | translate }}" cellTemplate="nameCellTemplate"></dxi-column>
  <div *dxTemplate="let data of 'nameCellTemplate'">
    <a href="{{data.data.blobUri}}{{getEncodedString(data.data.mimeSource)}}{{mimedate()}}" target="_blank">{{data.value}}</a>
  </div>
  <dxi-column dataField="fileSize" [allowFiltering]="false" [allowSorting]="false" caption="{{ 'Größe' | translate }}" cellTemplate="sizeCellTemplate"></dxi-column>
  <div *dxTemplate="let data of 'sizeCellTemplate'">
    {{getFileSizeString(data.value)}}
  </div>

  <dxi-column [width]="60" cellTemplate="editCellTemplate"></dxi-column>
  <div *dxTemplate="let data of 'editCellTemplate'">
    <div *ngIf="canView('mimePool')">
      <a (click)="replaceMime(data)" class="glyphicon glyphicon-random mr-2" title="{{ 'Mime Datei ersetzen' | translate }}"></a>
      <a (click)="deleteRequest(data)" class="dx-icon-trash mr-2" title="{{ 'Mime Datei löschen' | translate }}"></a>
    </div>
  </div>
</dx-data-grid>

<dx-popover [(target)]="target"
            position="bottom"
            [width]="300"
            [showTitle]="false"
            [(visible)]="imageVisable">
  <div *dxTemplate="let data = model of 'content'">
    <img src="{{source}}{{mimedate()}}" style="height: 244px;" />
  </div>
</dx-popover>

<dx-popup class="popup"
          [width]="500"
          [height]="300"
          [showTitle]="true"
          title="{{ 'Datei importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [(visible)]="importFileVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <importFile></importFile>
  </div>
</dx-popup>
