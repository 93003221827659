import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { Product } from '../../../Model/Catalog/Product';
import { ValidationService } from '../../../Services/Validation/validation.service';
import { fieldNames } from '../../../Model/FieldNames';
import { LoginService } from '../../../Services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Item as dxListItem } from 'devextreme/ui/list';
import { ElectronicSalesDetail } from 'app/Model/Catalog/ElectronicSalesDetail';

@Component({
  selector: 'electronicSales',
  templateUrl: 'electronicSales.component.html',
  styleUrls: ['electronicSales.component.css', '../template.css']
})
export class ElectronicSalesComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;
  @Input() templateView: Boolean = false;
  @Output() onValueChanged = new EventEmitter<any>();

  stockKeepingItems: dxListItem[] = [];
  stockKeepingModusItems: dxListItem[] = [];

  marketingMap = new Map([
    [fieldNames.electronicSalesDescriptionMarketing, 'descriptionMarketing'],
    [fieldNames.electronicSalesPromoInfo, 'promoInfo'],
    [fieldNames.electronicSalesNoteCustomer, 'noteCustomer'],
    [fieldNames.electronicSalesPriceInfo, 'priceInfo']
  ]);

  classificationMap = new Map([
    [fieldNames.electronicSalesFSK, 'fsk'],
    [fieldNames.electronicSalesEUDataSheet, 'euDataSheet'],
    [fieldNames.electronicSalesEEK, 'eek'],
    [fieldNames.electronicSalesEELabel, 'eeLabel'],
    [fieldNames.electronicSalesEEKColor, 'eekColor']
  ]);

  logisticsMap = new Map([
    [fieldNames.electronicSalesStockLocation, 'stockLocation'],
    [fieldNames.electronicSalesStockInfoText, 'stockInfoText'],
    [fieldNames.electronicSalesNoStockInfoText, 'noStockInfoText']
  ]);

  chargesMap = new Map([
    [fieldNames.electronicSalesSurCharges1ArtNr, 'surCharges1ArtNr'],
    [fieldNames.electronicSalesSurCharges1Percent, 'surCharges1Percent'],
    [fieldNames.electronicSalesSurCharges1FixValue, 'surCharges1FixValue'],
    [fieldNames.electronicSalesSurCharges2ArtNr, 'surCharges2ArtNr'],
    [fieldNames.electronicSalesSurCharges2Percent, 'surCharges2Percent'],
    [fieldNames.electronicSalesSurCharges2FixValue, 'surCharges2FixValue'],
    [fieldNames.electronicSalesCharges, 'charges']
  ]);

  ordersMap = new Map([
    [fieldNames.electronicSalesOrderFactor, 'orderFactor'],
    [fieldNames.electronicSalesOrderPriceFactor, 'orderPriceFactor']
  ]);

  private _showHTMLEditor: Boolean = true;

  get showHTMLEditor() {
    return this._showHTMLEditor;
  }

  set showHTMLEditor(value) {
    this._showHTMLEditor = value;
  }

  constructor(
    public loginService: LoginService,
    public translate: TranslateService,
    public validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.model.electronicSales ??= new ElectronicSalesDetail();
    this.stockKeepingItems = [
      { key: '', text: this.translate.instant('Standard (= nichts tun)') },
      { key: 'Y', text: this.translate.instant('Bestand bei Bestelleingang reduzieren') },
      { key: 'N', text: this.translate.instant('Bestand nicht reduzieren') }
    ];
    this.stockKeepingModusItems = [
      { key: 'standard', text: this.translate.instant('Standard (= nichts tun)') },
      { key: 'delete', text: this.translate.instant('Artikel löschen (kein weiterer Import)') },
      { key: 'delete_temporary', text: this.translate.instant('Artikel löschen (bis zum nächsten Import)') }
    ];
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }
    this.onValueChanged.emit(event);
  }

  showClearButton(): boolean {
    return !this.templateView;
  }
}
