export class MassGenerateKITextsParameter {

  constructor(
    public catalogId: string,
    public fullCatalog: boolean,
    public categoryIds: string[],
    public productIds: string[],
    public genVariantTexts: boolean,
    public teachingSetId: string,
    public generateHTML: boolean,
    public textstyle: string,
    public maxLength: number
  ) {

  }
}
