<fullscreen-page>
  <content-column look="dark">
    <dx-scroll-view slot="content" class="h-100 p-3" showScrollbar="always">
      <div class="d-block h-100 w-75 mx-auto">
        <div class="d-flex flex-row w-100">
          <dx-text-box [(value)]="searchText" class="w-100" [showClearButton]="true" (onValueChanged)="testSearch()"></dx-text-box>
        </div>
        <div class="d-flex flex-row">
          <dx-button stylingMode="text" type="default" text="{{ 'Produkte anzeigen' | translate }}" (onClick)="startSearch()"></dx-button>
        </div>
        <div>
          <dx-load-panel #loadPanel
                         shadingColor="rgba(0,0,0,0.4)"
                         [position]="{ of: '#gridContainer' }"
                         [(visible)]="loadingVisible"
                         [showIndicator]="true"
                         [showPane]="true"
                         [shading]="true"
                         [hideOnOutsideClick]="false">
          </dx-load-panel>

          <dx-data-grid [dataSource]="results"
                        [allowColumnReordering]="true"
                        [rowAlternationEnabled]="true"
                        [allowColumnResizing]="true"
                        [showBorders]="true"
                        id="gridContainer">
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="searchState"></dxo-state-storing>

            <dxi-column caption="{{ 'SupplierPid' | translate }}" dataField="supplierPid"></dxi-column>
            <dxi-column caption="{{ 'Mimes' | translate }}" dataField="mimes" cellTemplate="mimeCellTempalte"></dxi-column>
            <dxi-column caption="{{ 'KurzBeschreibung' | translate }}" dataField="descriptionShort"></dxi-column>
            <dxi-column caption="{{ 'Katalog' | translate }}" dataField="catalogName"></dxi-column>
            <dxi-column caption="" dataField="catalogMappings" cellTemplate="linkCellTempalte"></dxi-column>

            <div *dxTemplate="let result of 'mimeCellTempalte'">
              <dx-gallery [dataSource]="result.data.mimes"
                          [width]="100"
                          [loop]="true"
                          showIndicator="true"
                          [showNavButtons]="false">
                <div *dxTemplate="let galleryItem of 'item'">
                  <img src="{{galleryItem.normUri}}" width="100" height="100" />
                </div>
              </dx-gallery>
            </div>

            <div *dxTemplate="let result of 'linkCellTempalte'">
              <dx-button (onClick)="jumpToProduct(result.data)" stylingMode="text" type="default" text="{{ 'Zum Produkt springen' | translate }}"></dx-button>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </dx-scroll-view>
  </content-column>
</fullscreen-page>
