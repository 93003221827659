import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryFilterTreeItem } from '../Model/Catalog/exportFilter/categoryFilterTreeItem';
import { SimpleHierachicalFilter } from '../Model/exportParameter/SimpleHierachicalFilter';


@Injectable()
export class ExportFilterService {

  private readonly apiUrl = 'api/ExportFilter';

  constructor(private http: HttpClient) { }

  public getCategoryFilterTreeElements(catalogId, customerId): Observable<CategoryFilterTreeItem[]> {
    const url = `${this.apiUrl}/GetCategoryFilter?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<CategoryFilterTreeItem[]>(url);
  }

  public getUdxFilterTreeElements(catalogId, customerId): Observable<CategoryFilterTreeItem[]> {
    const url = `${this.apiUrl}/GetUdxFilters?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<CategoryFilterTreeItem[]>(url);
  }

  public getManufacturerFilters(catalogId: string, customerId: string): Observable<string[]> {
    const url = `${this.apiUrl}/GetManufacturerFilters?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<string[]>(url);
  }

  public getProductStates(catalogId: string, customerId: string): Observable<string[]> {
    const url = `${this.apiUrl}/getProductStates?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<string[]>(url);
  }

  public getPriceLists(catalogId: string, customerId: string): Observable<CategoryFilterTreeItem[]> {
    const url = `${this.apiUrl}/getPriceLists?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<CategoryFilterTreeItem[]>(url);
  }

  public getSuppliers(catalogId: string, customerId: string): Observable<string[]> {
    const url = `${this.apiUrl}/getSuppliersFilters?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<string[]>(url);
  }

  public getFeatureSystemFilters(catalogId: string, customerId: string): Observable<CategoryFilterTreeItem[]> {
    const url = `${this.apiUrl}/getFeatureSystemFilters?catalogId=${catalogId}&customerId=${customerId}`;
    return this.http.get<CategoryFilterTreeItem[]>(url);
  }


  public ConvertSystemFilterToHierachicalFilter(selectedNodes: import("devextreme/ui/tree_view").Node<any>[]): SimpleHierachicalFilter[] {
    return selectedNodes.map(x => {
      const filter = new SimpleHierachicalFilter();
      if (x.parent == null) {
        filter.category = x.text;
      } else {
        filter.category = x.parent.text;
        filter.item = [x.text];
      }

      return filter;
    });
  }
}
