
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { TestResult } from "../Model/Dto/TestResult";
import { ShopWare6Connection } from '../Model/Catalog/ShopWareConnection';
import { ShopWare5ShopDto } from '../Model/Dto/Shopware5ShopDto';
import { TreeItem as TreeElement } from "../Model/Dto/TreeItem";



@Injectable()
export class OdooService {
  constructor(private http: HttpClient) {}

  public testConnection(catalogId: string, customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get("api/odoo/testConnection", options)) as any;
  }

  public getCategories(shopUrl: string, database: string, user: string, key: string): Observable<TreeElement[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("shopUrl", shopUrl).append("database", database).append("user", user).append("key", key)
    }
    return (this.http.get("api/odoo/getcategories", options)) as any;
  }

  public startExport(catalogId: string, customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get("api/odoo/exportCatalog", options)) as any;
  }
}
