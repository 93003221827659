<div [ngStyle]="{'height': 'inherit'}">
  <div class="dx-fieldset" [ngStyle]="{'margin': '20px 30px', 'margin-bottom': '-20px'}">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Nach Liste filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.searchByListParameter.useFilter"></dx-switch>
      </div>
    </div>
    <div class="dx-field" *ngIf="exportParameter.searchByListParameter.useFilter" >
      <div class="dx-field-label">{{ 'Produkte zusätzlich zu anderen Filtern hinzufügen' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.searchByListFilterAdds"></dx-switch>
      </div>
    </div>
  </div>
  <div *ngIf="exportParameter.searchByListParameter.useFilter" [ngStyle]="{'height': '60%'}">
    <dx-lookup placeholder="{{'SuchKriterium' | translate}}" [searchEnabled]="false" [items]="searchValues" [(value)]="parameter.key" [ngStyle]="{'height': '30%'}">
    </dx-lookup>
    <div class="dx-text-area-coloured w-100" [ngStyle]="{'height': '70%'}">
      <dx-text-area [value]="parameter.list" (onValueChanged)="onValueChanged($event)" [ngStyle]="{'height': '100%'}">
      </dx-text-area>
    </div>
  </div>
</div>
