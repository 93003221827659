import { Component, OnInit } from "@angular/core";
import { Category } from "../../Model/Catalog/Category";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from "../../Services/userManagment.service";
import { Product } from "../../Model/Catalog/Product";
import { Output, EventEmitter } from '@angular/core';
import { MassDataChangeService } from "../../Services/CatalogManagement/massDataChange.service";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";

@Component({
  selector: "categoryDetail",
  templateUrl: "categoryDetail.component.html",
  styleUrls: ["./categoryDetail.component.css", "./../shared/detail.css"]
})
export class CategoryDetailComponent implements OnInit {
  @Output() onMassDataEdit = new EventEmitter<Product>();

  constructor(private modelService: ModelService,
    public translate: TranslateService,
    public userManagement: UserManagementService,
    public massDataService: MassDataChangeService,
    public catalogService: CatalogService) {
    this.openMassDataChange = this.openMassDataChange.bind(this);
  }

  pages: { id: number; title: string; template: string; }[] = [];

  ngOnInit(): void {
    this.translateTexts();

    this.reset();
  }

  txtKategorie: string;
  txtUdx: string;
  txtMedien: string;
  txtTemplates: string;
  txtSeo: string;
  txtOsg: string;
  txtShopware: string;

  translateTexts() {
    this.txtKategorie = this.translate.instant("Kategorie");
    this.txtMedien = this.translate.instant("Medien");
    this.txtUdx = this.translate.instant("Udx");
    this.txtTemplates = this.translate.instant("Vorlagen");
    this.txtSeo = this.translate.instant("Seo");
    this.txtOsg = this.translate.instant("OSG / Multishop");
    this.txtShopware = this.translate.instant("Shopware 6");
  }

  reset() {
    this.pages = [
      { id: 1, title: this.txtKategorie, template: "categoryTemplate" },
      { id: 2, title: this.txtMedien, template: "medienTemplate" }
    ];
    if (this.modelService.loginService.hasAddonUDX) {
      this.pages.push({ id: 3, title: this.txtUdx, template: "udxTemplate" });
    }
    if (!this.isVirtualCatalog() && this.modelService.loginService.hasAddonProductTemplates) {
      this.pages.push({ id: 4, title: this.txtTemplates, template: "templateTemplate" });
    }
    if (this.modelService.loginService.hasAddonSEO) {
      this.pages.push({ id: 5, title: this.txtSeo, template: "seoTemplate" });
    }
    if (this.modelService.catalogService.catalog.osgSegmentMapping?.categoriesAreSegments) {
      this.pages.push({ id: 5, title: this.txtOsg, template: "osgTemplate" });      
    }
    if (this.modelService.loginService.hasAddonShopware6) {
      this.pages.push({ id: 6, title: this.txtShopware, template: "shopware6Template" });
    }
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getCategory(this.modelService.catalogService.category.id, this.modelService.loginService.currentUser.customerId).subscribe();
  }

  categoryUpdate(event = null) {
    this.modelService.catalogService.updateCategory();
  }

  categoryChanged(e = null) {
    this.modelService.catalogService.categoryChanged(e);
  }

  get model(): Category {
    return this.modelService.catalogService.category;
  }

  get categoryMainImage(): string {
    if (this.modelService.catalogService.category != null &&
      this.modelService.catalogService.category.mimes.length > 0) {
      let mime = this.modelService.catalogService.category.mimes[0];
      if (mime.normUri) {
        return mime.normUri;
      }
    }
    return null;
  }

  public v: boolean[] = [false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  get bigHeader() {
    if (window) {
      return (window.innerHeight > 1000);
    }
    return true;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }

  readOnly(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }

  renderMassDataChangeButton(): boolean {
    return !this.isVirtualCatalog() && this.canView('massDataChange');
  }

  openMassDataChange() {
    this.massDataService.getEmtpyProductByCatalog(this.catalogService.catalog.id).subscribe((result: Product) => {
      this.onMassDataEdit.emit(this.modelService.productService.getProductFromJson(result, this.modelService.loginService.wawiSettings));
    });
  }
}
