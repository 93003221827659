import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateItemTemplate } from '../../../../../Model/Catalog/TemplateItemTemplate';
import { FieldListInterface } from '../../../../../Model/ui/FieldListInterface';

@Component({
  selector: 'fieldList',
  templateUrl: './fieldList.component.html',
  styleUrls: ['./fieldList.component.css']
})
export class FieldListComponent implements OnInit {
  @Input() set fieldListContainer(value: FieldListInterface) {
    this._fieldListContainer = value;
    this.selectFieldsFromConfig();
  }
  @Input() fields: TemplateItemTemplate[];
  @Input() fieldGroups: string[];
  @Input() isUpdateTemplate: boolean = false;

  @Output() onValueChanged = new EventEmitter();

  get fieldListContainer() {
    return this._fieldListContainer;
  }
  _fieldListContainer: FieldListInterface;
  groups: { name: string; active: boolean }[];

  ngOnInit(): void {
    if (this.fieldListContainer.fields == null) this.fieldListContainer.fields = ['SUPPLIER_PID'];

    this.selectFieldsFromConfig();
  }

  selectGroup(event: { value: boolean }, group: string) {
    for (var field of this.fields) {
      if (field.group == group) {
        if (field.field != 'SUPPLIER_PID') {
          field.active = event.value;
        }
      }
    }
    this.syncFieldWithConfig();
    this.onValueChanged.emit({ event: 'fieldListGroupChanged' });
  }

  selectField(event: { event: null }, field: any) {
    if (event.event != null) {
      this.syncFieldWithConfig();
      this.onValueChanged.emit({ event: 'fieldListFieldChanged' });
    }
  }

  syncFieldWithConfig() {
    if (this.fieldListContainer.fields == null) this.fieldListContainer.fields = ['SUPPLIER_PID'];

    this.fieldListContainer.fields = this.fields.filter((x) => x.active).map((x) => x.field);
  }

  selectFieldsFromConfig() {
    if (this.fieldListContainer.fields == null) this.fieldListContainer.fields = ['SUPPLIER_PID'];

    this.fields.forEach((x) => {
      x.active = this.fieldListContainer.fields.some((y) => y == x.field);
    });

    this.groups = this.fieldGroups.map((x) => {
      return { name: x, active: false };
    });
    this.groups.forEach((x) => {
      x.active = !this.fields?.some((y) => y.group == x.name && !y.active);
    });
  }

  getHeightRatio(group: string): number {
    var list = this.fields?.filter((item) => item.group == group);
    return list.length + 2;
  }
}
