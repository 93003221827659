export class OsgCategoryMapping {

  constructor(
    public lastSend?: Date,
    public productRange?: string,
    public segmentId?: number,
    public segmentIdTest?: number,
    public lastSendTest?: Date,
    public productRangeTest?: string,
    public categoryIdString ?: string,
    public parentCategoryIdString?: string,
    public categoryIdStringTest ?:string,
    public parentCategoryIdStringTest?: string,
    
  ) {
  }
}



