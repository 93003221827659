import { Functions } from '../../../Services/functions';
import { FeatureValue } from '../../classification/FeatureValue';

export class Feature {
  private _validFeatureId?: string;
  constructor(
    public forder?: number,
    public name?: string,
    public fDescr?: string,
    public featureValues: FeatureValue[] = new Array<FeatureValue>(),
    public fValueDetails?: string,
    public funit?: string,
    public printOrder: number = 0,
    public isVariant?: boolean,
    public csCardFilter?: string,
    public valueSource?: string,
    public fRequired: boolean = false,
    validFeatureId?: string,
    public oldValidFeatureId?: string,
    public updateLinkedFields: boolean = false
  ) {
    this.validFeatureId = validFeatureId;
  }
  public get validFeatureId(): string | undefined {
    return this._validFeatureId;
  }

  public set validFeatureId(newId: string | undefined) {
    if (this._validFeatureId) {
      if (this._validFeatureId != newId) {
        this.oldValidFeatureId = this._validFeatureId;
      }
    }
    this._validFeatureId = newId;
  }

  toJSON(): any {
    return {
      ...this,
      validFeatureId: this._validFeatureId // Include private property in JSON
    };
  }

  public get ftIdref(): string {
    let found = this.featureValues.find((l) => l.order === 1);
    if (found != null) {
      return found.valueId;
    }
    return '';
  }

  public set ftIdref(value: string) {
    let found = this.featureValues.find((l) => l.order === 1);
    if (!found) {
      found = new FeatureValue();
      found.order = 1;
      this.featureValues.push(found);
    }
    found.valueId = value;
  }

  public get value(): string {
    //changed to used lowest order featurevalue
    let found = this.featureValues.reduce((lowest, current) => {
      return lowest && lowest.order < current.order ? lowest : current;
    }, null);
    if (found != null) {
      return found.value;
    }
    return '';
  }

  public set value(value: string) {
    let found = this.featureValues.reduce((lowest, current) => {
      return lowest && lowest.order < current.order ? lowest : current;
    }, null);
    if (!found) {
      found = new FeatureValue();
      found.order = 1;
      this.featureValues.push(found);
    }
    found.value = value;
  }

  public guid: string = Functions.newGuid();
}
