import { Component, Input } from '@angular/core';
import { ModelService } from '../../Services/model.service';

@Component({
  selector: 'content-column',
  templateUrl: './content-column.component.html',
  styleUrls: ["./content-column.component.css"]
})
export class ContentColumnComponent {
    @Input() navigationSwitch?: boolean;
    @Input() helpSwitch?: boolean;
    @Input() look: 'light' | 'dark';  

    constructor(public modelService: ModelService) {}

    toggleNavigation() {
      this.modelService.systemService.showMenu = !this.modelService.systemService.showMenu;
    }

    toggleHelp() {
      this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    }
}