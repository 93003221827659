import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { OpenAIInvoice } from "../Model/OpenAi/OpenAIInvoice";
import { InvoiceStatistic } from "../Model/OpenAi/InvoiceStatistic";

import { MassGenerateKITextsParameter } from "../Model/MassGenerateKITextsParameter";
import { InvoiceSettingDto } from "../Model/Dto/InvoiceSettingDto";

@Injectable()
export class InvoiceService {

  constructor(private http: HttpClient) {
  }

  public create(invoice: OpenAIInvoice, customerId: string): Observable<OpenAIInvoice> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.post<OpenAIInvoice>("api/Invoice/CreateOpenAI", invoice, options);
    return (result);
  }

  public GetInvoiceSetting(customerId: string): Observable<InvoiceSettingDto> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.get<InvoiceSettingDto>("api/Invoice/GetInvoiceSetting",  options);
    return (result);
  }

  public getAll(customerId: string): Observable<InvoiceStatistic[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.get<InvoiceStatistic[]>("api/Invoice/GetAll", options);
    return (result);
  }

  public getTotalProducts(customerId: string, param: MassGenerateKITextsParameter) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.post<number>("api/Invoice/GetProductCount", param, options);
    return (result);
  }

  public ExportInvoices(customerId: string) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.get<string>("api/Invoice/ExportInvoices", options);
    return (result);
  } 

  public startMassGenerateOpenAITextsJob(customerId: string, param: MassGenerateKITextsParameter) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.post<number>("api/Invoice/StartMassGenerateOpenAITextsJob", param, options);
    return (result);
  }

  public updateInvoiceSetting(customerId: string, param: InvoiceSettingDto) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    var result = this.http.post<InvoiceSettingDto>("api/Invoice/UpdateInvoiceSetting", param, options);
    return (result);
  }
}
