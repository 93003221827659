import { Component, Inject, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { ModelService } from '../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'productreporting',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './productreporting.component.html',
  styleUrls: ["./productreporting.component.css"]
})

export class ProductReportingComponent implements OnInit {

  constructor(@Inject('BASE_URL') public hostUrl: string, public modelService: ModelService, public translate: TranslateService, private sanitizer: DomSanitizer) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Produktdatenblatt Designer");
  }

  ngOnInit(): void {
    this.translateTexts();
  }

  title: string;
  getDesignerModelAction = 'api/ReportDesigner/GetProductReportDesignerModel';
  // The report's path. The Report Designer opens it when the application starts.
  reportUrl: string;
  //hostUrl = 'https://localhost:44302';

  designerUrl: SafeUrl;
  showDesigner: boolean;


  translateTexts() {
    this.title = this.translate.instant("Produktdatenblatt Designer");
    this.translate.get("Produktdatenblatt")
      .subscribe((text: string) => {
        this.reportUrl = text;  // does this really make sense? if a report with this (translated) name does not exist, a blank one is created.

        // wrong language
        //this.designerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/ReportDesigner?lang=${encodeURIComponent(this.translate.getBrowserLang())}&report=${encodeURIComponent(text)}`);
        let lang = this.modelService.loginService.currentUser.frontEndLanguage;
        this.designerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/ReportDesigner?lang=${encodeURIComponent(lang)}&report=${encodeURIComponent(text)}&token=${encodeURIComponent(this.modelService.loginService.accessToken)}`);

        this.showDesigner = true;
      });
  }

  public onLogoClicked() {
    this.modelService.router.navigate(["/catalogs"]);
  }

  CustomizeLocalization(event) {

    // Auskommentiert, da u.U. BugFix von DXP benötigt
    // ==> https://supportcenter.devexpress.com/ticket/details/t970419/angular-web-reporting-with-asp-net-core-backend-missing-properties-for-bands-when



    let lang = this.modelService.loginService.currentUser.frontEndLanguage;
    //if (lang == "de") {
    //  event.args.LoadMessages(deAnalyticMessages);
    //  event.args.LoadMessages(deReportingMessages);
    //}
    //if (lang == "en") {
    //  event.args.LoadMessages(enAnalyticMessages);
    //  event.args.LoadMessages(enReportingMessages);
    //}
    //if (lang == "nl") {
    //  event.args.LoadMessages(nlAnalyticMessages);
    //  event.args.LoadMessages(nldeReportingMessages);
    //}
  }

  /*
  CustomizeMenuActions(event) {
    var newReportAction = event.args.GetById(ActionId.NewReport);
    if (newReportAction) {
      newReportAction.clickAction = function () {
        event.component.bindingSender.OpenReport("TemplateReport");
      }
    }
  }
  */
}
