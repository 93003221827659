import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'app/Model/Catalog/Product';
import { Mime } from 'app/Model/Catalog/Mime';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { ProductService } from 'app/Services/CatalogManagement/product.service';
import { VariantValue } from 'app/Model/VariantValue';
import { TranslateService } from '@ngx-translate/core';
import { Reference } from 'app/Model/Catalog/Reference';
import { SystemService } from 'app/Services/system.service';
import { LoginService } from 'app/Services/login.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'shop-view-new',
  templateUrl: 'shop-view-new.component.html',
  styleUrl: 'shop-view-new.component.css'
})
export class ShopViewNew implements OnInit {
  @Input() model: Product;
  mimes: Mime[];
  selectedImage: string = "";
  variantDetailsWithValues: any;
  basicPriceDutyInfo = "";
  existingReferenceTypes: Record<string, Reference[]>;
  referencePages = [];

  constructor(
    public catalogService: CatalogService,
    public productSercvie: ProductService,
    public translate: TranslateService,
    public systemService: SystemService,
    public loginService: LoginService
  ) {
    this.onVariantValueChanged = this.onVariantValueChanged.bind(this);
  }

  async ngOnInit(): Promise<void> {       
    this.model.prepareView();
    this.mimes = this.model.productImagesNormal;
    if(this.mimes.length > 0) {
      this.selectedImage = this.mimes[0].normUri;
    }

    let masterID = this.model.id;
    let childId = "";
    if(this.model.isChild) {
      masterID = this.model.parentProductId;      
      childId = this.model.id;
    }
    const result = await this.productSercvie.getVariantDetailsWithValues(masterID, childId, this.model.customerId);
    this.variantDetailsWithValues = result.valuesPerVariant;
    if (result.error != "") {
      this.systemService.notifyInfo(result.error, 4000);
    }

    if(this.model?.references?.length > 0) {
      let id = 0;
      for (var reference of this.model.references) {
        if (reference.normUri == undefined || reference.normUri == "") {
          const result: { descriptionShort; normUri } = await this.productSercvie
            .getReferenceDataBySupplierPid(
              reference.artIdTo,
              this.loginService.currentUser.customerId,
              this.catalogService.catalog.id
            )
          reference.normUri = result.normUri;
          reference.descriptionShort = result.descriptionShort;
        }
        var transletedType = this.translate.instant(reference.type)
        var data = this.model.references.filter((item) => item.type === reference.type);
        if(!this.referencePages.find(x => x.title === transletedType)) {
          
          this.referencePages.push({id: ++id, title: transletedType, data: data});
        }
      }
    }

    if(this.model.orderDetail.hasBasicPriceDuty) {
      const unitList = await lastValueFrom(this.productSercvie?.getOrderUnitList());
      for (let i = 0; i < unitList.length; i++) {
        if(this.model.orderDetail.contentPackage == unitList[i]) {
          const priceDetail = this.model.priceLists.flatMap((x) => x.productPriceDetails).find((x) => x.priceType === 'net_list');
          const priceAmount = priceDetail.priceAmountNumber;
          const currency = priceDetail.priceCurrency;
          const priceQuantity = this.model.orderDetail.priceQuantityNumber || 1;
          const basicQuantity = this.model.orderDetail.basicQuantityNumber ?? 0;
          const contentQuantity = this.model.orderDetail.contentQuantityNumber || 1;
          const value = ((priceAmount / priceQuantity) * (basicQuantity / contentQuantity)).toLocaleString(
            this.translate.currentLang,
            { style: 'currency', currency }
          );

          this.basicPriceDutyInfo = value + '/' + this.model.orderDetail.basicQuantityNumber + ' ' + this.translate.instant(unitList[i]);
          break;
        }
      }
    }
  } 

  onThumbnailHover(imageUrl: string) {
    this.selectedImage = imageUrl;
  }

  async referenceClicked(data) {
    const result = await lastValueFrom(this.catalogService.getProductBySupplierPid(
      data.artIdTo,
      this.loginService.currentUser.customerId,
      this.catalogService.catalog.id
    ));
    this.catalogService.callOpenItemInBrowseComponent(result.id.toString());
  }

  getVariantValues(name: string) {    
    var variantValues = [];
    if (this.variantDetailsWithValues) {
      variantValues = this.variantDetailsWithValues[name];
    }
    return variantValues;
  }

  async onVariantValueChanged(e) {
    var selectedVariantValues = [];
    var variants = this.model.technicalFeatures.filter(x => x.isVariant == true);
    for (let variant of variants) {
      selectedVariantValues.push(new VariantValue(variant.name, variant.value));
    }
    if (selectedVariantValues.length > 0) {
      var productId = this.model.parentProductId;
      if(!this.model.isChild) {
        productId = this.model.id;
      }

      const result = await this.productSercvie.getProductIdByVariantValues(this.model.customerId, productId, selectedVariantValues);
      this.catalogService.callOpenItemInBrowseComponent(result.productId);
    }
  }
}
