import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { last } from "rxjs/operators";
import { TestResult } from "../Model/Dto/TestResult";
import { NewsArticle } from "../Model/news/newsArticle";
import { ModelService } from "./model.service";

@Injectable()
export class NewsService {

  public newNews: boolean = false;

  constructor(public modelService: ModelService, private http: HttpClient) {

  }

  public getArticles(id: number): Observable<NewsArticle> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get("api/NewsArticle/GetNewsArticle", options)) as any;
  }

  public update(article: NewsArticle): Observable<NewsArticle> {
    var result = this.http.post<NewsArticle>("api/NewsArticle/Update", article);
    return (result);
  }

  public getLast(): Observable<NewsArticle> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }
    return (this.http.get("api/NewsArticle/GetLast", options)) as any;
  }

  public create(article: NewsArticle): Observable<NewsArticle> {
    var result = this.http.post<NewsArticle>("api/NewsArticle/Create", article);
    return (result);
  }

  public getSection(start: number, amount: number, isAdmin: boolean): Observable<NewsArticle[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("start", start.toString()).append("amount", amount.toString()).append("isAdmin", isAdmin.toString())
    }
    return (this.http.get("api/NewsArticle/GetSection", options)) as any;
  }

  public delete(id: number) {
    var result = this.http.post<NewsArticle>("api/NewsArticle/Delete", id);
    return (result);
  }

  public getNewNewsAmount(lastRead: Date): Observable<number> {
    if (lastRead == undefined)
      return of(0);
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("lastRead", lastRead.toDateString())
    }
    return (this.http.get("api/NewsArticle/GetNewNewsAmount", options)) as any;
  }

  public checkNewNews() {
    if (this.modelService.loginService.currentUser == undefined
      || this.modelService.loginService.lastNewRead == undefined)
      return of(0);

    this.getNewNewsAmount(this.modelService.loginService.lastNewRead).subscribe((amount: number) => {
      this.newNews = (amount > 0);
    }, e => {
      console.log("error checking news");
    });
  }
}
