export class CreateReportProductDatasheet {

  constructor(
    public customerId?: string,
    public catalogId?: string,
    public productId?: string,
    public selectedReport?: string,
    public filenamePattern?: string,
    public mimeDescr?: string,
    public replaceAll?: boolean
  ) {
  }
}
