import { Component, ViewChild } from "@angular/core";
import { MediaPool } from "../../Model/Catalog/MediaPool";
import { DataSourceParams } from "../../Model/Catalog/DataSourceParams";
import { ModelService } from "../../Services/model.service";
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from "devextreme-angular";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from "../../Services/userManagment.service";

@Component({
  selector: "mediaPool",
  templateUrl: "mediaPool.component.html",
  styleUrls: []
})
export class MediaPoolComponent {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  dataSource: any = {};
  mediaPool: MediaPool;
  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.dataSource.store = new CustomStore({
      load: function (loadOptions: any) {

        var params = new DataSourceParams();

        params.skip = loadOptions.skip || 0;
        params.take = loadOptions.take || 20;

        if (loadOptions.sort) {
          params.orderBy = loadOptions.sort[0].selector;
          if (loadOptions.sort[0].desc) {
            params.desc = true;
          }
        }
        if (loadOptions.filter) {
          if (loadOptions.filter[2]) {
            params.filter = loadOptions.filter[2];
          }
        }
        return modelService.mediaService.getMediaElements(modelService.catalogService.selectedCatalogId, params)
          .then((data: any) => {
            let mediaPool = data;
            return {
              data: mediaPool.items,
              totalCount: mediaPool.totalCount
            }
          })
          .catch(() => { throw 'Data Loading Error' });
      }
    });

  }

  getEncodedString(s: string) {
    return encodeURIComponent(s);

  }

  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'mediaDownloadButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'reloadButtonTemplate'
    });
  }

  public importFileVisible: boolean = false;
  public replaceFileName: string = "";

  onRowPrepared(e) {
    //if (e.data && !e.data.normUri) {
    //  this.modelService.mediaService
    //  .getMediaElement(e.data.mimeSource, this.modelService.catalogService.selectedCatalogId).subscribe(
    //    (element: MediaElement) => {
    //      if (element) {
    //        e.data.normUri = element.normUri;
    //        e.data.thumbUri = element.thumbUri;
    //      }
    //    });
    //}
  }



  getFileSizeString(size) {
    return this.modelService.systemService.getFileSizeString(size);
  }

  replaceMime(data) {
    console.log(data);
    this.importFileVisible = true;
    this.replaceFileName = data.data.mimeSource;
    this.modelService.catalogService.fileNameToUseInImport = this.replaceFileName;
  }

  deleteRequest(data) {
    this.modelService.mediaService.deleteMime(data.data.catalogFileId, this.modelService.catalogService.catalog.id).subscribe(() => {
      this.dataGrid.instance.refresh();
      this.modelService.catalogService.treeRefresh();
    });

  }

  reload() {
    this.dataGrid.instance.refresh();
  }

  mimedate() {
    return "?" + this.modelService.catalogService.catalog.lastImageUpload;
  }

  imageVisable: boolean = false;
  source: string = "";
  target: string = "";

  showImage(source, index) {
    if (source != null) {
      this.target = "#link" + index;
      this.source = source;
      this.imageVisable = true;
    }
  }

  hideImage() {
    this.imageVisable = false;
  }

  downloadMimes() {
    this.translate.get("Die Medien werden per Job bereitgestellt")
      .subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text));
      });

    this.modelService.catalogService.downloadMimes(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
      .subscribe(() => {
        let i = 1;
      });
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
