import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";


@Component({
    selector: "userAction-convertPictures",
    templateUrl: "userAction-convertPictures.component.html"
})
export class UserActionConvertPicturesComponent extends UserActionBaseComponent {
    _imageTypes: string[] = ["jpg", "png"];
    _imageType: string = "jpg";
    _maxResolution: number = 1920;

    imageSquare = false;
    imageBgColor = '#FFFFFF';

    constructor(public modelService: ModelService) {
        super(modelService);
    }

    get imageTypes(): string[] {
        return this._imageTypes;
    }

    get imageType(): string {
        return this._imageType;
    }

    set imageType(value:string) {
        this._imageType = value;
    }

    get maxResolution(): number {
        return this._maxResolution;
    }

    set maxResolution(value: number) {
        this._maxResolution = value;
    }

    startMacro() {
        let startAction = new StartAction();
        startAction.catalogId = this.modelService.catalogService.catalog.id;
        startAction.customerId = this.modelService.loginService.currentUser.customerId;
        startAction.actionId = "ResizeImages(" + this.maxResolution + ":" + this.imageType + ":" + (this.imageSquare ? 1 : 0) + ':' + this.imageBgColor + ")";

        this.modelService.jobService.startUserAction(startAction)
            .subscribe(next => {
                //additional steps
                this.imageType = "jpg";
                this.imageSquare = false;
                this.maxResolution = 1920;
                this.imageBgColor = '#FFFFFF';

                this.userActionStarted.emit(next);
            });
    }
}
