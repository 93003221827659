<dx-tab-panel>
  <dxi-item title="ProductDto">
    <dx-text-area [readOnly]="true"
                  [autoResizeEnabled]="true"
                  [ngModel]="productDto | json">
    </dx-text-area>
  </dxi-item>
  <dxi-item title="Product">
    <dx-text-area [readOnly]="true"
                  [autoResizeEnabled]="true"
                  [ngModel]="product | json">
    </dx-text-area>
  </dxi-item>
</dx-tab-panel>
