<div [ngStyle]="{'height': 'inherit', 'display':'flex', 'flex-direction':'column'}">
  <div class="dx-fieldset" [ngStyle]="{'margin': '20px 30px'}">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Kategorien filtern' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.categoryFilter.useFilter" (onValueChanged)="loadFilterIfNeeded()"></dx-switch>
      </div>
    </div>
  </div>
  <div *ngIf="exportParameter.categoryFilter.useFilter" [ngStyle]="{'height': '100%', 'overflow':'hidden'}">
    <div *ngIf="isFilterLoaded==false">
      <div class="loader">
      </div>
    </div>
    <div *ngIf="isFilterLoaded==true" class="{{fullScreen ? '' : 'maxHeight'}}" [ngStyle]="{'margin-top': '-20px', 'height': 'inherit'}">
      <dx-tree-list #treeListCategoryFilter
                    [(dataSource)]="categoryFilters"
                    [(selectedRowKeys)]="exportParameter.categoryFilter.filterKey"
                    dataStructure="tree"
                    itemsExpr="items"
                    keyExpr="groupString"
                    [ngStyle]="{'height': 'inherit'}">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection mode="multiple" recursive="true"></dxo-selection>
        <dxo-scrolling preloadEnabled="true" useNative="true"></dxo-scrolling>
        <dxi-column dataField="text" caption="{{ 'Kategorie' | translate }}"></dxi-column>
      </dx-tree-list>
    </div>
  </div>
</div>
