import { Component, Input, OnInit } from '@angular/core';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';

@Component({
  selector: 'np-interface-setting',
  templateUrl: './interface-setting.component.html',
  styleUrls: ['./interface-setting.component.css']
})
export class InterfaceSettingComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  constructor() { }

  ngOnInit(): void {
  }

}
