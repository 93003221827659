import { Functions } from '../../Services/functions';

export class Mime {

  constructor(
    public mimeType: string = "",
    public mimeOrder: number = 0,
    public mimePurpose: string = "",
    public mimeDescr: string = "",
    public mimeSource: string = "",
    public mimeAlt: string = "",
    public excludeFromShopware6: boolean = false,
    public blobUri?: string,
    public thumbUri?: string,
    public normUri?: string,
    public lastImageLookup?: Date,
    public lastChanged?: Date,
    public guid: string = Functions.newGuid()
  ) {
  }
}
