<fullscreen-page>
  <navigation-column>
    <div class="p-2 h-100 d-flex flex-column">
      <div class="dx-field" style="margin: 0 0 5px;">
        <div class="dx-field-label">{{'Datenqualität-Set' | translate }}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="validationMethods"
                         [(value)]="selectedValidationMethod"
                         [displayExpr]="getValidationMethodDisplayText"
                         (onValueChanged)="onValidationMethodChanged($event)">
            <dxo-drop-down-options [width]="400"></dxo-drop-down-options>
          </dx-select-box>
        </div>
        <dx-button stylingMode="text" type="default" icon="plus" (onClick)="showCreateValidationMethodPopup()"></dx-button>
        <dx-button stylingMode="text" type="default" icon="copy" (onClick)="showDuplicateValidationMethodPopup()"></dx-button>
        <dx-button *ngIf="!isReadOnlyForUser(selectedValidationMethod)"
                   stylingMode="text" type="default" icon="trash" (onClick)="deleteValidationMethod()"></dx-button>
      </div>
      <div *ngIf="selectedValidationMethod != undefined && !isReadOnlyForUser(selectedValidationMethod)"
           style="display: flex; justify-content: flex-end;">
        <dx-button stylingMode="text" type="default" text="{{ 'Neue Gruppe' | translate }}" (onClick)="createValidationGroup()"></dx-button>
      </div>
      <dx-tree-view [dataSource]="validationGroups"
                    itemsExpr="rules"
                    [noDataText]="noDataText"
                    [selectionMode]="'single'"
                    [selectByClick]="true"
                    (onSelectionChanged)="onValidationItemSelection($event)">
        <div *dxTemplate="let item of 'item'" style="width: 100%;">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div *ngIf="itemIsValidationGroup(item)">
              {{ item.name }}
            </div>
            <div *ngIf="!itemIsValidationGroup(item) && !item.isConditional">
              {{ item.printName }}
            </div>
            <div *ngIf="!itemIsValidationGroup(item) && item.isConditional">
              {{ item.printName }} {{ '(Bedingung)' | translate }}
            </div>
            <div class="ml-auto">
              <dx-button *ngIf="itemIsValidationGroup(item) && !isReadOnlyForUser(selectedValidationMethod)"
                         stylingMode="text" type="default" icon="plus" (onClick)="createValidationRule(item)"></dx-button>
              <dx-button *ngIf="!isReadOnlyForUser(selectedValidationMethod)"
                         stylingMode="text" type="default" icon="copy" (onClick)="duplicateValidationItem(item)"></dx-button>
              <dx-button *ngIf="!isReadOnlyForUser(selectedValidationMethod)"
                         stylingMode="text" type="default" icon="trash" (onClick)="deleteValidationItem(item)"></dx-button>
            </div>
          </div>
        </div>
      </dx-tree-view>
    </div>
  </navigation-column>
  <content-column helpSwitch="true" look="light">    
      <div slot="content" class="p-2 flex-fill overflow-hidden">
        <div *ngIf="selectedValidationItem == undefined && selectedValidationMethod != undefined"
             class="p-2 overflow-hidden h-100" style="display: grid; grid-template-rows: [first] 100px;">
          <h4 style="text-align:center">{{ 'Datenqualität-Set' | translate }}</h4>
          <dx-scroll-view [useNative]="true" showScrollbar="'always'">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'Name' | translate }}</div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationMethod.name"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div *ngIf="isAdminUser() && !selectedValidationMethod.isShared && selectedValidationMethod.createdByCustomerId" class="dx-field">
              <div id="isGlobal" class="dx-field-label">{{ 'Global' | translate }}</div>
              <dx-tooltip target="#isGlobal" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'isGlobalTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-switch [(value)]="selectedValidationMethod.isGlobal"
                           [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                           (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-switch>
              </div>
            </div>
            <div *ngIf="isAdminUser() && selectedValidationMethod.isGlobal" class="dx-field">
              <div id="isVisible" class="dx-field-label">{{ 'Sichtbar' | translate }}</div>
              <dx-tooltip target="#isVisible" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'isVisibleTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-switch [(value)]="selectedValidationMethod.isVisible"
                           [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                           (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-switch>
              </div>
            </div>
            <div *ngIf="!selectedValidationMethod.isGlobal" class="dx-field">
              <div id="isShared" class="dx-field-label">{{ 'Teilbar' | translate }}</div>
              <dx-tooltip target="#isShared" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'isSharedTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-switch [(value)]="selectedValidationMethod.isShared"
                           [readOnly]="isReadOnlyForUser(selectedValidationMethod) || isSharedIsReadOnly(selectedValidationMethod)"
                           (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-switch>
              </div>
            </div>
            <div *ngIf="selectedValidationMethod.isShared && isAdminUser()" class="dx-field">
              <div id="sharedToCustomers" class="dx-field-label">{{ 'zugeteilten Kunden' | translate }}</div>
              <dx-tooltip target="#sharedToCustomers" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'sharedToCustomerTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-tag-box [dataSource]="sharedToCustomersDataSource"
                            [(value)]="selectedValidationMethod.sharedToCustomers"
                            displayExpr="name"
                            valueExpr="id"
                            [searchEnabled]="true"
                            [showSelectionControls]="true"
                            (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-tag-box>
              </div>
            </div>
          </dx-scroll-view>
        </div>
        <div *ngIf="selectedValidationItem != undefined && itemIsValidationGroup(selectedValidationItem)"
             class="p-2 overflow-hidden h-100" style="display: grid; grid-template-rows: [first] 100px;">
          <h4 style="text-align:center">{{ 'Gruppe' | translate }}</h4>
          <dx-scroll-view [useNative]="true" showScrollbar="'always'">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'Name' | translate }}</div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.name"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="groupOrder" class="dx-field-label">{{ 'Order' | translate }}</div>
              <dx-tooltip target="#groupOrder" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'GroupOrderTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.order"
                               format="0#"
                               [showSpinButtons]="true"
                               [showClearButton]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="groupBackgroundColor" class="dx-field-label">{{ 'Hintergrundfarbe' | translate }}</div>
              <dx-tooltip target="#groupBackgroundColor" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'GroupBackgroundColorTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-color-box [(value)]="selectedValidationItem.backgroundColor"
                              applyButtonText="{{ 'Bestätigen' | translate }}"
                              cancelButtonText="{{ 'Abbrechen' | translate }}"
                              [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                              (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-color-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="groupInvalidStateText" class="dx-field-label">{{ 'Invalider Status-Text' | translate }}</div>
              <dx-tooltip target="#groupInvalidStateText" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'GroupInvalidStateTextTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.stateText"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="groupIgnoreForReport" class="dx-field-label">{{ 'Für Reports ignorieren' | translate }}</div>
              <dx-tooltip target="#groupIgnoreForReport" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'GroupIgnoreForReportTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-switch [(value)]="selectedValidationItem.ignoreForReport"
                           [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                           (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-switch>
              </div>
            </div>
          </dx-scroll-view>
        </div>
        <div *ngIf="selectedValidationItem != undefined && !itemIsValidationGroup(selectedValidationItem)"
             class="p-2 overflow-hidden h-100" style="display: grid; grid-template-rows: [first] 100px;">
          <h4 style="text-align:center">{{ 'Regel' | translate }}</h4>
          <dx-scroll-view [useNative]="true" showScrollbar="'always'">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'Name' | translate }}</div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.printName"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="ruleOrder" class="dx-field-label">{{ 'Order' | translate }}</div>
              <dx-tooltip target="#ruleOrder" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleOrderTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.order"
                               format="0#"
                               [showSpinButtons]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="RuleFieldGroup" class="dx-field-label">{{ 'Feld-Gruppe' | translate }}</div>
              <dx-tooltip target="#RuleFieldGroup" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleFieldGroupTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-select-box [dataSource]="fieldGroups"
                               [(value)]="selectedValidationItem.field.fieldGroup"
                               (onSelectionChanged)="onFieldGroupChanged($event)"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               valueExpr="value"
                               displayExpr="translated"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-select-box>
              </div>
            </div>
            <div *ngIf="selectedValidationItem.field.fieldGroup != undefined" class="dx-field">
              <div id="RuleFieldName" class="dx-field-label">{{ 'Feld-Name' | translate }}</div>
              <dx-tooltip target="#RuleFieldName" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleFieldNameTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-select-box [dataSource]="fieldNamesInGroup"
                               [(value)]="selectedValidationItem.field.fieldName"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               valueExpr="value"
                               displayExpr="translated"
                               (onValueChanged)="changeFieldNameAndSaveValidationMethod(selectedValidationMethod, $event)">
                </dx-select-box>
              </div>
            </div>
            <div class="dx-field">
              <div id="RuleInvalidStateText" class="dx-field-label">{{ 'Invalider Status-Text' | translate }}</div>
              <dx-tooltip target="#RuleInvalidStateText" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleInvalidStateTextTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.stateText"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled() && !selectedValidationItem.isConditional" class="dx-field">
              <div id="RuleWeight" class="dx-field-label">{{ 'Punkte (Gewichtung)' | translate }}</div>
              <dx-tooltip target="#RuleWeight" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleWeightTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.points"
                               [showSpinButtons]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldIsList() && !selectedValidationItem.isConditional" class="dx-field">
              <div id="RuleWeightForElement" class="dx-field-label">{{ 'Punkte pro Element (Gewichtung)' | translate }}</div>
              <dx-tooltip target="#RuleWeightForElement" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleWeightForElementTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.pointsPerCount"
                               [showSpinButtons]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldIsList() && !selectedValidationItem.isConditional" class="dx-field">
              <div id="RuleWeightMax" class="dx-field-label">{{ 'Maximale Punkte' | translate }}</div>
              <dx-tooltip target="#RuleWeightMax" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleWeightMaxTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.maxPoints"
                               [showSpinButtons]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled()" class="dx-field">
              <div id="RuleMandatoryField" class="dx-field-label">{{ 'Pflichtfeld' | translate }}</div>
              <dx-tooltip target="#RuleMandatoryField" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleMandatoryFieldTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-switch [(value)]="selectedValidationItem.isRequired"
                           [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                           (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-switch>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled() && selectedItemFieldIsList()" class="dx-field">
              <div id="RuleMinListCount" class="dx-field-label">{{ 'Minimale Anzahl' | translate }}</div>
              <dx-tooltip target="#RuleMinListCount" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleMinListCountTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.minCount"
                               format="#"
                               [showSpinButtons]="true"
                               [showClearButton]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled() && !selectedItemFieldIsList() && !selectedItemFieldIsBool()" class="dx-field">
              <div id="RuleMinLength"class="dx-field-label">{{ 'Minimale Länge' | translate }}</div>
              <dx-tooltip target="#RuleMinLength" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleMinLengthTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.minLength"
                               format="#"
                               [showSpinButtons]="true"
                               [showClearButton]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldIsPicture()" class="dx-field">
              <div id="RuleMinWidth" class="dx-field-label">{{ 'Minimale Breite' | translate }}</div>
              <dx-tooltip target="#RuleMinWidth" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleMinWidthTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.minWidth"
                               format="#"
                               [showSpinButtons]="true"
                               [showClearButton]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldIsPicture()" class="dx-field">
              <div id="RuleMinHeight" class="dx-field-label">{{ 'Minimale Höhe' | translate }}</div>
              <dx-tooltip target="#RuleMinHeight" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleMinHeightTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-number-box [(value)]="selectedValidationItem.minHeight"
                               format="#"
                               [showSpinButtons]="true"
                               [showClearButton]="true"
                               [min]="0"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-number-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled() && !selectedItemFieldIsList()" class="dx-field">
              <div id="RulePattern" class="dx-field-label">{{ 'Muster (Regex)' | translate }}</div>
              <dx-tooltip target="#RulePattern" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RulePatternTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.pattern"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled()" class="dx-field">
              <div id="RuleErrorText" class="dx-field-label">{{ 'Fehlertext' | translate }}</div>
              <dx-tooltip target="#RuleErrorText" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleErrorTextTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-text-box [(value)]="selectedValidationItem.displayMessage"
                             [showClearButton]="true"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-text-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled() && !selectedValidationItem.isConditional" class="dx-field">
              <div id="RuleArea" class="dx-field-label">{{ 'Bereich' | translate }}</div>
              <dx-tooltip target="#RuleArea" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleAreaTooltip' | translate }}</div>
              </dx-tooltip>
              <div class="dx-field-value">
                <dx-select-box [dataSource]="dataQualityAreas"
                               [(value)]="selectedValidationItem.area"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                </dx-select-box>
              </div>
            </div>
            <div *ngIf="selectedItemFieldFilled()" class="dx-fieldset">
              <div class="dx-fieldset-header">{{ 'Filter und Bedingungen' | translate }}</div>
              <div *ngIf="showFeatureSystemNameFilter()" class="dx-field">
                <div id="FeatureSystemNameFilter" class="dx-field-label">{{ 'Featuresystem-Name' | translate }}</div>
                <dx-tooltip target="#FeatureSystemNameFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'FeatureSystemNameFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.featureSystemNameFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showFeatureNameFilter()" class="dx-field">
                <div id="FeatureNameFilter" class="dx-field-label">{{ 'Feature-Name' | translate }}</div>
                <dx-tooltip target="#FeatureNameFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'FeatureNameFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.featureNameFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showMimeTypeFilter()" class="dx-field">
                <div id="MimeTypeFilter" class="dx-field-label">{{ 'Mime-Typ' | translate }}</div>
                <dx-tooltip target="#MimeTypeFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'MimeTypeFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.mimeTypeFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showMimePurposeFilter()" class="dx-field">
                <div id="MimePurposeFilter" class="dx-field-label">{{ 'Mime-Verwendung' | translate }}</div>
                <dx-tooltip target="#MimePurposeFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'MimePurposeFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.mimePurposeFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showMimeDescriptionFilter()" class="dx-field">
                <div id="MimeDescriptionFilter" class="dx-field-label">{{ 'Mime-Description' | translate }}</div>
                <dx-tooltip target="#MimeDescriptionFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'MimeDescriptionFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.mimeDescriptionFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showReferenceTypeFilter()" class="dx-field">
                <div id="ReferenceTypeFilter" class="dx-field-label">{{ 'Referenz-Typ' | translate }}</div>
                <dx-tooltip target="#ReferenceTypeFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'ReferenceTypeFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.referenceTypeFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showUdxGroupFilter()" class="dx-field">
                <div id="UdxGroupFilter" class="dx-field-label">{{ 'Udx-Gruppe' | translate }}</div>
                <dx-tooltip target="#UdxGroupFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'UdxGroupFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.udxGroupFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showSupplierNameFilter()" class="dx-field">
                <div id="SupplierNameFilter" class="dx-field-label">{{ 'Supplier-Name' | translate }}</div>
                <dx-tooltip target="#SupplierNameFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'SupplierNameFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.supplierNameFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showPackingUnitCodeFilter()" class="dx-field">
                <div id="UnitCodeFilter" class="dx-field-label">{{ 'Einheiten-Code' | translate }}</div>
                <dx-tooltip target="#UnitCodeFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'UnitCodeFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.packingUnitCodeFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div *ngIf="showPriceListLiveFilter()" class="dx-field">
                <div id="CurrentPriceListFilter" class="dx-field-label">{{ 'Aktuelle Preisliste' | translate }}</div>
                <dx-tooltip target="#CurrentPriceListFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'CurrentPriceListFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-switch [(value)]="selectedValidationItem.priceListLiveFilter"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-switch>
                </div>
              </div>
              <div *ngIf="showPriceTypeFilter()" class="dx-field">
                <div id="PriceTypeFilter" class="dx-field-label">{{ 'Preis-Typ' | translate }}</div>
                <dx-tooltip target="#PriceTypeFilter" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'PriceTypeFilterTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="selectedValidationItem.priceTypeFilter"
                               [showClearButton]="true"
                               [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                               (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-text-box>
                </div>
              </div>
              <div class="dx-field">
                <div id="RuleIsCondition" class="dx-field-label">{{ 'ist Bedingung' | translate }}</div>
                <dx-tooltip target="#RuleIsCondition" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleIsConditionTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-switch [(value)]="selectedValidationItem.isConditional"
                             [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                             (onValueChanged)="onIsConditionalChanged($event)">
                  </dx-switch>
                </div>
              </div>
              <div class="dx-field">
                <div id="RuleConditions" class="dx-field-label">{{ 'Bedingungen' | translate }}</div>
                <dx-tooltip target="#RuleConditions" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">{{ 'RuleConditionsTooltip' | translate }}</div>
                </dx-tooltip>
                <div class="dx-field-value">
                  <dx-tag-box [dataSource]="conditionalRules"
                              valueExpr="id"
                              displayExpr="printName"
                              [(value)]="selectedValidationItem.conditionalRuleIds"
                              [readOnly]="isReadOnlyForUser(selectedValidationMethod)"
                              (onValueChanged)="saveValidationMethod(selectedValidationMethod, $event)">
                  </dx-tag-box>
                </div>
              </div>
            </div>
          </dx-scroll-view>
        </div>
      </div>      
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>

<dx-popup [width]="500"
          [height]="300"
          [showTitle]="true"
          title="{{ 'Datenqualität-Set erstellen' | translate }}"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          contentTemplate="popupContent"
          [(visible)]="createValidationMethodPopupVisible">
  <dxi-toolbar-item widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    [options]="{ text: 'Erstellen' | translate, onClick: createValidationMethod }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Name' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newValidationMethod.name" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
    <div *ngIf="isAdminUser()" class="dx-field">
      <div class="dx-field-label">{{ 'Global' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="newValidationMethod.isGlobal"></dx-switch>
      </div>
    </div>
  </div>
</dx-popup>
