<!--97 apply Wawi-Mappings-->
<div class="dx-field">
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ 'Wert->Schlüssel' | translate }}</div>
      <dx-switch [(value)]="applyWawiMappingModel.exportMode"></dx-switch>
      <div class="dx-field-label switch-right">{{ 'Schlüssel->Wert' | translate }}</div>
    </div>
  </div>
</div>

<wawiMappingChooser [fields]="fields"
                    [fieldGroups]="fieldGroups"
                    [(exportParameter)]="config"
                    [exportMode]="applyWawiMappingModel.exportMode">
</wawiMappingChooser>
