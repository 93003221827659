export class HazmatClass  {

  constructor(
    public id?: string,
    public customerId?: string,
    public number?: string,
    public name?: string,
    public hazardStatements?: string[],
    public hazardPictograms?: string[],
    public precautionaryStatements?: string[],
  ) {
  }
}


