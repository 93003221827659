import { Input, Output, EventEmitter, Component } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from "../../Services/Validation/validation.service";


@Component({
  selector: "np-booleanRadioGroup",
  templateUrl: "booleanRadioGroup.component.html"
})

export class NpBooleanRadioGroupComponent {
  trueValue: string;
  falseValue: string;
  nullValue: string;
  _value: boolean;
  @Input() fieldName: string;
  @Input() readOnly: boolean;
  @Input() templateView: Boolean = false;
  @Input() validatorValidationGroup: string;

  @Input()
  set value(v: boolean) {
    this._value = v;
    if (v == null) {
      this.selectedValue = this.nullValue;
    }
    else {
      if (v == true) {
        this.selectedValue = this.trueValue;
      }
      else {
        this.selectedValue = this.falseValue;
      }
    }
  };

  get value(): boolean {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<boolean>();
  model: string[] = new Array<string>();
  selectedValue: string;

  @Output() onValueChanged = new EventEmitter<any>();



  constructor(public translate: TranslateService, public validationService: ValidationService) {
    this.validate = this.validate.bind(this);

    this.trueValue = this.translate.instant("Ja");
    this.falseValue = this.translate.instant("Nein");
    this.nullValue = this.translate.instant("Keine Angabe");

    this.model.push(this.trueValue);
    this.model.push(this.falseValue);
    this.model.push(this.nullValue);

  }

  valueChanged(event) {
    if (event.value == event.previousValue)
      return;
    this.value = this.reConvert(this.selectedValue);
    this.valueChange.emit(this.value);

    event.value = this.reConvert(event.value);
    event.previousValue = this.reConvert(event.previousValue);

    this.onValueChanged.emit(event);
  }

  validate(params) {
    if (!this.fieldName) {
      params.rule.isValid = true;
      params.validator.validate();
    }

    params.value = this.reConvert(params.value);
    return this.validationService.validateBooleanRadioGroup(params, this.fieldName);
  }

  reConvert(value: string): boolean {
    if (value == this.trueValue) {
      return true;
    }
    if (value == this.falseValue) {
      return false;
    }
    if (value == this.nullValue) {
      return null;
    }
  }
}
