import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MediaPool } from "../../../Model/Catalog/MediaPool";
import { DataSourceParams } from "../../../Model/Catalog/DataSourceParams";
import { ModelService } from "../../../Services/model.service";
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from "devextreme-angular";

@Component({
  selector: "jobdata",
  templateUrl: "jobdata.component.html",
  styleUrls: []
})
export class JobDataComponent {

  private _visible: boolean = false;

  public get visible() {
    return this._visible;
  }

  @Input()
  public set visible(val: boolean) {
      if (this._visible !== val) {
          this._visible = val;
          this.visibleChange.emit(val);
      }        
  }

  @Output() public visibleChange = new EventEmitter<boolean>();


  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent

  dataSource: any = {};
  mediaPool: MediaPool;
  constructor(public modelService: ModelService) {
    this.reload = this.reload.bind(this);

    this.dataSource.store = new CustomStore({
      load: function (loadOptions: any) {

        var params = new DataSourceParams();

        params.skip = loadOptions.skip || 0;
        params.take = loadOptions.take || 20;

        if (loadOptions.sort) {
          params.orderBy = loadOptions.sort[0].selector;
          if (loadOptions.sort[0].desc) {
            params.desc = true;
          }
        }
        if (loadOptions.filter) {
          if (loadOptions.filter[2]) {
            params.filter = loadOptions.filter[2];
          }
        }
        return modelService.mediaService.getJobDataElements(modelService.loginService.currentUser.customerId, params)
          .then((data: any) => {
            let mediaPool = data;
            return {
              data: mediaPool.items,
              totalCount: mediaPool.totalCount
            }
          })
          .catch(() => { throw 'Data Loading Error' });
      }
    });

  }

  getFileSizeString(size) {
    return this.modelService.systemService.getFileSizeString(size);
  }

  deleteRequest(data) {
    this.modelService.mediaService.deleteJobFile(data.data.mimeSource);
    this.dataGrid.instance.refresh();
  }

  reload() {
    console.log(this.dataSource);
    this.dataGrid.instance.refresh();

  }

}
