export class OxomiConnection {

  constructor(
    public id?: number,
    public customerId?: string,
    public portalId?: string,
    public apiToken?: string,
    public apiSecret?: string,
    public portalSecret?: string,
    public user?: string	
  ) {
  }
}