export class FtpServer {

  constructor(
    public id?: number,
    public name?: string,
    public address?: string,
    public importDirectory?: string,
    public exportDirectory?: string,
    public user?: string,
    public password?: string,
    public port?: number,
    public mode?: string,
    public customerId?: string,
    public privateKey?: string,
    public useKey?: boolean,
    public passPhrase?: string
  ) { }
}
