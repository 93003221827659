import { NextPimField } from '../Catalog/NextPimField';
import { TemplateItem } from '../Catalog/TemplateItem';

export class ExcelElement {
  constructor(
    public pimFields: NextPimField[] = [],
    public id: string = '',
    public title: string = '',
    public row1: string = '',
    public row2: string = '',
    public calculation: string = '',
    public numberformat: string = '',
    public seperator: string = '',
    public mapping: string = '',
    public remove: string = '',
    public inUse: boolean = false,
    public addprev: string = '',
    public addfoll: string = '',
    public defaultValue: string = '',
    public preview: string = '',
    public displayName: string = '',
    public isDisplayNameRequested: boolean = false,
    public maxCharacters: number = 0,
    public section: number = 1,
    public keys?: string[],
    public languageCode?: string,
    public factorOperator: string = '',
    public factor: number = 1,
    public prefix: string = '',
    public suffix: string = '',
    public catName: string = '',
    public name: string = ''
  ) {}

  toTemplateItem() {
    return new TemplateItem(
      this.pimFields,
      null,
      this.catName,
      this.seperator,
      null,
      this.defaultValue,
      null,
      this.catName,
      this.name,
      [this.row1, this.row2],
      this.calculation,
      this.numberformat,
      this.mapping,
      this.remove,
      true,
      this.addprev,
      this.addfoll,
      this.maxCharacters,
      this.section,
      this.keys,
      this.languageCode,
      null,
      this.factorOperator,
      this.factor,
      this.prefix,
      this.suffix
    );
  }

  update(templateItem: TemplateItem) {
    this.catName = templateItem.catName;
    this.seperator = templateItem.seperator;
    this.defaultValue = templateItem.defaultValue;
    this.calculation = templateItem.calculation;
    this.numberformat = templateItem.numberformat;
    this.mapping = templateItem.mapping;
    this.remove = templateItem.remove;
    this.addprev = templateItem.addprev;
    this.addfoll = templateItem.addfoll;
    this.maxCharacters = templateItem.maxCharacters;
    this.section = templateItem.section;
    this.keys = templateItem.keys;
    this.languageCode = templateItem.languageCode;
    this.factorOperator = templateItem.factorOperator;
    this.factor = templateItem.factor;
    this.prefix = templateItem.prefix;
    this.suffix = templateItem.suffix;
    this.pimFields = templateItem.pimFields;
    this.name = templateItem.name;
    this.row1 = templateItem.previewValues?.[0] || '';
    this.row2 = templateItem.previewValues?.[1] || '';
    this.preview = templateItem.previewValues?.[0] || '';
  }

  updateImportFieldUsed() {
    this.inUse = this.pimFields.some((x) => x.field != null && x.field != undefined && x.field != '');
  }
}
