import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";

@Component({
  selector: "userAction-splitFeatureValuesFromUnit",
  templateUrl: "userAction-splitFeatureValuesFromUnit.component.html"
})
export class UserActionSplitFeatureValuesFromUnitComponent extends UserActionBaseComponent implements OnInit {
  foundUnitsToBeSplit: string[] = [];
  selectedUnits: string[];
  unitsLoading: boolean = false;

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  loadUnits() {
    this.foundUnitsToBeSplit.length = 0;
    this.unitsLoading = true;
    this.modelService.catalogService.getUnitsThatCanBeSplitFromFeatureValues(
      this.modelService.catalogService.selectedCatalogId,
      this.modelService.loginService.currentUser.customerId)
      .subscribe((unit) => {
        this.foundUnitsToBeSplit.push(...unit);

        this.unitsLoading = false;
      });
  }

  addCustomUnit(unit) {
    if (!unit.text) {
      unit.customItem = null;
      return;
    }
    this.foundUnitsToBeSplit.push(unit.text);
    this.selectedUnits.push(unit.text);
  }

  startMacro() {
    if (!this.selectedUnits || this.selectedUnits.length == 0)
      return;

    this.modelService.catalogService.showUserAction = false;

    this.modelService.jobService.startUserAction(this.createUserAction()).subscribe(next => {
      this.userActionStarted.emit(next);
    });
  }

  createUserAction(): StartAction {
    let userAction = new StartAction();
    userAction.catalogId = this.modelService.catalogService.catalog.id;
    userAction.customerId = this.modelService.loginService.currentUser.customerId;
    userAction.actionId = `SplitFeatureValuesFromUnit(${this.createParameterStringAsJsonString()})`;
    return userAction;
  }

  createParameterStringAsJsonString(): string {
    let parameterJsonString = JSON.stringify(this.selectedUnits);
    return parameterJsonString;
  }
}
