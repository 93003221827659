import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
import dxButton from 'devextreme/ui/button';
import dxDropDownButton from 'devextreme/ui/drop_down_button';
import { NpHelpButtonComponent, NpHelpOption } from '../helpButton/NpHelpButton.component';

//<dxi-item slot="toolbar" location="before" widget="dxButton" [options]="{ text: 'nextCat ' + ('exportieren' | translate), onClick: exportBmeNextClicked }"></dxi-item>
@Directive({
  selector: 'np-dialog-button'
})
export class NpDialogButton {
  @Input() public toolbar: 'top' | 'bottom';
  @Input() public location: 'before' | 'after';
  @Input() public widget: string;
  @Input() public options: Record<string, unknown>;
}

@Component({
  selector: 'np-dialog',
  templateUrl: 'NpDialog.component.html',
  styleUrls: ['./NpDialog.component.css']
})
export class NpDialogComponent implements AfterViewInit {
  @Input() popupWidth? = 1000;
  @Input() popupHeight? = 800;
  @Input() popupTitle?: string;
  @Input() closeOnOutsideClick? = true;

  @Input() popupVisible? = false;
  @Output() popupVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Input() popupMaximized? = false;
  @Output() popupMaximizedChange: EventEmitter<boolean> = new EventEmitter();

  private _popup: DxPopupComponent;
  private _buttons: QueryList<NpDialogButton>;
  private _helpButton: NpHelpButtonComponent;

  private _windowButton: dxButton;
  private _fullscreenButton: dxButton;

  public toolbarItems = new Array<any>();

  constructor() {
    this.popupToWindow = this.popupToWindow.bind(this);
    this.popupToFullscreen = this.popupToFullscreen.bind(this);
  }

  refreshToolbarItems(): void {
    if (this._buttons) {
      const self = this;

      this.toolbarItems.length = 0;

      this.toolbarItems.push({
        toolbar: 'top',
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: this.popupMaximized,
          icon: 'chevrondown',
          onClick: this.popupToWindow,
          onInitialized: (e: { component: dxButton }) => {
            self._windowButton = e.component;
          }
        }
      });
      this.toolbarItems.push({
        toolbar: 'top',
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: !this.popupMaximized,
          icon: 'chevronup',
          onClick: this.popupToFullscreen,
          onInitialized: (e: { component: dxButton }) => {
            self._fullscreenButton = e.component;
          }
        }
      });

      if (self._helpButton != undefined) {
        this.toolbarItems.push({
          toolbar: 'top',
          location: 'after',
          widget: 'dxDropDownButton',
          options: {
            items: self._helpButton.options,
            icon: 'help',
            displayExpr: 'text',
            keyExpr: 'text',
            showArrowIcon: false,
            stylingMode: 'text',
            useSelectMode: false,
            wrapItemText: false,
            dropDownOptions: { width: '500%' },
            onItemClick: self._helpButton.openHelpLink
          }
        });
      }

      this.toolbarItems.push(
        ...this._buttons?.map((b) => {
          return { toolbar: b.toolbar, location: b.location, widget: b.widget, options: b.options };
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.refreshToolbarItems();
  }

  @ContentChildren(NpDialogButton, { descendants: true })
  public set contentButtons(buttons: QueryList<NpDialogButton>) {
    this._buttons = buttons;
    this.refreshToolbarItems();
  }

  @ContentChildren(NpHelpButtonComponent, { descendants: true })
  public set helpButton(childs: QueryList<NpHelpButtonComponent>) {
    if (childs != undefined) {
      this._helpButton = childs.first;
      this.refreshToolbarItems();
    }
  }

  @ViewChild(DxPopupComponent)
  public set popup(popup: DxPopupComponent) {
    this._popup = popup;
  }

  public get visible() {
    return this.popupVisible;
  }

  public set visible(val: boolean) {
    if (this.popupVisible !== val) {
      this.popupVisible = val;
      this.popupVisibleChange.emit(val);
    }
  }

  public popupToWindow() {
    if (this.popupMaximized) {
      this.popupMaximized = false;
      this._fullscreenButton.option('visible', true);
      this._windowButton.option('visible', false);
      this.popupMaximizedChange.emit(false);
    }
  }

  public popupToFullscreen() {
    if (!this.popupMaximized) {
      this.popupMaximized = true;
      this._fullscreenButton.option('visible', false);
      this._windowButton.option('visible', true);
      this.popupMaximizedChange.emit(true);
    }
  }
}
