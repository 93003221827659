import { Component, Input, AfterViewInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { EdeSortimentService } from '../../Services/edeSortiment.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../Services/model.service';
import { EdeSortiment } from '../../Model/edeSortimentsAbo/EdeSortiment';
import { EdeSortimentVersion } from '../../Model/edeSortimentsAbo/edeSortimentVersion';

@Component({
  selector: 'edeSortimentsVersion',
  templateUrl: 'edeSortimentsVersion.component.html'
})
export class EdeSortimentsVersionComponent implements AfterViewInit {
  @Input() sortiment: EdeSortiment;
  versions: DataSource;
  closeButtonOptions: any;
  popupVisible = false;
  selectedVersion = -1;

  constructor(public modelService: ModelService, public translate: TranslateService, public edeSortimentsService: EdeSortimentService) {
    this.ngAfterViewInit = this.ngAfterViewInit.bind(this);
    this.isInstalled = this.isInstalled.bind(this);
    this.install = this.install.bind(this);
    this.getText = this.getText.bind(this);
    let that = this;
    this.closeButtonOptions = {
      text: 'Accept',
      onClick(e) {
        that.edeSortimentsService.addEdeInvoice(that.sortiment.updateBez, that.modelService.loginService.currentCustomer.id, that.sortiment.katalogIdent).subscribe(() => {
          that.edeSortimentsService.startUpdateEDEJob(that.modelService.loginService.currentCustomer.id, that.sortiment.katalogIdent, that.selectedVersion).subscribe(() => {
            that.modelService.systemService.notifyInfo(that.translate.instant("Job wurde gestartet"));
          });
        });


        that.popupVisible = false;
      },
    };
  }

  ngAfterViewInit() {
    let that = this;
    this.versions = new DataSource({
      load: function () {
        return that.sortiment.versions;
      }
    });
  }

  public isInstalled(data) {
    return data.data.version == this.sortiment.installedVersion;
  }

  public canUpdateTo(data) {
    return data.data.version > this.sortiment.installedVersion;
  }


  public isOwned() {
    return this.sortiment.owned;
  }

  public install(data) {
    if (!this.sortiment.owned) {
      this.modelService.systemService.notifyWarning(this.translate.instant("Ihrerer Ede Nummer wurden nicht die Rechte für dieses Sortiment zugewiesen. Sollte das nicht stimmen, melden Sie sich bitte bei unserem Support."));
      return;
    }

    if (this.sortiment.installedVersion >= 0) {
      this.edeSortimentsService.startUpdateEDEJob(this.modelService.loginService.currentCustomer.id, this.sortiment.katalogIdent, data.key.version).subscribe(() => {
        this.modelService.systemService.notifyInfo(this.translate.instant("Job wurde gestartet"));
      });
    } else {
      this.selectedVersion = data.key.version;
      this.popupVisible = true;
    }
  }

  public getText() {
    if (!this.sortiment.owned)
      return this.translate.instant("Keinen Zugriff");

    if (this.sortiment.installedVersion >= 0) {
      return this.translate.instant("Update");
    } else {
      return this.translate.instant("Installieren");
    }

  }
}
