<div class="dx-fieldset" style="max-width: 600px;">
  <!--  <div class="dx-field">
      <div class="dx-field-label jobListTD">{{ 'Gevis Url' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [readOnly]="true" [(value)]="gevisUrl" [showClearButton]="true"></dx-text-box>
      </div>
    </div>-->
  <!--<div class="dx-field">
    <div class="dx-field-label">{{ 'Klassenmodus' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="useClassification" switchedOffText="{{ 'Kategorien' | translate }}" switchedOnText="{{ 'Klassifikation' | translate }}"></dx-switch>
    </div>
  </div>
  <div *ngIf="useClassification" class="dx-field">
    <div class="dx-field-label">{{ 'KlassifikationsFeaturesystem' | translate }}</div>
    <div class="dx-field-value">
      <dx-select-box [(value)]="featureSystemName"
                     [items]="featureSystemNames"
                     placeholder=""></dx-select-box>
    </div>
  </div>-->

  <div class="dx-field">
    <div>{{ 'Die Gevis Schnittstelle ist jetzt und zukünftig nur noch im Export Dialog nutzbar (Schnittstele => Gevis)' | translate }}</div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Gevis XML erstellen' | translate }}</div>
    <!--<dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Senden' | translate , onClick: send }"></dxi-item>-->
    <div class="dx-field-value">
      <dx-button text="{{ 'Export starten' | translate }}" (onClick)="send()"></dx-button>
    </div>
  </div>
</div>

