
export class NextPimField {
  constructor(
    public elementKey?: string,
    public systemKey?: string,
    public field?: string
  ) {
  }
}

export function HasSystemKey(nextPimField: NextPimField): boolean {
  if (!nextPimField.systemKey)
    return false;
  if (nextPimField.systemKey == "-1")
    return false;
  if (nextPimField.systemKey == "0")
    return false;
  return true;
}

export function HasElementKey(nextPimField: NextPimField): boolean {
  if (!nextPimField.elementKey)
    return false;
  if (nextPimField.elementKey == "-1")
    return false;
  if (nextPimField.elementKey == "0")
    return false;
  return true;
}
