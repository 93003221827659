import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'seo_component',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css'],
})
export class Seo_Component {
  @Input()
  public get metaTitel()  : string {
    return this._metaTitle;
  }
  public set metaTitel(value: string) {
    this._metaTitle = value;
    this.initValues();
  } 

  @Input()
  public get metaDescription() : string {
    return this._metaDescription;
  }
  public set metaDescription(value: string) {
    this._metaDescription = value;
    this.initValues();
  }

  _metaDescription: string;
  _metaTitle: string;
  metaString: string;
  maxValue: number;
  progressValue: number;
  progressColor: string;
  progressText: string;

  constructor(public translate: TranslateService) { }


  initValues() {
    this.getProgressValue();
    this.getProgressBarText();
    this.getProgressBarBackgroundColor();      ;
  }


  getProgressValue() {
    if (this.metaTitel != undefined) {
      this.metaString = this.metaTitel
      this.maxValue = 74
    } else {
      this.metaString = this.metaDescription
      this.maxValue = 147
    }
    const textLength = this.metaString?.length;
    if (textLength <= this.maxValue) {
      this.progressValue = (textLength / this.maxValue) * 100;
    } else {
      this.progressValue = 100;
    }
  }


  getProgressBarBackgroundColor() {

    if (this.metaTitel != undefined) {
      if (this.metaTitel.length <= 49) {
        this.progressColor = 'olivedrab';
      } else if (this.metaTitel.length <= 59) {
        this.progressColor = 'orange';
      } else {
        this.progressColor = 'firebrick';
      }
    } else {
      if (this.metaDescription.length <= 114) {
        this.progressColor = 'olivedrab';
      } else if (this.metaDescription.length <= 121) {
        this.progressColor = 'orange';
      } else {
        this.progressColor = 'firebrick';
      }
    }
  }

  getProgressBarText() {
    if (this.metaTitel != undefined) {
      if (this.metaTitel.length <= 49) {
        this.progressText = this.translate.instant("GUT");
      } else if (this.metaTitel.length <= 59) {
        this.progressText = this.translate.instant("RISKANT");
      } else {
        this.progressText = this.translate.instant("ZU LANG!");
      }
    } else {
      if (this.metaDescription.length <= 114) {
        this.progressText = this.translate.instant("GUT");
      } else if (this.metaDescription.length <= 121) {
        this.progressText = this.translate.instant("RISKANT");
      } else {
        this.progressText = this.translate.instant("ZU LANG!");
      }
    }
  }
}
