import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { TranslateService } from "@ngx-translate/core";
import { UserActionBaseComponent } from "../userAction-base.component";

@Component({
  selector: "userAction-markAsChanged",
  templateUrl: "userAction-markAsChanged.component.html"
})
export class UserActionMarkAsChangedComponent extends UserActionBaseComponent {
  markCategoriesAsChanged: boolean = false;
  markProductsAsChanged: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  startMacro() {
    if (!(this.markCategoriesAsChanged || this.markProductsAsChanged)) {
      this.modelService.systemService.notifyInfo(this.translate.instant("mindestens ein Schalter"));
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "MarkAsChanged";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify({
      markCategoriesAsChanged: this.markCategoriesAsChanged,
      markProductsAsChanged: this.markProductsAsChanged
    });
  }
}
