<!--84 rename features-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Featuresystem Name' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedFeatureSystem?.name?.length > 0 ? 'placeLabelSmall' : '' }}">REFERENCE FEATURESYSTEM NAME</label>
    <dx-select-box *ngIf="featureSystemsLoading == false"
                   [(value)]="selectedFeatureSystem"
                   [items]="featureSystems"
                   displayExpr="name"
                   [searchEnabled]="true"
                   [allowColumnResizing]="true"
                   (onValueChanged)="onFeatureSystemChanged($event)">
    </dx-select-box>
  </div>
</div>

<dx-load-indicator *ngIf="featureSystemsLoading" id="large-indicator" height="60" width="60"></dx-load-indicator>

<dx-data-grid *ngIf="selectedFeatureSystem && !featuresLoading"
              [dataSource]="featuresGridDataSource"
              [showBorders]="true"
              noDataText="{{ 'RenameFeaturesMacroNoDataMessage' | translate }}"
              [style.marginTop.px]="25">
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="feature.name" sortOrder="asc" caption="{{ 'Ursprünglicher Name' | translate }}"></dxi-column>
  <dxi-column dataField="feature.unit" caption="{{ 'Ursprüngliche Einheit' | translate }}"></dxi-column>
  <dxi-column dataField="newName" caption="{{ 'Neuer Name' | translate }}" cellTemplate="newNameTemplate"></dxi-column>
  <div *dxTemplate="let model of 'newNameTemplate'">
    <dx-text-box [(value)]="model.data.newName" [showClearButton]="false"></dx-text-box>
  </div>
  <dxi-column dataField="newUnit" caption="{{ 'Neue Einheit' | translate }}" cellTemplate="newUnitTemplate"></dxi-column>
  <div *dxTemplate="let model of 'newUnitTemplate'">
    <dx-text-box [(value)]="model.data.newUnit" [showClearButton]="false"></dx-text-box>
  </div>
</dx-data-grid>
