<!--31 rename featuresystem name-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Ursprungsname' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedFeatureSystem?.name?.length > 0 ? 'placeLabelSmall' : '' }}">REFERENCE FEATURESYSTEM NAME</label>
    <dx-select-box *ngIf="!featureSystemsLoading"
                   [(value)]="selectedFeatureSystem"
                   [items]="featureSystems"
                   displayExpr="name"
                   [searchEnabled]="true"
                   placeholder="">
    </dx-select-box>
    <dx-load-indicator *ngIf="featureSystemsLoading" id="medium-indicator" height="40" width="40"></dx-load-indicator>
  </div>
</div>
<div class="dx-field">
  <div class="dx-field-label">{{ 'Neuer Name' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ destFeatureSystemName?.length > 0 ? 'placeLabelSmall' : '' }}">DESTINATION FEATURESYSTEM NAME</label>
    <dx-autocomplete class="dataGridAutocomplete"
                     [minSearchLength]="0"
                     [dataSource]="featureSystemsWithMagicFeatureSystems"
                     valueExpr="name"
                     [showClearButton]="true"
                     [showDropDownButton]="true"
                     [(value)]="destFeatureSystemName"
                     valueChangeEvent="blur">
    </dx-autocomplete>
  </div>
</div>
