import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) {
  }

/*  getAllUsersWithCustomers(): Observable<AppUser[]> {
    return this.http.get<AppUser[]>("api/admin/GetAllUsersWithCustomers");
  }*/
}
