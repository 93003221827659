<!--78 setWawiSupplierPids-->
<div class="dx-field">
  <div class="dx-field-label" style="overflow: visible">
    {{ 'Bestehenden Wert auf SUPPLIER_ALT_PID übertragen' | translate }}
  </div>
  <div class="dx-field-value">
    <dx-switch [(value)]="fillAlt" />
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label" style="overflow: visible">{{ 'Lieferant ist auch Hersteller' | translate }}</div>
  <div class="dx-field-value">
    <dx-switch [(value)]="supplierIsManufacturer" />
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label" style="text-wrap: wrap">
    {{ 'ProduktStatus der gesetzt wird, wenn neues Mapping erstellt wurde' | translate }}
  </div>
  <div class="dx-field-value">
    <dx-text-box [(value)]="newProductStatus" [showClearButton]="true" />
  </div>
</div>

<div *ngIf="!catalogHasSupplier()" class="accordion-body-warning">
  <div>{{ 'Im Katalogkopf wurden keine Lieferanteninformationen gepflegt.' | translate }}</div>
</div>
<div *ngIf="catalogHasSupplier()" class="accordion-body-warning">
  <div>
    {{
      'Im Katalogkopf wurden Lieferanteninformationen gepflegt. Es werden die entsprechenden Lieferanteninformationen aus den Mappings benutzt.'
        | translate
    }}
  </div>
</div>
