import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { last } from "rxjs/operators";
import { FtpExportJobSettings } from "../../Model/recurringJobs/ftp/ftpExportJobSettings";
import { ModelService } from "../model.service";

@Injectable()
export class FtpExportJobService {
  constructor(public modelService: ModelService, private http: HttpClient) {

  }

  public getFtpImportJobById(id: number): Observable<FtpExportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get("api/FtpExportJob/Get", options)) as any;
  }

  public getAllFtpImportJobs(): Observable<FtpExportJobSettings[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.modelService.loginService.currentCustomer.id)
    }
    return (this.http.get("api/FtpExportJob/GetAll", options)) as any;
  }

  public update(ftpImport: FtpExportJobSettings): Observable<FtpExportJobSettings> {
    var result = this.http.post<FtpExportJobSettings>("api/FtpExportJob/Update", ftpImport);
    return (result);
  }

  public create(ftpImport: FtpExportJobSettings): Observable<FtpExportJobSettings> {
    ftpImport.customerId = this.modelService.loginService.currentCustomer.id;
    var result = this.http.post<FtpExportJobSettings>("api/FtpExportJob/Create", ftpImport);
    return (result);
  }

  public delete(id: number): Observable<FtpExportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.delete<FtpExportJobSettings>("api/FtpExportJob/Delete", options)) as any;
  }

  public run(id: number): Observable<FtpExportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get<object>("api/FtpExportJob/Run", options)) as any;
  }
}
