<br />
<dx-validation-group id="REFE">
  <!-- creates a accordion per referencetype, so each accoredion is its own own input control -->
  @for (item of referenceTypes; track item) {
  <np-inputControl
    [(model)]="model"
    [showLinkButtons]="false"
    [suppressLabel]="true"
    [suppressPlaceLabel]="true"
    [(selectedElements)]="selectedElements"
    (elementSelected)="templateSelect($event, item)"
    (onValueChanged)="update($event)"
    [field]="'PRODUCT_REFERENCES_' + item"
    [showDragHandle]="showDragHandle"
    [dragFilter]="dragFilter"
    (excelElementDropped)="excelElementDrop($event)"
  >
    <div slot="content" class="contentSlot mb-3">
      <dx-accordion
        [dataSource]="[item]"
        [collapsible]="true"
        [selectedIndex]="-1"
        itemTemplate="item"
        itemTitleTemplate="title"
        class="np-accordion np-others"
      >
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ReferencesStateNewUIV3"></dxo-state-storing>

        <div *dxTemplate="let data of 'title'">
          <div class="dx-accordion-item-title-caption gap-2">
            {{ data | translate }} {{ getReferencesTypeCountText(data) }}
            <dx-button
              *ngIf="!templateView"
              [visible]="!readOnly('referenceNew')"
              stylingMode="text"
              type="default"
              (onClick)="showAddReference($event, data)"
              hint="{{ 'Neue ' | translate }} {{ data | translate }} {{ ' erstellen' | translate }}"
              icon="add"
              class="np-accordion-hoverable btn-icon"
            ></dx-button>
          </div>
        </div>
        <div *dxTemplate="let item of 'item'">
          <dx-data-grid
            [dataSource]="getReferencesByType(item)"
            [remoteOperations]="false"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            noDataText="{{ 'keine ' + item + '  Referenzen' | translate }}"
            [showBorders]="false"
            [showColumnHeaders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            (onRowUpdated)="update($event)"
            (onRowRemoved)="onRowRemoved($event)"
            (onRowPrepared)="onReferencePrepared($event)"
            [customizeColumns]="customizeColumns"
          >
            <dxo-editing mode="cell" [allowDeleting]="!readOnly('referenceDelete')" [useIcons]="true"></dxo-editing>
            <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              storageKey="ReferencesStateNewUIV2"
            ></dxo-state-storing>

            <dxi-column
              dataField="artIdTo"
              caption="{{ 'Produktnummer' | translate }}"
              cellTemplate="referenceSpidTemplate"
              editCellTemplate="referenceSpidTemplate"
              [width]="200"
            ></dxi-column>
            <div *dxTemplate="let data of 'referenceSpidTemplate'" class="d-flex flex-row">
              <div class="np-field w-100">
                <np-inputControl
                  [(model)]="model"
                  [showLinkButtons]="showLinkButtons"
                  [showPlaceLabel]="data?.data?.artIdTo?.length > 0"
                  [suppressLabel]="true"
                  (onValueChanged)="update($event)"
                  field="PRODUCT_REFERENCES_SPID"
                  [system]="data.data.order.toString()"
                  [showDragHandle]="false"
                  dragFilter="nodrag"
                >
                  <div slot="content" class="contentSlot">
                    <dx-text-box [value]="data.data.artIdTo" [readOnly]="true">
                      <dx-validator *ngIf="!templateView"
                                    validationGroup="REFE"
                                    (onInitialized)="validationService.startAutomaticValidation($event)">
                        <dxi-validation-rule
                          type="async"
                          [validationCallback]="
                            validationService.validateField('PRODUCT_REFERENCES_SPID', data.data.order.toString())
                          "
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </np-inputControl>
              </div>
            </div>

            <dxi-column
              dataField="normUri"
              caption=""
              [allowEditing]="false"
              editCellTemplate="imageCellTemplate"
              cellTemplate="imageCellTemplate"
              [width]="70"
            ></dxi-column>
            <div *dxTemplate="let data of 'imageCellTemplate'" class="w-100">
              <div>
                <img *ngIf="!data.value" src="images/ikon_article_tree_invert.png" class="catlistImage" />
                <img *ngIf="data.value" src="{{ data.value }}" class="catlistImage" id="link{{ data.rowIndex }}" />
              </div>
            </div>

            <dxi-column
              dataField="descriptionShort"
              [allowEditing]="false"
              caption="{{ 'Produkt' | translate }}"
              cellTemplate="referenceDescriptionShortTemplate"
              [allowSearch]="false"
              [allowSorting]="false"
            ></dxi-column>
            <div *dxTemplate="let data of 'referenceDescriptionShortTemplate'" class="d-flex flex-row">
              <div class="np-field w-100">
                <dx-text-box
                  [(value)]="data.data.descriptionShort"
                  [showClearButton]="false"
                  [readOnly]="true"
                ></dx-text-box>
              </div>
            </div>
          </dx-data-grid>
        </div>
      </dx-accordion>
    </div>
  </np-inputControl>
  }
</dx-validation-group>

<div class="color5Text">
  <div *ngIf="loginService.currentCustomer.automaticValidation && !validationService.validationResult?.productReferenceValid">
    <table>
      <tr *ngFor="let msg of validationService.validationResult?.referenceMessages">
        <td>
          {{ msg | translate }}
        </td>
      </tr>
    </table>
  </div>
</div>

<dx-popup
  [(visible)]="addVisible"
  title="{{ 'Neue ' | translate }} {{ typeToAdd | translate }}"
  hideOnOutsideClick="true"
  position="center"
  width="500"
  height="300"
>
  <dx-select-box
    searchEnabled="true"
    [dataSource]="productSelectBoxData"
    [(selectedItem)]="selectedNewProduct"
    searchExpr="SupplierPid"
    displayExpr="supplierPid"
  >
    <div *dxTemplate="let item of 'item'">{{ item.supplierPid }} | {{ item.descriptionShort }}</div>
  </dx-select-box>
  <dx-button
    *ngIf="selectedNewProduct"
    stylingMode="text"
    type="default"
    text="{{ 'Neue ' | translate }} {{ typeToAdd | translate }} {{ ' erstellen' | translate }}"
    (onClick)="addReference()"
  ></dx-button>
</dx-popup>
