import { UserActionViewDetailIcon } from "./userActionViewDetailsIcon";

export class UserActionViewDetail {
    id: number;
    icon: UserActionViewDetailIcon;

    text?: string;
    textLookupKey: string;
    hint?: string;
    hintLookupKey: string;

    getCssClassForIcon(): string {
        switch (this.icon) {
            case UserActionViewDetailIcon.Trash:
                return "glyphicon glyphicon-trash";
            case UserActionViewDetailIcon.Wrench:
                return "glyphicon glyphicon-wrench";
            case UserActionViewDetailIcon.Random:
                return "glyphicon glyphicon-random";
            case UserActionViewDetailIcon.Check:
                return "glyphicon glyphicon-check";
            case UserActionViewDetailIcon.Search:
                return "glyphicon glyphicon-search";
            case UserActionViewDetailIcon.Refresh:
                return "glyphicon glyphicon-refresh";
            case UserActionViewDetailIcon.EyeClose:
                return "glyphicon glyphicon-eye-close";
            default:
                console.log(`missing css class for icon ${this.icon}`);
                return "glyphicon";
        }
    }
}
