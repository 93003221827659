<dx-scroll-view showScrollbar="always" height="inherit">
  <dx-tile-view
    class="dx-tile-content excelFields"
    height="inherit"
    [baseItemWidth]="285"
    direction="vertical"
    baseItemHeight="16"
  >
    <dxi-item *ngFor="let group of groups" [widthRatio]="1" [heightRatio]="getHeightRatio(group.name)">
      <div class="d-flex gap-2 ps-2 py-3">
        <dx-check-box [value]="group.active" (onValueChanged)="selectGroup($event, group.name)"></dx-check-box>
        <div class="flex-grow-1">
          <b>{{ group.name | translate }}</b>
        </div>
      </div>
      <div *ngFor="let field of fields">
        <div *ngIf="field.group == group.name" class="d-flex gap-2 p-1 ps-3 exportFieldContent">
          <div>
            <dx-check-box [(value)]="field.active" (onValueChanged)="selectField($event, field)"></dx-check-box>
          </div>
          <div class="flex-grow-1">
            <label class="exportLabel">{{ field.field }}</label>
            <div class="exportText d-flex">
              {{ field.field | translate }}
              <div *ngIf="isUpdateTemplate">
                <div *ngIf="field.field == 'MIME_UPDATE_MODE'" class="ms-3">
                  <div id="activeMIME" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeMIME" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Medien übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Medien geloscht und aus dem Quellkatalog neu angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Medien, die im
  Quellkatalog bereits existieren, uberschrieben. Nur im Zielkatalog vorhandene Medien werden
  nicht geandert.' | translate
                        }}
                      </p>
                      <p>
                        {{ 'Unterscheidungsmerkmal ist die ORDER-Nummer.' | translate }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'FEATURE_UPDATE_MODE'" class="ms-3">
                  <div id="activeFEATURE" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeFEATURE" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Features übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Features geloscht und aus dem Quellkatalog neu angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Features, die im
  Quellkatalog bereits existieren, uberschrieben. Nur im Zielkatalog vorhandene Features werden
  nicht geandert.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Unterscheidungsmerkmal ist die der Feature Name in Kombination mit der Einheit.' | translate
                        }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'PRICE_UPDATE_MODE'" class="ms-3">
                  <div id="activePRICE" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activePRICE" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Preise übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Preise geloscht und aus dem Quellkatalog neu angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Preise, die im
  Quellkatalog bereits existieren, uberschrieben. Nur im Zielkatalog vorhandene Preise werden
  nicht geandert.' | translate
                        }}
                      </p>
                      <p>
                        {{ 'Unterscheidungsmerkmal ist die ORDER-Nummer.' | translate }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'UDX_UPDATE_MODE'" class="ms-3">
                  <div id="activeUDX" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeUDX" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl UDX übertragen werden sollen, werden zu Beginn des Updates
  die im Zielkatalog vorhandenen UDX geloscht und aus dem Quellkatalog neu angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die UDX, die im Quellkatalog
  bereits existieren, uberschrieben. Nur im Zielkatalog vorhandene UDX werden nicht geandert.' | translate
                        }}
                      </p>
                      <p>
                        {{ 'Unterscheidungsmerkmal ist die ORDER-Nummer.' | translate }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'CATEGORY_UPDATE_MODE'" class="ms-3">
                  <div id="activeCategory" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeCategory" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Kategorien übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Kategorien gelöscht und aus dem Quellkatalog neu
  angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Kategorien, die im
  Quellkatalog bereits existieren, uberschrieben und neue hinzugefügt. Nur im Zielkatalog
  vorhandene Kategorien bleiben bestehen.' | translate
                        }}
                      </p>
                      <p>
                        {{ 'Unterscheidungsmerkmal ist der GROUP_STRING.' | translate }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'STATUS_UPDATE_MODE'" class="ms-3">
                  <div id="activeStatus" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeStatus" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Status übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Status gelöscht und aus dem Quellkatalog neu angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Status, die im
  Quellkatalog bereits existieren, uberschrieben und Neue hinzugefügt. Nur im Zielkatalog
  vorhandene Status bleiben bestehen.' | translate
                        }}
                      </p>
                      <p>
                        {{ 'Unterscheidungsmerkmal ist die Status Text.' | translate }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>

                <div *ngIf="field.field == 'SUPPLIER_UPDATE_MODE'" class="ms-3">
                  <div id="activeSupplier" class="dx-link dx-icon-info dx-link-icon infoBadgeStyle"></div>
                  <dx-tooltip target="#activeSupplier" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div *dxTemplate="let data of 'content'">
                      <p>
                        {{
                          'Ist der Update Modus inaktiv, obwohl Lieferanten übertragen werden sollen, werden zu Beginn des
  Updates die im Zielkatalog vorhandenen Lieferanten gelöscht und aus dem Quellkatalog neu
  angelegt.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Ist der Update Modus aktiv, werden beim Update im Zielkatalog nur die Lieferanten, die im
  Quellkatalog bereits existieren, uberschrieben und Neue hinzugefügt. Nur im Zielkatalog
  vorhandene Lieferanten bleiben bestehen.' | translate
                        }}
                      </p>
                      <p>
                        {{
                          'Unterscheidungsmerkmal ist die Lieferanten-Nr. in Verbindung mit dem gültig ab Datum.'
                            | translate
                        }}
                      </p>
                    </div>
                  </dx-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-tile-view>
</dx-scroll-view>
