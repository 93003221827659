import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MaintenanceMode } from "../Model/Dto/MaintenanceMode";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeService {

  constructor(private http: HttpClient) {
  }

  getCurrentState(): Observable<MaintenanceMode> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    };
    return (this.http.get<MaintenanceMode>("api/MaintenanceMode/GetCurrentState", options)) as any;
  }

  enableMaintenanceMode(): Observable<MaintenanceMode> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    };
    return (this.http.get<MaintenanceMode>("api/MaintenanceMode/Enable", options)) as any;
  }

  disableMaintenanceMode(): Observable<MaintenanceMode> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    };
    return (this.http.get<MaintenanceMode>("api/MaintenanceMode/Disable", options)) as any;
  }

}
