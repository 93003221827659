export class CustomerStatistics {

  constructor(
    public id?: string,
    public customerId?: string,
    public skuCount?: number,
    public catalogCount?: number,
    public fileCount?: number,
    public sumFileSize?: number,
    public eanCount?:number,
    public date?: Date,
  ) {
  }

  deserialize(input: any) {
    Object.assign(this, input);
    //this.date = new Date(input.date);
    return this;
  }
}
