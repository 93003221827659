<!--Klassifiierung-->
<div class="dx-fieldset" *ngIf="modelService.loginService.hasAddonCLASSIFICATION">
  <div class="dx-field-label">{{ 'Klassifizierungssysteme' | translate }}</div>
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-tag-box [(value)]="model.classificationSystems"
                  [dataSource]="classificationSystems"
                  displayExpr="name"
                  [acceptCustomValue]="false"
                  placeholder=""
                  class="hint"
                  [readOnly]="readOnlyUser('classifiction')"
                  (onValueChanged)="catalogChanged($event)">
      </dx-tag-box>
    </div>
  </div>
</div>
