<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Shop URL' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt10">
      <label class="placeLabel {{ model.baseUri?.length>0 ? 'placeLabelSmall' : '' }}">SHOP_URL</label>
      <dx-text-box [(value)]="model.baseUri" [readOnly]="readOnlyUser('showare5Url')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'User' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.user?.length>0 ? 'placeLabelSmall' : '' }}">USER</label>
      <dx-text-box [(value)]="model.user" [readOnly]="readOnlyUser('showare5User')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'API Key' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.apiKey?.length>0 ? 'placeLabelSmall' : '' }}">API_KEY</label>
      <dx-text-box [(value)]="model.apiKey" [readOnly]="readOnlyUser('showare5apiKey')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Datenbank' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt11">
      <label class="placeLabel {{ model.apiKey?.length>0 ? 'placeLabelSmall' : '' }}">DATABASE</label>
      <dx-text-box [(value)]="model.database" [readOnly]="readOnlyUser('showare5apiKey')" [showClearButton]="true" (onValueChanged)="valueChanged($event)"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Produkt Kategorie' | translate }}</div>
    <div class="dx-field-value">
      <dx-drop-down-box [value]="model.catalogCategoryId"
                        valueExpr="id"
                        displayExpr="text"
                        placeholder="{{ 'Kategorie auswählen' | translate }}"
                        [showClearButton]="true"
                        (onValueChanged)="syncTreeViewSelection($event)"
                        [dataSource]="categories"
                        [readOnly]="readOnlyUser('odooCategorie')">

        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [items]="categories"
                        dataStructure="plain"
                        parentIdExpr="parent"
                        selectionMode="single"
                        [selectByClick]="true"
                        keyExpr="id"
                        displayExpr="text"
                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
            <dxo-scrolling [useNative]="true">
            </dxo-scrolling>
          </dx-tree-view>
        </div>
      </dx-drop-down-box>

    </div>

  </div>


  <dx-toolbar>
    <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Verbindung testen' | translate , onClick: testConnection}"></dxi-item>
    <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Kategorien laden' | translate , onClick: getCategories}"></dxi-item>
    <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text:  'Katalog senden' | translate , onClick: sendCatalog}"></dxi-item>
  </dx-toolbar>

</div>
