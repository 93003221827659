import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { TemplateItemTemplate } from "../Model/Catalog/TemplateItemTemplate";
import { WawiList } from "../Model/wawi/WawiList";

@Injectable()
export class WawiListService {
  constructor(private http: HttpClient, public translate: TranslateService) {

  }

  getAllLists(customerId: string): Observable<WawiList[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<string[]>("api/WawiList/getAllLists", options)) as any;
  }

  getAllListsWithoutFields(customerId: string): Observable<WawiList[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<string[]>("api/WawiList/getAllListsWithoutFields", options)) as any;
  }

  getAllMappingsWithoutFields(customerId: string): Observable<WawiList[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<string[]>("api/WawiList/getAllMappingsWithoutFields", options)) as any;
  }

  getAllListNames(customerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<string[]>("api/WawiList/getAllListNames", options)) as any;
  }

  getList(listId: string, customerId: string): Observable<WawiList> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("listId", listId).append("customerId", customerId)
    }

    return (this.http.get<WawiList>("api/WawiList/getList", options)) as any;
  }

  getListByName(listName: string, customerId: string): Observable<WawiList> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("listName", listName).append("customerId", customerId)
    }

    return (this.http.get<WawiList>("api/WawiList/getListByName", options)) as any;
  }

  getListValues(listId: string, customerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("listId", listId).append("customerId", customerId)
    }

    return (this.http.get<string[]>("api/WawiList/getListValues", options)) as any;
  }

  getElementValue(listId: string, customerId: string, elementId: number): Observable<string> {
    const options : Object = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("listId", listId).append("customerId", customerId).append("elementId", elementId),
      responseType: 'text' 
    }

    return (this.http.get<string>("api/WawiList/GetElementValue", options)) as any;
  }

  create(entity: WawiList, customerId: string): Observable<WawiList> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    var result = this.http.post<WawiList>("api/WawiList/Create", entity, options);
    return (result);
  }

  update(entity: WawiList, customerId: string): Observable<WawiList> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    
    var result = this.http.post<WawiList>("api/WawiList/Update", entity, options);
    return (result);
  }

  updateOnlyList(entity: WawiList, customerId: string): Observable<WawiList> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    var result = this.http.post<WawiList>("api/WawiList/UpdateOnlyList", entity, options);
    return (result);
  }

  delete(listId: string, customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("listId", listId).append("customerId", customerId)
    }
    return (this.http.get("api/WawiList/delete", options)) as any;
  }

  startImport(customerId: string, filename: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("fileName", filename)
    }
    return (this.http.post("api/WawiList/StartImpotJob", undefined, options)) as any;
  }

  startExport(customerId: string, listId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("listId", listId)
    }
    return (this.http.post("api/WawiList/StartExportJob", undefined, options)) as any;
  }

  getWawiMappingFields(customerId: string): Observable<TemplateItemTemplate[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };
    return this.http.get<TemplateItemTemplate[]>("api/WawiList/getWawiMappingFields", options);
  }
}
