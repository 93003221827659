import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { Catalog } from "../../../../Model/Catalog/Catalog";

@Component({
  selector: "userAction-compareCatalog",
  templateUrl: "userAction-compareCatalog.component.html"
})
export class UserActionCompareCatalogComponent extends UserActionBaseComponent {

  constructor(public modelService: ModelService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important

    this.modelService.catalogService.getCatalogs(this.modelService.loginService.currentUser.customerId)
      .subscribe((result: Catalog[]) => {
        this.catalogs = result.filter(c => !c.isVirtual);
      });
  }

  _sourceCompareCatalog: string = "";
  useEanAsKey: boolean = false;
  catalogs: Catalog[];

  get sourceCompareCatalog(): string {
    return this._sourceCompareCatalog;
  }

  set sourceCompareCatalog(sourceCompareCatalog: string) {
    this._sourceCompareCatalog = sourceCompareCatalog;
  }

  startMacro() {
    if (this.sourceCompareCatalog == "") {
      this.modelService.systemService.notifyWarning("Bitte Katalog auswählen.");
      return;
    }
    if (this.sourceCompareCatalog == this.modelService.catalogService.catalog.id) {
      this.modelService.systemService.notifyWarning("Katalog kann nicht mit sich selbst verglichen werden.");
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `CompareCatalog(${this.sourceCompareCatalog}:${this.useEanAsKey})`;

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        //additional step
        this.sourceCompareCatalog = "";


        this.userActionStarted.emit(next);
      });
  }
}
