<fullscreen-page>
  <content-column look="dark" helpSwitch="true">
    <div slot="content" class="p-3 flex-fill w-75 mx-auto overflow-hidden">
      <div class="dx-fieldset-header">{{ 'Mapping Profile' | translate }}</div>
      <dx-data-grid class="h-100"
                    [dataSource]="mappingProfiles"
                    [remoteOperations]="false"
                    [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    noDataText="{{ 'Keine Mapping Profile im Account' | translate }}"
                    [showBorders]="true"
                    (onRowRemoving)="deleteStatus($event)"
                    (onEditingStart)="onEditClicked($event)"
                    (onRowInserted)="addMappingProfile($event)">
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Profile suchen' | translate }}"></dxo-search-panel>

        <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="true" [allowAdding]="true" [useIcons]="true"></dxo-editing>

        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureMappingProfilesStateNewUIV2"></dxo-state-storing>

        <dxi-column dataField="name" caption="{{ 'Name' | translate }}" (onValueChanged)="update($event)"></dxi-column>
      </dx-data-grid>
    </div>
  </content-column>
</fullscreen-page>
