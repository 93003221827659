import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductState } from '../../../../../../Model/Catalog/ProductState';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'productStateFilter',
  templateUrl: './productStateFilter.component.html',
  styleUrls: ['./productStateFilter.component.css']
})
export class ProductStateFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  catalogProductStates: { name: string }[];
  isStateFilterLoaded: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService) {
    this.loadStatesIfNeeded = this.loadStatesIfNeeded.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.productStatesFilter == null)
      this.exportParameter.productStatesFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.productStatesFilter.filterKey == undefined)
      this.exportParameter.productStatesFilter.filterKey = [];

    this.loadStatesIfNeeded();
  }

  loadStatesIfNeeded() {
    if (this.exportParameter.productStatesFilter.useFilter)
      this.refreshStates();
  }

  refreshStates() {
    this.isStateFilterLoaded = false;
    this.exportFilterService.getProductStates(this.catalogId, this.modelService.loginService.currentCustomer.id).subscribe(
      (states: string[]) => {
        this.catalogProductStates = states.map(x => { return { name: x } });
        this.isStateFilterLoaded = true;
      }
    );
  }
}
