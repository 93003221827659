import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { TranslateService } from '@ngx-translate/core';
import { ViewService } from "../../../../Services/view.service";

@Component({
  selector: "userAction-deleteMediaByPurpose",
  templateUrl: "userAction-deleteMediaByPurpose.component.html"
})
export class UserActionDeleteMediaByPurpose extends UserActionBaseComponent {
  _allPurposes: string[];
  _selectedPurpose: string;

  txtSelectPurpose: string;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important

    this.txtSelectPurpose = this.translate.instant("Bitte wählen Sie eine Verwendung aus");

    this.allPurposes = ViewService.dropDownMimePurposes;
  }



  get allPurposes(): string[] {
    return this._allPurposes;
  }

  set allPurposes(value: string[]) {
    this._allPurposes = value;
  }



  get selectedPurpose(): string {
    return this._selectedPurpose;
  }

  set selectedPurpose(value: string) {
    this._selectedPurpose = value;
  }



  startMacro() {
    if (this.selectedPurpose == "" || this.selectedPurpose == undefined) {
      this.modelService.systemService.notifyInfo(this.txtSelectPurpose);
      return;
    }



    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `DeleteMediaByPurpose(${this.selectedPurpose})`;

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
