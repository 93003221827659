import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { Backup } from "../../../Model/System/Backup";
import { RestoreBackup } from "../../../Model/Dto/RestoreBackup";
import { Message } from "../../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "backup",
  templateUrl: "backup.component.html"
})
export class BackUpComponent implements OnInit {

  constructor(private modelService: ModelService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.getBackUps();
  }

  public backUps: Backup[] = [];

  getBackUps() {
    this.modelService.backupService.getBackups(this.modelService.loginService.currentUser.customerId).subscribe(
      (backups: Backup[]) => {
        this.backUps = backups;
      }
    );
  }

  onGridToolbarPreparing(e) {



    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate'
    });

   

  }



  restoreBackUp(id) {
    var restoreBackup = new RestoreBackup();
    restoreBackup.backupId = id;
    restoreBackup.customerId = this.modelService.loginService.currentUser.customerId;
    restoreBackup.restoreMedia = true;
    this.modelService.backupService.restoreBackup(restoreBackup);
    this.modelService.catalogService.requestCatalogChangingJobs();
    this.translate.get("Das Backup wird per Job bereitgestellt").subscribe((text: string) => {
      this.modelService.systemService.notify(new Message(text));
    });
   
  }

  downloadMedia(id:string) {
    this.translate.get("Die Medien werden per Job bereitgestellt").subscribe((text: string) => {
      this.modelService.systemService.notify(new Message(text));
    });
    this.modelService.backupService.getMediaJob(id, this.modelService.loginService.currentUser.customerId);
  }


  deleteBackUp(event) {
    this.modelService.backupService.deleteBackup(event.data.id);
   
  }
}
