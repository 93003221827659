import { Component } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from "angular-oauth2-oidc";
@Component({
  selector: "importFile",
  templateUrl: "importFile.component.html"
})
export class ImportFileComponent {
  value: any[];
  uploadHeaders: any;
  msg: string = "";
  txtUploaded:string = "hochgeladen";
  constructor(private modelService: ModelService, public translate: TranslateService, private oAuthService: OAuthService) {
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);

    this.txtUploaded = this.translate.instant("hochgeladen");

    this.reset();
  }

  reset() {
    this.uploadHeaders = {
      CatalogId: this.modelService.catalogService.selectedCatalogId,
      fileNameToUseInImport: this.modelService.catalogService.fileNameToUseInImport,
      CustomerId: this.modelService.loginService.currentUser.customerId,

    }
    this.value = [];
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }


  onUploaded(e) {
    this.msg = e.file.name.substring(0, 15) + " "+ this.txtUploaded + ".";
    this.reset();
    this.modelService.catalogService.catalog.lastImageUpload = new Date();
    this.modelService.catalogService.fileNameToUseInImport = "";

  }

  onUploadStarted(e) {
    console.log(this);
  }

  
}
