<!--62: ExportReportProductDatasheet-->



<div class="dx-field">
  <div class="dx-field-label">{{ 'Report' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ selectedReport?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'REPORT' | translate }}</label>
    <dx-select-box [(value)]="selectedReport"
                   [(dataSource)]="allReports"
                   placeholder=""></dx-select-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Muster Dateiname' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ filenamePattern?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'MUSTER DATEINAME' | translate }}</label>
    <dx-text-box [(value)]="filenamePattern" [showClearButton]="true"></dx-text-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Beschreibung' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ mimeDescr?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'MIME DESCRIPTION' | translate }}</label>
    <dx-text-box [(value)]="mimeDescr" [showClearButton]="true"></dx-text-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Alle ersetzen' | translate }}</div>
  <dx-check-box [(value)]="replaceAll" style="padding-top: 6px;"></dx-check-box>
</div>
