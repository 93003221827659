<dx-drop-down-box
  class="flex-grow-1 np-highlight"
  style="width: 450px;"
  [showClearButton]="true"
  [(value)]="pimField.field"
  [dataSource]="fieldList"
  [displayExpr]="getFieldDisplayExpr"
  valueExpr="key"
  label="{{ 'Feld' | translate }}"
  placeholder="{{ 'Feld auswählen' | translate }}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="fieldList"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-drop-down-box
  *ngIf="isWarrantyClass"
  class="flex-grow-1 np-highlight"
  [showClearButton]="true"
  [(value)]="templateItem.defaultValue"
  [dataSource]="warrantyClasses"
  [displayExpr]="getFieldDisplayExpr"
  [acceptCustomValue]="true"
  valueExpr="key"
  label="{{ 'WARRANTY_ClASS' | translate }}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="warrantyClasses"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-drop-down-box
  *ngIf="isHazmatClass"
  class="flex-grow-1 np-highlight"
  [showClearButton]="true"
  [(value)]="templateItem.defaultValue"
  [dataSource]="hazmatClasses"
  [displayExpr]="getFieldDisplayExpr"
  [acceptCustomValue]="true"
  valueExpr="key"
  label="{{ 'HAZMAT_ClASS' | translate }}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="hazmatClasses"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<np-booleanRadioGroup
  *ngIf="isRadioGroupItem"
  [(value)]="templateItem.defaultValue"
  [templateView]="false"
  [readOnly]="false"
  [fieldName]="pimField.field"
  validatorValidationGroup="LEGAL"
></np-booleanRadioGroup>
