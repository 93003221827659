import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ProductDataSheet } from '../Model/Dto/ProductDataSheet';

@Injectable()
export class ProductReportingService {
  constructor(private http: HttpClient) {

  }

  public getUrls(): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
    }
    return (this.http.get<string[]>("api/ReportDesigner/GetUrlsList", options)) as any;
  }

  public getFiles(customerId: string): Observable<ProductDataSheet[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get<ProductDataSheet[]>("api/Reporting/GetFileNames", options)) as any;
  }

  public deleteReport(data: ProductDataSheet): Observable<boolean> {
    
    return (this.http.post("api/Reporting/DeleteReport", data)) as any;
  }

  public renameReport(data: ProductDataSheet): Observable<boolean> {
    
    return (this.http.post("api/Reporting/RenameReport", data)) as any;
  }

}
