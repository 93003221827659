import { Component, Input, OnInit } from '@angular/core';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';

@Component({
  selector: 'dateFilter',
  templateUrl: './dateFilter.component.html',
  styleUrls: ['./dateFilter.component.css']
})
export class DateFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;

  constructor() { }

  ngOnInit(): void {
    if (this.exportParameter.dateFilter == null)
      this.exportParameter.dateFilter = new CatalogFilter<Date>(null);
    if (this.exportParameter.dateFilter.filterKey == undefined)
      this.exportParameter.dateFilter.filterKey = new Date();


    if (this.exportParameter.expiredDateFilter == null)
      this.exportParameter.expiredDateFilter = new CatalogFilter<Date>(null);
    if (this.exportParameter.expiredDateFilter.filterKey == undefined)
      this.exportParameter.expiredDateFilter.filterKey = new Date();
  }

}
