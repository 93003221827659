<fullscreen-page>
  <content-column look="dark">
    <div slot="content" class="h-100 p-2">



      <div class="d-flex align-items-center h-100 w-100">

        <div class="d-flex flex-row h-50 w-100 border border-3 border-dark rounded m-2">



          <dx-sortable class="sortable-cards"
                       group="cardsGroup"
                       dropFeedbackMode="indicate"
                       [allowReordering]="false"
                       [data]="dummy42"
                       (onDragStart)="onSingleDragStart($event)">

            <div class="border bg-light border-3 border-dark rounded m-2 p-2 item text-dark">
              {{dummy42.name}}
            </div>

          </dx-sortable>



          <div class="d-flex flex-column w-50 border border-3 border-dark rounded ml-2 mt-2 mr-1 mb-2 p-2">

            <div class="h-100 bg-danger border border-3 border-danger rounded">
              <!--mb-1-->


              <dx-scroll-view class="scrollable-list h-100 w-100" 
                              direction="vertical"
                              showScrollbar="always">
                <dx-sortable class="sortable-cards h-100 w-100"
                             group="cardsGroup"
                             dropFeedbackMode="indicate"
                             [allowReordering]="false"
                             [data]="listLeft1"
                             (onDragStart)="onLeftDragStart($event)">

                  <div *ngFor="let item of listLeft1" class="bg-light border border-3 border-dark rounded m-2 p-2 item text-dark">
                    {{item.name}}
                  </div>

                </dx-sortable>
              </dx-scroll-view>


            </div>

            <!--<div class="h-50 border border-3 border-danger rounded ml-2 mt-1 mr-2 mb-2"></div>-->

          </div>



          <div class="d-flex flex-column w-50 border border-3 border-dark rounded ml-1 mt-2 mr-2 mb-2 p-2">

            <div class="h-25 bg-dark border border-3 border-dark rounded mb-2">


              <dx-sortable class="sortable-cards-trash w-100 d-flex justify-content-center align-items-center text-light"
                           group="cardsGroup"
                           dropFeedbackMode="indicate"
                           [allowReordering]="false"
                           (onAdd)="onTrashDrop($event)">
                <!--<h3>
                  T R A S H
                </h3>-->
              </dx-sortable>


            </div>

            <div class="h-75 bg-success border border-3 border-success rounded">


              <dx-scroll-view class="scrollable-list h-100 w-100"
                              direction="vertical"
                              showScrollbar="always">
                <dx-sortable class="sortable-cards-list w-100"
                             group="cardsGroup"
                             dropFeedbackMode="indicate"
                             [data]="listRight1"
                             (onDragStart)="onRightDragStart($event)"
                             (onAdd)="onRightDrop($event)"
                             (onReorder)="onRightReorder($event)">

                  <!-- [allowReordering]="false" -->

                  <div *ngFor="let item of listRight1" class="bg-light border border-3 border-dark rounded m-2 p-2 item text-dark">
                    {{item.id}} - {{item.name}} - {{item.created}}
                  </div>
                </dx-sortable>
              </dx-scroll-view>


            </div>

          </div>



        </div>

      </div>



    </div>
  </content-column>
</fullscreen-page>
