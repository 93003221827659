import { Input, Output, EventEmitter, Component } from '@angular/core';
import { Product } from '../../Model/Catalog/Product';
import { ViewService } from '../../Services/view.service';
import { TranslationService } from '../../Services/translation.service';
import { ModelService } from '../../Services/model.service';
import { ProductState } from '../../Model/Catalog/ProductState';
import { custom } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../Services/userManagment.service';
import { ValidationService } from '../../Services/Validation/validation.service';
import { LoginService } from '../../Services/login.service';

@Component({
  selector: 'infos',
  templateUrl: 'infos.html',
  styleUrls: ['infos.css', 'template.css']
})
export class Infos {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;
  @Input() hasAddonEDENUMBERS: Boolean = false;

  @Input() hasAddonPRODUCT_DETAILS: Boolean = false;
  @Input() isPrint: Boolean = false;
  @Input() productTranslation: Product = null;
  @Input() selectedTranslateLanguage: string = 'deu';
  @Output() onValueChanged = new EventEmitter<any>();
  @Output() translationUpdateFunction = new EventEmitter<{ txtOrg: string; txtTran: string }>();

  @Input() templateView: Boolean = false;
  @Input() massDataChangeMode: boolean = false;
  @Input() showHTMLSwitch: Boolean = true;
  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();

  edeNumbersDummy: string[] = new Array();

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public userManagement: UserManagementService,
    public validationService: ValidationService
  ) {
    this.linkAllFields = this.linkAllFields.bind(this);
    this.getSupplierPid = this.getSupplierPid.bind(this);
    this.getStatus();

    this.edeNumbersDummy.push('dummy');
  }

  public productStates: string[] = [];

  getStatus() {
    this.modelService.productStateService.getStatus().subscribe((result: ProductState[]) => {
      this.productStates = new Array<string>();
      result.forEach((x) => {
        this.productStates.push(x.name);
      });
    });
  }

  isMaster(): boolean {
    if (!this.showLinkButtons) return false;
    if (this.model) {
      if (this.model.productCount != null && this.model.productCount > 0) {
        return true;
      }
    }
    return false;
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  showHTMLEditorChanged() {
    this.modelService.catalogService
      .getProduct(this.model.id, this.model.customerId, this.modelService.catalogService.catalog.id)
      .subscribe(() => {
        this.model = this.modelService.catalogService.product;
      });
  }

  updateState(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (this.templateView) {
      // für den template editor erforderlich:
      if (event) {
        event.dField = field;
        event.dSystem = system;
        event.dElement = element;
        event.createTemplateItem = true;
      }

      this.onValueChanged.emit(event);

      return; // vom Template-Editor getriggert keine neuen Status anlegen!!
    }

    event.value.forEach((val) => {
      if (!this.productStates.find((item) => item == val)) {
        let status = new ProductState();
        status.customerId = this.modelService.loginService.currentUser.customerId;
        status.name = val;
        this.productStates.push(val);
        this.modelService.productStateService.addProductState(status).subscribe((result: ProductState) => {
          status = result;
          this.modelService.productStateService.productStates.push(status.name);
        });
      }
    });
    this.onValueChanged.emit(event);
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (!event.event && !event.toggleLinkedFields)
      //happens if value was changed by code and not by ui
      return;

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (!event.selectedItem) return;

    if (event.event == undefined) {
      event.event = new Object(); // für den template editor erforderlich, da sonst keine default-items erstellt werden
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }

    this.onValueChanged.emit(event);
  }

  getLanguageFlag(flag) {
    return ViewService.getFlag(flag);
  }

  translateLanguage(code) {
    return TranslationService.translateLanguage(code);
  }

  get hasTranslation(): Boolean {
    if (this.productTranslation == null) {
      return false;
    }
    return true;
  }

  public translationUpdate(txtOrg, txtTran) {
    this.translationUpdateFunction.emit({ txtOrg: txtOrg, txtTran: txtTran });
  }

  private _showHTMLEditor: Boolean = true;

  get showHTMLEditor() {
    return this._showHTMLEditor;
  }

  set showHTMLEditor(value) {
    this._showHTMLEditor = value;
  }

  get internationalPidType() {
    return ViewService.dropDownInternationalPidType;
  }

  //ExcelTemplate

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  getSupplierPid() {
    this.modelService.supplierPidMappingService.mapProduct(this.model).subscribe((product: Product) => {
      this.modelService.catalogService
        .getProduct(this.model.id, this.model.customerId, this.modelService.catalogService.catalog.id)
        .subscribe(() => {
          this.model = this.modelService.catalogService.product;
        });
    });
  }

  linkAllFields() {
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate
      .get(
        'Sind Sie sicher, dass Sie die Verknüpfungen neu erstellen wollen? Bestehend Verknüpfungen werden überschrieben!'
      )
      .subscribe((text: string) => {
        txtMsg = text;
      });
    this.translate.get('Wirklich neu verknüpfen?').subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return { buttonText: true };
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return { buttonText: false };
          }
        }
      ]
    });
    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {
        this.modelService.catalogService.linkAllFields(this.model).subscribe((product: Product) => {
          this.modelService.catalogService
            .getProduct(this.model.id, this.model.customerId, this.modelService.catalogService.catalog.id)
            .subscribe(() => {
              this.model = this.modelService.catalogService.product;
            });
        });
      }
    });
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public showClearButton(field: string): boolean {
    return !this.readOnlyForLinkedFields(field) && !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    // Im Übersetzungsmodus nicht anzeigen
    if (this.hasTranslation) {
      return false;
    }

    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    if (field == 'DESCRIPTION_SHORT' || field == 'SUPPLIER_PID') {
      // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
      event.forceTreeRefresh = true;
    }

    // SAVE
    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
