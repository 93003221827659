import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionBaseComponent } from "../userAction-base.component";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "userAction-exportReportProductDatasheet",
  templateUrl: "userAction-exportReportProductDatasheet.component.html"
})
export class UserActionExportReportProductDatasheet extends UserActionBaseComponent {
  _allReports: string[];
  _selectedReport: string;
  _filenamePattern: string;
  _mimeDescr: string
  _replaceAll: boolean = false;

  txtSelectReport: string;
  txtSelectFilenamePattern: string;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important

    this.txtSelectReport = this.translate.instant("Bitte wählen Sie einen Report aus");
    this.txtSelectFilenamePattern = this.translate.instant("Bitte wählen Sie ein Muster für die Vergabe der Dateinamen aus");

    this.modelService.productReportingService.getUrls()
      .subscribe((items: string[]) => {
        this.allReports = items;
      });
  }



  get allReports(): string[] {
    return this._allReports;
  }

  set allReports(value: string[]) {
    this._allReports = value;
  }



  get selectedReport(): string {
    return this._selectedReport;
  }

  set selectedReport(value: string) {
    this._selectedReport = value;
  }



  get filenamePattern(): string {
    return this._filenamePattern;
  }

  set filenamePattern(value: string) {
    this._filenamePattern = value;
  }



  get mimeDescr(): string {
    return this._mimeDescr;
  }

  set mimeDescr(value: string) {
    this._mimeDescr= value;
  }



  get replaceAll(): boolean {
    return this._replaceAll;
  }

  set replaceAll(value: boolean) {
    this._replaceAll = value;
  }



  startMacro() {
    if (this.selectedReport == "" || this.selectedReport == undefined) {
      this.modelService.systemService.notifyInfo(this.txtSelectReport);
      return;
    }
    if (this.filenamePattern == "" || this.filenamePattern == undefined) {
      this.modelService.systemService.notifyInfo(this.txtSelectFilenamePattern);
      return;
    }



    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `ExportReportProductDatasheet(${this.selectedReport}:${this.filenamePattern}:${this.replaceAll}:${this.mimeDescr})`;

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }
}
