import { Component, ViewChild } from '@angular/core';
import { OxomiSupplier } from '../Model/Dto/Oxomi/OxomiSupplier';
import { TranslateService } from '@ngx-translate/core';
import { OxomiSortimentService } from 'app/Services/oxomiSortiment.service';
import { LoginService } from 'app/Services/login.service';
import { SystemService } from 'app/Services/system.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from "angular-oauth2-oidc";
import { JobService } from 'app/Services/job.service';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxDataGridComponent } from "devextreme-angular";
import { Column } from 'devextreme/ui/data_grid';

@Component({
  selector: 'oxomi-sortiment',
  templateUrl: './oxomi-sortiment.component.html',
  styleUrl: './oxomi-sortiment.component.css'
})
export class OxomiSortimentComponent {

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  oxomiSupplierList: OxomiSupplier[] = [];
  loggedInSubscription: any = null;
  addVisible = false;
  excelImportVisible = false;
  importResumeVisible = false;
  excelExportVisible = true;
  supplierWithoutNameExists = false;
  supplierWithoutNumberExists = false;
  missingValues = false;
  excelImportNewCount = 0;
  excelImportUpdateCount = 0;
  excelImportIgnoreCount = 0;
  supplierWithoutName = "";
  supplierWithoutNumber = "";
  newSupplier: OxomiSupplier;

  constructor(public translate: TranslateService, public oxomiSortimentService: OxomiSortimentService, 
    public loginService: LoginService, public systemService: SystemService, private oAuthService: OAuthService, public jobService: JobService) {
    this.systemService.currentNavigationTitle = this.translate.instant("Oxomi Sortiment");
    this.showAdd = this.showAdd.bind(this);
    this.addSupplier = this.addSupplier.bind(this);
    this.showExcelImport = this.showExcelImport.bind(this);    
    this.onUploaded = this.onUploaded.bind(this);

    if (this.loginService.loggedIn)
      this.reloadSupplierList();
    else
      this.loggedInSubscription = this.loginService.onLoggedIn.subscribe(() => this.reloadSupplierList());
  }

  onContentToolbarPreparing(e: { toolbarOptions: { items: { location: string; locateInMenu: string; template: string; }[]; }; }) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelExportButtonTemplate',      
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelImportButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addSupllierTemplate',
    });
  }

  onRemovedSupplier(e: { data: any; }) {
    const supplierData = e.data;
    const supplierToDelete = new OxomiSupplier(supplierData.id, supplierData.customerId, supplierData.number, supplierData.name);
    this.oxomiSortimentService.deleteSupplier(supplierToDelete, this.loginService.currentCustomer.id).then((result: boolean) => {
      this.systemService.notifyInfo(this.translate.instant("Lieferant wurde gelöscht."));
    })
  }

  getValueForLastUpdate(rowData: { lastDataUpdate: string | number | Date; }) {
    if (!rowData.lastDataUpdate || rowData.lastDataUpdate === "0001-01-01T00:00:00Z") {
      return '';
    }

    const date = new Date(rowData.lastDataUpdate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  resetLastUpdateNotPossible(data: { data: { lastDataUpdate: string; }; }) {
    return data.data.lastDataUpdate == "0001-01-01T00:00:00Z";
  }

  resetLastUpdateDate(data: { data: { id: number; customerId: string; number: string; name: string; dataUpdateAvailable: boolean; }; }) {
    const date = new Date("0001-01-01T00:00:00Z");
    const supplierToUpdate = new OxomiSupplier(data.data.id, data.data.customerId, data.data.number, data.data.name, date, data.data.dataUpdateAvailable);
    this.oxomiSortimentService.updateSupplier(supplierToUpdate, this.loginService.currentCustomer.id).then((result: boolean) => {
      this.systemService.notifyInfo(this.translate.instant("Letztes Update Datum wurde zurückgesetzt."));
      this.reloadSupplierList();
    })
  }

  onUpdatedSupplier(e: { data: { id: number; customerId: string; number: string; name: string; lastDataUpdate: Date; dataUpdateAvailable: boolean; }; }) {
    const supplierData = e.data;
    const supplierToUpdate = new OxomiSupplier(
      supplierData.id, 
      supplierData.customerId, 
      supplierData.number, 
      supplierData.name, 
      supplierData.lastDataUpdate, 
      supplierData.dataUpdateAvailable
    );  

    if (this.isSupplierDataInvalid(supplierToUpdate)) {
        return;
    }

    this.oxomiSortimentService.updateSupplier(supplierToUpdate, this.loginService.currentCustomer.id).then((result: boolean) => {
      this.systemService.notifyInfo(this.translate.instant("Lieferant wurde geändert."));
      this.reloadSupplierList();
    }).catch(error => {
      var response = new HttpErrorResponse(error);
      if (response.status == 409) {
        this.systemService.notifyInfo(this.translate.instant("messageInvalidOxomiSuppliernumber", {value: response.error.number}));        
        this.reloadSupplierList();
      }
    });    
  }  

  addSupplier() {
    if (this.isSupplierDataInvalid(this.newSupplier)) {
      return;
    }

    this.oxomiSortimentService.createSupplier(this.newSupplier, this.loginService.currentCustomer.id).then((result: OxomiSupplier) => {
      this.systemService.notifyInfo(this.translate.instant("Lieferant wurde angelegt."));
      this.addVisible = false;
      this.reloadSupplierList();
    }).catch(error => {
      var response = new HttpErrorResponse(error);
      if (response.status == 409) {
        this.systemService.notifyInfo(this.translate.instant("messageInvalidOxomiSuppliernumber", {value: response.error.number}));//"Lieferant konnte nicht angelegt werden! Ein Lieferant mit der Lieferantennummer " + response.error.number + " ist bereits vorhanden.");
        this.reloadSupplierList();
      }
    });
  }

  isSupplierDataInvalid(supplier: OxomiSupplier): boolean {
    if (!supplier.number) {
        this.systemService.notifyInfo(this.translate.instant("Bitte geben Sie eine Lieferantennummer an."));
        return true;
    }

    if (!supplier.name) {
        this.systemService.notifyInfo(this.translate.instant("Bitte geben Sie einen Lieferantennamen an."));
        return true;
    }

    return false;
  }

  getHint(data: { data: { dataUpdateAvailable: any; }; }) {
    var hintValue = this.translate.instant("Keine Produkt-Updates vorhanden."); 
    if(data.data.dataUpdateAvailable) {
      hintValue = this.translate.instant("Vorhande Produkt-Updates importieren.");
    }
    return hintValue;
  }

  updateAvailable(data: { data: { dataUpdateAvailable: any; }; }) {
    return data.data.dataUpdateAvailable ? "" : "disabled";
  }

  showAdd() {
    this.newSupplier = new OxomiSupplier(undefined,this.loginService.currentCustomer.id,"","");
    this.addVisible = true;
  }

  showExcelImport() {
    this.excelImportVisible = true;
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.setRequestHeader("customerId", this.loginService.currentUser?.customerId);
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded(e: { request: XMLHttpRequest }) {
    const response = JSON.parse(e.request.response);
    this.excelImportNewCount = response.imported;
    this.excelImportUpdateCount = response.updated;

    this.supplierWithoutNumber = this.formatSupplierList(response.supplierWithoutNumber, 'name');
    this.supplierWithoutNumberExists = !!this.supplierWithoutNumber;

    this.supplierWithoutName = this.formatSupplierList(response.supplierWithoutName, 'number');
    this.supplierWithoutNameExists = !!this.supplierWithoutName;

    this.missingValues = this.supplierWithoutNameExists || this.supplierWithoutNumberExists;
    this.excelImportIgnoreCount = (response.supplierWithoutNumber?.length || 0) + (response.supplierWithoutName?.length || 0);
    this.excelImportVisible = false;
    this.importResumeVisible = true;
    this.reloadSupplierList();
  }

  formatSupplierList(suppliers: any[], key: string) {
      if (!suppliers || suppliers.length === 0) {
          return '';
      }

      return suppliers.map(supplier => supplier[key]).join(', ');
  }

  onUploadError(e: { request: XMLHttpRequest }) {
    var response = JSON.parse(e.request.response);
    this.excelImportVisible = false;
    var errorMessage = "";
    if(response.errorMessage && (response.errorMessage != "")) {
      errorMessage = ": " + response.errorMessage;
    }
    this.systemService.notifyWarning(this.translate.instant(response.errorKey) + errorMessage, 5000);
  }  

  checkForDataUpdate(data: { data: { number: string; }; }) {
    this.oxomiSortimentService.checkForDataUpdate(data.data.number, this.loginService.currentCustomer.id).then((result: boolean) => {
      if(result) {
        this.oxomiSortimentService.oxomiUpdateAvailable = true;
        this.systemService.notifyInfo(this.translate.instant("Updates vorhanden"));
      }
      else {
        this.systemService.notifyInfo(this.translate.instant("Keine Updates vorhanden!"));
      }
      this.reloadSupplierList();
    });
  }

  updateData(data: { data: { number: string; name: string }; }) {
    this.oxomiSortimentService.updateData(data.data.number, data.data.name, this.loginService.currentCustomer.id).then((result: boolean) => {
      if(result) {
        this.systemService.notifyInfo(this.translate.instant("Update Job wurde gestartet"));
      }
      this.reloadSupplierList();
    });
  }

  excelExport() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Supplier');
    const dataGridInstance = this.dataGrid.instance;
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const { numberIndex, nameIndex } = this.getColumnIndexes(visibleColumns);

    exportDataGrid({
        component: dataGridInstance,
        worksheet,
        autoFilterEnabled: true,
    })
    .then(() => {
        this.updateWorksheetHeaders(worksheet, numberIndex, nameIndex);
        this.addStatusColumn(worksheet);
    })
    .then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OXOMI-Supplier.xlsx');
    });
    });
 }

  getColumnIndexes(columns: Column<any, any>[] | { dataField: string; }[]) {
      const defaultIndexes = { numberIndex: 0, nameIndex: 1 };
      if (columns[0].dataField === 'name') {
          return { numberIndex: 1, nameIndex: 0 };
      }
      return defaultIndexes;
  }

  updateWorksheetHeaders(worksheet: Worksheet, numberIndex: number, nameIndex: number) {
      worksheet.columns[numberIndex].header = 'Lieferantennummer [partnerNumbers]';
      worksheet.columns[nameIndex].header = 'Daten-Lieferant [partner]';
      worksheet.columns = worksheet.columns.slice(0, 2);
  }

  addStatusColumn(worksheet: Worksheet) {
      worksheet.columns = [...worksheet.columns, { header: 'Status [state]', key: 'state' }];
      worksheet.getRows(2, worksheet.actualRowCount - 2).forEach(row => {
          row.getCell(3).value = 'Bestätigt';
      });
  }

  reloadSupplierList() {
    this.oxomiSortimentService.getOxomiSupplier(this.loginService.currentCustomer.id).then((result: OxomiSupplier[]) => {
      this.oxomiSupplierList = result;
      this.excelExportVisible = false;
      if(result.length > 0) {
        this.excelExportVisible = true;
      }
    });
  }
}
