import { SearchFields } from "./SearchFields";


export class ProductSearchOptions {
  constructor(
    public id?: string,
    public userId? : string,
    public searchMode?: string,
    public isTarget?: boolean,
    public searchFields?: SearchFields[],
    public searchStateMode?: 'or' | 'and',
  ) {
  }
  deserialize(input: any) {
    Object.assign(this, input);

    //fuer jeden komplexen Datentypen notwendig (!)
    //this.userToCustomerList = this.userToCustomerList.map((utc: UserToCustomer) => new UserToCustomer().deserialize(utc));

    return this;
  }
}
