<dx-radio-group [(items)]="model"
                [(value)]="selectedValue"
                [readOnly]="readOnly"
                layout="horizontal"
                (onValueChanged)="valueChanged($event)">
  <dx-validator *ngIf="!templateView"
                [validationGroup]="validatorValidationGroup"
                (onInitialized)="validationService.startAutomaticValidation($event)">
    <dxi-validation-rule type="async" [validationCallback]="validate"></dxi-validation-rule>
  </dx-validator>
</dx-radio-group>
