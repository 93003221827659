<fullscreen-page>
  <content-column helpSwitch="true" look="dark">    
    <div slot="content" class="h-100 p-3 w-100 mx-auto">
      <div class="d-block h-100 w-100">
        <iframe class="h-100 w-100" [style]="{ 'border-width': '0px' }" *ngIf="showDesigner" [src]="designerUrl"></iframe>
        <!--
        <dx-report-designer [reportUrl]="reportUrl" height="100%">
          <dxrd-request-options [getDesignerModelAction]="getDesignerModelAction"
                                [host]="hostUrl">
          </dxrd-request-options>
          <dxrd-callbacks (CustomizeLocalization)="CustomizeLocalization($event)"
                          (CustomizeMenuActions)="CustomizeMenuActions($event)">
          </dxrd-callbacks>
        </dx-report-designer>
        -->
      </div>
    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page> 
