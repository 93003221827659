<!--11 Bilder konvertieren-->
<!--MAX RESOLUTION-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Maximale Seitenlänge' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ maxResolution > 0 ? 'placeLabelSmall' : '' }}">MAX RESOLUTION</label>
    <dx-number-box [min]="16" [max]="8192" [(value)]="maxResolution" [showClearButton]="true"></dx-number-box>
  </div>
</div>
<!--SQUARE IMAGE-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Quadratische Ansicht' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ imageSquare ? 'placeLabelSmall' : '' }}">SQUARE IMAGE</label>
    <dx-switch [(value)]="imageSquare"></dx-switch>
  </div>
</div>
<!--BACKGROUND COLOR-->
<div *ngIf="imageSquare">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Hintergrundfarbe wählen' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ imageBgColor ? 'placeLabelSmall' : '' }}">BACKGROUND COLOR</label>
      <dx-color-box [(value)]="imageBgColor"></dx-color-box>
    </div>
  </div>
  <div class="alert alert-warning">
    {{ 'Achtung! Hintergrundfarbe kann nur einmal geändert werden.' | translate }}
  </div>
</div>
<!--FILE TYPE-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Dateityp' | translate }}</div>
  <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
    <label class="placeLabel {{ imageType?.length > 0 ? 'placeLabelSmall' : '' }}">FILE TYPE</label>
    <dx-select-box [(value)]="imageType" [items]="imageTypes"   [searchEnabled]="true" placeholder=""></dx-select-box>
  </div>
</div>
