export class OsgConnection {

  constructor(
    public id?: number,
    public customerId?: string,
    public shopUrl?: string,
    public apiKey?: string,
    public isTest?: boolean,
  
  ) {
  }
}
