import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { DeeplConnection } from "../Model/Dto/DeeplConnection";
import { Message } from "../Model/System/Message";

@Injectable()
export class DeeplConnectionService {


  constructor(private http: HttpClient, public translate: TranslateService) {

  }

  getConnection(customerId: string): Observable<DeeplConnection> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }

    return (this.http.get<DeeplConnection>("api/DeeplConnection/GetConnection", options)) as any;
  }

  update(deeplConnection: DeeplConnection): Observable<DeeplConnection> {
    return (this.http.post<DeeplConnection>("api/DeeplConnection/Update", deeplConnection));
  }

  checkAuthKey(deeplConnection: DeeplConnection): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("authKey", deeplConnection.authKey)
    }
    var result = (this.http.get<boolean>("api/DeeplConnection/CheckAuthKey", options)) as any;
    return result;
  }
}
