<fullscreen-page>
  <content-column look="dark">
    <logo slot="toolbar" [routerLink]="['/']" look="dark" route="['/catalogs']"></logo>
    <div slot="content" class="h-100 p-3">
      <div class="d-block h-100 w-100 mx-auto" style="max-width: 600px;">


        <form (submit)="onChangePassword()">

          <div class="dx-fieldset">

            <div class="dx-fieldset-header">{{ 'Password ändern' | translate }}</div>
            <div class="dx-field inputWithPlaceHolder">
              <label class="placeLabel {{ creds.oldPassword?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Password' | translate }}</label>
              <dx-text-box [(value)]="creds.oldPassword" mode="password" [showClearButton]="true" width="100%">
                <dx-validator>
                  <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihr altes Passwort ein' | translate }}."></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>

            <div class="dx-field inputWithPlaceHolder">
              <label class="placeLabel {{ creds.newPassword?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Neues Password' | translate }}</label>
              <dx-text-box [(value)]="creds.newPassword" mode="password" [showClearButton]="true" width="100%">
                <dx-validator>
                  <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihr neues Passwort ein' | translate }}."></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>

            <div class="dx-field inputWithPlaceHolder">
              <label class="placeLabel {{ creds.confirmPassword?.length>0 ? 'placeLabelSmall' : '' }}">{{ 'Wiederholung' | translate }}</label>
              <dx-text-box [(value)]="creds.confirmPassword" mode="password" [showClearButton]="true" width="100%">
                <dx-validator>
                  <dxi-validation-rule type="required" message="{{ 'Bitte wiederholen Sie Ihr neues Password' | translate }}."></dxi-validation-rule>
                  <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                       message="{{ 'Die Passwörter stimmen nicht überein' | translate }}.">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>

            <dx-validation-summary id="summary"></dx-validation-summary>

            <div class="dx-field d-flex flex-row">
              <dx-button stylingMode="text" type="default" text="{{ 'Ändern' | translate }}" [useSubmitBehavior]="true"></dx-button>
            </div>

          </div>

        </form>


      </div>
    </div>
  </content-column>
</fullscreen-page>
