import { CustomField } from "../User/CustomField";

export class ValidationField {

  constructor(
    public fieldName?: string,
    public fieldGroup?: string,
    public isList?: boolean,
    public isBool?: boolean,
    public isPicture?: boolean,
    public customField?: CustomField
  ) {
  }
}


