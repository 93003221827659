<fullscreen-page>
  <navigation-column>
    <div class="h-100 d-flex flex-column">
      <div class="p-2 flex-fill overflow-hidden">
        <dx-data-grid class="h-100"
                      (onToolbarPreparing)="onNavigationToolbarPreparing($event)"
                      [dataSource]="modelService.loginService.customerLanguages"
                      [remoteOperations]="false"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [allowColumnResizing]="true"
                      noDataText="{{ 'keine Sprache im Account' | translate }}"
                      [showBorders]="true"
                      (onRowRemoving)="onDeleteClicked($event)"
                      (onRowClick)="onLanguageClick($event)">
          <div *dxTemplate="let toolbaritem of 'addLanguageButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Neue Sprache' | translate }}" (onClick)="showAddLanguage()"></dx-button>
          </div>
          <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="false" [useIcons]="true">
            <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diese Sprache wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Sprache löschen' | translate }}"></dxo-texts>
          </dxo-editing>
          <dxo-search-panel [visible]="false"></dxo-search-panel>

          <dxo-state-storing [enabled]="true" type="localStorage" storageKey="TranslationLanguagesStateNewUIV2"></dxo-state-storing>

          <dxi-column name="languageFlag" dataField="language" caption="" width="70" cellTemplate="languageFlagTemplate"></dxi-column>
          <div *dxTemplate="let data of 'languageFlagTemplate'">
            <img src="images/flags/{{getLanguageFlag(data.data.language)}}" class="flag" />
          </div>
          <dxi-column name="language" dataField="language" caption="{{ 'Sprache' | translate }}" cellTemplate="languageTemplate"></dxi-column>
          <div *dxTemplate="let data of 'languageTemplate'">
            {{translateLanguage(data.data.language)}}
          </div>
          <dxi-column caption="" width="50" cellTemplate="switchLanguageTemplate"></dxi-column>
          <div *dxTemplate="let data of 'switchLanguageTemplate'" class="center">
            <span *ngIf="isCurrentLanguage(data.data)" class="glyphicon glyphicon-ok-circle" title="{{ 'Sprache ist ausgewält' | translate }}"></span>
          </div>

        </dx-data-grid>        
      </div>
    </div>
  </navigation-column>
  <content-column helpSwitch="true" look="light">    
      <div slot="content" class="p-2 flex-fill overflow-hidden">
        <dx-data-grid class="h-100"
                      (onToolbarPreparing)="onContentToolbarPreparing($event)"
                      [dataSource]="translations"
                      [remoteOperations]="false"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [allowColumnResizing]="true"
                      noDataText="{{ 'keine Übersetzungen im Account' | translate }}"
                      [showBorders]="true"
                      (onRowRemoving)="deleteTranslation($event)"
                      (onRowUpdated)="update($event)"
                      [visible]="selectedLanguageCode">
          <div *dxTemplate="let toolbaritem of 'addTranslationButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Neue Übersetzung' | translate }}" (onClick)="addTranslation()"></dx-button>
          </div>

          <div *dxTemplate="let toolbaritem of 'excelExportButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Excel Export' | translate }}" (onClick)="exportExcel()"></dx-button>
          </div>

          <div *dxTemplate="let toolbaritem of 'excelImportButtonTemplate'">
            <dx-button stylingMode="text" type="default" text="{{ 'Excel Import' | translate }}" (onClick)="showImportTranslations()"></dx-button>
          </div>

          <div *dxTemplate="let toolbaritem of 'deleteTranslationsButtonTemplate'">
            <dx-button stylingMode="text" type="danger" text="{{ 'Übersetzungen löschen' | translate }}" (onClick)="deleteAllTranslations()"></dx-button>
          </div>

          <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
            <dx-button (onClick)="selectedLanguageCodeChanged()" icon="refresh"></dx-button>
          </div>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Übersetzung suchen' | translate }}" width="250"></dxo-search-panel>
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-editing mode="cell" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
            <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diese Übersetzung wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Übersetzung löschen' | translate }}"></dxo-texts>
          </dxo-editing>

          <dxo-state-storing [enabled]="true" type="localStorage" storageKey="TranslationTextsStateNewUIV2"></dxo-state-storing>

          <dxi-column dataField="text" caption="{{ 'Text' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
          <dxi-column dataField="translation" caption="{{ 'Übersetzung' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
          <dxi-column dataField="approved" caption="{{ 'Geprüft' | translate }}" (onValueChanged)="update($event)" [allowEditing]="true"></dxi-column>
        </dx-data-grid>
      </div>      
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>

<dx-popup *ngIf="importTranslationsVisible"
          [width]="500"
          [height]="300"
          [showTitle]="true"
          title="Übersetzungen importieren"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importTranslationsVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <importTranslations></importTranslations>
  </div>
</dx-popup>

<dx-popup *ngIf="addLanguageVisable"
          [width]="600"
          [height]="200"
          [showTitle]="true"
          title="Sprache hinzufügen"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addLanguageVisable" 
          contentTemplate="popupContent">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Sprache hinzufügen' | translate, onClick: addLanguage }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-field">
      <div class="dx-field-label">Sprache</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ addLanguageSelected?.length>0 ? 'placeLabelSmall' : '' }}">LANGUAGE</label>
        <dx-select-box [(value)]="addLanguageSelected"
                       [items]="languages"
                       displayExpr="language"
                       placeholder=""
                       valueExpr="code">
        </dx-select-box>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup *ngIf="popupDeleteTranslateVisible"
          [width]="400"
          [height]="220"
          [showTitle]="true"
          title="{{ 'Übersetzungen löschen' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="popupDeleteTranslateVisible">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Ja' | translate, onClick: deleteAllTranslationsConfirmed }"></dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Nein' | translate, onClick: deleteAllTranslationsClose }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">    
    <p>{{'Wollen Sie alle Übersetzungen wirlich löschen' | translate }}?</p>    
  </div>
</dx-popup>
